import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  AnalysisIcon,
  MomentumIcon,
  OptimusGapIcon,
  PaidIcon,
  StrategyIcon,
} from "../../../Components/CustomIcons";
import Tabs from "../../../Components/Tabs";
import { setCurrentView } from "../../../Redux/modules/layout/actions";
import { clearMatrizESimulation } from "../../../Redux/modules/sniper/matriz-e/actions";
import { clearMomentumSimulation } from "../../../Redux/modules/sniper/momentum/actions";
import Analysis from "./Analysis";
import MatrizE from "./MatrizE";
import Momentum from "./Momentum";
import OptimusGap from "./OptimusGap";
import Strategy from "./Strategy";

const Wrapper = styled.div`
  .title {
    margin-bottom: 2em;
    .page-name {
      display: flex;
      align-items: flex-start;
      .caption {
      }
    }
  }
`;

const Sniper = () => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();

  const options = [
    {
      content: <MatrizE />,
      view: "matriz-e",
      name: (
        <span>
          {t("price_management.sniper_module.matriz_component.title")}
          <span
            style={{
              fontWeight: 700,
              fontSize: "30px",
              paddingLeft: "5px",
              marginRight: "3px",
            }}
          >
            e
          </span>
        </span>
      ),
    },
    {
      content: <Momentum />,
      view: "momentum",
      name: `${t("price_management.sniper_module.momentum_component.title")}`,
      icon: <MomentumIcon style={{ marginRight: "3px" }} />,
    },
    {
      content: <OptimusGap />,
      view: "optimus-gap",
      name: `${t(
        "price_management.sniper_module.optimus_gap_component.title"
      )}`,
      icon: <OptimusGapIcon style={{ marginRight: "3px" }} />,
      disabled: true,
    },
    {
      content: <Analysis />,
      view: "analysis",
      name: `${t("price_management.sniper_module.analysis_component.title")}`,
      icon: <AnalysisIcon style={{ marginRight: "3px" }} />,
      disabled: true,
    },
    {
      content: <Strategy />,
      view: "strategy",
      name: `${t("price_management.sniper_module.strategy_component.title")}`,
      icon: <StrategyIcon style={{ marginRight: "3px" }} />,
      disabled: true,
    },
  ];

  const handleTabSelected = (index) => {
    const tab = options[index];
    dispatch(setCurrentView(tab.view));
  };

  useEffect(() => {
    handleTabSelected(0);
    return () => {
      dispatch(clearMatrizESimulation());
      dispatch(clearMomentumSimulation());
    };
  }, []);

  return (
    <Wrapper>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <PaidIcon style={{ fontSize: "40px", marginRight: "10px" }}></PaidIcon>
        <Stack>
          <Typography sx={{ fontSize: "25px", fontWeight: "700" }}>
            {t("drawer_component.prices_lab")}
          </Typography>
          <Typography color="text.primary">
            {t("drawer_component.sniper")}
          </Typography>
        </Stack>
      </div>
      <Tabs
        setTabSelected={handleTabSelected}
        tabs={options}
        defaultValue={0}
      />
    </Wrapper>
  );
};

export default Sniper;
