export const CREATE_SKU_REQUEST = "CREATE_SKU_REQUEST";
export const CREATE_SKU_SUCCESS = "CREATE_SKU_SUCCESS";
export const CREATE_SKU_FAILURE = "CREATE_SKU_FAILURE";

export const GET_SKUS_BY_CATEGORY_ID_REQUEST = "GET_SKUS_BY_CATEGORY_ID_REQUEST";
export const GET_SKUS_BY_CATEGORY_ID_SUCCESS = "GET_SKUS_BY_CATEGORY_ID_SUCCESS";
export const GET_SKUS_BY_CATEGORY_ID_FAILURE = "GET_SKUS_BY_CATEGORY_ID_FAILURE";

export const UPDATE_SKU_REQUEST = "UPDATE_SKU_REQUEST";
export const UPDATE_SKU_SUCCESS = "UPDATE_SKU_SUCCESS";
export const UPDATE_SKU_FAILURE = "UPDATE_SKU_FAILURE";

export const DELETE_SKU_REQUEST = "DELETE_SKU_REQUEST";
export const DELETE_SKU_SUCCESS = "DELETE_SKU_SUCCESS";
export const DELETE_SKU_FAILURE = "DELETE_SKU_FAILURE";

export const CLEAR_DATA_SKU = "CLEAR_DATA_SKU";

export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA'
export const CLEAR_RESPONSE_SKU = "CLEAR_RESPONSE_SKU";
