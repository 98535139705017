import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";

export const Wrapper = styled("div")(() => ({
  boxShadow: "0px 3px 6px #00000029 ",
  borderRadius: "10px",
  height: "648px",
  scrollBehavior: "smooth",
}));

export const CssBox = styled(Paper)(({ theme }) => ({
  position: "fixed",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  placeContent: "center",
  alignItems: "center",
  backgroundColor:theme.palette.custom.secondary,

  "& .close-btn": {
    display: "flex",
    gap: "1rem",
    color: theme.palette.custom.primary,
    position: "absolute",
    top: "1em",
    right: "1em",
    cursor: "pointer",
  },
}));
