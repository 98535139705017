import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { token } from "../auth/selectors";
import {
  CREATE_SUBCATEGORY_REQUEST,
  CREATE_SUBCATEGORY_SUCCESS,
  CREATE_SUBCATEGORY_FAILURE,
  GET_SUBCATEGORIES_REQUEST,
  GET_SUBCATEGORIES_SUCCESS,
  GET_SUBCATEGORIES_FAILURE,
  UPDATE_SUBCATEGORY_FAILURE,
  UPDATE_SUBCATEGORY_SUCCESS,
  UPDATE_SUBCATEGORY_REQUEST,
  DELETE_SUBCATEGORY_FAILURE,
  DELETE_SUBCATEGORY_SUCCESS,
  DELETE_SUBCATEGORY_REQUEST,
} from "./types";

import {
  ADD_SUBCATEGORY_TO_CATEGORY,
  UPDATE_SUBCATEGORY_IN_CATEGORY,
  DELETE_SUBCATEGORY_OF_CATEGORY,
} from "../categories/types";

function* createSubcategory({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const body = {
      category_id: payload.categoryId,
      name: payload.name,
      description: payload.description,
      color: payload.color,
    };

    const response = yield call(
      callApiAxios,
      "POST",
      "pricing.sku.subcategory/",
      tokenAccess,
      {},
      body
    );
    yield put({
      type: CREATE_SUBCATEGORY_SUCCESS,
      subcategory: response.data[0],
    });
    yield put({
      type: ADD_SUBCATEGORY_TO_CATEGORY,
      subcategory: response.data[0],
    });
  } catch (e) {
    yield put({
      type: CREATE_SUBCATEGORY_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* getSubcategories({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const response = yield call(
      callApiAxios,
      "GET",
      `pricing.sku.subcategory/${payload}`,
      tokenAccess,
      {},
      {}
    );
    yield put({
      type: GET_SUBCATEGORIES_SUCCESS,
      subcategories: response.data,
    });
  } catch (e) {
    yield put({
      type: GET_SUBCATEGORIES_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* updateSubcategory({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const body = {
      name: payload.name,
      description: payload.description,
      color: payload.color,
    };

    const response = yield call(
      callApiAxios,
      "POST",
      `pricing.sku.subcategory/${payload.id}`,
      tokenAccess,
      {},
      body
    );
    yield put({
      type: UPDATE_SUBCATEGORY_SUCCESS,
      subcategory: response.data,
    });
    yield put({ type: UPDATE_SUBCATEGORY_IN_CATEGORY, subcategory: payload });
  } catch (e) {
    yield put({
      type: UPDATE_SUBCATEGORY_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* deleteSubcategory({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const response = yield call(
      callApiAxios,
      "POST-PARAMS",
      `pricing.sku.subcategory/${payload.id}/del`,
      tokenAccess,
      {},
      {}
    );
    yield put({
      type: DELETE_SUBCATEGORY_SUCCESS,
      payload: response.data,
    });
    yield put({ type: DELETE_SUBCATEGORY_OF_CATEGORY, subcategory: payload });
  } catch (e) {
    yield put({
      type: DELETE_SUBCATEGORY_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

export default function* subcategoriesSaga() {
  yield takeEvery(CREATE_SUBCATEGORY_REQUEST, createSubcategory);
  yield takeEvery(UPDATE_SUBCATEGORY_REQUEST, updateSubcategory);
  yield takeEvery(DELETE_SUBCATEGORY_REQUEST, deleteSubcategory);
  yield takeEvery(GET_SUBCATEGORIES_REQUEST, getSubcategories);
}
