import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import { BrowserRouter } from "react-router-dom";

import { isAuth, getLoadingAuth } from "./Redux/modules/auth/selectors";
import {
  getLanguage,
  getTheme,
  getIsLoading,
} from "./Redux/modules/app/selectors";
import { getLoadingCategories } from "./Redux/modules/categories/selectors";
import { getLoadingSubcategories } from "./Redux/modules/subcategories/selectors";
import { getLoadingOwners } from "./Redux/modules/owners/selectors";
import { getLoadingBrands } from "./Redux/modules/brands/selectors";
import { getLoadingUnits } from "./Redux/modules/units/selectors";
import { getLoadingUsers } from "./Redux/modules/users/selectors";
import { getLoadingCustomers } from "./Redux/modules/customers/selectors";
import { getLoadingSkus } from "./Redux/modules/skus/selectors";
import { getLoadingRelations } from "./Redux/modules/relations/selectors";
import { getLoadingVariables } from "./Redux/modules/variables/selectors";
import { getLoadingCategoryData } from "./Redux/modules/categoryData/selectors";
import { getLoadingCurves } from "./Redux/modules/what-if/curves/selectors";
import { getLoadingOptimalPrices } from "./Redux/modules/what-if/optimal-prices/selectors";
import { getLoadingTrend } from "./Redux/modules/what-if/trend/selectors";
import { getLoadingMomentum } from "./Redux/modules/sniper/momentum/selectors";
import { getLoadingMatrizE } from "./Redux/modules/sniper/matriz-e/selectors";
import { getLoadingPL } from "./Redux/modules/promotion-planning/pl/selectors";
import { getLoadingPromotions } from "./Redux/modules/promotion-planning/promotions/selectors";
import { getLoadingVolumes } from "./Redux/modules/promotion-planning/volumes/selectors";
import { getConfig } from "./Redux/modules/app/actions";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import "./App.css";

import { SnackbarProvider } from "notistack";

import Login from "./Routes/Login";

import Pages from "./Routes";

import Loading from "./Components/Loading";
import Layout from "./Containers/Layout";

function App() {
  const [t, i18n] = useTranslation("global");

  const dispatch = useDispatch();

  const language = useSelector(getLanguage);
  const currentTheme = useSelector(getTheme);
  const userIsAuth = useSelector(isAuth);

  const loadingAuth = useSelector(getLoadingAuth);
  const loadingColors = useSelector(getIsLoading);
  const loadingCategories = useSelector(getLoadingCategories);
  const loadingSubCategories = useSelector(getLoadingSubcategories);
  const loadingOwners = useSelector(getLoadingOwners);
  const loadingBrands = useSelector(getLoadingBrands);
  const loadingUnits = useSelector(getLoadingUnits);
  const loadingUsers = useSelector(getLoadingUsers);
  const loadingSkus = useSelector(getLoadingSkus);
  const loadingCustomers = useSelector(getLoadingCustomers);
  const loadingRelations = useSelector(getLoadingRelations);
  const loadingVariables = useSelector(getLoadingVariables);
  const loadingCategoryData = useSelector(getLoadingCategoryData);
  const loadingCurves = useSelector(getLoadingCurves);
  const loadingOptimalPrices = useSelector(getLoadingOptimalPrices);
  const loadingTrend = useSelector(getLoadingTrend);
  const loadingMomentum = useSelector(getLoadingMomentum);
  const loadingMatrizE = useSelector(getLoadingMatrizE);
  const loadingPromotions = useSelector(getLoadingPromotions);
  const loadingVolumes = useSelector(getLoadingVolumes);
  const loadingPL= useSelector(getLoadingPL);
  const isLoading =
    loadingAuth ||
    loadingColors ||
    loadingCategories ||
    loadingSubCategories ||
    loadingOwners ||
    loadingBrands ||
    loadingUnits ||
    loadingUsers ||
    loadingSkus ||
    loadingRelations ||
    loadingVariables ||
    loadingCustomers ||
    loadingCategoryData ||
    loadingCurves ||
    loadingOptimalPrices ||
    loadingTrend ||
    loadingMomentum ||
    loadingMatrizE ||
    loadingPromotions ||
    loadingVolumes ||
    loadingPL;

  const theme = createTheme({
    palette: {
      custom: {
        ...currentTheme,
      },
    },
    typography: {
      fontFamily: '"Open Sans", sans-serif',
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          shrink: {
            // display: "none",
            transform: "translate(38px, -6px) scale(0.65)",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& legend": {
              marginLeft: 21,
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    i18n.changeLanguage(language);
    if (userIsAuth) {
      dispatch(getConfig());
    }
  }, [language, userIsAuth]);

  return (
    <div className="App">
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            {userIsAuth === false ? (
              <Login />
            ) : (
              <Layout>
                <Pages />
              </Layout>
            )}
            <Loading isLoading={isLoading} />
          </BrowserRouter>
        </ThemeProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;
