import React from "react";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getFormDataTrend,getTrendFunctionType } from "../../../../Redux/modules/what-if/trend/selectors";
import { setFormDataTrend ,getSimulateTrend} from "../../../../Redux/modules/what-if/trend/actions";
import { getCategorySelected } from "../../../../Redux/modules/categories/selectors";

const RadioBtnGroup = () => {
  const [t, i18n] = useTranslation("global");
  const functionType = useSelector(getTrendFunctionType);
  const dispatch = useDispatch();
  const typeFunctions = ["optimal", "max_sales", "max_profit"];
  const tempForm = useSelector(getFormDataTrend);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        alignContent: "start",
        paddingTop:"5px",
        paddingBottom:"40px"
      }}
    >
      <FormLabel
        sx={{
          color: (theme) => `${theme.palette.custom.primaryVariant}`,
          fontWeight: "bold",
          "&.Mui-focused fieldset,&.Mui-focused ": {
            color: (theme) => `${theme.palette.custom.primaryVariant}`,
          },
          marginRight: "0.5rem",
        }}
        id="radio-subcategories-group-label"
      >
        {`${t("price_management.trend.type_Of_Price")}`}
      </FormLabel>
      <RadioGroup
        style={{ display: "flex", flexDirection: "row" }}
        aria-labelledby="radio-function-type-label"
        value={tempForm.typePrice}
        name="radio-function-type"
        onChange={(e) => {
          dispatch(
            setFormDataTrend({
              propertyName: "typePrice",
              propertyValue: e.target.value,
            })
          );
          dispatch(getSimulateTrend.trigger("changeTypeOfPrice"));
        }}
      >
        {typeFunctions.map((option, index) => {
          return (
            <FormControlLabel
              key={index}
              value={option}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: (theme) =>
                        `${theme.palette.custom.primaryVariant}`,
                    },
                  }}
                />
              }
              label={`${t("price_management.trend." + option)}`}
              sx={{ marginBottom: "-10px" }}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
};

export default RadioBtnGroup;
