import * as type from "./types";
import { createAction } from "redux-actions";

export const setStep = createAction(type.SET_STEP);
export const setStage = createAction(type.SET_STAGE);
export const setStatus = createAction(type.SET_STATUS);
export const closeCategory = createAction(type.CLOSE_CATEGORY);

export const selectCategoryIdToCreateOrUpdate = createAction(
  type.SELECT_CATEGORY_ID_TO_CREATE_OR_UPDATE
);
export const clearCategoryIdToCreateOrUpdate = createAction(
  type.CLEAR_CATEGORY_ID_TO_CREATE_OR_UPDATE
);
export const clearResponseInitialSetup = createAction(
    type.CLEAR_RESPONSE_INITIAL_SETUP
  );