import React from "react";
import { SvgIcon } from "@mui/material";

export const AlertBellIcon = () => {
  const classAlertBell = {
    fill: "none",
    stroke: "#6FD1B0",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5px",
  };
  return (
    <SvgIcon viewBox="0 0 48 48">
      <g transform="matrix(1.5,0,0,1.5,0,0)">
        <title>alert-bell-notification-2</title>
        <circle
          style={{
            fill: "none",
            stroke: "#EBCE75",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "1.5px",
          }}
          cx="18.875"
          cy="5.827"
          r="3"
        />
        <path
          style={classAlertBell}
          d="M9.125,21.75a2.087,2.087,0,0,0,4.005,0"
        />
        <line style={classAlertBell} x1="11.125" y1="3" x2="11.125" y2="0.75" />
        <path
          style={classAlertBell}
          d="M18.648,11.961c.189,5.756,1.477,6.789,1.477,6.789h-18s1.5-1.916,1.5-8.25a7.507,7.507,0,0,1,9.724-7.165"
        />
      </g>
    </SvgIcon>
  );
};
