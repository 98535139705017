import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Tab, Tabs } from "./styles";
import { useEffect, useState } from "react";

function TabPanel(props) {
  const { children, value, index, icon, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CustomTabs = ({ defaultValue = 0, setTabSelected, ...props }) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (setTabSelected) {
      setTabSelected(newValue);
    }
  };

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue]);

  return (
    value >= 0 && (
      <Box sx={{ width: "100%", marginBottom: "0.5em" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            defaultValue={value ? value : 0}
            onChange={(e, value) => handleChange(e, value)}
          >
            {props.tabs?.map((item, index) => (
              <Tab
                isSelected={value === index}
                label={item.name}
                key={index}
                disabled={item.disabled}
                icon={item.icon}
                iconPosition="start"
              />
            ))}
          </Tabs>
        </Box>
        {!props.notContentDynamicTabs &&
          props.tabs?.map((item, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              disabled={item.disabled}
            >
              {item.content}
            </TabPanel>
          ))}
      </Box>
    )
  );
};

export default CustomTabs;
