import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import TextField from "../../../../Components/TextField";
import Button from "../../../../Components/Button";
import ColorPicker from "../../../../Components/ColorPicker";
import MenuItem from "@mui/material/MenuItem";
import {
  Chip,
  Box,
  Autocomplete,
  Stack,
} from "@mui/material/";
import { SaveIcon, ClipIcon } from "../../../../Components/CustomIcons";
import { styled } from "@mui/material/styles";

const FormBrand = ({
  brand,
  brands,
  currentOwners,
  onBrandCreated,
  onBrandUpdated,
  onBrandsAdded,
  setCurrentBrand,
}) => {
  const [t, i18n] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const [addExisting, setAddExisting] = useState(false);
  const [tempExistBrands, setTempExistBrands] = useState([]);
  const [tempBrand, setTempBrand] = useState(
    brand
      ? {
          id: brand.id,
          name: brand.name,
          description: brand.description,
          color: brand.color,
          ownerId: brand.owner_id ? brand.owner_id[0] : null,
        }
      : {
          id: 0,
          name: "",
          description: "",
          color: "",
          ownerId: null,
        }
  );
  const isNew = brand ? false : true;
  const isValidForm = () => {
    if (!tempBrand.name) {
      enqueueSnackbar("Error", { variant: "error" });
      return false;
    }
    return true;
  };

  const handleInputChanges = (newValue, type) => {
    setTempBrand({ ...tempBrand, [type]: newValue });
    if (!isNew) {
      setCurrentBrand({ ...tempBrand, [type]: newValue });
    }
  };

  const handleAssociateForm = (e) => {
    setAddExisting(!addExisting);
  };

  const onSubmitBrand = () => {
    if (isValidForm()) {
      isNew ? onBrandCreated(tempBrand) : onBrandUpdated(tempBrand);
      onReset();
    }
  };

  const onReset = () => {
    setTempBrand({ ownerId: 0, name: "", description: "", color: "#B2ADB4" });
  };

  const handleCancelAssociate = () => {
    setAddExisting(!addExisting);
    setTempExistBrands([]);
  };
  const handleAssociateBrands = () => {
    setAddExisting(!addExisting);
    onBrandsAdded(tempExistBrands);
  };

  const CssAssociateLink = styled("span")(({ theme }) => ({
    color: theme.palette.custom.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.custom.thirdVariant,
    },
    ".chipExisting": {
      color: theme.palette.custom.background,
      backgroundColor: theme.palette.custom.thirdVariant,
    },
  }));

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginBottom: "1em" }}
    >
      {!addExisting ? (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              required
              variant="third"
              label={`${t("initial_setup.step_2.stage_2.form_name")} ${t(
                "initial_setup.forms.common_inputs.name"
              )}`}
              value={tempBrand.name}
              onChange={(e) => {
                handleInputChanges(e.target.value, "name");
              }}
            />
            <TextField
              fullWidth
              select
              style={{ marginLeft: "20px" }}
              variant="third"
              label={t("initial_setup.forms.common_inputs.owner")}
              value={tempBrand.ownerId}
              disabled={ !tempBrand.name}
              onChange={(e) => handleInputChanges(e.target.value, "ownerId")}
            >
              {currentOwners?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </TextField>

            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                fontWeight: "600",
                flexDirection: "column",
                margin: "0px 20px",
              }}
            >
              <span>{t("initial_setup.forms.common_inputs.color")}</span>
              <ColorPicker
                value={tempBrand.color}
                onChangeComplete={(e) =>
                  handleInputChanges(e.target.value, "color")
                }
              />
            </div>
            {isNew && (
              <Button
                fullWidth
                style={{ maxWidth: "150px" }}
                variant="thirdVariant"
                disabled={
                  !tempBrand.name || !tempBrand.ownerId
                }
                onClick={onSubmitBrand}
              >
                <SaveIcon />
                <span style={{ paddingLeft: "5px" }}>
                  {t("components_globals.button_save")}
                </span>
              </Button>
            )}
          </div>
          {brands && brands.length > 0 && (
            <div style={{ marginTop: "0.5em", marginBottom: "1.5em" }}>
              <CssAssociateLink onClick={handleAssociateForm}>
                <Chip className="chipExisting" label={brands.length}></Chip>{" "}
                {t("initial_setup.step_2.stage_2.associate_brands")}
              </CssAssociateLink>
            </div>
          )}
        </>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Autocomplete
            style={{ width: "500px" }}
            value={tempExistBrands}
            onChange={(e, newValue) => setTempExistBrands(newValue)}
            multiple
            options={brands}
            getOptionLabel={(option) => option.name}
            freeSolo
            renderTags={(currentIds) => {
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {currentIds.map((item, index) => (
                    <Chip
                      key={index}
                      label={brands.find((x) => x.id === item.id)?.name}
                    />
                  ))}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="third"
                label={t("initial_setup.forms.common_inputs.brands")}
                placeholder={t("components_globals.button_search")}
              />
            )}
          />
          <Stack
            gap={"1rem"}
            justifyContent={"end"}
            direction="row"
            mr={"5rem"}
          >
            <Button
              variant="thirdVariant"
              disabled={tempExistBrands.length === 0  }
              style={{ width: "max-content", marginLeft: "0.5em" }}
              onClick={handleAssociateBrands}
            >
              <ClipIcon />
              {t("components_globals.button_associate")}
            </Button>
            <Button
              variant="gray"
              style={{
                width: "max-content",
                marginRight: "0.5em",
              }}
              onClick={handleCancelAssociate}
            >
              {t("components_globals.button_cancel")}
            </Button>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default FormBrand;
