import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { TableBody } from "@mui/material/";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "../../../../Components/Button";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import { SweepingIcon } from "../../../../Components/CustomIcons/SweepingIcon";
import Input from "../../../../Components/Input";
import {
  clearMomentumSimulation,
  getSimulateMomentum,
  setSimulationDataMomentum,
} from "../../../../Redux/modules/sniper/momentum/actions";
import {
  getFormDataMomentum,
  getMomentumLoaded,
  getSimulationDataMomentum,
} from "../../../../Redux/modules/sniper/momentum/selectors";
import { commaSeparateNumber, getMonthName } from "../../../../Utils";
import { getDateValues } from "../../../../Utils/commonWithDates";
import {
  ButtonsCell,
  CustomBox,
  CustomCell,
  CustomCellSticky,
  CustomTable,
  CustomTableRow,
  TitleCell,
} from "./Styles";
import { useState } from "react";

const TableCompetitorPrices = () => {
  const dispatch = useDispatch();
  const table = useRef(null);
  const [t, i18n] = useTranslation("global");
  const tempForm = useSelector(getFormDataMomentum);
  const momentumLoaded = useSelector(getMomentumLoaded);
  const tempSimulationData = useSelector(getSimulationDataMomentum);
  const targetPrices = momentumLoaded?.response;
  const columns = targetPrices ? targetPrices : [];
  const [ellipsis, setEllipsis] = useState(true);
  const [id, setId] = useState("");
  const handleChangeInput = (propertyName, propertyValue, index) => {
    dispatch(
      setSimulationDataMomentum({
        propertyName,
        propertyValue,
        index,
      })
    );
  };

  const findPriceSimulation = (type, index) => {
    let itemExist = tempSimulationData[type].find(
      (item) => item.index === index
    );
    return itemExist;
  };

  const getValue = (type, value, index) => {
    let valExist = findPriceSimulation(type, index);
    return valExist ? valExist.value : value.toFixed(2);
  };

  const buildHeaders = (cols) => {
    return (
      cols &&
      cols.map((col, index) => {
        const colTitle = getDateValues(col.date);
        return (
          <TitleCell minWidth="60px" color="background" key={`th-${index}`}>
            <span style={{ fontWeight: "500", fontSize: "0.8rem" }}>
              {`${t(`months_names.${getMonthName(colTitle.month - 1)}`)
                .substring(0, 3)
                .toUpperCase()}-${colTitle.year.toString().substring(2, 4)}`}
            </span>
          </TitleCell>
        );
      })
    );
  };

  const buildCol = (col, index, isTarget) => {
    const { target, object } = col;
    let template = isTarget ? (
      <CustomCell
        minWidth="60px"
        backgroundColor="thirdVariant"
        key={`${col.index}-${index}-1`}
      >
        <Input
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            width: "60px",
            padding: "8px 2px",
          }}
          variant="secondary"
          name={`${col.index}-${index}`}
          input
          onChange={(e) =>
            handleChangeInput("prices", e.target.value, col.index)
          }
          type="tel"
          value={commaSeparateNumber(
            getValue("prices", target.price, col.index)
          )}
        />
      </CustomCell>
    ) : (
      <CustomCell
        minWidth="60px"
        color="background"
        backgroundColor="thirdVariant"
        key={`${col.index}-${index}-2`}
        style={{ fontWeight: "600" }}
      >
        {commaSeparateNumber(object.price.toFixed(2))}
      </CustomCell>
    );
    return template;
  };

  const handleEllipsis = (bol, id) => {
    setEllipsis(bol);
    setId(id);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <CustomBox backgroundColor="thirdVariant">
        <TableContainer sx={{ maxHeight: 640 }}>
          <CustomTable className="tablePrices" ref={table}>
            <TableHead
              style={{
                zIndex: 100,
                position: "sticky",
                top: 0,
              }}
            >
              <TableRow>
                <ButtonsCell
                  backgroundColor="thirdVariant"
                  className="first-cell"
                  minWidth="150px"
                  maxWidth="150px"
                  sx={{ border: "none" }}
                >
                  <div
                    className="first-cell"
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <ButtonItemColumn
                      style={{ height: "29px" }}
                      tooltip={t("components_globals.button_clear")}
                      onClick={() =>
                        dispatch(clearMomentumSimulation("prices"))
                      }
                      icon={
                        <SweepingIcon
                          className="iconButtonPrimary strokeLigth"
                          sx={{ fontSize: "1.6rem" }}
                        />
                      }
                    ></ButtonItemColumn>
                    <span
                      style={{
                        paddingLeft: "14px",
                        paddingTop: "5px",
                        fontSize: "0.8rem",
                        textTransform: "uppercase",
                        color: "white",
                      }}
                    >
                      {t(
                        `price_management.sniper_module.momentum_component.price`
                      )}
                    </span>
                  </div>
                </ButtonsCell>
                {buildHeaders(columns)}
              </TableRow>
            </TableHead>
            <TableBody>
              <CustomTableRow
                sx={{ "& > *": { borderBottom: "unset" } }}
                key={`tr-${1}-${1}`}
              >
                <CustomCellSticky
                  minWidth="150px"
                  maxWidth="150px"
                  backgroundColor="thirdVariant"
                  type="large"
                  onMouseEnter={() => handleEllipsis(true, "sku")}
                  onMouseLeave={() => handleEllipsis(false, "")}
                  isEllipsis={ellipsis && id === "sku" ? true : false}
                >
                  <span
                    style={{
                      marginLeft: "3px",
                      color: "white",
                      fontSize: "0.8rem",
                    }}
                  >
                    {tempForm.nameSku}
                  </span>
                </CustomCellSticky>
                {targetPrices.length > 0 &&
                  targetPrices.map((col, index) => buildCol(col, index, false))}
              </CustomTableRow>
              <CustomTableRow
                sx={{ "& > *": { borderBottom: "unset" } }}
                key={`tr-${1}-${2}`}
              >
                <CustomCellSticky
                  minWidth="150px"
                  maxWidth="150px"
                  backgroundColor="thirdVariant"
                  type="large"
                  onMouseEnter={() => handleEllipsis(true, "skuCompetitor")}
                  onMouseLeave={() => handleEllipsis(false, "")}
                  isEllipsis={ellipsis && id === "skuCompetitor" ? true : false}
                >
                  <span
                    style={{
                      marginLeft: "3px",
                      color: "white",
                      fontSize: "0.8rem",
                    }}
                  >
                    {tempForm.nameSkuCompetitor}
                  </span>
                </CustomCellSticky>
                {targetPrices.length > 0 &&
                  targetPrices.map((col, index) => buildCol(col, index, true))}
              </CustomTableRow>
            </TableBody>
          </CustomTable>
        </TableContainer>
      </CustomBox>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{ maxWidth: "150px" }}
          onClick={() => {
            dispatch(getSimulateMomentum.trigger());
          }}
        >
          {t("components_globals.button_simulate")}
        </Button>
      </div>
    </div>
  );
};

export default TableCompetitorPrices;
