import { createAction } from "redux-actions";
import { createRoutine } from "redux-saga-routines";
import { SET_FORM_DATA_MOMENTUM,GET_LOAD_MOMENTUM, GET_SIMULATE_MOMENTUM, CLEAR_SIMULATE_MOMENTUM, SET_DEFAULT_FORM_DATA_MOMENTUM, CLEAR_RESPONSE_MOMENTUM, SET_SIMULATION_DATA_MOMENTUM } from "./types";

export const getLoadMomentum = createRoutine(GET_LOAD_MOMENTUM);

export const getSimulateMomentum = createRoutine(GET_SIMULATE_MOMENTUM);

export const setFormDataMomentum = createAction(SET_FORM_DATA_MOMENTUM)
export const setSimulationDataMomentum = createAction(SET_SIMULATION_DATA_MOMENTUM)

export const clearMomentumSimulation = createAction(CLEAR_SIMULATE_MOMENTUM)

export const setDefaultFormDataMomentum = createAction(SET_DEFAULT_FORM_DATA_MOMENTUM);


export const clearResponseMomentum = createAction(CLEAR_RESPONSE_MOMENTUM);

