export const GET_PROMOTIONS = "GET_PROMOTIONS";
export const GET_SIMULATE_PROMOTIONS = "GET_SIMULATE_PROMOTIONS";
export const CLEAR_SIMULATE_PROMOTIONS = "CLEAR_SIMULATE_PROMOTIONS";
export const SET_FORM_DATA_PROMOTIONS = "SET_FORM_DATA_PROMOTIONS";
export const SET_DEFAULT_FORM_DATA_PROMOTIONS = "SET_DEFAULT_FORM_DATA_PROMOTIONS";
export const CLEAR_RESPONSE_PROMOTIONS  = "CLEAR_RESPONSE_PROMOTIONS";
export const ADD_TEMP_PROMOTION  = "ADD_TEMP_PROMOTION";
export const CREATE_PROMOTION  = "CREATE_PROMOTION";
export const UPDATE_PROMOTION  = "UPDATE_PROMOTION";
export const DELETE_PROMOTION  = "DELETE_PROMOTION";
export const SET_SIMULATION_DATA_PROMOTIONS  = "SET_SIMULATION_DATA_PROMOTIONS";



