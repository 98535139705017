import React from 'react';
import styled from 'styled-components';
import { styled as MuiStyled } from '@mui/material/styles';

const LogoWrapperTheme = MuiStyled('div')(({ theme }) => ({
    'path, circle':{
        fill: theme.palette.custom.secondary,
    }
}));
//<style>.i{fill:none;}.j{fill:#cdcace;}.k{opacity:.26;}.l{clip-path:url(#e);}</style>
const LogoWrapper = styled.div`
    path, circle{
        fill: ${props => props.color || ""} !important;
        fill:none,
        opacity:.26,
    }
`;

const BackgroundPinionsSVG= ({color=null}) => {
    return (
        <LogoWrapperTheme>
            <LogoWrapper color={color}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 328.3 327.64">
    <defs>
       
        <clipPath id="e">
            <rect className="i" width="328.3" height="327.64" />
        </clipPath>
    </defs>
    <g id="a" />
    <g id="b">
        <g id="c">
            <g id="d" className="k">
                <g className="l">
                    <g id="f">
                        <path id="g" className="j" d="M199.74,192.22l22.98-39.72c1.43-2.45,.87-5.58-1.31-7.38l-24.3-19.04c.52-3.7,.8-7.43,.82-11.16-.03-3.79-.3-7.57-.82-11.32l24.3-19.04c2.18-1.81,2.74-4.93,1.31-7.39l-22.98-39.72c-1.44-2.43-4.42-3.47-7.06-2.46l-28.56,11.49c-5.92-4.62-12.44-8.43-19.37-11.33l-4.26-30.37c-.57-2.73-2.95-4.71-5.74-4.76h-45.96c-2.84,.06-5.25,2.12-5.75,4.92l-4.26,30.37c-6.91,2.93-13.42,6.74-19.37,11.33l-28.57-11.49c-2.64-1-5.62,.04-7.06,2.46L.8,77.32c-1.43,2.45-.87,5.57,1.31,7.38l24.3,19.04c-.52,3.7-.8,7.43-.82,11.16,.03,3.79,.3,7.57,.82,11.33L2.11,145.27c-2.19,1.81-2.74,4.93-1.31,7.38l22.98,39.72c1.44,2.43,4.42,3.46,7.06,2.46l28.56-11.49c5.92,4.62,12.44,8.43,19.37,11.32l4.27,30.37c.57,2.73,2.95,4.71,5.75,4.76h45.96c2.84-.06,5.25-2.12,5.74-4.92l4.27-30.37c6.91-2.94,13.42-6.74,19.37-11.33l28.56,11.49c2.64,1,5.62-.04,7.06-2.46m-87.98-44.49c-18.13,0-32.83-14.69-32.83-32.82s14.69-32.83,32.82-32.83c18.13,0,32.83,14.69,32.83,32.82h0c-.05,18.11-14.72,32.78-32.83,32.83" />
                        <path id="h" className="j" d="M326.96,273.63l-15.76-12.15c.39-2.39,.61-4.8,.66-7.22,.03-2.42-.19-4.84-.66-7.22l15.59-12.15c1.39-1.18,1.74-3.18,.82-4.76l-14.77-25.44c-.94-1.58-2.87-2.27-4.6-1.64l-18.22,7.39c-3.78-3-7.99-5.44-12.47-7.22l-2.78-19.37c-.13-1.82-1.63-3.24-3.45-3.28h-29.38c-1.82-.04-3.38,1.31-3.61,3.12l-2.79,19.37c-4.44,1.89-8.63,4.31-12.47,7.22l-18.22-7.39c-1.73-.65-3.68,.04-4.6,1.64l-14.77,25.44c-.92,1.58-.57,3.58,.82,4.76l15.6,12.15c-.66,4.79-.66,9.65,0,14.44l-15.6,12.15c-1.39,1.18-1.74,3.18-.82,4.76l14.77,25.44c.94,1.58,2.87,2.27,4.6,1.64l18.22-7.39c3.78,3,7.99,5.44,12.47,7.22l2.79,19.37c.28,1.78,1.81,3.1,3.61,3.12h29.38c1.82,.04,3.38-1.31,3.61-3.12l2.79-19.37c4.4-1.85,8.54-4.28,12.31-7.22l18.38,7.39c1.73,.65,3.68-.04,4.6-1.64l14.77-25.44c.91-1.52,.56-3.48-.82-4.6m-70.42,2.62c-12.24,0-22.16-9.92-22.16-22.16s9.92-22.16,22.16-22.16c12.24,0,22.16,9.92,22.16,22.16,0,12.24-9.92,22.16-22.16,22.16" />
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
            </LogoWrapper>
        </LogoWrapperTheme>
    )
}

export default BackgroundPinionsSVG;
