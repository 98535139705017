/* eslint-disable default-case */
import produce from "immer";
import {
  getLoadTree,
  setFilterTree,
  getFilterTree,
  clearFilterTree,
} from "./actions";
import * as types from "./types";
import { initialState } from "./states";

export const tree = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    let newSettings = [];
    switch (type) {
      case getLoadTree.REQUEST:
        draft.loading = true;
        break;
      case getLoadTree.SUCCESS:
        break;
      case getLoadTree.FULFILL:
        draft.loading = false;
        break;
      case types.SET_CATEGORY_FILTERED_TREE:
        draft.filterModalSubcategories = "All";
        draft.currentCategoryFiltered = payload;
        newSettings = [];
        if (draft.currentCategoryFiltered) {
          draft.currentCategoryFiltered.subcategory_ids.forEach(
            (subcategory) => {
              newSettings.push({
                from: `category-${draft.currentCategoryFiltered.id}`,
                to: `subcategory-${subcategory.id}`,
                positions: {
                  start: {
                    side: "right",
                    indent: 20,
                  },
                  end: {
                    side: "left",
                  },
                },
                style: "subcategory-color-" + `${subcategory.id}`,
              });

              let brands = draft.currentCategoryFiltered.brand_ids.filter(
                (brand) => subcategory.brand_ids.includes(brand.id)
              );

              brands.forEach((brand) => {
                newSettings.push({
                  from: `subcategory-${subcategory.id}`,
                  to: `subcategory-${subcategory.id}-brand-${brand.id}`,
                  positions: {
                    start: {
                      side: "right",
                      indent: 20,
                    },
                    end: {
                      side: "left",
                    },
                  },
                  style: `subcategory-color-${subcategory.id}`,
                });

                let skus = draft.currentCategoryFiltered.sku_ids.filter(
                  (sku) =>
                    sku.subcategory_id[0] === subcategory.id &&
                    sku.brand_id[0] === brand.id
                );

                skus.map((sku) => {
                  newSettings.push({
                    from: `subcategory-${subcategory.id}-brand-${brand.id}`,
                    to: `subcategory-${subcategory.id}-brand-${brand.id}-sku-${sku.id}`,
                    positions: {
                      start: {
                        side: "right",
                        indent: 20,
                      },
                      end: {
                        side: "left",
                      },
                    },
                    style: `brand-color-${brand.id}`,
                  });
                });
              });
            }
          );
        }
        draft.settings = newSettings;

        break;
      case types.SET_FILTER_TREE:
        const filterSubcategories = (filter, category) => {
          if (filter !== "All") {
            return category.subcategory_ids.filter((x) =>
              filter.includes(x.id)
            );
          } else {
            return category.subcategory_ids;
          }
        };
        const filterSkus = (filter, category) => {
          if (filter !== "All") {
            return category.sku_ids.filter((x) =>
              filter.includes(x.subcategory_id[0])
            );
          } else {
            return category.sku_ids;
          }
        };

        draft.filterModalSubcategories = payload.filter;
        const subcategories = filterSubcategories(
          payload.filter,
          payload.currentCategory
        );
        const skus = filterSkus(payload.filter, payload.currentCategory);
        draft.currentCategoryFiltered = {
          ...payload.currentCategory,
          subcategory_ids: subcategories,
          sku_ids: skus,
        };

        newSettings = [];
        if (draft.currentCategoryFiltered) {
          draft.currentCategoryFiltered.subcategory_ids.forEach(
            (subcategory) => {
              newSettings.push({
                from: `category-${draft.currentCategoryFiltered.id}`,
                to: `subcategory-${subcategory.id}`,
                positions: {
                  start: {
                    side: "right",
                    indent: 20,
                  },
                  end: {
                    side: "left",
                  },
                },
                style: "subcategory-color-" + `${subcategory.id}`,
              });

              let brands = draft.currentCategoryFiltered.brand_ids.filter(
                (brand) => subcategory.brand_ids.includes(brand.id)
              );

              brands.forEach((brand) => {
                newSettings.push({
                  from: `subcategory-${subcategory.id}`,
                  to: `subcategory-${subcategory.id}-brand-${brand.id}`,
                  positions: {
                    start: {
                      side: "right",
                      indent: 20,
                    },
                    end: {
                      side: "left",
                    },
                  },
                  style: `subcategory-color-${subcategory.id}`,
                });

                let skus = draft.currentCategoryFiltered.sku_ids.filter(
                  (sku) =>
                    sku.subcategory_id[0] === subcategory.id &&
                    sku.brand_id[0] === brand.id
                );

                skus.map((sku) => {
                  newSettings.push({
                    from: `subcategory-${subcategory.id}-brand-${brand.id}`,
                    to: `subcategory-${subcategory.id}-brand-${brand.id}-sku-${sku.id}`,
                    positions: {
                      start: {
                        side: "right",
                        indent: 20,
                      },
                      end: {
                        side: "left",
                      },
                    },
                    style: `brand-color-${brand.id}`,
                  });
                });
              });
            }
          );
        }
        draft.settings = newSettings;

        break;
    }
  });
