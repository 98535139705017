import * as type from './types';
export function changePassword(action) {
    return {
      type: type.CHANGE_PASSWORD_REQUEST,
      payload: action
    };
  }

  export function clearResponseUsers() {
    return {
      type: type.CLEAR_RESPONSE_USERS,
    };
  }