import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CommonStepList from "../../CommonStepList";
import { CustomModal } from "../../../../Components/Modal";
import ItemRowWithOptions from "../../../../Components/Table/ItemRowWithOptionsVariant";

import Typography from "@mui/material/Typography";
import ItemColumn from "../../../../Components/Table/ItemColumn";
import FormVariable from "./FormVariable";
import { scopeValues, periodWidthValues } from "../../dataCommon";
import { setCurrentVariable } from "../../../../Redux/modules/variables/actions";
const ListVariables = ({
  variables,
  onVariableUpdated,
  onVariableDeleted,
  category,
}) => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [searchVariable, setSearchVariable] = useState("");
  const [configModal, setConfigModal] = useState({
    title: "",
    type: "edit",
  });
  const columnNames = ["", "", "", "", ""];
  const onItemVariableEdit = (variable) => {
    dispatch(setCurrentVariable(variable));

    setOpenModal(!openModal);
    setConfigModal({
      title: t("initial_setup.modals.edit_variable"),
      type: "edit",
      cancelText: t("components_globals.button_cancel"),
      acceptText: t("components_globals.button_update"),
    });
  };
  const onItemVariableDelete = (variable) => {
    dispatch(setCurrentVariable(variable));
    setOpenModal(!openModal);
    setConfigModal({
      title: (
        <div>
          {t("initial_setup.modals.delete_variable")}
          <br />
          <Typography variant="body" fontWeight={600}>
            {variable.name}
          </Typography>
        </div>
      ),

      type: "delete",
      cancelText: t("components_globals.button_cancel"),
      acceptText: t("components_globals.button_ok"),
    });
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
    dispatch(setCurrentVariable(null));
  };

  const handleCancelModal = () => {
    setOpenModal(!openModal);
    dispatch(setCurrentVariable(null));
  };

  const handleOkModal = () => {
    switch (configModal.type) {
      case "edit":
        onVariableUpdated();
        break;

      case "delete":
        onVariableDeleted();
        break;
      default:
        break;
    }
    setOpenModal(!openModal);
  };

  const onChangeSearchVariable = (filter) => {
    if (filter) {
      setSearchVariable(filter);
    } else {
      setSearchVariable("");
    }
  };
  const filterVariable = (filter) => {
    if (filter) {
      return variables.filter(
        (variable) =>
          variable.name.toUpperCase().indexOf(filter.toUpperCase()) !== -1
      );
    } else {
      return variables;
    }
  };
  const variablesFiltered = filterVariable(searchVariable);

  const listVariableItems = variablesFiltered.map((variable, index) => {
    return (
      <ItemRowWithOptions
        key={index}
        index={index}
        withCommonButtons={true}
        onItemEdit={() => onItemVariableEdit(variable)}
        tooltipTitleEdit={t("components_globals.button_edit")}
        onItemDelete={() => onItemVariableDelete(variable)}
        tooltipTitleDelete={t("components_globals.button_remove")}
        columnsNumber={5}
        name={variable.name}
      >
        <ItemColumn>
          <Typography
            variant="caption"
            gutterBottom
            component="div"
            textAlign={"center"}
          >
            {t("initial_setup.forms.common_inputs.level_affects")}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            component="div"
            textAlign={"center"}
            fontWeight="bold"
          >
            {variable.scope &&
              t(
                `initial_setup.forms.common_inputs.${
                  scopeValues.find((x) => x.value === variable.scope).label
                }`
              )}
          </Typography>
        </ItemColumn>
        <ItemColumn>
          <Typography
            variant="caption"
            gutterBottom
            component="div"
            textAlign={"center"}
          >
            {t("initial_setup.forms.common_inputs.periodWidthValues")}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            component="div"
            textAlign={"center"}
            fontWeight="bold"
          >
            {variable.period_width &&
              t(
                `initial_setup.forms.common_inputs.${
                  periodWidthValues.find(
                    (x) => x.value === variable.period_width
                  ).label
                }`
              )}
          </Typography>
        </ItemColumn>
        <ItemColumn>
          <Typography
            variant="caption"
            gutterBottom
            component="div"
            textAlign={"center"}
          >
            {t("initial_setup.forms.common_inputs.date_first_measurement")}
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            component="div"
            textAlign={"center"}
            fontWeight="bold"
          >
            {variable.first_period && variable.first_period}
          </Typography>
        </ItemColumn>
      </ItemRowWithOptions>
    );
  });
  return (
    <div>
      <CommonStepList
        listItems={listVariableItems}
        onChangeSearchItem={onChangeSearchVariable}
        filterFn={filterVariable}
        columnNames={columnNames}
        paginationTitle={t("initial_setup.step_5.stage_1.title")}
      ></CommonStepList>
      <CustomModal
        customWidth="750px"
        title={configModal.title}
        type={configModal.type}
        open={openModal}
        onCloseModal={handleCloseModal}
        onCancelModal={handleCancelModal}
        onOkModal={handleOkModal}
        cancelText={configModal.cancelText}
        acceptText={configModal.acceptText}
      >
        {configModal.type === "edit" && (
          <FormVariable category={category} />
        )}
      </CustomModal>
    </div>
  );
};

export default ListVariables;
