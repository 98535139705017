import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "../../../../Components/TextField";
import { useTranslation } from "react-i18next";
import { SaveIcon } from "../../../../Components/CustomIcons";
import Button from "../../../../Components/Button";
import { Box, Typography, Grid } from "@mui/material/";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpPopover from "./HelpPopover";
import { useSnackbar } from "notistack";
import { CustomModal } from "../../../../Components/Modal";
import CustomDataPicker from "../../../../Components/Datapicker";
import { SweepingIcon } from "../../../../Components/CustomIcons";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import {
  scopeValues,
  periodWidthValues,
  completionRuleValues,
} from "../../dataCommon";
import LevelAffects from "./LevelAffects";
import {
  calculateRows,
  getDefaultFirstPeriod,
} from "../../../../Utils/commonWithDates";
import TableVariableValues from "./TableVariableValues";
import {
  getCurrentValues,
  getVariablesFormData,
} from "../../../../Redux/modules/variables/selectors";
import {
  getValuesByVariableId,
  resetVariableValues,
  syncVariableValues,
  setVariableValues,
  setVariableTempValues,
  setFormDataVariables,
} from "../../../../Redux/modules/variables/actions";

const FormVariable = ({ onVariableCreated, onVariableUpdated, category }) => {
  const [t, i18n] = useTranslation("global");

  const [configModal, setConfigModal] = useState({
    open: false,
    title: t("initial_setup.add_data"),
    type: "empty",
  });
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [recalculateRows, setRecalculateRows] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const rows = useSelector(getCurrentValues);
  const tempVariable = useSelector(getVariablesFormData);

  useEffect(() => {
    if (tempVariable.id !== 0) {
      dispatch(
        getValuesByVariableId({
          id: tempVariable.id,
          period: tempVariable.periodWidth,
        })
      );
    } else {
      const defaultFirstPeriod = getDefaultFirstPeriod(
        tempVariable.periodWidth
      );
      if (tempVariable.periodWidth === "weeks") {
        checkSundayByWeeks(tempVariable.periodWidth, defaultFirstPeriod);
      } else {
        dispatch(
          setFormDataVariables({
            propertyName: "firstPeriod",
            value: defaultFirstPeriod,
          })
        );
      }
    }
  }, []);

  const handleCloseModal = () => {
    dispatch(resetVariableValues());
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  const handleCancelModal = () => {
    dispatch(resetVariableValues());
    setConfigModal({ ...configModal, open: !configModal.open });
  };
  const isFirstPeriodValid = (firstPeriod) => {
    try {
      new Date(firstPeriod);
      return true;
    } catch (error) {
      return false;
    }
  };

  const openModalAddData = () => {
    if (rows.length === 0 || recalculateRows) {
      if (
        tempVariable.periodWidth &&
        tempVariable.firstPeriod &&
        isFirstPeriodValid(tempVariable.firstPeriod)
      ) {
        const rowsData = calculateRows(
          tempVariable.periodWidth,
          tempVariable.firstPeriod
        );
        if (rowsData.length > 0) {
          dispatch(setVariableTempValues(rowsData));
          setRecalculateRows(true);
          setConfigModal({ ...configModal, open: !configModal.open });
        }
      }
    } else {
      setConfigModal({ ...configModal, open: !configModal.open });
    }
  };

  const handleOkModal = () => {
    dispatch(syncVariableValues());
    setConfigModal({ ...configModal, open: !configModal.open });
    setRecalculateRows(false);
  };
  const handleCleanValues = () => {
    let newRows = [];
    if (tempVariable && rows) {
      newRows = rows.map((item) => {
        return {
          ...item,
          value: null,
        };
      });
    } else {
      newRows = calculateRows(
        tempVariable.periodWidth,
        tempVariable.firstPeriod
      );
    }

    dispatch(setVariableTempValues(newRows));
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const isNew = tempVariable.id !== 0 ? false : true;

  const isValidForm = () => {
    if (!tempVariable.periodWidth || !tempVariable.firstPeriod) {
      enqueueSnackbar("Error", { variant: "error" });
      return false;
    }
    return true;
  };

  const getLevelAffects = (selected) => {
    let affectsBy = { items: [], name: "" };

    switch (selected) {
      case "pricing.sku.subcategory":
        affectsBy.items = category.subcategory_ids;
        break;
      case "pricing.sku":
        affectsBy.items = category.sku_ids;
        break;
      case "pricing.sku.owner":
        affectsBy.items = category.owner_ids;
        break;
      case "pricing.sku.brand":
        affectsBy.items = category.brand_ids;
        break;
      default:
        break;
    }
    affectsBy.name = scopeValues.find((x) => x.value === selected).label;
    return affectsBy;
  };

  const handleInputChangeLevelAffect = (e) => {
    const selected = e.target.value;
    dispatch(
      setFormDataVariables({
        propertyName: "influencesFor",
        value: [],
      })
    );
    dispatch(
      setFormDataVariables({
        propertyName: "scope",
        value: selected,
      })
    );
  };

  const handleInputChange = (e) => {
    if (e.target.name === "periodWidth") {
      if (e.target.value === "weeks") {
        checkSundayByWeeks(e.target.value, tempVariable.firstPeriod);
      }
      if (e.target.value !== tempVariable.periodWidth) {
        setRecalculateRows(true);
      }
    }

    dispatch(
      setFormDataVariables({
        propertyName: e.target.name,
        value: e.target.value,
      })
    );
  };

  const handleItemSelected = (e) => {
    const selectedItems = e.target.value;
    dispatch(
      setFormDataVariables({
        propertyName: "influencesFor",
        value: selectedItems,
      })
    );
  };

  const onSubmitVariable = () => {
    if (isValidForm()) {
      isNew
        ? onVariableCreated({ ...tempVariable })
        : onVariableUpdated({ ...tempVariable });
      //onReset();
    }
  };
  const checkSundayByWeeks = (period, firstPeriod) => {
    if (period === "weeks") {
      let newStartDate = new Date(firstPeriod);

      let day = newStartDate.getDay();
      if (day !== 0 && day < 4) {
        newStartDate.setDate(newStartDate.getDate() - day);
      } else if (day !== 0 && day >= 4) {
        newStartDate.setDate(newStartDate.getDate() + (7 - day));
        if (newStartDate >= new Date()) {
          newStartDate = new Date(firstPeriod);
          newStartDate.setDate(newStartDate.getDate() - day);
        }
      }
      dispatch(
        setFormDataVariables({
          propertyName: "firstPeriod",
          value: newStartDate,
        })
      );
    }
  };
  const affectsBy = getLevelAffects(tempVariable.scope);

  const filterCompletionRules = () => {
    if (["days", "weeks"].includes(tempVariable.periodWidth)) {
      return completionRuleValues.filter((x) =>
        ["mean", "mode", "median", "sum"].includes(x.value)
      );
    }
    if (["quarter", "biannual", "years"].includes(tempVariable.periodWidth))
      return completionRuleValues.filter((x) =>
        ["repeat", "apportionment"].includes(x.value)
      );

    if (["months"].includes(tempVariable.periodWidth))
      return completionRuleValues.filter((x) => ["repeat"].includes(x.value));

    return completionRuleValues;
  };

  const completionRuleValuesFiltered = filterCompletionRules();

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          alignItems: "center",
          gap: "0.5rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          variant="third"
          name="name"
          label={`${t("initial_setup.forms.common_inputs.name")}`}
          value={tempVariable.name}
          onChange={handleInputChange}
        />
        <TextField
          variant="third"
          name="abbreviation"
          label={`${t("initial_setup.forms.common_inputs.abbreviation")}`}
          value={tempVariable.abbreviation}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <ErrorOutlineIcon
                  layout="intrinsic"
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onClick={handlePopoverOpen}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  style={{ marginLeft: "5px" }}
                  width={25}
                  height={25}
                />
                <HelpPopover
                  open={open}
                  anchorEl={anchorEl}
                  onPopoverClose={handlePopoverClose}
                  message={t("initial_setup.messages.info_abbreviation")}
                ></HelpPopover>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="third"
          name="scope"
          label={`${t("initial_setup.forms.common_inputs.level_affects")}`}
          value={tempVariable.scope}
          onChange={handleInputChangeLevelAffect}
          select
        >
          {scopeValues.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {`${t("initial_setup.forms.common_inputs." + option.label)}`}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          variant="third"
          name="periodWidth"
          label={`${t("initial_setup.forms.common_inputs.periodWidthValues")}`}
          value={tempVariable.periodWidth}
          onChange={handleInputChange}
          select
        >
          {periodWidthValues.map((option) => (
            <MenuItem key={option.code} value={option.value}>
              {`${t("initial_setup.forms.common_inputs." + option.label)}`}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          variant="third"
          name="completionRule"
          label={`${t("initial_setup.forms.common_inputs.business_rule")}`}
          value={tempVariable.completionRule}
          onChange={handleInputChange}
          select
        >
          {completionRuleValuesFiltered.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {`${t("initial_setup.forms.common_inputs." + option.label)}`}
            </MenuItem>
          ))}
        </TextField>
        <CustomDataPicker
          {...(!["days", "weeks"].includes(tempVariable.periodWidth)
            ? { views: ["year", "month"] }
            : {})}
          label={t("initial_setup.forms.common_inputs.first_period")}
          value={tempVariable.firstPeriod}
          onChange={(newValue) => {
            let firstPeriod = newValue;
            const defaultFirstPeriod = getDefaultFirstPeriod(
              tempVariable.periodWidth
            );

            if (firstPeriod > defaultFirstPeriod) {
              firstPeriod = defaultFirstPeriod;
            }

            if (tempVariable.periodWidth === "weeks") {
              checkSundayByWeeks(tempVariable.periodWidth, firstPeriod);
            } else {
              dispatch(
                setFormDataVariables({
                  propertyName: "firstPeriod",
                  value: firstPeriod,
                })
              );
            }

            if (firstPeriod !== tempVariable.firstPeriod) {
              setRecalculateRows(true);
            }
          }}
        />
      </div>
      <Box
        sx={{
          background: "#CDCACE ",
          height: "9rem",
          with: "100%",
          borderRadius: "0.5rem",
          opacity: "1",
          display: "flex",
          direction: "row",
          justifyContent: "space-around",
          alignItems: "center",
          padding: "2rem",
          marginBottom: "1rem",
        }}
      >
        <Grid container direction="column">
          <Grid xs={8}>
            <Typography variant="h6" fontWeight={700} opacity={1}>
              {`${t("initial_setup.messages.affectation_environment")}`}
            </Typography>
            <Typography>
              {`${t("initial_setup.messages.select_level_affects")}`}
            </Typography>

            {affectsBy && affectsBy.items.length ? (
              <LevelAffects
                items={affectsBy.items}
                influencesFor={tempVariable.influencesFor}
                nameSelected={affectsBy.name}
                handleItemSelected={handleItemSelected}
              />
            ) : (
              "N/A"
            )}
          </Grid>
        </Grid>

        <Button
          fullWidth
          style={{
            maxWidth: "150px",
            height: "2.5rem",
            fontSize: "1rem",
            fontWeight: "bold",
          }}
          variant="primaryVariant"
          onClick={openModalAddData}
        >
          {t("components_globals.button_add_data")}
        </Button>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        marginTop="1rem"
      >
        {isNew && (
          <Button
            fullWidth
            style={{ maxWidth: "150px", height: "2.5rem", fontWeight: "bold" }}
            variant="thirdVariant"
            disabled={!tempVariable.name || !tempVariable.scope}
            onClick={onSubmitVariable}
          >
            <SaveIcon />
            <span style={{ paddingLeft: "5px" }}>
              {t("components_globals.button_save")}
            </span>
          </Button>
        )}
      </Grid>
      <CustomModal
        customWidth="350px"
        title={configModal.title}
        type={configModal.type}
        open={configModal.open}
        onCloseModal={handleCloseModal}
        headerOptions={
          <ButtonItemColumn
            onClick={handleCleanValues}
            tooltip={t("components_globals.button_clear")}
            icon={
              <SweepingIcon
                className="iconButton strokeLigth"
                sx={{ margin: "0 1rem 0 0" }}
              />
            }
          ></ButtonItemColumn>
        }
      >
        {configModal.type === "empty" && (
          <TableVariableValues
            labels={["date", tempVariable.name ? tempVariable.name : "value"]}
            onOkModal={handleOkModal}
            onCancelModal={handleCancelModal}
          />
        )}
      </CustomModal>
    </div>
  );
};

export default FormVariable;
