import { SvgIcon } from "@mui/material";
import React from "react";

export const QuestionIcon = (props) => {
  const classQuestionIcon = {
    fill: "none",
    strokeWidth: "1.5px",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: "10",
    ...props.style,
  };
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          style={classQuestionIcon}
          d="M9 9.00004C9.00011 8.45007 9.15139 7.91071 9.43732 7.44091C9.72325 6.97111 10.1328 6.58895 10.6213 6.33619C11.1097 6.08344 11.6583 5.96982 12.2069 6.00776C12.7556 6.04569 13.2833 6.23372 13.7323 6.55129C14.1813 6.86886 14.5344 7.30375 14.7529 7.80843C14.9715 8.31311 15.0471 8.86816 14.9715 9.41291C14.8959 9.95766 14.6721 10.4712 14.3244 10.8973C13.9767 11.3234 13.5185 11.6457 13 11.829C12.7074 11.9325 12.4541 12.1241 12.275 12.3775C12.0959 12.631 11.9998 12.9337 12 13.244V14.25"
        />
        <path
          style={classQuestionIcon}
          d="M12 18C11.7929 18 11.625 17.8321 11.625 17.625C11.625 17.4179 11.7929 17.25 12 17.25"
        />
        <path
          style={classQuestionIcon}
          d="M12 18C12.2071 18 12.375 17.8321 12.375 17.625C12.375 17.4179 12.2071 17.25 12 17.25"
        />
        <path
          style={classQuestionIcon}
          d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
        />
      </g>
    </SvgIcon>
  );
};
