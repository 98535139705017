import { Box, TableBody, Typography } from "@mui/material/";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import { SweepingIcon } from "../../../../Components/CustomIcons/SweepingIcon";
import { clearTrendSimulation } from "../../../../Redux/modules/what-if/trend/actions";
import {
  getColumnsTableTrend,
  getSelectedSkus,
  getTrendLoaded,
} from "../../../../Redux/modules/what-if/trend/selectors";
import { getMonthName } from "../../../../Utils";
import { getDateValues } from "../../../../Utils/commonWithDates";
import { ButtonsCell } from "../OptimalPrices/StyleTable";
import { RowSimulation } from "./RowSimulation";
import { RowSimulationStk } from "./RowSimulationStk";
import { RowSimulationStkEnv } from "./RowSimulationStkEnv";
import { RowSimulationStkQk } from "./RowSimulationStkQk";
import SaveMenu from "../../../../Components/SaveMenu";
import * as XLSX from "xlsx";
import {
  CustomTable,
  TitleCell,
  TitleGrayCell,
  Wrapper,
} from "./StyleTableTrend";

const TableTrend = () => {
  const dispatch = useDispatch();
  const trendTableSimulation = useRef(null);
  const [t, i18n] = useTranslation("global");
  const trendLoaded = useSelector(getTrendLoaded);
  const skus = useSelector(getSelectedSkus);
  const columns = useSelector(getColumnsTableTrend);
  const env = trendLoaded?.env;
  const colSpan = columns.length * 3;
  const secondHeaderTitles = ["base", "forecast", "d"];
  const pageStyle = `
  @media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  }
`;
  const handlePdf = useReactToPrint({
    content: () => trendTableSimulation.current,
    documentTitle: "Table-Simulation-",
    pageStyle: pageStyle,
  });

  const exportToExcel = () => {
    let [table] = document.getElementsByClassName("tableTrend");
    let today = new Date();
    try {
      let excelTable = document.createElement("table");
      excelTable.innerHTML = table.innerHTML.trim();
      let firstCell = excelTable.querySelector(".first-cell");
      firstCell.innerHTML = "";
      let collapses = excelTable.querySelectorAll(".collapse-tr");
      for (let index = 0; index < collapses.length; index++) {
        const collapse = collapses[index];
        let expandTr = collapse.previousSibling;
        const tbody = collapse.querySelector("tbody");
        if (tbody) {
          let inputs = tbody.querySelectorAll("input");
          for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            const parentTd = input.parentNode;
            parentTd.className = "";
            parentTd.innerHTML = input.value;
          }
          const rows = tbody.innerHTML;
          expandTr.insertAdjacentHTML("afterend", rows);
        }
        collapses[index].parentNode.removeChild(collapses[index]);
      }

      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.table_to_sheet(excelTable);

      XLSX.utils.book_append_sheet(wb, ws, "Table");
      XLSX.writeFile(
        wb,
        `export_table_trend_${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDay()}${today.getTime()}.xlsx`
      );
    } catch (error) {}
  };

  const handleDownloadExcel = () => {
    exportToExcel();
  };

  const buildHeaders = (column) => {
    return (
      column &&
      column.map((col, index) => {
        const objDate = getDateValues(col.date);
        return (
          <TitleCell key={`first-${col.index}-${objDate.month}-${index}`} colSpan={3}>
            <span className="space-cell">
              {`${t(`months_names.${getMonthName(objDate.month - 1)}`)} ${
                objDate.year
              }`}
            </span>
          </TitleCell>
        );
      })
    );
  };

  const buildSecondHeaders = (column) => {
    const buildSubHeaders = (col, index) => {
      return secondHeaderTitles.map((title, indexCol) => (
        <TitleGrayCell key={`${col.index}-${indexCol}-${index}`}>
          <div className={`space-gray`}>
            <span key={`${title}-${indexCol}-${index}`}>
              {t(`price_management.trend.${title}`)}
            </span>
          </div>
        </TitleGrayCell>
      ));
    };
    return columns.map((col, index) => buildSubHeaders(col, col.index));
  };

  const buildRows = (currentSkus) => {
    const numCols = [
      "price",
      "distribution",
      "cost",
      "quantity",
      "som",
      "value",
    ];
    let rows = [];
    for (const col of numCols) {
      let template = <></>;
      switch (col) {
        case "som":
          template = (
            <RowSimulation colSpan={colSpan} skus={skus} name={"som"} />
          );
          break;
        case "value":
          template = (
            <RowSimulation colSpan={colSpan} skus={skus} name={"value"} />
          );
          break;
        case "quantity":
          template = (
            <RowSimulationStkQk
              colSpan={colSpan}
              skus={skus}
              name={"quantity"}
            />
          );
          break;

        default:
          template = (
            <RowSimulationStk colSpan={colSpan} skus={currentSkus} name={col} />
          );
      }
      rows.push(template);
    }

    return rows;
  };

  return (
    <Wrapper>
      <Box
        sx={{
          background: (theme) => `${theme.palette.custom.secondary}`,
          color: (theme) => `${theme.palette.custom.primary}`,
          height: "3.5rem",
          borderRadius: "0.5rem 0.5rem 0rem 0rem",
        }}
      >
        <Typography
          sx={{
            padding: "1rem  0rem 0rem 2rem",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          {t("price_management.trend.table")}
        </Typography>
      </Box>
      <div style={{ padding: "1rem" }}>
        <TableContainer sx={{ maxHeight: 640 }}>
          <CustomTable className="tableTrend" ref={trendTableSimulation}>
            <TableHead
              style={{
                zIndex: 100,
                position: "sticky",
                top: 0,
                backgroundColor: "#fff",
              }}
            >
              <TableRow
                style={{
                  marginTop: "20px",
                  zIndex: 100,
                  backgroundColor: "#fff",
                }}
              >
                <ButtonsCell
                  className="first-cell"
                  rowSpan={2}
                  sx={{ border: "none" }}
                >
                  <div
                    className="first-cell"
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <ButtonItemColumn
                      style={{ height: "29px" }}
                      tooltip={t("components_globals.button_clear")}
                      onClick={() =>
                        dispatch(clearTrendSimulation("simulationTrend"))
                      }
                      icon={
                        <SweepingIcon
                          className="iconButtonPrimary strokeLigth"
                          sx={{ fontSize: "1.6rem" }}
                        />
                      }
                    ></ButtonItemColumn>
                    <SaveMenu
                      handlePdf={handlePdf}
                      handleExcel={handleDownloadExcel}
                    />
                  </div>
                </ButtonsCell>
                {buildHeaders(columns)}
              </TableRow>
              <TableRow>{buildSecondHeaders(columns)}</TableRow>
            </TableHead>
            <TableBody>
              <RowSimulationStkEnv
                colSpan={colSpan}
                environmentVariables={env}
                type={"value"}
              />
              {skus.length > 0 && buildRows(skus)}
            </TableBody>
          </CustomTable>
        </TableContainer>
      </div>
    </Wrapper>
  );
};

export default TableTrend;
