import React, {useState} from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Typography, Box } from "@mui/material/";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "0.5em",

  width: "100%",
  marginBottom: theme.spacing(2),

  border: `1px solid ${theme.palette.custom.text}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.2rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  height: "4rem",
  flexDirection: "row",

  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
    marginRight: `${theme.spacing(1)}`,
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(3),
    backgroundColor: theme.palette.custom.background,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0, 4, 2, 4),
}));

export default function AccordionProps({ open=false, id,  accordionTitle, accordionDetails, }) {
  const [expanded, setExpanded] = useState(open);

  const handleChange = (id) => (event, newExpanded) => {
    setExpanded(newExpanded ? id : false);
  };

  return (
    <Box
      sx={{
        borderRadius: "2rem",
        boxShadow: "0px 3px 6px #00000029",
        width: "100%",
        marginBottom: "1.2em",
      }}
    >
      <Accordion
        expanded={expanded === id || expanded}
        onChange={handleChange(id)}
      >
        <AccordionSummary aria-controls="panel-content" id= {`panel-header-${id}`}>
          {accordionTitle}
        </AccordionSummary>
        <AccordionDetails>{accordionDetails}</AccordionDetails>
      </Accordion>
    </Box>
  );
}
