import React from "react";
import { SvgIcon } from "@mui/material";

export function ExpandIcon(props) {
  const classExpand = {
    fill: "none",
    strokeWidth: "1px",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    ...props.style,
  };
  return (
    <SvgIcon {...props} viewBox="0 0 9.156 19.977">
      <g transform="translate(0 -0.048)">
        <path
          d="M10.416,19.978A.416.416,0,0,1,10,19.561V.417a.416.416,0,1,1,.832,0V19.561a.416.416,0,0,1-.416.416"
          transform="translate(-5.838 0.047)"
          style={classExpand}
        />
        <path
          d="M8.74,4.994a.415.415,0,0,1-.294-.122L4.578,1,.71,4.872a.416.416,0,0,1-.588-.588L4.284.122a.416.416,0,0,1,.588,0L9.034,4.284a.416.416,0,0,1-.294.71"
          transform="translate(0 0.048)"
          style={classExpand}
        />
        <path
          d="M4.578,41a.415.415,0,0,1-.294-.122L.122,36.711a.416.416,0,1,1,.588-.588L4.578,39.99l3.868-3.868a.416.416,0,1,1,.588.588L4.872,40.873A.415.415,0,0,1,4.578,41"
          transform="translate(0 -20.97)"
          style={classExpand}
        />
      </g>
    </SvgIcon>
  );
}
