import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import { SweepingIcon } from "../../../../Components/CustomIcons/SweepingIcon";
import { clearOptimalPrice } from "../../../../Redux/modules/what-if/optimal-prices/actions";
import { getOptimalsLoaded } from "../../../../Redux/modules/what-if/optimal-prices/selectors";
import { RowCategorySku } from "./RowCategorySku";
import SaveMenu from "./SaveMenu";
import {
  ArrowCell,
  ButtonsCell,
  CustomTable,
  TitleCell,
  TitleGrayCell,
} from "./StyleTable";

export default function TableOptimal() {
  const handlePdf = useReactToPrint({
    content: () => optimalTableSimulationRef.current,
    documentTitle: "optimal-prices-",
    pageStyle: { with: "100%" },
  });
  const tableMaximunSalesCols = [
    "table_maximun_sales.col_price",
    "table_maximun_sales.col_qty",
    "table_maximun_sales.col_sales",
    "table_maximun_sales.col_profit",
  ];
  const tableOptimalPricePointCols = [
    "table_optimal_price_point.col_price",
    "table_optimal_price_point.col_qty",
    "table_optimal_price_point.col_sales",
    "table_optimal_price_point.col_profit",
  ];
  const tableMaximumProfitCols = [
    "table_maximum_profit.col_price",
    "table_maximum_profit.col_qty",
    "table_maximum_profit.col_sales",
    "table_maximum_profit.col_profit",
  ];
  const optimals = useSelector(getOptimalsLoaded);
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const [qtyType, setQtyType] = useState("quantity_kg");
  const [mainCol, setMainCol] = useState("elasticity");
  const optimalTableSimulationRef = useRef(null);

  const exportToExcel = () => {
    let [table] = document.getElementsByClassName("tableOptimal");
    let today = new Date();
    try {
      let excelTable = document.createElement("table");
      excelTable.innerHTML = table.innerHTML.trim();
      let firstCell = excelTable.querySelector(".first-cell");
      firstCell.innerHTML = "";
      let collapses = excelTable.querySelectorAll(".collapse-tr");
      for (let index = 0; index < collapses.length; index++) {
        const collapse = collapses[index];
        let expandTr = collapse.previousSibling;
        const tbody = collapse.querySelector("tbody");
        if (tbody) {
          let inputs = tbody.querySelectorAll("input");
          for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            const parentTd = input.parentNode;
            parentTd.className = "";
            parentTd.innerHTML = input.value;
          }
          const rows = tbody.innerHTML;
          expandTr.insertAdjacentHTML("afterend", rows);
        }
        collapses[index].parentNode.removeChild(collapses[index]);
      }

      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.table_to_sheet(excelTable);

      XLSX.utils.book_append_sheet(wb, ws, "Table");
      XLSX.writeFile(
        wb,
        `export_table_optimals_${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDay()}-${today.getTime()}.xlsx`
      );
    } catch (error) {}
  };

  const handleDownloadExcel = () => {
    exportToExcel();
  };

  const buildHeaders = (items) => {
    return items.map((col) => {
      if (col.includes("qty")) {
        return (
          <TitleGrayCell style={{ backgroundColor: "#fff" }}>
            <div
              className="space-gray"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setQtyType(
                  qtyType === "quantity_kg" ? "quantity" : "quantity_kg"
                );
              }}
            >
              <Tooltip title="Click para cambiar">
                <span>{qtyType === "quantity_kg" ? "QTY (Kg)" : "QTY"}</span>
              </Tooltip>
            </div>
          </TitleGrayCell>
        );
      } else {
        return (
          <TitleGrayCell style={{ backgroundColor: "#fff" }}>
            <div className="space-gray">
              <span>{t(`price_management.optimal_prices.${col}`)}</span>
            </div>
          </TitleGrayCell>
        );
      }
    });
  };

  return (
    <TableContainer
      sx={{ maxHeight: 440, backgroundColor: "#fff", width: "100%" }}
    >
      <CustomTable
        className="tableOptimal"
        ref={optimalTableSimulationRef}
        sx={{ maxHeight: 440, width: "100%" }}
      >
        <TableHead
          style={{
            zIndex: 100,
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
          }}
        >
          <TableRow style={{ zIndex: 100, backgroundColor: "#fff" }}>
            <ButtonsCell rowSpan={2} sx={{ border: "none" }} colSpan={1}>
              <div
                className="first-cell"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <ButtonItemColumn
                  style={{ height: "29px" }}
                  tooltip={t("components_globals.button_clear")}
                  onClick={() => {
                    dispatch(clearOptimalPrice());
                  }}
                  icon={
                    <SweepingIcon
                      className="iconButtonPrimary strokeLigth"
                      sx={{ fontSize: "1.6rem" }}
                    />
                  }
                ></ButtonItemColumn>
                <SaveMenu
                  handlePdf={handlePdf}
                  handleExcel={handleDownloadExcel}
                />
              </div>
            </ButtonsCell>
            <ArrowCell colSpan={2} sx={{ border: "none" }}></ArrowCell>
            <TitleCell colSpan={4} style={{ backgroundColor: "#fff" }}>
              <span className="space-cell">
                {t("price_management.optimal_prices.table_maximun_sales.title")}
              </span>
            </TitleCell>
            <TitleCell colSpan={4} style={{ backgroundColor: "#fff" }}>
              <span className="space-cell">
                {t(
                  "price_management.optimal_prices.table_optimal_price_point.title"
                )}
              </span>
            </TitleCell>
            <TitleCell colSpan={4} style={{ backgroundColor: "#fff" }}>
              <span className="space-cell">
                {t(
                  "price_management.optimal_prices.table_maximum_profit.title"
                )}
              </span>
            </TitleCell>
          </TableRow>
          <TableRow style={{ zIndex: 100, backgroundColor: "#fff" }}>
            <ArrowCell colSpan={1} sx={{ border: "none" }}></ArrowCell>

            <TitleGrayCell style={{ backgroundColor: "#fff" }}>
              <div
                className="space-cell"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMainCol(mainCol === "elasticity" ? "b" : "elasticity");
                }}
              >
                <Tooltip title="Click para cambiar">
                  <span
                    style={{
                      fontSize: mainCol === "elasticity" ? "2rem" : "1rem",
                    }}
                  >
                    {mainCol === "elasticity" ? "e" : "Index"}
                  </span>
                </Tooltip>
              </div>
            </TitleGrayCell>
            {buildHeaders(tableMaximunSalesCols)}
            {buildHeaders(tableOptimalPricePointCols)}
            {buildHeaders(tableMaximumProfitCols)}
          </TableRow>
        </TableHead>
        <TableBody>
          {optimals.subcategory_ids?.map((row) => (
            <RowCategorySku
              key={row.id}
              row={row}
              qtyType={qtyType}
              mainCol={mainCol}
            />
          ))}
        </TableBody>
      </CustomTable>
    </TableContainer>
  );
}
