import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Table from "../../../../Components/CustomTable";
import {
  getCurvesFunctionType, getCurvesLoaded
} from "../../../../Redux/modules/what-if/curves/selectors";
import { formatNumber, truncateNumber } from "../../../../Utils";

const TableCurvesSimulator = () => {
  const [t, i18n] = useTranslation("global");

  const dataSimulation = useSelector(getCurvesLoaded);
  const dataSerie1 = dataSimulation[0];
  const dataSerie2 = dataSimulation[1];
  const curveFunction = useSelector(getCurvesFunctionType);

  return (
    <Table
      className="tableGraph"
      style={{
        marginBlock: "3rem",
        width: "100%",
        height: "100%",
        marginInline: "auto",
      }}
    >
      <thead>
        <tr>
          <th colSpan={5} className="align-left text-secondary">
            {t("price_management.curves_component.optimal_prices")}
          </th>
        </tr>
        <tr>
          <th className="joined joined-first secondary"></th>

          <th className="joined secondary">
            {t("price_management.curves_component.scenery1")}
          </th>
          <th className="joined secondary">
            {t("price_management.curves_component.scenery2")}
          </th>
          <th className="joined joined-last secondary">
            {t("price_management.curves_component.var") + " " + "%"}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="bold">{t("price_management.curves_component.PMV")}</td>
          <td className="joined background">
            {formatNumber(
              truncateNumber(dataSerie1?.[curveFunction].optimals.price[0])
            )}
          </td>
          <td>
            {formatNumber(
              truncateNumber(dataSerie2?.[curveFunction].optimals.price[0])
            )}
          </td>
          <td>
            {dataSerie2
              ? `${truncateNumber(
                  ((dataSerie2?.[curveFunction].optimals.price[0] -
                    dataSerie1?.[curveFunction].optimals.price[0]) /
                    dataSerie1?.[curveFunction].optimals.price[0]) *
                    100,
                  0
                )}%`
              : "0.00%"}
          </td>
        </tr>
        <tr>
          <td className="joined joined-first primary bold">P*</td>
          <td className="joined primary">
            {formatNumber(
              truncateNumber(dataSerie1?.[curveFunction].optimal_point.price)
            )}
          </td>
          <td className="joined primary">
            {formatNumber(
              truncateNumber(dataSerie2?.[curveFunction].optimal_point.price)
            )}
          </td>
          <td className="joined primary">
            {dataSerie2
              ? `${truncateNumber(
                  ((dataSerie2?.[curveFunction].optimal_point.price -
                    dataSerie1?.[curveFunction].optimal_point.price) /
                    dataSerie1?.[curveFunction].optimal_point.price) *
                    100,
                  0
                )}%`
              : "0.00%"}
          </td>
        </tr>
        <tr>
          <td className="joined background bold">
            {t("price_management.curves_component.PMR")}
          </td>
          <td>
            {formatNumber(
              truncateNumber(dataSerie1?.[curveFunction].max_profit_point.price)
            )}
          </td>
          <td>
            {formatNumber(
              truncateNumber(dataSerie2?.[curveFunction].max_profit_point.price)
            )}
          </td>
          <td>
            {dataSerie2
              ? `${truncateNumber(
                  ((dataSerie2?.[curveFunction].max_profit_point.price -
                    dataSerie1?.[curveFunction].max_profit_point.price) /
                    dataSerie1?.[curveFunction].max_profit_point.price) *
                    100,
                  0
                )}%`
              : "0.00%"}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TableCurvesSimulator;
