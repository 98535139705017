export const getState = (state) => state.layout;

export const getIsLoading = (state) => getState(state).loading;
export const getCurrentPage = (state) => getState(state).currentPage;
export const getCurrentPath = (state) => {
  return {
    view: getState(state).currentView,
    page: getState(state).currentPage,
  };
};

export const getMainMenuItems = (state) => getState(state).menuOptions;

export const showCategorySelectInHeader = (state) =>
  getState(state).showCategorySelectInHeader;
