import React from "react";
import { SvgIcon } from "@mui/material";

export function ViewOffIcon(props) {
  const classViewOffIcon = {
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5px",
  };
  return (
    <SvgIcon {... props} viewBox="0 0 48 48">
      <g transform="matrix(2,0,0,2,0,0)">
        <g>
          <line
            x1="2.78"
            y1="21"
            x2="21.53"
            y2="3"
            style={classViewOffIcon}
            
          ></line>
          <path
            d="M9,19.05a9.91,9.91,0,0,0,3,.45c4.1.07,8.26-2.81,10.82-5.64a1.65,1.65,0,0,0,0-2.22,20.06,20.06,0,0,0-3.07-2.76"
            style={classViewOffIcon}
          ></path>
          <path
            d="M17.09,7.27A11.31,11.31,0,0,0,12,6C8,5.93,3.8,8.75,1.18,11.64a1.65,1.65,0,0,0,0,2.22,20,20,0,0,0,4.93,4"
            style={classViewOffIcon}
          ></path>
          <path
            d="M9,15.07a3.85,3.85,0,0,1,5.5-5.28"
            style={classViewOffIcon}
          ></path>
          <path
            d="M15.75,12.75h0A3.75,3.75,0,0,1,12,16.5"
            style={classViewOffIcon}
          ></path>
        </g>
      </g>
    </SvgIcon>
  );
}
