import * as XLSX from "xlsx";

export const randomNumber = (start, end) =>
  Math.floor(Math.random() * end) + start;

export const randomColor = () => {
  let color = Math.floor(Math.random() * 16777215).toString(16);
  return `#${color}`;
};

export const truncateNumber = (number, toFixed = 2) => {
  try {
    // return parseFloat((number+'').toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    return number.toFixed(toFixed);
  } catch {
    return parseFloat(number);
  }
};

export const median = (arr) => {
  const mid = Math.floor(arr.length / 2),
    nums = [...arr].sort((a, b) => a - b);
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const contDots = (value) => {
  var dots = [];
  for (var i = 0; i < value.length; i++) {
    if (value[i] === ".") dots.push(i);
  }
  return dots.length;
};

export const formatNumber = (number, decimals = false) => {
  let nf = new Intl.NumberFormat("en-US");
  if (`${number}` === "NaN") return 0;
  let newNumber = number;
  if (decimals) {
    newNumber = truncateNumber(number);
  }
  return nf.format(newNumber.toString());
};

export const exportToExcel = (name, excelTable,sheetName="Table", type = "sheet", sheetNames ) => {
  let today = new Date();
  let wb = XLSX.utils.book_new();
  let ws = null;
  switch (type) {
    case "sheet":
      ws = XLSX.utils.table_to_sheet(excelTable);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      break;
    case "sheets":
      for (let index = 0; index < excelTable.length; index++) {
        const table = excelTable[index];
        ws = XLSX.utils.table_to_sheet(table);
        XLSX.utils.book_append_sheet(wb, ws, sheetNames[index]);
      }
      break;
  }

  XLSX.writeFile(
    wb,
    `export_table_${name}_${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDay()}${today.getTime()}.xlsx`
  );
};
export const getMonthName = (number) => {
  let monthNames = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  return monthNames[number];
};

export function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export function PrintElem(elem, styles = "") {
  var mywindow = window.open("", "PRINT", "height=400,width=600");

  mywindow.document.write("<html><head><title>" + document.title + "</title>");
  mywindow.document.write("</head><body >");
  mywindow.document.write(`<style type="text/css">${styles}</style>`);
  //mywindow.document.write('<h1>' + document.title  + '</h1>');
  mywindow.document.write(elem.innerHTML);
  mywindow.document.write("</body></html>");

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/

  mywindow.print();
  mywindow.close();

  return true;
}

export function isNumber(n) {
  if (n.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) return true;
  return false;
}

export const exportExel = (table, name, errorHandler = () => {}) => {
  let today = new Date();
  try {
    let excelTable = document.createElement("table");
    excelTable.innerHTML = table.innerHTML.trim();

    var cells = [
      ...excelTable.querySelectorAll(".spacer-vertical"),
      ...excelTable.querySelectorAll(".spacer"),
      ...excelTable.querySelectorAll(".client-avatar"),
      ...excelTable.querySelectorAll(".divider"),
      ...excelTable.querySelectorAll(".input"),
    ];

    for (var i = 0; i < cells.length; i++) {
      cells[i].parentNode.removeChild(cells[i]);
    }

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.table_to_sheet(excelTable);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(
      wb,
      `${name}_${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDay()}.xlsx`
    );
  } catch (error) {
    errorHandler(error);
  }
};

export function getTableStyle(theme) {
  let styles;
  styles = `
      *{
          -webkit-print-color-adjust: exact;
      }
      body {
          margin: 0;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
      }
      @media print{
          @page {
              size: Tabloid landscape;
          }
          body { color-adjust: exact; }
          .expand-icon, .input{
              display:none;
          }
      }
      tr th{
          white-space: nowrap;
      }
      tr td{
          text-align:center;
          white-space: nowrap;
          padding: .25em;
          color: ${theme.palette.custom.secondary};
          background-color: ${theme.palette.custom.background};
      }
      .empty{
          opacity: 0;
      }
      .rounded{
          padding: .5em;
          border-radius: .25em;
          color: ${theme.palette.custom.secondary};
          background-color: ${theme.palette.custom.text};
      }
      .spacer{
        width: .5em;
      }
      .spacer-vertical{
        height:.5em;
      }
      .joined{
          background-color: ${theme.palette.custom.text};
          color: ${theme.palette.custom.secondary};
          padding: .5em;
      }
      .joined-first{
        border-radius: .25em 0 0 .25em;
      }
      .joined-last{
        border-radius: 0 .25em .25em 0;
      }
      .primary{
          background-color: ${theme.palette.custom.primary};
          color: ${theme.palette.custom.secondary},
      }
      .secondary{
          background-color: ${theme.palette.custom.secondary};
          color: ${theme.palette.custom.primary};
      }
      .transparent{
          background-color: transparent;
          color: ${theme.palette.custom.secondary};
      }
      .transparent > td{
          background-color: transparent;
      }
      .text{
          color: ${theme.palette.custom.secondary};
          background-color: ${theme.palette.custom.text + " !important"};
          border-radius: .25em;
      }
      .text-primary{
          color: ${theme.palette.custom.primary};
      }
      .background{
          background-color: ${theme.palette.custom.background};
      }
      .divider{
          height: 1px;
          background-color: ${theme.palette.custom.text};
          border-radius: .25em;
          padding: 0;
      }
  `;
  return styles;
}
export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const commaSeparateNumber = (val) => {
  let numberForm = parseFloat(val);

  // remove sign if negative
  let sign = 1;
  if (val < 0) {
    sign = -1;
    numberForm = -numberForm;
    val = numberForm.toString();
  }

  // trim the number decimal point if it exists
  let num = val.includes(".") ? val.split(".")[0] : val;

  while (/(\d+)(\d{3})/.test(num)) {
    // insert comma to 4th last position to the match number
    num = num.replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
  }

  // add number after decimal point
  if (val.includes(".")) {
    num = num + "." + val.split(".")[1];
  }

  // return result with - sign if negative
  return sign < 0 ? "-" + num : num;
};
