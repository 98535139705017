import { SvgIcon } from "@mui/material";

export function SweepingIcon(props) {
  const style = {
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    ...props.style,
  };
  return (
    <SvgIcon {...props}>
      <g transform="matrix(1,0,0,1,0,0)">
        <defs></defs>
        <path className="a" d="M10.969,22.58A8.541,8.541,0,0,1,8.619,20" />
        <path style={style} d="M14.188,21.2a8.545,8.545,0,0,1-2.531-2.406" />
        <path
          style={style}
          d="M18.357,19.1s-4.115,1.04-6.947-5.634a4.229,4.229,0,0,0-5.545-2.242c-3.988,1.659-2.241,5.545-2.241,5.545s.822,4.993,3.434,6.51C12.132,23.436,18.357,19.1,18.357,19.1Z"
        />
        <line style={style} x1="4.029" y1="16.697" x2="11.41" y2="13.705" />
        <path
          style={style}
          d="M4.438,12.1C2.407,8.854,1.78,2.83,1.71,2.085A1.25,1.25,0,0,1,4.2,1.849c.181,1.889.964,6.968,2.486,9.121"
        />
        <circle style={style} cx="18.42" cy="9.701" r="0.5" />
        <circle style={style} cx="21.796" cy="12.951" r="0.5" />
        <circle style={style} cx="16.844" cy="14.205" r="0.5" />
      </g>
    </SvgIcon>
  );
}
