import produce from "immer";
import { initialState } from "./states";
import * as types from "./types";
export const volumes = (state = initialState, { payload, type }) =>
  produce(state, (draft) => {
    switch (type) {

      case types.CLEAR_RESPONSE_VOLUMES:
        draft.response = { error: null, message: null, result: null };
        break;
    }
  });
