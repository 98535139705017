import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import {
  Grid,
  Modal,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Menu, MenuItem } from "@mui/material/";
import { styled } from "@mui/material/styles";
import { createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createFileName, useScreenshot } from "use-react-screenshot";
import * as XLSX from "xlsx";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import {
  DownloadIcon,
  SaveIcon,
  TableIcon,
  ViewIcon,
} from "../../../../Components/CustomIcons";
import {
  getCurvesCurrentGraph,
  getCurvesFunctionType,
} from "../../../../Redux/modules/what-if/curves/selectors";
import Graph from "./Graph";
import SelectLabels from "./SelectLabels";
import TableCurvesSimulator from "./TableCurvesSimulator";

const Wrapper = styled("div")(({ theme }) => ({
  boxShadow: "0px 3px 6px #00000029 ",
  borderRadius: "10px",
  height: "648px",
  scrollBehavior: "smooth",
}));

const CssBox = styled(Paper)(({ theme }) => ({
  position: "fixed",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  placeContent: "center",
  backgroundColor: theme.palette.custom.secondary,

  "& .close-btn": {
    display: "flex",
    gap: "1rem",
    color: theme.palette.custom.primary,
    position: "absolute",
    top: "1em",
    right: "1em",
    cursor: "pointer",
  },
}));

const GraphCurves = () => {
  const [showLabels, setShowLabels] = useState({
    Esc1: true,
    Esc2: true,
  });
  const [showGraphTable, setShowGraphTable] = useState(true);
  const [t, i18n] = useTranslation("global");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const refTable = createRef(null);
  const refImg = createRef(null);
  const currentGraph = useSelector(getCurvesCurrentGraph);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpg",
    quality: 1.0,
  });

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleGraphTable = () => {
    setShowGraphTable(!showGraphTable);
  };

  const handleChange = (event) => {
    setShowLabels({
      ...showLabels,
      [event.target.name]: event.target.checked,
    });
  };

  const selectedFunction = useSelector(getCurvesFunctionType);

  const downloadGraph = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    const annotations = document.querySelectorAll(".annotation");
    for (let index = 0; index < annotations.length; index++) {
      const element = annotations[index];
      element.parentNode.removeChild(element);
    }
    a.click();
  };

  const downloadScreenshotGraph = () => {
    const divGraph = document.querySelector(".mainGraphDiv");
    const mainContentGraph = document.querySelector(".mainContentGraph");
    const labelsy = divGraph.querySelectorAll(
      ".apexcharts-yaxis-annotation-label"
    );
    const labelsx = divGraph.querySelectorAll(
      ".apexcharts-xaxis-annotation-label"
    );
    const annotations = document.createElement("div");
    annotations.style["position"] = "relative";
    annotations.style["display"] = "inline";
    annotations.style["top"] = `${0}px`;
    annotations.style["left"] = `${0}px`;
    for (let index = 0; index < labelsy.length; index++) {
      const pointerAnotationY = document.createElement("div");
      const labely = labelsy[index];
      pointerAnotationY.innerHTML = labely.innerHTML;
      pointerAnotationY.style["position"] = "relative";
      pointerAnotationY.style["display"] = "inline";
      pointerAnotationY.className = "annotation";
      pointerAnotationY.style["top"] = `${
        parseInt(labely.y.baseVal[0].value) + 17
      }px`;
      pointerAnotationY.style["left"] = labelsy.length > 1 ? "5px" : "30px";
      pointerAnotationY.style["font-weight"] = `600`;
      pointerAnotationY.style["font-size"] = `0.87em`;
      pointerAnotationY.style["width"] = `auto`;
      pointerAnotationY.style["height"] = `auto`;
      annotations.insertAdjacentHTML("afterbegin", pointerAnotationY.outerHTML);
      labely.style["visibility"] = "hidden";
    }
    for (let index = 0; index < labelsx.length; index++) {
      const labelx = labelsx[index];
      const pointerAnotationX = document.createElement("div");
      pointerAnotationX.innerHTML = labelx.innerHTML;
      pointerAnotationX.style["position"] = "relative";
      pointerAnotationX.style["display"] = "inline";
      pointerAnotationX.className = "annotation";
      pointerAnotationX.style["top"] = `${
        parseInt(labelx.y.baseVal[0].value) + 16
      }px`;
      pointerAnotationX.style["left"] =
        labelsx.length > 1
          ? `${parseInt(labelx.x.baseVal[0].value) + 82}px`
          : `${parseInt(labelx.x.baseVal[0].value) + 102}px`;

      pointerAnotationX.style["font-weight"] = `600`;
      pointerAnotationX.style["font-size"] = `0.87em`;
      pointerAnotationX.style["width"] = `auto`;
      pointerAnotationX.style["height"] = `auto`;
      annotations.insertAdjacentHTML("afterbegin", pointerAnotationX.outerHTML);
      labelx.style["visibility"] = "hidden";
    }
    mainContentGraph.insertAdjacentHTML("afterbegin", annotations.outerHTML);
    takeScreenShot(divGraph).then(downloadGraph);
  };

  const handleCloseDownloadMenu = () => {
    setAnchorEl(null);
  };

  const downloadTable = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const exportToExcel = () => {
    let [table] = document.getElementsByClassName("tableGraph");
    let today = new Date();
    try {
      let excelTable = document.createElement("table");
      excelTable.innerHTML = table.innerHTML.trim();
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.table_to_sheet(excelTable);

      XLSX.utils.book_append_sheet(wb, ws, "Table");
      XLSX.writeFile(
        wb,
        `export_table_graph_curves_${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDay()}-${today.getTime()}.xlsx`
      );
    } catch (error) {}
  };

  const handleOpenDownloadMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadExcel = () => {
    exportToExcel();
  };

  const handleDownloadImage = () => {
    if (!showGraphTable) {
      const downloadScreenshotTable = () =>
        takeScreenShot(refImg.current).then(downloadTable);
      downloadScreenshotTable();
    } else {
      downloadScreenshotGraph();
    }
  };

  const title =
    !currentGraph.x ||
    (currentGraph.x === "quantity") & (currentGraph.y === "price")
      ? t("price_management.curves_component.demand_curve")
      : t(
          `price_management.curves_component.${currentGraph.y}_${currentGraph.x}`
        );

  return (
    <Wrapper>
      <Table id="expanded">
        <TableHead>
          <TableRow
            sx={{
              display: "flex",
              justifyContent: "space-between",
              background: (theme) => `${theme.palette.custom.secondary}`,
            }}
          >
            <TableCell
              align="center"
              colSpan={3}
              sx={{
                border: 0,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  color: (theme) => `${theme.palette.custom.primary}`,
                  textAlign: "left",
                }}
              >
                {title}
              </Typography>
            </TableCell>
            <TableCell
              align="right"
              sx={{
                border: 0,
              }}
            >
              {showGraphTable ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <SelectLabels
                    showLabels={showLabels}
                    handleChange={handleChange}
                  />

                  <ButtonItemColumn
                    onClick={handleGraphTable} //cambia grafica vs tabla
                    tooltip={t("price_management.curves_component.see_table")}
                    icon={
                      <TableIcon className="iconButtonPrimary strokeLigth" />
                    }
                  />
                  <ButtonItemColumn
                    onClick={() => {}}
                    tooltip={t("components_globals.button_save")}
                    icon={
                      <SaveIcon
                        className="iconButtonPrimary strokeLigth"
                        sx={{ fontSize: "2rem" }}
                      />
                    }
                  />
                  <ButtonItemColumn
                    onClick={() => {}}
                    tooltip={t("components_globals.button_download")}
                    icon={
                      <DownloadIcon
                        onClick={downloadScreenshotGraph}
                        className="iconButtonPrimary strokeLigth"
                      />
                    }
                  />
                  <ButtonItemColumn
                    onClick={handleOpen}
                    tooltip={t("components_globals.button_expand")}
                    icon={
                      <ViewIcon
                        className="iconButtonPrimary strokeLigth"
                        sx={{ fontSize: "2rem" }}
                      />
                    }
                  />
                </Grid>
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <ButtonItemColumn
                    onClick={handleGraphTable} //cambia grafica vs tabla
                    tooltip={t("price_management.curves_component.see_graph")}
                    icon={
                      <QueryStatsIcon className="iconButtonPrimary strokeLigth" />
                    }
                  />
                  <ButtonItemColumn
                    onClick={() => {}}
                    tooltip={t("components_globals.button_save")}
                    icon={
                      <SaveIcon
                        className="iconButtonPrimary strokeLigth"
                        sx={{ fontSize: "2rem" }}
                      />
                    }
                  />
                  <div>
                    <ButtonItemColumn
                      onClick={handleOpenDownloadMenu}
                      tooltip={t("components_globals.button_download")}
                      icon={
                        <DownloadIcon
                          sx={{ fontSize: "1.5rem" }}
                          className="iconButtonPrimary strokeLigth"
                        />
                      }
                    />
                    <Menu
                      id="download-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleCloseDownloadMenu}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleDownloadImage}>
                        Download Image
                      </MenuItem>
                      <MenuItem onClick={handleDownloadExcel}>
                        Download Excel
                      </MenuItem>
                    </Menu>
                  </div>
                </Grid>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <div
        className="mainGraphDiv"
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "4px",
        }}
        ref={refImg}
      >
        {showGraphTable ? (
          <Graph
            xyAxis={currentGraph}
            curveFunction={selectedFunction}
            variant={"demand"}
            labelsEsc1={showLabels.Esc1}
            labelsEsc2={showLabels.Esc2}
          />
        ) : (
          <div style={{ width: "80%", height: "60%" }}>
            <TableCurvesSimulator />
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CssBox>
          <div>
            <div className="close-btn">
              <SelectLabels
                showLabels={showLabels}
                handleChange={handleChange}
              />
              <CloseFullscreenIcon
                onClick={handleClose}
                sx={{ fontSize: "1.3rem" }}
              />
            </div>

            <Graph
              xyAxis={currentGraph}
              curveFunction={selectedFunction}
              variant={"fullScreen"}
              labelsEsc1={showLabels.Esc1}
              labelsEsc2={showLabels.Esc2}
            />
          </div>
        </CssBox>
      </Modal>
    </Wrapper>
  );
};

export default GraphCurves;
