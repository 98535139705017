import React from "react";
import { SvgIcon } from "@mui/material";

export function EditIcon(props) {
  const classEditIcon = {
    fill: "none",
    strokelinecap: "round",
    strokelinejoin: "round",
    strokewidth: "1.5",
  };

  return (
    <SvgIcon viewBox="0 0 48 60" {...props}>
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M22.19,1.81a3.639,3.639,0,0,0-5.17.035l-14.5,14.5L.75,23.25l6.905-1.771,14.5-14.5A3.637,3.637,0,0,0,22.19,1.81Z"
          style={classEditIcon}
        />
        <path d="M16.606 2.26L21.74 7.394" style={classEditIcon} />
        <path d="M2.521 16.344L7.66 21.474" style={classEditIcon} />
      </g>
    </SvgIcon>
  );
}
