import * as type from './types';
export function createSku(action) {
  return {
    type: type.CREATE_SKU_REQUEST,
    payload: action,
  };
}
export function getSkusByCategoryId(action) {
  return {
    type: type.GET_SKUS_BY_CATEGORY_ID_REQUEST,
    payload: action,
  };
}

export function updateSku(action) {
  return {
    type: type.UPDATE_SKU_REQUEST,
    payload: action,
  };
}
export function deleteSku(action) {
  return {
    type: type.DELETE_SKU_REQUEST,
    payload: action,
  };
}
export function clearResponseSku() {
  return {
    type: type.CLEAR_RESPONSE_SKU,
  };
}
