export const matrizEState = (state) => state.matrizE;

export const getLoadingMatrizE = (state) => matrizEState(state).loading;

export const getFormDataMatrizE = (state) => matrizEState(state).formData;
export const getSimulationDataMatrizE = (state) =>
  matrizEState(state).simulationData;

export const getMatrizESubcategoryId = (state) =>
  matrizEState(state).formData.subcategoryId;

export const getMatrizELoaded = (state) => matrizEState(state).matrizE;

export const getMatrizESimulationData = (state) =>
  matrizEState(state).formData.simulationMatrizE;

export const getMatrizEFunctionType = (state) =>
  matrizEState(state).formData.typePrice;

export const getVariablesSelected = (state) =>
  matrizEState(state).formData.variablesSelected;

export const getSkuSelected = (state) =>
  matrizEState(state).matrizE.subcategory_ids[0].sku_ids.find(
    (sku) => sku.id === matrizEState(state).formData.skuId
  );

export const getResponseMatrizE = (state) => matrizEState(state).response;

export const getSkuGraph = (state) =>
  matrizEState(state).matrizE.subcategory_ids[0].sku_ids.find(
    (sku) => sku.id === matrizEState(state).formData.skuIdGraph
  );

export const getSelectedSkus = (state) => {
  const currentMatrizE = matrizEState(state).matrizE;
  let filters = matrizEState(state).formData.skuIds;
  const subcategory =
    currentMatrizE?.subcategory_ids && currentMatrizE?.subcategory_ids[0];
  const skus = subcategory?.sku_ids.filter((sku) => filters.includes(sku.id));
  return skus;
};
export const getColumnsTableMatrizE = (state) => {
  const currentMatrizE = matrizEState(state).matrizE;
  const subcategory =
    currentMatrizE?.subcategory_ids && currentMatrizE?.subcategory_ids[0];
  const skus = subcategory?.sku_ids;
  const cols = skus.length > 0 ? skus[0].table : [];
  return cols;
};
