import * as React from "react";
import Popover from "@mui/material/Popover";
import { Grid, styled} from "@mui/material";
import { Box } from "@mui/system";
import { AlertCircleIcon } from "../CustomIcons";

const MenuPopover = ({ message, Icon }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Grid>
      <Grid>
        <Icon
          layout="intrinsic"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onClick={handlePopoverOpen}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          width={25}
          height={25}
        />
      </Grid>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          borderRadius: "30%",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box
          textAlign="center"
          p={0.5}
          sx={{
            width: 200,
            height: 100,
            backgroundColor: "#1F1C36",
            color: "#EBCE75",
            stroke: "#EBCE75",
            padding: "10px",
            fontSize: "12px",
          }}
        >
          <div sx={{  fontSize: "18px",marginTop: "2px"}}>
           <AlertCircleIcon />
          </div>
          <span>
            {message}
          </span>
        </Box>
      </Popover>
    </Grid>
  );
};
export default MenuPopover;
