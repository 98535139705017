import { combineReducers } from "redux";
import app from "./modules/app/reducer";
import auth from "./modules/auth/reducer";
import { layout } from "./modules/layout/reducer";
import { initialSetup } from "./modules/initialSetup/reducer";
import { categories } from "./modules/categories/reducer";
import { categoryData } from "./modules/categoryData/reducer";
import subcategories from "./modules/subcategories/reducer";
import users from "./modules/users/reducer";
import { brands } from "./modules/brands/reducer";
import owners from "./modules/owners/reducer";
import skus from "./modules/skus/reducer";
import units from "./modules/units/reducer";
import { variables } from "./modules/variables/reducer";
import lab from "./modules/lab/reducer";
import relations from "./modules/relations/reducer";
import { customers } from "./modules/customers/reducer";
import { curves } from "./modules/what-if/curves/reducer";
import { optimalPrices } from "./modules/what-if/optimal-prices/reducer";
import { trend } from "./modules/what-if/trend/reducer";
import { tree } from "./modules/tree/reducer";
import { matrizE } from "./modules/sniper/matriz-e/reducer";
import { momentum } from "./modules/sniper/momentum/reducer";
import { promotions } from "./modules/promotion-planning/promotions/reducer";
import { volumes } from "./modules/promotion-planning/volumes/reducer";
import { pl } from "./modules/promotion-planning/pl/reducer";
const rootReducer = combineReducers({
  app: app,
  auth: auth,
  layout: layout,
  initialSetup: initialSetup,
  categories: categories,
  subcategories: subcategories,
  users: users,
  brands: brands,
  owners: owners,
  skus: skus,
  units: units,
  variables: variables,
  lab: lab,
  relations: relations,
  pl: pl,
  customers: customers,
  categoryData: categoryData,
  curves: curves,
  optimalPrices: optimalPrices,
  trend: trend,
  tree: tree,
  matrizE: matrizE,
  momentum: momentum,
  promotions: promotions,
  volumes: volumes,
});

export default rootReducer;
