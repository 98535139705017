import React from "react";
import { SvgIcon } from "@mui/material";

export function StreamLineIcon(props) {
    const classStreamLineIcon = {
      fill: "none",
      stroke: "#1F1C36",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "1.5px",
    };
    return (
      <SvgIcon {...props}>
        <svg viewBox="0 0 48 48">
        <g transform="matrix(2,0,0,2,0,0)">
          <g>
            <path
              d="M8.25,19.75l4-4a.75.75,0,0,1,1.06,0l1.94,1.94a.75.75,0,0,0,1.06,0l4-4"
              style={classStreamLineIcon}
            />
            <rect
              x="0.75"
              y="0.75"
              width="22.5"
              height="22.5"
              rx="1.5"
              style={classStreamLineIcon}
            />
            <circle cx="8.25" cy="8.5" r="4.5" style={classStreamLineIcon} />
            <polyline
              points="8.25 4 8.25 8.5 11.43 11.68"
              style={classStreamLineIcon}
            />
            <line x1="16.5" y1="5.5" x2="20" y2="5.5" style={classStreamLineIcon} />
            <line x1="16.5" y1="9" x2="20" y2="9" style={classStreamLineIcon} />
          </g>
        </g>
        </svg>
      </SvgIcon>
    );
  }