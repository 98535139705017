import * as type from "./types";
import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";

export function createCategory(action) {
  return {
    type: type.CREATE_CATEGORY_REQUEST,
    payload: action,
  };
}
export const getCategories = createRoutine(type.GET_CATEGORIES_REQUEST);

export function getCategoryById(action) {
  return {
    type: type.GET_CATEGORY_BY_ID_REQUEST,
    payload: action,
  };
}
export const clearCategory = createAction(type.CLEAR_CATEGORY);

export const selectCategory = createAction(type.SELECT_CATEGORY);

export const updateCategoryAfterLoadData = createAction(type.UPDATE_CATEGORY_AFTER_LOAD_DATA);

export const setFinishCategory = createRoutine(type.SET_FINISH_CATEGORY);

export const setCurrentCategoryId = createAction(type.SET_CATEGORY_ID);

export function clearResponseCategory() {
  return {
    type: type.CLEAR_RESPONSE_CATEGORY,
  };
}


export function addSubcategoryToCategory(action) {
  return {
    type: type.ADD_SUBCATEGORY_TO_CATEGORY,
    payload: action,
  };
}

export function updateSubcategoryInCategory(action) {
  return {
    type: type.UPDATE_SUBCATEGORY_IN_CATEGORY,
    payload: action,
  };
}
export function deleteSubcategoryOfCategory(action) {
  return {
    type: type.DELETE_SUBCATEGORY_OF_CATEGORY,
    payload: action,
  };
}

export const addOwnerToCategory = createAction(type.ADD_OWNER_TO_CATEGORY);
export const deleteOwnerToCategory = createAction(
  type.DELETE_OWNER_OF_CATEGORY
);

export function deleteOwnerInCategory(action) {
  return {
    type: type.DELETE_OWNER_OF_CATEGORY,
    payload: action,
  };
}
export const addBrandToCategory = createAction(type.ADD_BRAND_TO_CATEGORY);
export const deleteBrandToCategory = createAction(
  type.DELETE_BRAND_OF_CATEGORY
);

export function updateBrandInCategory(action) {
  return {
    type: type.UPDATE_BRAND_IN_CATEGORY,
    payload: action,
  };
}
export function deleteBrandOfCategory(action) {
  return {
    type: type.DELETE_BRAND_OF_CATEGORY,
    payload: action,
  };
}

export function addSkuToCategory(action) {
  return {
    type: type.ADD_SKU_TO_CATEGORY,
    payload: action,
  };
}

export function updateSkuInCategory(action) {
  return {
    type: type.UPDATE_SKU_IN_CATEGORY,
    payload: action,
  };
}
export function deleteSkuOfCategory(action) {
  return {
    type: type.DELETE_SKU_OF_CATEGORY,
    payload: action,
  };
}

export function getDataByCategoryId(payload) {
  return {
    type: type.GET_DATA_BY_CATEGORY_ID_REQUEST,
    payload: payload,
  };
}

export const addValuesToCategory = createAction(type.ADD_VALUES_TO_CATEGORY);