import { createRoutine } from "redux-saga-routines";
import * as type from "./types";

export function createCustomer(action) {
  return {
    type: type.CREATE_CUSTOMER_REQUEST,
    payload: action,
  };
}

export const getCustomers = createRoutine(type.GET_CUSTOMERS);

export function clearResponseCustomer() {
  return {
    type: type.CLEAR_RESPONSE_CUSTOMERS,
  };
}

export function updateCustomer(action) {
  return {
    type: type.UPDATE_CUSTOMER_REQUEST,
    payload: action,
  };
}

export function deleteCustomer (action){
  return {
    type: type.DELETE_CUSTOMER_REQUEST,
    payload: action
  };
}