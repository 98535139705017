import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Stepper from "../../Components/Stepper";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Typography from "@mui/material/Typography";
import { FolderIcon } from "../../Components/CustomIcons";
import { InitialSetupWrapper } from "./styles";
import {
  getCategoryLoaded,
  getResponseCategory,
} from "../../Redux/modules/categories/selectors";
import {
  canCreateCategory,
  getInitialSetupStatus,
  getCurrentStep,
  getCurrentStage,
  getInitialCategoryId,
} from "../../Redux/modules/initialSetup/selectors";
import { getUser } from "../../Redux/modules/app/selectors";
import {
  setStep,
  setStage,
  setStatus,
} from "../../Redux/modules/initialSetup/actions";
import { getLoadOwners } from "../../Redux/modules/owners/actions";
import { getLoadBrands } from "../../Redux/modules/brands/actions";
import {
  getCategoryById,
  setFinishCategory,
} from "../../Redux/modules/categories/actions";
import HeaderStep from "./HeaderStep";
import { CustomModal } from "../../Components/Modal";

const InitialSetup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const initialSetupStatus = useSelector(getInitialSetupStatus);
  const currentCategoryId = useSelector(getInitialCategoryId);
  const currentCategory = useSelector(getCategoryLoaded);
  const canCreateNew = useSelector(canCreateCategory);
  const step = useSelector(getCurrentStep);
  const stage = useSelector(getCurrentStage);
  const user = useSelector(getUser);
  const [configModal, setConfigModal] = useState({
    title: "",
    type: "delete",
    open: false,
    cancelText: t("components_globals.button_cancel"),
    acceptText: t("components_globals.button_ok"),
  });
  useEffect(() => {
    if (currentCategoryId !== 0) {
      dispatch(getCategoryById(currentCategoryId));
      dispatch(getLoadOwners.trigger());
      dispatch(getLoadBrands.trigger());
      dispatch(setStatus("loading"));
    } else {
      dispatch(setStatus("new"));
    }
  }, [currentCategoryId]);

  useEffect(() => {
    if (!canCreateNew) {
      navigate("/");
      return;
    }
  }, [canCreateNew]);

  useEffect(() => {
    if (currentCategory && initialSetupStatus === "loading") {
      dispatch(setStatus("loaded"));
    }
  }, [currentCategory]);

  useEffect(() => {
    if (currentCategory && currentCategory.id === currentCategoryId) {
      switch (initialSetupStatus) {
        case "loaded":
          const stepG = stepValidation();
          dispatch(setStage(stepG.stage));
          dispatch(setStep(stepG.step));
          dispatch(setStatus("entered"));
          break;
      }
    }
  }, [initialSetupStatus]);

  const onFinishStep = () => {
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  const onNextStep = () => {
    if (currentCategory && currentCategory.id === currentCategoryId) {
      const current = getStepById(step);
      const stepG = stepValidation();
      if (current.id === stepG.step && stage === stepG.stage) {
        enqueueSnackbar(t("initial_setup.messages.step_incompleted"), {
          variant: "error",
        });
      } else {
        if (stage === current.stages - 1) {
          if (steps.length - 1 === step) {
            setConfigModal({ ...configModal, open: !configModal.open });
          } else {
            dispatch(setStep(step + 1));
            dispatch(setStage(0));
          }
        } else {
          dispatch(setStep(step));
          dispatch(setStage(stage + 1));
        }
      }
    }
  };

  const TitleWithIcon = ({ icon, title }) => {
    return (
      <Typography
        variant="h5"
        style={{
          display: "flex",
          fontWeight: "700",
          alignItems: "center",
          marginBottom: "1em",
        }}
      >
        {icon}
        {title}
      </Typography>
    );
  };

  const PanelStepView = ({ renderView }) => {
    return renderView;
  };

  const TitleStages = ({ currentStage, stages }) => {
    const getDinamycStyle = (theme, index) => {
      return currentStage !== index
        ? {
            color: theme.palette.custom.text,
            fontWeight: "500",
          }
        : {
            color: theme.palette.custom.thirdVariant,
            fontWeight: "600",
          };
    };

    return (
      <div style={{ display: "flex", marginTop: "10px", marginBottom: "10px" }}>
        {stages.map((title, index) => {
          return (
            <>
              <Typography
                key={index}
                sx={(theme) => getDinamycStyle(theme, index)}
              >
                {title}
              </Typography>
              {index !== stages.length - 1 && (
                <span style={{ color: "#CDCACE", padding: "0px 3px" }}>/</span>
              )}
            </>
          );
        })}
      </div>
    );
  };

  const onBack = (wishedStep, wishedStage) => {
    if (!wishedStep) {
      if (step === 0) {
        navigate(`/`);
      }
      if (currentCategory.state === "done" && step === 1 && stage === 0) {
        navigate(`/`);
      }

      if (step > 0) {
        if (stage === 0) {
          dispatch(setStep(step - 1));
          dispatch(setStage(getStepById(step - 1).stages - 1));
        } else if (stage > 0) {
          dispatch(setStage(stage - 1));
        }
      }
    } else {
      dispatch(setStep(wishedStep));
      dispatch(setStage(wishedStage));
    }
  };

  const onClickOnStep = (step) => {
    dispatch(setStep(step));
    dispatch(setStage(0));
    if (currentCategory && step === 0) {
      dispatch(setStage(1));
    }
  };

  const steps = [
    {
      id: 0,
      stages: 2,
      showStages: true,
      renderView: (
        <Step1
          currentCategory={currentCategory}
          stage={stage}
          onNext={onNextStep}
          onBack={onBack}
        />
      ),
    },
    {
      id: 1,
      stages: 2,
      showStages: true,
      renderView: (
        <Step2
          currentCategory={currentCategory}
          stage={stage}
          onNext={onNextStep}
          onBack={onBack}
        />
      ),
    },
    {
      id: 2,
      stages: 1,
      renderView: (
        <Step3
          currentCategory={currentCategory}
          onNext={onNextStep}
          onBack={onBack}
        />
      ),
    },
    {
      id: 3,
      stages: 1,
      renderView: (
        <Step4
          currentCategory={currentCategory}
          onNext={onNextStep}
          onBack={onBack}
        />
      ),
    },
    {
      id: 4,
      stages: 1,
      renderView: (
        <Step5
          currentCategory={currentCategory}
          onNext={onNextStep}
          onBack={onBack}
        />
      ),
    },
    {
      id: 5,
      stages: 2,
      showStages: true,
      renderView: (
        <Step6
          currentCategory={currentCategory}
          stage={stage}
          onNext={onNextStep}
          onBack={onBack}
          onFinishStep={onFinishStep}
        />
      ),
    },
  ];

  const stepValidation = () => {
    let step = 0;
    let stage = 1;
    if (!currentCategory) {
      step = 0;
      stage = 0;
      return { step, stage };
    }
    if (currentCategory.values && currentCategory.values.length > 0) {
      //Final
      step = 5;
      stage = 1;
      return { step, stage };
    }

    let relations = [];
    for (const sku of currentCategory.sku_ids) {
      relations = relations.concat(sku.affects_ids);
    }

    if (
      currentCategory.variable_ids &&
      currentCategory.variable_ids.length > 0 &&
      relations.length > 0
    ) {
      //Data
      step = 5;
      stage = 0;
      return { step, stage };
    }

    if (currentCategory.sku_ids.length > 0 && relations.length > 0) {
      //Variables
      step = 4;
      stage = 0;
      return { step, stage };
    }

    if (currentCategory.sku_ids.length > 0 && relations.length === 0) {
      //relations
      step = 3;
      stage = 0;
      return { step, stage };
    }

    if (currentCategory.brand_ids && currentCategory.brand_ids.length > 0) {
      //Show SKUs
      step = 2;
      return { step, stage };
    }
    if (currentCategory.owner_ids && currentCategory.owner_ids.length > 0) {
      //Owners
      step = 1;
      stage = 1;
      return { step, stage };
    }

    if (
      currentCategory.subcategory_ids &&
      currentCategory.subcategory_ids.length > 0
    ) {
      //Brands
      step = 1;
      stage = 0;
      return { step, stage };
    }
    if (currentCategory.state !== "done") {
      if (currentCategory) {
        //Subcategories
        step = 0;
        stage = 1;
        return { step, stage };
      }
    }
  };

  const getStepById = (id) => {
    return steps.find((step) => step.id === id);
  };

  const stepsLabels = steps.map(
    (x) => `${t(`initial_setup.step_${x.id + 1}.step_name`)}`
  );

  const getStepTitle = (step) => {
    return `${t("initial_setup.step")} ${step + 1}: ${t(
      `initial_setup.step_${step + 1}.step_name`
    )}`;
  };

  const getStepSubtitle = (step, stage) => {
    return `${t(`initial_setup.step_${step + 1}.stage_${stage + 1}.subtitle`)}`;
  };

  const getTitleStages = (step) => {
    let titles = [];
    for (let index = 1; index <= getStepById(step).stages; index++) {
      titles.push(
        `${t(`initial_setup.step_${step + 1}.stage_${index}.title`)}`
      );
    }
    return titles;
  };
  const currentStep = getStepById(step);
  const currentView = currentStep.renderView;
  const handleCloseModal = () => {
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  const handleCancelModal = () => {
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  const handleOkModal = () => {
    if (["pricing_supervisor", "pricing_admin"].includes(user.userType)) {
      dispatch(setFinishCategory.trigger(currentCategoryId));
      setConfigModal({ ...configModal, open: !configModal.open });
      navigate("/");
      return;
    }
  };

  return (
    <InitialSetupWrapper>
      <TitleWithIcon
        icon={
          <FolderIcon style={{ fontSize: "1.80em", marginRight: ".5em" }} />
        }
        title={`${t("initial_setup.title")} ${
          currentCategory ? `- ${currentCategory.name}` : ""
        }`}
      ></TitleWithIcon>
      <Stepper
        onClickOnStep={onClickOnStep}
        steps={stepsLabels}
        activeStep={step}
      />
      <HeaderStep
        title={getStepTitle(step)}
        subtitle={getStepSubtitle(step, stage)}
        labelSeeChart={t("initial_setup.see_chart")}
      ></HeaderStep>
      {currentStep.stages > 1 && currentStep.showStages && (
        <TitleStages
          currentStage={stage}
          stages={getTitleStages(step)}
        ></TitleStages>
      )}
      <PanelStepView renderView={currentView}></PanelStepView>
      <CustomModal
        customWidth="750px"
        title={configModal.title}
        type={configModal.type}
        open={configModal.open}
        onCloseModal={handleCloseModal}
        onCancelModal={handleCancelModal}
        onOkModal={handleOkModal}
        cancelText={configModal.cancelText}
        acceptText={configModal.acceptText}
      >
        <div>
          <Typography
            sx={{
              textAlign: "center",
              color: (theme) => theme.palette.custom.primary,
            }}
          >
            {t("initial_setup.messages.message_confirm")}
          </Typography>
          <br />
          <div
            variant="body"
            style={{ fontSize: "1.5em", textAlign: "center", fontWeight: 600 }}
          >
            {currentCategory && currentCategory.name}
          </div>
        </div>
      </CustomModal>
    </InitialSetupWrapper>
  );
};

export default InitialSetup;
