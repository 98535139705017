import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table, { tableClasses } from "@mui/material/Table";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import {
  Accordion,
  AccordionSummary,
  accordionSummaryClasses,
  Box,
} from "@mui/material";

export const Wrapper = styled("div")(() => ({
  boxShadow: "0px 3px 6px #00000029 ",
  borderRadius: "10px",
}));

export const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.custom.secondary,
  borderRadius: "10px",

  [`.${accordionSummaryClasses.expanded}`]: {
    margin: "5px 0px",
  },
  [`&.${accordionSummaryClasses.expanded}`]: {
    borderRadius: "10px 10px 0px 0px",
  },
}));

export const CustomBox = styled(Box)(({ theme, backgroundColor }) => ({
  boxShadow: "0px 3px 6px #00000029 ",
  borderRadius: "10px",
  padding: "30px",
  backgroundColor: `${theme.palette.custom[backgroundColor]}`,
}));

export const ButtonsCell = styled(TableCell)(
  ({
    theme,
    backgroundColor = "background",
    minWidth = "150px",
    maxWidth = "150px",
  }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.custom[backgroundColor],
      padding: 0,
      border: "none",
      minWidth: minWidth,
      maxWidth: maxWidth,
      height: "35px",
      left: 0,
      position: "sticky",
      zIndex: 120,
    },
  })
);

export const TitleCell = styled(TableCell)(
  ({ theme, color = "primary", type, minWidth = "100px" }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.custom[color],
      border: "none",
      fontWeight: "bold",
      padding: 0,
      minWidth: minWidth,
      textAlign: "center",
    },
    [`&.${tableCellClasses.head} .space-cell`]: {
      display: "grid",
      placeContent: "center",
      borderRadius: "10px",
      backgroundColor: theme.palette.custom.primary,
      color: theme.palette.custom.secondary,
      height: "35px",
      fontSize: "0.8rem",
      marginInline: "auto",
    },
    [`&.${tableCellClasses.head} .first-cell`]: {
      display: "grid",
      placeContent: "center",
      textAlign: "center",
      backgroundColor: theme.palette.custom.background,
      height: "35px",
      fontSize: "0.8rem",
      minWidth: "300px",
      marginInline: "auto",
    },
  })
);
export const TitleGrayCell = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.custom.secondary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    minWidth: "100px",
  },
  [`&.${tableCellClasses.head} .space-cell`]: {
    display: "grid",
    placeContent: "center",
    borderRadius: "10px",
    textAlign: "center",
    backgroundColor: theme.palette.custom.primary,
    height: "65px",
    fontSize: "3rem",
  },
  [`&.${tableCellClasses.head} .space-gray`]: {
    backgroundColor: "#cdcace",
    display: "grid",
    textAlign: "center",
    placeContent: "center",
    height: "40px",
    borderRadius: "10px",
  },
  [`&.${tableCellClasses.head} .noBorder`]: {
    border: "none",
  },
}));

export const CustomTable = styled(Table)(({ theme }) => ({
  borderCollapse: "separate",
  borderSpacing: "0px  3px",
  [`&.${tableClasses.root}`]: {
    [`tr td:first-child`]: {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    [`tr td:last-child`]: {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
}));

export const StyledTableRowHead = styled(TableRow)(({ theme }) => ({
  background: `${theme.palette.custom.secondary}`,
  color: `${theme.palette.custom.primary}`,
  height: "3.5rem",
}));

export const ArrowCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.custom.secondary,
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    padding: 0,
    border: "none",
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
  },
  [`&.${tableCellClasses.body} button`]: {
    color: theme.palette.custom.primary,
  },
  [`&.${tableCellClasses.body} .title-row`]: {
    color: theme.palette.custom.primary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    height: "35px",
  },
}));

///

export const CustomCell = styled(TableCell)(
  ({
    theme,
    type,
    minWidth = "100px",
    maxWidth = "100px",
    backgroundColor = "background",
    color = "background",
  }) => ({
    [`&.${tableCellClasses.body}`]: {
      minWidth: minWidth,
      maxWidth: maxWidth,
      border: "none",
      color: `${theme.palette.custom[color]}`,
      padding: 0,
      paddingBlock: "6px",
      paddingLeft: type === "large" ? "5px" : "",
      textAlign: type === "large" ? "left" : "center",
      fontWeight: type === "large" ? "bold" : "",
      backgroundColor: `${theme.palette.custom[backgroundColor]}`,
    },
  })
);

export const CustomCellSticky = styled(TableCell)(
  ({
    theme,
    type,
    backgroundColor = "background",
    minWidth = "300px",
    maxWidth = "100px",
    isEllipsis = false,
  }) => ({
    [`&.${tableCellClasses.body}`]: {
      minWidth: minWidth,
      maxWidth: maxWidth,
      border: "none",
      padding: 0,
      paddingBlock: "6px",
      paddingLeft: type === "large" ? "5px" : "",
      textAlign: type === "large" ? "left" : "center",
      fontWeight: type === "large" ? "bold" : "",
      left: 0,
      position: "sticky",
      backgroundColor: `${theme.palette.custom[backgroundColor]}`,
      whiteSpace: isEllipsis ? "normal" : "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

export const ColorCell = styled(TableCell)(
  ({
    theme,
    minWidth = "100px",
    backgroundColor,
    position,
    fontWeight,
    textAlign = "center",
  }) => ({
    [`&.${tableCellClasses.body}`]: {
      minWidth: minWidth,
      border: "none",
      paddingLeft: "15px",
      textAlign: textAlign,
      fontWeight: fontWeight ? fontWeight : "",
      left: 0,
      position: position ? position : "",
      background: theme.palette.custom[backgroundColor],
    },
  })
);

export const CustomTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "transparent",
}));
