export const treeState = (state) => state.tree;

export const getLoadingTree = (state) => treeState(state).loading;

export const getTreeFilter = (state) => treeState(state).filterModalSubcategories;

export const getSettingsTree = (state) => treeState(state).settings;

export const getCurrentCategoryFilteredTree = (state) => treeState(state).currentCategoryFiltered;


