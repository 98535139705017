export const initialSetupState = (state) => state.initialSetup;

export const getLoadingInitialSetup = (state) =>
  initialSetupState(state).loading;

export const getCurrentStep = (state) => initialSetupState(state).step;
export const getCurrentStage = (state) => initialSetupState(state).stage;

export const getInitialSetupStatus = (state) => initialSetupState(state).status;

export const canCreateCategory = (state) =>
  initialSetupState(state).canCreateCategory;


  export const getInitialCategoryId = (state) =>
  initialSetupState(state).currentCategoryId;

  

  export const getResponseInitialSetup = (state) => initialSetupState(state).response;