import { useRef, useState } from "react";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import {
  DeleteIcon,
  EditIcon,
  SweepingIcon,
} from "../../../../Components/CustomIcons";
import { ExpandIcon } from "../../../../Components/CustomIcons/ExpandIcon";
import SaveMenu from "../../../../Components/SaveMenu";
import { RoundedButton } from "./Styles";
import TablePromotion from "./TablePromotion";
import { useTranslation } from "react-i18next";
import { exportToExcel } from "../../../../Utils";

export default function ItemPromotion({
  promotion,
  onItemEdit,
  onDeleteItem,
  onItemClear,
  simulationData,
  setSimulationData
}) {
  const [t, i18n] = useTranslation("global");

  const [isExpandedAllMonth, setIsExpandedAllMonth] = useState(true);
  const [expandedMonths, setExpandedMonths] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const itemRef = useRef(null);
  const handleEditItem = (e) => {
    onItemEdit(promotion);
  };
  const handleRemoveItem = (e) => {
    onDeleteItem(promotion);
  };
  const handleClearItem = (e) => {
    onItemClear(promotion);
  };
  const handleChangeExpandedAllMonths = (item) => {
    setExpandedMonths([]);
    setIsExpandedAllMonth(!isExpandedAllMonth);
  };
  const handleDownloadExcel = () => {
    try {
      let [table] = document.getElementsByClassName(
        `tablePromotion_${promotion.id}`
      );
      let excelTable = document.createElement("table");
      excelTable.innerHTML = table.innerHTML.trim();
      const tbody = excelTable.querySelector("tbody");
      const customerCells = excelTable.getElementsByClassName("customerImage");
      for (let index = 0; index < customerCells.length; index++) {
        const customerName = customerCells[index].getAttribute("customerName");
        customerCells[index].innerHTML = customerName;
      }
      if (tbody) {
        let tr = tbody.querySelectorAll("tr");
        let inputs = tbody.querySelectorAll("input");
        for (let i = 0; i < inputs.length; i++) {
          let input = inputs[i];
          const parentTd = input.parentNode;
          parentTd.className = "";
          parentTd.innerHTML = input.value;
        }
      }
      exportToExcel("promotion", excelTable, promotion.name);
    } catch (error) {}
  };

  return (
    <div
      ref={itemRef}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <ButtonItemColumn
          onClick={handleEditItem}
          tooltip={t("components_globals.button_edit")}
          icon={<EditIcon className="iconButtonPrimary strokeLigth" />}
        ></ButtonItemColumn>
        <ButtonItemColumn
          onClick={handleRemoveItem}
          tooltip={t("components_globals.button_remove")}
          icon={<DeleteIcon className="iconButtonPrimary strokeLigth" />}
        ></ButtonItemColumn>
        <ButtonItemColumn
          onClick={handleClearItem}
          tooltip={t("components_globals.button_clear")}
          icon={<SweepingIcon className="iconButtonPrimary strokeLigth" />}
        ></ButtonItemColumn>

        <SaveMenu buttons={["export"]} list={["excel"]} handleExcel={handleDownloadExcel} />
        <RoundedButton
          backgroundColor="text"
          onClick={handleChangeExpandedAllMonths}
        >
          <ExpandIcon
            style={{ paddingLeft: "2px", fontSize: "1rem" }}
          ></ExpandIcon>
          <span style={{ width: "150px", textAlign: "center" }}>
            {`${
              !isExpandedAllMonth
                ? t("components_globals.button_expand")
                : t("components_globals.button_contract")
            }  ${t("components_globals.months")}`}
          </span>
        </RoundedButton>
      </div>
      <TablePromotion
        key={promotion.id}
        isExpandedAllMonth={isExpandedAllMonth}
        promotion={promotion}
        simulationData={simulationData}
        setSimulationData={setSimulationData}
        expandedMonths={expandedMonths}
        setExpandedMonths={setExpandedMonths}
        showDetails={showDetails}
        setShowDetails={setShowDetails}
      ></TablePromotion>
    </div>
  );
}
