import { useEffect, useState } from "react";

export function useLocalStorage(type) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const _items = JSON.parse(localStorage.getItem(type));
    if (_items) {
      setItems(_items);
    }
  }, []);

  const saveLocalStorage = (_type, data) => {
    localStorage.setItem(_type, JSON.stringify(data));
  };

  return {
    items,
    saveLocalStorage
  };
}
