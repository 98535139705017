import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  GraphBarIcon,
  GraphCurvesIcon,
  PaidIcon,
  TableMoneyIcon,
} from "../../../Components/CustomIcons";
import Tabs from "../../../Components/Tabs";
import { setCurrentView } from "../../../Redux/modules/layout/actions";
import { clearCurvesSimulation } from "../../../Redux/modules/what-if/curves/actions";
import { clearOptimalPrice } from "../../../Redux/modules/what-if/optimal-prices/actions";
import { clearTrendSimulation } from "../../../Redux/modules/what-if/trend/actions";
import Curves from "./Curves";
import OptimalPrices from "./OptimalPrices";
import Trend from "./Trend";

const Wrapper = styled.div`
  .title {
    margin-bottom: 2em;
    .page-name {
      display: flex;
      align-items: flex-start;
      .caption {
      }
    }
  }
`;

const WhatIf = () => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();

  const options = [
    {
      content: <Curves />,
      view: "curves",
      name: `${t("price_management.curves_component.curves")}`,
      icon: <GraphCurvesIcon />,
    },
    {
      content: <OptimalPrices />,
      view: "optimal-prices",
      name: `${t("price_management.optimal_prices.optimal_prices")}`,
      icon: <TableMoneyIcon />,
    },
    {
      content: <Trend />,
      view: "trend",
      name: `${t("price_management.trend.trend")}`,
      icon: <GraphBarIcon />,
    },
  ];

  const handleTabSelected = (index) => {
    const tab = options[index];
    dispatch(setCurrentView(tab.view));
  };

  useEffect(() => {
    handleTabSelected(0);
    return () => {
      dispatch(clearCurvesSimulation());
      dispatch(clearOptimalPrice());
      dispatch(clearTrendSimulation());
    };
  }, []);

  return (
    <Wrapper>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <PaidIcon style={{ fontSize: "40px", marginRight: "10px" }}></PaidIcon>
        <Stack>
          <Typography sx={{ fontSize: "25px", fontWeight: "700" }}>
            {t("drawer_component.prices_lab")}
          </Typography>
          <Typography color="text.primary">
            {t("drawer_component.what_if")}
          </Typography>
        </Stack>
      </div>
      <Tabs
        setTabSelected={handleTabSelected}
        tabs={options}
        defaultValue={0}
      />
    </Wrapper>
  );
};

export default WhatIf;
