import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SaveIcon, DownloadIcon } from "../../../../../Components/CustomIcons";
import ButtonItemColumn from "../../../../../Components/Button/ButtonItemColumn";
import { useTranslation } from "react-i18next";

const SaveMenu = ({ handlePdf, handleExcel, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [t, i18n] = useTranslation("global");
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div style={{ display: "flex", height: "28px" }}>
        <ButtonItemColumn
          onClick={handleOpen}
          tooltip={t("components_globals.button_download")}
          icon={
            <DownloadIcon
              sx={{ fontSize: "1.5rem" }}
              className="iconButtonPrimary strokeLigth"
            />
          }
        ></ButtonItemColumn>
        <ButtonItemColumn
          // onClick={handleSave}
          tooltip={t("components_globals.button_save")}
          icon={
            <SaveIcon
              sx={{ fontSize: "2rem" }}
              className="iconButtonPrimary strokeLigth"
            />
          }
        ></ButtonItemColumn>
        <Menu
          id="download-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handlePdf}>Download PDF</MenuItem>
          <MenuItem onClick={handleExcel}>Download Excel</MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default SaveMenu;
