import { Box } from "@mui/material";
import React from "react";
import Graph from "./Graph";

const ListGraphs = () => {
  
  const graphList = [
    { id: 1, x: "value", y: "price" },
    { id: 2, x: "profit", y: "price" },
    { id: 3, x: "profit", y: "value" },
    { id: 4, x: "value", y: "quantity" },
    { id: 5, x: "profit", y: "quantity" },
    { id: 6, x: "quantity", y: "price" },
  ];

  return (
    <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
      {graphList.map((data) => (
        <Graph
          key={data.id}
          xyAxis={data}
          variant="miniature"
        />
      ))}
    </Box>
  );
};

export default React.memo(ListGraphs);
