import { useSelector } from "react-redux";
import { getSimulationPromotionsLoaded } from "../Redux/modules/promotion-planning/promotions/selectors";
export function useVolumes({ promotion }) {
    const { customers, skus } = useSelector(getSimulationPromotionsLoaded);
  if (!promotion) {
    return {
      id: null,
      customers: [],
    };
  }
  let customersData = [];
  for (const key in promotion.customers) {
    if (Object.hasOwnProperty.call(promotion.customers, key)) {
      let data = [];
      let currentCustomer = promotion.customers[key];
      const customerInfo = customers.find((x) => x.id == key);
      for (const keyC in currentCustomer) {
        if (Object.hasOwnProperty.call(currentCustomer, keyC)) {
          const skuInfo = skus.find((x) => x.id == keyC);
          if (skuInfo) {
            data.push({ id: keyC, items: currentCustomer[keyC], ...skuInfo });
          }
        }
      }
      customersData.push({
        id: key,
        ...customerInfo,
        data,
      });
    }
  }

  return {
    id: promotion.id,
    customers: customersData,
  };
}
