import * as React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)(({ theme }) => ({
  stroke: theme.palette.custom.primary,
  "& label": {
    color: theme.palette.custom.primary,
  },
  "& .MuiInputAdornment-root": {
    color: theme.palette.custom.primary,
  },
  "&:hover label, &:hover .MuiInputAdornment-root": {
    color: theme.palette.custom.primary,
  },
  "& .MuiInput-underline:after": {
    //borderBottomColor: 'green',
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.custom.primary,
      borderWidth: "3px",
      borderRadius: "2em",
      background:"transparent !important"
    },
    "&:hover fieldset": {
      borderColor: theme.palette.custom.primary,
    },
    "&.Mui-focused fieldset": {
      //borderColor: 'green',
    },
  },
  "& .MuiInputBase-input": {
    color: "#fff",
  },

}));

const CssTextFieldPrimary = styled(TextField)(({ theme }) => ({
  "& label": {
    color: theme.palette.custom.third,
  },
  "& .MuiInputAdornment-root": {
    color: theme.palette.custom.third,
  },
  "&:hover label, &:hover .MuiInputAdornment-root": {
    color: theme.palette.custom.primary,
  },
  "& .MuiInput-underline:after": {
    //borderBottomColor: 'green',
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.custom.third,
      borderWidth: "3px",
      borderRadius: "2em",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.custom.primary,
    },
    "&.Mui-focused fieldset": {
      //borderColor: 'green',
    },
  },
}));
const CssTextFieldSecondary = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& label": {
    color: theme.palette.custom.text,
    "&:focused": {
      color: theme.palette.custom.third,
    },
  },
  "& .MuiInputAdornment-root": {
    color: theme.palette.custom.third,
  },
  "&:hover label, &:hover .MuiInputAdornment-root": {
    color: theme.palette.custom.primary,
  },

  "& .MuiOutlinedInput-root": {
    padding: "0em",
    "& fieldset": {
      borderColor: theme.palette.custom.text,
      borderWidth: "2px",
      borderRadius: "2em",
      padding: "0em",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.custom.primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.custom.primary,
      color: theme.palette.custom.primary,
    },
  },
}));

const CssTextFieldThird = styled(TextField)(({ theme }) => ({
  stroke: theme.palette.custom.text,
  "& label": {
    color: theme.palette.custom.text,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    color: theme.palette.custom.thirdVariant,
  },
  "& .MuiInputAdornment-root": {
    color: theme.palette.custom.text,
  },
  "&:hover label, &:hover .MuiInputAdornment-root": {
    color: theme.palette.custom.thirdVariant,
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.custom.text,
      borderWidth: "3px",
      borderRadius: "2em",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.custom.thirdVariant,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.custom.thirdVariant,
    },
  },
}));

const CustomTextField = (props) => {
  const getVariant = (variant) => {
    switch (variant) {
      case "primary":
        return <CssTextFieldPrimary {...props} variant="outlined" />;
      case "contrast":
        return <CssTextField {...props} variant="outlined" />;
      case "secondary":
        return <CssTextFieldSecondary {...props} variant="outlined" />;

      case "third":
        return <CssTextFieldThird {...props} variant="outlined" />;
        case "grid":
        return <CssTextFieldThird {...props} variant="standard" />;
      default:
        return <CssTextFieldPrimary {...props} fullWidth />;
    }
  };
  return getVariant(props.variant ? props.variant : "default");
};

export default CustomTextField;
