import { useSelector } from "react-redux";
import { getCustomersLoaded } from "../Redux/modules/customers/selectors";
export function usePromotions({ categoryId }) {
  const customers = useSelector(getCustomersLoaded);
  let skusFiltered = [];

  if (!categoryId) {
    return {
      id: null,
      customers: [],
    };
  }

  customers.forEach((customer) => {
    if (customer) {
      customer.skus.forEach((sku) => {
        const skuExist = skusFiltered.findIndex((x) => x.id === sku.id);
        if (skuExist === -1 && sku.category_id[0] === categoryId) {
          skusFiltered.push(sku);
        }
      });
    }
  });

  return {
    id: categoryId,
    customers,
    skus: skusFiltered,
  };
}
