import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Typography } from "@mui/material";
import { useEffect } from "react";

const TypographyError = ({
  label,
  regularExpression,
  error,
  setError,
  inputValue,
}) => {
  {
    
  }

  const validationRegularExpression = () => {
    if (regularExpression) {
      if (regularExpression.test(inputValue)) {
        setError((error = false));
      } else {
        setError((error = true));
      }
    }
  };

  useEffect(() => {
    validationRegularExpression();
  }, [inputValue]);

  return (
    <Typography
      variant="caption"
      style={{ display: "flex", alignItems: "center" }}
    >
      {error === true ? (
        <CancelOutlinedIcon
          style={{ marginRight: ".5em", fontSize: "1rem" }}
          color="error"
        />
      ) : (
        <CheckCircleOutlineOutlinedIcon
          style={{ marginRight: ".5em", fontSize: "1rem" }}
          color="success"
        />
      )}
      {label}
    </Typography>
  );
};

export default TypographyError;
