import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import FormSubcategory from "./FormSubcategory";
import FooterStep from "../../FooterStep";
import ListSubcategories from "./ListSubcategories";
import {
  createSubcategory,
  clearResponseSubcategory,
  deleteSubcategory,
  updateSubcategory,
} from "../../../../Redux/modules/subcategories/actions";
import { getResponseSubcategories } from "../../../../Redux/modules/subcategories/selectors";
import { StepWrapper } from "../../styles";

const Subcategory = ({
  categoryId,
  currentSubcategories = [],
  onNext,
  onBack,
}) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const response = useSelector(getResponseSubcategories);

  const onSubcategoryCreated = (newSubcategory) => {
    newSubcategory.categoryId = categoryId;
    dispatch(createSubcategory(newSubcategory));
  };

  const onSubcategoryDeleted = (subcategory) => {
    dispatch(deleteSubcategory({ ...subcategory, categoryId: categoryId }));
  };

  const onSubcategoryUpdated = (subcategory) => {
    dispatch(updateSubcategory({ ...subcategory, categoryId: categoryId }));
  };

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseSubcategory());
    }
  }, [response]);

  return (
    <StepWrapper>
      <FormSubcategory
        onSubcategoryCreated={onSubcategoryCreated}
      ></FormSubcategory>
      <FooterStep onNext={onNext} onBack={onBack}></FooterStep>
      <ListSubcategories
        onSubcategoryDeleted={onSubcategoryDeleted}
        onSubcategoryUpdated={onSubcategoryUpdated}
        subcategories={currentSubcategories}
      ></ListSubcategories>
    </StepWrapper>
  );
};

export default Subcategory;
