import React, { useState } from "react";

import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import StartBox from "./StartBox";
import EndBox from "./EndBox";
import ReactBezier from "../ReactBezier";
import ScreenShot from "../ScreenShot";

const RelatableBoxesWrapper = styled.div`
  path,
  polyline {
    stroke-width: 2.5px !important;
    fill: none !important;
  }
`;

const ColorWrapper = MuiStyled("div")((props) => ({
  "path, polyline": {
    stroke: props.color,
  },
  ...props.colorClasses,
}));

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
    justifyContent: 'space-between'
  },



});

const RelatableBoxes = ({
  dataFrom,
  dataTo,
  onChange,
  onDelete,
  relations = [],
  onRelationComplete,
}) => {
  const classes = useStyles();
  const [draggablesNodes, setDraggablesNodes] = React.useState([]);
  const [settings, setSettings] = React.useState([]);
  const [enter, setEnter] = React.useState({ data: null, flag: false });
  const [colorClasses, setColorClasses] = React.useState({});
  const ref = React.useRef();
  const [colorPath, setColorPath] = useState("#6FD1B0");
  React.useEffect(() => {
    let newArray = [];
    for (let i = 0; i < relations.length; i++) {
      let newData = {
        from: "startBox-" + relations[i].from.id,
        to: "endBox-" + relations[i].to.id,
        toData: relations[i].to,
        fromData: relations[i].from,
        positions: {
          start: {
            side: "right",
            indent: 20,
          },
          end: {
            side: "left",
          },
        },
        style:
          "relationship-line " +
          `stroke-color-${relations[i].from.brand.name}-${relations[i].from.brand.id}`
            .replace(/\s/g, "_")
            .split(".")
            .join(""),
      };
      newArray.push(newData);
    }
    setDraggablesNodes([...newArray]);
    setSettings([...settings, ...newArray]);
    setColorClasses(getClasses());
  }, []);

  React.useEffect(() => {
    let newSettings = [];
    for (let i = 0; i < dataFrom.length; i++) {
      newSettings.push({
        from: "start-" + dataFrom[i].id,
        to: "end-" + dataFrom[i].id,
        toData: dataFrom[i],
        fromData: dataFrom[i],
        positions: {
          start: {
            side: "right",
            indent: 20,
          },
          end: {
            side: "left",
          },
        },
        style: `relationship-line-selected`,
      });
    }
    setSettings([...newSettings, ...draggablesNodes]);
    if (settings.length !== 0) {
      handleUpdate([...newSettings, ...draggablesNodes]);
    }
  }, [draggablesNodes]);

  const handleUpdate = (data) => {
    let aux = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].style !== "relationship-line-selected") {
        aux.push({ from: data[i].fromData, to: data[i].toData });
      }
    }
    let currentChange;
    try {
      currentChange = aux.filter(
        (i) => i.from.id === aux[aux.length - 1].from.id
      );
    } catch (error) {
      currentChange = [];
    }
    let response = {
      relations: aux,
      lastRelation: aux[aux.length - 1],
      currentItemRelations: currentChange,
    };
    if (onChange) onChange(response);
  };

  const handleRelationComplete = (from, to) => {
    if (onRelationComplete) onRelationComplete({ from, to });
  };

  const handleEnter = (data) => {
    setEnter({ data, flag: true });
  };

  const handleExit = (data) => {
    setEnter({ data: null, flag: false });
  };

  const handleStop = (data) => {
    let aux = draggablesNodes.slice();
    let newData;
    if (enter.flag) {
      newData = {
        from: "startBox-" + data.id,
        to: "endBox-" + enter.data.id,
        toData: enter.data,
        fromData: data,
        positions: {
          start: {
            side: "right",
            indent: 20,
          },
          end: {
            side: "left",
          },
        },
        style:
          "relationship-line " +
          `stroke-color-${data.brand.name}-${data.brand.id}`
            .replace(/\s/g, "_")
            .split(".")
            .join(""),
      };

      aux.push(newData);
      handleRelationComplete(data, enter.data);
      setDraggablesNodes(aux);
    }
  };

  const isRelated = (id) => {
    for (let i = 0; i < draggablesNodes.length; i++) {
      if (`${draggablesNodes[i].toData.id}` === `${id}`) return true;
    }
    

    return false;
  };

  const changeLineColor = (data, newClass) => {
    let newSettings = settings.slice();
    for (let i = 0; i < newSettings.length; i++) {
      if (newSettings[i].toData.id === data.id)
        newSettings[i] = {
          ...newSettings[i],
          style: newClass,
        };
    }
    setSettings([...newSettings]);
  };

  const changeLineColorFrom = (from, to, newClass) => {
    let newSettings = settings.slice();
    for (let i = 0; i < newSettings.length; i++) {
      if (
        newSettings[i].fromData.id === from.id &&
        newSettings[i].toData.id === to.id
      ) {
        newSettings[i] = {
          ...newSettings[i],
          style: newClass,
        };

      }
    }

    setSettings([...newSettings]);
  };

  const getRelations = (id) => {
    let data = settings.slice();
    let newData = [];
    let response = [];
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].toData.id === id &&
        data[i].style !== "relationship-line-selected"
      )
        newData.push({ from: data[i].fromData, to: data[i].toData });
    }

    for (let i = 0; i < newData.length; i++) {
      let flag = false;
      for (let j = 0; j < response.length; j++) {
        if (newData[i].from.id === response[j].from.id) {
          flag = true;
        }
      }
      if (!flag) {
        response.push(newData[i]);
      }
    }
  
    return response;
  };

  const handleDelete = (data) => {
    let aux = [];
    let newSettings = settings.slice();
    for (let i = 0; i < newSettings.length; i++) {
      if (
        !(
          newSettings[i].fromData.id === data.from.id &&
          newSettings[i].toData.id === data.to.id
        )
      ) {
        aux.push(newSettings[i]);
      }
    }

    let aux2 = [];
    let newDraggables = draggablesNodes.slice();
    for (let i = 0; i < newDraggables.length; i++) {
           if (
        !(
          newDraggables[i].fromData.id === data.from.id &&
          newDraggables[i].toData.id === data.to.id
        )
      ) {
        aux2.push(newDraggables[i]);
      }
    }

    if (onDelete) {
      onDelete(data);
    }

    setDraggablesNodes([...aux2]);
    setSettings([...aux]);
  };

  const cleanNodes = () => {
    let aux = [];
    let newSettings = settings.slice();
    for (let i = 0; i < newSettings.length; i++) {
      if (newSettings[i].style === "relationship-line") {
        aux.push(newSettings[i]);
      }
    }

    setDraggablesNodes([]);
    setSettings([...aux]);
  };

  const getClasses = () => {
    let lineClasses = {};
    for (let i = 0; i < dataFrom.length; i++) {
      lineClasses = {
        ...lineClasses,
        ["." +
        `stroke-color-${dataFrom[i].brand.name}-${dataFrom[i].brand.id}`
          .replace(/\s/g, "_")
          .split(".")
          .join("")]: {
          stroke: `${dataFrom[i].brand.color}`, 
          //dataFrom[i].grouper.color
        },
      };
    }

    
    return lineClasses;
  };

  return (
    <RelatableBoxesWrapper>
      <ScreenShot>
        <ColorWrapper ref={ref} color={colorPath} colorClasses={colorClasses}>
          <ReactBezier settings={settings}>
            <div className={classes.container}>
              <div>
                {dataFrom.map((data, index) => {
                  return (
                    <StartBox
                      key={index}
                      data={data}
                      id={data.id}
                      onStop={handleStop}
                      onEnter={()=>{changeLineColor(data, 'relationship-line')}}
                    />
                  );
                })}
              </div>
              <div >
                {dataTo.map((data, index) => {
                  return (
                    <EndBox
                      data={data}
                      id={data.id}
                      key={index}
                      onEnter={handleEnter}
                      onExit={handleExit}
                      related={isRelated(data.id)}
                      relations={getRelations(data.id)}
                      onEnterItem={(data) =>
                        changeLineColorFrom(
                          data.from,
                          data.to,
                          "relationship-line-delete"
                        )
                      }
                      onLeaveItem={(data) =>
                        changeLineColorFrom(
                          data.from,
                          data.to,
                          "relationship-line " +
                            `stroke-color-${data.from.brand.name}-${data.from.brand.id}`
                              .replace(/\s/g, "_")
                              .split(".")
                              .join("")
                        )
                      } //cambia el color de la linea
                      onDelete={(data) => handleDelete(data)}
                    />
                  );
                })}
              </div>
            </div>
          </ReactBezier>
        </ColorWrapper>
      </ScreenShot>
      {/* <div style={{display: 'flex'}}>
                <Fab size="small" color="primary" aria-label="clean" onClick={cleanNodes}>
                    <CleaningServicesIcon/>
                </Fab>
            </div> */}
    </RelatableBoxesWrapper>
  );
};

export default RelatableBoxes;
