import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import FormBrand from "./FormBrand";
import FooterStep from "../../FooterStep";
import ListBrands from "./ListBrands";
import {
  createBrand,
  clearResponseBrand,
  deleteBrand,
  updateBrand,
} from "../../../../Redux/modules/brands/actions";
import { addBrandToCategory } from "../../../../Redux/modules/categories/actions";
import { getResponseBrand } from "../../../../Redux/modules/brands/selectors";
import { getBrandsLoaded } from "../../../../Redux/modules/brands/selectors";
import { getById } from "../../../../Utils/logics";
import { StepWrapper } from "../../styles";

const Brand = ({
  categoryId,
  currentBrands = [],
  currentOwners = [],
  onNext,
  onBack,
}) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const brands = useSelector(getBrandsLoaded);
  const response = useSelector(getResponseBrand);

  const onBrandCreated = (newBrand) => {
    const owner = getById(currentOwners, newBrand.ownerId);
    newBrand.categoryId = categoryId;
    newBrand.owner_id = [owner.id, owner.name];
    dispatch(createBrand(newBrand));
  };

  const onBrandDeleted = (brand) => {
    dispatch(deleteBrand({ ...brand, categoryId: categoryId }));
  };

  const onBrandUpdated = (oldBrand) => {
    const owner = getById(currentOwners, oldBrand.ownerId);
    oldBrand.owner_id = [owner.id, owner.name];
    dispatch(updateBrand({ ...oldBrand, categoryId: categoryId }));
  };

  const onBrandsAdded = (brandsAdded) => {
    dispatch(addBrandToCategory({ brands: brandsAdded, many: true }));
  };

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseBrand());
    }
  }, [response]);

  const filterBrands = () => {
    return brands
      ? brands.filter((xBrand) => {
          if (currentBrands?.map((x) => x.id).includes(xBrand.id)) {
            return false;
          }
          const owner =
            xBrand.owner_id && xBrand.owner_id.length > 0 && xBrand.owner_id[0]
              ? xBrand.owner_id[0]
              : null;
          if (!owner) {
            return false;
          }
          const ownerId = owner.id ? owner.id : owner[0];
          return  currentOwners?.map((x) => x.id).includes(ownerId)
        })
      : [];
  };

  return (
    <StepWrapper>
      <FormBrand
        currentOwners={currentOwners}
        brands={filterBrands()}
        onBrandsAdded={onBrandsAdded}
        onBrandCreated={onBrandCreated}
      ></FormBrand>
      <FooterStep onNext={onNext} onBack={onBack}></FooterStep>
      <ListBrands
        onBrandDeleted={onBrandDeleted}
        onBrandUpdated={onBrandUpdated}
        brands={currentBrands}
        currentOwners={currentOwners}
      ></ListBrands>
    </StepWrapper>
  );
};

export default Brand;
