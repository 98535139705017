import React from "react";
import List from "@mui/material/List";
import Menu from "@mui/material/Menu";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import { LabelsIcon } from "../../../../Components/CustomIcons";
import { useTranslation } from "react-i18next";

const SelectLabels = ({ marginTop, children, showLabels, handleChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [t, i18n] = useTranslation("global");
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{ padding: "0px", margin: "0px" }}
      >
        <ButtonItemColumn
          onClick={handleClickListItem}
          tooltip={t("price_management.curves_component.activate/deactivate")}
          icon={
            <LabelsIcon
              className="iconButtonPrimary strokeLigth"            
              sx={{ fontSize: "2rem" }}
              onClick={handleClickListItem}
            />
          }
        ></ButtonItemColumn>

        {children}
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        sx={{
          opacity: "0.7",
          "& .MuiPaper-root": {
            backgroundColor: "black",
            color: "white",
            marginTop: marginTop ? marginTop : "30px",
            padding: "12px",
            paddingBottom: "0px",
            paddingTop: "0px",
            textAlign: "right",
          },
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={showLabels.Esc1}
                onChange={handleChange}
                name="Esc1"
                size="small"
              />
            }
            label="Esc1"
          />
          <FormControlLabel
            control={
              <Switch
                checked={showLabels.Esc2}
                onChange={handleChange}
                name="Esc2"
                size="small"
              />
            }
            label="Esc2"
          />
        </FormGroup>
      </Menu>
    </div>
  );
};

export default SelectLabels;
