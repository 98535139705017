import { TableRow } from "@mui/material";
import { useDispatch } from "react-redux";
import Input from "../../../../Components/Input";
import { commaSeparateNumber } from "../../../../Utils";
import { CustomCell, CustomCellSticky, CustomTableRow } from "./Styles";

export default function TemplateRow({
  type,
  id,
  item,
  onGetValue,
  onHandleInputChange,
  mainIndex,
  values,
}) {
  const buildCol = (col, index) => {
    let template = (
      <CustomCell
        minWidth="60px"
        backgroundColor={mainIndex % 2 === 0 ? "text" : "primary"}
        key={`${col.index}-${index}`}
      >
        <Input
          variant="secondary"
          style={{ width: "60px", padding: "8px 2px" }}
          name={`${col.index}-${index}`}
          input
          onChange={(e) =>
            onHandleInputChange(type, e.target.value, id, col.index)
          }
          type="tel"
          value={commaSeparateNumber(
            onGetValue(type, id, col.value, col.index)
          )}
        />
      </CustomCell>
    );
    return template;
  };

  return (
    <CustomTableRow
      sx={{ "& > *": { borderBottom: "unset" } }}
      key={`tr-${mainIndex}-${id}`}
    >
      <CustomCellSticky
        backgroundColor={mainIndex % 2 === 0 ? "text" : "primary"}
        minWidth="150px"
        maxWidth="150px"
        type="large"        
      >
        <span style={{ marginLeft: "3px" }}>{item.name}</span>
      </CustomCellSticky>
      {values.map((col, index) => buildCol(col, index))}
    </CustomTableRow>
  );
}
