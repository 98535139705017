import React from "react";
import { Box } from "@mui/material/";
import styled from "styled-components";
import LogoSVG from "../../../../Components/LogoSVG";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

const LayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  .side-bar {
    width: 20%;
    //height: 70vh;
    background-color: ${(props) => props.colors.primary};
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      width: 60%;
      margin: 2em 0 2em 0;
      img {
        width: 100%;
      }
    }
    .options {
      list-style: none;
      margin: 0;
      margin-bottom: 2em;
      padding: 0;
      position: relative;
      .option {
        margin-bottom: 0.5em;
        display: flex;
        align-items: center;
        opacity: 0.5;
        transition: background-color 0.25s;
        .text {
          width: 5em;
          height: 1em;
          background: ${(props) => props.colors.thirdVariant};
          border-radius: 0.25em;
        }
        .icon {
          width: 1.5em;
          height: 1.5em;
          background: ${(props) => props.colors.thirdVariant};
          margin-right: 0.5em;
          border-radius: 50%;
        }
      }
      .options:hover {
        opacity: 1;
      }
      .active {
        .text {
          background-color: ${(props) => props.colors.secondary};
        }
        .icon {
          background-color: ${(props) => props.colors.secondary};
        }
        opacity: 1;
      }
      .active::after {
        position: absolute;
        content: "";
        width: 0.25em;
        height: 1.5em;
        right: -2em;
        border-radius: 0.25em;
        background-color: ${(props) => props.colors.secondary};
      }
    }
  }
  .header {
    width: 100%;
    padding: 1em 3em;
    background-color: ${(props) => props.colors.secondary};
    display: flex;
    justify-content: flex-end;
  }
  .layout-content {
    background-color: ${(props) => props.colors.background};
    width: 100%;
    display: flex;
    flex-direction: column;
    main {
      padding-top: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .content {
        box-shadow: 0px 3px 6px #00000029;
        background-color: ${(props) => props.colors.text};
      }
      .box {
        // box-shadow: 0px 3px 6px #00000029;
        width: 80%;
        padding: 2em;
        margin-bottom: 2em;
        .title {
          height: 1.25em;
          width: 15em;
          background-color: ${(props) => props.colors.secondary};
          margin-bottom: 1em;
          border-radius: 0.25em;
        }
        .alert {
          padding: 1em;
          border-radius: 0.25em;
          width: 100%;
          background-color: ${(props) => props.colors.backgroundContrast};
          color: ${(props) => props.colors.secondary};
          .p {
            height: 0.75em;
            background-color: ${(props) => props.colors.secondary};
            border-radius: 0.25em;
            margin-bottom: 0.25em;
          }
        }
        .stack {
          padding-top: 1em;
          display: flex;
          justify-content: flex-end;
          .button {
            padding: 0.75em 1.5em;
            border-radius: 2em;
            border: 0;
            background-color: ${(props) => props.colors.secondary};
            .text {
              height: 1em;
              width: 2.5em;
              border-radius: 0.25em;
              background-color: ${(props) => props.colors.primary};
            }
          }
          .secondary {
            background-color: ${(props) => props.colors.text};
            .text {
              background-color: ${(props) => props.colors.secondary};
            }
          }
          .thirdVariant {
            background-color: ${(props) => props.colors.thirdVariant};
            .text {
              background-color: ${(props) => props.colors.background};
            }
        }
      }
    }
  }
  .user-data {
    display: flex;
    align-items: center;
    .texts {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      div {
        height: 1em;
        background-color: ${(props) => props.colors.third};
        width: 10em;
        border-radius: 0.25em;
      }
    }
    .logo {
      margin-left: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1em;
      background: ${(props) => props.colors.third};
      border-radius: 50%;
      color: ${(props) => props.colors.secondary};
      img {
        width: 100%;
      }
    }
    .image-logo {
      margin-left: 1em;
      width: 3em;
      height: 3em;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
  }
`;

const LayoutTheme = ({ colors, logo }) => {
  return (
    <LayoutWrapper colors={colors}>
      <section className="side-bar">
        <div className="logo">
          <LogoSVG color={colors.secondary} />
        </div>
        <ul className="options" style={{ marginBottom: "4rem" }}>
          <li className="option">
            <div className="icon"></div>
            <div className="text"></div>
          </li>
          <li className="option">
            <div className="icon"></div>
            <div className="text"></div>
          </li>
          <li className="option">
            <div className="icon"></div>
            <div className="text"></div>
          </li>
          <li className="option">
            <div className="icon"></div>
            <div className="text"></div>
          </li>
          <li className="option">
            <div className="icon"></div>
            <div className="text"></div>
          </li>
          <li className="option">
            <div className="icon"></div>
            <div className="text"></div>
          </li>
        </ul>
        <ul className="options">
          <li className="option">
            <div className="icon"></div>
            <div className="text"></div>
          </li>
          <li className="option active">
            <div className="icon"></div>
            <div className="text"></div>
          </li>
          <li className="option">
            <div className="icon"></div>
            <div className="text"></div>
          </li>
          <li className="option">
            <div className="icon"></div>
            <div className="text"></div>
          </li>
          <li className="option">
            <div className="icon"></div>
            <div className="text"></div>
          </li>
        </ul>
      </section>
      <section className="layout-content">
        <header className="header">
          <div className="user-data">
            <div className="texts">
              <div style={{ marginBottom: ".25em" }}></div>
              <div style={{ width: "5em", height: ".75em" }}></div>
            </div>
            {logo ? (
              <img
                className="image-logo"
                src={`${
                  logo.indexOf("base64") != -1
                    ? logo
                    : `data:image/png;base64,${logo}`
                }`}
              />
            ) : (
              <div className="logo">
                <ImageOutlinedIcon />
              </div>
            )}
          </div>
        </header>
        <main>
          <Box className="box" sx={{ marginTop: "0.5rem" }}>
            <div className="title"></div>
            <div className="content">
              <div className="alert">
                <div className="title"></div>
                <div className="p"></div>
                <div className="p"></div>
                <div className="p" style={{ width: "50%" }}></div>
              </div>
            </div>
            <div className="stack">
              <button
                className="button secondary"
                style={{ marginRight: "1em" }}
              >
                <div className="text"></div>
              </button>
              <button className="button"
                style={{ marginRight: "1em" }}>
                <div className="text"></div>
              </button>
              <button className="button thirdVariant"
                style={{ marginRight: "1em" }}
              >
                <div className="text"></div>
              </button>
            </div>
          </Box>
        </main>
      </section>
    </LayoutWrapper>
  );
};

export default LayoutTheme;
