import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import { setFormDataTrend } from "../../../../Redux/modules/what-if/trend/actions";
import { getTrendSimulationData } from "../../../../Redux/modules/what-if/trend/selectors";
import {
  ArrowCell,
  CustomCell,
  CustomCellSticky,
  ArrowCellSimulationSticky,
  HeadingCell,
} from "./StyleRowSimulationStk";
import Input from "../../../../Components/Input";
import { commaSeparateNumber } from "../../../../Utils";

export const RowSimulationStkEnv = ({
  colSpan,
  environmentVariables,
  type,
}) => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const tempSimulationData = useSelector(getTrendSimulationData);
  const [open, setOpen] = useState(false);
  const handleChangeInput = (propertyName, propertyValue, envId, col) => {
    let index = col.index;
    dispatch(
      setFormDataTrend({
        propertyName,
        propertyValue,
        envId,
        index,
      })
    );
    getValue(type, envId, col.value, col.index);
  };

  const findEnvSimulation = (type, code, index) => {
    let itemExist = tempSimulationData[type].find(
      (item) => item.code === index
    );
    if (itemExist) {
      let envExists = itemExist.items.find((env) => env.code === code);
      return envExists;
    }
    return null;
  };

  const getValue = (type, envId, value, index) => {
    let envExists = findEnvSimulation(type, envId, index);
    return envExists ? envExists.value : value.toFixed(2);
  };

  const buildGroup = (col, type, envId) => {
    return (
      <>
        <CustomCell key={`${type}-${envId}-${col.index}-0`}></CustomCell>
        <CustomCell key={`${type}-${envId}-${col.index}-1`}>
          <Input
            variant="secondary"
            name={`${type}-${envId}-${col.index}`}
            input
            value={commaSeparateNumber(getValue(type, envId, col.value, col.index))}
            onChange={(e) =>
              handleChangeInput(type, e.target.value, envId, col)
            }
            type="tel"
          />
        </CustomCell>
        <CustomCell key={`${type}-${envId}-${col.index}-2`}></CustomCell>
      </>
    );
  };
  return (
    <>
      <TableRow className="expand-tr">
        <ArrowCellSimulationSticky>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span className="title-row">{t(`price_management.trend.env`)}</span>
        </ArrowCellSimulationSticky>
        <HeadingCell className="empty-td" colSpan={colSpan}></HeadingCell>
      </TableRow>

      <TableRow className="collapse-tr">
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingInline: 0,
            border: "none",
          }}
          colSpan={colSpan + 1}
        >
          <Collapse  className="collapse" in={open} timeout="auto" unmountOnExit>
            <Table className="nestedTable">
              <TableBody>
                {environmentVariables &&
                  environmentVariables?.map((row, index) => (
                    <TableRow
                      key={`env-row-${index}`}
                      sx={{ "& > *": { borderBottom: "unset" } }}
                    >
                      <CustomCellSticky type="large">
                        {row.name}
                      </CustomCellSticky>
                      {row.values.map((col) => {
                        return buildGroup(col, type, row.id);
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
