import { SvgIcon } from "@mui/material";
import React from "react";

export const CloseIcon = (props) => {
  const classEarth = {
    fill: "none",
    stroke: "red",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5px",
    ...props.style,
  };
  return (
    <SvgIcon viewBox="0 0 48 48"  {...props}>
      <g transform="matrix(2,0,0,2,0,0)">
        <path d="M0.750 11.999 A11.250 11.250 0 1 0 23.250 11.999 A11.250 11.250 0 1 0 0.750 11.999 Z" style={classEarth}></path>
        <path d="M7.5 16.499L16.5 7.499" style={classEarth}></path>
        <path d="M16.5 16.499L7.5 7.499" style={classEarth}></path>
      </g>
    </SvgIcon>
  );
};
