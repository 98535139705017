import * as type from "./types";
import { setDataCategory } from "./actions";
import { initialState } from "./states";
import produce from "immer";

export const categoryData = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case setDataCategory.REQUEST:
        draft.loading = true;
        break;

      case setDataCategory.SUCCESS:
        draft.response = {
          message: "initial_setup.messages.load_data_category_success",
          error: null,
        };

        break;

      case setDataCategory.FAILURE:
        break;

      case setDataCategory.FULFILL:
        draft.loading = false;
        break;

      case type.SET_CATEGORY_DATA_TEMP_VALUE:
        let itemTempFound = null;
        let filterType =
          action.payload.tableType === "total_subcategory"
            ? "subcategory_id"
            : "sku_id";
        itemTempFound = draft.values.find(
          (x) =>
            x.period_index === action.payload.period_index &&
            x[filterType] === action.payload.id
        );
        if (!itemTempFound) {
          let createdItem = {
            [filterType]: action.payload.id,
            period_index: action.payload.period_index,
            sales_units: "",
            sales_price: "",
          };
          if (action.payload.tableType !== "total_subcategory") {
            createdItem = { ...createdItem, distribution: "" };
          }
          createdItem[action.payload.valueType] = action.payload.value;
          draft.values.push(createdItem);
        } else {
          itemTempFound[action.payload.valueType] = action.payload.value;
        }
        break;

      case type.SET_CATEGORY_DATA_ROWS:
        draft.rows = action.payload.rows;
        draft.values = action.payload.values;
        draft.values = action.payload.values;
        break;

      case type.SET_FORM_DATA_CATEGORY_DATA:
        const cleanValues = () => {
          let filterType =
            draft.formData.tableType === "total_subcategory"
              ? "subcategory_id"
              : "sku_id";
          switch (filterType) {
            case "subcategory_id":
              draft.values = draft.values.filter(
                (item) => item[filterType] !== draft.formData.subcategoryId
              );
              break;
            case "sku_id":
              draft.values = draft.values.map((item) => {
                if (!item["subcategory_id"]) {
                  return { ...item, [draft.formData.tableType]: "" };
                }
                return item;
              });
              break;
          }
        };
        switch (action.payload.propertyName) {
          case "cleanValues":
            cleanValues();
            break;
          case "rows":
            draft.rows = action.payload.value;
            break;
          case "rowsData":
            draft.rows = action.payload.value;
            draft.values = [];
            break;

          default:
            draft.formData[action.payload.propertyName] = action.payload.value;
            break;
        }
        break;

        case type.CLEAR_RESPONSE_DATA_CATEGORY:
          draft.response = { error: null, message: null };
          break;
    }
  });
