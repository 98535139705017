import { SvgIcon } from "@mui/material";

export function PlIcon(props) {
  const style = {
    fill: "none",
    stroke: "#000000",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    ...props.style,
  };
  return (
    <SvgIcon {...props}>
      <g>
        <path
          style={style}
          d="M10.5 16.5H1.5C1.23478 16.5 0.98043 16.3946 0.792893 16.2071C0.605357 16.0196 0.5 15.7652 0.5 15.5V1.5C0.5 1.23478 0.605357 0.98043 0.792893 0.792893C0.98043 0.605357 1.23478 0.5 1.5 0.5H18.5C18.7652 0.5 19.0196 0.605357 19.2071 0.792893C19.3946 0.98043 19.5 1.23478 19.5 1.5V8.5"
        ></path>
        <path
          style={style}
          d="M4.38 10.73L7.57 7.57C7.66313 7.47475 7.77435 7.39908 7.89713 7.34741C8.01992 7.29574 8.15179 7.26912 8.285 7.26912C8.41821 7.26912 8.55008 7.29574 8.67286 7.34741C8.79564 7.39908 8.90687 7.47475 9 7.57L10 8.57C10.0961 8.66815 10.2114 8.74545 10.3388 8.79707C10.4661 8.84868 10.6027 8.87351 10.74 8.87C10.8776 8.8644 11.0127 8.83042 11.1365 8.77019C11.2604 8.70997 11.3706 8.62479 11.46 8.52L15.5 4"
        ></path>
        <path style={style} d="M12.5 4H15.5V7"></path>
        <path
          style={style}
          d="M15.5 18.24C15.3619 18.24 15.25 18.1281 15.25 17.99C15.25 17.8519 15.3619 17.74 15.5 17.74"
        ></path>
        <path
          style={style}
          d="M15.5 18.24C15.6381 18.24 15.75 18.1281 15.75 17.99C15.75 17.8519 15.6381 17.74 15.5 17.74"
        ></path>
        <path
          style={style}
          d="M15.5 20.76C15.3619 20.76 15.25 20.6481 15.25 20.51C15.25 20.3719 15.3619 20.26 15.5 20.26"
        ></path>
        <path
          style={style}
          d="M15.5 20.76C15.6381 20.76 15.75 20.6481 15.75 20.51C15.75 20.3719 15.6381 20.26 15.5 20.26"
        ></path>
        <path
          style={style}
          d="M18 18.24C17.8619 18.24 17.75 18.1281 17.75 17.99C17.75 17.8519 17.8619 17.74 18 17.74"
        ></path>
        <path
          style={style}
          d="M18 18.24C18.1381 18.24 18.25 18.1281 18.25 17.99C18.25 17.8519 18.1381 17.74 18 17.74"
        ></path>
        <path
          style={style}
          d="M20.5 18.24C20.3619 18.24 20.25 18.1281 20.25 17.99C20.25 17.8519 20.3619 17.74 20.5 17.74"
        ></path>
        <path
          style={style}
          d="M20.5 18.24C20.6381 18.24 20.75 18.1281 20.75 17.99C20.75 17.8519 20.6381 17.74 20.5 17.74"
        ></path>
        <g>
          <path
            style={style}
            d="M18 20.76C17.8619 20.76 17.75 20.6481 17.75 20.51C17.75 20.3719 17.8619 20.26 18 20.26"
          ></path>
          <path
            style={style}
            d="M18 20.76C18.1381 20.76 18.25 20.6481 18.25 20.51C18.25 20.3719 18.1381 20.26 18 20.26"
          ></path>
        </g>
        <g>
          <path
            style={style}
            d="M20.5 20.76C20.3619 20.76 20.25 20.6481 20.25 20.51C20.25 20.3719 20.3619 20.26 20.5 20.26"
          ></path>
          <path
            style={style}
            d="M20.5 20.76C20.6381 20.76 20.75 20.6481 20.75 20.51C20.75 20.3719 20.6381 20.26 20.5 20.26"
          ></path>
        </g>
        <path
          style={style}
          d="M21.5 15C21.5044 15.0669 21.4945 15.1338 21.471 15.1965C21.4474 15.2592 21.4107 15.3161 21.3634 15.3634C21.316 15.4108 21.2591 15.4474 21.1965 15.471C21.1338 15.4946 21.0668 15.5045 21 15.5H15C14.9318 15.5031 14.8637 15.4916 14.8003 15.4663C14.7369 15.441 14.6796 15.4024 14.6323 15.3532C14.585 15.304 14.5487 15.2453 14.5259 15.1809C14.5031 15.1166 14.4942 15.0481 14.5 14.98V13C14.4956 12.9332 14.5055 12.8663 14.5291 12.8036C14.5526 12.7409 14.5893 12.684 14.6366 12.6367C14.684 12.5893 14.7409 12.5527 14.8036 12.5291C14.8662 12.5055 14.9332 12.4956 15 12.5H21C21.0668 12.4956 21.1338 12.5055 21.1965 12.5291C21.2591 12.5527 21.316 12.5893 21.3634 12.6367C21.4107 12.684 21.4474 12.7409 21.471 12.8036C21.4945 12.8663 21.5044 12.9332 21.5 13V15Z"
        ></path>
        <path
          style={style}
          d="M22.5 10.5H13.5C12.9477 10.5 12.5 10.9477 12.5 11.5V22.5C12.5 23.0523 12.9477 23.5 13.5 23.5H22.5C23.0523 23.5 23.5 23.0523 23.5 22.5V11.5C23.5 10.9477 23.0523 10.5 22.5 10.5Z"
        ></path>
      </g>
    </SvgIcon>
  );
}
