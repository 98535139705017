import React from "react";
import { SvgIcon } from "@mui/material";

export function TicketIcon(props) {
  const classTicketIcon = {
    fill: "none",
    stroke: "#000000",
    strokeWidth: "1.5px",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    ...props.style,
    color: "red",
  };
  return (
    <SvgIcon style={props.style} viewBox="0 0 48 48">
      <g transform="matrix(2,0,0,2,0,0)">
        <g>
          <path
            d="M22.79,14.52a1,1,0,0,1,0,1.41L15.72,23a1,1,0,0,1-1.41,0L1.21,9.91A2.84,2.84,0,0,1,.51,8.2V1.71a1,1,0,0,1,1-1H8a2.78,2.78,0,0,1,1.71.7Z"
            style={classTicketIcon}
          ></path>
          <circle cx="5.5" cy="5.71" r="2" style={classTicketIcon}></circle>
        </g>
      </g>
    </SvgIcon>
  );
}
