import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import { setFormDataTrend } from "../../../../Redux/modules/what-if/trend/actions";
import { getTrendSimulationData } from "../../../../Redux/modules/what-if/trend/selectors";
import {
  ArrowCellSimulationSticky,
  HeadingCell,
  CustomCell,
  CustomCellSticky,
} from "./StyleRowSimulationStk";
import Input from "../../../../Components/Input";
import { commaSeparateNumber } from "../../../../Utils";

export const RowSimulationStk = ({ colSpan, skus, name }) => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const tempSimulationData = useSelector(getTrendSimulationData);
  const [open, setOpen] = useState(false);
  const handleChangeInput = (propertyName, propertyValue, skuId, index) => {
    dispatch(
      setFormDataTrend({
        propertyName,
        propertyValue,
        skuId,
        index,
      })
    );
  };

  const findSkuSimulation = (type, skuId, index) => {
    let itemExist = tempSimulationData[type].find(
      (item) => item.code === index
    );
    if (itemExist) {
      let skuExists = itemExist.items.find((sku) => sku.code === skuId);
      return skuExists;
    }
    return null;
  };

  const getValue = (type, skuId, value, index) => {
    let skuExists = findSkuSimulation(type, skuId, index);
    return skuExists ? skuExists.value : value;
  };
  const onChangeStyle = (type, skuId) => {
    let itemExist = findSkuSimulation(type, skuId);
    return itemExist
      ? { color: "#707070", fontWeight: "bold" }
      : { color: "#1F1C36" };
  };

  const getColorCell = (value) => {
    if (value === 0) {
      return {
        color: "gray",
        fontWeight: "bold",
      };
    } else
      return {
        color: value < 0 ? "red" : "green",
        fontWeight: "bold",
      };
  };

  const buildGroup = (col, type, skuId) => {
    return (
      <>
        <CustomCell key={`${type}-${col.index}-${skuId}-base`}>
          <Input
            style={onChangeStyle(type, skuId)}
            variant="secondary"
            name={`${type}-${col.index}-${skuId}`}
            input
            onChange={(e) =>
              handleChangeInput(type, e.target.value, skuId, col.index)
            }
            type="tel"
            value={commaSeparateNumber(
              getValue(type, skuId, col[type][0].toFixed(2), col.index)
            )}
          />
        </CustomCell>
        <CustomCell key={`${type}-${col.index}-${skuId}-forecast`}>
          <Input
            style={onChangeStyle(type, skuId)}
            variant="secondary"
            name={`${type}-${col.index}-${skuId}`}
            input
            onChange={(e) =>
              handleChangeInput(`${type}2`, e.target.value, skuId, col.index)
            }
            type="tel"
            value={commaSeparateNumber(
              getValue(`${type}2`, skuId, col[type][1].toFixed(2), col.index)
            )}
          />
        </CustomCell>
        <CustomCell
          key={`${type}-${col.index}-${skuId}-d`}
          sx={
            col[type][0].toFixed(2) !== col[type][1].toFixed(2) &&
            getColorCell(col[type][2].toFixed(2))
          }
        >
          {commaSeparateNumber(col[type][2].toFixed(2))}
        </CustomCell>
      </>
    );
  };
  return (
    <>
      <TableRow className="expand-tr" sx={{ border: "none", "& > *": { borderBottom: "unset" } }}>
        <ArrowCellSimulationSticky>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span className="title-row">
            {t(`price_management.trend.${name}`)}
          </span>
        </ArrowCellSimulationSticky>
        <HeadingCell colSpan={colSpan}></HeadingCell>
      </TableRow>
      <TableRow className="collapse-tr">
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingInline: 0,
            border: "none",
          }}
          colSpan={colSpan + 1}
        >
          <Collapse className="collapse" in={open} timeout="auto" unmountOnExit>
            <Table className="nestedTable">
              <TableBody>
                {skus?.map((row, index) => (
                  <TableRow
                    key={`sticky-row-${index}`}
                    sx={{ "& > *": { borderBottom: "unset" } }}
                  >
                    <CustomCellSticky type="large">{row.name}</CustomCellSticky>
                    {row.table.map((col) => {
                      return buildGroup(col, name, row.id);
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
