import * as type from "./types";
import { initialState } from "./states";
export default function relations(state = initialState, action) {
  switch (action.type) {
    case type.CREATE_RELATION_SKU_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CREATE_RELATION_SKU_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {
          message: "initial_setup.messages.create_relation_success",
          error: null,
        },
      };
    case type.CREATE_RELATION_SKU_FAILURE:
      return {
        ...state,
        loading: false,
        response: {
          message: null,
          error: action.error,
        },
      };

    case type.DELETE_RELATION_SKU_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_RELATION_SKU_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {
          message: "initial_setup.messages.delete_relation_success",
          error: null,
        },
      };
    case type.DELETE_RELATION_SKU_FAILURE:
      return {
        ...state,
        loading: false,
        response: {
          message: null,
          error: action.error,
        },
      };
    case type.CLEAR_RESPONSE_RELATION:
      return {
        ...state,
        response: {
          message: null,
          error: null,
        },
      };
      case type.SET_TAB_SELECTED:
      return {
        ...state,
        tabSelected: action.payload,    
      };

    default:
      return state;
  }
}
