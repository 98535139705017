import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import HeaderStepList from "./HeaderStepList";
import ListGrid from "../../Components/Table/ListGrid";

export default function CommonStepList({
  listItems,
  onChangeSearchItem,
  columnNames,
  paginationTitle,
}) {

  const [searchItem, setSearchItem] = useState("");

  const onChangeSearch = (filter) => {
    if (filter) {
      setSearchItem(filter);
    } else {
      setSearchItem("");
    }
    onChangeSearchItem(filter);
  };
  return (
    <>
      <HeaderStepList
        searchText={searchItem}
        onChangeSearch={onChangeSearch}
        paginationTitle={paginationTitle}
      ></HeaderStepList>
      <Stack>
        <ListGrid columnNames={columnNames}>{listItems}</ListGrid>
      </Stack>
      
    </>
  );
}
