import React from "react";
import { styled as MuiStyled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoSVG from "../../../Components/LogoSVG";
import MuiDrawer from "@mui/material/Drawer";
import MainMenu from "../MainMenu";

const openedMixin = (theme, width) => ({
  width: width,
  backgroundColor: theme.palette.custom.primary,
  color: theme.palette.custom.secondary,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  backgroundColor: theme.palette.custom.primary,
  color: theme.palette.custom.secondary,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = MuiStyled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = MuiStyled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, width }) => ({
  ".MuiList-root": {
    paddingTop: "0px",
    paddingBottom: "0px",
    color: theme.palette.custom.secondary,

    ".MuiListItemButton-root": {
      opacity: "0.5",
      "&:hover": {
        cursor: "pointer",
        opacity: "1",
      },
      "&.active": {
        opacity: "1",
        borderRight: "5px solid",
        filter: "brightness(1.5)",
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        borderColor: theme.palette.custom.secondary,
      },
    },
  },

  width: width,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, width),
    "& .MuiDrawer-paper": openedMixin(theme, width),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideBar = ({
  openSideBar,
  setOpenSideBar,
  sideBarWidth,
  menuOptions,
}) => {
  return (
    <Drawer variant="permanent" width={sideBarWidth} open={openSideBar}>
      <DrawerHeader>
        <div style={{ width: "100%", padding: "25px 25px" }}>
          <LogoSVG />
        </div>
        <IconButton onClick={() => setOpenSideBar(!openSideBar)}>
          {openSideBar ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <MainMenu openSideBar={openSideBar} menuOptions={menuOptions}></MainMenu>
    </Drawer>
  );
};

export default SideBar;
