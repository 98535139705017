import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../../api";
import { token } from "../../auth/selectors";
import {
  getLoadMatrizE,
  getSimulateMatrizE
} from "./actions";
import { getFormDataMatrizE, getSimulationDataMatrizE } from "./selectors";


function* getLoadMatrizESaga({ payload }) {
  try {
    yield put(getLoadMatrizE.request());
    const tokenAccess = yield select(token);
    const form = yield select(getFormDataMatrizE);
    const annual = form.isAnnual ? 1 : 0;
    let body = {};
    body.month = form.month;
    body.annual = annual;
    const response = yield call(
      callApiAxios,
      "POST",
      `pricing.sku.subcategory/${form.subcategoryId}/elasticity_matrix`,
      tokenAccess,
      {},
      body
    );
    yield put(getLoadMatrizE.success(response.data));
  } catch (e) {
    yield put(
      getLoadMatrizE.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getLoadMatrizE.fulfill());
  }
}

function* getSimulateMatrizESaga({ payload }) {
  try {
    yield put(getSimulateMatrizE.request());
    const tokenAccess = yield select(token);
    const form = yield select(getFormDataMatrizE);
    const annual = form.isAnnual ? 1 : 0;
    const dataSimulation = yield select(getSimulationDataMatrizE);
    let body = {};
    const types = ["env"];
    for (const type of types) {
      if (dataSimulation[type].length > 0) {
        let data = {};
        for (const item of dataSimulation[type]) {
          let items = {};
          for (const obj of item.items) {
            items[`${obj.code}`] = parseFloat(obj.value);
          }

          data[`${item.code}`] = items;
        }
        body[`values`] = data;
      }
    }
    body.month = form.month;
    body.annual = annual;

    const url = `pricing.sku.subcategory/${form.subcategoryId}/elasticity_matrix`;
    const response = yield call(
      callApiAxios,
      "POST",
      url,
      tokenAccess,
      {},
      body
    );

    yield put(getLoadMatrizE.success(response.data));
  } catch (e) {
    yield put(
      getSimulateMatrizE.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getSimulateMatrizE.fulfill());
  }
}

export default function* matrizESaga() {
  yield takeEvery(getLoadMatrizE.TRIGGER, getLoadMatrizESaga);
  yield takeEvery(getSimulateMatrizE.TRIGGER, getSimulateMatrizESaga);
}
