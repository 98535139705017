import { createAction } from "redux-actions";
import { createRoutine } from "redux-saga-routines";
import {
  GET_LOAD_TREE,
  GET_FILTER_TREE,
  CLEAR_FILTER_TREE,
  SET_CATEGORY_FILTERED_TREE,
  SET_FILTER_TREE,
  SET_SETTINGS_TREE,
} from "./types";

export const getLoadTree = createRoutine(GET_LOAD_TREE);

export const getFilterTree = createRoutine(GET_FILTER_TREE);

export const clearFilterTree = createAction(CLEAR_FILTER_TREE);

export const setFilterTree = createAction(SET_FILTER_TREE);

export const setSettingsTree = createAction(SET_SETTINGS_TREE);

export const setCategoryFilteredTree = createAction(SET_CATEGORY_FILTERED_TREE);
