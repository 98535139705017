import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "../../../Components/TextField";
import TypographyError from "./TypographyError";
import { Typography, Box } from "@mui/material/";
import Button from "../../../Components/Button";
import { useSelector, useDispatch } from "react-redux";
import { changePassword,clearResponseUsers} from "../../../Redux/modules/users/actions";
import { getResponseUsers } from "../../../Redux/modules/users/selectors";
import { useSnackbar } from "notistack";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const response = useSelector(getResponseUsers);
  const { enqueueSnackbar } = useSnackbar();
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [errorMinLeng, setErrorMinLeng] = useState(false);
  const [errorMixedCase, setErrorMixedCase] = useState(false);
  const [errorAtLeasOneNumber, setErrorAtLeasOneNumber] = useState(false);
  const [errorAtLeasOneSpecialCharacter, setErrorAtLeasOneSpecialCharacter] =
    useState(false);

  const validatePassword = () => {
    if (
      currentPassword.length > 1 &&
      errorMinLeng === false &&
      errorMixedCase === false &&
      errorAtLeasOneNumber === false &&
      errorAtLeasOneSpecialCharacter === false
    ) {
      if (repeatPassword !== newPassword) {
        enqueueSnackbar(t("settings_component.password_not_match"), {
          variant: "error",
        });
      } else {
        handlePassword();
      }
    } else {
      enqueueSnackbar(t("settings_component.check_the_fields"), {
        variant: "error",
      });
    }
  };
  const expressions = {
    lengthPassword: /^.{6,12}$/, // 6 a 12 digitos.
    mixedCase: /(?=.*?[a-z])(?=.*?[A-Z])/, 
    atLeasOneNumber: /[0-9]/,
    atLeasOneSpaceCharacter: /[@$!%*#?&+-]/,
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };
  const handlePassword = () => {
    const data = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };
    dispatch(changePassword(data));
    onReset();

  };
  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseUsers());
    }
  }, [response]);

  const onReset=()=>{
    setCurrentPassword("");
    setNewPassword("");
    setRepeatPassword("");
    setErrorMinLeng(false);
    setErrorMixedCase(false);
    setErrorAtLeasOneNumber(false);
    setErrorAtLeasOneSpecialCharacter(false);
  }

  return (
    <>
      <TextField
        variant="secondary"
        required
        type="password"
        label={t("settings_component.current_password")}
        style={{ marginBottom: "1em" }}
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />

      <Typography
        variant="body2"
        fontWeight={600}
        gutterBottom
        component="div"
        marginLeft={2}
      >
        {t("settings_component.the_password_must_contain")}
      </Typography>
      <Box ml={3} mb={1}>
        <TypographyError
          label={t("settings_component.min_password_length")}
          regularExpression={expressions.lengthPassword}
          error={errorMinLeng}
          setError={setErrorMinLeng}
          inputValue={newPassword}
        />
        <TypographyError
          label={t("settings_component.mixed_case")}
          regularExpression={expressions.mixedCase}
          error={errorMixedCase}
          setError={setErrorMixedCase}
          inputValue={newPassword}
        />
        <TypographyError
          label={t("settings_component.at_least_one_number")}
          regularExpression={expressions.atLeasOneNumber}
          error={errorAtLeasOneNumber}
          setError={setErrorAtLeasOneNumber}
          inputValue={newPassword}
        />
        <TypographyError
          label={t("settings_component.at_least_one_special_character")}
          regularExpression={expressions.atLeasOneSpaceCharacter}
          error={errorAtLeasOneSpecialCharacter}
          setError={setErrorAtLeasOneSpecialCharacter}
          inputValue={newPassword}
        />
      </Box>
      <TextField
        variant="secondary"
        required
        type="password"
        value={newPassword}
        label={t("settings_component.new_password")}
        style={{ marginBottom: "1em" }}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <TextField
        variant="secondary"
        required
        type="password"
        value={repeatPassword}
        label={t("settings_component.confirm_new_password")}
        onChange={(e) => setRepeatPassword(e.target.value)}
      />
      <div
        style={{
          marginTop: "1em",
          textAlign: "center",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1em",
        }}
      >
        <Button
          variant="secondary"
          style={{ width: "max-content" }}
          onClick={validatePassword}
        >
          {t("components_globals.change_password")}
        </Button>
      </div>
    </>
  );
};

export default ChangePassword;
