import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { token } from "../auth/selectors";
import { getLanguage } from "../app/selectors";
import { getVariablesFormData } from "./selectors";
import {
  DELETE_VARIABLE_FAILURE,
  DELETE_VARIABLE_REQUEST,
  DELETE_VARIABLE_SUCCESS,
} from "./types";
import { createVariable, updateVariable } from "./actions";
import {
  ADD_VARIABLE_TO_CATEGORY,
  UPDATE_VARIABLE_IN_CATEGORY,
  DELETE_VARIABLE_OF_CATEGORY,
} from "../categories/types";

import moment from "moment";
import {
  GET_VALUES_BY_VARIABLE_ID_REQUEST,
  GET_VALUES_BY_VARIABLE_ID_SUCCESS,
  GET_VALUES_BY_VARIABLE_ID_FAILURE,
} from "./types";

function* createVariableSaga({ payload }) {
  try {
    yield put(createVariable.request());
    const tokenAccess = yield select(token);
    const formData = yield select(getVariablesFormData);
    let dateFirstPeriod = moment(formData.firstPeriod).format("YYYY-MM-DD");
    if (!["days", "weeks"].includes(formData.periodWidth)) {
      dateFirstPeriod = `${moment(formData.firstPeriod).format("YYYY-MM")}-01`;
    }
    let body = {
      scope: formData.scope,
      name: formData.name,
      description: formData.abbreviation,
      period_width: formData.periodWidth,
      completion_rule: formData.completionRule,
      first_period: dateFirstPeriod,
    };

    const influencesFor =
      formData.influencesFor.length > 0
        ? formData.influencesFor.map((item) => {
            return {
              catalog_id: `${formData.scope},${item}`,
            };
          })
        : [];

    let flagValues = false;
    switch (formData.scope) {
      case "pricing.sku.category":
        body = { ...body, category_id: payload.categoryId };
        flagValues = true;
        break;
      case "env":
        flagValues = true;
        break;
      default:
        body = {
          ...body,
          category_id: payload.categoryId,
          influences_for: influencesFor,
        };
        break;
    }

    const response = yield call(
      callApiAxios,
      "POST",
      "pricing.variable/",
      tokenAccess,
      {},
      body
    );

    let bodyValues = {
      values: [],
    };

    if (formData.values && formData.values.length > 0) {
      if (!flagValues) {
        for (const influence of influencesFor) {
          const tempValues = formData.values.map((item) => {
            return {
              influences_for: `${influence.catalog_id}`,
              period_index: parseInt(item.period_index),
              value: parseFloat(item.value),
            };
          });
          bodyValues.values = bodyValues.values.concat(tempValues);
        }
      } else {
        const tempValues = formData.values.map((item) => {
          return {
            period_index: parseInt(item.period_index),
            value: parseFloat(item.value),
          };
        });
        bodyValues.values = bodyValues.values.concat(tempValues);
      }
      if (bodyValues.values.length > 0) {
        const responseValues = yield call(
          callApiAxios,
          "PATCH",
          `pricing.variable/${response.data[0].id}/set_values`,
          tokenAccess,
          {},
          bodyValues
        );
      }
    }
    yield put(createVariable.success({ variable: response.data[0] }));
    yield put({
      type: ADD_VARIABLE_TO_CATEGORY,
      variable: response.data[0],
    });
  } catch (e) {
    yield put(
      createVariable.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(createVariable.fulfill());
  }
}

function* updateVariableSaga({ payload }) {
  try {
    yield put(updateVariable.request());
    const tokenAccess = yield select(token);
    const formData = yield select(getVariablesFormData);
    let dateFirstPeriod = moment(formData.firstPeriod).format("YYYY-MM-DD");
    if (!["days", "weeks"].includes(formData.periodWidth)) {
      dateFirstPeriod = `${moment(formData.firstPeriod).format("YYYY-MM")}-01`;
    }

    let body = {
      name: formData.name,
      scope: formData.scope,
      description: formData.abbreviation,
      period_width: formData.periodWidth,
      completion_rule: formData.completionRule,
      first_period: dateFirstPeriod,
    };
    const influencesFor = formData.influencesFor.map((item) => {
      return {
        catalog_id: `${formData.scope},${item}`,
      };
    });

    let flagValues = false;
    switch (formData.scope) {
      case "pricing.sku.category":
        body = { ...body, category_id: payload.categoryId };
        flagValues = true;
        break;
      case "env":
        flagValues = true;
        break;
      default:
        body = {
          ...body,
          category_id: payload.categoryId,
          influences_for: influencesFor,
        };
        break;
    }

    const response = yield call(
      callApiAxios,
      "PUT",
      `pricing.variable/${formData.id}`,
      tokenAccess,
      {},
      body
    );

    let bodyValues = {
      values: [],
    };
    if (formData.values && formData.values.length > 0) {
      if (!flagValues) {
        for (const influence of influencesFor) {
          const tempValues = formData.values.map((item) => {
            return {
              influences_for: `${influence.catalog_id}`,
              period_index: parseInt(item.period_index),
              value: parseFloat(item.value),
            };
          });
          bodyValues.values = bodyValues.values.concat(tempValues);
        }
      } else {
        const tempValues = formData.values.map((item) => {
          return {
            period_index: parseInt(item.period_index),
            value: parseFloat(item.value),
          };
        });
        bodyValues.values = bodyValues.values.concat(tempValues);
      }
      if (bodyValues.values.length > 0) {
        const responseValues = yield call(
          callApiAxios,
          "PATCH",
          `pricing.variable/${response.data[0].id}/set_values`,
          tokenAccess,
          {},
          bodyValues
        );
      }
    }
    yield put(updateVariable.success({ variable: response.data[0] }));
    yield put({
      type: UPDATE_VARIABLE_IN_CATEGORY,
      variable: response.data[0],
    });
  } catch (e) {
    yield put(
      updateVariable.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(updateVariable.fulfill());
  }
}

function* deleteVariable({ payload }) {
  try {
    const tokenAccess = yield select(token);

    const response = yield call(
      callApiAxios,
      "POST-PARAMS",
      `pricing.variable/${payload.id}/del`,
      tokenAccess,
      {},
      {}
    );
    yield put({
      type: DELETE_VARIABLE_SUCCESS,
      payload: response.data,
    });
    yield put({ type: DELETE_VARIABLE_OF_CATEGORY, variable: payload });
  } catch (e) {
    yield put({
      type: DELETE_VARIABLE_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* getValuesByVariableId({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const currentLanguage = yield select(getLanguage);
    const response = yield call(
      callApiAxios,
      "GET",
      `pricing.variable/${payload.id}/get_values`,
      tokenAccess,
      {},
      {}
    );

    yield put({
      type: GET_VALUES_BY_VARIABLE_ID_SUCCESS,
      data: {
        values: response.data,
        period: payload.period,
        language: currentLanguage,
      },
    });
  } catch (e) {
    yield put({
      type: GET_VALUES_BY_VARIABLE_ID_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

export default function* variablesSaga() {
  yield takeEvery(createVariable.TRIGGER, createVariableSaga);
  yield takeEvery(updateVariable.TRIGGER, updateVariableSaga);
  yield takeEvery(DELETE_VARIABLE_REQUEST, deleteVariable);
  yield takeEvery(GET_VALUES_BY_VARIABLE_ID_REQUEST, getValuesByVariableId);
}
