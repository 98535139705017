import React, { useState } from "react";
import SideBar from "./SideBar";
import Header from "./Header";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import GridViewIcon from "@mui/icons-material/GridView";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  PaidIcon,
  StreamLineIcon,
  ForecastIcon,
  SummaryIcon,
} from "../../Components/CustomIcons";
import { getUser } from "../../Redux/modules/app/selectors";
import { getMainMenuItems } from "../../Redux/modules/layout/selectors";

const CssLayoutBox = styled(Box)(({ theme }) => ({
  display: "flex",
  "&.MuiBox-root": {
    backgroundColor: theme.palette.custom.background,
    minHeight: "100vh",
  },
}));
const CssContentLayoutBox = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    backgroundColor: theme.palette.custom.background,
    minHeight: "100vh",
  },
}));
const LayoutBase = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Layout = ({ children }) => {
  const [t, i18n] = useTranslation("global");
  const user = useSelector(getUser);
  const menuItems = useSelector(getMainMenuItems);

  const getIconCustom = (iconName) => {
    const icons = {
      ["GridViewIcon"]: <GridViewIcon />,
      ["PaidIcon"]: <PaidIcon />,
      ["StreamLineIcon"]: <StreamLineIcon />,
      ["ForecastIcon"]: <ForecastIcon />,
      ["SummaryIcon"]:<SummaryIcon/>
    };

    return icons[iconName] || <GridViewIcon />;
  };
 const isMenuLocked=()=>{

 }
  const loadMenuDetails = (menu) => {
    const isLocked =  menu.canLocked? !user.modules?.includes(menu.code) : menu.locked;
    return {
      ...menu,
      locked: isLocked,
      icon: getIconCustom(menu.icon),
      text: t(menu.text),
      children: !isLocked && menu.children.map((subMenu) => {
        return loadMenuDetails(subMenu);
      }),
    };
  };

  const menuOptions = menuItems.map((menu) => {
    return loadMenuDetails(menu);
  });

  const [openSideBar, setOpenSideBar] = useState(true);
  const sideBarWidth = "17%";
  return (
    <CssLayoutBox>
      <CssBaseline />
      <Header
      
        sideBarWidth={sideBarWidth}
        user={user}
        openSideBar={openSideBar}
        setOpenSideBar={setOpenSideBar}
      ></Header>
      <SideBar
        openSideBar={openSideBar}
        setOpenSideBar={setOpenSideBar}
        sideBarWidth={sideBarWidth}
        menuOptions={menuOptions}
      />
      <CssContentLayoutBox
        sx={{ flexGrow: 1, p: 3 }}
        style={{
          width: openSideBar
            ? `calc(100% - ${sideBarWidth})`
            : "calc(100% - 73px)",
        }}
      >
        <LayoutBase />
        <div style={{ padding: "40px 20px 20px 20px" }}>{children}</div>
      </CssContentLayoutBox>
    </CssLayoutBox>
  );
};
export default Layout;
