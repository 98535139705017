import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { clearResponseMomentum } from "../../../../Redux/modules/sniper/momentum/actions";
import {
  getMomentumLoaded,
  getResponseMomentum,
} from "../../../../Redux/modules/sniper/momentum/selectors";
import AccordionsMomentum from "./AccordionsMomentum";
import FormMomentum from "./FormMomentum";
import TableCompetitorPrices from "./TableCompetitorPrices";
import TableEnvironmentVariables from "./TableEnvironmentVariables";
const Momentum = () => {
  const { enqueueSnackbar } = useSnackbar();
  const response = useSelector(getResponseMomentum);
  const momentumLoaded = useSelector(getMomentumLoaded);
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseMomentum());
    }
  }, [response]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "13px" }}>
      <FormMomentum />
      {momentumLoaded && <TableEnvironmentVariables />}
      {momentumLoaded && <TableCompetitorPrices />}
      {momentumLoaded && <AccordionsMomentum />}
    </div>
  );
};

export default Momentum;
