import React from "react";
import { SvgIcon } from "@mui/material";

export function GraphBarIcon(props) {
  const style = {
    fill: 'none',
    stroke: '#000000',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    ...props.style
  }
  return (
    <SvgIcon {...props}>
 <g transform="matrix(1,0,0,1,0,0)">
<line style={style} className="a" x1="0.5" y1="15.251" x2="8.718" y2="15.251"/>
<path style={style} className="a" d="M16.507,9.249v-8a.5.5,0,0,0-.5-.5h-2a.5.5,0,0,0-.5.5v8"/>
<path style={style} className="a" d="M10.5,9.249v-4a.5.5,0,0,0-.5-.5H8a.5.5,0,0,0-.5.5v10"/>
<path style={style} className="a" d="M4.5,15.251v-6a.5.5,0,0,0-.5-.5H2a.5.5,0,0,0-.5.5v6"/>
<rect style={style} className="a" x="10.494" y="12.25" width="13.006" height="11.005" rx="1"/>
<line style={style} className="a" x1="10.494" y1="14.967" x2="23.5" y2="14.967"/>
<line style={style} className="a" x1="10.494" y1="17.752" x2="23.5" y2="17.752"/>
<line style={style} className="a" x1="10.494" y1="20.388" x2="23.5" y2="20.388"/>
<line style={style} className="a" x1="16.507" y1="14.968" x2="16.507" y2="23.255"/>
<line style={style} className="a" x1="19.837" y1="14.968" x2="19.837" y2="23.255"/>
</g>
    </SvgIcon>
  );
}