export const SET_DATA_CATEGORY = "SET_DATA_CATEGORY";
// get categories
export const SET_CATEGORY_DATA_VALUE = "SET_DATA_CATEGORY_VALUE";
export const SET_CATEGORY_DATA_VALUES = "SET_DATA_CATEGORY_VALUES";

export const SET_CATEGORY_DATA_TEMP_VALUE = "SET_DATA_CATEGORY_TEMP_VALUE";
export const SET_CATEGORY_DATA_TEMP_VALUES = "SET_DATA_CATEGORY_TEMP_VALUES";

export const SYNC_DATA_CATEGORY_VALUES = "SYNC_DATA_CATEGORY_VALUES";
export const RESET_DATA_CATEGORY_VALUES = "RESET_DATA_CATEGORY_VALUES";

export const CLEAR_DATA_CATEGORY = "CLEAR_DATA_CATEGORY";

export const CLEAR_RESPONSE_DATA_CATEGORY = "CLEAR_RESPONSE_DATA_CATEGORY";

export const SET_CATEGORY_DATA_ROWS = "SET_CATEGORY_DATA_ROWS";

export const SET_FORM_DATA_CATEGORY_DATA = "SET_FORM_DATA_CATEGORY_DATA";

export const CLEAN_VALUES_CATEGORY_DATA = "CLEAN_VALUES_CATEGORY_DATA";

