import { Grid, Typography, Box } from "@mui/material/";
import LogoSVG from "../../Components/LogoSVG";
import BackgroundSVG from "../../Components/BackgroundPinionsSVG/index";
import { useTranslation } from "react-i18next";

const ErrorRoute = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <Box display="flex" justifyContent="center" mt={1}>
      <div
        style={{
          width: "20%",
          position: "absolute",
          zIndex: "1",
          opacity: "0.1",
        }}
      >
        <BackgroundSVG />
      </div>
      <Grid>
        <div style={{ width: "28%", margin: "0 auto", marginTop: "1rem" }}>
          <LogoSVG />
        </div>
        <Grid>
          <Typography
            variant="h3"
            component="h2"
            align="center"
            mt={7}
            bold={999}
          >
             {t("site_under_construction.page_title")}
          </Typography>
        </Grid>
        <Grid>
          <Typography variant="h6" component="h3" align="center" mt={3}>
          {t("site_under_construction.page_subtitle")}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ErrorRoute;
