import React, { useState } from "react";
import RelatableBoxes from "../../../../Components/RelatableBoxes";
const RelationBoard = ({
  index,
  skus,
  relations,
  onRelationCreated,
  onRelationDeleted,
}) => {
  const handleCreated = (e) => {
    const relation = {
      idFrom: e.from.id,
      idTo: [e.to.id],
    };
    onRelationCreated(relation);
  };

  const handleDelete = (e) => {
    const relation = {
      idFrom: e.from.id,
      idTo: [e.to.id],
    };
    onRelationDeleted(relation);
  };

  const handleChange = (e) => {};

  return (
    <RelatableBoxes
      dataFrom={skus}
      dataTo={skus}
      lineClasses={[]}
      onChange={handleChange}
      relations={relations}
      onDelete={(e) => handleDelete(e)}
      key={index}
      onRelationComplete={(e) => handleCreated(e)}
    />
  );
};

export default RelationBoard;
