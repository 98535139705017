import {
  SAVE_CONFIG_FAILURE,
  SAVE_CONFIG_REQUEST,
  SAVE_CONFIG_SUCCESS,
  SELECT_LANGUAGE,
  GET_CONFIG_REQUEST,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,
  ON_INITIAL_STATE,
  CLEAR_RESPONSE_APP,
  RESET_COLORS,
} from "./types";
import { initialState } from "./states";
import { updateLogo } from "./actions";

export default function app(state = initialState, action) {
  switch (action.type) {
    case SAVE_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        language: "",
      };
    case SAVE_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          imageLogo: action.payload.logo
            ? action.payload.logo
            : state.user.logo,
        },
        colors: action.payload.colors,
        response: {
          message:
            "settings_component.your_customization_was_saved_successfully",
          error: null,
        },
      };
    case SAVE_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        response: { message: null, error: action.error },
      };
    case GET_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CONFIG_SUCCESS:
      const modules = action.payload.modules;
      let newModules = [];
      for (const property of Object.keys(modules)) {
        if (!newModules.includes(property)) {
          newModules.push(property);
          for (const item of modules[property]) {
            if (!newModules.includes(item)) {
              newModules.push(item);
            }
          }
        }
      }
      return {
        ...state,
        colors: action.payload.colors
          ? JSON.parse(action.payload.colors)
          : initialState.colors,
        numMaxCategories: action.payload.categories,
        user: {
          ...state.user,
          userName: action.payload.user_name,
          userType: action.payload.access,
          companyName: action.payload.company_name,
          imageLogo: action.payload.logo,
          modules: newModules,
        },
        loading: false,
      };
    case GET_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        response: {
          message: null,
          error: action.error,
        },
      };
    case ON_INITIAL_STATE:
      return {
        ...initialState,
      };
    case SELECT_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case CLEAR_RESPONSE_APP:
      return {
        ...state,
        response: { message: null, error: null },
      };

    case updateLogo.REQUEST:
      return {
        ...state,
        loading: true,
      };
      break;
    case updateLogo.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          imageLogo: action.payload.logo
            ? action.payload.logo
            : state.user.logo,
        },
      };

    case updateLogo.FAILURE:
      break;
    case updateLogo.FULFILL:
      return {
        ...state,
        loading: false,
      };
      break;

    case RESET_COLORS:
      return {
        ...state,
        colors: initialState.colors,
      };
      break;

    default:
      return state;
  }
}
