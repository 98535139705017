import React from "react";
import Accordion from "../../../../Components/Accordion";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material/";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import TableCurves from "./TableCurves";
import GraphCurves from "./GraphCurves";
import Simulator from "./Simulator";
import FormCurves from "./FormCurves";
import {
  getCurvesLoaded,
  getResponseCurves,
} from "../../../../Redux/modules/what-if/curves/selectors";
import styled from "styled-components";
import ListGraphs from "./ListGraphs";
import RadioBtnGroup from "./RadioBtnGroup";
import { clearResponseCurves } from "../../../../Redux/modules/what-if/curves/actions";
import { useEffect } from "react";

const ContainerSimulator = styled.div`
  width: 100%;
  & > * {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
`;

const Curves = () => {
  const curves = useSelector(getCurvesLoaded);
  const [t, i18n] = useTranslation("global");
  const response = useSelector(getResponseCurves);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseCurves());
    }
  }, [response]);

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
          <Typography fontWeight={600} variant="subtitle1">
            {t("price_management.curves_component.select_data")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormCurves />
        </AccordionDetails>
      </Accordion>

      {curves && curves.length > 0 && (
        <ContainerSimulator>
          <RadioBtnGroup />
          <Grid container>
            <TableCurves />
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={5}>
              <Simulator />
            </Grid>
            <Grid item xs={7}>
              <GraphCurves />
            </Grid>
          </Grid>
          <ListGraphs />
        </ContainerSimulator>
      )}
    </>
  );
};

export default Curves;
