import React from "react";
import { Box, Popover } from "@mui/material/";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const HelpPopover = ({ open, anchorEl, onPopoverClose, message }) => {
    return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: "none",
        borderRadius: "30%",
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={onPopoverClose}
      disableRestoreFocus
    >
      <Box
        textAlign="center"
        p={0.5}
        sx={{
          width: 160,
          height: 100,
          backgroundColor: (theme) => `${theme.palette.custom.secondary}`,
          color: (theme) => `${theme.palette.custom.third}`,
          padding: "10px",
        }}
      >
        <div style={{ marginTop: "2px" }}>
          <ErrorOutlineIcon />
        </div>
        <p
          style={{
            fontSize: "12px",
            marginTop: "-8px",
          }}
        >
          {message}
        </p>
      </Box>
    </Popover>
  );
};
export default HelpPopover;
