// create category
export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILURE = "CREATE_CATEGORY_FAILURE";
// get categories
export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";
// update category
export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";
// delete category
export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";
// get category id
export const GET_CATEGORY_BY_ID_REQUEST = "GET_CATEGORY_BY_ID_REQUEST";
export const GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS";
export const GET_CATEGORY_BY_ID_FAILURE = "GET_CATEGORY_BY_ID_FAILURE";

export const CLEAR_CATEGORY = "CLEAR_CATEGORY";

export const SELECT_CATEGORY = "SELECT_CATEGORY";

export const SET_CATEGORY_ID = "SET_CATEGORY_ID";

export const CLEAR_RESPONSE_CATEGORY = "CLEAR_RESPONSE_CATEGORY";

export const ADD_SUBCATEGORY_TO_CATEGORY = "ADD_SUBCATEGORY_TO_CATEGORY";
export const UPDATE_SUBCATEGORY_IN_CATEGORY = "UPDATE_SUBCATEGORY_IN_CATEGORY";
export const DELETE_SUBCATEGORY_OF_CATEGORY = "DELETE_SUBCATEGORY_OF_CATEGORY";

export const ADD_BRAND_TO_CATEGORY = "ADD_BRAND_TO_CATEGORY";
export const UPDATE_BRAND_IN_CATEGORY = "UPDATE_BRAND_IN_CATEGORY";
export const DELETE_BRAND_OF_CATEGORY = "DELETE_BRAND_OF_CATEGORY";

export const ADD_OWNER_TO_CATEGORY = "ADD_OWNER_TO_CATEGORY";
export const UPDATE_OWNER_IN_CATEGORY = "UPDATE_OWNER_IN_CATEGORY";
export const DELETE_OWNER_OF_CATEGORY = "DELETE_OWNER_OF_CATEGORY";

export const ADD_SKU_TO_CATEGORY = "ADD_SKU_TO_CATEGORY";
export const UPDATE_SKU_IN_CATEGORY = "UPDATE_SKU_IN_CATEGORY";
export const DELETE_SKU_OF_CATEGORY = "DELETE_SKU_OF_CATEGORY";

export const ADD_VARIABLE_TO_CATEGORY = "ADD_VARIABLE_TO_CATEGORY";
export const UPDATE_VARIABLE_IN_CATEGORY = "UPDATE_VARIABLE_IN_CATEGORY";
export const DELETE_VARIABLE_OF_CATEGORY = "DELETE_VARIABLE_OF_CATEGORY";
export const ADD_RELATION_SKU_TO_CATEGORY = "ADD_RELATION_SKU_TO_CATEGORY";
export const DELETE_RELATION_SKU_OF_CATEGORY =
  "DELETE_RELATION_SKU_OF_CATEGORY";

export const GET_DATA_BY_CATEGORY_ID_REQUEST =
  "GET_DATA_BY_CATEGORY_ID_REQUEST";
export const GET_DATA_BY_CATEGORY_ID_SUCCESS =
  "GET_DATA_BY_CATEGORY_ID_SUCCESS";
export const GET_DATA_BY_CATEGORY_ID_FAILURE =
  "GET_DATA_BY_CATEGORY_ID_FAILURE";

  export const SET_FINISH_CATEGORY = "SET_FINISH_CATEGORY";

  export const ADD_VALUES_TO_CATEGORY ="ADD_VALUES_TO_CATEGORY";

  export const UPDATE_CATEGORY_AFTER_LOAD_DATA='UPDATE_CATEGORY_AFTER_LOAD_DATA';