import React from "react";

import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";

import DeleteNode from "./DeleteNode";

const useStyles = makeStyles((theme) => ({
  endNode: {
    border: "1px solid #E6EBF1",
    borderRadius: "2px",
    padding: "6px",
    width:'100%',
    position: "relative",
    overflow: "hidden",
    color: "#727272",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      borderWidth: "2px",
      cursor: "pointer",
    },
  },

  magnet: {
    position: "absolute",
    backgroundColor: "transparent",
    width: "100%",
    height: "10em",
    right: "0",
    top: "0",
    zIndex: "1"
  },

  endPoint: {
    position: "absolute",
    right: "0",
    top: "25%",
  },
  endNodeContainer: {
    display: "flex",
 
    width: "300px",
    alignItems: "center",
    marginBottom: ".5em",
    transition: "all .5s",
  },
  borderColor: {
    height: "100%",
    width: ".5em",
    backgroundColor: (props) => props.color,
    position: "absolute",
    left: "0",
    top: "0",
  },
  // magnet: {
  //   position: "absolute",
  //   backgroundColor: "transparent",
  //   width: "100%",
  //   height: "10em",
  //   right: "0",
  //   top: "0",
  //   zIndex: "1",
  // },

  title: {
    textTransform: "uppercase",
    display: "flex",
    marginBottom: ".25em",
    fontWeight: "600",
    fontSize: "0.8rem",
  },
  subtitle: {
    textTransform: "uppercase",
    fontSize: "0.75rem",
  },
  detail: {
    fontSize: ".75em",
    display: "flex",
  },
}));

const EndBox = ({
  data,
  id,
  onEnter,
  onExit,
  related = false,
  onEnterDelete,
  onExitDelete,
  relations = [],
  onEnterItem,
  onLeaveItem,
  onDelete,
}) => {
  const classes = useStyles({ color: data.brand.color });
  const [hover, setHover] = React.useState(false);
  const wrapperRef = React.useRef(null);

  const handleEnter = () => {
    setHover(true);
    if (onEnter) onEnter(data);
  };

  const handleExit = () => {
    setHover(false);
    if (onExit) onExit(data);
  };

  const handleEnterDelete = () => {
    if (onEnterDelete) onEnterDelete(data);
  };

  const handleExitDelete = () => {
    if (onExitDelete) onExitDelete(data);
  };

  return (
    <div
      className={classes.endNodeContainer}
      ref={wrapperRef}
      id={"endBox-" + id}
    >
      <div className={classes.endNode}>
      <div
          className={classes.magnet}
          onMouseEnter={handleEnter}
          onMouseLeave={handleExit}
        />
        <DeleteNode
          related={related}
          onMouseEnter={handleEnterDelete}
          onMouseLeave={handleExitDelete}
          relations={relations}
          onEnterItem={onEnterItem}
          onLeaveItem={onLeaveItem}
          onDelete={(data) => onDelete(data)}
          className={classes.endPoint}
          color={data.brand.color}
        />
 
        <div className={classes.borderColor} />
    
        <span style={{ margin: "1em" }}>
          {data.img ? (
            <Avatar alt="" src={data.img} sx={{ width: 40, height: 40 }} />
          ) : (
            <Avatar sx={{ width: 40, height: 40, bgcolor: data.brand.color }}>
              {data.name.toUpperCase().charAt(0)}
            </Avatar>
          )}
        </span>
        <div >
          <div className={classes.title}>{data.name}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className={classes.subtitle}>
              {data.owner_id[1]} / {data.brand_id[1]}
            </span>
          </div>

          <div className={classes.detail}>
            <span style={{ fontWeight: "600" }}>
              {data.unit_weight} {data.uom_id[1]}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndBox;
