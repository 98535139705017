import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import TextField from "../../../../Components/TextField";
import Button from "../../../../Components/Button";
import ColorPicker from "../../../../Components/ColorPicker";
import { SaveIcon } from "../../../../Components/CustomIcons";
const FormSubcategory = ({
  subcategory,
  onSubcategoryCreated,
  onSubcategoryUpdated,
  setCurrentSubcategory,
}) => {
  const [t, i18n] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const [isNew, setIsNew] = useState(true);
  const [tempSubcategory, setTempSubcategory] = useState({
    name: "",
    description: "",
    color: "#B2ADB4",
  });

  useEffect(() => {
    if (subcategory) {
      setIsNew(false);
      setTempSubcategory(subcategory);
    }
  }, []);

  const isValidForm = () => {
    if (!tempSubcategory.name) {
      enqueueSnackbar("Error", { variant: "error" });
      return false;
    }
    return true;
  };

  const handleInputChanges = (newValue, type) => {
    setTempSubcategory({ ...tempSubcategory, [type]: newValue });
    if (!isNew) {
      setCurrentSubcategory({ ...tempSubcategory, [type]: newValue });
    }
  };

  const onSubmitSubcategory = () => {
    if (isValidForm()) {
      isNew
        ? onSubcategoryCreated(tempSubcategory)
        : onSubcategoryUpdated(tempSubcategory);
      onReset();
    }
  };

  const onReset = () => {
    setTempSubcategory({ name: "", description: "", color: "#B2ADB4" });
    setIsNew(true);
  };

  return (
    <div
      style={{ display: "flex", marginBottom: "1.5em", alignItems: "center" }}
    >
      <TextField
        fullWidth
        required
        variant="third"
        label={`${t("initial_setup.step_1.stage_2.form_name")} ${t(
          "initial_setup.forms.common_inputs.name"
        )}`}
        value={tempSubcategory.name}
        onChange={(e) => {
          handleInputChanges(e.target.value, "name");
        }}
      />

      <TextField
        fullWidth
        style={{ marginLeft: "20px" }}
        variant="third"
        label={`${t("initial_setup.forms.common_inputs.description")}`}
        value={tempSubcategory.description}
        onChange={(e) => handleInputChanges(e.target.value, "description")}
      />

      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          fontWeight: "600",
          flexDirection: "column",
          margin: "0px 20px",
        }}
      >
        <span>{t("initial_setup.forms.common_inputs.color")}</span>
        <ColorPicker
          value={tempSubcategory.color}
          onChangeComplete={(e) => handleInputChanges(e.target.value, "color")}
        />
      </div>
      {isNew && (
        <Button
          fullWidth
          style={{ maxWidth: "150px" }}
          variant="thirdVariant"
          disabled={
            !tempSubcategory.name 
          }
          onClick={onSubmitSubcategory}
        >
          <SaveIcon />
          <span style={{ paddingLeft: "5px" }}>
            {t("components_globals.button_save")}
          </span>
        </Button>
      )}
    </div>
  );
};

export default FormSubcategory;
