import { useState } from "react";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  styled,
  Typography,
} from "@mui/material";
import SaveMenu from "../../../../Components/SaveMenu";
import { exportToExcel } from "../../../../Utils";
import { usePromotions } from "../../../../Hooks/usePromotions";
import TableCustomer from "./TableCustomer";
import { useVolumes } from "../../../../Hooks/useVolumes";
import { ButtonBox, CustomSwitch } from "./Styles";

export default function ItemVolume({ promotion }) {
  const [t, i18n] = useTranslation("global");

  const [isExpandedAllMonth, setIsExpandedAllMonth] = useState(true);
  const [expandedMonths, setExpandedMonths] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [expandedList, setExpandedList] = useState([]);
  const [isAllExpanded, setIsAllxpanded] = useState(false);
  const [isKg, setIsKg] = useState(false);
  const { id, customers } = useVolumes({ promotion });
  const customerIds = customers.map((x) => x.id);
  const handleDownloadExcel = (customer) => {
    try {
      let [table] = document.getElementsByClassName(
        `tableVolumes_${customer.id}`
      );
      let excelTable = document.createElement("table");
      excelTable.innerHTML = table.innerHTML.trim();
      const tbody = excelTable.querySelector("tbody");
      if (tbody) {
        let tr = tbody.querySelectorAll("tr");
        let inputs = tbody.querySelectorAll("input");
        for (let i = 0; i < inputs.length; i++) {
          let input = inputs[i];
          const parentTd = input.parentNode;
          parentTd.className = "";
          parentTd.innerHTML = input.value;
        }
      }
      exportToExcel(`promotion_${customer.id}`, excelTable, customer.name);
    } catch (error) {}
  };
  const handleChangeExpandedAll = (e) => {
    setIsAllxpanded(!isAllExpanded);
    if (!isAllExpanded) {
      setExpandedList(customerIds);
    } else {
      setExpandedList([]);
    }
  };
  const isExpandedList = (id) => {
    return expandedList.includes(id);
  };

  const handleChangeExpandedList = (id) => {
    if (isExpandedList(id)) {
      const newArray = expandedList.filter((x) => x !== id);
      setExpandedList(newArray);
    } else {
      const newArray = expandedList.concat([id]);
      setExpandedList(newArray);
    }
  };

  return (
    <div
      style={{
        paddingTop: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {customers.map((customer) => (
        <Accordion
          sx={{
            borderRadius: "10px",
          }}
          key={customer.name}
          expanded={isExpandedList(customer.id)}
          onChange={(e) => handleChangeExpandedList(customer.id)}
        >
          <AccordionSummary
            key={`summary-${customer.name}`}
            expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.5rem" }} />}
            aria-controls={customer.name}
          >
            {" "}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
              }}
            >
              <div
                style={{
                  display: "grid",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {customer.image ? (
                  <img src={"data:image/png;base64," + customer.image} />
                ) : (
                  <>
                    <Avatar
                      sx={{
                        borderRadius: "10px",
                        width: 120,
                        height: 40,
                        bgcolor: "rgb(205, 202, 206)",
                      }}
                      className="client-avatar"
                    >
                      {customer.name.toUpperCase().charAt(0)}
                    </Avatar>
                  </>
                )}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails key={`details-${customer.name}`}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <SaveMenu
                style={{ marginRight: "32px" }}
                buttons={["export"]}
                list={["excel"]}
                // handlePdf={handlePdf}
                handleExcel={() => handleDownloadExcel(customer)}
              />
              <ButtonBox
                style={{ cursor: "pointer" }}
                onClick={() => setIsKg(!isKg)}
              >
                {isKg ? "Qty" : "Kg"}
              </ButtonBox>
            </div>
            <TableCustomer id={id} isKg={isKg} customer={customer} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
