export const GET_LOAD_OPTIMAL_PRICES = "GET_LOAD_OPTIMAL_PRICES";

export const GET_SIMULATE_OPTIMAL_PRICES = "GET_SIMULATE_OPTIMAL_PRICES";

export const SET_FORM_DATA_OPTIMAL_PRICES  ="SET_FORM_DATA_OPTIMAL_PRICES";

export const SET_TYPE_FUNCTION  ="SET_TYPE_FUNCTION";

export const CLEAR_OPTIMAL_PRICE  = "CLEAR_OPTIMAL_PRICE";
export const CLEAR_RESPONSE_OPTIMAL_PRICES  = "CLEAR_RESPONSE_OPTIMAL_PRICES";


export const SET_DEFAULT_FORM_DATA_OPTIMAL_PRICES ="SET_DEFAULT_FORM_DATA_OPTIMAL_PRICES";