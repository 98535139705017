import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";

export const TitleCell = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.custom.secondary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
  },
  [`&.${tableCellClasses.head} .space-cell`]: {
    display: "grid",
    placeContent: "center",
    width: "98%",
    borderRadius: "10px",
    textAlign: "center",
    backgroundColor: theme.palette.custom.primary,
    height: "35px",
    marginInline: "auto",
  },
}));
export const HeadingCellSimulation = styled(TableCell)(
  ({ theme, type, color }) => ({
    [`&.${tableCellClasses.body}`]: {
      backgroundColor:
        color === "gray" ? "#cdcace" : theme.palette.custom.secondary,
      color: color === "gray" ? "black" : theme.palette.custom.primary,
      minWidth: type === "large" ? "300px" : "100px",
      border: "none",
      fontWeight: "bold",
      padding: 0,
      textAlign: type === "large" ? "" : "center",
      height: "35px",
    },
    [`&.${tableCellClasses.body}:last-child`]: {
      borderBottomRightRadius: "10px",
      borderTopRightRadius: "10px",
    },
  })
);

export const BlankCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: "#cdcace",
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    padding: 0,
    minWidth: 0,
    border: "none",
  },
}));

export const TitleCellSimulation = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.custom.secondary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    minWidth: "100px",
  },
  [`&.${tableCellClasses.head} .space-cell`]: {
    display: "grid",
    placeContent: "center",
    width: "95%",
    borderRadius: "10px",
    textAlign: "center",
    backgroundColor: theme.palette.custom.primary,
    height: "35px",
    fontSize: "0.8rem",
    marginInline: "auto",
  },
  [`&.${tableCellClasses.head} .first-cell`]: {
    display: "grid",
    placeContent: "center",
    width: "95%",
    textAlign: "center",
    backgroundColor: theme.palette.custom.background,
    height: "35px",
    fontSize: "0.8rem",
    minWidth: "300px",
    marginInline: "auto",
  },
}));

export const TitleGrayCell = styled(TableCell)(({ theme, type, minWidth="100px" }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.custom.secondary,
    minWidth:minWidth,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    textAlign: "center",
  },
  [`&.${tableCellClasses.head} .space-cell`]: {
    display: "grid",
    placeContent: "center",
    width: "80%",
    borderRadius: "10px",
    textAlign: "center",
    backgroundColor: theme.palette.custom.primary,
    height: "65px",
    marginInline: "auto",
    fontSize: "3rem",
  },
  [`&.${tableCellClasses.head} .space-gray`]: {
    backgroundColor: "#cdcace",
    display: "grid",
    placeContent: "center",
    width: "98%",
    height: "65px",
    borderRadius: "10px",
    marginInline: "auto",
    fontSize: "1em",
  },
}));

export const CustomTable = styled(Table)(({ theme }) => ({
  borderCollapse: "separate",
  borderSpacing: "0 5px",
  backgroundColor: "#fff",
  [`.thead`]: {
    backgroundColor: "#fff",
  },
  [`.tr`]: {
    backgroundColor: "#fff",
  },
}));

export const CustomCellSticky = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.body}`]: {
    minWidth: type === "large" ? "300px" : "100px",
    maxWidth: type === "large" ? "300px" : "100px",
    border: "none",
    padding: 0,
    paddingBlock: "6px",
    paddingLeft: type === "large" ? "5px" : "",
    textAlign: type === "large" ? "left" : "center",
    fontWeight: type === "large" ? "bold" : "",
    left: 0,
    position: "sticky",
    backgroundColor: theme.palette.common.white,
  },
}));
export const ColorCell = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.body}`]: {
    minWidth: type === "large" ? "300px" : "100px",
    maxWidth: type === "large" ? "300px" : "100px",
    fontWeight: "bold",
    padding: 0,
    textAlign: type === "large" ? "" : "center",
    backgroundColor: theme.palette.custom.secondary,
    color: theme.palette.custom.primary,
  },
  [`&.${tableCellClasses.body}:.first`]: {
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
  },
  [`&.${tableCellClasses.body}:nth-of-type(4)`]: {
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  [`&.${tableCellClasses.body}:last-child`]: {
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
  },
}));
export const HeadingCell = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.body}`]: {
    minWidth: type === "large" ? "300px" : "100px",
    border: "none",
    fontWeight: "bold",
    padding: 0,
    textAlign: type === "large" ? "" : "center",
  },
  [`&.${tableCellClasses.body}:nth-of-type(2)`]: {
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
  },
  [`&.${tableCellClasses.body}:nth-of-type(3)`]: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}:nth-of-type(4)`]: {
    color: "black",
    backgroundColor: "white",
  },
  [`&.${tableCellClasses.body} .space-cell`]: {
    display: "grid",
    placeContent: "center",
    width: "80%",
    height: "34px",
    backgroundColor: "#cdcace",
    borderRadius: "10px",
    marginInline: "auto",
  },
  [`&.${tableCellClasses.body}:nth-of-type(5)`]: {
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
  },
  [`&.${tableCellClasses.body}:last-child`]: {
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
  },
}));

export const CustomCell = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.body}`]: {
    minWidth: type === "large" ? "300px" : "100px",
    border: "none",
    padding: 0,
    paddingBlock: "6px",
    paddingLeft: type === "large" ? "5px" : "",
    textAlign: type === "large" ? "left" : "center",
    fontWeight: type === "large" ? "bold" : "",
  },
}));

export const ArrowCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.custom.secondary,
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    padding: 0,
    border: "none",
    minWidth: "300px",
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
  },
  [`&.${tableCellClasses.body} button`]: {
    color: theme.palette.custom.primary,
  },
  [`&.${tableCellClasses.body} .title-row`]: {
    color: theme.palette.custom.primary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    height: "35px",
  },
}));

export const ArrowCellSticky = styled(TableCell)(({ theme, isRounded }) => ({
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.custom.secondary,
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    padding: 0,
    border: "none",
    minWidth: "300px",
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
    left: 0,
    position: "sticky",
  },
  [`&.${tableCellClasses.body} button`]: {
    color: theme.palette.custom.primary,
  },
  [`&.${tableCellClasses.body} .title-row`]: {
    color: theme.palette.custom.primary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    height: "35px",
  },
}));

export const ArrowCellSimulationSticky = styled(TableCell)(
  ({ theme, isRounded }) => ({
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: theme.palette.custom.secondary,
      borderBottomLeftRadius: "10px",
      borderTopLeftRadius: "10px",
      padding: 0,
      border: "none",
      minWidth: "300px",
      left: 0,
      position: "sticky",
    },
    [`&.${tableCellClasses.body} button`]: {
      color: theme.palette.custom.primary,
    },
    [`&.${tableCellClasses.body} .title-row`]: {
      color: theme.palette.custom.primary,
      border: "none",
      fontWeight: "bold",
      padding: 0,
      height: "35px",
    },
  })
);

export const CellPrimary = styled(TableCell)(({ theme, isRounded }) => ({
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.custom.secondary,
    padding: 0,
    border: "none",
    minWidth: "300px",
    height: "35px",
  },
}));

export const ButtonsCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.custom.background,
    padding: 0,
    border: "none",
    minWidth: "300px",
    height: "35px",
    left: 0,
    position: "sticky",
    zIndex: 120,
  },
}));
