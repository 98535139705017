import React from "react";
import { SvgIcon } from "@mui/material";

export function OptimusGapIcon(props) {
  const classOptimusGapIcon = {
    fill: "none",
    strokeWidth: "1.5px",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    ...props.style,
  };
  return (
    <SvgIcon style={props.style}  width="30.818" height="30.176" viewBox="0 0 30.818 30.176">
    <g id="Grupo_2000" data-name="Grupo 2000" transform="translate(0)">
      <g id="Grupo_2001" data-name="Grupo 2001" transform="translate(0)">
        <path id="Trazado_3209" data-name="Trazado 3209" d="M6.642,30.176A.642.642,0,0,1,6,29.534V.642a.642.642,0,0,1,1.284,0V29.534a.642.642,0,0,1-.642.642" transform="translate(-2.148)" fill="#cdcace"/>
        <path id="Trazado_3210" data-name="Trazado 3210" d="M8.347,5.784a.642.642,0,0,1-.488-.224L4.5,1.635,1.13,5.56a.642.642,0,0,1-.975-.836L4.007.23a.664.664,0,0,1,.975,0L8.835,4.724a.642.642,0,0,1-.487,1.06" transform="translate(0 -0.006)" fill="#cdcace"/>
        <path id="Trazado_3211" data-name="Trazado 3211" d="M15.631,22.284H6.642a.642.642,0,1,1,0-1.284h8.989a.642.642,0,1,1,0,1.284" transform="translate(-2.148 -7.517)" fill="#cdcace"/>
        <path id="Trazado_3212" data-name="Trazado 3212" d="M40.2,22.284H28.642a.642.642,0,1,1,0-1.284H40.2a.642.642,0,0,1,0,1.284" transform="translate(-10.023 -7.517)" fill="#cdcace"/>
        <path id="Trazado_3213" data-name="Trazado 3213" d="M13.75,26.829a5.076,5.076,0,0,1-3.927-2.243.642.642,0,1,1,1.07-.71,3.771,3.771,0,0,0,2.9,1.67c3.752,0,4.408-4.722,5.1-9.72C19.633,10.5,20.4,5,25.307,5c2.946,0,5.5,3.367,6.222,8.187a.642.642,0,1,1-1.27.189c-.612-4.11-2.695-7.093-4.952-7.093C21.516,6.284,20.859,11,20.164,16c-.74,5.322-1.5,10.826-6.414,10.826" transform="translate(-3.478 -1.79)" fill="#cdcace"/>
      </g>
    </g>
    </SvgIcon>
  );
}
