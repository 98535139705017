import React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const CssCommonPaper = styled(Paper)(({ theme }) => ({
    '&.MuiPaper-root': {
      backgroundColor: theme.palette.custom.background,
    }
  }));
    
  
    export const CommonPaper = (props) => {
      return (
              <CssCommonPaper  
                  {...props}
              >
                  {props.children}
              </CssCommonPaper>
      );
  }
  