import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { token } from "../auth/selectors";
import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
} from "./types";

function* doChangePassword({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const body = {
      current_password: payload.currentPassword,
      new_password: payload.newPassword,
    };

    const response = yield call(
      callApiAxios,
      "POST",
      "auth/change_password/",
      tokenAccess,
      {},
      body
    );
    yield put({ type: CHANGE_PASSWORD_SUCCESS, changePassword: response });
  } catch (e) {
    yield put({
      type: CHANGE_PASSWORD_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

export default function* usersSaga() {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, doChangePassword);
}
