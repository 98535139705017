export const initialState = {
  loading: false,
  formData: {
    id: null,
    name: "",
    description: "",
    categoryId: null,
    skuIds: [],
    skus: [],
    customerIds: [],
    promotionsSelectedIds: [],
    packageIds: [],
    startPeriod: null,
    endPeriod: null,
    maxDate: null,
    minDate: null,
  },
  simulationData: {
    env: [],
    base: [],
    promo: [],
  },
  simulationDataEnv: [],
  promotions: [],
  promotionsData: [],
  simulation: null,
  action: null,
  response: { message: null, error: null, result: null },
};
