import { put, select, takeEvery } from "redux-saga/effects";
import { SELECT_CATEGORY } from "../categories/types";
import { SET_CURRENT_VIEW } from "../layout/types";
import { getCurrentPath } from "../layout/selectors";
import { setDefaultFormDataMatrizE } from "../sniper/matriz-e/actions";
import { setDefaultFormDataMomentum } from "../sniper/momentum/actions";
import { setDefaultFormDataCurves } from "../what-if/curves/actions";
import { setDefaultFormDataOptimalPrices } from "../what-if/optimal-prices/actions";
import { setDefaultFormDataTrend } from "../what-if/trend/actions";
import { setDefaultFormDataPromotions } from "../promotion-planning/promotions/actions";
import { getCategorySelected } from "../categories/selectors";

function* changesInViewSaga({ payload }) {
  yield setDefaultDataView(payload, false);
}
function* changesInCategoryHeaderSaga({ payload }) {
  yield setDefaultDataView(payload, true);
}
function* setDefaultDataView(payload, shouldClear) {
  try {
    let category = payload;
    if (!category.id) {
      category = yield select(getCategorySelected);
    }
    if (category.id) {
      const { page, view } = yield select(getCurrentPath);
      switch (page) {
        case "/prices-lab/what-if":
          switch (view) {
            case "curves":
              yield put(setDefaultFormDataCurves({ category, shouldClear }));
              break;
            case "optimal-prices":
              yield put(
                setDefaultFormDataOptimalPrices({ category, shouldClear })
              );
              break;
            case "trend":
              yield put(setDefaultFormDataTrend({ category, shouldClear }));
              break;
          }
          break;
        case "/prices-lab/sniper":
          switch (view) {
            case "matriz-e":
              yield put(setDefaultFormDataMatrizE({ category, shouldClear }));
              break;
            case "momentum":
              yield put(setDefaultFormDataMomentum({ category, shouldClear }));
              break;
          }
          break;
        case "/promotion-lab/promotion-planning":
          switch (view) {
            case "promotions":
              yield put(
                setDefaultFormDataPromotions({ category, shouldClear })
              );
              break;
          }
          break;
      }
    }
  } catch (e) {
  } finally {
  }
}

export default function* layout() {
  yield takeEvery(SELECT_CATEGORY, changesInCategoryHeaderSaga);
  yield takeEvery(SET_CURRENT_VIEW, changesInViewSaga);
}
