import {
  Box,
  Chip,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableHead,
  Typography,
} from "@mui/material/";
import { createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createFileName, useScreenshot } from "use-react-screenshot";
import * as XLSX from "xlsx";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import { DownloadIcon } from "../../../../Components/CustomIcons";
import FormControl from "../../../../Components/FormControl";
import { setFormDataTrend } from "../../../../Redux/modules/what-if/trend/actions";
import {
  getFormDataTrend,
  getSelectedSkus,
} from "../../../../Redux/modules/what-if/trend/selectors";
import { commaSeparateNumber } from "../../../../Utils";
import {
  StyledTableCell,
  StyledTableCellHeader,
  StyledTableRow,
  Wrapper,
} from "./StyleTotalOrder";

const TotalOrder = ({ skus }) => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const tempForm = useSelector(getFormDataTrend);
  const selectedSkus = useSelector(getSelectedSkus);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const refTable = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpg",
    quality: 1.0,
  });
  const handleCloseDownloadMenu = () => {
    setAnchorEl(null);
  };

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const exportToExcel = () => {
    let [table] = document.getElementsByClassName("tableOrder");
    let today = new Date();
    try {
      let excelTable = document.createElement("table");
      excelTable.innerHTML = table.innerHTML.trim();
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.table_to_sheet(excelTable);

      XLSX.utils.book_append_sheet(wb, ws, "Table");
      XLSX.writeFile(
        wb,
        `export_table_orders_${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDay()}-${today.getTime()}.xlsx`
      );
    } catch (error) {}
  };

  const handleOpenDownloadMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadExcel = () => {
    exportToExcel();
  };

  const handleDownloadImage = () => {
    const downloadScreenshot = () =>
      takeScreenShot(refTable.current).then(download);
    downloadScreenshot();
  };

  const handleChangeSkus = (event) => {
    const {
      target: { value },
    } = event;

    dispatch(
      setFormDataTrend({
        propertyName: "skuIds",
        propertyValue: value,
      })
    );
  };

  const sumatoryTotals = (currentSkus, variant, index) => {
    if (!currentSkus || currentSkus.length === 0) return 0;
    const totals = currentSkus
      .map((sku) => parseFloat(sku.totals[variant][index]))
      .reduce((total, current) => {
        return total + current;
      }, 0);

    return commaSeparateNumber(totals.toFixed(2));
  };

  const calculateSumatory = (currentSkus, variant) => {
    if (!currentSkus || currentSkus.length === 0) return 0;
    const totalsB = currentSkus
      .map((sku) => parseFloat(sku.totals[variant][0]))
      .reduce((total, current) => {
        return total + current;
      }, 0);
    const totalsF = currentSkus
      .map((sku) => parseFloat(sku.totals[variant][1]))
      .reduce((total, current) => {
        return total + current;
      }, 0);

    const totals = (totalsF / totalsB - 1) * 100;
    return commaSeparateNumber(totals.toFixed(2));
  };

  return (
    <Wrapper>
      <Box
        sx={{
          background: (theme) => `${theme.palette.custom.secondary}`,
          color: (theme) => `${theme.palette.custom.primary}`,
          height: "3.5rem",
          borderRadius: "0.5rem 0.5rem 0rem 0rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            padding: "1rem  1rem 0rem 2rem",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          {t("price_management.trend.total_order")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            padding: "1rem  2rem 0rem 0rem",
          }}
        >
          <ButtonItemColumn
            onClick={handleOpenDownloadMenu}
            tooltip={t("components_globals.button_download")}
            icon={
              <DownloadIcon
                sx={{ fontSize: "1.5rem" }}
                className="iconButtonPrimary strokeLigth"
              />
            }
          />
          <Menu
            id="download-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseDownloadMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleDownloadImage}>Download Image</MenuItem>
            <MenuItem onClick={handleDownloadExcel}>Download Excel</MenuItem>
          </Menu>
        </Box>
      </Box>
      <FormControl style={{ marginTop: "18px" }} variant="secondary">
        <InputLabel>{t("price_management.trend.skus")}</InputLabel>
        <Select
          multiple
          fullWidth
          label={t("price_management.trend.skus")}
          value={tempForm?.skuIds ? tempForm?.skuIds : []}
          onChange={handleChangeSkus}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              <Chip label={`Seleccionados (${tempForm?.skuIds.length})`} />
            </Box>
          )}
        >
          {skus &&
            skus.map((option, index) => {
              return (
                <MenuItem key={index} value={option.id}>
                  {`${option.name} ${option.unit_weight} ${
                    option.uom_id && option.uom_id[1]
                  }`}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <Table
        className="tableOrder"
        style={{ marginTop: "18px", fontSize: "1rem" }}
        ref={refTable}
        sx={{ height: "80%" }}
      >
        <TableHead>
          <StyledTableRow sx={{ marginBottom: "2px" }}>
            <StyledTableCellHeader
              style={{ backgroundColor: "white" }}
            ></StyledTableCellHeader>
            <StyledTableCellHeader
              align="center"
              sx={{ borderRadius: "0.5rem 0 0 0.5rem" }}
            >
              {t("price_management.trend.base")}
            </StyledTableCellHeader>
            <StyledTableCellHeader align="center">
              {t("price_management.trend.forecast")}
            </StyledTableCellHeader>
            <StyledTableCellHeader
              sx={{ borderRadius: "0rem 0.5rem 0.5rem 0rem" }}
              align="center"
            >
              {t("price_management.trend.d")}
            </StyledTableCellHeader>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell
              component="th"
              scope="row"
              style={{ fontSize: "1rem" }}
              align="left"
              sx={{
                borderRadius: "0.5rem 0 0 0.5rem",
                paddingLeft: "10px",
                fontWeight: "600",
              }}
            >
              {t("price_management.trend.sale_volume")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {sumatoryTotals(selectedSkus, "quantity", 0)}
            </StyledTableCell>
            <StyledTableCell align="center">
              {sumatoryTotals(selectedSkus, "quantity", 1)}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              sx={{
                borderRadius: "0 0.5rem 0.5rem 0",
              }}
            >
              {calculateSumatory(selectedSkus, "quantity")}%
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={{ fontSize: "1rem" }}
              sx={{
                borderRadius: "0.5rem 0 0 0.5rem",
                paddingLeft: "10px",
                fontWeight: "600",
              }}
            >
              {t("price_management.trend.sale_value")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {sumatoryTotals(selectedSkus, "value", 0)}
            </StyledTableCell>
            <StyledTableCell align="center">
              {sumatoryTotals(selectedSkus, "value", 1)}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              sx={{
                borderRadius: "0 0.5rem 0.5rem 0",
              }}
            >
              {calculateSumatory(selectedSkus, "value")}%
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              style={{ fontSize: "1rem" }}
              sx={{
                borderRadius: "0.5rem 0 0 0.5rem",
                paddingLeft: "10px",
                fontWeight: "600",
              }}
            >
              {t("price_management.trend.sale_kilos")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {sumatoryTotals(selectedSkus, "quantity_kg", 0)}
            </StyledTableCell>
            <StyledTableCell align="center">
              {sumatoryTotals(selectedSkus, "quantity_kg", 1)}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              sx={{
                borderRadius: "0 0.5rem 0.5rem 0",
              }}
            >
              {calculateSumatory(selectedSkus, "quantity_kg")}%
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell
              component="th"
              style={{ fontSize: "1rem" }}
              scope="row"
              align="left"
              sx={{
                borderRadius: "0.5rem 0 0 0.5rem",
                paddingLeft: "10px",
                fontWeight: "600",
              }}
            >
              {t("price_management.trend.profit")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {sumatoryTotals(selectedSkus, "profit", 0)}
            </StyledTableCell>
            <StyledTableCell align="center">
              {sumatoryTotals(selectedSkus, "profit", 1)}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              sx={{
                borderRadius: "0 0.5rem 0.5rem 0",
              }}
            >
              {calculateSumatory(selectedSkus, "profit")}%
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell
              component="th"
              scope="row"
              align="left"
              sx={{
                borderRadius: "0.5rem 0 0 0.5rem",
                paddingLeft: "10px",
                fontWeight: "600",
              }}
            >
              {t("price_management.trend.som")}
            </StyledTableCell>
            <StyledTableCell align="center">
              {sumatoryTotals(selectedSkus, "som", 0)}
            </StyledTableCell>
            <StyledTableCell align="center">
              {sumatoryTotals(selectedSkus, "som", 1)}
            </StyledTableCell>
            <StyledTableCell
              align="center"
              sx={{
                borderRadius: "0 0.5rem 0.5rem 0",
              }}
            >
              {sumatoryTotals(selectedSkus, "som", 2)}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default TotalOrder;
