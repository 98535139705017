import React from "react";
import styled from 'styled-components';

const RoundedColWrapper = styled.div`
 font-size:'0.9em';
`;

const ItemColumn = ({ children }) => {
    return (
    <RoundedColWrapper className="divCol">
      {children}
    </RoundedColWrapper>
  );
};



export default ItemColumn;
