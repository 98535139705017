import produce from "immer";
import { getLoadTrend, getSimulateTrend } from "./actions";
import { initialState } from "./states";
import * as types from "./types";

export const trend = (state = initialState, { payload, type }) =>
  produce(state, (draft) => {
    switch (type) {
      case getLoadTrend.TRIGGER:
        draft.loading = true;
        draft.simulationCount = 0;
        draft.trend = [];
        draft.formData.simulationTrend = initialState.formData.simulationTrend;
        draft.formData.skuIds = [];
        draft.simulations = [];
        break;
      case getLoadTrend.REQUEST:
        break;
      case getLoadTrend.SUCCESS:
        draft.trend = payload;
        draft.simulations.push({
          trend: payload,
          data: initialState.formData.simulationTrend,
        });

        break;
      case getLoadTrend.FAILURE:
        draft.response.error = payload.error;
        break;
      case getLoadTrend.FULFILL:
        draft.loading = false;
        break;

      case getSimulateTrend.TRIGGER:
        draft.loading = true;
        break;
      case getSimulateTrend.REQUEST:
        break;
      case getSimulateTrend.SUCCESS:
        draft.simulationCount = draft.simulationCount + 1;
        draft.trend = payload;
        draft.simulations.push({
          trend: payload,
          data: draft.formData.simulationTrend,
        });

        break;
      case getSimulateTrend.FAILURE:
        draft.response.error = payload.error;
        break;
      case getSimulateTrend.FULFILL:
        draft.loading = false;
        break;
      case types.SET_FORM_DATA_TREND:
        if (payload.from) {
          draft.trend = [];
          draft.tempTrend = null;
          draft.simulationCount = 0;
          for (const change of payload.changes) {
            draft.formData[change.propertyName] = change.propertyValue;
          }
        } else {
          if (
            [
              "price",
              "distribution",
              "cost",
              "value",
              "price2",
              "distribution2",
              "cost2",
            ].includes(payload.propertyName)
          ) {
            const code =
              payload.propertyName === "value" ? payload.envId : payload.skuId;

            let itemExist = draft.formData.simulationTrend[
              payload.propertyName
            ].find((item) => item.code === payload.index);
            if (itemExist) {
              let skuExists = itemExist.items.find((sku) => sku.code === code);
              if (skuExists) {
                skuExists.value = payload.propertyValue;
              } else {
                itemExist.items.push({
                  code: code,
                  value: payload.propertyValue,
                });
              }
            } else {
              draft.formData.simulationTrend[payload.propertyName].push({
                code: payload.index,
                items: [{ code: code, value: payload.propertyValue }],
              });
            }
          } else {
            if (payload.propertyName === "skuIds") {
              draft.formData[payload.propertyName] = [].concat(
                payload.propertyValue
              );
            } else {
              draft.formData[payload.propertyName] = payload.propertyValue;
            }
          }
        }

        break;
      case types.SET_DEFAULT_FORM_DATA_TREND:
        const categorySelected = payload.category;
        draft.formData.subcategoryId = categorySelected.subcategory_ids[0].id;
        draft.formData.skuId = categorySelected.subcategory_ids[0].sku_ids[0];
        draft.formData.skuIdGraph =
          categorySelected.subcategory_ids[0].sku_ids[0];
        if (payload.shouldClear) {
          draft.trend = [];
          draft.tempTrend = null;
          draft.simulationCount = 0;
        }
        break;
      case types.CLEAR_SIMULATE_TREND:
        if (payload === "simulationTrend") {
          if (draft.simulationCount > 0) {
            const count = draft.simulationCount - 1;
            draft.simulationCount = count;
            draft.trend = draft.simulations[count].trend;
            draft.formData.simulationTrend = draft.simulations[count].data;
            draft.simulations.pop();
          } else {
            draft.trend = draft.simulations[draft.simulationCount].trend;
            draft.formData.simulationTrend =
              draft.simulations[draft.simulationCount].data;
          }
        } else {
          draft.trend = [];
          draft.tempTrend = null;
          draft.simulationCount = 0;
        }
        break;
      case types.CLEAR_RESPONSE_TREND:
        draft.response = { error: null, message: null };
        break;
    }
  });
