//CREATE OWNER
export const CREATE_OWNER_REQUEST = "CREATE_OWNER_REQUEST";
export const CREATE_OWNER_SUCCESS = "CREATE_OWNER_SUCCESS";
export const CREATE_OWNER_FAILURE = "CREATE_OWNER_FAILURE";
//GET OWNERS
export const GET_LOAD_OWNERS = "GET_LOAD_OWNERS";
//UPDATE OWNER
export const UPDATE_OWNER_REQUEST = "UPDATE_OWNER_REQUEST";
export const UPDATE_OWNER_SUCCESS = "UPDATE_OWNER_SUCCESS";
export const UPDATE_OWNER_FAILURE = "UPDATE_OWNER_FAILURE";
//DELETE OWNER
export const DELETE_OWNER_REQUEST = "DELETE_OWNER_REQUEST";
export const DELETE_OWNER_SUCCESS = "DELETE_OWNER_SUCCESS";
export const DELETE_OWNER_FAILURE = "DELETE_OWNER_FAILURE";
//CLEAR STATE MESSAGE SUCCESS OR FAILURE
export const CLEAR_DATA_OWNER = "CLEAR_DATA_OWNER";
export const CLEAR_RESPONSE_OWNER = "CLEAR_RESPONSE_OWNER";
