import {
  TableCell,
  TableRow,
  tableCellClasses,
  tableRowClasses,
} from "@mui/material/";
import { styled } from "@mui/material/styles";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: 0,
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.custom.primary,
  },
}));

export const StyledTableRowHead = styled(TableRow)(({ theme }) => ({
  background: `${theme.palette.custom.secondary}`,
  color: `${theme.palette.custom.primary}`,
  height: "3.5rem",
}));

export const Wrapper = styled("div")(() => ({
  boxShadow: "0px 3px 6px #00000029 ",
  borderRadius: "10px",
  height: "648px",
  scrollBehavior: "smooth",
}));

export const CommonCell = styled(TableCell)(
  ({ theme, type, minWidth = "100px",textAlign="center",odd=true }) => ({
    [`&.${tableCellClasses.body}`]: {
      minWidth: minWidth,
      border: "none",
      
      paddingLeft: "15px",
      textAlign: textAlign,
      fontWeight: type === "large" ? "bold" : "",
      left: 0,
      position: "sticky",
      background: odd?`${theme.palette.custom.primary}`:`${theme.palette.custom.background}`,
    },
  })
);

export const CustomCellSticky = styled(TableCell)(
  ({ theme, type, minWidth = "100px" }) => ({
    [`&.${tableCellClasses.body}`]: {
      minWidth: type !== "large" ? minWidth : "300px",
      border: "none",
      padding: 0,
      paddingBlock: "6px",
      paddingLeft: type === "large" ? "5px" : "",
      textAlign: type === "large" ? "left" : "center",
      fontWeight: type === "large" ? "bold" : "",
      left: 0,
      position: "sticky",
      background: `${theme.palette.custom.background}`,
    },
  })
);
export const CustomCell = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.body}`]: {
    minWidth: type === "large" ? "300px" : "100px",
    border: "none",
    padding: 0,
    paddingBlock: "6px",
    paddingLeft: type === "large" ? "5px" : "",
    textAlign: type === "large" ? "left" : "center",
    fontWeight: type === "large" ? "bold" : "",
  },
}));

export const StyledTableCellHeader = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.head}`]: {
    background: `${theme.palette.custom.secondary}`,
    color: `${theme.palette.custom.primary}`,
    height: "3.2rem",
    minWidth: type === "large" ? "4rem" : "2rem",
    padding: "8px",
    border: "none",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "2rem",
  },
}));
export const StyledTableCell = styled(TableCell)(({ theme, type }) => ({
  padding: 0,

  [`&.${tableCellClasses.head}`]: {
    background: `${theme.palette.custom.secondary}`,
    color: `${theme.palette.custom.primary}`,
    height: "3.5rem",
    minWidth: type === "large" ? "4rem" : "2rem",
    paddingLeft: type === "large" ? "4rem" : "",
  },

  [`&.${tableCellClasses.head} .styleHead`]: {
    background: `${theme.palette.custom.secondary}`,
    color: `${theme.palette.custom.primary}`,
    height: "3.5rem",
    fontSize: "1rem",
    fontWeight: "bold",
    align: "left",
    paddingLeft: "2rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "2.8rem",
  },
}));

export const StyledTableCellHeaderSticky = styled(TableCell)(
  ({ theme, type }) => ({
    [`&.${tableCellClasses.head}`]: {
      background: `${theme.palette.custom.secondary}`,
      color: `${theme.palette.custom.primary}`,
      height: "3.2rem",
      minWidth: type === "large" ? "4rem" : "2rem",
      padding: "8px",
      border: "none",
      left: 0,
      position: "sticky",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      height: "2rem",
    },
  })
);
