import React from "react";
import { SvgIcon } from "@mui/material";

export function ForecastIcon() {
  const classForecastIcon = {
    strokeWidth: "1.5px",
    strokeLinejoin: "round",
    fill: "none",
    stroke: "#1F1C36",
    strokeLinecap: "round",
  };
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M0.750 12.000 A11.250 11.250 0 1 0 23.250 12.000 A11.250 11.250 0 1 0 0.750 12.000 Z"
          style={classForecastIcon}
        />
        <path d="M15.75 17.25L15.75 11.25" style={classForecastIcon} />
        <path d="M17.25 17.25L6.75 17.25 6.75 8.25" style={classForecastIcon} />
        <path d="M9.75 17.25L9.75 12" style={classForecastIcon} />
        <path d="M12.75 17.25L12.75 9.75" style={classForecastIcon} />
      </g>
    </SvgIcon>
  );
}
