import * as type from "./types";
import { createRoutine } from "redux-saga-routines";
export function createBrand(action) {
  return {
    type: type.CREATE_BRAND_REQUEST,
    payload: action,
  };
}

export const getLoadBrands = createRoutine(type.GET_LOAD_BRANDS);

export function updateBrand(action) {
  return {
    type: type.UPDATE_BRAND_REQUEST,
    payload: action,
  };
}
export function deleteBrand(action) {
  return {
    type: type.DELETE_BRAND_REQUEST,
    payload: action,
  };
}

export function clearResponseBrand() {
  return {
    type: type.CLEAR_RESPONSE_BRAND,
  };
}
