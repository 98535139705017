import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";
import {
  ArrowCellSimulationSticky,
  CustomCell,
  HeadingCell,
  CustomCellSticky,
} from "./StyleRowSimulationStk";
import { commaSeparateNumber } from "../../../../Utils";

export const RowSimulationStkQk = ({ colSpan, skus, name }) => {
  const [t, i18n] = useTranslation("global");
  const [qtyType, setQtyType] = useState(name);
  const [open, setOpen] = useState(false);

  const getColorCell = (value) => {
    if (value === 0) {
      return {
        color: "gray",
        fontWeight: "bold",
      };
    } else
      return {
        color: value < 0 ? "red" : "green",
        fontWeight: "bold",
      };
  };

  const buildGroup = (col, type, skuId) => {
    return (
      <>
        <CustomCell key={`${type}-${skuId}-${col[type][0].toFixed(2)}`}>
          {commaSeparateNumber(col[type][0].toFixed(2))}
        </CustomCell>
        <CustomCell key={`${type}-${skuId}-${col[type][1].toFixed(2)}`}>
          {commaSeparateNumber(col[type][1].toFixed(2))}
        </CustomCell>
        <CustomCell
          key={`${type}-${skuId}-${col[type][2].toFixed(2)}`}
          sx={
            col[type][0].toFixed(2) !== col[type][1].toFixed(2) &&
            getColorCell(col[type][2].toFixed(2))
          }
        >
          {commaSeparateNumber(col[type][2].toFixed(2))}
        </CustomCell>
      </>
    );
  };

  return (
    <>
      <TableRow
        className="expand-tr"
        sx={{ border: "none", "& > *": { borderBottom: "unset" } }}
      >
        <ArrowCellSimulationSticky>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <div
              onClick={() => {
                setQtyType(
                  qtyType === "quantity_kg" ? "quantity" : "quantity_kg"
                );
              }}
            >
              <Tooltip title="Click para cambiar">
                <span className="title-row">
                  {t(`price_management.trend.${qtyType}`)}
                </span>
              </Tooltip>
            </div>
          </div>
        </ArrowCellSimulationSticky>
        <HeadingCell colSpan={colSpan}></HeadingCell>
      </TableRow>

      <TableRow className="collapse-tr">
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingInline: 0,
            border: "none",
          }}
          colSpan={colSpan + 1}
        >
          <Collapse className="collapse" in={open} timeout="auto" unmountOnExit>
            <Table className="nestedTable">
              <TableBody>
                {skus?.map((row, index) => (
                  <TableRow
                    key={`qk-row-${index}`}
                    sx={{ "& > *": { borderBottom: "unset" } }}
                  >
                    <CustomCellSticky type="large">{row.name}</CustomCellSticky>
                    {row.table.map((col) => {
                      return buildGroup(col, qtyType, row.id);
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
