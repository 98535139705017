import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { token } from "../auth/selectors";
import {
  CREATE_SKU_FAILURE,
  CREATE_SKU_REQUEST,
  CREATE_SKU_SUCCESS,
  GET_SKUS_BY_CATEGORY_ID_FAILURE,
  GET_SKUS_BY_CATEGORY_ID_REQUEST,
  GET_SKUS_BY_CATEGORY_ID_SUCCESS,
  UPDATE_SKU_FAILURE,
  UPDATE_SKU_REQUEST,
  UPDATE_SKU_SUCCESS,
  DELETE_SKU_FAILURE,
  DELETE_SKU_REQUEST,
  DELETE_SKU_SUCCESS,
} from "./types";

import {
  ADD_SKU_TO_CATEGORY,
  UPDATE_SKU_IN_CATEGORY,
  DELETE_SKU_OF_CATEGORY,
} from "../categories/types";

function* createSku({ payload }) {
  try {
    const tokenAccess = yield select(token);

    let skus = payload.selectedBrands.map((brandId) => {
      return {
        name: payload.name,
        unit_weight: payload.unitWeight,
        uom_id: payload.uomId,
        package: payload.package,
        cost: payload.cost,
        brand_id: brandId,
        image: payload.image
          ? payload.image.substring(payload.image.indexOf("base64,") + 7)
          : null,
        internal_code: payload.internalCode,
        subcategory_id: payload.subcategoryId,
      };
    });

    let responses = [];

    for (const sku of skus) {
      const response = yield call(
        callApiAxios,
        "POST",
        "pricing.sku/",
        tokenAccess,
        {},
        sku
      );
      responses.push(response.data);
      yield put({
        type: ADD_SKU_TO_CATEGORY,
        payload: { skus: response.data, categoryId: payload.categoryId },
      });
    }

    yield put({ type: CREATE_SKU_SUCCESS, skus: responses });
  } catch (e) {
    yield put({
      type: CREATE_SKU_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* updateSku({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const body = {
      name: payload.name,
      unit_weight: payload.unitWeight,
      uom_id: payload.uomId,
      package: payload.package,
      cost: payload.cost,
      brand_id: payload.brandId,
      image: payload.image
        ? payload.image.substring(payload.image.indexOf("base64,") + 7)
        : null,
      internal_code: payload.internalCode,
      subcategory_id: payload.subcategoryId,
    };

    const response = yield call(
      callApiAxios,
      "POST",
      `pricing.sku/${payload.id}`,
      tokenAccess,
      {},
      body
    );
    yield put({
      type: UPDATE_SKU_SUCCESS,
      payload: response.data[0],
    });
    yield put({
      type: UPDATE_SKU_IN_CATEGORY,
      payload: { sku: response.data[0], categoryId: payload.categoryId },
    });
  } catch (e) {
    yield put({
      type: UPDATE_SKU_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* deleteSku({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const response = yield call(
      callApiAxios,
      "POST-PARAMS",
      `pricing.sku/${payload.id}/del`,
      tokenAccess,
      {},
      {}
    );
    yield put({
      type: DELETE_SKU_SUCCESS,
      payload: response.data,
    });
    yield put({ type: DELETE_SKU_OF_CATEGORY, sku: payload });
  } catch (e) {
    yield put({
      type: DELETE_SKU_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

export default function* skusSaga() {
  yield takeEvery(CREATE_SKU_REQUEST, createSku);
  yield takeEvery(UPDATE_SKU_REQUEST, updateSku);
  yield takeEvery(DELETE_SKU_REQUEST, deleteSku);
}
