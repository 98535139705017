import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableRow,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getMomentumLoaded } from "../../../../Redux/modules/sniper/momentum/selectors";
import { commaSeparateNumber } from "../../../../Utils";
import { CustomCell, CustomCellSticky } from "./Styles";
import TableMomentum from "./TableMomentum";
import GraphMomentum from "./GraphMomentum";
import GridMomentum from "./GridMomentum";
import imagePng from "../../../../assets/formula.png";

export default function AccordionsMomentum() {
  const momentumLoaded = useSelector(getMomentumLoaded);
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");

  const accordions = [
    { title: "gaps_vs_competidor", type: "table" },
    { title: "relative_prices", type: "table" },
    { title: "category", type: "chart" },
    { title: "elasticity_price", type: "chart" },
    { title: "cross_elasticity", type: "chart" },
  ];

  const getContent = (item) => {
    let template = <></>;
    switch (item.type) {
      case "table":
        template = (
          <TableMomentum
            name={item.title}
            key={item.title}
            rows={momentumLoaded[item.title].object.rows}
            columns={momentumLoaded?.columns}
          ></TableMomentum>
        );
        break;
      case "chart":
        let rowsFormated = [];
        switch (item.title) {
          case "cross_elasticity":
            rowsFormated = momentumLoaded.response.map((row, index) => {
              return {
                [0]: commaSeparateNumber(
                  row.object.cross_elasticity.toFixed(2)
                ),
                [1]: `${commaSeparateNumber(
                  row.target.cross_elasticity.toFixed(2)
                )}`,
                [2]: parseFloat(
                  (
                    row.object.cross_elasticity -
                    Math.abs(row.target.cross_elasticity)
                  ).toFixed(2)
                ),
              };
            });

            template = (
              <div>
                <GridMomentum
                  name={item.title}
                  key={item.title}
                  rows={rowsFormated}
                  legend={
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "30px",
                        alignItems: "center",
                        padding: "10px",
                        borderRadius: "10px",
                        gridColumn: 2,
                        border: "1px solid #CDCACE",
                        width:"500px"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "17px",
                          fontWeight: 700,
                          marginRight: "15px",
                          marginLeft: "15px",
                        }}
                      >
                        Vulnerabilidad
                      </span>
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#407D68",
                          marginLeft: "15px",
                          marginRight: "15px",
                        }}
                      ></div>
                      <span>
                        <span
                          style={{
                            fontSize: "30px",
                            fontWeight: 800,
                          }}
                        >
                          e
                        </span>
                        <sup>R-C</sup>
                        {" < "}
                        <span
                          style={{
                            fontSize: "30px",
                            fontWeight: 800,
                          }}
                        >
                          e
                        </span>
                        <sup>C-R</sup>
                      </span>
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#FF0000",
                          marginLeft: "15px",
                          marginRight: "15px",
                        }}
                      ></div>
                      <span>
                        <span
                          style={{
                            fontSize: "30px",
                            fontWeight: 800,
                          }}
                        >
                          e
                        </span>
                        <sup>R-C</sup>
                        {" > "}
                        <span
                          style={{
                            fontSize: "30px",
                            fontWeight: 800,
                          }}
                        >
                          e
                        </span>
                        <sup>C-R</sup>
                      </span>
                    </div>
                  }
                  rowNames={[
                    <span
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "30px",
                          fontWeight: 800,
                        }}
                      >
                        e
                      </span>
                      {" C - R"}
                    </span>,
                    <span
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "30px",
                          fontWeight: 800,
                        }}
                      >
                        e
                      </span>
                      {" R - C"}
                    </span>,
                  ]}
                  columns={momentumLoaded?.columns}
                >
                  <GraphMomentum
                    rows={momentumLoaded?.response}
                    type={item.title}
                    columns={momentumLoaded?.columns}
                  />
                </GridMomentum>
              </div>
            );

            break;
          case "elasticity_price":
            rowsFormated = momentumLoaded.response.map((row, index) => {
              return {
                [0]: commaSeparateNumber(row.object.elasticity.toFixed(2)),
                [1]: `${commaSeparateNumber(row.target.elasticity.toFixed(2))}`,
                [2]: parseFloat(
                  (
                    row.object.elasticity - Math.abs(row.target.elasticity)
                  ).toFixed(2)
                ),
              };
            });

            template = (
              <div>
                <GridMomentum
                  name={item.title}
                  key={item.title}
                  rows={rowsFormated}
                  legend={
                    <div
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      {" Index "}
                      <br />
                      <span>
                        <span
                          style={{
                            fontSize: "30px",
                            fontWeight: 800,
                          }}
                        >
                          e
                        </span>
                        <sup>R</sup>
                        {" - "}
                        <span
                          style={{
                            fontSize: "30px",
                            fontWeight: 800,
                          }}
                        >
                          e
                        </span>
                        <sup>C</sup>
                      </span>
                    </div>
                  }
                  rowNames={[
                    <span
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "30px",
                          fontWeight: 800,
                        }}
                      >
                        e
                      </span>
                      {" Ref."}
                    </span>,
                    <span
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "30px",
                          fontWeight: 800,
                        }}
                      >
                        e
                      </span>
                      {" Comp."}
                    </span>,
                  ]}
                  columns={momentumLoaded?.columns}
                >
                  <GraphMomentum
                    rows={momentumLoaded?.response}
                    type={item.title}
                    columns={momentumLoaded?.columns}
                  />
                </GridMomentum>
              </div>
            );

            break;
          case "category":
            rowsFormated = momentumLoaded.response.map((row, index) => {
              return {
                [0]: commaSeparateNumber(row.subcategory_elasticity.toFixed(2)),
                [1]: `${commaSeparateNumber(
                  row.subcategory_curve.toFixed(1)
                )}%`,
                [2]: parseFloat(row.subcategory_opportunity.toFixed(2)),
              };
            });

            template = (
              <div>
                <GridMomentum
                  name={item.title}
                  key={item.title}
                  rows={rowsFormated}
                  rowNames={[
                    <span
                      style={{
                        marginLeft: "20px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "30px",
                          fontWeight: 800,
                        }}
                      >
                        e
                      </span>
                      {" CAT"}
                    </span>,
                    <span style={{ marginLeft: "20px" }}>
                      {t(
                        `price_management.sniper_module.momentum_component.concentration`
                      )}
                    </span>,
                  ]}
                  columns={momentumLoaded?.columns}
                >
                  <GraphMomentum
                    rows={momentumLoaded?.response}
                    type={item.title}
                    columns={momentumLoaded?.columns}
                  />
                </GridMomentum>
              </div>
            );
            break;

          default:
            break;
        }
        break;
    }
    return template;
  };

  return (
    <div
      style={{
        paddingTop: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {accordions.map((accordion) => (
        <Accordion
          sx={{
            borderRadius: "10px",
          }}
          key={accordion.title}
        >
          <AccordionSummary
            key={`summary-${accordion.title}`}
            expandIcon={
              <ExpandMoreIcon sx={{ color: "white", fontSize: "1.5rem" }} />
            }
            sx={{
              backgroundColor: (theme) => theme.palette.custom.secondary,
              borderRadius: "10px",
              zIndex: 2000,
            }}
            aria-controls={accordion.title}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.custom.primary,
                paddingTop: "3px",
                paddingLeft: "10px",
              }}
              fontSize={18}
              fontWeight={700}
            >
              {t(
                `price_management.sniper_module.momentum_component.accordions.${accordion.title}`
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails key={`details-${accordion.title}`}>
            {getContent(accordion)}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
