export const momentumState = (state) => state.momentum;

export const getLoadingMomentum = (state) => momentumState(state).loading;

export const getFormDataMomentum = (state) => momentumState(state).formData;
export const getSimulationDataMomentum = (state) => momentumState(state).simulationData;

export const getMomentumSubcategoryId = (state) =>
  momentumState(state).formData.subcategoryId;

export const getMomentumLoaded = (state) => momentumState(state).momentum;

export const getMomentumSimulationData = (state) =>
  momentumState(state).formData.simulationMomentum;

export const getMomentumFunctionType = (state) =>
  momentumState(state).formData.typePrice;

export const getVariablesSelected = (state) =>
  momentumState(state).formData.variablesSelected;

export const getSkuSelected = (state) =>
  momentumState(state).momentum.subcategory_ids[0].sku_ids.find(
    (sku) => sku.id === momentumState(state).formData.skuId
  );

export const getResponseMomentum = (state) => momentumState(state).response;

export const getSkuGraph = (state) =>
  momentumState(state).momentum.subcategory_ids[0].sku_ids.find(
    (sku) => sku.id === momentumState(state).formData.skuIdGraph
  );

export const getSelectedSkus = (state) => {
  const currentMomentum = momentumState(state).momentum;
  let filters = momentumState(state).formData.skuIds;
  const subcategory =
    currentMomentum?.subcategory_ids && currentMomentum?.subcategory_ids[0];
  const skus = subcategory?.sku_ids.filter((sku) => filters.includes(sku.id));
  return skus;
};
export const getColumnsTableMomentum = (state) => {
  const currentMomentum = momentumState(state).momentum;
  const subcategory =
    currentMomentum?.subcategory_ids && currentMomentum?.subcategory_ids[0];
  const skus = subcategory?.sku_ids;
  const cols = skus.length > 0 ? skus[0].table : [];
  return cols;
};
