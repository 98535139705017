import React from 'react';
import { useScreenshot } from "use-react-screenshot";
import { makeStyles } from '@mui/styles';

import Fab from '@mui/material/Fab';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';

const useStyles = makeStyles((theme)=>({
    circularButon: {
        margin: '.5em',
        marginRight: '0',
        padding: '1em',
        width: '1em',
        height: '1em',
        borderRadius: '50%',
        transition: 'all .25s',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#ecf0f1',
            borderColor: '#95a5a6'
        }
    },
    alert: {
        position:'fixed',
        bottom: '1em',
        right: '1em',
    },
    container: {
        overflow: 'hidden',
        position: 'relative'
    },
    capture: {
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,.5)',
        left: 0,
        top: 0, 
        width: '100%',
        height: '100%',
        zIndex: '100'
    }
}));

const ScreenShot = ({children, onCapture}) => {
    const classes = useStyles();

    const ref = React.createRef(null);
    const [image, takeScreenShot] = useScreenshot();
    const [loaded, setLoaded] = React.useState(true);
    const getImage = () => {
        takeScreenShot(ref.current);
    };

    function downloadBase64File( base64Data, fileName) {
        const linkSource = `${base64Data}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    const handleDownload = () => {
        takeScreenShot(ref.current);
        setLoaded(true);
 
    }

    return (
        <>
            <div ref={ref} className={classes.container}>
                {children}
            </div>
         
        </>
    )
}

export default ScreenShot;