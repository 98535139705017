import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { getCustomers } from "../../../Redux/modules/customers/actions";
import { getPromotions } from "../../../Redux/modules/promotion-planning/promotions/actions";
import {
  PlIcon,
  StreamLineIcon,
  TicketIcon,
} from "../../../Components/CustomIcons";
import Tabs from "../../../Components/Tabs";
import { setCurrentView } from "../../../Redux/modules/layout/actions";
import { clearPromotionsSimulation } from "../../../Redux/modules/promotion-planning/promotions/actions";
import Promotions from "./Promotions";
import Volumes from "./Volumes";
import PL from "./PL";
const Wrapper = styled.div`
  .title {
    margin-bottom: 2em;
    .page-name {
      display: flex;
      align-items: flex-start;
      .caption {
      }
    }
  }
`;

const PromotionPlanning = () => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    dispatch(getCustomers.trigger());
    dispatch(getPromotions.trigger());
  }, []);

  const options = [
    {
      content: <Promotions setCurrentTab={setCurrentTab} />,
      view: "promotions",
      name: `${t(
        "sales_planning.promotion_planning_module.promotions_component.title"
      )}`,
      icon: <TicketIcon style={{ marginRight: "3px" }} />,
    },
    {
      content: <Volumes />,
      view: "volumes",
      name: `${t(
        "sales_planning.promotion_planning_module.volumes_component.title"
      )}`,
      icon: <StreamLineIcon style={{ marginRight: "3px" }} />,
    },
    {
      content: <PL />,
      view: "pl",
      name: `${t(
        "sales_planning.promotion_planning_module.pl_component.title"
      )}`,
      icon: <PlIcon style={{ marginRight: "3px" }} />,
    },
  ];

  const handleTabSelected = (index) => {
    const tab = options[index];
    setCurrentTab(index);
    dispatch(setCurrentView(tab.view));
  };

  useEffect(() => {
    handleTabSelected(0);
    return () => {
      dispatch(clearPromotionsSimulation());
    };
  }, []);

  return (
    <Wrapper>
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <StreamLineIcon
          style={{ fontSize: "40px", marginRight: "10px" }}
        ></StreamLineIcon>
        <Stack>
          <Typography sx={{ fontSize: "25px", fontWeight: "700" }}>
            {t("drawer_component.promo_lab")}
          </Typography>
          <Typography color="text.primary">
            {t("drawer_component.promotion_planning")}
          </Typography>
        </Stack>
      </div>
      <Tabs
        setTabSelected={handleTabSelected}
        tabs={options}
        defaultValue={currentTab}
      />
    </Wrapper>
  );
};

export default PromotionPlanning;
