import React from "react";
import { SvgIcon } from "@mui/material";

export function FileUpload(props) {
    const classFileUpload = {
      fill: "none",
      strokelinecap: "round",
      strokelinejoin: "round",
      strokewidth: "1.5",
    };
    return (
      <SvgIcon {...props}>
        <svg viewBox="0 0 48 48">
          <g transform="matrix(2,0,0,2,0,0)">
            <path
              d="M11.250 17.250 A6.000 6.000 0 1 0 23.250 17.250 A6.000 6.000 0 1 0 11.250 17.250 Z"
              style={classFileUpload}
            />
            <path d="M17.25 20.25L17.25 14.25" style={classFileUpload} />
            <path d="M17.25 14.25L15 16.5" style={classFileUpload} />
            <path d="M17.25 14.25L19.5 16.5" style={classFileUpload} />
            <path
              d="M8.25,20.25h-6a1.5,1.5,0,0,1-1.5-1.5V2.25A1.5,1.5,0,0,1,2.25.75H12.879a1.5,1.5,0,0,1,1.06.439l2.872,2.872a1.5,1.5,0,0,1,.439,1.06V8.25"
              style={classFileUpload}
            />
          </g>
        </svg>
        ;
      </SvgIcon>
    );
  }