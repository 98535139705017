import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CheckList from "../../../../Components/CheckList";
import { AlertCircleIcon } from "../../../../Components/CustomIcons";
import { ExpandIcon } from "../../../../Components/CustomIcons/ExpandIcon";
import FormControl from "../../../../Components/FormControl";
import CustomPagination from "../../../../Components/Pagination";
import ItemPromotion from "./ItemPromotion";
import { RoundedButton } from "./Styles";
import FormPromotions from "./FormPromotions";
import { CustomModal } from "../../../../Components/Modal";
import { useModal } from "../../../../Hooks/useModal";
import {
  clearPromotionsSimulation,
  deletePromotion,
  setFormDataPromotions,
  updatePromotion,
} from "../../../../Redux/modules/promotion-planning/promotions/actions";
import SaveMenu from "../../../../Components/SaveMenu";
import { exportToExcel } from "../../../../Utils";
import { getFormDataPromotions } from "../../../../Redux/modules/promotion-planning/promotions/selectors";
const CssFormControl = styled(FormControl)(({ theme }) => ({
  stroke: theme.palette.custom.text,
  "& label": {
    color: theme.palette.custom.text,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    color: theme.palette.custom.thirdVariant,
  },
  "& .MuiInputAdornment-root": {
    color: theme.palette.custom.text,
  },
  "&:hover label, &:hover .MuiInputAdornment-root": {
    color: theme.palette.custom.thirdVariant,
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.custom.text,
      borderWidth: "3px",
      borderRadius: "1em",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.custom.thirdVariant,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.custom.thirdVariant,
    },
  },
}));

export default function ListPromotions({ promotions, simulationData, setSimulationData }) {
  const skipPages = [5, 10, 20];
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const [searchText, setSearchText] = useState("");
  const [promotionsSelectedIds, setPromotionsSelectedIds] = useState([]);
  const promotionsIds = promotions.map((x) => x.id);
  const filterPromotions = promotionsSelectedIds;
  const { isOpen, openModal, okModal, cancelModal, closeModal } = useModal();
  const [configModal, setConfigModal] = useState({
    title: "",
    type: "edit",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    pages:
      promotions.length > skipPages[0]
        ? Math.floor(promotions.length / skipPages[0])
        : 1,  
    skip: skipPages[0],
  });

  const [expandedList, setExpandedList] = useState([]);
  const [isAllExpanded, setIsAllxpanded] = useState(false);

  const handleItemClear = (item) => {
    dispatch(clearPromotionsSimulation({ type: "promotion", id: item.id }));
  };
  const handleItemEdit = (item) => {
    const config = {
      title: t(
        "sales_planning.promotion_planning_module.promotions_component.modals.edit_promotion"
      ),
      type: "edit",
      cancelText: t("components_globals.button_cancel"),
      acceptText: t("components_globals.button_update"),
      item,
    };
    dispatch(setFormDataPromotions({ edit: true, ...item }));
    setConfigModal(config);
    openModal(config);
  };
  const handleItemDelete = (item) => {
    const config = {
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          {`${t(
            "sales_planning.promotion_planning_module.promotions_component.modals.delete_promotion"
          )} `}
          <br />
          <Typography variant="body" fontWeight={600}>
            {item.name}
          </Typography>
        </div>
      ),
      type: "delete",
      cancelText: t("components_globals.button_cancel"),
      acceptText: t("components_globals.button_ok"),
      item,
    };
    setConfigModal(config);
    openModal(config);
  };
  const handleChangeExpandedAll = (e) => {
    setIsAllxpanded(!isAllExpanded);
    if (!isAllExpanded) {
      setExpandedList(promotionsIds);
    } else {
      setExpandedList([]);
    }
  };
  const handleChangePagination = (e) => {
    const skip = e.target.value;
    const pages = promotions.length / skip;
    setPagination({ ...pagination, pages: pages, page: 1, skip: skip });
  };
  const handleChangePagePagination = (e) => {
    const page = e.target.value;
    setPagination({ ...pagination, page: page });
  };
  const handleOkModal = (e) => {
    switch (configModal.type) {
      case "edit":
        dispatch(updatePromotion.trigger());
        break;

      case "delete":
        dispatch(deletePromotion.trigger({ id: configModal.item.id }));
        break;
    }
    okModal();
  };

  const isExpandedList = (id) => {
    return expandedList.includes(id);
  };
  const changeDate = (date) => {
    const stringSplitted = date.split("-");
    return `${stringSplitted[2]}/${stringSplitted[1]}/${stringSplitted[0]}`;
  };
  const handleChangeExpandedList = (id) => {
    if (isExpandedList(id)) {
      const newArray = expandedList.filter((x) => x !== id);
      setExpandedList(newArray);
    } else {
      const newArray = expandedList.concat([id]);
      setExpandedList(newArray);
    }
  };

  const handleChangeSelectedPromotions = (value) => {
    if (value[0] !== undefined || value.length === 0) {
      setPromotionsSelectedIds(value);
    }
  };

  const handleDownloadExcel = () => {
    try {
      let tables = document.getElementsByClassName(`tablePromotion`);
      let sheets = [];
      for (let table of tables) {
        let excelTable = document.createElement("table");
        excelTable.innerHTML = table.innerHTML.trim();
        const tbody = excelTable.querySelector("tbody");

        const customerCells =
          excelTable.getElementsByClassName("customerImage");
        for (let index = 0; index < customerCells.length; index++) {
          const customerName =
            customerCells[index].getAttribute("customerName");
          customerCells[index].innerHTML = customerName;
        }
        if (tbody) {
          let inputs = tbody.querySelectorAll("input");
          for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            const parentTd = input.parentNode;
            parentTd.className = "";
            parentTd.innerHTML = input.value;
          }
        }
        sheets.push(excelTable);
      }
      const promotionNames = promotions.map((x) => x.name);
      exportToExcel("promotions", sheets, null, "sheets", promotionNames);
    } catch (error) {}
  };

  const handleChangeFilter = (e) => {};

  const promotionsFiltered =
    filterPromotions.length > 0
      ? promotions.filter((x) => filterPromotions.includes(x.id))
      : promotions;

  return (
    <div
      style={{
        paddingTop: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <CheckList
          style={{ width: "300px" }}
          labelName={t(
            "sales_planning.promotion_planning_module.promotions_component.form.inputs.select_promotions"
          )}
          items={promotions}
          selectedItems={filterPromotions}
          onChangeSelectItems={handleChangeSelectedPromotions}
          onBuildItemName={(option) => {
            return `${option.name}`;
          }}
        ></CheckList>
        <RoundedButton backgroundColor="text" onClick={handleChangeExpandedAll}>
          <ExpandIcon
            style={{ paddingLeft: "2px", fontSize: "1rem" }}
          ></ExpandIcon>
          <span
            style={{ width: "200px", fontSize: "0.9rem", textAlign: "center" }}
          >
            {`${
              !isAllExpanded
                ? t("components_globals.button_expand")
                : t("components_globals.button_contract")
            }  ${t(
              "sales_planning.promotion_planning_module.promotions_component.title"
            )}`}
          </span>
          <AlertCircleIcon
            style={{ paddingRight: "2px", fontSize: "1.5rem" }}
          ></AlertCircleIcon>
        </RoundedButton>
        <SaveMenu
          style={{ marginRight: "32px" }}
          buttons={["export"]}
          list={["excel"]}
          // handlePdf={handlePdf}
          handleExcel={handleDownloadExcel}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{ cursor: "pointer", width: "100px" }}
            onClick={(e) => handleChangeFilter("")}
          >{`${t("initial_setup.header_list.view_all")}`}</span>
          <CssFormControl>
            <Select
              sx={{ width: "100px", marginRight: "15px" }}
              value={pagination.skip}
              onChange={handleChangePagination}
            >
              {promotions.length > skipPages[0] ? (
                skipPages &&
                skipPages.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option}>
                      {`${option}  `}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem key={1} value={skipPages[0]}>
                  {`${skipPages[0]}  `}
                </MenuItem>
              )}
            </Select>
          </CssFormControl>
        </div>
      </div>
      {promotionsFiltered.map((promotion) => (
        <Accordion
          sx={{
            borderRadius: "10px",
          }}
          key={promotion.name}
          expanded={isExpandedList(promotion.id)}
          onChange={(e) => handleChangeExpandedList(promotion.id)}
        >
          <AccordionSummary
            key={`summary-${promotion.name}`}
            expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.5rem" }} />}
            aria-controls={promotion.name}
          >
            <Typography
              sx={{
                paddingTop: "3px",
                paddingLeft: "10px",
              }}
              fontSize={18}
              fontWeight={700}
            >
              {`${promotion.name} `}
            </Typography>{" "}
            <Typography
              sx={{
                paddingTop: "3px",
                paddingLeft: "10px",
              }}
              fontSize={16}
              fontWeight={600}
            >
              {`${changeDate(promotion.start_date)}-${changeDate(
                promotion.end_date
              )}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails key={`details-${promotion.name}`}>
            <ItemPromotion
              key={promotion.name}
              onDeleteItem={handleItemDelete}
              onItemEdit={handleItemEdit}
              onItemClear={handleItemClear}
              promotion={promotion}
              simulationData={simulationData}
              setSimulationData={setSimulationData}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CustomPagination
          count={pagination.pages}
          page={pagination.page}
          onChange={handleChangePagePagination}
        />
        <CustomModal
          customWidth="600px"
          title={configModal.title}
          type={configModal.type}
          open={isOpen}
          onCloseModal={closeModal}
          onCancelModal={cancelModal}
          onOkModal={handleOkModal}
          cancelText={configModal.cancelText}
          acceptText={configModal.acceptText}
        >
          {configModal.type === "edit" && <FormPromotions type="edit" />}
        </CustomModal>
      </div>
    </div>
  );
}
