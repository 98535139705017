export const GET_RELATION_SKU = 'GET_RELATION_SKU';
export const GET_RELATION_SKU_SUCCESS = 'GET_RELATION_SKU_SUCCESS';
export const GET_RELATION_SKU_FAILURE = 'GET_RELATION_SKU_FAILURE';

export const CREATE_RELATION_SKU_REQUEST = 'CREATE_RELATION_SKU_REQUEST';
export const CREATE_RELATION_SKU_SUCCESS = 'CREATE_RELATION_SKU_SUCCESS';
export const CREATE_RELATION_SKU_FAILURE = 'CREATE_RELATION_SKU_FAILURE';

export const PUT_RELATION_SKU = 'PUT_RELATION_SKU';
export const PUT_RELATION_SKU_SUCCESS = 'PUT_RELATION_SKU_SUCCESS';
export const PUT_RELATION_SKU_FAILURE = 'PUT_RELATION_SKU_FAILURE';

export const DELETE_RELATION_SKU_REQUEST = 'DELETE_RELATION_SKU_REQUEST';
export const DELETE_RELATION_SKU_SUCCESS = 'DELETE_RELATION_SKU_SUCCESS';
export const DELETE_RELATION_SKU_FAILURE = 'DELETE_RELATION_SKU_FAILURE';

export const GET_RELATION_SKU_BY_ID = 'GET_RELATION_SKU_BY_ID';
export const GET_RELATION_SKU_BY_ID_SUCCESS = 'GET_RELATION_SKU_BY_ID_SUCCESS';
export const GET_RELATION_SKU_BY_ID_FAILURE = 'GET_RELATION_SKU_BY_ID_FAILURE';

export const CLEAR_RESPONSE_RELATION = 'CLEAR_RESPONSE_RELATION';

export const SET_TAB_SELECTED = 'SET_TAB_SELECTED';
