import { useState, useRef, useEffect } from "react";
import { AlertCircleIcon } from "../../../../Components/CustomIcons";
import { ExpandIcon } from "../../../../Components/CustomIcons/ExpandIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SaveMenu from "../../../../Components/SaveMenu";
import { RoundedButton } from "./Styles";
import TablePL from "./TablePL";
import { exportToExcel } from "../../../../Utils";
import { useReactToPrint } from "react-to-print";

export default function ListPL({ promotions }) {
  const [t, i18n] = useTranslation("global");
  const promotionsIds = promotions.map((x) => x.id);
  const [expandedList, setExpandedList] = useState([]);
  const [expandedPL, setExpandedPL] = useState([]);
  const [isAllExpanded, setIsAllxpanded] = useState(false);
  const [isAllExpandedPL, setIsAllxpandedPL] = useState(false);

  const handleDownloadPdf = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "pl",
    pageStyle: { with: "100%" },
  });

  const pdfRef = useRef(null);

  const handleChangeExpandedAll = (e) => {
    setIsAllxpanded(!isAllExpanded);
    if (!isAllExpanded) {
      setExpandedList(promotionsIds);
    } else {
      setExpandedList([]);
    }
    setIsAllxpandedPL(!isAllExpandedPL);
    if (!isAllExpandedPL) {
      setExpandedPL(promotionsIds);
    } else {
      setExpandedPL([]);
    }
  };
  const handleChangeExpandedPL = (id) => {
    if (isExpandedListPL(id)) {
      const newArray = expandedPL.filter((x) => x !== id);
      setIsAllxpandedPL(false);
      setExpandedPL(newArray);
    } else {
      const newArray = expandedPL.concat([id]);
      setExpandedPL(newArray);
    }
  };
  const isExpandedListPL = (id) => {
    return expandedPL.includes(id);
  };
  const isExpandedList = (id) => {
    return expandedList.includes(id);
  };
  const handleChangeExpandedList = (id) => {
    if (isExpandedList(id)) {
      const newArray = expandedList.filter((x) => x !== id);
      setIsAllxpanded(false);
      setExpandedList(newArray);
    } else {
      const newArray = expandedList.concat([id]);
      setExpandedList(newArray);
    }
  };
  const handleDownloadExcel = (id) => {
    try {
      let className = id ? `tableSimulation_${id}` : `tableSimulation`;
      let tables = document.getElementsByClassName(className);
      let sheets = [];
      for (let table of tables) {
        let excelTable = document.createElement("table");
        excelTable.innerHTML = table.innerHTML.trim();
        const tbody = excelTable.querySelector("tbody");

        const customerCells =
          excelTable.getElementsByClassName("customerImage");
        for (let index = 0; index < customerCells.length; index++) {
          const customerName =
            customerCells[index].getAttribute("customerName");
          customerCells[index].innerHTML = customerName;
        }
        sheets.push(excelTable);
      }
      let promotionNames = [];
      if (id) {
        promotionNames = promotions
          .filter((x) => x.id === id)
          .map((x) => x.name);
      } else {
        promotionNames = promotions.map((x) => x.name);
      }
      exportToExcel("pl", sheets, null, "sheets", promotionNames);
    } catch (error) {}
  };
  return (
    <div
      style={{
        paddingTop: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <RoundedButton backgroundColor="text" onClick={handleChangeExpandedAll}>
          <ExpandIcon
            style={{ paddingLeft: "2px", fontSize: "1rem" }}
          ></ExpandIcon>
          <span
            style={{
              width: "200px",
              fontSize: "0.9rem",
              textAlign: "center",
            }}
          >
            {`${
              !isAllExpanded
                ? t("components_globals.button_expand")
                : t("components_globals.button_contract")
            }  ${t(
              "sales_planning.promotion_planning_module.promotions_component.title"
            )}`}
          </span>
          <AlertCircleIcon
            style={{ paddingRight: "2px", fontSize: "1.5rem" }}
          ></AlertCircleIcon>
        </RoundedButton>
        <SaveMenu
          style={{ marginRight: "32px" }}
          buttons={["export"]}
          list={["excel", "pdf"]}
          handleExcel={handleDownloadExcel}
          handlePdf={handleDownloadPdf}
        />
      </div>
      <div ref={pdfRef}>
        {promotions.map((promotion) => (
          <Accordion
            style={{ borderRadius: "10px" }}
            expanded={expandedList.includes(promotion.id)}
            onChange={() => handleChangeExpandedList(promotion.id)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
              {!expandedList.includes(promotion.id) && (
                <Typography fontWeight={700}>{promotion.name}</Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  paddingTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <RoundedButton
                    backgroundColor="text"
                    onClick={() => handleChangeExpandedPL(promotion.id)}
                  >
                    <ExpandIcon
                      style={{ paddingLeft: "2px", fontSize: "1rem" }}
                    ></ExpandIcon>
                    <span
                      style={{
                        width: "200px",
                        fontSize: "0.9rem",
                        textAlign: "center",
                      }}
                    >
                      {`${
                        !isExpandedListPL(promotion.id)
                          ? t("components_globals.button_expand")
                          : t("components_globals.button_contract")
                      }`}
                    </span>
                    <AlertCircleIcon
                      style={{ paddingRight: "2px", fontSize: "1.5rem" }}
                    ></AlertCircleIcon>
                  </RoundedButton>
                </div>
                <TablePL
                  isExpanded={expandedPL.includes(promotion.id)}
                  key={promotion.id}
                  promotion={promotion}
                  handleDownloadExcel={handleDownloadExcel}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
