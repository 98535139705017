import React from "react";
import { Box, Popover } from "@mui/material/";
import { AlertCircleIcon } from "../../../Components/CustomIcons";

const HelpPopover = ({ open, anchorEl, handlePopoverClose, message }) => {
  
  return (
      <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: "none",
        borderRadius: "30%",
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Box
        textAlign="center"
        p={0.5}
        sx={{
          width: 200,
          height: 100,
          backgroundColor: "#1F1C36",
          color: "#EBCE75",
          stroke: "#EBCE75",
          padding: "10px",
          fontSize: "12px",
        }}
      >
        <div sx={{  fontSize: "18px",marginTop: "2px"}}>
        <AlertCircleIcon />
        </div>
        <span>
          {message}
        </span>
      </Box>
    </Popover>
  );
};
export default HelpPopover;
