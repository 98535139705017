export const initialState = {
  loading: false,
  rows: [],
  values: [],
  formData: {
    periodWidth: null,
    subcategoryId: null,
    recalculateRows: false,
    firstPeriod: new Date(),
    tableType: "total_subcategory",
    columns: [
      { code: "sales_units", name: "Total Q", id: 1 },
      { code: "sales_price", name: "Total P", id: 2 },
    ],
    defaultColumns: [
      { code: "sales_units", name: "Total Q", id: 1 },
      { code: "sales_price", name: "Total P", id: 2 },
    ],
  },
  response: { message: null, error: null },
};
