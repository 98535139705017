import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Components/Button";
import MonthPicker from "../../../../Components/MonthPicker";
import TextField from "../../../../Components/TextField";
import MenuItem from "@mui/material/MenuItem";

import { getCategorySelected } from "../../../../Redux/modules/categories/selectors";
import {
  getLoadMomentum,
  setFormDataMomentum,
} from "../../../../Redux/modules/sniper/momentum/actions";
import { getFormDataMomentum } from "../../../../Redux/modules/sniper/momentum/selectors";
import { monthDiff } from "../../../../Utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useCategorySelected } from "../../../../Hooks/useCategorySelected";
const FormMomentum = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const tempForm = useSelector(getFormDataMomentum);
  const categorySelected = useCategorySelected({
    subcategoryId: tempForm.subcategoryId,
  });
  const { subcategories, skus } = categorySelected;

  return categorySelected.id ? (
    <Accordion style={{ borderRadius: "10px" }} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
        <Typography fontWeight={700}>
          {t("price_management.sniper_module.momentum_component.form.title")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            alignItems: "center",
            gap: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          <TextField
            fullWidth
            select
            variant="third"
            label={t(
              "price_management.sniper_module.momentum_component.form.inputs.subcategory"
            )}
            value={tempForm?.subcategoryId}
            onChange={(e) => {
              const subcategorySelected =
                subcategories &&
                subcategories.find((x) => x.id === e.target.value);
              const skuId = subcategorySelected?.sku_ids[0];
              const sku = skus && skus.find((x) => x.id === skuId);

              let nameSku = "";
              if (sku) {
                nameSku = `${sku.brand_id[1]} ${sku.name} ${sku.unit_weight} ${
                  sku.uom_id ? sku.uom_id[1] : ""
                }${sku.package ? "/" + sku.package : ""}`;
              }
              dispatch(
                setFormDataMomentum({
                  many: true,
                  changes: [
                    {
                      propertyName: "subcategoryId",
                      propertyValue: e.target.value,
                    },
                    {
                      propertyName: "skuId",
                      propertyValue: skuId,
                    },
                    {
                      propertyName: "nameSku",
                      propertyValue: nameSku,
                    },
                  ],
                })
              );
            }}
          >
            {subcategories &&
              subcategories.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                );
              })}
          </TextField>
          <TextField
            fullWidth
            select
            variant="third"
            label={t(
              "price_management.sniper_module.momentum_component.form.inputs.sku"
            )}
            value={tempForm?.skuId}
            onChange={(e) => {
              const skuId = e.target.value;

              dispatch(
                setFormDataMomentum({
                  propertyName: "skuId",
                  propertyValue: skuId,
                })
              );
            }}
          >
            {skus &&
              skus.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {`${option.brand_id[1]} ${option.name} ${
                      option.unit_weight
                    } ${option.uom_id ? option.uom_id[1] : ""}${
                      option.package ? "/" + option.package : ""
                    }`}
                  </MenuItem>
                );
              })}
          </TextField>
          <TextField
            fullWidth
            select
            variant="third"
            label={t(
              "price_management.sniper_module.momentum_component.form.inputs.sku_competitor"
            )}
            value={tempForm?.skuCompetitorId}
            onChange={(e) => {
              const skuId = e.target.value;

              dispatch(
                setFormDataMomentum({
                  propertyName: "skuCompetitorId",
                  propertyValue: skuId,
                })
              );
            }}
          >
            {skus &&
              skus.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {`${option.brand_id[1]} ${option.name} ${
                      option.unit_weight
                    } ${option.uom_id ? option.uom_id[1] : ""}${
                      option.package ? "/" + option.package : ""
                    }`}
                  </MenuItem>
                );
              })}
          </TextField>
          <MonthPicker
            label={t(
              "price_management.sniper_module.momentum_component.form.inputs.month"
            )}
            views={["year", "month"]}
            value={tempForm?.period}
            onChange={(e) => {
              const month = 12 - monthDiff(e, tempForm.maxDate);
              dispatch(
                setFormDataMomentum({
                  propertyName: "month",
                  propertyValue: month,
                })
              );
              dispatch(
                setFormDataMomentum({
                  propertyName: "period",
                  propertyValue: e,
                })
              );
            }}
            maxDate={tempForm.maxDate}
            minDate={tempForm.minDate}
            renderInput={(params) => <TextField variant="third" {...params} />}
          />
          <div></div>
          <div></div>
          <div></div>
          <Button
            onClick={() => {
              let skuId = tempForm.skuId;
              let sku = skus && skus.find((x) => x.id === skuId);
              let nameSku = `${sku.brand_id[1]} ${sku.name} ${
                sku.unit_weight
              } ${sku.uom_id ? sku.uom_id[1] : ""}${
                sku.package ? "/" + sku.package : ""
              }`;
              dispatch(
                setFormDataMomentum({
                  propertyName: "nameSku",
                  propertyValue: nameSku,
                })
              );
              skuId = tempForm.skuCompetitorId;
              sku = skus && skus.find((x) => x.id === skuId);
              nameSku = `${sku.brand_id[1]} ${sku.name} ${sku.unit_weight} ${
                sku.uom_id ? sku.uom_id[1] : ""
              }${sku.package ? "/" + sku.package : ""}`;

              dispatch(
                setFormDataMomentum({
                  propertyName: "nameSkuCompetitor",
                  propertyValue: nameSku,
                })
              );
              dispatch(getLoadMomentum.trigger());
            }}
          >
            {t("components_globals.button_run")}
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};

export default FormMomentum;
