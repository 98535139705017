export const variablesState = (state) => state.variables; 

export const getLoadingVariables = (state) => variablesState(state).loading; 

export const getVariable = (state) => variablesState(state).variable; 

export const getVariables = (state) => variablesState(state).variables;

export const getResponseVariables = (state) => variablesState(state).response;

export const getCurrentValues = (state) => variablesState(state).values;

export const getCurrentTempValues = (state) => variablesState(state).tempValues;

export const getVariablesFormData = (state) => variablesState(state).formData;
