import React from "react";
import TextField from "../../Components/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
const formats = {
  normalDate: "d/MM/yyyy",
  keyboardDate: "dd/MM/yyyy",
};

function CustomDataPicker({
  value,
  label = "",
  onChange,
  views,
  maxDate,
  minDate,
  isDefault = false,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={formats}>
      {!isDefault ? (
        <DatePicker
          label={label}
          views={views ? views : ["day", "month", "year"]}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          renderInput={(params) => (
            <TextField variant="third" type="date" {...params} />
          )}
        />
      ) : (
        <DatePicker
          label={label}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={onChange}
          renderInput={(params) => (
            <TextField variant="third" type="date" {...params} />
          )}
        />
      )}
    </LocalizationProvider>
  );
}

export default CustomDataPicker;
