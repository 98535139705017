import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../../api";
import { token } from "../../auth/selectors";
import {
  getLoadOptimals,
  getSimulateOptimals
} from "./actions";
import { getOptimalPricesFormData } from "./selectors";

function* getLoadOptimalsSaga({ payload }) {
  try {
    yield put(getLoadOptimals.request());
    const tokenAccess = yield select(token);
    const form = yield select(getOptimalPricesFormData);
    let body = {
      month: form.month,
      mode: form.functionType,
    };
    let isSimulating = false;
    const type = "values";
    if (form.simulationOptimals[type].length > 0) {
      let data = {};
      for (const item of form.simulationOptimals[type]) {
        data[`${item.code}`] = parseFloat(item.value);
      }
      body[`${type}`] = data;
      isSimulating = true;
    }
    const params = isSimulating
      ? ""
      : `?month=${form.month}&mode=${form.functionType}`;
    const response = yield call(
      callApiAxios,
      isSimulating ? "POST" : "GET",
      `pricing.sku.category/${payload}/get_month_table${params}`,
      tokenAccess,
      {},
      body
    );
    yield put(getLoadOptimals.success(response.data));
  } catch (e) {
    yield put(
      getLoadOptimals.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getLoadOptimals.fulfill());
  }
}

function* getSimulateOptimalsSaga({ payload }) {
  try {
    yield put(getSimulateOptimals.request());
    const tokenAccess = yield select(token);
    const form = yield select(getOptimalPricesFormData);
    const simulationTypes = ["prices", "increments", "distributions", "costs"];
    let body = {
      month: form.month,
      mode: form.functionType,
    };
    let isSimulating = false;
    for (const type of simulationTypes) {
      if (form.simulationOptimals[type].length > 0) {
        let data = {};
        for (const item of form.simulationOptimals[type]) {
          data[`${item.code}`] = parseFloat(item.value);
        }
        body[`${type === "distributions" ? `${type}2` : type}`] = data;
        isSimulating = true;
      }
    }
    const response = yield call(
      callApiAxios,
      "POST",
      `pricing.sku.category/${payload}/simulate`,
      tokenAccess,
      {},
      body
    );
    yield put(getSimulateOptimals.success(response.data));
  } catch (e) {
    yield put(
      getSimulateOptimals.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getSimulateOptimals.fulfill());
  }
}

export default function* optimalPricesSaga() {
  yield takeEvery(getLoadOptimals.TRIGGER, getLoadOptimalsSaga);
  yield takeEvery(getSimulateOptimals.TRIGGER, getSimulateOptimalsSaga);
}
