
import styled from "styled-components";

export const CssGridWrapper = styled.div`

.header-grid {
}
.bodyGrid {
  display: grid;
  grid-template-columns:1fr 1fr 1fr;
  grid-template-rows:repeat(4,auto);
  grid-gap: 20px;
  grid-template-areas:"radioBtnGroup radioBtnGroup radioBtnGroup"
  "totalOrder graphSeries graphSeries"
  "tableTrend tableTrend tableTrend"
  "simular simular simular";
}
 .radioBtnGroup{
grid-area:radioBtnGroup;
}
 .totalOrder{
  grid-area:totalOrder;
}
 .graphSeries{
  grid-area:graphSeries;
}
 .tableTrend{
  grid-area:tableTrend;
}
.simular{
  grid-area:simular;
  display: flex;
  justify-content: flex-end;
  margin: 1rem 4rem 0rem 0rem;
}
`;
