import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../../api";
import { token } from "../../auth/selectors";
import { getLoadMomentum, getSimulateMomentum } from "./actions";
import { getFormDataMomentum, getSimulationDataMomentum } from "./selectors";

function* getLoadMomentumSaga({ payload }) {
  try {
    yield put(getLoadMomentum.request());
    const tokenAccess = yield select(token);
    const form = yield select(getFormDataMomentum);
    const response = yield call(
      callApiAxios,
      "GET",
      `pricing.sku/${form.skuId}/momentum?target=${form.skuCompetitorId}&start_month=${form.month}`,
      tokenAccess,
      {},
      {}
    );
    yield put(getLoadMomentum.success(response.data));
  } catch (e) {
    yield put(
      getLoadMomentum.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getLoadMomentum.fulfill());
  }
}

function* getSimulateMomentumSaga({ payload }) {
  try {
    yield put(getSimulateMomentum.request());
    const tokenAccess = yield select(token);
    const form = yield select(getFormDataMomentum);
    const dataSimulation = yield select(getSimulationDataMomentum);
    let body = {};
    let isSimulating = false;
    const types = ["env", "prices"];

    const getCustomType = (type) => {
      return type === "env" ? "values" : "target_prices";
    };
    for (const type of types) {
      if (dataSimulation[type].length > 0) {
        let data = {};
        for (const item of dataSimulation[type]) {
          let items = {};
          if (type === "env") {
            for (const obj of item.items) {
              items[`${obj.code}`] = parseFloat(obj.value);
            }
            data[`${item.code}`] = items;
          } else {
            data[`${item.index}`] = parseFloat(item.value);
          }
        }
        let retype = getCustomType(type);
        body[`${retype}`] = data;
        isSimulating = true;
        body.start_month = form.month;
        body.target = form.skuCompetitorId;
      }
    }

    const paramsURL = isSimulating
      ? ""
      : `?target=${form.skuCompetitorId}&start_month=${form.month}`;
    const url = `pricing.sku/${form.skuId}/momentum${paramsURL}`;
    const response = yield call(
      callApiAxios,
      isSimulating ? "POST" : "GET",
      url,
      tokenAccess,
      {},
      body
    );

    yield put(getLoadMomentum.success(response.data));
  } catch (e) {
    yield put(
      getSimulateMomentum.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getSimulateMomentum.fulfill());
  }
}

export default function* momentumSaga() {
  yield takeEvery(getLoadMomentum.TRIGGER, getLoadMomentumSaga);
  yield takeEvery(getSimulateMomentum.TRIGGER, getSimulateMomentumSaga);
}
