import React from "react";
import { Route, Routes } from "react-router-dom";

import Configuration from "./Configuration";
import ErrorRoute from "./ErrorRoute";
import Home from "./Home";
import InitialSetup from "./InitialSetup";
import WhatIf from "./PriceManagement/LaboratorySimulations";
import Sniper from "./PriceManagement/Sniper";
import PromotionPlanning from "./SalesPlanning/PromotionPlanning";

export default function Pages() {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/settings" element={<Configuration />} />
      <Route path="/load-category" element={<InitialSetup />} />
      <Route path="/prices-lab/what-if" element={<WhatIf />} />
      <Route path="/prices-lab/sniper" element={<Sniper />} />
      <Route
        path="/promotion-lab/promotion-planning"
        element={<PromotionPlanning />}
      />
      <Route path="*" element={<ErrorRoute />} />
    </Routes>
  );
}
