import * as type from "./types";
import { initialState } from "./states";
export default function units(state = initialState, action) {
  switch (action.type) {
    case type.GET_UNITS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.GET_UNITS_SUCCESS:
      
      return {
        ...state,
        loading: false,
        units: action.units,
      };
    case type.GET_UNITS_FAILURE:
      return {
        ...state,
        loading: false,
        response: { message: null, error: action.error },
        units: [],
      };
    case type.CLEAR_RESPONSE_UNIT:
      return {
        ...state,
        response: { error: null, message: null },
      };
    default:
      return state;
  }
}
