import React from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const BannerWelcomeWrapper = styled.div`
  padding: 3em 2em;
  border-radius: 0.5em;
  position: relative;
  overflow: hidden;
  .bubble {
    position: absolute;
    width: 50em;
    height: 50em;
    border-radius: 50%;
    filter: brightness(0.5);
    transition: all 0.25s;
  }
  .bubble {
    animation: fade 20s infinite;
  }
  .secondary {
    animation: fade2 25s infinite;
  }
  @keyframes fade {
    0% {
      opacity: 0.25;
      top: -3em;
      right: -20em;
    }
    50% {
      opacity: 1;
      top: 0;
      right: -30em;
    }
    100% {
      opacity: 0.25;
      top: -3em;
      right: -20em;
    }
  }
  @keyframes fade2 {
    0% {
      opacity: 0.5;
      top: -7em;
      right: -20em;
    }
    50% {
      opacity: 1;
      top: -2;
      right: -30em;
    }
    100% {
      opacity: 0.5;
      top: -7em;
      right: -20em;
    }
  }
`;

const BannerWelcome = ({ userName }) => {
  const [t, i18n] = useTranslation("global");
  return (
    <BannerWelcomeWrapper className="home-alert">
      <div>
        <Typography variant="h4">
          <strong>{t("dashboard_component.welcome")}</strong>
          {` ${userName}`}
        </Typography>
      </div>
      <div
        className="bubble secondary"
        sx={{ width: 50, height: 50, background: "red" }}
      />
      <div className="bubble" />
    </BannerWelcomeWrapper>
  );
};

export default BannerWelcome;
