import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  clearCategory,
  getCategories,
  clearResponseCategory
} from "../../Redux/modules/categories/actions";
import {
  selectCategoryIdToCreateOrUpdate,
  clearCategoryIdToCreateOrUpdate,
  clearResponseInitialSetup,
} from "../../Redux/modules/initialSetup/actions";
import {
  getCategoriesLoaded,
  getResponseCategory,
} from "../../Redux/modules/categories/selectors";

import {
  getUserName,
  getNumMaxCategories,
} from "../../Redux/modules/app/selectors";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { styled as MuiStyled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import CategoryGroups from "./CategoryGroups";
import CategoryList from "./CategoryList";
import BannerWelcome from "./BannerWelcome";

const HomeWrapper = MuiStyled("div")(({ theme }) => ({
  ".home-alert": {
    backgroundColor: theme.palette.custom.primary,
    color: theme.palette.custom.secondary,
  },
  ".bubble": {
    backgroundColor: theme.palette.custom.primary,
    "&.secondary": {
      backgroundColor: theme.palette.custom.secondary,
    },
  },
}));

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [t, i18n] = useTranslation("global");
  const [categoryStateSelected, setCategoryStateSelected] = useState("done");
  const categories = useSelector(getCategoriesLoaded);
  const userName = useSelector(getUserName);
  const numMaxCategories = useSelector(getNumMaxCategories);
  const response = useSelector(getResponseCategory);
  const [temp, setTemp] = useState({});

  useEffect(() => {
    if (categories.length === 0) {
      dispatch(getCategories());
    }
    dispatch(clearCategoryIdToCreateOrUpdate());
    dispatch(clearCategory());
  }, []);

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseCategory());
    }
  }, [response]);

  const onSelectGroup = (categoryState) => {
    setCategoryStateSelected(categoryState);
  };

  const onSelectCategory = (category) => {
    dispatch(selectCategoryIdToCreateOrUpdate(category.id));
    navigate(`/load-category`);
  };

  const categoriesFiltered = categories.filter(
    (category) => category.state === categoryStateSelected
  );

  const numAvailableCategories = numMaxCategories - categories.length;

  if (categoryStateSelected === "available") {
    const category = {
      id: 0,
      name: t("dashboard_component.available_name_category"),
      state: categoryStateSelected,
      last_update: t("dashboard_component.available_last_update"),
    };
    for (let index = 0; index < numAvailableCategories; index++) {
      categoriesFiltered.push(category);
    }
  }

  return (
    <HomeWrapper>
      <Grid container xs={12} alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <BannerWelcome userName={userName}></BannerWelcome>
        </Grid>
        <Grid xs={12}>
          <CategoryGroups
            categories={categories}
            numAvailableCategories={numAvailableCategories}
            onSelectGroup={onSelectGroup}
          />
        </Grid>
        <Grid xs={12}>
          <CategoryList
            numMaxCategories={numMaxCategories}
            onSelectCategory={onSelectCategory}
            categoriesFiltered={categoriesFiltered}
          />
        </Grid>
      </Grid>
    </HomeWrapper>
  );
}

export default Home;
