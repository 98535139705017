import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Components/Button";
import MonthPicker from "../../../../Components/MonthPicker";
import TextField from "../../../../Components/TextField";
import MenuItem from "@mui/material/MenuItem";

import { getMinMaxDateCategorySelected } from "../../../../Redux/modules/categories/selectors";
import {
  getLoadMatrizE,
  setFormDataMatrizE,
} from "../../../../Redux/modules/sniper/matriz-e/actions";
import { getFormDataMatrizE } from "../../../../Redux/modules/sniper/matriz-e/selectors";
import { monthDiff } from "../../../../Utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import { CustomSwitch } from "./Styles";
import { useCategorySelected } from "../../../../Hooks/useCategorySelected";
const FormMatrizE = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const tempForm = useSelector(getFormDataMatrizE);
  const categorySelected = useCategorySelected({ subcategoryId: null });
  const { subcategories } = categorySelected;
  return categorySelected.id ? (
    <Accordion style={{ borderRadius: "10px" }} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
        <Typography fontWeight={700}>
          {t("price_management.sniper_module.matriz_component.form.title")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            alignItems: "center",
            gap: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomSwitch
              checked={tempForm?.isAnnual}
              onChange={(e) => {
                dispatch(
                  setFormDataMatrizE({
                    propertyName: "isAnnual",
                    propertyValue: e.target.checked,
                  })
                );
              }}
              optionTrue={t(
                "price_management.sniper_module.matriz_component.form.inputs.month"
              )}
              optionFalse={t(
                "price_management.sniper_module.matriz_component.form.inputs.year"
              )}
              defaultChecked
              inputProps={{ "aria-label": "ant design" }}
            />
          </div>
          <MonthPicker
            label={t(
              "price_management.sniper_module.matriz_component.form.inputs.month"
            )}
            views={!tempForm.isAnnual ? ["year", "month"] : ["year"]}
            value={tempForm?.period}
            onChange={(e) => {
              let month = 1;
              let newDate = e;
              if (tempForm.isAnnual) {
                const year = e.getFullYear();
                const monthMin = tempForm.minDate.getMonth();
                const yearMin = tempForm.minDate.getFullYear();
                const yearMax = tempForm.maxDate.getFullYear();
                const numYears = yearMax - yearMin;
                if (year === yearMin) {
                  month = 1;
                } else {
                  newDate = new Date(`01/01/${year}`);
                  month = 12 - monthDiff(newDate, tempForm.maxDate);
                }
              } else {
                month = 12 - monthDiff(e, tempForm.maxDate);
              }
              dispatch(
                setFormDataMatrizE({
                  propertyName: "month",
                  propertyValue: month,
                })
              );
              dispatch(
                setFormDataMatrizE({
                  propertyName: "period",
                  propertyValue: newDate,
                })
              );
            }}
            maxDate={tempForm.maxDate}
            minDate={tempForm.minDate}
            renderInput={(params) => <TextField variant="third" {...params} />}
          />
          <TextField
            fullWidth
            select
            variant="third"
            label={t(
              "price_management.sniper_module.matriz_component.form.inputs.subcategory"
            )}
            value={tempForm?.subcategoryId}
            onChange={(e) => {
              const subcategorySelected =
                subcategories &&
                subcategories.find((x) => x.id === e.target.value);
              dispatch(
                setFormDataMatrizE({
                  many: true,
                  changes: [
                    {
                      propertyName: "subcategoryId",
                      propertyValue: e.target.value,
                    },
                    {
                      propertyName: "skuId",
                      propertyValue: subcategorySelected?.sku_ids[0],
                    },
                  ],
                })
              );
            }}
          >
            {subcategories &&
              subcategories.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {option.name}
                  </MenuItem>
                );
              })}
          </TextField>

          <Button
            onClick={() => {
              dispatch(getLoadMatrizE.trigger("first"));
            }}
          >
            {t("components_globals.button_run")}
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};

export default FormMatrizE;
