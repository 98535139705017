export const initialState = {
  loading: false,
  isAuth: false,
  remember: false,
  user: {},
  response: { message: null, error: null },
  access_token: "",
  company_id: "",
  company_ids: [],
  expires_in: "",
  partner_id: "",
  uid: "",
  user_context: "",
};
