import React, { useState, useEffect } from "react";
import { StepWrapper } from "../../styles";
import FormDataLoad from "./FormDataLoad";
import { useTranslation } from "react-i18next";
import FooterStep from "../../FooterStep";
import { useSnackbar } from "notistack";
import { getResponseCategoryData } from "../../../../Redux/modules/categoryData/selectors";
import {
  setDataCategory,
  clearResponseCategoryData,
} from "../../../../Redux/modules/categoryData/actions";
import { useSelector, useDispatch } from "react-redux";
import CustomButton from "../../../../Components/Button";
import { setFormDataCategoryData } from "../../../../Redux/modules/categoryData/actions";
import {
  calculateRows,
  getDefaultFirstPeriod,
} from "../../../../Utils/commonWithDates";
const DataLoad = ({ onNext, onBack, currentCategory }) => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const response = useSelector(getResponseCategoryData);

  useEffect(() => {
    if (currentCategory) {
      dispatch(
        setFormDataCategoryData({
          propertyName: "periodWidth",
          value: currentCategory.period_width,
        })
      );
      if (currentCategory.first_period && currentCategory.period_width) {
        let firstPeriod = new Date(
          currentCategory.first_period.split("-").join("/")
        );
  
        const defaultFirstPeriod = getDefaultFirstPeriod(
          currentCategory.period_width
        );

        if (firstPeriod > defaultFirstPeriod) {
          firstPeriod = defaultFirstPeriod;
        }
        dispatch(
          setFormDataCategoryData({
            propertyName: "firstPeriod",
            value: firstPeriod,
          })
        );
        dispatch(
          setFormDataCategoryData({
            propertyName: "subcategoryId",
            value: currentCategory.subcategory_ids[0].id,
          })
        );

        const rowsData = calculateRows(
          currentCategory.period_width,
          firstPeriod
        );
        dispatch(
          setFormDataCategoryData({
            propertyName: "rows",
            value: rowsData,
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseCategoryData());
    }
  }, [response]);

  const onDataLoadCreated = () => {};
  const onDataLoadUpdated = () => {};
  const onDataLoadExecute = () => {
    dispatch(setDataCategory.trigger({ categoryId: currentCategory.id }));
  };
  return (
    <StepWrapper>
      <FooterStep onNext={onNext} onBack={onBack}>
        <CustomButton
          variant="primary"
          style={{ width: "20%", height: "35px" }}
          onClick={onDataLoadExecute}
        >
          {t("components_globals.button_load_data")}
        </CustomButton>
      </FooterStep>
      <FormDataLoad
        onDataLoadCreated={onDataLoadCreated}
        onDataLoadUpdated={onDataLoadUpdated}
        category={currentCategory}
      />
    </StepWrapper>
  );
};

export default DataLoad;
