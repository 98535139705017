import produce from "immer";
import { getLoadMomentum, getSimulateMomentum } from "./actions";
import { initialState } from "./states";
import * as types from "./types";

export const momentum = (state = initialState, { payload, type }) =>
  produce(state, (draft) => {
    switch (type) {
      case getLoadMomentum.TRIGGER:
        draft.action = payload;
        draft.loading = true;
        break;
      case getLoadMomentum.REQUEST:
        break;
      case getLoadMomentum.SUCCESS:
        let newResponse = {
          columns: [],
          gaps_vs_competidor: {},
          relative_prices: {},
        };
        newResponse.columns = payload.env[0].values.map((item) => item.date);
        const addRow = (type) => {
          newResponse[type].object = { rows: [] };
          newResponse[type].target = { rows: [] };
        };

        const addValue = (type, item, index, name) => {
          if (!newResponse[type].object.rows[index]) {
            newResponse[type].object.rows.push({ name, values: [] });
            newResponse[type].target.rows.push({ name, values: [] });
          }

          newResponse[type].object.rows[index].values.push(item.object[name]);
          newResponse[type].target.rows[index].values.push(item.target[name]);
        };

        addRow("gaps_vs_competidor", "max_sales_gap");
        addRow("gaps_vs_competidor", "optimal_gap");
        addRow("gaps_vs_competidor", "max_profit_gap");

        addRow("relative_prices", "max_sales_price");
        addRow("relative_prices", "optimal_price");
        addRow("relative_prices", "max_profit_price");

        for (let index = 0; index < payload.data.length; index++) {
          const item = payload.data[index];

          addValue("gaps_vs_competidor", item, 0, "max_sales_gap");
          addValue("gaps_vs_competidor", item, 1, "optimal_gap");
          addValue("gaps_vs_competidor", item, 2, "max_profit_gap");

          addValue("relative_prices", item, 0, "max_sales_price");
          addValue("relative_prices", item, 1, "optimal_price");
          addValue("relative_prices", item, 2, "max_profit_price");
        }

        const momentum = {
          ...newResponse,
          env: payload.env,
          response: payload.data,
        };
        if (!draft.momentum || draft.action === "first") {
          draft.simulation = momentum;
        }
        draft.momentum = momentum;

        break;
      case getLoadMomentum.FAILURE:
        draft.response.error = payload.error;
        break;
      case getLoadMomentum.FULFILL:
        draft.loading = false;
        break;

      case getSimulateMomentum.TRIGGER:
        draft.loading = true;
        draft.action = payload;
        break;
      case getSimulateMomentum.REQUEST:
        break;
      case getSimulateMomentum.SUCCESS:
        break;
      case getSimulateMomentum.FAILURE:
        draft.response.error = payload.error;
        break;
      case getSimulateMomentum.FULFILL:
        draft.loading = false;
        break;
      case types.SET_FORM_DATA_MOMENTUM:
        if (payload.many) {
          for (const change of payload.changes) {
            draft.formData[change.propertyName] = change.propertyValue;
          }
        } else {
          draft.formData[payload.propertyName] = payload.propertyValue;
        }

        break;
      case types.SET_DEFAULT_FORM_DATA_MOMENTUM:
        const categorySelected = payload.category;

        const maxDate = categorySelected
          ? new Date(categorySelected.last_month.split("-").join("/"))
          : new Date();
        const minDate = new Date(
          categorySelected.first_month.split("-").join("/")
        );
        maxDate.setFullYear(maxDate.getFullYear() - 1);
        draft.formData.subcategoryId = categorySelected.subcategory_ids[0].id;
        draft.formData.skuId = categorySelected.subcategory_ids[0].sku_ids[0];
        draft.formData.skuCompetitorId =
          categorySelected.subcategory_ids[0].sku_ids[1];
        draft.formData.maxDate = maxDate;
        draft.formData.minDate = minDate;
        draft.formData.period = minDate;
        draft.formData.month = 1;

        if (payload.shouldClear) {
          draft.simulationData = initialState.simulationData;
          draft.momentum = null;
          draft.simulation = null;
        }

        break;
      case types.CLEAR_SIMULATE_MOMENTUM:
        if (payload) {
          draft.simulationData[payload] = [];
          if (draft.simulation) {
            draft.momentum = draft.simulation;
          }
        } else {
          draft.simulationData = initialState.simulationData;
          draft.momentum = null;
          draft.simulation = null;
        }

        break;
      case types.CLEAR_RESPONSE_MOMENTUM:
        draft.response = { error: null, message: null, result: null };
        break;

      case types.SET_SIMULATION_DATA_MOMENTUM:
        if (payload.propertyName === "prices") {
          const { propertyValue, propertyName, index } = payload;
          let itemExist = draft.simulationData[propertyName].find(
            (item) => item.index === index
          );
          if (itemExist) {
            itemExist.value = propertyValue;
          } else {
            draft.simulationData[propertyName].push({
              index: index,
              value: propertyValue,
            });
          }
        } else {
          const code = payload.id;
          let itemExist = draft.simulationData[payload.propertyName].find(
            (item) => item.code === payload.index
          );
          if (itemExist) {
            let periodExists = itemExist.items.find(
              (period) => period.code === code
            );
            if (periodExists) {
              periodExists.value = payload.propertyValue;
            } else {
              itemExist.items.push({
                code: code,
                value: payload.propertyValue,
              });
            }
          } else {
            draft.simulationData[payload.propertyName].push({
              code: payload.index,
              items: [{ code: code, value: payload.propertyValue }],
            });
          }
        }
        break;
    }
  });
