import React from "react";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";

const useStyles = makeStyles((theme) => ({
  itemChart: {
    display: "flex",
    alignItems: "center",
    padding: "4px",
  },
  itemChartText: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Item = ({
  color,
  title,
  subtitle,
  imagen,
  description,
  style,
  className,
  type = "default",
}) => {
  const classes = useStyles();

  const getImage = () => {
    if (type === "sku") {
      return imagen ? (
        <Avatar
          alt=""
          src={`${
            imagen.indexOf("base64") !== -1
              ? imagen
              : `data:image/png;base64,${imagen}`
          }`}
          sx={{ marginRight: "10px", width: 40, height: 40 }}
        />
      ) : (
        <Avatar
          sx={{ marginRight: "10px", width: 40, height: 40, bgcolor: color }}
        >
          {title.toUpperCase().charAt(0)}
        </Avatar>
      );
    }
    return <></>;
  };

  return (
    <div className={classes.itemChart} style={{ ...style }}>
      {getImage()}
      <div className={classes.itemChartText}>
        <span style={{ fontWeight: 700 }}>{title}</span>
        {subtitle && <span>{subtitle}</span>}
        {description && <span>{description}</span>}
      </div>
    </div>
  );
};

export default Item;
