import {
  Checkbox,
  Chip,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Typography,
} from "@mui/material";
import FormControl from "../FormControl";
import { styled } from "@mui/material/styles";

const CssSelect = styled(Select)(({ theme, color = "primary" }) => ({}));

const CssMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: "2px 9px",
}));

export default function CheckList({
  style,
  labelName,
  items,
  selectedItems,
  onBuildItemName = (option) => {
    return option.name;
  },
  onChangeSelectItems,
}) {
  return (
    <FormControl
      customWidth="300px"
      style={{ marginTop: "18px" }}
      variant="secondary"
    >
      <InputLabel>{labelName}</InputLabel>
      <CssSelect
        style={{ ...style }}
        multiple
        fullWidth
        label={labelName}
        value={selectedItems}
        onChange={
          (e) => {
            const {
              target: { value },
            } = e;

            onChangeSelectItems(value);
          }

          //   dispatch(
          //     setFormDataPromotions({
          //       propertyName: "skuIds",
          //       propertyValue: value,
          //     })
          //   );
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            <Chip label={`Seleccionados (${selectedItems.length})`} />
          </Box>
        )}
      >
        <div
          style={{
            padding: "0 1em",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: (theme) => theme.palette.custom.primary,
            }}
            className={"pointer buttons"}
            onClick={() => {
              const all = items.map((i) => i.id);
              onChangeSelectItems(all);
            }}
          >
            Seleccionar todo
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.custom.primary,
            }}
            className={"pointer buttons"}
            onClick={() => {
              onChangeSelectItems([]);
            }}
          >
            Limpiar
          </Typography>
        </div>
        {items &&
          items.map((option, index) => (
            <CssMenuItem key={index} value={option.id}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Checkbox checked={selectedItems.includes(option.id)} />
                <div>{onBuildItemName(option)}</div>
              </div>
            </CssMenuItem>
          ))}
      </CssSelect>
    </FormControl>
  );
}
