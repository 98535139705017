import produce from "immer";
import { getLoadOptimals, getSimulateOptimals } from "./actions";
import { initialState } from "./states";
import * as types from "./types";

export const optimalPrices = (state = initialState, { type, payload }) =>
  produce(state, (draft) => {
    switch (type) {
      case getLoadOptimals.TRIGGER:
        draft.loading = true;
        break;
      case getLoadOptimals.REQUEST:
        break;
      case getLoadOptimals.SUCCESS:
        draft.optimals = payload;
        draft.simulationCount = 0;
        break;
      case getLoadOptimals.FAILURE:
        draft.response.error = payload.error;
        break;
      case getLoadOptimals.FULFILL:
        draft.loading = false;
        break;
      case getSimulateOptimals.TRIGGER:
        draft.loading = true;
        break;
      case getSimulateOptimals.REQUEST:
        break;
      case getSimulateOptimals.SUCCESS:
        if (draft.simulationCount === 0) {
          draft.tempSimulationOptimals = draft.simulationOptimals;
          draft.simulationOptimals = payload;
        } else {
          draft.simulationOptimals = payload;
          draft.simulationCount += 1;
        }

        break;
      case getSimulateOptimals.FAILURE:
        draft.response.error = payload.error;
        break;
      case getSimulateOptimals.FULFILL:
        draft.loading = false;
        break;
      case types.SET_FORM_DATA_OPTIMAL_PRICES:
        if (
          ["prices", "increments", "distributions", "costs", "values"].includes(
            payload.propertyName
          )
        ) {
          const code =
            payload.propertyName === "values" ? payload.key : payload.skuId;

          let itemExist = draft.formData.simulationOptimals[
            payload.propertyName
          ].find((item) => item.code === code);
          if (itemExist) {
            itemExist.value = payload.propertyValue;
          } else {
            draft.formData.simulationOptimals[payload.propertyName].push({
              code,
              value: payload.propertyValue,
            });
          }
        } else {
          draft.formData[payload.propertyName] = payload.propertyValue;
        }

        break;
      case types.SET_TYPE_FUNCTION:
        draft.formData.functionType = payload;
        break;
      case types.CLEAR_OPTIMAL_PRICE:
        if (payload === "simulationOptimals") {
          draft.formData.simulationOptimals =
            initialState.formData.simulationOptimals;
          if (draft.tempSimulationOptimals) {
            draft.simulationOptimals = draft.tempSimulationOptimals;
          }
        } else {
          draft.optimals = [];
          draft.simulationOptimals = [];
          draft.tempSimulationOptimals = null;
        }
        break;
      case types.CLEAR_RESPONSE_OPTIMAL_PRICES:
        draft.response = { error: null, message: null };
        break;
      case types.SET_DEFAULT_FORM_DATA_OPTIMAL_PRICES:
        const categorySelected = payload.category;
        draft.formData.period = null;
        draft.formData.month = 1;
        if (payload.shouldClear) {
          draft.optimals = [];
          draft.simulationOptimals = [];
          draft.tempSimulationOptimals = null;
        }
        break;
    }
  });
