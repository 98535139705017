export const trendState = (state) => state.trend;

export const getLoadingTrend = (state) => trendState(state).loading;

export const getFormDataTrend = (state) => trendState(state).formData;

export const getTrendSubcategoryId = (state) =>
  trendState(state).formData.subcategoryId;

export const getTrendLoaded = (state) => trendState(state).trend;

export const getTrendSimulationData = (state) =>
  trendState(state).formData.simulationTrend;

export const getTrendFunctionType = (state) =>
  trendState(state).formData.typePrice;

export const getVariablesSelected = (state) =>
  trendState(state).formData.variablesSelected;

export const getSkuSelected = (state) =>
  trendState(state).trend.subcategory_ids[0].sku_ids.find(
    (sku) => sku.id === trendState(state).formData.skuId
  );

export const getResponseTrend = (state) => trendState(state).response;

export const getSkuGraph = (state) =>
  trendState(state).trend.subcategory_ids[0].sku_ids.find(
    (sku) => sku.id === trendState(state).formData.skuIdGraph
  );

export const getSelectedSkus = (state) => {
  const currentTrend = trendState(state).trend;
  let filters = trendState(state).formData.skuIds;
  const subcategory =
    currentTrend?.subcategory_ids && currentTrend?.subcategory_ids[0];
  const skus = subcategory?.sku_ids.filter((sku) => filters.includes(sku.id));
  return skus;
};
export const getColumnsTableTrend = (state) => {
  const currentTrend = trendState(state).trend;
  const subcategory =
    currentTrend?.subcategory_ids && currentTrend?.subcategory_ids[0];
  const skus = subcategory?.sku_ids;
  const cols = skus.length > 0 ? skus[0].table : [];
  return cols;
};
