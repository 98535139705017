import { useRef } from "react";
import { TableContainer, TableHead, TableBody, TableRow } from "@mui/material/";
import {
  CustomCell,
  CustomCellSticky,
  CustomTable,
  CustomTableRow,
  TitleCell,
} from "./Styles";
import { useState } from "react";
import { getDateValues } from "../../../../Utils/commonWithDates";
import { useTranslation } from "react-i18next";
import { getMonthName } from "../../../../Utils";
import { TitleCellSticky } from "../Promotions/Styles";
function TemplateRow({ id, customerId, item, mainIndex, subType }) {
  const buildCol = (
    col,
    index,
    mainIndex,
    type,
    color = "background",
    isHighlight = false,
    rounded = ""
  ) => {
    return (
      <CustomCell
        minWidth="60px"
        backgroundColor={color}
        key={`${col.id}-${index}`}
      >
        <span
          style={{
            display: "table",
            width: "100%",
          }}
        >
          <span
            className={rounded}
            style={{
              paddingLeft: rounded === "" ? "0px" : "8px",
              paddingRigth: rounded === "" ? "0px" : "8px",
              paddingTop: "2px",
              paddingBottom: "2px",
              display: "table",
              backgroundColor: isHighlight ? "#6fd1b09e" : "transparent",
              width: "100%",
            }}
          >
            {col[type].toFixed(2)}
          </span>
        </span>
      </CustomCell>
    );
  };

  return (
    <>
      <CustomTableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        key={`tr-${customerId}-${mainIndex}-${id}`}
      >
        <CustomCellSticky
          minWidth="150px"
          maxWidth="150px"
          type="large"
          rowSpan={2}
        >
          <span style={{ marginLeft: "3px" }}>{item.name}</span>
        </CustomCellSticky>
        <CustomCell
          minWidth="60px"
          backgroundColor="text"
          className="border-radius-left"
        >
          BASE
        </CustomCell>
        {item.items.map((col, index) =>
          buildCol(col, index, mainIndex, `base_${subType}`, "text", false)
        )}
      </CustomTableRow>
      <CustomTableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        key={`tr-${mainIndex}-${id}`}
      >
        <CustomCell minWidth="60px">PROMO</CustomCell>
        {item.items.map((col, index) => {
          const isHighlight =
            col[`base_${subType}`] !== col[`promo_${subType}`];
          let rounded = index === 0 ? "border-radius-left" : "";
          rounded =
            rounded === "" && index === item.items.length - 1
              ? "border-radius-right"
              : rounded;
          return buildCol(
            col,
            index,
            mainIndex,
            `promo_${subType}`,
            "background",
            isHighlight,
            rounded
          );
        })}
      </CustomTableRow>
    </>
  );
}

export default function TableCustomer({ id, customer, isKg }) {
  const table = useRef(null);
  const columns = customer ? customer.data[0].items : [];
  const colSpan = columns?.length;
  const [t, i18n] = useTranslation("global");
  const buildHeaders = (cols, type) => {
    let years = [];
    if (type === "years") {
      cols.forEach((col) => {
        const year = col.date.split("-")[0];
        const month = col.date.split("-")[1];
        let yearExist = years.find((x) => x.code === year);
        if (yearExist) {
          yearExist.months.push(month);
        } else {
          years.push({ code: year, months: [month] });
        }
      });

      return (
        years &&
        years.map((year, index) => {
          return (
            <TitleCell colSpan={year.months.length}>
              <span style={{ fontSize: "1rem" }} className="space-cell">
                {year.code}
              </span>
            </TitleCell>
          );
        })
      );
    } else {
      return (
        cols &&
        cols.map((col, index) => {
          const colTitle = getDateValues(col.date);
          return (
            <TitleCell minWidth="60px" color="thirdVariant" key={`th-${index}`}>
              <span style={{ fontWeight: "500", fontSize: "0.8rem" }}>
                {`${t(`months_names.${getMonthName(colTitle.month - 1)}`)
                  .substring(0, 3)
                  .toUpperCase()}-${colTitle.year.toString().substring(2, 4)}`}
              </span>
            </TitleCell>
          );
        })
      );
    }
  };

  const buildRows = (rows) => {
    return rows.map((row, index) => (
      <TemplateRow
        customerId={customer.id}
        id={row.id}
        key={row.id}
        item={row}
        mainIndex={index}
        subType={isKg ? "qty_kg" : "qty"}
      ></TemplateRow>
    ));
  };
  return (
    <div style={{ padding: "1rem" }}>
      <TableContainer sx={{ maxHeight: 640 }}>
        <CustomTable   className={`tableVolumes tableVolumes_${customer.id}`} ref={table}>
          <TableHead
            style={{
              zIndex: 100,
              position: "sticky",
              top: 0,
              backgroundColor: "#fff",
            }}
          >
            <TableRow
              style={{
                marginTop: "20px",
                zIndex: 100,
                backgroundColor: "#fff",
              }}
            >
              <TitleCellSticky>
                <span style={{ fontSize: "1rem" }} className="space-cell">
                  SKUS
                </span>
              </TitleCellSticky>
              <TitleCell></TitleCell>

              {buildHeaders(columns, "years")}
            </TableRow>

            <TableRow>
              <TitleCell></TitleCell>
              <TitleCell></TitleCell>
              {buildHeaders(columns, "months")}
            </TableRow>
          </TableHead>
          <TableBody id={id} customerName={customer.name}>
            {customer.data.length > 0 && buildRows(customer.data)}
          </TableBody>
        </CustomTable>
      </TableContainer>
    </div>
  );
}
