import React from "react";

import { makeStyles } from "@mui/styles";
import CustomDraggable from "../Draggable";
import Avatar from "@mui/material/Avatar";

const useStyles = makeStyles((theme) => ({
  startNode: {
    border: "1px solid #E6EBF1",
    borderRadius: "2px",
    padding: "6px",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    color: "#727272",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      borderWidth: "2px",
      cursor: "pointer",
    },
  },
  startNodeContainer: {
    display: "flex",
    width: "300px",
    alignItems: "center",
    marginBottom: ".5em",
    transition: "all .5s",
  },
  borderColor: {
    height: "100%",
    width: ".5em",
    backgroundColor: (props) => props.color,
    position: "absolute",
    right: "0",
    top: "0",
  },
  title: {
    textTransform: "uppercase",
    display: "flex",
    marginBottom: ".25em",
    fontWeight: "600",
    fontSize: "0.8rem",
  },
  subtitle: {
    textTransform: "uppercase",
    fontSize: "0.75rem",
  },
  detail: {
    fontSize: ".75em",
    display: "flex",
  },
  startPoint: {
    position: "absolute",
    right: "0",
    top: "25%",
  },
}));

function useOutsideAlerter(ref, callback) {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        //alert("You clicked outside of me!");
        if (callback) callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const StartBox = ({ id, data, onStop }) => {
  const wrapperRef = React.useRef(null);
  const classes = useStyles({ color: data.brand.color });
  const [focus, setFocus] = React.useState(false);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleFocusOut = () => {
    setFocus(false);
  };
  useOutsideAlerter(wrapperRef, handleFocusOut);
  return (
    <div className={classes.startNodeContainer}>
      <div className={classes.startNode} onClick={handleFocus}>
        <div id={"startBox-" + id} className={classes.startPoint} />
        <div id={"start-" + id} className={classes.startPoint} />
        <div className={classes.borderColor} />

        <span style={{ margin: "1em" }}>
          {data.img ? (
            <Avatar alt="" src={data.img} sx={{ width: 40, height: 40 }} />
          ) : (
            <Avatar sx={{ width: 40, height: 40, bgcolor: data.brand.color }}>
              {data.name.toUpperCase().charAt(0)}
            </Avatar>
          )}
        </span>

        <div>
          <div className={classes.title}>{data.name}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className={classes.subtitle}>
              {data.owner_id[1]} / {data.brand_id[1]}
            </span>
          </div>

          <div className={classes.detail}>
            <span style={{ fontWeight: "600" }}>
              {data.unit_weight} {data.uom_id[1]}
            </span>
          </div>
        </div>
      </div>
      <CustomDraggable data={data} onStop={onStop} color={data.brand.color} />
    </div>
  );
};

export default StartBox;
