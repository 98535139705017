import React, { useState, useEffect } from "react";
import ReactBezier from "../ReactBezier";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";

import ItemChart from "./ItemChart";
import {
  getCurrentCategoryFilteredTree,
  getSettingsTree,
} from "../../Redux/modules/tree/selectors";

const WrapperDiv = styled.div`
  ${(props) => props.classes}
  path, polyline {
    stroke-width: 1.5px;
    fill: none;
    stroke: #999;
  }
`;

const useStyles = makeStyles((theme) => ({
  chart: {
    display: "flex",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "100px",
  },
  chartColumnCategory: {
    padding: "0px 50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  chartColumnGeneralGroup: {
    padding: "0px 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  chartRowGroup: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
  },
  chartColumnInternalGroup: {
    display: "flex",
    flexDirection: "column",
  },
  chartColumnSubcategory: {
    padding: "0px 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  chartColumnBrand: {
    padding: "0px 50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  chartColumnSku: {
    padding: "0px 50px",
    display: "flex",
    flexDirection: "column",
  },

  unselectable: {
    "-webkit-touch-callout": "none",
    "-webkit-user-select": "none",
    "-khtml-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    userSelect: "none",
  },
}));

const HorusChart = () => {
  const category = useSelector(getCurrentCategoryFilteredTree);
  const settings = useSelector(getSettingsTree);
  const classes = useStyles();

  useEffect(() => {
    return () => {
   
    };
  }, []);

  const getClasses = (data, b) => {
    let lineClasses = {};

    for (let i = 0; i < data.length; i++) {
      lineClasses = {
        ...lineClasses,
        [".subcategory-color-" + `${data[i].id}`.split(".").join("")]: {
          stroke: `${data[i].color}`,
          strokeWidth: "3px",
        },
      };
    }
    for (let i = 0; i < b.length; i++) {
      lineClasses = {
        ...lineClasses,
        [".brand-color-" + `${b[i].id}`.split(".").join("")]: {
          stroke: `${b[i].color}`,
          strokeWidth: "3px",
        },
      };
    }
    return lineClasses;
  };

  let customHeight = "100%";
  let customWidth = "100%";

  customHeight =
    100 * category.sku_ids.length > 800 ? 100 * category.sku_ids.length : 1500;
  return (
    <WrapperDiv
      style={{
        width: "100%",
        minWidth: "1500px",
        minHeight: "1500px",
        height: customHeight + "px",
      }}
      classes={getClasses(category.subcategory_ids, category.brand_ids)}
    >
      <ReactBezier settings={settings}>
        <div className={classes.chart}>
          <div className={classes.chartColumnCategory}>
            <ItemChart
              id={`category-${category.id}`}
              variant="category"
              data={category}
            />
          </div>
          <div className={classes.chartColumnGeneralGroup}>
            {category.subcategory_ids.map((subcategory, index) => (
              <div
                key={`subcategory-${subcategory.id}`}
                className={classes.chartRowGroup}
              >
                <div
                  key={`subcategory-${subcategory.id}`}
                  className={classes.chartColumnSubcategory}
                >
                  <ItemChart
                    key={`subcategory-${subcategory.id}`}
                    id={`subcategory-${subcategory.id}`}
                    variant="subcategory"
                    data={subcategory}
                  />
                </div>

                <div className={classes.chartColumnBrand}>
                  {category.brand_ids
                    .filter((brand) => subcategory.brand_ids.includes(brand.id))
                    .map((brand, index) => (
                      <div className={classes.chartRowGroup}>
                        <div className={classes.chartColumnBrand}>
                          <ItemChart
                            key={index}
                            id={`subcategory-${subcategory.id}-brand-${brand.id}`}
                            variant="brand"
                            data={brand}
                          />
                        </div>
                        <div className={classes.chartColumnSku}>
                          {category.sku_ids
                            .filter(
                              (sku) =>
                                sku.subcategory_id[0] === subcategory.id &&
                                sku.brand_id[0] === brand.id
                            )
                            .map((sku, index) => (
                              <ItemChart
                                key={index}
                                id={`subcategory-${subcategory.id}-brand-${brand.id}-sku-${sku.id}`}
                                variant="sku"
                                data={{ ...sku, color: brand.color }}
                              />
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </ReactBezier>
    </WrapperDiv>
  );
};

export default HorusChart;
