import React from "react";
import { SvgIcon } from "@mui/material";

export function ViewIcon(props) {
  const classViewIcon = {
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5px",
    ...props.style,
  };
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M23.5,12S18.352,18.5,12,18.5.5,12,.5,12,5.648,5.5,12,5.5,23.5,12,23.5,12Z"
          style={classViewIcon}
        ></path>
        <path
          d="M8.000 12.000 A4.000 4.000 0 1 0 16.000 12.000 A4.000 4.000 0 1 0 8.000 12.000 Z"
          style={classViewIcon}
        ></path>
        <path d="M12,10a2,2,0,1,1-2,2" style={classViewIcon}></path>
      </g>
    </SvgIcon>
  );
}
