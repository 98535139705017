import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Components/Button";
import TextField from "../../../../Components/TextField";
import { getMinMaxDateCategorySelected } from "../../../../Redux/modules/categories/selectors";
import { getPL } from "../../../../Redux/modules/promotion-planning/pl/actions";
const FormPL = ({ promotions }) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const { maxDate, minDate } = useSelector(getMinMaxDateCategorySelected);
  const [simulationId, setSimulationId] = useState(null);
  const handleRun = () => {
    dispatch(getPL.trigger(simulationId));
  };

  const handleChangeSelectPromotions = (e) => {
    setSimulationId(e.target.value);
  };

  return promotions && promotions.length > 0 ? (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        alignItems: "center",
        gap: "0.5rem",
        rowGap: "1rem",
        marginBottom: "1rem",
      }}
    >
      <TextField
        fullWidth
        select
        variant="third"
        label={t(
          "sales_planning.promotion_planning_module.pl_component.form.inputs.promotions"
        )}
        value={simulationId}
        onChange={handleChangeSelectPromotions}
      >
        {promotions &&
          promotions.map((option, index) => {
            return (
              <MenuItem key={index} value={option.id}>
                {option.name}
              </MenuItem>
            );
          })}
      </TextField>

      <div></div>

      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button onClick={handleRun} style={{ width: "260px" }}>
          {t("components_globals.button_run")}
        </Button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default FormPL;
