import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import Button from "../../../../Components/Button";
import {
  clearResponsePromotions,
  getSimulatePromotions,
} from "../../../../Redux/modules/promotion-planning/promotions/actions";
import {
  getEnvironmentVariables,
  getPromotionsLoaded,
  getResponsePromotions,
  getSimulationDataEnvPromotions,
  getSimulationDataPromotions,
} from "../../../../Redux/modules/promotion-planning/promotions/selectors";
import FormPromotions from "./FormPromotions";
import ListPromotions from "./ListPromotions";
import TableEnvironmentVariables from "./TableEnvironmentVariables";
import { useRef } from "react";
const Promotions = ({ setCurrentTab }) => {
  const { enqueueSnackbar } = useSnackbar();
  const response = useSelector(getResponsePromotions);
  const promotionsLoaded = useSelector(getPromotionsLoaded);
  const environmentVariables = useSelector(getEnvironmentVariables);
  const simulationDataEnv = useSelector(getSimulationDataEnvPromotions);
  const [tempSimulationData, setTempSimulationData] = useState({
    env: [],
    base: [],
    promo: [],
  });

  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const listRef = useRef(null);

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponsePromotions());
    }
  }, [response]);

  const handleSimulate = () => {
    if (tempSimulationData["promo"].length > 0) {
      dispatch(getSimulatePromotions.trigger(tempSimulationData));
      setCurrentTab(1);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "13px" }}>
      <Accordion style={{ borderRadius: "10px" }} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
          <Typography fontWeight={700}>
            {t(
              "sales_planning.promotion_planning_module.promotions_component.form.title"
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormPromotions />
        </AccordionDetails>
      </Accordion>
      {promotionsLoaded && promotionsLoaded.length > 0 && (
    
          <ListPromotions
            simulationData={tempSimulationData}
            setSimulationData={setTempSimulationData}
            promotions={promotionsLoaded}
          />
      )}
      {environmentVariables && environmentVariables.length > 0 && (
        <TableEnvironmentVariables
          environmentVariables={environmentVariables}
          simulationDataEnv={simulationDataEnv}
         />
      )
      }
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={handleSimulate} style={{ width: "160px" }}>
              {t("components_globals.button_simulate")}
            </Button>
          </div>
      
    
    </div>
  );
};

export default Promotions;
