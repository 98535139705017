export const curvesState = (state) => state.curves;

export const getLoadingCurves = (state) => curvesState(state).loading;

export const getCurvesLoaded = (state) => curvesState(state).curves;

export const getResponseCurves = (state) => curvesState(state).response;

export const getCurvesFormData = (state) => curvesState(state).formData;

export const getSimulatorData = (state) => curvesState(state).simulatorData;

export const getCurvesFunctionType = (state) => curvesState(state).formData.functionType;

export const getCurvesSubcategoryId = (state) => curvesState(state).formData.subcategoryId;

export const getCurvesCurrentGraph= (state) => curvesState(state).xyAxis;


