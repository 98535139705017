export const categoryState = (state) => state.categories;

export const getLoadingCategories = (state) => categoryState(state).loading;

export const getCategoryLoaded = (state) => categoryState(state).category;

export const getCategoriesLoaded = (state) => categoryState(state).categories;

export const getCategorySelected = (state) =>
  categoryState(state).categorySelected;

export const getCategoriesByState = (state, currentCategoryState) =>
  categoryState(state).categories.filter(
    (category) => category.state == currentCategoryState
  );

export const getResponseCategory = (state) => categoryState(state).response;

export const getCategoryById = (state, categoyId) =>
  categoryState(state).categories.find((category) => category.id == categoyId);

export const getCurrentCategoryId = (state) =>
  categoryState(state).currentCategoryId;

export const getMinMaxDateCategorySelected = (state) => {
  const categorySelected = categoryState(state).categorySelected;
  if (!categorySelected) {
    return { maxDate: new Date(), minDate: new Date() };
  }
  const maxDate = categorySelected
    ? new Date(categorySelected.last_month.split("-").join("/"))
    : new Date();
  const minDate = new Date(categorySelected.first_month.split("-").join("/"));
  return { maxDate, minDate };
};
