import React from "react";
import { SvgIcon } from "@mui/material";

export function SaveIcon(props) {
  const classSave = {
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5px",
    ...props.style,
  };

  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 40 48">
        <g transform="matrix(1.5,0,0,1.5,0,0)">
          <path
            d="M21.75,23.25H2.25a1.5,1.5,0,0,1-1.5-1.5V7.243a3,3,0,0,1,.879-2.121L5.121,1.629A3,3,0,0,1,7.243.75H21.75a1.5,1.5,0,0,1,1.5,1.5v19.5A1.5,1.5,0,0,1,21.75,23.25Z"
            style={classSave}
          />
          <path
            d="M9.750 12.750 A3.000 3.000 0 1 0 15.750 12.750 A3.000 3.000 0 1 0 9.750 12.750 Z"
            style={classSave}
          />

          <path d="M12.75 20.25L19.5 20.25" style={classSave} />

          <path
            d="M8.25.75v3a1.5,1.5,0,0,0,1.5,1.5h7.5a1.5,1.5,0,0,0,1.5-1.5v-3"
            style={classSave}
          />
        </g>
      </svg>
      ;
    </SvgIcon>
  );
}
