import { Grid } from "@mui/material/";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Components/Button";
import MonthPicker from "../../../../Components/MonthPicker";
import TextField from "../../../../Components/TextField";
import {
  getCategorySelected,
  getMinMaxDateCategorySelected,
} from "../../../../Redux/modules/categories/selectors";
import {
  getLoadOptimals,
  getSimulateOptimals,
  setFormDataOptimalPrices,
} from "../../../../Redux/modules/what-if/optimal-prices/actions";
import { getOptimalPricesFormData } from "../../../../Redux/modules/what-if/optimal-prices/selectors";
import { monthDiff } from "../../../../Utils";

const FormOptimalPrices = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const categorySelected = useSelector(getCategorySelected);
  const { minDate, maxDate } = useSelector(getMinMaxDateCategorySelected);
  const tempOptimalPrices = useSelector(getOptimalPricesFormData);
  const handleMonthPicker = (e) => {
    const month = 24 - monthDiff(e, maxDate);
    dispatch(
      setFormDataOptimalPrices({
        propertyName: "month",
        propertyValue: month,
      })
    );
    dispatch(
      setFormDataOptimalPrices({
        propertyName: "period",
        propertyValue: e,
      })
    );
  };

  const handleSubmit = (e) => {
    dispatch(getLoadOptimals.trigger(categorySelected.id));
    dispatch(getSimulateOptimals.trigger(categorySelected.id));
  };

  return (
    <div>
      <Grid
        container
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          alignItems: "center",
          gap: "0.5rem",
          marginBottom: "1rem",
        }}
      >
        <MonthPicker
          label={t("price_management.curves_component.month_simulate")}
          views={["year", "month"]}
          value={tempOptimalPrices.period ? tempOptimalPrices.period : minDate}
          onChange={handleMonthPicker}
          maxDate={maxDate}
          minDate={minDate}
          renderInput={(params) => <TextField variant="third" {...params} />}
        />
        <Button
          onClick={handleSubmit}
          disabled={!tempOptimalPrices.functionType || !tempOptimalPrices.month}
        >
          {t("components_globals.button_run")}
        </Button>
      </Grid>
    </div>
  );
};

export default FormOptimalPrices;
