import React from "react";
import { SvgIcon } from "@mui/material";

export function LogoutIcon(props ) {
  const classLogoutIcon = {
    fill: "none",
    strokeWidth: "1.5px",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeMiterlimit: "10",
    ...props.style,
  };
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <g transform="matrix(2,0,0,2,0,0)">
        <path d="M0.75 12.004L16.5 12.004" style={classLogoutIcon} />
        <path
          d="M12.75 15.754L16.5 12.004 12.75 8.254"
          style={classLogoutIcon} 
        />
        <path
          d="M3.306,16.6a10.5,10.5,0,1,0,.179-9.542"
          style={classLogoutIcon} 
        />
      </g>
    </SvgIcon>
  );
}
