export const initialState = {
  loading: false,
  formData: {
    subcategoryId: null,
    skuId: null,
    skuCompetitorId: null,
    nameSku: "",
    nameSkuCompetitor: "",
    month: 1,
    period: new Date(),
    maxDate: new Date(),
    minDate: new Date(),
  },
  simulationData: {
    env: [],
    prices: [],
  },
  momentum: null,
  simulation: null,
  action: null,
  response: { message: null, error: null, result: null },
};
