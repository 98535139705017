import { Paper, Menu, MenuItem } from "@mui/material";
import { createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createFileName, useScreenshot } from "use-react-screenshot";
import * as XLSX from "xlsx";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import { DownloadIcon } from "../../../../Components/CustomIcons";
import Table from "../../../../Components/CustomTable";
import {
  getCurvesFunctionType,
  getCurvesLoaded,
} from "../../../../Redux/modules/what-if/curves/selectors";
import { formatNumber, truncateNumber } from "../../../../Utils";
const TableCurves = () => {
  const [t, i18n] = useTranslation("global");
  const selectedFunction = useSelector(getCurvesFunctionType);
  const mainCurve = useSelector(getCurvesLoaded)[0];
  let nf = new Intl.NumberFormat("en-US");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpg",
    quality: 1.0,
  });
  const refTable = createRef(null);

  const handleCloseDownloadMenu = () => {
    setAnchorEl(null);
  };

  const downloadTable = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const exportToExcel = () => {
    let [table] = document.getElementsByClassName("tableMainGraph");
    let today = new Date();
    try {
      let excelTable = document.createElement("table");
      excelTable.innerHTML = table.innerHTML.trim();
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.table_to_sheet(excelTable);

      XLSX.utils.book_append_sheet(wb, ws, "Table");
      XLSX.writeFile(
        wb,
        `export_table_curves_${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDay()}-${today.getTime()}.xlsx`
      );
    } catch (error) {}
  };

  const handleOpenDownloadMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadExcel = () => {
    exportToExcel();
  };

  const handleDownloadImage = () => {
    const downloadScreenshotTable = () =>
      takeScreenShot(refTable.current).then(downloadTable);
    downloadScreenshotTable();
  };

  return (
    <Paper
      style={{
        width: "100%",
        marginTop: "2rem",
        borderRadius: "10px",
        position: "relative",
      }}
      ref={refTable}
    >
      <div
        style={{
          right: "2rem",
          top: "1.5rem",
          position: "absolute",
        }}
      >
        <ButtonItemColumn
          onClick={handleOpenDownloadMenu}
          tooltip={t("components_globals.button_download")}
          icon={
            <DownloadIcon
              sx={{ fontSize: "1.5rem" }}
              className="iconButtonPrimary strokeLigth"
            />
          }
        />
        <Menu
          id="download-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseDownloadMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleDownloadImage}>Download Image</MenuItem>
          <MenuItem onClick={handleDownloadExcel}>Download Excel</MenuItem>
        </Menu>
      </div>
      <Table
        className="tableMainGraph"
        style={{
          marginBlock: "3rem",
          width: "95%",
          marginInline: "auto",
        }}
      >
        <thead>
          <tr>
            <th colSpan={5} className="align-left text-secondary">
              {t("price_management.curves_component.optimal_prices")}
            </th>
            <th></th>
            <th colSpan={4} className="align-left text-secondary">
              {t("price_management.curves_component.message_curvas")}
            </th>
          </tr>
          <tr>
            <th className="joined joined-first secondary"></th>
            <th className="joined secondary">
              {t("price_management.common_label.price")}
            </th>
            <th className="joined secondary">
              {t("price_management.common_label.quantity")}
            </th>
            <th className="joined secondary">
              {t("price_management.common_label.sale")}
            </th>
            <th className="joined joined-last secondary">
              {t("price_management.common_label.profit")}
            </th>
            <th className="empty">---</th>
            <th className="joined joined-first secondary">
              {t("price_management.common_label.price")}
            </th>
            <th className="joined secondary">
              {t("price_management.common_label.quantity")}
            </th>
            <th className="joined secondary">
              {t("price_management.common_label.sale")}
            </th>
            <th className="joined joined-last secondary">
              {t("price_management.common_label.profit")}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="bold">
              {t("price_management.curves_component.maximum_sale")}
            </td>
            <td className="joined background">
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_sales_point.price
                )
              )}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_sales_point.quantity
                )
              )}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_sales_point.value
                )
              )}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_sales_point.profit
                )
              )}
            </td>
            <td></td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_sales_point_diff.price
                )
              )}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_sales_point_diff.quantity
                )
              )}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_sales_point_diff.value
                )
              )}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_sales_point_diff.profit
                )
              )}
            </td>
          </tr>
          <tr>
            <td className="joined joined-first primary bold">
              {t("price_management.common_label.optimum")}
            </td>
            <td className="joined primary">
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].optimal_point.price
                )
              )}
            </td>
            <td className="joined primary">
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].optimal_point.quantity
                )
              )}
            </td>
            <td className="joined primary">
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].optimal_point.value
                )
              )}
            </td>
            <td className="joined joined-last primary">
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].optimal_point.profit
                )
              )}
            </td>
            <td></td>
            <td className="joined joined-first primary">
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].optimal_point_diff.price
                )
              )}
            </td>
            <td className="joined primary">
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].optimal_point_diff.quantity
                )
              )}
            </td>
            <td className="joined primary">
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].optimal_point_diff.value
                )
              )}
            </td>
            <td className="joined joined-last primary">
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].optimal_point_diff.profit
                )
              )}
            </td>
          </tr>
          <tr>
            <td className="joined background bold">
              {t("price_management.curves_component.maximum_profit")}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_profit_point.price
                )
              )}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_profit_point.quantity
                )
              )}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_profit_point.value
                )
              )}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_profit_point.profit
                )
              )}
            </td>
            <td></td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_profit_point_diff.price
                )
              )}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_profit_point_diff.quantity
                )
              )}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_profit_point_diff.value
                )
              )}
            </td>
            <td>
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].max_profit_point_diff.profit
                )
              )}
            </td>
          </tr>
          <tr>
            <td className="joined joined-first primary bold">
              {t("price_management.common_label.current")}
            </td>
            <td className="joined primary">
              {formatNumber(
                truncateNumber(mainCurve?.[selectedFunction].user_point.price)
              )}
            </td>
            <td className="joined primary">
              {formatNumber(
                truncateNumber(
                  mainCurve?.[selectedFunction].user_point.quantity
                )
              )}
            </td>
            <td className="joined primary">
              {formatNumber(
                truncateNumber(mainCurve?.[selectedFunction].user_point.value)
              )}
            </td>
            <td className="joined joined-last primary">
              {formatNumber(
                truncateNumber(mainCurve?.[selectedFunction].user_point.profit)
              )}
            </td>
            <td></td>
          </tr>
        </tbody>
      </Table>
    </Paper>
  );
};

export default TableCurves;
