import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../../api";
import { token } from "../../auth/selectors";
import {
  getLoadTrend,
  getSimulateTrend
} from "./actions";
import { getFormDataTrend } from "./selectors";


function* getLoadTrendSaga({ payload }) {
  try {
    yield put(getLoadTrend.request());
    const tokenAccess = yield select(token);
    const form = yield select(getFormDataTrend);
    const response = yield call(
      callApiAxios,
      "GET",
      `pricing.sku.subcategory/${form.subcategoryId}/simulate_trend?price_type=${form.typePrice}&start_month=${form.startMonth}&end_month=${form.endMonth}`,
      tokenAccess,
      {},
      {}
    );
    yield put(getLoadTrend.success(response.data));
  } catch (e) {
    yield put(
      getLoadTrend.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getLoadTrend.fulfill());
  }
}

function* getSimulateTrendSaga({ payload }) {
  try {
    yield put(getSimulateTrend.request());
    const tokenAccess = yield select(token);
    const form = yield select(getFormDataTrend);
    let simulationTypes = [
      "price",
      "distribution",
      "cost",
      "value",
      "distribution2",
      "cost2",
    ];
    if (payload !== "changeTypeOfPrice") {
      simulationTypes.push("price2");
    }

    const getCustomType = (type) => {
      if (type.includes("2")) return type.replace("2", "s2");
      return `${type}s`;
    };

    let body = {};
    let isSimulating = false;
    for (const type of simulationTypes) {
      if (form.simulationTrend[type].length > 0) {
        let data = {};
        for (const item of form.simulationTrend[type]) {
          let items = {};
          for (const obj of item.items) {
            items[`${obj.code}`] = parseFloat(obj.value);
          }
          data[`${item.code}`] = items;
        }
        let retype = getCustomType(type);
        body[`${retype}`] = data;
        isSimulating = true;

        body.price_type = form.typePrice;
        body.start_month = form.startMonth;
        body.end_month = form.endMonth;
      }
    }
    const paramsURL = isSimulating
      ? ""
      : `?price_type=${form.typePrice}&start_month=${form.startMonth}&end_month=${form.endMonth}`;
    const url = `pricing.sku.subcategory/${form.subcategoryId}/simulate_trend${paramsURL}`;
    const response = yield call(
      callApiAxios,
      isSimulating ? "POST" : "GET",
      url,
      tokenAccess,
      {},
      body
    );
    yield put(getSimulateTrend.success(response.data));
  } catch (e) {
    yield put(
      getSimulateTrend.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getSimulateTrend.fulfill());
  }
}

export default function* trendSaga() {
  yield takeEvery(getLoadTrend.TRIGGER, getLoadTrendSaga);
  yield takeEvery(getSimulateTrend.TRIGGER, getSimulateTrendSaga);
}
