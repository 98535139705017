import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../Redux/modules/layout/actions";
import Menu from "./MenuItem";
import List from "@mui/material/List";

const MainMenu = ({ openSideBar, menuOptions }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuSelected, setMenuSelected] = useState({
    menuId: 1,
    subMenuId: null,
    thirdMenuId: null,
  });
  useEffect(() => {
    navigate("/");
    dispatch(setCurrentPage("/"));
  }, []);
  return (
    <List
      style={{ marginTop: !openSideBar ? "2em" : "0.5em" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {menuOptions.map((menuItem, index) => (
        <Menu
          closeAll={openSideBar}
          menuSelected={menuSelected}
          setMenuSelected={setMenuSelected}
          menuItem={menuItem}
          key={index}
          index={index}
        />
      ))}
    </List>
  );
};

export default MainMenu;
