import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { clearResponseMatrizE } from "../../../../Redux/modules/sniper/matriz-e/actions";
import {
  getMatrizELoaded,
  getResponseMatrizE,
} from "../../../../Redux/modules/sniper/matriz-e/selectors";
import FormMatrizE from "./FormMatrizE";
import TableEnvironmentVariables from "./TableEnvironmentVariables";
import GridMatrizE from "./GridMatrizE";
import { Modal } from "@mui/material";
import { CustomModalBoxExpand } from "./Styles";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { useLayout } from "../../../../Hooks/useLayout";

const MatrizE = () => {
  const { enqueueSnackbar } = useSnackbar();
  const response = useSelector(getResponseMatrizE);
  const matrizELoaded = useSelector(getMatrizELoaded);
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseMatrizE());
    }
  }, [response]);

  const [isExpandGrid, setIsExpandGrid] = useState(false);
  const handleExpandGridMatriz = () => {
    setIsExpandGrid(!isExpandGrid);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "13px" }}>
      <FormMatrizE />
      {matrizELoaded && <TableEnvironmentVariables />}
      {matrizELoaded && (
        <GridMatrizE
          showHeader={true}
          onHandleExpandGridMatriz={handleExpandGridMatriz}
          data={matrizELoaded.response}
        />
      )}
      {matrizELoaded && (
        <Modal
          open={isExpandGrid}
          onClose={handleExpandGridMatriz}
          aria-labelledby="modal-grid-matriz-e"
          aria-describedby="modal-grid-matriz-e"
        >
          <CustomModalBoxExpand>
            <div className="close-btn">
              <CloseFullscreenIcon
                onClick={handleExpandGridMatriz}
                sx={{ fontSize: "1.3rem" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "90%",
                marginTop: "10px",
                backgroundColor: "#fff",
                padding: "1rem",
              }}
            >
              <GridMatrizE data={matrizELoaded.response} />
            </div>
          </CustomModalBoxExpand>
        </Modal>
      )}
    </div>
  );
};

export default MatrizE;
