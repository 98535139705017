import { SvgIcon } from "@mui/material";

export function ProfileIcon(props) {
  const classProfile = {
    fill:"none" ,
    strokelinecap:"round" ,
    strokelinejoin:"round" ,
    strokewidth:"1.5",
  };
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 48 48">
      <g transform="matrix(2.3,0,0,2.3,0,0)">
        <path 
        style={classProfile}
        d="M6.750 6.000 A5.250 5.250 0 1 0 17.250 6.000 A5.250 5.250 0 1 0 6.750 6.000 Z" />
  
   
        <path 
        style={classProfile}
        d="M2.25,23.25a9.75,9.75,0,0,1,19.5,0" />
  
  
    </g>
      </svg>
      ;
    </SvgIcon>
  );
}