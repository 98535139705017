import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SaveIcon, DownloadIcon } from "../CustomIcons";
import ButtonItemColumn from "../Button/ButtonItemColumn";
import { useTranslation } from "react-i18next";
const SaveMenu = ({
  handlePdf,
  handleExcel,
  handleSave = () => {},
  list = ["pdf", "excel"],
  buttons = ["save", "export"],
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [t, i18n] = useTranslation("global");
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div
        style={{
          ...props.style,
          display: "flex",
          height: "28px",
          marginRight: "5px",
        }}
      >
        {buttons.includes("export") && (
          <ButtonItemColumn
            onClick={handleOpen}
            tooltip={t("components_globals.button_download")}
            icon={
              <DownloadIcon
                sx={{ fontSize: "1.5rem" }}
                className="iconButtonPrimary strokeLigth"
              />
            }
          ></ButtonItemColumn>
        )}
        {buttons.includes("save") && (
          <ButtonItemColumn
            onClick={handleSave}
            tooltip={t("components_globals.button_save")}
            icon={
              <SaveIcon
                sx={{ fontSize: "2rem" }}
                className="iconButtonPrimary strokeLigth"
              />
            }
          ></ButtonItemColumn>
        )}
        <Menu
          id="download-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {list.includes("pdf") && (
            <MenuItem
              onClick={
                handlePdf
                // handleClose();
              }
            >
              {t("components_globals.download_pdf")}
            </MenuItem>
          )}
          {list.includes("excel") && (
            <MenuItem
              onClick={() => {
                handleExcel();
                handleClose();
              }}
            >
              {t("components_globals.download_excel")}
            </MenuItem>
          )}
        </Menu>
      </div>
    </>
  );
};

export default SaveMenu;
