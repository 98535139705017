import React from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "paste-from-excel";
import { useTranslation } from "react-i18next";
import { getCategoryDataValues } from "../../../../Redux/modules/categoryData/selectors";
import { setCategoryDataTempValue } from "../../../../Redux/modules/categoryData/actions";
function TableDataLoadValues({
  subcategoryId,
  tableType,
  rows,
  columns,
  onCancelModal,
  onOkModal,
  dataTableRef,
}) {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const tempValues = useSelector(getCategoryDataValues);


  const validation =(e)=>{
    const fixed = parseFloat(e.target.value).toFixed(2).toString()
    if (fixed.length < parseFloat(e.target.value).toString().length)
      e.target.value = fixed

    return fixed
  }
  const onKeyPressTd = (e, currentValue) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (keyValue === ".") {
      if (currentValue.indexOf(keyValue) !== -1) {
        e.preventDefault();
        return;
      }
    }
    if (!new RegExp("[0-9.]").test(keyValue)) {
      e.preventDefault();
      return;
    }
  };

  const handlePasteTd = (rowIndex, row, e, colIndex, col) => {
    return parse(e);
  };

  const handleInputTd = (rowIndex, row, e, colIndex, col) => {
    validation(e)
    const newValue = e.target.value;
    dispatch(
      setCategoryDataTempValue({
        id: tableType === "total_subcategory" ? subcategoryId : col.id,
        period_index: row.period_index,
        value: newValue,
        tableType,
        valueType: tableType === "total_subcategory" ? col.code : tableType,
      })
    );
  };

  const getValue = (row, col) => {
    let filterType =
      tableType === "total_subcategory" ? "subcategory_id" : "sku_id";

    let searchId = tableType === "total_subcategory" ? subcategoryId : col.id;

    const data =
      tempValues &&
      tempValues.find(
        (x) => x.period_index === row.period_index && x[filterType] === searchId
      );
    return data
      ? data[tableType === "total_subcategory" ? col.code : tableType]
      : "";
  };

  const stylesTr = {
    height: "40px",
  };

  const stylesTh = {
    minWidth: "100px",
    borderRadius: "10px",
    background: "#6FD1B0",
    color: "#1F1C36",
    fontSize: "0.7em",
    textAlign: "center",
  };

  const stylesTd = {
    height: "40px",
    minWidth: "100px",
    maxWidth: "150px",
    fontSize: "0.8em",
    textAlign: "center",
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div ref={dataTableRef} style={{ width: "100%" }}>
        <table style={{ width: "100%" }}>
          <thead>
            <tr style={stylesTr}>
              <th style={stylesTh}>-</th>
              <th style={stylesTh}>
                {t(`initial_setup.forms.common_inputs.date`)}
              </th>
              {columns &&
                columns.map((option, idx) => {
                  if (option?.brand_id) {
                    return (
                      <th key={idx} style={stylesTh}>
                        {option.brand_id && option.brand_id[1]}
                        <br></br>
                        {`${option.name} ${option.unit_weight} ${
                          option.uom_id && option.uom_id[1]
                        }`}
                      </th>
                    );
                  } else {
                    return (
                      <th key={idx} style={stylesTh}>
                        {option.name}
                      </th>
                    );
                  }
                })}
            </tr>
          </thead>
          <tbody>
            {rows &&
              rows.map((row, indexF) => {
                return (
                  <tr key={indexF}>
                    <td style={stylesTd}>{`${row.period_index}`}</td>
                    <td style={stylesTd}>{`${row.date}`}</td>
                    {columns &&
                      columns.map((col, indexC) => (
                        <td key={`${indexF}${indexC}`} style={stylesTd}>
                          <input
                            name={`value-${indexF}${indexC}`}
                            type="number"
                            step="0.1"
                            placeholder="0.00"
                            value={getValue(row, col)}
                            style={{
                              borderRadius: "5px",
                              border: "1px solid #1F1C36",
                              width: "100%",
                              height: "100%",
                              opacity: 0.8,
                              textAlign: "center",
                            }}
                            onKeyPress={(e) =>
                              onKeyPressTd(e, getValue(row, col))
                            }
                            onInput={(e) => {
                              handleInputTd(indexF, row, e, indexC, col);
                            }}
                            onPaste={(e) => {
                              handlePasteTd(indexF, row, e, indexC, col);
                            }}
                          />
                        </td>
                      ))}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TableDataLoadValues;
