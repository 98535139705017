import * as type from "./types";
import { initialState } from "./states";
export default function subcategories(state = initialState, action) {
  switch (action.type) {
    case type.CREATE_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
              
      };
    case type.CREATE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
         response: {
          message: "initial_setup.messages.create_subcategory_success",
          error: null,
        },
      };

    case type.CREATE_SUBCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        response: { message: null, error: action.error },
        
      };
    case type.GET_SUBCATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        subcategories: [],
      };
    case type.GET_SUBCATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        subcategories: action.subcategories,
        
      };
    case type.GET_SUBCATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        subcategories: [],
      };
    case type.UPDATE_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.UPDATE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
       response: {
          message: "initial_setup.messages.update_subcategory_success",
          error: null,
        },
      };
    case type.UPDATE_SUBCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        response: { message: null, error: action.error },
      };
    case type.DELETE_SUBCATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {
          message: "initial_setup.messages.delete_subcategoy_success",
          error: null,
        },
      };
    case type.DELETE_SUBCATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        response: { message: null, error: action.error },
      };
    case type.CLEAR_RESPONSE_SUBCATEGORY:
      return {
        ...state,
        response: { error: null, message: null },
      };
    case type.CLEAR_ALL_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
