import styled from "styled-components";
import ButtonItemColumn from "../Button/ButtonItemColumn";
import { EditIcon, DeleteIcon } from "../CustomIcons";
import { styled as MuiStyled } from "@mui/material/styles";

const RoundedRowWrapper = styled.div`
  width: 100%;
  height: 100%;
  height: 5rem;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0.5em;
  margin-top: 0.6em;
`;
const VariableName = MuiStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.custom.secondary,
  color: theme.palette.custom.third,
  height: "100%",
  width: "85%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0.5em 0 0 0.5em",
  fontSize: "1.2em",
  fontWeight: "bold",
}));
const ItemRowWithOptionsVariant = ({
  index,
  children,
  buttons,
  withCommonButtons,
  onItemEdit,
  tooltipTitleEdit,
  onItemDelete,
  tooltipTitleDelete,
  columnsNumber,
  name
}) => {
  let commonButtons = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ButtonItemColumn
         onClick={onItemEdit}
         tooltip={tooltipTitleEdit}
        icon={<EditIcon className="iconButton" />}
      ></ButtonItemColumn>
      <ButtonItemColumn
         onClick={onItemDelete}
        tooltip={tooltipTitleDelete}
        icon={<DeleteIcon className="iconButton" />}
      ></ButtonItemColumn>
    </div>
  );
  return (
    <RoundedRowWrapper 
    style={{ gridTemplateColumns:
      "repeat(" + (columnsNumber?columnsNumber:4) + ", 1fr)",padding:"0em",borderRadius:"0.5em"}}
      className="divRow">
         <VariableName>
        {name}
      </VariableName>
      {children}
      <div>
        {buttons && buttons}
        {withCommonButtons && commonButtons}
      </div>
    </RoundedRowWrapper>
  );
};

export default ItemRowWithOptionsVariant;
