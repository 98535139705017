import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { token } from "../auth/selectors";
import {
  CREATE_BRAND_FAILURE,
  CREATE_BRAND_REQUEST,
  CREATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAILURE,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
  DELETE_BRAND_FAILURE,
  DELETE_BRAND_REQUEST,
  DELETE_BRAND_SUCCESS,
} from "./types";
import { getLoadBrands } from "./actions";

import {
  UPDATE_BRAND_IN_CATEGORY,
  DELETE_BRAND_OF_CATEGORY,
} from "../categories/types";

import { addBrandToCategory } from "../categories/actions";

function* createBrand({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const body = {
      name: payload.name,
      description: payload.description,
      color: payload.color,
      owner_id: payload.ownerId,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      "pricing.sku.brand/",
      tokenAccess,
      {},
      body
    );
    yield put({
      type: CREATE_BRAND_SUCCESS,
      brand: response.data[0],
    });
    yield put(
      addBrandToCategory({
        brand: { ...payload, id: response.data[0].id },
        many: false,
      })
    );
  } catch (e) {
    yield put({
      type: CREATE_BRAND_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* getBrandsSaga() {
  try {
    yield put(getLoadBrands.request());
    const tokenAccess = yield select(token);
    const response = yield call(
      callApiAxios,
      "GET",
      "pricing.sku.brand/",
      tokenAccess,
      {},
      {}
    );
    yield put(getLoadBrands.success({ brands: response.data }));
  } catch (e) {
    yield put(
      getLoadBrands.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getLoadBrands.fulfill());
  }
}

function* updateBrand({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const body = {
      name: payload.name,
      description: payload.description,
      color: payload.color,
      owner_id: payload.ownerId,
    };

    const response = yield call(
      callApiAxios,
      "POST",
      `pricing.sku.brand/${payload.id}`,
      tokenAccess,
      {},
      body
    );
    yield put({
      type: UPDATE_BRAND_SUCCESS,
      brand: payload,
    });
    yield put({ type: UPDATE_BRAND_IN_CATEGORY, brand: payload });
  } catch (e) {
    yield put({
      type: UPDATE_BRAND_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* deleteBrand({ payload }) {
  try {
    yield put({ type: DELETE_BRAND_SUCCESS, brand: payload });
    yield put({ type: DELETE_BRAND_OF_CATEGORY, brand: payload });
  } catch (e) {
    yield put({
      type: DELETE_BRAND_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

export default function* brandsSaga() {
  yield takeEvery(CREATE_BRAND_REQUEST, createBrand);
  yield takeEvery(getLoadBrands.TRIGGER, getBrandsSaga);
  yield takeEvery(UPDATE_BRAND_REQUEST, updateBrand);
  yield takeEvery(DELETE_BRAND_REQUEST, deleteBrand);
}
