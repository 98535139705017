import produce from "immer";
import { getCustomers } from "./actions";
import { initialState } from "./states";

export const customers = (state = initialState, { payload, type }) =>
  produce(state, (draft) => {
    switch (type) {
      case getCustomers.TRIGGER:
        draft.action = payload;
        draft.loading = true;
        break;
      case getCustomers.REQUEST:
        break;
      case getCustomers.SUCCESS:
        let customersItems = payload;
        if (customersItems) {
          customersItems = customersItems.map((item) => {
            const skus = item.sku_ids;
            const skus_ids = skus.map((x) => x.id);
            return { ...item, sku_ids: skus_ids, skus: skus };
          });
          draft.customers = customersItems;
        }
        break;
      case getCustomers.FAILURE:
        draft.response.error = payload.error;
        break;
      case getCustomers.FULFILL:
        draft.loading = false;
        break;
    }
  });
