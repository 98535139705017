import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../../api";
import { token } from "../../auth/selectors";
import {
  getLoadCurves,
  getSimulateCurves
} from "./actions";

import { getCurvesFormData } from "./selectors";

function* getLoadCurvesSaga({ payload }) {
  try {
    yield put(getLoadCurves.request());
    const tokenAccess = yield select(token);
    const response = yield call(
      callApiAxios,
      "GET",
      `pricing.sku/${payload.skuId}?method=get_table&month=${payload.month}&granularity=0.1`,
      tokenAccess,
      {},
      {}
    );
    yield put(getLoadCurves.success(response.data));
  } catch (e) {
    yield put(
      getLoadCurves.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getLoadCurves.fulfill());
  }
}

function* getSimulateCurvesSaga({ payload }) {
  try {
    yield put(getSimulateCurves.request());
    const tokenAccess = yield select(token);
    // const formCurves = yield select(getCurvesFunctionType);
    const formData = yield select(getCurvesFormData);

    let finalResponse = [];
    let paramsVariables = "";
    for (const variable of payload.variablesSc1) {
      paramsVariables += `${variable.key}=${variable.value}&`;
    }
    if (paramsVariables) {
      paramsVariables = paramsVariables.substring(
        0,
        paramsVariables.length - 1
      );
    }
    // "min_price", "max_price"
    const responseEsc1 = yield call(
      callApiAxios,
      "GET",
      `pricing.sku/${formData.skuId}?method=get_table${
        payload.priceSc1 ? "&price=" + payload.priceSc1 : ""
      }${paramsVariables !== "" ? "&" + paramsVariables : ""}${
        formData.month ? "&month=" + formData.month : ""
      }&granularity=0.1&min_price=${payload.table_limits[0].toFixed(
        2
      )}&max_price=${payload.table_limits[1].toFixed(2)}`,
      tokenAccess,
      {},
      {}
    );
    finalResponse.push(responseEsc1.data);
    paramsVariables = "";
    for (const variable of payload.variablesSc2) {
      paramsVariables += `${variable.key}=${variable.value}&`;
    }
    if (paramsVariables) {
      paramsVariables = paramsVariables.substring(
        0,
        paramsVariables.length - 1
      );
    }
    if (
      (payload.priceSc2 && payload.priceSc2 !== "0") ||
      paramsVariables !== ""
    ) {
      const responseEsc2 = yield call(
        callApiAxios,
        "GET",
        `pricing.sku/${formData.skuId}?method=get_table${
          payload.priceSc2 && payload.priceSc2 !== "0"
            ? "&price=" + payload.priceSc2
            : ""
        }${paramsVariables !== "" ? "&" + paramsVariables : ""}${
          formData.month ? "&month=" + formData.month : ""
        }&granularity=0.1&min_price=${payload.table_limits[0].toFixed(
          2
        )} & max_price=${payload.table_limits[1].toFixed(2)}`,
        tokenAccess,
        {},
        {}
      );
      finalResponse.push(responseEsc2.data);
    }
    yield put(getSimulateCurves.success(finalResponse));
  } catch (e) {
    yield put(
      getSimulateCurves.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getSimulateCurves.fulfill());
  }
}


export default function* curvesSaga() {
  yield takeEvery(getLoadCurves.TRIGGER, getLoadCurvesSaga);
  yield takeEvery(getSimulateCurves.TRIGGER, getSimulateCurvesSaga);

}
