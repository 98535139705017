import React from "react";
import { styled } from "@mui/material/styles";

const CssInput = styled("input")(({ theme }) => ({
  borderRadius: "1.5em",
  border: "2px solid " + theme.palette.custom.text,
  backgroundColor: theme.palette.custom.background,
  transition: "all .25s",
  padding: ".5em 1em",
  textAlign: "center",
  width: "100%",
  "&:focus": {
    outline: "none",
  },
  "&:hover": {
    borderColor: theme.palette.custom.primary,
  },
}));

const CssInputPrimary = styled("input")(({ theme }) => ({
  borderRadius: "0.5em",
  width: "4.8rem",
  border: "1px solid " + theme.palette.custom.secondary,
  backgroundColor: theme.palette.custom.background,
  transition: "all .25s",
  padding: ".5em 1em",
  textAlign: "center",
  "&:focus": {
    outline: "none",
  },
  "&:hover": {
    borderColor: theme.palette.custom.background,
  },
}));


const CssInputSecondary = styled("input")(({ theme }) => ({
  borderRadius: "none",
  width: "4.3rem",
  border: "unset",
  padding: ".5em 1em",
  background: "transparent",
  textAlign: "center",
  "&:hover": {
    border: `1px solid ${theme.palette.custom.background}`,
    backgroundColor: "#0000ff0f",
    borderRadius: "0.5em",
  },
}));

const CustonInput = (props) => {
  const getVariant = (variant) => {
    switch (variant) {
      case "primary":
        return (
          <CssInputPrimary
            defaultValue={props.defaultValue}
            label={props.label}
            {...props}
          />
        );
      case "secondary":
        return (
          <CssInputSecondary
            defaultValue={props.defaultValue}
            label={props.label}
            {...props}
          />
        );
      default:
        return (
          <CssInput
            defaultValue={props.defaultValue}
            label={props.label}
            {...props}
          />
        );
    }
  };
  return getVariant(props.variant);
};

export default CustonInput;
