export const CREATE_VARIABLE = "CREATE_VARIABLE";

export const CREATE_VARIABLE_VALUE_REQUEST = "CREATE_VARIABLE";
export const CREATE_VARIABLE_VALUE_SUCCESS = "CREATE_VARIABLE_SUCCESS";
export const CREATE_VARIABLE_VALUE_FAILURE = "CREATE_VARIABLE_FAILURE";

export const GET_VARIABLE_REQUEST = "GET_VARIABLE_REQUEST";
export const GET_VARIABLE_SUCCESS = "GET_VARIABLE_SUCCESS";
export const GET_VARIABLE_FAILURE = "GET_VARIABLE_FAILURE";

export const DELETE_VARIABLE_REQUEST = "DELETE_VARIABLE_REQUEST";
export const DELETE_VARIABLE_SUCCESS = "DELETE_VARIABLE_SUCCESS";
export const DELETE_VARIABLE_FAILURE = "DELETE_VARIABLE_FAILURE";

export const UPDATE_VARIABLE = "UPDATE_VARIABLE";

export const GET_VARIABLE_BY_ID_REQUEST = "GET_VARIABLE_BY_ID_REQUEST";
export const GET_VARIABLE_BY_ID_SUCCESS = "GET_VARIABLE_BY_ID_SUCCESS";
export const GET_VARIABLE_BY_ID_FAILURE = "GET_VARIABLE_BY_ID_FAILURE";

export const GET_VALUES_BY_VARIABLE_ID_REQUEST =
  "GET_VALUES_BY_VARIABLE_ID_REQUEST";
export const GET_VALUES_BY_VARIABLE_ID_SUCCESS =
  "GET_VALUES_BY_VARIABLE_ID_SUCCESS";
export const GET_VALUES_BY_VARIABLE_ID_FAILURE =
  "GET_VALUES_BY_VARIABLE_ID_FAILURE";

export const SET_VARIABLE_VALUE = "SET_VARIABLE_VALUE";
export const SET_VARIABLE_VALUES = "SET_VARIABLE_VALUES";

export const SET_VARIABLE_TEMP_VALUE = "SET_VARIABLE_TEMP_VALUE";
export const SET_VARIABLE_TEMP_VALUES = "SET_VARIABLE_TEMP_VALUES";

export const SYNC_VARIABLE_VALUES = "SYNC_VARIABLE_VALUES";
export const RESET_VARIABLE_VALUES = "RESET_VARIABLE_VALUES";

export const CLEAR_VARIABLE_DATA = "CLEAR_VARIABLE_DATA";

export const CLEAR_RESPONSE_VARIABLE = "CLEAR_RESPONSE_VARIABLE";

export const SET_FORM_DATA_VARIABLES = "SET_FORM_DATA_VARIABLES";
export const SET_CURRENT_VARIABLE = "SET_CURRENT_VARIABLE";
