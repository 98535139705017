import { styled as MuiStyled } from "@mui/material/styles";
import React from "react";
import ButtonItemColumn from "../Button/ButtonItemColumn";
import { EditIcon, DeleteIcon } from "../CustomIcons";

const RoundedRowWrapper = MuiStyled("div")(({ theme }) => ({
  width: "100%",
  borderBottom: `1px solid ${theme.palette.custom.text}`,
  borderRadius:" 0.1em",
  padding:" 0.5em",
  marginTop:" 0.6em",
}));
const ItemRowWithOptionsCategory = ({
  index,
  children,
  buttons,
  withCommonButtons,
  onItemEdit,
  tooltipTitleEdit,
  onItemDelete,
  tooltipTitleDelete,
  columnsNumber,
  customStyle
}) => {
  let commonButtons = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ButtonItemColumn
        onClick={onItemEdit}
        tooltip={tooltipTitleEdit}
        icon={<EditIcon className="iconButton" />}
      ></ButtonItemColumn>
      <ButtonItemColumn
        onClick={onItemDelete}
        tooltip={tooltipTitleDelete}
        icon={<DeleteIcon className="iconButton" />}
      ></ButtonItemColumn>
    </div>
  );
  return (
    <RoundedRowWrapper style={{...customStyle, gridTemplateColumns:
      "repeat(" + (columnsNumber?columnsNumber:4) + ", 1fr)"}} className="divRow">
      {children}
      <div>
        {buttons && buttons}
        {withCommonButtons && commonButtons}
      </div>
    </RoundedRowWrapper>
  );
};

export default ItemRowWithOptionsCategory;
