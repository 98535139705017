import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Components/Button";
import TextField from "../../../../Components/TextField";

import { Typography } from "@mui/material";
import CheckList from "../../../../Components/CheckList";
import { SaveIcon } from "../../../../Components/CustomIcons";
import CustomDataPicker from "../../../../Components/Datapicker";
import { CustomModal } from "../../../../Components/Modal";
import { useCategorySelected } from "../../../../Hooks/useCategorySelected";
import { usePromotions } from "../../../../Hooks/usePromotions";
import {
  createPromotion,
  setFormDataPromotions,
} from "../../../../Redux/modules/promotion-planning/promotions/actions";
import { getFormDataPromotions } from "../../../../Redux/modules/promotion-planning/promotions/selectors";

const FormPromotions = ({ type }) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const [configModal, setConfigModal] = useState({
    title: "",
    type: "save",
    open: false,
    cancelText: t("components_globals.button_cancel"),
    acceptText: t("components_globals.button_save"),
  });
  const tempForm = useSelector(getFormDataPromotions);
  const categorySelected = useCategorySelected({ subcategoryId: null });
  const { id, packages } = categorySelected;
  const { skus, customers } = usePromotions({ categoryId: id });

  const customersFiltered =
    tempForm.skuIds.length > 0
      ? customers.filter((c) => {
          let flag = false;
          tempForm.skuIds.forEach((sku) => {
            if (c.sku_ids.includes(sku)) {
              flag = true;
            }
          });
          return flag;
        })
      : customers;

  const handleCloseModal = () => {
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  const handleCancelModal = () => {
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  const onChangeForm = useCallback((type, value) => {
    dispatch(
      setFormDataPromotions({
        propertyName: type,
        propertyValue: value,
      })
    );
  }, []);

  const handleChangeSelectSkus = (value) => {
    if (value[0] !== undefined || value.length === 0) {
      onChangeForm("skuIds", value);
    }
  };
  const handleChangeSelectCustomers = (value) => {
    if (value[0] !== undefined || value.length === 0) {
      onChangeForm("customerIds", value);
    }
  };
  const handleChangeSelectPackages = (value) => {
    if (value[0] !== undefined || value.length === 0) {
      onChangeForm("packageIds", value);
    }
  };

  const handleOkModal = () => {
    dispatch(createPromotion.trigger());
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  return categorySelected.id ? (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        alignItems: "center",
        gap: "0.5rem",
        rowGap: "1rem",
        marginBottom: "1rem",
      }}
    >
      <TextField
        variant="third"
        required
        value={tempForm?.name}
        label={`${t(
          "sales_planning.promotion_planning_module.promotions_component.form.inputs.name"
        )}`}
        onChange={(e) => onChangeForm("name", e.target.value)}
      />
      <CustomDataPicker
        label={t(
          "sales_planning.promotion_planning_module.promotions_component.form.inputs.start_date"
        )}
        value={tempForm?.startPeriod}
        onChange={(e) => {
          dispatch(
            setFormDataPromotions({
              propertyName: "startPeriod",
              propertyValue: e,
            })
          );
        }}
        isDefault={true}
        minDate={tempForm.minDate}
        maxDate={tempForm.maxDate}
      />

      <CustomDataPicker
        label={t(
          "sales_planning.promotion_planning_module.promotions_component.form.inputs.end_date"
        )}
        value={tempForm?.endPeriod}
        isDefault={true}
        onChange={(e) => {
          dispatch(
            setFormDataPromotions({
              propertyName: "endPeriod",
              propertyValue: e,
            })
          );
        }}
        minDate={tempForm.minDate}
        maxDate={tempForm.maxDate}
      />

      <CheckList
        labelName={t(
          "sales_planning.promotion_planning_module.promotions_component.form.inputs.skus"
        )}
        items={skus.filter((x) => x.category_id[0] === categorySelected.id)}
        selectedItems={tempForm?.skuIds ? tempForm?.skuIds : []}
        onChangeSelectItems={handleChangeSelectSkus}
        onBuildItemName={(option) => {
          return `${option.brand_id[1]} ${option.name} ${option.unit_weight} ${
            option.uom_id ? option.uom_id[1] : ""
          }${option.package ? "/" + option.package : ""}`;
        }}
      ></CheckList>
      <CheckList
        labelName={t(
          "sales_planning.promotion_planning_module.promotions_component.form.inputs.customers"
        )}
        items={customersFiltered}
        selectedItems={tempForm?.customerIds ? tempForm?.customerIds : []}
        onChangeSelectItems={handleChangeSelectCustomers}
      ></CheckList>

      {packages.length > 0 ? (
        <CheckList
          labelName={t(
            "sales_planning.promotion_planning_module.promotions_component.form.inputs.packages"
          )}
          items={packages}
          selectedItems={tempForm?.packageIds ? tempForm?.packageIds : []}
          onChangeSelectItems={handleChangeSelectPackages}
        ></CheckList>
      ) : (
        <div></div>
      )}

      <div></div>
      <div></div>

      {!type && (
        <Button
          variant="thirdVariant"
          onClick={() => {
            setConfigModal({ ...configModal, open: !configModal.open });
          }}
        >
          <SaveIcon style={{ marginRight: "10px" }} />
          {t("components_globals.button_save")}
        </Button>
      )}
      <CustomModal
        customWidth="750px"
        title={configModal.title}
        type={configModal.type}
        open={configModal.open}
        onCloseModal={handleCloseModal}
        onCancelModal={handleCancelModal}
        onOkModal={handleOkModal}
        cancelText={configModal.cancelText}
        acceptText={configModal.acceptText}
      >
        <div>
          <Typography
            sx={{
              textAlign: "center",
              color: (theme) => theme.palette.custom.primary,
            }}
          >
            {t(
              "sales_planning.promotion_planning_module.promotions_component.form.messages.save_confirm"
            )}
          </Typography>
          <br />
          <div
            variant="body"
            style={{
              fontSize: "1.5em",
              textAlign: "center",
              fontWeight: 600,
            }}
          >
            {tempForm.name}
          </div>
        </div>
      </CustomModal>
    </div>
  ) : (
    <></>
  );
};

export default FormPromotions;
