import React from "react";
import { styled } from "@mui/material/styles";

const RoundedBoxWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  borderColor: theme.palette.custom.secondary,
  borderRadius: "1em",
  boxShadow: "3px 3px 5px #00000029",
  padding: "0 0.8em",
  marginBottom: "16px",
  height: "5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));
const RoundedBoxCategory = ({ children, variant = "draft" }) => {
  const getVariant = (variant) => {
    switch (variant) {
      case "done":
        return (
          <RoundedBoxWrapper
            variant={"contained"}
            sx={{
              borderLeft: (theme) =>
                `1.5rem solid ${theme.palette.custom.secondary}`,
            }}
          >
            {children}
          </RoundedBoxWrapper>
        );
        break;
      case "draft":
        return (
          <RoundedBoxWrapper
            variant={"contained"}
            sx={{
              borderLeft: (theme) =>
                `1.5rem solid ${theme.palette.custom.primary}`,
            }}
          >
            {children}
          </RoundedBoxWrapper>
        );
        break;
      case "available":
        return (
          <RoundedBoxWrapper
            variant={"contained"}
            sx={{
              borderLeft: (theme) =>
                `1.5rem solid ${theme.palette.custom.text}`,
            }}
          >
            {children}
          </RoundedBoxWrapper>
        );
        break;
      default:
        return <p>h1</p>;
    }
  };
  return getVariant(variant);
};
export default RoundedBoxCategory;
