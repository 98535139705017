
import * as type from './types';
import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";

export function saveConfig(action) {
  return {
    type: type.SAVE_CONFIG_REQUEST,
    payload: action
  };
}


export const updateLogo = createRoutine(type.UPDATE_LOGO);
export const resetColors = createAction(type.RESET_COLORS);

export function getConfig() {
  return {
    type: type.GET_CONFIG_REQUEST,
  };
}
export function selectLanguage(action) {
  return {
    type:  type.SELECT_LANGUAGE,
    payload: action
  };
}
export function clearResponseApp() {
  return {
    type: type.CLEAR_RESPONSE_APP,
  };
}
export function setInitialState() {
  return {
    type:  type.ON_INITIAL_STATE,
  };
}