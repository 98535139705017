import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "@mui/material/Select";
import FormControl from "../../../Components/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import {
  getCategoriesByState,
  getCategorySelected,
} from "../../../Redux/modules/categories/selectors";
import {
  selectCategory,
  getCategories,
} from "../../../Redux/modules/categories/actions";

export default function MenuSelectCategory() {
  const dispatch = useDispatch();
  const finishedCategories = useSelector((state) =>
    getCategoriesByState(state, "done")
  );
  const categorySelected = useSelector(getCategorySelected);
  const [t, i18n] = useTranslation("global");
  useEffect(() => {
    if (!categorySelected && finishedCategories.length > 0) {
      dispatch(selectCategory(finishedCategories[0]));
    }
  }, [finishedCategories]);

  useEffect(() => {
    if (finishedCategories.length === 0) {
      dispatch(getCategories());
    }
  }, []);

  const selectCategoryOptions = (c) => {
    return c.map((category, index) => (
      <MenuItem key={category.id} value={category.id}>
        {category.name}
      </MenuItem>
    ));
  };

  const handleChangeCategory = (event) => {
    const newCategoryIdSelected = event.target.value;
    const newCategorySelected = finishedCategories.find(
      (x) => x.id === newCategoryIdSelected
    );
    dispatch(selectCategory(newCategorySelected));
  };

  return (
    <>
      <FormControl style={{ width: "300px" }}>
        <InputLabel>{t("header.select_categories_label")}</InputLabel>
        {finishedCategories.length > 0 && (
          <Select
            value={categorySelected ? categorySelected.id : 0}
            label={t("header.select_categories_label")}
            onChange={handleChangeCategory}
          >
            {selectCategoryOptions(finishedCategories)}
          </Select>
        )}
      </FormControl>
    </>
  );
}
