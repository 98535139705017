import styled from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";

export const InitialSetupWrapper = styled.div`
  .alert-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5em 2.5em;
    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .action {
      display: flex;
      align-items: center;
      .icon-container {
        margin-left: 0.5em;
        padding: 1em;
        border-radius: 50%;
        background: red;
        display: flex;
      }
    }
  }
`;

export const StepWrapper = styled.div`
.iconButton{
  opacity:0.5;
}
.alert-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5em 2.5em;
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5em 2.5em;
    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    .action {
      display: flex;
      align-items: center;
      .icon-container {
        margin-left: 0.5em;
        padding: 1em;
        border-radius: 50%;
        background: red;
        display: flex;
      }
      .color {
        box-shadow: 0px 3px 6px #00000029;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
      }
    }
  }
`;

export const RoundedButton = MuiStyled("div")(({ theme }) => ({
  // backgroundColor: theme.palette.custom.primary,
  // marginLeft: ".5em",
  // padding: "1em",
  // borderRadius: "50%",
  // display: "flex",
  // cursor: "pointer",
  // transition: "all .25s",
  // "&:hover": {
  //   filter: "brightness(1.15)",
  // },
}));
