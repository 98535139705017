import React from "react";
import Backdrop from "@mui/material/Backdrop";
import { LoaderIcon } from "../CustomIcons";
import { styled, makeStyles } from "@mui/material/styles";
import effect from "./effect.css";

export const CustomLoading = styled("span")(({ style, theme }) => ({
  width: "48px",
  height: "48px",
  borderRadius: "50%",
  display: "inline-block",
  position: "relative",
  border: "3px solid",
  borderColor: "#FFF #FFF transparent transparent",
  boxSizing: "border-box",
  animation: "rotation 1s linear infinite",

  "&:after, &:before": {
    content: "''",
    boxSizing: "border-box",
    position: "absolute",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    margin: "auto",
    border: "3px solid",
    borderColor: "transparent transparent #6FD1B0 #6FD1B0",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    boxSizing: "border-box",
    animation: "rotationBack 0.5s linear infinite",
    transformOrigin: "center center",
  },
  "&:before": {
    width: "32px",
    height: "32px",
    borderColor: "#1F1C36 #1F1C36 transparent transparent",
    animation: "rotation 1.5s linear infinite",
  },
}));

function Loading({ isLoading }) {
  return (
    <Backdrop
      sx={{ color: "#2e2e31", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          justifyContent:"center",
          alignItems:"center"
        }}
      >
        <LoaderIcon style={{ width: "80%", opacity: 0.3 }} />
        <span className="loader"></span>
      </div>
    </Backdrop>
  );
}

export default Loading;
