import { TableBody, Menu, MenuItem } from "@mui/material/";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  commaSeparateNumber,
  exportToExcel,
  getMonthName,
} from "../../../../Utils";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import { DownloadIcon, SaveIcon } from "../../../../Components/CustomIcons";
import { getDateValues } from "../../../../Utils/commonWithDates";
import {
  ButtonsCell,
  CustomCell,
  CustomCellSticky,
  CustomTable,
  TitleCell,
} from "./Styles";
import { useState } from "react";
import { useScreenshot, createFileName } from "use-react-screenshot";

export default function TableMomentum({ name, id, rows, columns, children }) {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [t, i18n] = useTranslation("global");
  const [anchorMenuDownload, setAnchorMenuDownload] = useState(null);
  const isOpenMenuDownload = Boolean(anchorMenuDownload);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpg",
    quality: 1.0,
  });
  
  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };
  
  const handleDownloadExcel = () => {
    let [table] = document.getElementsByClassName(`tableMomentum${name}`);
    try {
      let excelTable = document.createElement("table");
      excelTable.innerHTML = table.innerHTML.trim();
      let firstCell = excelTable.querySelector(".first-cell");
      firstCell.innerHTML = "";
      let collapses = excelTable.querySelectorAll(".collapse-tr");
      for (let index = 0; index < collapses.length; index++) {
        const collapse = collapses[index];
        let expandTr = collapse.previousSibling;
        const tbody = collapse.querySelector("tbody");
        if (tbody) {
          let inputs = tbody.querySelectorAll("input");
          for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            const parentTd = input.parentNode;
            parentTd.className = "";
            parentTd.innerHTML = input.value;
          }
          const rows = tbody.innerHTML;
          expandTr.insertAdjacentHTML("afterend", rows);
        }
        collapses[index].parentNode.removeChild(collapses[index]);
      }
      exportToExcel(name, excelTable);
    } catch (error) {}
  };

  const handleDownloadImage = () => {
    const downloadScreenshot = () =>
      takeScreenShot(tableRef.current).then(download);
    downloadScreenshot();
  };

  const handleOpenDownloadMenu = (event) => {
    setAnchorMenuDownload(event.currentTarget);
  };

  const handleCloseDownloadMenu = () => {
    setAnchorMenuDownload(null);
  };

  const buildHeaders = (cols) => {
    return (
      cols &&
      cols.map((col, index) => {
        const colTitle = getDateValues(col);
        return (
          <TitleCell minWidth="60px" color="thirdVariant" key={`th-${index}`}>
            <span style={{ fontWeight: "500", fontSize: "0.8rem" }}>
              {`${t(`months_names.${getMonthName(colTitle.month - 1)}`)
                .substring(0, 3)
                .toUpperCase()}-${colTitle.year.toString().substring(2, 4)}`}
            </span>
          </TitleCell>
        );
      })
    );
  };

  const buildRows = (rows) => {
    let templateRows = rows.map((row, rowIndex) => (
      <TableRow
        style={{
          marginTop: "20px",
          zIndex: 100,
          backgroundColor: "#fff",
        }}
      >
        <CustomCellSticky
          minWidth="150px"
          maxWidth="150px"
          backgroundColor={rowIndex % 2 === 0 ? "text" : "primary"}
          type="large"
        >
          <span style={{ marginLeft: "3x" }}>
            {t(`price_management.sniper_module.momentum_component.${row.name}`)}
          </span>
        </CustomCellSticky>
        {row.values.map((col, index) => (
          <CustomCell
            minWidth="60px"
            backgroundColor={rowIndex % 2 === 0 ? "text" : "primary"}
            key={`${col.index}-${index}`}
          >
            {commaSeparateNumber(col.toFixed(2))}
          </CustomCell>
        ))}
      </TableRow>
    ));
    return templateRows;
  };

  return (
    <div style={{ padding: "1rem" }}>
      <TableContainer sx={{ maxHeight: 640 }}>
        <CustomTable className={`tableMomentum${name}`} ref={tableRef}>
          <TableHead
            style={{
              zIndex: 100,
              position: "sticky",
              top: 0,
              backgroundColor: "#fff",
            }}
          >
            <TableRow>
              <ButtonsCell
                minWidth="150px"
                maxWidth="150px"
                className="first-cell"
                sx={{ border: "none" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    marginRight: "20px",
                  }}
                >
                  <ButtonItemColumn
                    onClick={() => {}}
                    tooltip={t("components_globals.button_save")}
                    icon={
                      <SaveIcon
                        className="iconButtonPrimary strokeLigth"
                        sx={{ fontSize: "2rem" }}
                      />
                    }
                  />
                  <ButtonItemColumn
                    onClick={handleOpenDownloadMenu}
                    tooltip={t("components_globals.button_download")}
                    icon={
                      <DownloadIcon
                        sx={{ fontSize: "1.5rem" }}
                        className="iconButtonPrimary strokeLigth"
                      />
                    }
                  />
                  <Menu
                    id={`download-menu-`}
                    anchorEl={anchorMenuDownload}
                    open={isOpenMenuDownload}
                    onClose={handleCloseDownloadMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleDownloadImage}>
                      Download Image
                    </MenuItem>
                    <MenuItem onClick={handleDownloadExcel}>
                      Download Excel
                    </MenuItem>
                  </Menu>
                </div>
              </ButtonsCell>
              {buildHeaders(columns)}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 && buildRows(rows)}
            {children}
          </TableBody>
        </CustomTable>
      </TableContainer>
    </div>
  );
}
