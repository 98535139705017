import { getCategorySelected } from "../Redux/modules/categories/selectors";
import { useSelector } from "react-redux";

export function useCategorySelected({ subcategoryId }) {
  const categorySelected = useSelector(getCategorySelected);

  if (!categorySelected) {
    return {
      id: null,
      skus: [],
      packages: [],
      subcategory: null,
    };
  }

  const packages =
    categorySelected?.sku_ids &&
    categorySelected?.sku_ids
      .map((sku) => sku.package + "")
      .filter((x) => x !== "false");
  const subcategories = categorySelected?.subcategory_ids;
  let skus = categorySelected?.sku_ids;
  let subcategory = null;
  if (!subcategoryId) {
    subcategory = subcategories ? subcategories[0] : null;
  } else {
    subcategory = subcategories
      ? subcategories.find((x) => x.id === subcategoryId)
      : null;
  }
  skus = subcategory
    ? categorySelected.sku_ids.filter((x) => subcategory.sku_ids.includes(x.id))
    : skus;

  return {
    ...categorySelected,
    skus,
    packages,
    subcategory,
    subcategories,
  };
}
