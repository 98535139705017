import produce from "immer";
import { setCurrentPage, setCurrentView } from "./actions";
import { initialState } from "./states";

export const layout = (state = initialState, { payload, type }) =>
  produce(state, (draft) => {
    switch (type) {
      case setCurrentPage.toString():
        draft.currentPage = payload;
        draft.showCategorySelectInHeader =
          draft.pagesWithSelectCategory.includes(payload);
        break;
      case setCurrentView.toString():
        draft.currentView = payload;
        draft.showCategorySelectInHeader =
          draft.pagesWithSelectCategory.includes(
            `${draft.currentPage}/${payload}`
          );
        break;
    }
  });
