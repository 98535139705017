import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { token } from "../auth/selectors";
import {
  GET_UNITS_FAILURE,
  GET_UNITS_REQUEST,
  GET_UNITS_SUCCESS,
} from "./types";

function* getUnits() {
  try {
    const tokenAccess = yield select(token);
    const response = yield call(callApiAxios,"GET", `uom.uom`,tokenAccess, {}, {});
    yield put({ type: GET_UNITS_SUCCESS, units: response.data });
  } catch (e) {
    yield put({
      type: GET_UNITS_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

export default function* unitsSaga() {
  yield takeEvery(GET_UNITS_REQUEST, getUnits);
}
