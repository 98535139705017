export const GET_LOAD_CURVES = "GET_LOAD_CURVES";
export const GET_SIMULATE_CURVES = "GET_SIMULATE_CURVES";
export const GET_SIMULATOR_DATA = "GET_SIMULATOR_DATA";
export const CLEAR_RESPONSE_CURVES = "CLEAR_RESPONSE_CURVES";
export const CLEAR_CURVES_SIMULATION = "CLEAR_CURVES_SIMULATION";
export const CLEAR_CURVES_SIMULATION_DATA = "CLEAR_CURVES_SIMULATION_DATA";
export const SET_FORM_DATA_SIMULATION = "SET_FORM_DATA_SIMULATION";
export const SET_AXIS_GRAPH = "SET_AXIS_GRAPH";
export const SET_TYPE_FUNCTION = "SET_TYPE_FUNCTION";
export const SET_DEFAULT_FORM_DATA_CURVES = "SET_DEFAULT_FORM_DATA_CURVES";
export const SET_SIMULATOR_DATA= "SET_SIMULATOR_DATA";


