import * as type from "./types";
import { getCategories, setFinishCategory } from "./actions";
import { initialState } from "./states";
import produce from "immer";

export const categories = (state = initialState, action) =>
  produce(state, (draft) => {
    let category = null;
    let categoryId = 0;
    let subcategory = null;
    let brand = null;
    let owner = null;
    let sku = null;
    let skus = [];
    let variable = [];
    let index = 0;
    let relations = [];

    switch (action.type) {
      case getCategories.TRIGGER:
        draft.loading = true;
        break;
      case getCategories.REQUEST:
        break;
      case getCategories.SUCCESS:
        draft.categories = action.payload.categories;
        break;
      case getCategories.FAILURE:
        draft.loading = false;
        draft.categories = [];
        break;
      case getCategories.FULFILL:
        draft.loading = false;
        break;
      case type.CREATE_CATEGORY_REQUEST:
        draft.loading = true;
        break;
      case type.CREATE_CATEGORY_SUCCESS:
        draft.loading = false;
        draft.response = {
          message: "initial_setup.messages.create_category_success",
          error: null,
        };
        draft.categories.push(action.category);
        draft.currentCategoryId = action.category.id;
        break;
      case type.CREATE_CATEGORY_FAILURE:
        draft.loading = false;
        draft.response = { message: null, error: action.error };
        break;
      case type.GET_CATEGORY_BY_ID_REQUEST:
        draft.loading = true;
        break;
      case type.GET_CATEGORY_BY_ID_SUCCESS:
        draft.loading = false;
        draft.category = action.category;

        break;
      case type.GET_CATEGORY_BY_ID_FAILURE:
        draft.loading = false;
        draft.categorySelected = null;
        draft.category = null;
        break;
      case type.CLEAR_CATEGORY:
        draft.loading = false;
        draft.categorySelected = null;
        draft.category = null;
        break;
      case type.SELECT_CATEGORY:
        draft.categorySelected = action.payload;
        break;
      case type.ADD_SUBCATEGORY_TO_CATEGORY:
        subcategory = action.subcategory;
        draft.category.subcategory_ids.push(subcategory);
        break;

      case type.UPDATE_SUBCATEGORY_IN_CATEGORY:
        subcategory = action.subcategory;
        let tempSubcategory = draft.category.subcategory_ids.find(
          (x) => x.id === subcategory.id
        );
        tempSubcategory.name = subcategory.name;
        tempSubcategory.description = subcategory.description;
        tempSubcategory.color = subcategory.color;
        break;
      case type.DELETE_SUBCATEGORY_OF_CATEGORY:
        subcategory = action.subcategory;
        draft.category.subcategory_ids = state.category.subcategory_ids.filter(
          (x) => x.id != subcategory.id
        );
        break;
      case type.ADD_OWNER_TO_CATEGORY:
        if (action.payload.many) {
          let tempOwners = action.payload.owners;
          draft.category.owner_ids =
            draft.category.owner_ids.concat(tempOwners);
        } else {
          let tempOwner = action.payload.owner;
          draft.category.owner_ids.push(tempOwner);
        }
        break;

      case type.UPDATE_OWNER_IN_CATEGORY:
        owner = action.payload.owner;
        let tempOwner = draft.category.owner_ids.find((x) => x.id === owner.id);
        tempOwner.name = owner.name;
        tempOwner.description = owner.description;
        tempOwner.color = owner.color;
        break;
      case type.DELETE_OWNER_OF_CATEGORY:
        owner = action.owner;
        draft.category.owner_ids = state.category.owner_ids.filter(
          (x) => x.id != owner.id
        );
        break;

      case type.ADD_BRAND_TO_CATEGORY:
        if (action.payload.many) {
          let tempBrands = action.payload.brands.map((x) => {
            return { ...x, owner_id: [x.owner_id[0].id, x.owner_id[0].name] };
          });
          draft.category.brand_ids =
            draft.category.brand_ids.concat(tempBrands);
        } else {
          brand = action.payload.brand;
          draft.category.brand_ids.push(brand);
        }
        break;

      case type.UPDATE_BRAND_IN_CATEGORY:
        brand = action.brand;
        let tempBrand = draft.category.brand_ids.find(
          (x) => x.id === action.brand.id
        );
        tempBrand.name = brand.name;
        tempBrand.description = brand.description;
        tempBrand.color = brand.color;
        tempBrand.owner_id = brand.owner_id;
        break;
      case type.DELETE_BRAND_OF_CATEGORY:
        brand = action.brand;
        draft.category.brand_ids = state.category.brand_ids.filter(
          (x) => x.id != brand.id
        );
        break;
      case type.ADD_SKU_TO_CATEGORY:
        skus = action.payload.skus;
        for (let sku of skus) {
          if (sku.owner_id && sku.owner_id[0].id) {
            sku.owner_id = [sku.owner_id[0].id, sku.owner_id[0].name];
          }
          if (sku.brand_id && sku.brand_id[0].id) {
            sku.brand_id = [sku.brand_id[0].id, sku.brand_id[0].name];
          }
          if (sku.subcategory_id && sku.subcategory_id[0].id) {
            sku.subcategory_id = [
              sku.subcategory_id[0].id,
              sku.subcategory_id[0].name,
            ];
          }
          draft.category.sku_ids.push(sku);
        }
        break;

      case type.UPDATE_SKU_IN_CATEGORY:
        sku = action.payload.sku;
        if (sku.owner_id && sku.owner_id[0].id) {
          sku.owner_id = [sku.owner_id[0].id, sku.owner_id[0].name];
        }
        if (sku.brand_id && sku.brand_id[0].id) {
          sku.brand_id = [sku.brand_id[0].id, sku.brand_id[0].name];
        }
        if (sku.subcategory_id && sku.subcategory_id[0].id) {
          sku.subcategory_id = [
            sku.subcategory_id[0].id,
            sku.subcategory_id[0].name,
          ];
        }
        let tempSku = draft.category.sku_ids.find((x) => x.id === sku.id);
        tempSku.uom_id = sku.uom_id;
        tempSku.package = sku.package;
        tempSku.cost = sku.cost;
        tempSku.internal_code = sku.internal_code;
        tempSku.subcategory_id = sku.subcategory_id;
        tempSku.brand_id = sku.brand_id;
        tempSku.owner_id = sku.owner_id;
        tempSku.unit_weight = sku.unit_weight;
        tempSku.name = sku.name;
        tempSku.image = sku.image;
        tempSku.write_date = sku.write_date;
        break;
      case type.DELETE_SKU_OF_CATEGORY:
        sku = action.sku;
        draft.category.sku_ids = state.category.sku_ids.filter(
          (x) => x.id !== sku.id
        );
        break;
      case type.ADD_RELATION_SKU_TO_CATEGORY:
        relations = action.payload.relations;
        sku = draft.category.sku_ids.find(
          (x) => x.id === action.payload.idFrom
        );
        sku.affects_ids = relations;
        break;

      case type.DELETE_RELATION_SKU_OF_CATEGORY:
        relations = action.payload.relations;
        sku = draft.category.sku_ids.find(
          (x) => x.id === action.payload.idFrom
        );
        sku.affects_ids = relations;
        break;

      case type.ADD_VARIABLE_TO_CATEGORY:
        variable = action.variable;
        draft.category.variable_ids.push(variable);
        break;

      case type.UPDATE_VARIABLE_IN_CATEGORY:
        variable = action.variable;
        let tempVariable = draft.category.variable_ids.find(
          (x) => x.id === variable.id
        );
        tempVariable.name = variable.name;
        tempVariable.scope = variable.scope;
        tempVariable.influences_for = variable.influences_for;
        tempVariable.period_width = variable.period_width;
        tempVariable.completion_rule = variable.completion_rule;
        tempVariable.first_period = variable.first_period;
        break;

      case type.DELETE_VARIABLE_OF_CATEGORY:
        variable = action.variable;
        draft.category.variable_ids = state.category.variable_ids.filter(
          (x) => x.id != variable.id
        );
        break;
      case type.CLEAR_RESPONSE_CATEGORY:
        draft.response = { error: null, message: null };
        break;
      case setFinishCategory.TRIGGER:
        draft.loading = true;
        break;
      case setFinishCategory.REQUEST:
        break;
      case setFinishCategory.SUCCESS:
        draft.response.message =
          "initial_setup.messages.upload_category_success";
        draft.response.error = null;
        break;
      case setFinishCategory.FAILURE:
        draft.loading = false;
        draft.response = { message: null, error: action.error };
        break;
      case setFinishCategory.FULFILL:
        draft.loading = false;
        break;
      case type.ADD_VALUES_TO_CATEGORY:
        draft.category.values = action.payload;
        break;
      case type.UPDATE_CATEGORY_AFTER_LOAD_DATA:
        draft.category.first_period = action.payload.first_period;
        draft.category.period_width = action.payload.period_width;
        break;
    }
  });
