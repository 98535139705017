import React from "react";
import { SvgIcon } from "@mui/material";


export function FilterIcon(props) {
    const classFilterIcon = {
        fill: "none",
        strokelinecap: "round",
        strokelinejoin: "round",
        strokewidth: "1.5",
      };
    return (
    <SvgIcon viewBox="0 0 48 48" {...props}>
      
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M23.2,2.054a.751.751,0,0,0-.748-.8H1.5a.75.75,0,0,0-.748.8,11.254,11.254,0,0,0,8.972,10.22v9.875a.75.75,0,0,0,1.23.576l3-2.5a.748.748,0,0,0,.27-.576V12.274A11.253,11.253,0,0,0,23.2,2.054Z"
       className={classFilterIcon}
        ></path>
      </g>
    </SvgIcon>
  );
}
