import React from "react";
import styled from "styled-components";

const CssCustomLoaderIcon = styled.svg`
  stroke: #fff;
  fill: #fff;
  .a {
    animation: logo 3s linear infinite;
  }
  @keyframes logo {
    0% {
      stroke-width: 1pt !important;
      stroke-dashoffset: 1200;
      fill-opacity: 0;
    }
    40% {
      fill-opacity: 0;
    }
    100% {
      stroke-dashoffset: 0;
      fill-opacity: 1;
      stroke-width: 1pt;
    }
  }
`;

export function LoaderIcon(props) {
  const iconClass = {
    fill: "#fff",
    stroke: "#fff",
  };
  return (
    <CssCustomLoaderIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 382.19 128.48"
      {...props}
    >
      <g id="a" />
      <g id="b">
        <g id="c">
          <g>
            <g>
              <g>
                <path
                  className={iconClass}
                  d="M0,14.4H30.13v2.85H3.29v18.65H28.52v2.71H3.29v22.6H0V14.4Z"
                />
                <path
                  className={iconClass}
                  d="M32.76,42.27c0-9.73,3.66-19.23,18.21-19.23s18.28,9.5,18.28,19.23-3.73,19.31-18.36,19.31-18.14-9.44-18.14-19.31Zm18.14,16.46c12.14,0,15.14-7.53,15.14-16.46s-2.92-16.38-15.06-16.38-15,7.75-15,16.38,2.85,16.46,14.92,16.46Z"
                />
                <path
                  className={iconClass}
                  d="M74.65,23.4h1.9l.73,8.12c1.46-5.12,5.71-8.63,14.99-8.48v2.92c-11.85-.22-14.4,5.26-14.4,14.84v20.41h-3.22V23.4Z"
                />
                <path
                  className={iconClass}
                  d="M91.62,42.34c0-9.94,4.02-19.3,18.43-19.3,13.46,0,17.19,9.5,16.75,20.84h-31.81c.29,8.41,4.02,15.07,15.06,15.07,9.14,0,12.8-4.75,13.38-9.58h3.36c-.66,6.44-5.34,12.21-16.67,12.21-14.63,0-18.5-9.22-18.5-19.24Zm31.89-1.24c.07-8.85-3.66-15.43-13.75-15.43-10.9,0-14.7,7.17-14.77,15.43h28.52Z"
                />
                <path
                  className={iconClass}
                  d="M130.81,42.49c0-10.09,3.95-19.45,18.21-19.45,11.34,0,16.82,5.63,16.82,14.99h-3.29c-.22-7.9-4.39-12.21-13.53-12.21-11.7,0-14.99,7.75-14.99,16.67s3.22,16.31,15.06,16.31c9.22,0,13.24-4.46,13.46-12.21h3.29c0,9.36-5.34,15-16.67,15-14.63,0-18.36-9.22-18.36-19.09Z"
                />
                <path
                  className={iconClass}
                  d="M170.22,50.82c0-6.8,4.61-9.21,14.99-10.6l15.73-2.12c-.22-8.04-2.49-12.43-13.75-12.43-10.02,0-12.87,4.53-12.87,10.82h-3.22c0-7.61,3.58-13.46,16.02-13.46,14.55,0,16.89,6.22,16.89,16.6v21.57h-1.98l-.59-9.58c-2.34,6.29-9.14,9.95-17.19,9.95s-14.04-3.29-14.04-10.75Zm14.7,8.19c8.26,0,15.51-4.75,15.87-14.62l.15-3.66-15.29,2.05c-8.99,1.17-12.14,3.14-12.14,7.9,0,5.63,4.68,8.34,11.41,8.34Z"
                />
                <path
                  className={iconClass}
                  d="M208.75,46.95h3.14c0,7.82,4.17,11.92,14.26,11.92,9.29,0,12.58-3.95,12.58-7.53,.08-6.07-5.26-7.02-14.62-8.41-7.97-1.17-14.41-2.49-14.41-9.8,0-5.93,3.51-10.09,15.43-10.09s15.87,5.78,15.87,13.97h-3c-.07-6.73-3.36-11.33-12.94-11.33s-12.21,3.22-12.21,7.39c0,5.56,5.41,6.29,12.8,7.39,9.43,1.39,16.24,2.92,16.24,10.68,0,5.12-4.39,10.46-15.65,10.46-12.29,0-17.48-4.9-17.48-14.63Z"
                />
                <path
                  className={iconClass}
                  d="M247.43,50.09V26.11h-4.53v-2.27l4.53-.58V14.4h3.22v8.99h13.67v2.71h-13.67v23.99c0,6.07,2.71,8.77,10.68,8.77,.95,0,1.9-.07,3-.14v2.78c-.95,.08-1.9,.08-2.78,.08-9.95,0-14.12-3.22-14.12-11.48Z"
                />
                <path
                  className={iconClass}
                  d="M270.69,12.14c2.12,0,3.44,.44,3.44,2.85,0,2.27-1.32,2.92-3.44,2.92s-3.36-.66-3.36-2.92c0-2.41,1.17-2.85,3.36-2.85Zm-1.54,11.26h3.22V61.21h-3.22V23.4Z"
                />
                <path
                  className={iconClass}
                  d="M279.31,23.4h1.83l.81,8.19c1.46-5.12,6.22-8.56,15.14-8.56,13.24,0,15.5,7.02,15.5,17.99v20.19h-3.07v-20.41c0-9.5-1.98-15.06-13.16-15.06s-13.75,5.7-13.75,15.06v20.41h-3.29V23.4Z"
                />
                <path
                  className={iconClass}
                  d="M316.6,63.11h3.36c.07,6.87,2.63,11.26,15.14,11.26,11.48,0,14.19-3.8,14.19-7.53,0-4.75-3.66-5.85-14.19-7.39-10.83-1.61-17.77-2.05-17.77-6.8,0-2.41,1.83-4.24,5.63-4.98-3.8-2.49-5.04-6.22-5.04-10.6,0-7.82,3.44-13.68,16.82-13.68h16.31v1.83l-7.31,.51c5.12,1.68,6.88,6,6.88,11.63,0,7.61-3.22,13.46-15.8,13.46-4.97,0-8.12-.95-9.43-1.46-4.02,.44-4.9,1.68-4.9,2.85,0,2.92,5.19,3.14,15.29,4.6,11.41,1.68,16.89,3.29,16.89,9.73,0,5.56-4.02,10.61-17.77,10.61s-18.28-5.49-18.28-14.04Zm17.7-14.78c10.53,0,12.87-4.53,12.87-11.34,0-6.14-2.05-10.89-12.87-10.89s-13.09,4.6-13.09,11.04,2.41,11.19,13.09,11.19Z"
                />
              </g>
              <g>
                <path
                  className={iconClass}
                  d="M.73,81.16H8.78v40.22H26.55v6.58H.73v-46.8Z"
                />
                <path
                  className={iconClass}
                  d="M29.83,117c0-6.88,4.9-9.95,15.58-11.04l11.78-1.17c-.22-6.14-2.19-8.56-9.73-8.56-6.95,0-8.85,2.78-8.85,7.46l-7.61,.07c0-7.75,3.22-13.75,16.38-13.75,15.28,0,17.48,6.58,17.48,16.67v21.28h-4.24l-1.61-5.63c-1.76,2.92-6.95,6.14-14.7,6.14s-14.48-3.58-14.48-11.48Zm16.46,5.34c7.53,0,10.9-3.95,10.9-10.46v-1.17l-10.61,1.17c-6.51,.73-8.7,2.12-8.7,5.04,0,3.44,2.92,5.41,8.41,5.41Z"
                />
                <path
                  className={iconClass}
                  d="M77.36,122.56l-1.61,5.41h-4.24v-51.7h7.68v18.79c1.61-2.49,5.34-5.04,12.72-5.04,12.36,0,15.79,8.99,15.79,19.23s-3.66,19.23-15.94,19.23c-8.92,0-12.94-3.36-14.41-5.92Zm12.29-.44c8.41,0,10.24-5.2,10.24-12.87s-1.83-12.65-10.46-12.65-10.31,5.27-10.31,12.58c0,7.75,1.83,12.94,10.53,12.94Z"
                />
              </g>
            </g>
            <g id="d">
              <path
                id="e"
                className={iconClass}
                d="M364.62,18.49V4.74h5.85c1.08-.05,2.16,.08,3.2,.37,.67,.25,1.23,.71,1.6,1.32,.41,.65,.61,1.4,.6,2.17,.03,.94-.29,1.85-.91,2.55-.73,.73-1.69,1.18-2.72,1.27,.55,.31,1.05,.7,1.49,1.15,.59,.7,1.12,1.44,1.58,2.23l1.68,2.68h-3.32l-2.01-2.99c-.44-.7-.93-1.38-1.46-2.02-.23-.25-.52-.45-.84-.57-.45-.13-.93-.18-1.4-.16h-.56v5.74h-2.77Zm2.77-7.93h2.06c.83,.04,1.67-.01,2.49-.17,.32-.1,.59-.31,.78-.58,.2-.31,.29-.67,.28-1.03,.02-.41-.12-.81-.37-1.12-.27-.3-.64-.49-1.05-.54-.22-.03-.9-.04-2.02-.04h-2.16v3.49Z"
              />
              <path
                id="f"
                className={iconClass}
                d="M382.19,12.14c0,6.7-5.44,12.14-12.14,12.14-6.7,0-12.14-5.44-12.14-12.14C357.92,5.43,363.35,0,370.05,0c6.7,0,12.14,5.43,12.14,12.14h0m-12.14,10.26c5.66,0,10.26-4.59,10.26-10.26,0-5.66-4.59-10.26-10.26-10.26-5.66,0-10.26,4.59-10.26,10.26h0c0,5.66,4.59,10.25,10.26,10.26"
              />
            </g>
          </g>
        </g>
      </g>
    </CssCustomLoaderIcon>
  );
}
