import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "../../../../Components/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { setFormDataTrend } from "../../../../Redux/modules/what-if/trend/actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["value", "profit", "distribution", "som","quantity", "quantity_kg"];

function getStyles(name, variablesName, theme) {
  return {
    fontWeight:
      variablesName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function InputSelectVariables({ variables }) {
  const theme = useTheme();
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.length <= 2 && value.length) {
      dispatch(
        setFormDataTrend({
          propertyName: "variablesSelected",
          propertyValue: value,
        })
      );
    }
  };

  return (
    <div style={{ minWidth: "18rem" }}>
      <FormControl variant="secondary">
        <InputLabel>{t("price_management.trend.variables")}</InputLabel>
        <Select
          label={t("price_management.trend.variables")}
          multiple
          fullWidth
          value={variables ? variables : []}
          onChange={handleChange}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={t(`price_management.trend.${value}`)}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, variables, theme)}
            >
              {t(`price_management.trend.${name}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
