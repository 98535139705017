export const authState = state => state.auth;

export const isAuth = state => authState(state).isAuth;

export const token = state => authState(state).access_token;

export const getLoadingAuth = state => authState(state).loading;

export const getResponseAuth = state => authState(state).response;

export const getUserRemember = state => authState(state).user;

export const getRemember = state => authState(state).remember;

export const getUid = state => authState(state).uid;