import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material/";
import IconButton from "@mui/material/IconButton";

const Input = styled("input")({
  display: "none",
});
const CssButtonUpload = styled(Button)(({ theme }) => ({
  color: theme.palette.custom.primary,
  stroke: theme.palette.custom.primary,

  backgroundColor: theme.palette.custom.secondary,
  borderRadius: "2em",
  height: "2.3rem",
  width: "10rem",
  transition: "filter .25s",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: theme.palette.custom.secondary,
    color: theme.palette.custom.primary,
    stroke: theme.palette.custom.primary,
    filter: "brightness(1.15)",
  },
}));

export default function ButtonUpload({
  title,
  onChange,
  iconUpload,
  style,
  id = Math.random(),
}) {
  return (
    <label htmlFor={`contained-button-file-${id}`}>
      <Input
        accept="image/*"
        id={`contained-button-file-${id}`}
        type="file"
        onChange={onChange}
      />
      <CssButtonUpload
        variant="contained"
        component="span"
        style={{ ...style }}
      >
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          {iconUpload}
        </IconButton>
        <Typography variant="caption" fontWeight={600}>
          {" "}
          {title}
        </Typography>
      </CssButtonUpload>
    </label>
  );
}
