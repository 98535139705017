import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import { styled as MuiStyled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  AlertBellIcon,
  EarthIcon,
  LogoutIcon,
  QuestionIcon,
  SettingIcon
} from "../../../Components/CustomIcons";
import { showCategorySelectInHeader } from "../../../Redux/modules/layout/selectors";
import FloatingMenu from "./FloatingMenu";
import { FloatingMenuLanguages } from "./FloatingMenuLanguages";
import { FloatingMenuNotifications } from "./FloatingMenuNotifications";
import MenuSelectCategory from "./MenuSelectCategory";

const HeaderWrapper = MuiStyled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  paddingRight: "2em",
  ".user-data": {
    display: "flex",
    alignItems: "center",
    ".iconoEarth": {
      marginright: "1px",
    },
    ".title": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      color: theme.palette.custom.third,
    },
    ".logo-container": {
      padding: "1em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "3em",
      minWidth: "100px",
      height: "3em",
      marginLeft: "1em",
    },
  },
}));

const AppBar = MuiStyled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, sideBarWidth }) => ({
  padding: ".5em",
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.custom.secondary,
  color: theme.palette.custom.primary,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: sideBarWidth,
    width: `calc(100% - ${sideBarWidth})`,
    backgroundColor: theme.palette.custom.secondary,
    color: theme.palette.custom.primary,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = ({ sideBarWidth, user, openSideBar, setOpenSideBar }) => {
  const [t, i18n] = useTranslation("global");
  const showCategorySelect = useSelector(showCategorySelectInHeader);
  const menuOptions = [
    {
      icon: <SettingIcon />,
      text: t("drawer_component.settings"),
      id: "1",
      path: "/settings",
    },
    {
      icon: <QuestionIcon />,
      text: t("drawer_component.supports"),
      id: "2",
      path: "/supports",
    },
    {
      icon: <LogoutIcon />,
      text: t("drawer_component.logout"),
      id: "3",
    },
  ];

  return (
    <AppBar
      sx={{ zIndex: 1200 }}
      position="fixed"
      sideBarWidth={sideBarWidth}
      open={openSideBar}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={setOpenSideBar}
          edge="start"
          sx={{
            marginRight: "36px",
            ...(openSideBar && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        {showCategorySelect && <MenuSelectCategory></MenuSelectCategory>}
        <HeaderWrapper>
          <div className="user-data">
            <FloatingMenuLanguages className="iconoEarth">
              <EarthIcon />
            </FloatingMenuLanguages>
            <FloatingMenuNotifications>
              <AlertBellIcon />
            </FloatingMenuNotifications>
            <FloatingMenu options={menuOptions}>
              <div className="title">
                <strong className="company-name">{user.companyName}</strong>
                <div className="user-name">{user.userName}</div>
              </div>
            </FloatingMenu>
            <div className="logo-container">
              {user.imageLogo ? (
                <img
                  style={{ width: "70px" }}
                  src={`${
                    user.imageLogo.indexOf("base64") != -1
                      ? user.imageLogo
                      : `data:image/png;base64,${user.imageLogo}`
                  }`}
                  alt="image"
                />
              ) : (
                <ImageOutlinedIcon />
              )}
            </div>
          </div>
        </HeaderWrapper>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
