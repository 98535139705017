export const GET_LOAD_MATRIZ_E = "GET_LOAD_MATRIZ_E";
export const GET_SIMULATE_MATRIZ_E = "GET_SIMULATE_MATRIZ_E";
export const CLEAR_SIMULATE_MATRIZ_E = "CLEAR_SIMULATE_MATRIZ_E";
export const SET_FORM_DATA_MATRIZ_E = "SET_FORM_DATA_MATRIZ_E";
export const SET_DEFAULT_FORM_DATA_MATRIZ_E = "SET_DEFAULT_FORM_DATA_MATRIZ_E";
export const CLEAR_RESPONSE_MATRIZ_E  = "CLEAR_RESPONSE_MATRIZ_E";
export const SET_SIMULATION_DATA_MATRIZ_E  = "SET_SIMULATION_DATA_MATRIZ_E";



