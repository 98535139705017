import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../../api";
import { token } from "../../auth/selectors";
import { getPL, savePL } from "./actions";
import moment from "moment";

function* getPLSaga({ payload }) {
  try {
    yield put(getPL.request());
    const tokenAccess = yield select(token);

    const url = `pricing.promotion.collection/${payload}/get_p_l`;
    const response = yield call(callApiAxios, "GET", url, tokenAccess, {}, {});
    yield put(getPL.success(response.data));
  } catch (e) {
    yield put(
      getPL.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getPL.fulfill());
  }
}
function* savePLSaga({ payload }) {
  try {
    yield put(savePL.request());
    const tokenAccess = yield select(token);

    const url = `pricing.promotion.collection/${payload.id}/save_p_l`;

    const body = { name: payload.name };
    const response = yield call(
      callApiAxios,
      "POST",
      url,
      tokenAccess,
      {},
      body
    );
    yield put(savePL.success(response.data));
  } catch (e) {
    yield put(
      savePL.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(savePL.fulfill());
  }
}

export default function* plSaga() {
  yield takeEvery(getPL.TRIGGER, getPLSaga);
  yield takeEvery(savePL.TRIGGER, savePLSaga);
}
