import React, { useState, useEffect } from "react";
import FooterStep from "../../FooterStep";
import { StepWrapper } from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import TextField from "../../../../Components/TextField";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  createCategory,
  clearResponseCategory,
} from "../../../../Redux/modules/categories/actions";
import { getResponseCategory } from "../../../../Redux/modules/categories/selectors";

const Category = ({ onNext, onBack }) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const [tempCategory, setTempCategory] = useState({
    name: "",
    description: "",
  });
  const response = useSelector(getResponseCategory);

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseCategory());
    }
  }, [response]);

  const isValidForm = () => {
    if (!tempCategory.name) {
      enqueueSnackbar(t("components_globals.error_require_fields"), {
        variant: "error",
      });
      return false;
    }
    return true;
  };

  const onSubmitCategory = () => {
    if (isValidForm()) {
      dispatch(createCategory(tempCategory));
      onNext();
    }
  };

  return (
    <StepWrapper>
      <Stack style={{marginBottom:"1em"}} spacing={2}>
        <TextField
          variant="third"
          required
          label={`${t("initial_setup.forms.common_inputs.name")}`}
          onChange={(e) =>
            setTempCategory({ ...tempCategory, name: e.target.value })
          }
        />
        <TextField
          variant="third"
          label={t("initial_setup.forms.common_inputs.description")}
          onChange={(e) =>
            setTempCategory({ ...tempCategory, description: e.target.value })
          }
        />
      </Stack>
      <FooterStep  onNext={onSubmitCategory} onBack={onBack}></FooterStep>
    </StepWrapper>
  );
};

export default Category;
