import * as type from "./types";
import { initialState } from "./states";
export default function users(state = initialState, action) {
  switch (action.type) {
    case type.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {
          message: "settings_component.change_password_success",
          error: null,
        },
      };
    case type.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        response: { message: null, error: action.error },
      };
    case type.CLEAR_RESPONSE_USERS:
      return {
        ...state,
        response: { message: null, error: null },
      };

    default:
      return state;
  }
}
