import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";

export const TitleCell = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.custom.secondary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    minWidth: "100px",
  },
  [`&.${tableCellClasses.head} .space-cell`]: {
    display: "grid",
    placeContent: "center",
    borderRadius: "10px",
    backgroundColor: theme.palette.custom.primary,
    height: "35px",
    fontSize: "0.8rem",
    marginInline: "auto",
  },
  [`&.${tableCellClasses.head} .first-cell`]: {
    display: "grid",
    placeContent: "center",
    textAlign: "center",
    backgroundColor: theme.palette.custom.background,
    height: "35px",
    fontSize: "0.8rem",
    minWidth: "300px",
    marginInline: "auto",
  },
}));
export const TitleGrayCell = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.custom.secondary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    minWidth: "100px",
  },
  [`&.${tableCellClasses.head} .space-cell`]: {
    display: "grid",
    placeContent: "center",
    borderRadius: "10px",
    textAlign: "center",
    backgroundColor: theme.palette.custom.primary,
    height: "65px",
    fontSize: "3rem",
  },
  [`&.${tableCellClasses.head} .space-gray`]: {
    backgroundColor: "#cdcace",
    display: "grid",
    textAlign: "center",
    placeContent: "center",
    height: "40px",
    borderRadius: "10px",
  },
  [`&.${tableCellClasses.head} .noBorder`]: {
   border:"none"
  },
}));

export const CustomTable = styled(Table)(({ theme }) => ({
  borderCollapse: "separate",
  borderSpacing: "0 5px",
}));

export const StyledTableRowHead = styled(TableRow)(({ theme }) => ({
  background: `${theme.palette.custom.secondary}`,
  color: `${theme.palette.custom.primary}`,
  height: "3.5rem",
}));

export const Wrapper = styled("div")(() => ({
  boxShadow: "0px 3px 6px #00000029 ",
  borderRadius: "10px",
}));

export const ArrowCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.custom.secondary,
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    padding: 0,
    border: "none",
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
  },
  [`&.${tableCellClasses.body} button`]: {
    color: theme.palette.custom.primary,
  },
  [`&.${tableCellClasses.body} .title-row`]: {
    color: theme.palette.custom.primary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    height: "35px",
  },
}));
