import { createAction } from "redux-actions";
import { createRoutine } from "redux-saga-routines";
import { SET_FORM_DATA_TREND,GET_LOAD_TREND, GET_SIMULATE_TREND, CLEAR_SIMULATE_TREND, SET_DEFAULT_FORM_DATA_TREND, CLEAR_RESPONSE_TREND } from "./types";

export const getLoadTrend = createRoutine(GET_LOAD_TREND);

export const getSimulateTrend = createRoutine(GET_SIMULATE_TREND);

export const setFormDataTrend = createAction(SET_FORM_DATA_TREND)

export const clearTrendSimulation = createAction(CLEAR_SIMULATE_TREND)

export const setDefaultFormDataTrend = createAction(SET_DEFAULT_FORM_DATA_TREND);


export const clearResponseTrend = createAction(CLEAR_RESPONSE_TREND);

