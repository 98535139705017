import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { token } from "../auth/selectors";
import {
  CREATE_OWNER_FAILURE,
  CREATE_OWNER_REQUEST,
  CREATE_OWNER_SUCCESS,
  UPDATE_OWNER_FAILURE,
  UPDATE_OWNER_REQUEST,
  UPDATE_OWNER_SUCCESS,
  DELETE_OWNER_FAILURE,
  DELETE_OWNER_REQUEST,
  DELETE_OWNER_SUCCESS,
} from "./types";
import { getLoadOwners } from "./actions";

import {
  UPDATE_OWNER_IN_CATEGORY,
  DELETE_OWNER_OF_CATEGORY,
} from "../categories/types";

import { addOwnerToCategory } from "../categories/actions";

function* createOwner({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const body = {
      name: payload.name,
      description: payload.description,
      color: payload.color,
    };    
    const response = yield call(
      callApiAxios,
      "POST",
      "pricing.sku.owner/",
      tokenAccess,
      {},
      body
    );
    yield put({
      type: CREATE_OWNER_SUCCESS,
      owner: { ...response.data[0], categoryId: payload.categoryId },
    });
    yield put(addOwnerToCategory({ owner: response.data[0], many: false }));
  } catch (e) {
    yield put({
      type: CREATE_OWNER_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* getOwnersSaga() {
  try {
    yield put(getLoadOwners.request());
    const tokenAccess = yield select(token);
    const response = yield call(
      callApiAxios,
      "GET",
      "pricing.sku.owner/",
      tokenAccess,
      {},
      {}
    );
    yield put(getLoadOwners.success({ owners: response.data }));
  } catch (e) {
    yield put(
      getLoadOwners.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getLoadOwners.fulfill());
  }
}

function* updateOwner({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const body = {
      name: payload.name,
      description: payload.description,
      color: payload.color,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      `pricing.sku.owner/${payload.id}`,
      tokenAccess,
      {},
      body
    );
    yield put({
      type: UPDATE_OWNER_SUCCESS,
      payload: response.data[0],
    });
    yield put({
      type: UPDATE_OWNER_IN_CATEGORY,
      payload: { owner: response.data[0], categoryId: payload.categoryId },
    });
  } catch (e) {
    yield put({
      type: UPDATE_OWNER_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* deleteOwner({ payload }) {
  try {
    yield put({
      type: DELETE_OWNER_SUCCESS,
      owner: payload
    });
    yield put({ type: DELETE_OWNER_OF_CATEGORY, owner: payload });
  } catch (e) {
    yield put({
      type: DELETE_OWNER_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

export default function* ownersSaga() {
  yield takeEvery(CREATE_OWNER_REQUEST, createOwner);
  yield takeEvery(getLoadOwners.TRIGGER, getOwnersSaga);
  yield takeEvery(UPDATE_OWNER_REQUEST, updateOwner);
  yield takeEvery(DELETE_OWNER_REQUEST, deleteOwner);
}
