import React from "react";
import Typography from "@mui/material/Typography";
import { StepperDescriptionPaper } from "../../Components/Paper";
import Tree from "../../Containers/Tree";

const HeaderStep = ({ title, subtitle, labelSeeChart }) => {
  return (
    <StepperDescriptionPaper>
      <div className="alert-content">
        <div className="text">
          <Typography
            style={{ marginLeft: "10px", display: "flex", fontWeight: "700" }}
          >
            {title}
          </Typography>
          <Typography
            style={{
              fontWeight: "500",
              marginLeft: "4.4rem",
              display: "flex",
            }}
          >
            {subtitle}
          </Typography>
        </div>
        <div
          style={{
            fontWeight: "600",
            cursor: "pointer",
            paddingRight: "4px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography style={{ margin: "5px" }}>{labelSeeChart}</Typography>
          <Tree></Tree>
        </div>
      </div>
    </StepperDescriptionPaper>
  );
};

export default HeaderStep;
