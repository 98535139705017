import moment from "moment";

export const getDateValues = (date) => {
  const newDate = new Date(date.split("-").join("/"));
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const day = newDate.getDay();

  return { month, year, day, date: newDate };
};

const getRowsPeriodicity = (periodicity, startDateTemp) => {
  const endDate = new Date();
  const startDateUtc = Date.UTC(
    startDateTemp.getFullYear(),
    startDateTemp.getMonth(),
    startDateTemp.getDate()
  );
  const endDateUtc = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );
  let days = 1;
  let months = 1;
  switch (periodicity) {
    case "days":
      days = 1;
      break;
    case "weeks":
      days = 7;

      break;
    case "months":
      months = 1;
      days = 30;
      break;
    case "quarter":
      months = 3;
      days = 30 * months;
      break;
    case "biannual":
      months = 6;
      days = 30 * months;
      break;
    case "years":
      months = 12;
      days = 30 * months;
      break;
    default:
      days = 1;
      break;
  }
  const time = 1000 * 60 * 60 * 24;
  return {
    count: Math.round((endDateUtc - startDateUtc) / (time * days)),
    days: days,
    months: months,
  };
};

export const calculateRows = (periodicity, startDateTemp, language = "es") => {
  const newStartDate = new Date(startDateTemp);
  if (periodicity === "weeks") {
    let day = newStartDate.getDay();
    if (day !== 0 && day < 4) {
      newStartDate.setDate(newStartDate.getDate() - day);
    } else if (day !== 0 && day >= 4) {
      newStartDate.setDate(newStartDate.getDate() + (6 - day));
    }
  }
  const startDate = new Date(newStartDate);
  let rowsData = [];
  const response = getRowsPeriodicity(periodicity, newStartDate);

  for (let i = 0; i < response.count; i++) {
    rowsData.push({
      date: getDateText(periodicity, startDate, language),
      period_index: i + 1,
      value: null,
    });

    if (["days", "weeks"].includes(periodicity)) {
      startDate.setDate(startDate.getDate() + response.days);
    } else {
      startDate.setMonth(startDate.getMonth() + response.months);
    }
  }
  return rowsData;
};
export const parseDateBackend = (dateB) => {
  try {
    const newDate = new Date(dateB.replace("T", " ").split("-").join("/"));
    return newDate;
  } catch (error) {}
};

export const validateFirstPeriod = (firstPeriod, periodWidth) => {
  const startDate = new Date(firstPeriod);
  const response = getRowsPeriodicity(periodWidth, startDate);
  if (response.count > 0) {
    return { result: true };
  } else {
    let days = 0;
    let months = 0;
    switch (periodWidth) {
      case "weeks":
        days = 7;
        break;
      case "months":
        months = 1;
        days = 30;
        break;
    }
  }
};

export const getDefaultFirstPeriod = (periodWidth) => {
  const startDate = new Date();
  let days = 0;
  let months = 0;
  switch (periodWidth) {
    case "weeks":
      days = 7;
      startDate.setDate(startDate.getDate() - days);
      break;
    case "months":
      months = 1;
      days = 30;
      startDate.setDate(1);
      startDate.setDate(startDate.getDate() - days);
      break;
  }

  return startDate;
};

export const getDateText = (period, date, language = "es") => {
  const formatter = new Intl.DateTimeFormat(language, { month: "short" });
  let monthName = formatter.format(date);
  if (["days", "weeks"].includes(period)) {
    return moment(date).format("DD/MM/YYYY");
  }
  if (["years"].includes(period)) {
    return moment(date).format("YYYY");
  }

  return `${monthName[0].toUpperCase()}${monthName.substring(1)}/${moment(
    date
  ).format("YYYY")}`;
};
