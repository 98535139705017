export const initialState = {
  loading: false,
  response: { message: null, error: null },
  colors: {
    primary: "#6FD1B0",
    secondary: "#1F1C36",
    third: "#EBCE75",
    thirdVariant: "#407D68",
    text: "#CDCACE",
    background: "#fff",
    primaryVariant: "#469378",
    primaryVariantLigth: "#46937880",
    secondaryVariant: "#00000029",
    backgroundContrast: "#C4C3C4"    
  },
  language: "es",
  name: "",
  numMaxCategories: 0,
  user: {
    userType:"pricing-user",
    userName: "",
    companyName: "",
    imageLogo: null,
    modules: [],
  },
};
