import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material/";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "../../../../Components/Accordion";
import Button from "../../../../Components/Button";
import { getCategorySelected } from "../../../../Redux/modules/categories/selectors";
import {
  clearResponseOptimalPrices,
  getLoadOptimals,
  getSimulateOptimals
} from "../../../../Redux/modules/what-if/optimal-prices/actions";
import {
  getResponseOptimalPrices, getSimulationCount, getSimulationLoaded
} from "../../../../Redux/modules/what-if/optimal-prices/selectors";
import FormOptimalPrices from "./FormOptimalPrices";
import RadioBtnGroup from "./RadioBtnGroup";
import TableOptimal from "./TableOptimal";
import TableSimulationOptimalsPrice from "./TableSimulationOptimalsPrice";
import TableVariables from "./TableVariables";

const OptimalPrices = () => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const [firshSimulation, setFirshSimulation] = useState(false);
  const [temSimulationOptimals, setTemSimulationOptimals] = useState();
  const simulationOptimals = useSelector(getSimulationLoaded);
  const { enqueueSnackbar } = useSnackbar();
  const categorySelected = useSelector(getCategorySelected);
  const countSimulations = useSelector(getSimulationCount);

  const response = useSelector(getResponseOptimalPrices);

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseOptimalPrices());
    }
  }, [response]);

  const handleClickSimulation = (e) => {
    dispatch(getLoadOptimals.trigger(categorySelected.id));
    dispatch(getSimulateOptimals.trigger(categorySelected.id));
  };

  return (
    <>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
              <Typography fontWeight={600}>
                {t("price_management.curves_component.select_data")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormOptimalPrices />
            </AccordionDetails>
          </Accordion>
        </Grid>
        {simulationOptimals.subcategory_ids?.length > 0 && (
          <>
            <Grid item xs={12} gap={5} display="flex" direction="column">
              <RadioBtnGroup />
              <TableVariables />
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls=""
                >
                  <Typography fontWeight={600}>
                    {t("price_management.optimal_prices.optimal_price_points")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ overflow: "auto" }}>
                  <TableOptimal />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls=""
                >
                  <Typography fontWeight={600}>
                    {t("price_management.optimal_prices.simulator")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ overflow: "auto" }}>
                  <TableSimulationOptimalsPrice />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "1rem",
                    }}
                  >
                    <Button
                      style={{
                        width: "7rem",
                      }}
                      onClick={handleClickSimulation}
                    >
                      {t("price_management.optimal_prices.simulate")}
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default OptimalPrices;
