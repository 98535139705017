import { createAction } from "redux-actions";
import { createRoutine } from "redux-saga-routines";
import {
  SET_FORM_DATA_MATRIZ_E,
  GET_LOAD_MATRIZ_E,
  GET_SIMULATE_MATRIZ_E,
  CLEAR_SIMULATE_MATRIZ_E,
  SET_DEFAULT_FORM_DATA_MATRIZ_E,
  CLEAR_RESPONSE_MATRIZ_E,
  SET_SIMULATION_DATA_MATRIZ_E,
} from "./types";

export const getLoadMatrizE = createRoutine(GET_LOAD_MATRIZ_E);

export const getSimulateMatrizE = createRoutine(GET_SIMULATE_MATRIZ_E);

export const setFormDataMatrizE = createAction(SET_FORM_DATA_MATRIZ_E);
export const setSimulationDataMatrizE = createAction(
  SET_SIMULATION_DATA_MATRIZ_E
);

export const clearMatrizESimulation = createAction(CLEAR_SIMULATE_MATRIZ_E);

export const setDefaultFormDataMatrizE = createAction(SET_DEFAULT_FORM_DATA_MATRIZ_E);

export const clearResponseMatrizE = createAction(CLEAR_RESPONSE_MATRIZ_E);
