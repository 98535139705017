import * as type from "./types";
import { initialState } from "./states";
import produce from "immer";

export const initialSetup = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case type.SET_STEP:
        draft.step = action.payload;
        break;
      case type.SET_STAGE:
        draft.stage = action.payload;
        break;
      case type.SET_STATUS:
        draft.status = action.payload;
        break;
      case type.SELECT_CATEGORY_ID_TO_CREATE_OR_UPDATE:
        draft.status = "entering";
        draft.canCreateCategory = true;
        draft.currentCategoryId = action.payload;
        break;
      case type.CLEAR_CATEGORY_ID_TO_CREATE_OR_UPDATE:
        draft.status = "home";  
        draft.step = 0;
        draft.stage = 0;
        draft.canCreateCategory = false;
        draft.currentCategoryId = 0;        
        break;

        case type.CLOSE_CATEGORY:

        break;
    }
  });
