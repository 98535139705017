import React from "react";
import Button from "../../Components/Button";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

export default function FooterStep({
  onNext,
  onBack,
  textButtonName,
  children,
  notBack = false,
  notNext = false,
}) {
  const [t, i18n] = useTranslation("global");

  return (
    <Stack
      direction="row"
      style={{ paddingBottom: "1em" }}
      justifyContent="flex-end"
      borderBottom={(theme) => `1px solid ${theme.palette.custom.text}`}
    >
        {children && children}
      {!notBack && (
        <Button
          variant="gray"
          style={{ height: "35px",marginLeft: "12px", marginRight: "12px", width: "max-content" }}
          onClick={() => onBack()}
        >
          {t("components_globals.button_back")}
        </Button>
      )}

      {!notNext && (
        <Button
          style={{ height: "35px", marginRight: "12px", width: "max-content" }}
          onClick={() => onNext()}
        >
          {textButtonName
            ? t("components_globals." + textButtonName)
            : t("components_globals.button_next")}
        </Button>
      )}
    
    </Stack>
  );
}
