import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Input from "../../../../Components/Input";
import {
  ArrowCell,
  CustomCell,
  CustomTable,
  CustomCellSticky,
  TitleCellSimulation as TitleCell,
  BlankCell,
  HeadingCellSimulation as HeadingCell,
} from "./StyleTableSimulationSticky";
import { setFormDataOptimalPrices } from "../../../../Redux/modules/what-if/optimal-prices/actions";
import { getOptimalPricesSimulationData } from "../../../../Redux/modules/what-if/optimal-prices/selectors";
import { ArrowCellSimulationSticky, CellPrimary } from "./StyleTable";
import { commaSeparateNumber } from "../../../../Utils";

export const RowCategorySkuSimulation = ({ row, qtyType }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const tempSimulationData = useSelector(getOptimalPricesSimulationData);
  const handleChangeInput = (propertyName, propertyValue, skuId) => {
    dispatch(
      setFormDataOptimalPrices({
        propertyName,
        propertyValue,
        skuId,
      })
    );
  };

  const findSkuSimulation = (type, skuId) => {
    let itemExist = tempSimulationData[type].find(
      (item) => item.code === skuId
    );
    return itemExist;
  };

  const getValue = (type, skuId, value) => {
    let itemExist = findSkuSimulation(type, skuId);
    return itemExist ? itemExist.value : value;
  };

  const onChangeStyle = (type, skuId) => {
    let itemExist = findSkuSimulation(type, skuId);
    return itemExist
      ? { color: "#707070", fontWeight: "bold" }
      : { color: "#1F1C36" };
  };

  const getColorCell = (value) => {
    if (value === 0) {
      return {
        color: "gray",
        fontWeight: "bold",
      };
    } else
      return {
        color: value < 0 ? "red" : "green",
        fontWeight: "bold",
      };
  };

  const buildGroup = (sku, type) => {
    return (
      <>
        <CustomCell>{commaSeparateNumber(sku[type][0].toFixed(2))}</CustomCell>
        <CustomCell
          sx={
            sku[type][0].toFixed(2) !== sku[type][1].toFixed(2) &&
            getColorCell(0)
          }
        >
          {commaSeparateNumber(sku[type][1].toFixed(2))}
        </CustomCell>
        <CustomCell sx={getColorCell(sku[type][2])}>
          {commaSeparateNumber(sku[type][2].toFixed(2))}
        </CustomCell>
      </>
    );
  };

  return (
    <>
      <TableRow
        className="expand-tr"
        sx={{ border: "none", "& > *": { borderBottom: "unset" } }}
      >
        <ArrowCellSimulationSticky colSpan={1}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span className="title-row">{row.name}</span>
        </ArrowCellSimulationSticky>
        <CellPrimary colSpan={6}></CellPrimary>
        {qtyType === "quantity_kg" &&
          row.quantity_kg?.map((num) => (
            <HeadingCell>{commaSeparateNumber(num.toFixed(2))}</HeadingCell>
          ))}
        {qtyType === "quantity" && <HeadingCell colSpan={3}></HeadingCell>}

        {row.value?.map((num) => (
          <HeadingCell>{commaSeparateNumber(num.toFixed(2))}</HeadingCell>
        ))}
        <HeadingCell colSpan={6}></HeadingCell>
      </TableRow>
      <TableRow className="collapse-tr">
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingInline: 0,
            border: "none",
          }}
          colSpan={20}
        >
          <Collapse className="collapse" in={open} timeout="auto" unmountOnExit>
            <Table className="nestedTable">
              <TableBody>
                {row.sku_ids?.map((sku) => (
                  <TableRow
                    key={sku.id}
                    sx={{ "& > *": { borderBottom: "unset" } }}
                  >
                    <CustomCellSticky type="large">{sku.name}</CustomCellSticky>
                    <CustomCell>
                      <Input
                        style={onChangeStyle("prices", sku.id)}
                        label="price1"
                        variant="secondary"
                        name="price1"
                        input
                        onChange={(e) =>
                          handleChangeInput("prices", e.target.value, sku.id)
                        }
                        type="tel"
                        value={commaSeparateNumber(
                          getValue("prices", sku.id, sku.price[0].toFixed(2))
                        )}
                      />
                    </CustomCell>
                    <CustomCell>
                      <Input
                        style={onChangeStyle("increments", sku.id)}
                        label="prorate"
                        variant="secondary"
                        name="prorate"
                        input
                        onChange={(e) =>
                          handleChangeInput(
                            "increments",
                            e.target.value,
                            sku.id
                          )
                        }
                        type="tel"
                        value={commaSeparateNumber(
                          getValue(
                            "increments",
                            sku.id,
                            sku.price[2].toFixed(2)
                          )
                        )}
                      />
                    </CustomCell>
                    <CustomCell
                      sx={
                        sku.price[0].toFixed(2) !== sku.price[1].toFixed(2) &&
                        getColorCell(0)
                      }
                    >
                      {commaSeparateNumber(sku.price[1].toFixed(2))}
                    </CustomCell>
                    <CustomCell>{sku.distribution[0].toFixed(2)}</CustomCell>
                    <CustomCell>
                      <Input
                        style={onChangeStyle("distributions", sku.id)}
                        label="ponderada"
                        variant="secondary"
                        name="ponderada"
                        input
                        onChange={(e) =>
                          handleChangeInput(
                            "distributions",
                            e.target.value,
                            sku.id
                          )
                        }
                        type="tel"
                        value={commaSeparateNumber(
                          getValue(
                            "distributions",
                            sku.id,
                            sku.distribution[1].toFixed(2)
                          )
                        )}
                      />
                    </CustomCell>
                    <CustomCell sx={getColorCell(sku.distribution[2])}>
                      {commaSeparateNumber(sku.distribution[2].toFixed(2))}
                    </CustomCell>
                    {buildGroup(sku, qtyType)}
                    {buildGroup(sku, "value")}
                    {buildGroup(sku, "profit")}
                    {buildGroup(sku, "som")}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
