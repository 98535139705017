import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import { Button, ListItemButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const FloatingMenuNotifications = ({ children })=> {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [t, i18n] = useTranslation("global");

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event) => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List component="nav" aria-label="Device settings">
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          {children}
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        sx={{
          opacity: "0.7",
          "& .MuiPaper-root": {
            backgroundColor: "black",
            color: "white",
            marginTop: "30px",
            padding: "20px",
          },
        }}
      >
        <List
          onClick={(event) => handleMenuItemClick(event)}
          sx={{ width: "100%", maxWidth: 360 }}
        >
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <div>
                  <Typography
                    sx={{
                      color: (theme) => `${theme.palette.custom.background}`,
                    }}
                    component="span"
                    variant="caption"
                  >
                    Ofertas - hace 2 días <br />
                  </Typography>
                  <Typography variant="p" sx={{ color: "white" }}>
                    Planifica tus ventas de forma ágil y segura <br />
                  </Typography>
                  <ListItemButton
                    sx={{
                      marginLeft: "-15px",
                      color: (theme) => `${theme.palette.custom.primary}`,
                      fontWeight: "600",
                    }}
                  >
                    Ver Oferta
                  </ListItemButton>
                </div>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <div>
                  <Typography
                    sx={{ color: "white" }}
                    component="span"
                    variant="caption"
                  >
                    Ofertas - hace 7 días <br />
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      color: (theme) => `${theme.palette.custom.background}`,
                    }}
                  >
                    Habilita nuevas categorías y mejora la gestión de tu negocio
                    <br />
                  </Typography>
                  <ListItemButton
                    sx={{
                      marginLeft: "-15px",
                      color: (theme) => `${theme.palette.custom.primary}`,
                      fontWeight: "600",
                    }}
                  >
                    Ver Oferta
                  </ListItemButton>
                </div>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              secondary={
                <div>
                  <Typography
                    sx={{
                      color: (theme) => `${theme.palette.custom.background}`,
                    }}
                    component="span"
                    variant="caption"
                  >
                    Ofertas - hace 15 días <br />
                  </Typography>
                  <Typography variant="p" sx={{ color: "white" }}>
                    Habilita nuevas categorías y mejora la gestión de tu negocio
                    <br />
                  </Typography>
                  <ListItemButton
                    sx={{
                      marginLeft: "-15px",
                      color: (theme) => `${theme.palette.custom.primary}`,
                      fontWeight: "bold",
                    }}
                  >
                    Ver Oferta
                  </ListItemButton>
                </div>
              }
            />
          </ListItem>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              size="large"
              fullWidth="true"
              sx={{
                backgroundColor: (theme) => `${theme.palette.custom.primary}`,
                color: (theme) => `${theme.palette.custom.secondary}`,
                fontWeight: "bold",
                borderRadius: "100px",
              }}
            >
              {t("drawer_component.seeAll")}
            </Button>
          </div>
        </List>
      </Menu>
    </div>
  );
}

