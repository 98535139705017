import produce from "immer";
import { getPL, savePL } from "./actions";
import { initialState } from "./states";
import * as types from "./types";
export const pl = (state = initialState, { payload, type }) =>
  produce(state, (draft) => {
    switch (type) {
      case getPL.TRIGGER:
        draft.action = payload;
        draft.loading = true;
        break;
      case getPL.REQUEST:
        break;
      case getPL.SUCCESS:
        draft.pl = payload;
        break;
      case getPL.FAILURE:
        draft.response.error = payload.error;
        break;
      case getPL.FULFILL:
        draft.loading = false;
        break;
      case savePL.TRIGGER:
        draft.loading = true;
        break;
      case savePL.REQUEST:
        break;
      case savePL.SUCCESS:
        draft.response = {
          error: null,
          message:
            "sales_planning.promotion_planning_module.pl_component.form.messages.save_success",
          result: null,
        };
        break;
      case savePL.FAILURE:
        draft.response.error = payload.error;
        break;
      case savePL.FULFILL:
        draft.loading = false;
        break;
      case types.CLEAR_RESPONSE_PL:
        draft.response = { error: null, message: null, result: null };
        break;
    }
  });
