import { SvgIcon } from "@mui/material";
import React from "react";

export const EarthIcon = () => {
  const classEarth = {
    fill: "none",
    stroke: "#6FD1B0",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5px",
  };
  return (
    <SvgIcon viewBox="0 0 48 48">
      <g transform="matrix(1.5,0,0,1.5,0,0)">
        <g>
          <circle cx="12" cy="12" r="11.25" style={classEarth} />
          <path
            d="M9.88,23.05c-1.57-2.2-2.63-6.33-2.63-11S8.31,3.15,9.88,1"
            style={classEarth}
          />
          <path
            d="M14.12,23.05c1.57-2.2,2.63-6.33,2.63-11S15.69,3.15,14.12,1"
            style={classEarth}
          />
          <line x1="0.75" y1="12" x2="23.25" y2="12" style={classEarth} />
          <line x1="2.05" y1="17.25" x2="21.95" y2="17.25" style={classEarth} />
          <line x1="2.05" y1="6.75" x2="21.95" y2="6.75" style={classEarth} />
        </g>
      </g>
    </SvgIcon>
  );
};
