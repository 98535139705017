import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";

import { token } from "../auth/selectors";
import {
  getCategoryDataValues,
  getCategoryDataFormData,
} from "./selectors";
import { setDataCategory } from "./actions";
import moment from "moment";
import {
  addValuesToCategory,
  updateCategoryAfterLoadData,
} from "../categories/actions";

function* setCategoryDataSaga({ payload }) {
  try {
    yield put(setDataCategory.request());
    const tokenAccess = yield select(token);
    const dataValues = yield select(getCategoryDataValues);
    const formData = yield select(getCategoryDataFormData);

    if (dataValues && dataValues.length > 0) {
      const newValues = dataValues.map((item) => {
        if (item.subcategory_id) {
          if (item.sales_units && item.sales_price) {
            return {
              subcategory_id: item.subcategory_id,
              period_index: item.period_index,
              sales_units: item.sales_units,
              sales_price: item.sales_price,
            };
          }
        } else {
          if (item.sales_units && item.sales_price && item.distribution) {
            return {
              sku_id: item.sku_id,
              period_index: item.period_index,
              sales_units: item.sales_units,
              sales_price: item.sales_price,
              distribution: item.distribution,
            };
          }
        }
        return null;
      });
      const filterValues = newValues.filter((x) => x != null);
      if (filterValues.length > 0) {
        let dateFirstPeriod = moment(formData.firstPeriod).format("YYYY-MM-DD");
        if (!["days", "weeks"].includes(formData.periodWidth)) {
          dateFirstPeriod = `${moment(formData.firstPeriod).format(
            "YYYY-MM"
          )}-01`;
        }
        const bodyPut = {
          first_period: dateFirstPeriod,
          period_width: formData.periodWidth,
        };
        const responseChange = yield call(
          callApiAxios,
          "PUT",
          `pricing.sku.category/${payload.categoryId}`,
          tokenAccess,
          {},
          bodyPut
        );
        yield put(updateCategoryAfterLoadData(bodyPut));
        const body = {
          values: filterValues,
        };
        const response = yield call(
          callApiAxios,
          "POST",
          `pricing.sku.category/${payload.categoryId}/set_values`,
          tokenAccess,
          {},
          body
        );
        yield put(setDataCategory.success({ data: response }));
        yield put(addValuesToCategory(filterValues));
      } else {
        yield put(
          setDataCategory.failure({
            error: "revisar que todos los datos los datos esten correctos",
          })
        );
      }
    }
  } catch (e) {
    yield put(
      setDataCategory.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(setDataCategory.fulfill());
  }
}

export default function* categoryDataSaga() {
  yield takeEvery(setDataCategory.TRIGGER, setCategoryDataSaga);
}
