import * as type from "./types";
import { getLoadBrands } from "./actions";
import { initialState } from "./states";
import produce from "immer";

export const brands = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case type.CREATE_BRAND_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case type.CREATE_BRAND_SUCCESS:
        draft.brands.push(action.brand);
        draft.loading = false;
        draft.response = {
          message: "initial_setup.messages.create_brand_success",
          error: null,
        };
        break;
      case type.CREATE_BRAND_FAILURE:
        draft.loading = false;
        draft.response.mesage = null;
        draft.response.error = action.error;
        break;
      case getLoadBrands.REQUEST:
        draft.loading = true;
        break;
      case getLoadBrands.SUCCESS:
        draft.loading = false;
        draft.brands = action.payload.brands;
        break;
      case getLoadBrands.FAILURE:
        draft.loading = false;
        draft.brands = [];
        draft.response.mesage = null;
        draft.response.error = action.error;
        break;
      case type.UPDATE_BRAND_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case type.UPDATE_BRAND_SUCCESS:
        let brand = action.brand;
        let tempBrand = draft.brands.find((x) => x.id === brand.id);
        tempBrand.name = brand.name;
        tempBrand.description = brand.description;
        tempBrand.color = brand.color;
        tempBrand.owner_id = brand.owner_id;
        draft.loading = false;
        draft.response = {
          message: "initial_setup.messages.update_brand_success",
          error: null,
        };
        break;

      case type.UPDATE_BRAND_FAILURE:
        return {
          ...state,
          loading: false,
          response: { error: null, message: action.error },
        };
      case type.DELETE_BRAND_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case type.DELETE_BRAND_SUCCESS:
        return {
          ...state,
          loading: false,
          response: {
            message: "initial_setup.messages.delete_brand_success",
            error: null,
          },
        };
      case type.DELETE_BRAND_FAILURE:
        return {
          ...state,
          loading: false,
          response: { error: null, message: action.error },
        };
      case type.CLEAR_RESPONSE_BRAND:
        return {
          ...state,
          response: { error: null, message: null },
        };

      default:
        return state;
    }
  });
