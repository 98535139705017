import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table, { tableClasses } from "@mui/material/Table";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import {
  Accordion,
  AccordionSummary,
  accordionSummaryClasses,
  Box,
  Switch,
} from "@mui/material";

export const Wrapper = styled("div")(() => ({
  boxShadow: "0px 3px 6px #00000029 ",
  borderRadius: "10px",
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.custom.secondary,
  borderRadius: "10px",
  width: "50px",
  color: theme.palette.custom.primary,
  textAlign:"center",
  
}));

export const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.custom.secondary,
  borderRadius: "10px",

  [`.${accordionSummaryClasses.expanded}`]: {
    margin: "5px 0px",
  },
  [`&.${accordionSummaryClasses.expanded}`]: {
    borderRadius: "10px 10px 0px 0px",
  },
}));

export const RoundedButton = styled("div")(({ theme, backgroundColor }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "20px",
  cursor: "pointer",
  padding: "3px",
  stroke: `${theme.palette.custom.secondary}`,
  backgroundColor: `${theme.palette.custom[backgroundColor]}`,
}));

export const CustomBox = styled(Box)(({ theme, backgroundColor }) => ({
  boxShadow: "0px 3px 6px #00000029 ",
  borderRadius: "10px",
  padding: "30px",
  backgroundColor: `${theme.palette.custom[backgroundColor]}`,
}));

export const ButtonsCell = styled(TableCell)(
  ({
    theme,
    backgroundColor = "background",
    minWidth = "150px",
    maxWidth = "150px",
  }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.custom[backgroundColor],
      padding: 0,
      border: "none",
      minWidth: minWidth,
      maxWidth: maxWidth,
      height: "35px",
      left: 0,
      position: "sticky",
      zIndex: 120,
    },
  })
);

export const TitleCell = styled(TableCell)(
  ({
    theme,
    color = "primary",
    type,
    width = "100px",
    minWidth = "50px",
    backgroundColor = "background",
  }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.custom[color],
      border: "none",
      fontWeight: "bold",
      padding: 0,
      width: width,
      minWidth: minWidth,
      backgroundColor: theme.palette.custom[backgroundColor],
      textAlign: "center",
    },
    [`&.${tableCellClasses.head} .space-cell`]: {
      display: "grid",
      placeContent: "center",
      borderRadius: "10px",
      backgroundColor: theme.palette.custom.primary,
      color: theme.palette.custom.secondary,
      height: "30px",
      fontSize: "0.6rem",
      marginInline: "auto",
    },

    [`&.${tableCellClasses.head} .space-gray`]: {
      display: "grid",
      placeContent: "center",
      borderRadius: "10px",
      backgroundColor: theme.palette.custom.text,
      color: theme.palette.custom.secondary,
      fontSize: "0.6rem",
      marginInline: "auto",
    },
    [`&.${tableCellClasses.head} .space-white`]: {
      backgroundColor: theme.palette.custom.background,
      height: "30px",
    },
    [`&.${tableCellClasses.head} .first-cell`]: {
      display: "grid",
      placeContent: "center",
      textAlign: "center",
      backgroundColor: theme.palette.custom.background,
      height: "30px",
      fontSize: "0.8rem",
      minWidth: "300px",
      marginInline: "auto",
    },
  })
);
export const TitleGrayCell = styled(TableCell)(({ theme, type }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.custom.secondary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    minWidth: "100px",
  },
  [`&.${tableCellClasses.head} .space-cell`]: {
    display: "grid",
    placeContent: "center",
    borderRadius: "10px",
    textAlign: "center",
    backgroundColor: theme.palette.custom.primary,
    height: "30px",
    fontSize: "3rem",
  },
  [`&.${tableCellClasses.head} .space-gray`]: {
    backgroundColor: "#cdcace",
    display: "grid",
    textAlign: "center",
    placeContent: "center",
    height: "30px",
    borderRadius: "10px",
  },
  [`&.${tableCellClasses.head} .noBorder`]: {
    border: "none",
  },
}));

export const CustomTable = styled(Table)(({ theme }) => ({
  borderCollapse: "separate",
  borderSpacing: "0px  1px",

  [`&.${tableClasses.root}`]: {
    [`td`]: {
      padding: "0px",
      margin: "0px",
    },
    [`th`]: {
      padding: "0px",
      margin: "0px",
    },
    [`td.xs`]: {
      width: "30px",
    },
    [`tr`]: {
      padding: "0px",
      margin: "0px",
    },
    [`tr td:first-child`]: {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    [`td.border-radius-right`]: {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
    [`td.border-radius-left`]: {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    [`tr td:last-child`]: {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
    [`td span.border-radius-right`]: {
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
    },
    [`td span.border-radius-left`]: {
      borderTopLeftRadius: "5px",
      borderBottomLeftRadius: "5px",
    },
  },
}));

export const CustomSwitch = styled(Switch)(
  ({ theme, optionTrue, optionFalse }) => ({
    width: 100,
    height: 30,
    padding: 0,
    borderRadius: 30,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 60,
        height: 25,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        color: theme.palette.custom.thirdVariant,
        transform: "translateX(45px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 0,
      "&.Mui-checked": {
        transform: "translateX(40px)",
        color: theme.palette.custom.thirdVariant,
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.custom.text,
        },
        "& .MuiSwitch-thumb": {
          "&:after": {
            content: `"${optionFalse}"`,
          },
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 60,
      height: 40,
      borderRadius: 30,
      color: theme.palette.custom.thirdVariant,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
      "&:after": {
        position: "absolute",
        top: "5px",
        left: "20px",
        width: 20,
        height: 20,
        color: "#fff",
        content: `"${optionTrue}"`,
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 30,
      opacity: 1,
      backgroundColor: theme.palette.custom.text,
      boxSizing: "border-box",

      "&:before, &:after": {
        position: "absolute",
        width: 40,
        height: 20,
        color: "#fff",
      },
      "&:after": {
        left: "40px",

        content: `"${optionTrue}"`,
      },
      "&:before": {
        top: "5px",
        right: "50px",

        content: `"${optionFalse}"`,
      },
    },
  })
);

export const StyledTableRowHead = styled(TableRow)(({ theme }) => ({
  background: `${theme.palette.custom.secondary}`,
  color: `${theme.palette.custom.primary}`,
  height: "3.5rem",
}));

export const ArrowCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.custom.secondary,
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    padding: 0,
    border: "none",
    borderBottomRightRadius: "10px",
    borderTopRightRadius: "10px",
  },
  [`&.${tableCellClasses.body} button`]: {
    color: theme.palette.custom.primary,
  },
  [`&.${tableCellClasses.body} .title-row`]: {
    color: theme.palette.custom.primary,
    border: "none",
    fontWeight: "bold",
    padding: 0,
    height: "35px",
  },
}));

///

export const CustomCell = styled(TableCell)(
  ({
    theme,
    type,
    minWidth = "100px",
    maxWidth = "100px",
    maxHeight = "30px",
    height = "30px",
    backgroundColor = "background",
  }) => ({
    [`&.${tableCellClasses.body}`]: {
      minWidth: minWidth,
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      border: "none",
      padding: 0,
      height: height,
      paddingBlock: "6px",
      paddingLeft: type === "large" ? "5px" : "",
      textAlign: type === "large" ? "left" : "center",
      fontWeight: type === "large" ? "bold" : "",
      backgroundColor: `${theme.palette.custom[backgroundColor]}`,
    },
  })
);

export const CustomCellSticky = styled(TableCell)(
  ({
    theme,
    type,
    backgroundColor = "background",
    minWidth = "300px",
    maxWidth = "100px",
    isEllipsis = false,
  }) => ({
    [`&.${tableCellClasses.body}`]: {
      minWidth: minWidth,
      maxWidth: maxWidth,
      border: "none",
      padding: 0,
      paddingBlock: "6px",
      paddingLeft: type === "large" ? "5px" : "",
      textAlign: type === "large" ? "left" : "center",
      fontWeight: type === "large" ? "bold" : "",
      left: 0,
      position: "sticky",
      backgroundColor: `${theme.palette.custom[backgroundColor]}`,
      whiteSpace: isEllipsis ? "normal" : "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

export const ColorCell = styled(TableCell)(
  ({
    theme,

    backgroundColor,
    position,
    fontWeight,
    height = "40px",
    fontSize = "0.8rem",
    textAlign = "center",
  }) => ({
    [`&.${tableCellClasses.body}`]: {
      border: "none",
      fontSize: fontSize,
      textAlign: textAlign,
      height: height,
      minWidth: "60px",
      fontWeight: fontWeight ? fontWeight : "",
      background: theme.palette.custom[backgroundColor],
    },
  })
);

export const CustomTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "transparent",
}));
