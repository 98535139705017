export const promotionsState = (state) => state.promotions;

export const getLoadingPromotions = (state) => promotionsState(state).loading;

export const getFormDataPromotions = (state) => promotionsState(state).formData;
export const getSimulationDataPromotions = (state) =>
  promotionsState(state).simulationData;
export const getSimulationDataEnvPromotions = (state) =>
  promotionsState(state).simulationDataEnv;
export const getSimulationPromotionsLoaded = (state) =>
  promotionsState(state).simulation;

export const getPromotionsSubcategoryId = (state) =>
  promotionsState(state).formData.subcategoryId;

export const getPromotionsLoaded = (state) => promotionsState(state).promotions;
export const getPromotionsData = (state, promotion) => {
  const data = promotionsState(state).promotionsData;

  const customers = data.customers.filter((x) =>
    promotion.filters.customers.includes(x.id)
  );
  const skus = data.skus.filter((x) => promotion.filters.skus.includes(x.id));

  return { customers, skus };
};

export const getPromotionsSimulationData = (state) =>
  promotionsState(state).simulationData;
export const getEnvironmentVariables = (state) =>
  promotionsState(state).promotionsData.vars;

export const getPromotionsFunctionType = (state) =>
  promotionsState(state).formData.typePrice;

export const getVariablesSelected = (state) =>
  promotionsState(state).formData.variablesSelected;

export const getSkuSelected = (state) =>
  promotionsState(state).promotions.subcategory_ids[0].sku_ids.find(
    (sku) => sku.id === promotionsState(state).formData.skuId
  );

export const getResponsePromotions = (state) => promotionsState(state).response;

export const getSkuGraph = (state) =>
  promotionsState(state).promotions.subcategory_ids[0].sku_ids.find(
    (sku) => sku.id === promotionsState(state).formData.skuIdGraph
  );

export const getSelectedSkus = (state) => {
  const currentPromotions = promotionsState(state).promotions;
  let filters = promotionsState(state).formData.skuIds;
  const subcategory =
    currentPromotions?.subcategory_ids && currentPromotions?.subcategory_ids[0];
  const skus = subcategory?.sku_ids.filter((sku) => filters.includes(sku.id));
  return skus;
};
export const getColumnsTablePromotions = (state) => {
  const currentPromotions = promotionsState(state).promotions;
  const subcategory =
    currentPromotions?.subcategory_ids && currentPromotions?.subcategory_ids[0];
  const skus = subcategory?.sku_ids;
  const cols = skus.length > 0 ? skus[0].table : [];
  return cols;
};
