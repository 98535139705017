import { ExpandMore } from "@mui/icons-material";
import { Avatar, TableBody } from "@mui/material/";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../Components/Input";
import { getPromotionsData } from "../../../../Redux/modules/promotion-planning/promotions/selectors";
import { setSimulationDataPromotions } from "../../../../Redux/modules/promotion-planning/promotions/actions";
import { commaSeparateNumber, getMonthName } from "../../../../Utils";
import { getDateValues } from "../../../../Utils/commonWithDates";
import {
  ColorCell,
  CustomCellSticky,
  CustomTable,
  TitleCell,
  TitleCellSticky,
} from "./Styles";

export default function TablePromotion({
  promotion,
  isExpandedAllMonth,
  expandedMonths,
  setExpandedMonths,
  showDetails,
  setShowDetails,
  simulationData,
  setSimulationData,
}) {
  // const table = useRef(null);
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");

  const promotionsData = useSelector((state) =>
    getPromotionsData(state, promotion)
  );
  const colSpan = 0;
  const columns = 0;

  const updateChanges = (
    promotionId,
    customerId,
    skuId,
    newValue,
    type,
    day,
    index
  ) => {
    const replaceAll = index === 1 && type === "promo";
    const isMainValue =
      (index === 2 && type === "promo") || (index === 1 && type === "base");

    let skuExist = simulationData[type].find(
      (x) => x.code === `${promotionId}-${customerId}-${skuId}`
    );
    if (skuExist) {
      if (replaceAll) {
        skuExist.items = [];
        skuExist.value = newValue;
      }
      let objExist = skuExist.items.find((x) => x.day === day);
      if (objExist) {
        objExist.value = newValue;
      } else {
        skuExist.items.push({
          day,
          value: newValue,
          simulate: isMainValue,
        });
      }
      if (isMainValue) {
        skuExist.value = newValue;
      }
      let list =
        simulationData &&
        simulationData[type].filter(
          (x) => x.code !== `${promotionId}-${customerId}-${skuId}`
        );
      const newList = list.concat([skuExist]);
      setSimulationData({
        ...simulationData,
        [type]: newList,
      });
    } else {
      const newPromotion = {
        promotionId,
        customerId,
        skuId,
        code: `${promotionId}-${customerId}-${skuId}`,
        items: [
          {
            day,
            value: newValue,
            simulate: isMainValue,
          },
        ],
      };
      const newList = simulationData[type].concat([newPromotion]);
      setSimulationData({
        ...simulationData,
        [type]: newList,
      });
    }
  };

  const handleChangeInput = (
    promotionId,
    customerId,
    skuId,
    newValue,
    type,
    day,
    index
  ) => {
    updateChanges(promotionId, customerId, skuId, newValue, type, day, index);
  };

  const findValueInSimulationData = (
    promotionId,
    customerId,
    skuId,
    type,
    day,
    index
  ) => {
    let skuExist =
      simulationData[type] &&
      simulationData[type].find(
        (item) =>
          item.promotionId === promotionId &&
          item.customerId === customerId &&
          item.skuId === skuId
      );
    if (skuExist) {
      let objExist = skuExist.items.find((x) => x.day === day);
      if (objExist) {
        return objExist;
      }
      return skuExist;
    }
    return null;
  };

  const getValue = (
    promotionId,
    customerId,
    skuId,
    type,
    day,
    currentValue,
    index
  ) => {
    const data = findValueInSimulationData(
      promotionId,
      customerId,
      skuId,
      type,
      day,
      index
    );
    return data
      ? data.value
      : commaSeparateNumber(`${currentValue ? currentValue.toFixed(2) : 0}`);
  };

  const isExpandedMonth = (id) => {
    return expandedMonths.includes(id);
  };
  const handleChangeExpandedMonths = (id) => {
    if (isExpandedMonth(id)) {
      const newArray = expandedMonths.filter((x) => x !== id);
      setExpandedMonths(newArray);
    } else {
      const newArray = expandedMonths.concat([id]);
      setExpandedMonths(newArray);
    }
  };

  const buildDataCols = useCallback(({ starDate, endDate }) => {
    let currentDate = new Date(`${starDate} 00:00:00`);
    let lastDate = new Date(`${endDate} 23:59:00`);
    const fechaInicio = new Date(
      moment(currentDate).subtract(1, "days").format()
    );
    const fechaFin = new Date(moment(lastDate).add(1, "days").format());
    const days = moment(fechaFin).diff(moment(fechaInicio), "days");
    const months = moment(fechaFin).diff(moment(fechaInicio), "months");
    const monthsBuilder = [];
    for (let index = 0; index <= days; index++) {
      const formattedDate = moment(fechaInicio)
        .add(index, "days")
        .format()
        .substring(0, 10);
      const dateData = getDateValues(formattedDate);
      let monthExist = monthsBuilder.find(
        (x) => x.month === dateData.month && x.year === dateData.year
      );
      if (!monthExist) {
        monthsBuilder.push({
          year: dateData.year,
          month: dateData.month,
          date: formattedDate,
          dates: [{ formattedDate, indexDay: index + 1 }],
        });
      } else {
        monthExist.dates.push({ formattedDate, indexDay: index + 1 });
      }
    }
    return { days, monthsBuilder };
  }, []);

  const { days, monthsBuilder } = buildDataCols({
    starDate: promotion.start_date,
    endDate: promotion.end_date,
  });

  const buildCols = (type, customerId, skuId, isBase) => {
    if (type === "months") {
      if (monthsBuilder.length > 0) {
        return monthsBuilder.map((item, index) => {
          const dateData = getDateValues(item.date);
          const isExpanded =
            isExpandedMonth(`${item.year}-${item.month}`) || isExpandedAllMonth;
          return (
            <TitleCell
              className="xs"
              colSpan={isExpanded ? item.dates.length : 1}
              minWidth="90px"
              key={`th-${item.year}-${item.month}`}
            >
              <span
                style={{ fontSize: "0.7rem" }}
                className="space-cell"
                onClick={() =>
                  handleChangeExpandedMonths(`${item.year}-${item.month}`)
                }
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="th-title">
                    {`${t(`months_names.${getMonthName(item.month - 1)}`)
                      .substring(0, 3)
                      .toUpperCase()}-${dateData.year
                      .toString()
                      .substring(2, 4)}`}
                  </span>
                  <ExpandMore
                    sx={{
                      fontSize: "1rem",
                      rotate: isExpanded ? "90deg" : "-90deg",
                    }}
                  />
                </div>
              </span>
            </TitleCell>
          );
        });
      }
    } else if (type === "days") {
      if (days > 0) {
        return monthsBuilder.map((x, indexMonth) => {
          if (isExpandedMonth(`${x.year}-${x.month}`) || isExpandedAllMonth) {
            return x.dates.map((day, index) => {
              const dateData = getDateValues(day.formattedDate);
              const formattedDay = moment(dateData.date).format("DD");
              return (
                <TitleCell
                  className="xs"
                  minWidth="40px"
                  key={`th-${day.indexDay}`}
                >
                  <span style={{ fontSize: "0.7rem" }} className="space-gray">
                    {formattedDay}
                  </span>
                </TitleCell>
              );
            });
          } else {
            return (
              <TitleCell className="xs" key={`th-${indexMonth}`}>
                <span
                  style={{ fontSize: "0.7rem" }}
                  className="space-white"
                ></span>
              </TitleCell>
            );
          }
        });
      }
    } else {
      if (days > 0) {
        return monthsBuilder.map((x, indexMonth) => {
          if (isExpandedMonth(`${x.year}-${x.month}`) || isExpandedAllMonth) {
            return x.dates.map((day, index) => {
              if (day.indexDay === 1 || (day.indexDay === 2 && !isBase)) {
                return (
                  <ColorCell
                    className="xs"
                    backgroundColor={isBase ? "text" : "background"}
                    key={`td-${promotion.id}-${customerId}-${skuId}-${
                      isBase ? "base" : "promo"
                    }-${day.indexDay}`}
                  >
                    <Input
                      variant="secondary"
                      style={{ width: "60px" }}
                      name={`input-${promotion.id}-${customerId}-${skuId}-${
                        isBase ? "base" : "promo"
                      }-${day.indexDay}`}
                      input
                      onChange={(e) =>
                        handleChangeInput(
                          promotion.id,
                          customerId,
                          skuId,
                          e.target.value,
                          isBase ? "base" : "promo",
                          day.formattedDate,
                          day.indexDay
                        )
                      }
                      type="tel"
                      value={getValue(
                        promotion.id,
                        customerId,
                        skuId,
                        isBase ? "base" : "promo",
                        day.formattedDate,
                        promotion.customers[customerId][skuId],
                        day.indexDay
                      )}
                    />
                  </ColorCell>
                );
              } else {
                return (
                  <ColorCell
                    className="xs"
                    backgroundColor={isBase ? "text" : "background"}
                    height="30px"
                    key={`td-${promotion.id}-${customerId}-${skuId}-${
                      isBase ? "base" : "promo"
                    }-${day.indexDay}`}
                  >
                    <span>
                      {getValue(
                        promotion.id,
                        customerId,
                        skuId,
                        isBase ? "base" : "promo",
                        days + 1 === day.indexDay
                          ? monthsBuilder[0].dates[0].formattedDate
                          : day,
                        promotion.customers[customerId][skuId],
                        day.indexDay
                      )}
                    </span>
                  </ColorCell>
                );
              }
            });
          } else {
            return (
              <ColorCell
                className="xs"
                backgroundColor={isBase ? "text" : "background"}
                height="30px"
                key={`td-${promotion.id}-${customerId}-${skuId}-${
                  isBase ? "base" : "promo"
                }-${x.year}-${x.month}`}
              >
                <span>
                  {getValue(
                    promotion.id,
                    customerId,
                    skuId,
                    isBase ? "base" : "promo",
                    x.month,
                    promotion.customers[customerId][skuId],
                    0
                  )}
                </span>
              </ColorCell>
            );
          }
        });
      }
    }
  };

  const buildRows = (customers, skus) => {
    let isFirstTh = false;

    const rowsBase = ["Base", "Forecast"];
    const rowSpanCustomer = skus.length * rowsBase.length;
    let templateRows = customers.map((customer, index) => {
      return skus.map((sku, index2) => {
        // return rowsBase.map((base, indexB) => {
        isFirstTh = index2 === 0;
        return (
          <>
            <TableRow
              className={`tablePromotion_${promotion.id}_${customer.id}`}
              key={`${promotion.id}-A`}
            >
              {isFirstTh && (
                <CustomCellSticky
                  className="customerImage"
                  minWidth="150px"
                  width="150px"
                  customerName={customer.name}
                  rowSpan={rowSpanCustomer}
                >
                  <div
                    style={{
                      display: "grid",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {customer.image ? (
                      <img src={"data:image/png;base64," + customer.image} />
                    ) : (
                      <>
                        <Avatar
                          sx={{
                            borderRadius: "10px",
                            width: 120,
                            height: 40,
                            bgcolor: "rgb(205, 202, 206)",
                          }}
                          className="client-avatar"
                        >
                          {customer.name.toUpperCase().charAt(0)}
                        </Avatar>
                      </>
                    )}
                  </div>
                </CustomCellSticky>
              )}
              {isFirstTh && showDetails && (
                <TitleCell rowSpan={rowSpanCustomer}>{customer.name}</TitleCell>
              )}
              <CustomCellSticky minWidth="150px" rowSpan={2}>
                {`${sku.name}`}
              </CustomCellSticky>
              {showDetails && (
                <TitleCell rowSpan={2}>{sku.internal_code}</TitleCell>
              )}
              {showDetails && (
                <TitleCell rowSpan={2}>{sku.subcategory_id}</TitleCell>
              )}
              {showDetails && <TitleCell rowSpan={2}>{sku.package}</TitleCell>}
              <ColorCell className="border-radius-left" backgroundColor="text">
                <span>BASE</span>
              </ColorCell>
              {buildCols("values", customer.id, sku.id, true)}
            </TableRow>
            <TableRow key={`${index}-B`}>
              <ColorCell backgroundColor="background">
                {" "}
                <span>PROMO</span>
              </ColorCell>
              {buildCols("values", customer.id, sku.id, false)}
            </TableRow>
          </>
        );
      });
    });
    // });
    return templateRows;
  };

  return (
    promotion && (
      <TableContainer sx={{ maxHeight: 400 }}>
        <CustomTable
          className={`tablePromotion tablePromotion_${promotion.id}`}
          key={`tablePromotion_${promotion.id}`}
        >
          <TableHead
            style={{
              zIndex: 100,
              position: "sticky",
              top: 0,
              backgroundColor: "#fff",
            }}
          >
            <TableRow
              style={{
                marginTop: "20px",
                zIndex: 100,
                backgroundColor: "#fff",
              }}
            >
              <TitleCellSticky minWidth="150px" width="150px" rowSpan={2}>
                <span style={{ fontSize: "0.7rem" }} className="space-cell top">
                  {t(
                    "sales_planning.promotion_planning_module.promotions_component.table.customer"
                  )}
                </span>
              </TitleCellSticky>
              {showDetails && (
                <TitleCell rowSpan={2}>
                  <span
                    style={{ fontSize: "0.7rem" }}
                    className="space-cell top"
                  >
                    {t(
                      "sales_planning.promotion_planning_module.promotions_component.table.name"
                    )}
                  </span>
                </TitleCell>
              )}
              <TitleCellSticky minWidth="250px" width="250px" rowSpan={2}>
                <span
                  onClick={() => setShowDetails(!showDetails)}
                  style={{ fontSize: "0.7rem", cursor: "pointer" }}
                  className="space-cell top"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      {" "}
                      {t(
                        "sales_planning.promotion_planning_module.promotions_component.table.sku"
                      )}
                    </span>
                    <ExpandMore
                      sx={{
                        fontSize: "1rem",
                        rotate: showDetails ? "90deg" : "-90deg",
                      }}
                    />
                  </div>
                </span>
              </TitleCellSticky>
              {showDetails && (
                <TitleCell rowSpan={2}>
                  <span style={{ fontSize: "0.7rem" }} className="space-cell">
                    {t(
                      "sales_planning.promotion_planning_module.promotions_component.table.ci"
                    )}
                  </span>
                </TitleCell>
              )}
              {showDetails && (
                <TitleCell rowSpan={2}>
                  <span style={{ fontSize: "0.7rem" }} className="space-cell">
                    {t(
                      "sales_planning.promotion_planning_module.promotions_component.table.subcategory"
                    )}
                  </span>
                </TitleCell>
              )}
              {showDetails && (
                <TitleCell rowSpan={2}>
                  <span style={{ fontSize: "0.7rem" }} className="space-cell">
                    {t(
                      "sales_planning.promotion_planning_module.promotions_component.table.package"
                    )}
                  </span>
                </TitleCell>
              )}
              <TitleCell rowSpan={2}></TitleCell>
              {buildCols("months")}
            </TableRow>
            <TableRow
              style={{
                backgroundColor: "#fff",
              }}
            >
              {buildCols("days")}
            </TableRow>
          </TableHead>
          <TableBody>
            {promotionsData.customers.length > 0 &&
              buildRows(promotionsData.customers, promotionsData.skus)}
          </TableBody>
        </CustomTable>
      </TableContainer>
    )
  );
}
