export const scopeValues = [
  {
    value: "env",
    label: "around",
  },
  {
    value: "pricing.sku.category",
    label: "category",
  },
  {
    value: "pricing.sku.subcategory",
    label: "subcategory",
  },
  {
    value: "pricing.sku.brand",
    label: "brand",
  },
  {
    value: "pricing.sku.owner",
    label: "owner",
  },
  {
    value: "pricing.sku",
    label: "SKU",
  },
];

export const periodWidthValues = [
  {
    code: 0,
    value: "days",
    label: "daily",
  },
  {
    code: 2,
    value: "weeks",
    label: "weekly",
  },
  {
    code: 1,
    value: "months",
    label: "monthly",
  },
  {
    code: 3,
    value: "quarter",
    label: "quarterly",
  },
  {
    code: 4,
    value: "biannual",
    label: "biannual",
  },
  {
    code: 5,
    value: "years",
    label: "annual",
  },
];

export const completionRuleValues = [
  {
    value: "mode",
    label: "mode",
  },
  {
    value: "repeat",
    label: "repeat",
  },
  {
    value: "apportionment",
    label: "apportionment",
  },
  {
    value: "mean",
    label: "mean",
  },
  {
    value: "median",
    label: "median",
  },
  {
    value: "sum",
    label: "sum",
  },
];
