import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import TextField from "../../../../Components/TextField";
import Button from "../../../../Components/Button";
import { SaveIcon } from "../../../../Components/CustomIcons";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import FormControl from "../../../../Components/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Chip from "@mui/material/Chip";
import ButtonUpload from "../../../../Components/Button/ButtonUpload";
import { getBase64 } from "../../../../Utils";
const DivImageSkuWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .new-logo {
    width: 100px;
    height: 100px;
    border: 2px dashed #000;
    border-radius: 20%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .empty-image {
    margin-right: 20px;
    width: 100px;
    height: 100px;
    border: 2px dashed #000;
    border-radius: 20%;
  }
`;

const FormSku = ({
  sku,
  onSkuCreated,
  onSkuUpdated,
  setCurrentSku,
  subcategories = [],
  brands = [],
  units = [],
}) => {
  const [t, i18n] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const [tempSku, setTempSku] = useState(
    sku
      ? {
          id: sku.id,
          name: sku.name,
          internalCode: sku.internal_code,
          unitWeight: sku.unit_weight,
          uomId: sku.uom_id ? sku.uom_id[0] : null,
          package: sku.package,
          cost: sku.cost,
          brandId: sku.brandId ? sku.brandId : sku.brand_id[0],
          image: sku.image ? sku.image : null,
          selectedBrands: [],
          subcategoryId: sku.subcategoryId
            ? sku.subcategoryId
            : sku.subcategory_id[0],
        }
      : {
          id: 0,
          name: "",
          internalCode: "",
          content: "",
          unitWeight: "",
          uomId: "",
          package: "",
          cost: 0,
          brandId: null,
          image: "",
          selectedBrands: [],
          subcategoryId: null,
        }
  );

  const isNew = sku ? false : true;

  const isValidForm = () => {
    if (!tempSku.name) {
      enqueueSnackbar("Error", { variant: "error" });
      return false;
    }
    return true;
  };

  const handleInputChanges = (newValue, type) => {
    setTempSku({ ...tempSku, [type]: newValue });
    if (!isNew) {
      setCurrentSku({ ...tempSku, [type]: newValue });
    }
  };

  const handleBrandsSelected = (e) => {
    const selectedItems = e.target.value;
    setTempSku({
      ...tempSku,
      selectedBrands: selectedItems,
    });

    if (!isNew) {
      setCurrentSku({
        ...tempSku,
        selectedBrands: selectedItems,
      });
    }
  };

  const handleImageSkuChange = async (e) => {
    let files = e.target.files;
    const file = files[0];
    const newImage = await beforeImageUpload(file).then();
    if (newImage) {
      setTempSku({
        ...tempSku,
        image: newImage,
      });
      if (!isNew) {
        setCurrentSku({
          ...tempSku,
          image: newImage,
        });
      }
    }
  };

  const beforeImageUpload = async (file) => {
    return new Promise((resolve, reject) => {
      const isJPG = file.type === "image/jpeg";
      const isPng = file.type === "image/png";

      if (!isJPG) {
        if (!isPng) {
          return false;
        }
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return false;
      }
      getBase64(file, (img) => {
        if (img) {
          resolve(img);
        }
      });
    });
  };

  const getSelectedBrandStyle = (currentId, theme) => {
    const selectedStyle = {
      fontWeight: 600,
      backgroundColor: theme.palette.custom.text,
      "&:hover": {
        fontWeight: 600,
      },
    };
    const notSelectedStyle = {
      fontWeight: 400,
      backgroundColor: theme.palette.custom.background,
    };

    return tempSku.selectedBrands.find((id) => id === currentId)
      ? selectedStyle
      : notSelectedStyle;
  };

  const onSubmitSku = () => {
    if (isValidForm()) {
      isNew
        ? onSkuCreated({
            ...tempSku,
          })
        : onSkuUpdated(tempSku);
      onReset();
    }
  };

  const onReset = () => {
    setTempSku({
      id: 0,
      name: "",
      internalCode: "",
      unitWeight: "",
      uomId: null,
      package: "",
      cost: 0,
      image: null,
      brandId: null,
      selectedBrands: [],
      subcategoryId: null,
    });
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(2, 1fr)`,
        alignItems: "center",
        justifyContent: "center",
        gap: "0.5rem",
      }}
    >
      <TextField
        fullWidth
        required
        variant="third"
        label={`${t("initial_setup.step_3.stage_1.form_name")} ${t(
          "initial_setup.forms.common_inputs.name"
        )}`}
        value={tempSku?.name}
        onChange={(e) => {
          handleInputChanges(e.target.value, "name");
        }}
      />
      <TextField
        variant="third"
        label={t("initial_setup.forms.common_inputs.internal_code")}
        type="text"
        value={tempSku?.internalCode}
        onChange={(e) => handleInputChanges(e.target.value, "internalCode")}
      />

      <TextField
        fullWidth
        variant="third"
        label={t("initial_setup.forms.common_inputs.content")}
        type="number"
        value={tempSku?.unitWeight}
        onChange={(e) => handleInputChanges(e.target.value, "unitWeight")}
      />
      <TextField
        fullWidth
        select
        variant="third"
        label={t("initial_setup.forms.common_inputs.unit")}
        value={tempSku?.uomId}
        onChange={(e) => handleInputChanges(e.target.value, "uomId")}
      >
        {units?.map((option, index) => {
          return (
            <MenuItem key={index} value={option.id}>
              {option.name}
            </MenuItem>
          );
        })}
      </TextField>
      <TextField
        variant="third"
        label={t("initial_setup.forms.common_inputs.package")}
        type="text"
        required
        value={tempSku?.package}
        onChange={(e) => handleInputChanges(e.target.value, "package")}
      />
      <TextField
        variant="third"
        label={t("initial_setup.forms.common_inputs.cost")}
        type="number"
        required
        value={tempSku?.cost}
        onChange={(e) => handleInputChanges(e.target.value, "cost")}
      />
      <TextField
        fullWidth
        select
        variant="third"
        label={t("initial_setup.forms.common_inputs.subcategory")}
        value={tempSku?.subcategoryId}
        onChange={(e) => {
          handleInputChanges(e.target.value, "subcategoryId");
        }}
      >
        {subcategories?.map((option, index) => {
          return (
            <MenuItem key={index} value={option.id}>
              {option.name}
            </MenuItem>
          );
        })}
      </TextField>
      {isNew ? (
        <FormControl variant="secondary">
          <InputLabel>
            {t("initial_setup.forms.common_inputs.brands")}
          </InputLabel>
          <Select
            label={t("initial_setup.forms.common_inputs.brands")}
            required
            multiple
            value={tempSku?.selectedBrands ? tempSku.selectedBrands : []}
            onChange={handleBrandsSelected}
            renderValue={(currentIds) => {
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {currentIds.map((id, index) => (
                    <Chip
                      key={index}
                      label={brands.find((x) => x.id === id)?.name}
                    />
                  ))}
                </Box>
              );
            }}
          >
            {brands?.map((option, index) => {
              return (
                <MenuItem
                  sx={(theme) => getSelectedBrandStyle(option.id, theme)}
                  key={index}
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <TextField
          fullWidth
          select
          variant="third"
          label={t("initial_setup.forms.common_inputs.brands")}
          value={tempSku?.brandId}
          onChange={(e) => handleInputChanges(e.target.value, "brandId")}
        >
          {brands?.map((option, index) => {
            return (
              <MenuItem key={index} value={option.id}>
                {option.name}
              </MenuItem>
            );
          })}
        </TextField>
      )}
      <DivImageSkuWrapper>
        {tempSku?.selectedBrands?.length < 2 && (
          <Box
            display="flex"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {tempSku?.image ? (
              <img
                className="new-logo"
                src={`${
                  tempSku.image.indexOf("base64") !== -1
                    ? tempSku.image
                    : `data:image/png;base64,${tempSku.image}`
                }`}
              ></img>
            ) : (
              <div className="empty-image"></div>
            )}

            <ButtonUpload
              title={"Subir Imagen"}
              onChange={handleImageSkuChange}
            />
          </Box>
        )}
      </DivImageSkuWrapper>

      {isNew && (
        <div style={{ display: "flex", justifyContent: "start" }}>
          <Button
            fullWidth
            style={{ maxWidth: "150px" }}
            variant="thirdVariant"
            onClick={onSubmitSku}
            disabled={
              !tempSku.name ||
              !tempSku.internalCode ||
              !tempSku.unitWeight ||
              !tempSku.uomId ||
              !tempSku.package ||
              !tempSku.cost ||
              !tempSku.subcategoryId ||
              tempSku.selectedBrands.length == 0
            }
          >
            <SaveIcon />
            <span style={{ paddingLeft: "5px" }}>
              {t("components_globals.button_save")}
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default FormSku;
