import * as type from './types';
export function createSubcategory(action) {
  return {
    type: type.CREATE_SUBCATEGORY_REQUEST,
    payload: action,
  };
}

export function getSubcategories(action) {
  return {
    type: type.GET_SUBCATEGORIES_REQUEST,
    payload: action,
  };
}

export function updateSubcategory(action) {
  return {
    type: type.UPDATE_SUBCATEGORY_REQUEST,
    payload: action,
  };
}

export function deleteSubcategory(action) {
  return {
    type: type.DELETE_SUBCATEGORY_REQUEST,
    payload: action,
  };
}

export function clearResponseSubcategory() {
  return {
    type: type.CLEAR_RESPONSE_SUBCATEGORY,
  };
}
