import React from 'react';
import styled from 'styled-components';

const WavesWrapper = styled.div`
    position: fixed;
    right: 0em;
    width: 50%;
    display: flex;
    svg{
        transform: rotate(-90deg) scale(10, 10) translateX(20%);
        //transform-origin: 100% 25%; 
        //opacity: .75;
        //animation: fade 20s infinite;
    }
    svg path{
        fill: #6FD1B0 !important;
    }
    .overlay svg{
        position: absolute;
        animation: fade2 30s infinite;
    }
    .overlay-top svg{
        animation: fade 20s infinite;
    }
    @keyframes fade { 
        0% {
            opacity: .5;
            top: 10em;
        }
        50% {
            opacity: .75;
            top: 0em;
        }
        100% {
            opacity: .5;
            top: 10em;
        }
    }
    @keyframes fade2 { 
        0% {
            opacity: .25;
            top: 0em;
        }
        25% {
            opacity: .75;
            top: 10em;
        }
        100% {
            opacity: .25;
            top: 0em;
        }
    }
`;

const AnimateWaves = () => (
    <WavesWrapper>
        <div className='overlay overlay-top'>
            <svg viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity="1" d="M0,64L34.3,69.3C68.6,75,137,85,206,112C274.3,139,343,181,411,176C480,171,549,117,617,117.3C685.7,117,754,171,823,202.7C891.4,235,960,245,1029,229.3C1097.1,213,1166,171,1234,138.7C1302.9,107,1371,85,1406,74.7L1440,64L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
        </div>
        <div className='overlay'>
            <svg viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity="1" d="M0,192L34.3,160C68.6,128,137,64,206,42.7C274.3,21,343,43,411,85.3C480,128,549,192,617,229.3C685.7,267,754,277,823,240C891.4,203,960,117,1029,106.7C1097.1,96,1166,160,1234,186.7C1302.9,213,1371,203,1406,197.3L1440,192L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
        </div>
    </WavesWrapper>
)

export default AnimateWaves;