import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Components/Button";
import { useLocalStorage } from "../../../../Hooks/useLocalStorage";
import { usePL } from "../../../../Hooks/usePL";
import TextField from "../../../../Components/TextField";

import {
  clearResponsePL,
  savePL,
} from "../../../../Redux/modules/promotion-planning/pl/actions";
import { getResponsePL } from "../../../../Redux/modules/promotion-planning/pl/selectors";
import FormPL from "./FormPL";
import ListPL from "./ListPL";
import { PLWrapper } from "./Styles";
import { CustomModal } from "../../../../Components/Modal";

const PL = () => {
  const [t, i18n] = useTranslation("global");
  const { id, data } = usePL();
  const { enqueueSnackbar } = useSnackbar();
  const response = useSelector(getResponsePL);
  const dispatch = useDispatch();
  const { items } = useLocalStorage("tempSimulations");
  const [plName, setPlName] = useState("");

  const [configModal, setConfigModal] = useState({
    title: "",
    type: "save",
    open: false,
    cancelText: t("components_globals.button_cancel"),
    acceptText: t("components_globals.button_save"),
  });

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponsePL());
    }
  }, [response]);

  const handleSave = () => {
    setPlName("");
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  const handleCloseModal = () => {
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  const handleCancelModal = () => {
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  const handleOkModal = () => {
    dispatch(
      savePL.success({
        id,
        name: plName,
      })
    );
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  return (
    <PLWrapper
      style={{ display: "flex", flexDirection: "column", gap: "13px" }}
    >
      <Accordion style={{ borderRadius: "10px" }} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
          <Typography fontWeight={700}>
            {t(
              "sales_planning.promotion_planning_module.pl_component.form.title"
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormPL promotions={items} />
        </AccordionDetails>
      </Accordion>
      {id && id > 0 && <ListPL promotions={data} />}
      {id && id > 0 && (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button onClick={handleSave} style={{ width: "160px" }}>
            {t("components_globals.button_save")}
          </Button>
          <CustomModal
            customWidth="750px"
            title={configModal.title}
            type={configModal.type}
            open={configModal.open}
            onCloseModal={handleCloseModal}
            onCancelModal={handleCancelModal}
            onOkModal={handleOkModal}
            cancelText={configModal.cancelText}
            acceptText={configModal.acceptText}
          >
            <div>
              <Typography
                sx={{
                  textAlign: "center",
                  color: (theme) => theme.palette.custom.primary,
                }}
              >
                {t(
                  "sales_planning.promotion_planning_module.pl_component.form.messages.save_confirm"
                )}
              </Typography>
              <br />
              <div
                variant="body"
                style={{
                  fontSize: "1.5em",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                <TextField
                  variant="third"
                  required
                  value={plName}
                  label={`${t(
                    "sales_planning.promotion_planning_module.pl_component.form.inputs.name"
                  )}`}
                  onChange={(e) => setPlName(e.target.value)}
                />
              </div>
            </div>
          </CustomModal>
        </div>
      )}
    </PLWrapper>
  );
};

export default PL;
