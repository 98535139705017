import * as type from "./types";
import { createAction } from "redux-actions";
import { createRoutine } from "redux-saga-routines";
export const setDataCategory = createRoutine(type.SET_DATA_CATEGORY);

export function setCategoryDataRows(payload) {
  return {
    type: type.SET_CATEGORY_DATA_ROWS,
    payload: payload,
  };
}
export function setCategoryDataValues(payload) {
  return {
    type: type.SET_CATEGORY_DATA_VALUES,
    payload: payload,
  };
}
export function setCategoryDataValue(payload) {
  return {
    type: type.SET_CATEGORY_DATA_VALUE,
    payload: payload,
  };
}

export function setCategoryDataTempValues(payload) {
  return {
    type: type.SET_CATEGORY_DATA_TEMP_VALUES,
    payload: payload,
  };
}

export function setCategoryDataTempValue(payload) {
  return {
    type: type.SET_CATEGORY_DATA_TEMP_VALUE,
    payload: payload,
  };
}
export const setFormDataCategoryData = createAction(type.SET_FORM_DATA_CATEGORY_DATA)

export const cleanValuesCategoryData = createAction(type.CLEAN_VALUES_CATEGORY_DATA)

export const clearResponseCategoryData = createAction(type.CLEAR_RESPONSE_DATA_CATEGORY)