import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { token } from "../auth/selectors";
import {
  SAVE_CONFIG_FAILURE,
  SAVE_CONFIG_REQUEST,
  SAVE_CONFIG_SUCCESS,
  GET_CONFIG_REQUEST,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,
} from "./types";

import {updateLogo} from './actions'

function* saveConfig({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const body = {
      colors: JSON.stringify(payload.colors),
      logo:
       payload.logo && payload.logo.indexOf("base64") != -1
          ? payload.logo.replace("data:image/png;base64,", "")
          : payload.logo,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      "pricing/config/",
      tokenAccess,
      {},
      body
    );

    yield put({ type: SAVE_CONFIG_SUCCESS, payload: payload });
  } catch (e) {
    yield put({
      type: SAVE_CONFIG_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* updateLogoSaga({ payload }) {
  try {
    yield put(updateLogo.request())
    const tokenAccess = yield select(token);
    const body = {
      logo:
       payload.logo && payload.logo.indexOf("base64") != -1
          ? payload.logo.replace("data:image/png;base64,", "")
          : payload.logo,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      "pricing/logo",
      tokenAccess,
      {},
      body
    );
    yield put(updateLogo.success(payload))
  } catch (e) {
    yield put(
      updateLogo.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(updateLogo.fulfill());
  }
}


function* getConfig() {
  try {
    const tokenAccess = yield select(token);
    const response = yield call(
      callApiAxios,
      "GET",
      "pricing/config/",
      tokenAccess,
      {},
      {}
    );
    yield put({ type: GET_CONFIG_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({
      type: GET_CONFIG_FAILURE,
      message: e ? String(e.message) : "Error de conexión",
    });
  }
}

export default function* appSaga() {
  yield takeEvery(SAVE_CONFIG_REQUEST, saveConfig);
  yield takeEvery(updateLogo.TRIGGER, updateLogoSaga);
  yield takeEvery(GET_CONFIG_REQUEST, getConfig);
}
