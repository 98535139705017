import { useSelector } from "react-redux";
import { getPlLoaded } from "../Redux/modules/promotion-planning/pl/selectors";

export function usePL() {
  const plLoaded = useSelector(getPlLoaded);
  const { cache, promotions, customers, skus } = plLoaded || {};
  if (!cache) {
    return {
      id: null,
      data: [],
    };
  }
  let data = [];
  for (const key in promotions) {
    if (Object.hasOwnProperty.call(promotions, key)) {
      let currentPromotion = promotions[key];
      let dataCustomers = [];
      for (const keyC in currentPromotion.customers) {
        if (Object.hasOwnProperty.call(currentPromotion.customers, keyC)) {
          let currentCustomer = currentPromotion.customers[keyC];
          const customerInfo = customers[keyC];
          let dataSkus = [];
          for (const keyS in currentCustomer) {
            if (Object.hasOwnProperty.call(currentCustomer, keyS)) {
              let currentSKU = currentCustomer[keyS];
              const skuInfo = skus[keyS];
              dataSkus.push({
                id: parseInt(keyS),
                items: currentSKU,
                ...skuInfo,
              });
            }
          }
          dataCustomers.push({
            id: parseInt(keyC),
            items: dataSkus,
            ...customerInfo,
          });
        }
      }

      data.push({ items: dataCustomers, ...currentPromotion });
    }
  }

  return {
    id: cache,
    data,
  };
}
