import React from "react";
import styled from "styled-components";
import ItemColumn from "./ItemColumn";

const CssGridWrapper = styled.div`
  display: flex-column;

  width: 100%;
  .header-grid {
  }

  .row-colum-names-grid {
    width: 100%;
    padding: 1em;
    display: grid;
    font-weight: 700;
  }

  .body-grid .divRow {
    display: grid;
    align-items: center;
  }
  .divCol {
    text-align: center;
  }
  .color {
    width: 20px;
    height: 20px;
  }
`;

const ListGrid = (props) => {
  return (
    <CssGridWrapper>
      <div className="header-grid">
        <div
          style={{
            gridTemplateColumns:
              "repeat(" + props.columnNames.length + ", 1fr)",
          }}
          className="row-colum-names-grid"
        >
          {props.columnNames.map((name) => (
            <ItemColumn>{name}</ItemColumn>
          ))}
        </div>
      </div>
      <div className="body-grid">
        {props.children}
      </div>
    </CssGridWrapper>
  );
};

export default ListGrid;
