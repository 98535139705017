import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import {
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  GET_CATEGORY_BY_ID_SUCCESS,
  GET_CATEGORY_BY_ID_REQUEST,
  GET_CATEGORY_BY_ID_FAILURE,
} from "./types";
import { getCategories, setFinishCategory } from "./actions";
import { token } from "../auth/selectors";
import {
  SET_CATEGORY_DATA_TEMP_VALUES,
  SET_CATEGORY_DATA_ROWS,
} from "../categoryData/types";

import { getLanguage } from "../app/selectors";
import { selectCategoryIdToCreateOrUpdate } from "../initialSetup/actions";
import { getDateText, parseDateBackend } from "../../../Utils/commonWithDates";

function* createCategory({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const body = {
      name: payload.name,
      description: payload.description,
    };
    const response = yield call(
      callApiAxios,
      "POST",
      "pricing.sku.category/",
      tokenAccess,
      {},
      body
    );
    yield put({ type: CREATE_CATEGORY_SUCCESS, category: response.data[0] });
    yield put(selectCategoryIdToCreateOrUpdate(response.data[0].id));
  } catch (e) {
    yield put({
      type: CREATE_CATEGORY_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* getCategoriesSaga() {
  try {
    yield put(getCategories.request());

    const tokenAccess = yield select(token);
    const response = yield call(
      callApiAxios,
      "GET",
      "pricing.sku.category",
      tokenAccess,
      {},
      {}
    );
    yield put(getCategories.success({ categories: response.data }));
  } catch (e) {
    yield put(
      getCategories.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getCategories.fulfill());
  }
}

function* getCategoryById({ payload }) {
  try {
    const tokenAccess = yield select(token);
    const currentLanguage = yield select(getLanguage);
    const response = yield call(
      callApiAxios,
      "GET",
      `pricing.sku.category/${payload}/`,
      tokenAccess,
      {},
      {}
    );
    const category = response.data[0];
    if (category && category.sku_ids.length > 0) {
      let values = [];
    
      try {
        const responseValues = yield call(
          callApiAxios,
          "GET",
          `pricing.sku.category/${payload}/get_values`,
          tokenAccess,
          {},
          {}
        );
        const data = responseValues.data;
        let periods = [];
        let rowsCount = [];
       

        for (const item of data) {
          const skuId = !item.sku_id ? false : item.sku_id[0];
          const subcategoryId = !item.subcategory_id
            ? false
            : item.subcategory_id[0];
          const period = periods.find(
            (x) =>
              x === parseInt(item.period_index) &&
              x.subcategory_id === subcategoryId &&
              x.sku_id === skuId
          );
          if (!period) {
            periods.push({
              subcategory_id: subcategoryId,
              sku_id: skuId,
              period_index: parseInt(item.period_index),
            });
            const found = rowsCount.find(
              (x) => x.subcategory_id === subcategoryId && x.sku_id === skuId
            );
            const newValue = {
              subcategory_id: subcategoryId,
              sku_id: skuId,
              date: getDateText(
                category.period_width,
                parseDateBackend(item.date),
                currentLanguage
              ),
              period_index: parseInt(item.period_index),
              sales_units: item.sales_units,
              sales_price: item.sales_price,
              distribution: item.distribution,
            };
            if (!found) {
              rowsCount.push({
                subcategory_id: subcategoryId,
                sku_id: skuId,
                values: [newValue],
                count: 1,
              });
            } else {
              found.values.push(newValue);
              found.count += 1;
            }
            values.push(newValue);
          }
        }

        let rows = [];
        if (values && values.length > 0) {
          rows = rowsCount.sort((a, b) => a.count - b.count)[0].values;
        }

        yield put({
          type: SET_CATEGORY_DATA_ROWS,
          payload: { rows, values },
        });
      } catch (error) {
        
      }
      finally{
        yield put({
          type: GET_CATEGORY_BY_ID_SUCCESS,
          category: { ...category, values: values},
        });

      }
      

    } else {
      yield put({
        type: GET_CATEGORY_BY_ID_SUCCESS,
        category: { ...category, values: [] },
      });
    }
  } catch (e) {
    yield put({
      type: GET_CATEGORY_BY_ID_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* finishCategorySaga({ payload }) {
  try {
    yield put(setFinishCategory.request());
    const tokenAccess = yield select(token);
    const body = {
      state: "done",
    };
    const response = yield call(
      callApiAxios,
      "PUT",
      `pricing.sku.category/${payload}/`,
      tokenAccess,
      {},
      body
    );
    yield put(setFinishCategory.success());
  } catch (e) {
    yield put(
      setFinishCategory.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(setFinishCategory.fulfill());
  }
}

export default function* categoriesSaga() {
  yield takeEvery(CREATE_CATEGORY_REQUEST, createCategory);
  yield takeEvery(getCategories.TRIGGER, getCategoriesSaga);
  yield takeEvery(GET_CATEGORY_BY_ID_REQUEST, getCategoryById);
  yield takeEvery(setFinishCategory.TRIGGER, finishCategorySaga);
  yield takeEvery(setFinishCategory.FULFILL, getCategoriesSaga);
}
