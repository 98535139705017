import { createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TextField from "../../../../Components/TextField";
import MenuItem from "@mui/material/MenuItem";
import { setFormDataTrend } from "../../../../Redux/modules/what-if/trend/actions";
import InputSelectVariables from "./InputSelectVariables";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import {
  getSkuGraph,
  getFormDataTrend,
} from "../../../../Redux/modules/what-if/trend/selectors";
import {
  DownloadIcon,
  SaveIcon,
  ViewIcon,
  TableIcon,
} from "../../../../Components/CustomIcons";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Modal } from "@mui/material";
import { useScreenshot, createFileName } from "use-react-screenshot";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import * as XLSX from "xlsx";
import Graph from "./Graph";
import { Wrapper, CssBox } from "./StyleGraph";
import { TableGraph } from "./TableGraph";
import { getDateValues } from "../../../../Utils/commonWithDates";

const GraphSeries = ({ skus }) => {
  const dispatch = useDispatch();
  const skuSelected = useSelector(getSkuGraph);
  const [open, setOpen] = useState(false);
  const tempForm = useSelector(getFormDataTrend);
  const refGraph = createRef(null);
  const skuTable = skuSelected && skuSelected.table;
  const [t, i18n] = useTranslation("global");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showGraphTable, setShowGraphTable] = useState(true);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpg",
    quality: 1.0,
  });

  const handleGraphTable = () => {
    setShowGraphTable(!showGraphTable);
  };

  const exportToExcel = () => {
    let [table] = document.getElementsByClassName("tableGraph");
    let today = new Date();
    try {
      let excelTable = document.createElement("table");
      excelTable.innerHTML = table.innerHTML.trim();
      let times = excelTable.querySelectorAll(".col-date");
      let index = 0;
      for (let time of times) {
        const objDate = getDateValues(skuTable[index].date);
        const month = objDate.month > 9 ? objDate.month : `0${objDate.month}`;
        const day = objDate.day > 9 ? objDate.day : `0${objDate.day}`;
        time.innerText = `${month}-${objDate.year}`;
        index++;
      }
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.table_to_sheet(excelTable);

      XLSX.utils.book_append_sheet(wb, ws, "Table");
      XLSX.writeFile(
        wb,
        `export_table_graph_trend${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDay()}-${today.getTime()}.xlsx`
      );
    } catch (error) {}
  };

  const handleDownload = () => {
    if (!showGraphTable) {
      exportToExcel();
    } else {
      const downloadScreenshot = () =>
        takeScreenShot(refGraph.current).then(download);
      downloadScreenshot();
    }
  };

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  return (
    <Wrapper>
      <Box
        sx={{
          background: (theme) => `${theme.palette.custom.secondary}`,
          color: (theme) => `${theme.palette.custom.primary}`,
          height: "3.5rem",
          borderRadius: "0.5rem 0.5rem 0rem 0rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            padding: "1rem  1rem 0rem 2rem",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          {t("price_management.trend.graph_liquid")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            padding: "1rem  2rem 0rem 0rem",
          }}
        >
          <ButtonItemColumn
            onClick={() => {}}
            tooltip={t("components_globals.button_save")}
            icon={
              <SaveIcon
                className="iconButtonPrimary strokeLigth"
                sx={{ fontSize: "2rem" }}
              />
            }
          />
          {showGraphTable ? (
            <ButtonItemColumn
              onClick={handleGraphTable} //cambia grafica vs tabla
              tooltip={t("price_management.curves_component.see_table")}
              icon={<TableIcon className="iconButtonPrimary strokeLigth" />}
            />
          ) : (
            <ButtonItemColumn
              onClick={handleGraphTable} //cambia grafica vs tabla
              tooltip={t("price_management.curves_component.see_graph")}
              icon={
                <QueryStatsIcon className="iconButtonPrimary strokeLigth" />
              }
            />
          )}
          <ButtonItemColumn
            onClick={() => {}}
            tooltip={t("components_globals.button_download")}
            icon={
              <DownloadIcon
                onClick={handleDownload}
                className="iconButtonPrimary strokeLigth"
              />
            }
          />
          <ButtonItemColumn
            onClick={handleOpen}
            tooltip={t("components_globals.button_expand")}
            icon={
              <ViewIcon
                className="iconButtonPrimary strokeLigth"
                sx={{ fontSize: "2rem" }}
              />
            }
          />
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <div style={{ display: "flex", alignItems: "end", gap: "1rem" }}>
          <TextField
            sx={{ width: "18rem", margin: "1rem 0rem 0rem 0rem" }}
            select
            variant="third"
            label={t("initial_setup.sku")}
            value={tempForm?.skuIdGraph}
            onChange={(e) => {
              dispatch(
                setFormDataTrend({
                  propertyName: "skuIdGraph",
                  propertyValue: e.target.value,
                })
              );
            }}
          >
            {skus &&
              skus.map((option, index) => {
                return (
                  <MenuItem key={index} value={option.id}>
                    {`${option.name}`}
                  </MenuItem>
                );
              })}
          </TextField>
          <InputSelectVariables variables={tempForm.variablesSelected} />
        </div>
        <div style={{ width: "100%", height: "100%" }} ref={refGraph}>
          {showGraphTable ? (
            skuTable && <Graph skuTable={skuTable} size={"normal"} />
          ) : (
            <TableGraph skuTable={skuTable} />
          )}
        </div>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CssBox>
          <div className="close-btn">
            <CloseFullscreenIcon
              onClick={handleClose}
              sx={{ fontSize: "1.3rem" }}
            />
          </div>
          <Graph skuTable={skuTable} size={"fullScreen"} />
        </CssBox>
      </Modal>
    </Wrapper>
  );
};

export default GraphSeries;
