import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import FormOwner from "./FormOwner";
import FooterStep from "../../FooterStep";
import ListOwners from "./ListOwners";
import {
  createOwner,
  clearResponseOwner,
  deleteOwner,
  updateOwner,
} from "../../../../Redux/modules/owners/actions";
import { addOwnerToCategory } from "../../../../Redux/modules/categories/actions";
import { getResponseOwner } from "../../../../Redux/modules/owners/selectors";
import { getOwnersLoaded } from "../../../../Redux/modules/owners/selectors";
import { StepWrapper } from "../../styles";


const Owner = ({ categoryId, currentOwners = [], onNext, onBack }) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const owners = useSelector(getOwnersLoaded);
  const response = useSelector(getResponseOwner);
  


  const onOwnerCreated = (newOwner) => {
    dispatch(createOwner({ ...newOwner, categoryId: categoryId }));
  };

  const onOwnerDeleted = (owner) => {
    dispatch(deleteOwner({ ...owner, categoryId: categoryId }));
  };

  const onOwnerUpdated = (owner) => {
    dispatch(updateOwner({ ...owner, categoryId: categoryId }));
  };

  const onOwnersAdded = (ownersAdded) => {
    dispatch(addOwnerToCategory({ owners: ownersAdded, many: true }));
  };

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseOwner());
    }
  }, [response]);

  const filterOwners = () => {
    return owners
      ? owners.filter(
          (xOwner) => !currentOwners.map((x) => x.id).includes(xOwner.id)
        )
      : [];
  };

  return (
    <StepWrapper>
      <FormOwner
        owners={filterOwners()}
        onOwnerCreated={onOwnerCreated}
        onOwnersAdded={onOwnersAdded}
      ></FormOwner>
      <FooterStep onNext={onNext} onBack={onBack}></FooterStep>
      <ListOwners
        onOwnerDeleted={onOwnerDeleted}
        onOwnerUpdated={onOwnerUpdated}
        owners={currentOwners}
      ></ListOwners>
    </StepWrapper>
  );
};

export default Owner;
