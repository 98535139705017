import * as type from "./types";
import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";
export const createVariable = createRoutine(type.CREATE_VARIABLE);

export const updateVariable = createRoutine(type.UPDATE_VARIABLE);
//get all variable

export function getVariable() {
  return {
    type: type.GET_VARIABLE_REQUEST,
  };
}

//delete variable
export function deleteVariable(id) {
  return {
    type: type.DELETE_VARIABLE_REQUEST,
    payload: id,
  };
}
//clear data message and error
export function clearVariableData() {
  return {
    type: type.CLEAR_VARIABLE_DATA,
  };
}
//get variable by id
export function getVariableById(action) {
  return {
    type: type.GET_VARIABLE_BY_ID_REQUEST,
    payload: action,
  };
}
export function clearResponseVariable() {
  return {
    type: type.CLEAR_RESPONSE_VARIABLE,
  };
}

export function getValuesByVariableId(payload) {
  return {
    type: type.GET_VALUES_BY_VARIABLE_ID_REQUEST,
    payload: payload,
  };
}

export function setVariableValues(payload) {
  return {
    type: type.SET_VARIABLE_VALUES,
    payload: payload,
  };
}

export function setVariableTempValues(payload) {
  return {
    type: type.SET_VARIABLE_TEMP_VALUES,
    payload: payload,
  };
}

export function setVariableTempValue(payload) {
  return {
    type: type.SET_VARIABLE_TEMP_VALUE,
    payload: payload,
  };
}

export function syncVariableValues() {
  return {
    type: type.SYNC_VARIABLE_VALUES,
  };
}

export function resetVariableValues() {
  return {
    type: type.RESET_VARIABLE_VALUES,
  };
}

export const setFormDataVariables = createAction(type.SET_FORM_DATA_VARIABLES)
export const setCurrentVariable = createAction(type.SET_CURRENT_VARIABLE)