import React from "react";
import Accordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";

const CssAccordion = styled(Accordion)(({ theme }) => ({
  marginBottom: "1.2em",
  borderRadius: "0.5em",
  boxShadow: "0px 3px 6px #00000029",

  padding: theme.spacing(1), //cambiar el tamaño del
  ".MuiAccordionSummary-root": {
    backgroundColor: theme.palette.custom.background,
  },

  ".MuiAccordionSummary-content": {
    margin: theme.spacing(0),
    width: "100%",
    color: theme.palette.custom.secondary,
  },

  ".MuiAccordionDetails-root": {
    paddingTop: theme.spacing(0),
    backgroundColor: theme.palette.custom.background,
  },

  "& .MuiAccordionSummary-content.Mui-expanded": {
    backgroundColor: theme.palette.custom.background,
  },
}));

const CustomAccordion = (props) => {
  return <CssAccordion {...props}>{props.children}</CssAccordion>;
};

export default CustomAccordion;
