import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import ItemRowWithOptions from "../../../../Components/Table/ItemRowWithOptions";
import ItemColumn from "../../../../Components/Table/ItemColumn";
import CommonStepList from "../../CommonStepList";
import FormSku from "./FormSku";
import { CustomModal } from "../../../../Components/Modal";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";

export default function ListSkus({
  skus,
  units,
  brands,
  category,
  onSkuUpdated,
  onSkuDeleted,
}) {
  const [t, i18n] = useTranslation("global");
  const [currentSku, setCurrentSku] = useState(null);
  const [searchSku, setSearchSku] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [configModal, setConfigModal] = useState({
    title: "",
    type: "edit",
  });

  const onItemSkuEdit = (sku) => {
    setCurrentSku({
      ...sku,
      uomId: sku.uom_id[0],
      brandId: sku.brand_id[0],
      subcategoryId: sku.subcategory_id[0],
    });

    setOpenModal(!openModal);
    setConfigModal({
      title: t("initial_setup.modals.edit_sku"),
      type: "edit",
      cancelText: t("components_globals.button_cancel"),
      acceptText: t("components_globals.button_update"),
    });
  };
  const onItemSkuDelete = (sku) => {
    setCurrentSku(sku);
    setOpenModal(!openModal);
    setConfigModal({
      title:( <div>
        {`${t("initial_setup.modals.delete_sku")} `}
        <br />
          <Typography variant="body" fontWeight={600}>
            {sku.name}
          </Typography>
        </div>),
      type: "delete",
      cancelText: t("components_globals.button_cancel"),
      acceptText: t("components_globals.button_ok"),
    });
  };

  const onChangeSearchSku = (filter) => {
    if (filter) {
      setSearchSku(filter);
    } else {
      setSearchSku("");
    }
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
    setCurrentSku(null);
  };

  const handleCancelModal = () => {
    setOpenModal(!openModal);
    setCurrentSku(null);
  };

  const handleOkModal = () => {
    switch (configModal.type) {
      case "edit":
        onSkuUpdated(currentSku);
        break;

      case "delete":
        onSkuDeleted(currentSku);
        break;
      default:
        break;
    }
    setOpenModal(!openModal);
  };

  const filterSkus = (filter) => {
    if (filter) {
      return skus.filter(
        (sku) =>
          sku.name.toUpperCase().indexOf(filter.toUpperCase()) != -1 ||
          sku.description.toUpperCase().indexOf(filter.toUpperCase()) != -1 ||
          sku.brand_id[1].toUpperCase().indexOf(filter.toUpperCase()) != -1 ||
          sku.subcategory_id[1].toUpperCase().indexOf(filter.toUpperCase()) !=
            -1
      );
    } else {
      return skus;
    }
  };

  const skusFiltered = filterSkus(searchSku);

  const getSkuImage = (imagen, title, color) => {
    return imagen ? (
      <Avatar
        alt=""
        src={`${
          imagen.indexOf("base64") !== -1
            ? imagen
            : `data:image/png;base64,${imagen}`
        }`}
        sx={{ width: 30, height: 30 }}
      />
    ) : (
      <Avatar
        sx={{
          fontSize: "14px",
          marginRight: "10px",
          width: 30,
          height: 30,
          bgcolor: color,
        }}
      >
        {title.toUpperCase().charAt(0)}
      </Avatar>
    );
  };

  const columnNames = [
    t("initial_setup.forms.common_inputs.name"),
    t("initial_setup.forms.common_inputs.content"),
    t("initial_setup.forms.common_inputs.brand"),
    t("initial_setup.forms.common_inputs.subcategory"),
    t("initial_setup.forms.common_inputs.options"),
  ];
  const listSkuItems = skusFiltered.map((sku, index) => {
    const brand = brands.find((x) => x.id == sku.brand_id[0]);
    return (
      <ItemRowWithOptions
        customStyle={{ padding: "10px" }}
        key={index}
        index={index}
        withCommonButtons={true}
        onItemEdit={() => onItemSkuEdit(sku)}
        tooltipTitleEdit={t("components_globals.button_edit")}
        onItemDelete={() => onItemSkuDelete(sku)}
        tooltipTitleDelete={t("components_globals.button_remove")}
        columnsNumber={5}
      >
        <ItemColumn>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <div
              style={{
                fontWeight: 700,
                lineHeight: "1.8rem",
                width: "30px",
                height: "30px",
                marginRight: "10px",
              }}
            >
              {getSkuImage(sku.image, sku.name, brand.color)}
            </div>

            <span style={{ fontSize: "13px" }}>{sku.name}</span>
          </div>
        </ItemColumn>
        <ItemColumn>{`${sku.unit_weight} ${sku.uom_id && sku.uom_id[1]}/${
          sku.package
        }`}</ItemColumn>
        <ItemColumn>
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: brand.color,
                fontWeight: 700,
                width: "15px",
                height: "15px",
                marginRight: "5px",
                borderRadius: "50%",
              }}
            ></div>
            {brand && brand.name}
          </div>
        </ItemColumn>
        <ItemColumn>{sku.subcategory_id && sku.subcategory_id[1]}</ItemColumn>
      </ItemRowWithOptions>
    );
  });
  return (
    <>
      <CommonStepList
        listItems={listSkuItems}
        onChangeSearchItem={onChangeSearchSku}
        filterFn={filterSkus}
        columnNames={columnNames}
        paginationTitle={t("initial_setup.step_3.stage_1.title")}
      ></CommonStepList>
      <CustomModal
        customWidth="750px"
        title={configModal.title}
        type={configModal.type}
        open={openModal}
        onCloseModal={handleCloseModal}
        onCancelModal={handleCancelModal}
        onOkModal={handleOkModal}
        cancelText={configModal.cancelText}
        acceptText={configModal.acceptText}
      >
        {configModal.type === "edit" && (
          <FormSku
            key={2}
            sku={currentSku}
            brands={category && category.brand_ids}
            units={units}
            subcategories={category && category.subcategory_ids}
            setCurrentSku={setCurrentSku}
          ></FormSku>
        )}
      </CustomModal>
    </>
  );
}
