import { useState } from "react";
import { getVariablesSelected } from "../../../../Redux/modules/what-if/trend/selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getDateValues } from "../../../../Utils/commonWithDates";
import TableContainer from "@mui/material/TableContainer";
import { CustomTable } from "./StyleTableTrend";
import { CustomCell } from "./StyleRowSimulationStk";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import {
  StyledTableRow,
  StyledTableCellHeaderSticky,
  StyledTableCellHeader,
  CustomCellSticky,
  CommonCell,
} from "./StyleTotalOrder";
import { commaSeparateNumber } from "../../../../Utils";

export const TableGraph = ({ skuTable, ref }) => {
  const [t, i18n] = useTranslation("global");
  const currentVariablesSelected = useSelector(getVariablesSelected);
  let rows = [
    { name: "price", index: 0 },
    { name: "price", index: 1 },
  ];
  currentVariablesSelected.forEach((item) => {
    rows.push({ name: item, index: 0 });
    rows.push({ name: item, index: 1 });
  });
  let times =
    skuTable &&
    skuTable.map((item) => {
      const objDate = getDateValues(item.date);
      const month = [10, 11, 12].includes(objDate.month)
        ? objDate.month
        : `0${objDate.month}`;
      const year = objDate.year.toString().substring(2, 4);
      return `${month}-${year}`;
    });

  const getDataVariables = (variant, index) => {
    const values =
      skuTable &&
      skuTable.map((item) =>
        commaSeparateNumber(item[variant][index].toFixed(2))
      );
    return {
      name: `${t(`price_management.trend.${variant}`)} ${
        index === 0 ? "Base" : "Forecast"
      }`,
      data: values,
    };
  };
  const buildHeaders = () => {
    return (
      times &&
      times.map((col, index) => {
        return (
          <StyledTableCellHeader
          className="col-date"
            key={`first-${index}`}
            align="center"
            sx={{ fontWeight: "600", fontSize: "1rem" }}
          >
            {col}
          </StyledTableCellHeader>
        );
      })
    );
  };
  const buildRow = (row) => {
    let cell = getDataVariables(row.name, row.index);
    const cells =
      cell &&
      cell.data.map((col, index) => {
        return (
          <CustomCell
            minWidth="70px"
            key={`col-${row.name}-${row.index}-${index}`}
          >
            {col}
          </CustomCell>
        );
      });

    return (
      <StyledTableRow key={`row-${row.name}-${row.index}`}>
        <CommonCell
          scope="row"
          textAlign="left"
          minWidth="180px"
          odd={row.index % 2 === 0}
          sx={{
            paddingLeft: "10px",
            fontWeight: "600",
            position: "sticky",
          }}
        >
          {cell.name}
        </CommonCell>
        {cells}
      </StyledTableRow>
    );
  };
  return (
    <TableContainer sx={{ minHeight: 400 }}>
      <CustomTable className="tableGraph">
        <TableHead>
          <StyledTableRow>
            <StyledTableCellHeaderSticky
              scope="row"
              align="left"
              sx={{
                fontSize: "1rem",
                paddingLeft: "1rem",
                fontWeight: "600",
              }}
            ></StyledTableCellHeaderSticky>
            {buildHeaders()}
          </StyledTableRow>
        </TableHead>
        <TableBody>{rows?.map((row) => buildRow(row))}</TableBody>
      </CustomTable>
    </TableContainer>
  );
};
