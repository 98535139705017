import React, { useState } from "react";
import TextField from "../../Components/TextField";
import FormControl from "../../Components/FormControl";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import { SearchIcon } from "../../Components/CustomIcons";

const CssFormControl = styled(FormControl)(({ theme }) => ({
  stroke: theme.palette.custom.text,
  "& label": {
    color: theme.palette.custom.text,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    color: theme.palette.custom.thirdVariant,
  },
  "& .MuiInputAdornment-root": {
    color: theme.palette.custom.text,
  },
  "&:hover label, &:hover .MuiInputAdornment-root": {
    color: theme.palette.custom.thirdVariant,
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.custom.text,
      borderWidth: "3px",
      borderRadius: "1em",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.custom.thirdVariant,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.custom.thirdVariant,
    },
  },
}));

export default function HeaderStepList({
  searchText,
  onChangeSearch,
  paginationTitle,
}) {
  const [t, i18n] = useTranslation("global");
  const [pagination, setPagination] = useState(4);
  const handleChangePagination = (e) => {
    setPagination(e.target.value);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "20px",
      }}
    >
      <TextField
        variant="third"
        label={`${t("initial_setup.header_list.search")}`}
        value={searchText}
        onChange={(e) => onChangeSearch(e.target.value)}
        InputProps={{ endAdornment: <SearchIcon /> }}
      />

      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          style={{ marginRight: "15px", cursor: "pointer" }}
          onClick={(e) => onChangeSearch("")}
        >{`${t("initial_setup.header_list.view_all")}`}</span>
        {/* <CssFormControl style={{ width: "200px" }}>
          <Select
            style={{ width: "100%" }}
            value={pagination}
            onChange={handleChangePagination}
          >
            <MenuItem value={5}>{`5 ${paginationTitle} `}</MenuItem>
            <MenuItem value={10}>{`10 ${paginationTitle} `}</MenuItem>
            <MenuItem value={15}>{`15 ${paginationTitle} `}</MenuItem>
          </Select>
        </CssFormControl> */}
      </div>
    </div>
  );
}
