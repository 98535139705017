import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import ListGrid from "../../Components/Table/ListGridCategory";

export default function CommonStepList({
  listItems,
  columnNames,
}) {
  return (
      <Stack>
        <ListGrid columnNames={columnNames}>{listItems}</ListGrid>
      </Stack>
      
  );
}
