import React from 'react'
import { Grid, Typography } from '@mui/material/';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from "react-redux";
import { getOptimalsLoaded,getOptimalPricesSimulationData } from "../../../../Redux/modules/what-if/optimal-prices/selectors"
import { setFormDataOptimalPrices } from "../../../../Redux/modules/what-if/optimal-prices/actions"
import Tooltip from "@mui/material/Tooltip";
import Input from "../../../../Components/Input";
import { commaSeparateNumber, truncateNumber } from "../../../../Utils";
import { useTranslation } from "react-i18next";

const TableVariables = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const optimalsData = useSelector(getOptimalsLoaded);
  const tempSimulationData = useSelector(getOptimalPricesSimulationData);

  const handleChangeInput = (propertyName, propertyValue, key) => {
    dispatch(
      setFormDataOptimalPrices({
        propertyName,
        propertyValue,
        key,
      })
    );
  };

  const CssDiv = styled('div')(({ theme }) => ({
    width: '100%',
    overflow: 'hidden',
    borderRadius: '.5em',
    backgroundColor: theme.palette.custom.primary,
    '.title': {
      backgroundColor: theme.palette.custom.secondary,
      color: theme.palette.custom.primary,
      textAlign: 'center',
      fontSize: '1em',
      fontWeight: 'bold',
      width: '100%',
      padding: '1em',
      margin: '0 !important',
    },
    '.env-item': {
      color: theme.palette.custom.secondary,
    }
  }));
  const getValue = (type, code, value) => {
        let itemExist = tempSimulationData[type].find((item) => item.code === code);
    return itemExist ? itemExist.value : value;
  };
  return (
    <CssDiv>
      <Grid item xs={12} className='title'>
        {t("price_management.optimal_prices.environment_variable")}
      </Grid>
      <Grid container 
      display="flex"
      alignItems="center"
      justifyContent="center"
      spacing={3}
      padding={2}
      >
        {
          optimalsData.env?.map((item, index) => (
            <Grid item
              xs={2}
              key={index}
            >
              <Tooltip title={item.name} placement="top">
                <div style={{ textAlign: 'center' }} className={'env-item'}>
                  {item.abbreviation ? item.abbreviation : item.name}
                  <Input
                    label="Variable de entorno 1"
                    variant="secundary"
                    input
                    type="number"
                   value={commaSeparateNumber(getValue(
                      "values",
                      item.key,
                       truncateNumber(item.value)
                    ))}
                     onChange={(e) =>
                      handleChangeInput(
                        "values",
                        e.target.value,
                        item.key
                      )
                    }
                  />
                </div>
              </Tooltip>
            </Grid>
          ))}
      </Grid>
    </CssDiv>
  )
}

export default TableVariables