import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ItemRowWithOptions from "../../../../Components/Table/ItemRowWithOptions";
import ItemColumn from "../../../../Components/Table/ItemColumn";
import CommonStepList from "../../CommonStepList";
import FormSubcategory from "./FormSubcategory";
import { CustomModal } from "../../../../Components/Modal";
import { textAlign } from "@mui/system";
import { Typography } from "@mui/material";

export default function ListSubcategories({
  subcategories,
  onSubcategoryUpdated,
  onSubcategoryDeleted,
}) {
  const [t, i18n] = useTranslation("global");
  const [currentSubcategory, setCurrentSubcategory] = useState(null);
  const [searchSubcategory, setSearchSubcategory] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [configModal, setConfigModal] = useState({
    title: "",
    type: "edit",
  });

  const onItemSubcategoryEdit = (subcategory) => {
    setCurrentSubcategory(subcategory);
    setOpenModal(!openModal);
    setConfigModal({
      title: t("initial_setup.modals.edit_subcategory"),
      type: "edit",
      cancelText: t("components_globals.button_cancel"),
      acceptText: t("components_globals.button_update"),
    });
  };
  const onItemSubcategoryDelete = (subcategory) => {
    setCurrentSubcategory(subcategory);
    setOpenModal(!openModal);
    setConfigModal({
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          {`${t("initial_setup.modals.delete_subcategory")} `}
          <br />
          <Typography variant="body" fontWeight={600}>
            {subcategory.name}
          </Typography>
        </div>
      ),
      type: "delete",
      cancelText: t("components_globals.button_cancel"),
      acceptText: t("components_globals.button_ok"),
    });
  };

  const onChangeSearchSubcategory = (filter) => {
    if (filter) {
      setSearchSubcategory(filter);
    } else {
      setSearchSubcategory("");
    }
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
    setCurrentSubcategory(null);
  };

  const handleCancelModal = () => {
    setOpenModal(!openModal);
    setCurrentSubcategory(null);
  };

  const handleOkModal = () => {
    switch (configModal.type) {
      case "edit":
        onSubcategoryUpdated(currentSubcategory);
        break;

      case "delete":
        onSubcategoryDeleted(currentSubcategory);
        break;
    }
    setOpenModal(!openModal);
  };

  const filterSubcategories = (filter) => {
    if (filter) {
      return subcategories.filter(
        (subcategory) =>
          subcategory.name.toUpperCase().indexOf(filter.toUpperCase()) != -1 ||
          subcategory.description.toUpperCase().indexOf(filter.toUpperCase()) !=
            -1
      );
    } else {
      return subcategories;
    }
  };

  const subcategoriesFiltered = filterSubcategories(searchSubcategory);

  const columnNames = [
    t("initial_setup.forms.common_inputs.name"),
    t("initial_setup.forms.common_inputs.description"),
    t("initial_setup.forms.common_inputs.color"),
    t("initial_setup.forms.common_inputs.options"),
  ];
  const listSubcategoryItems = subcategoriesFiltered.map(
    (subcategory, index) => {
      return (
        <ItemRowWithOptions
          key={index}
          index={index}
          withCommonButtons={true}
          onItemEdit={() => onItemSubcategoryEdit(subcategory)}
          tooltipTitleEdit={t("components_globals.button_edit")}
          onItemDelete={() => onItemSubcategoryDelete(subcategory)}
          tooltipTitleDelete={t("components_globals.button_remove")}
        >
          <ItemColumn>{subcategory.name}</ItemColumn>
          <ItemColumn>{subcategory.description}</ItemColumn>
          <ItemColumn>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                className="color"
                style={{ backgroundColor: subcategory.color }}
              ></div>
            </div>
          </ItemColumn>
        </ItemRowWithOptions>
      );
    }
  );
  return (
    <>
      <CommonStepList
        listItems={listSubcategoryItems}
        onChangeSearchItem={onChangeSearchSubcategory}
        filterFn={filterSubcategories}
        columnNames={columnNames}
        paginationTitle={t("initial_setup.step_1.stage_2.title")}
      ></CommonStepList>
      <CustomModal
        customWidth="600px"
        title={configModal.title}
        type={configModal.type}
        open={openModal}
        onCloseModal={handleCloseModal}
        onCancelModal={handleCancelModal}
        onOkModal={handleOkModal}
        cancelText={configModal.cancelText}
        acceptText={configModal.acceptText}
      >
        {configModal.type === "edit" && (
          <FormSubcategory
            subcategory={currentSubcategory}
            setCurrentSubcategory={setCurrentSubcategory}
          ></FormSubcategory>
        )}
      </CustomModal>
    </>
  );
}
