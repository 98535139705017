import React, { useState } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
  Accordion as MuiAccordion,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { periodWidthValues } from "../../dataCommon";
import CustomDataPicker from "../../../../Components/Datapicker";
import { calculateRows, getDefaultFirstPeriod } from "../../../../Utils/commonWithDates";
import { setFormDataCategoryData } from "../../../../Redux/modules/categoryData/actions";
import { useDispatch, useSelector } from "react-redux";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const FormDataLoadInputs = ({ category, tempDataLoad }) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const [expanded, setExpanded] = useState("panel1");
  const [expanded2, setExpanded2] = useState("panel2");
  const [expanded3, setExpanded3] = useState("panel3");

  const handleChange = (panel) => (event, newExpanded) => {
    if (expanded !== "") {
      setExpanded(newExpanded ? panel : false);
    }
    if (expanded2 !== "") {
      setExpanded2(newExpanded ? panel : false);
    }
    if (expanded3 !== "") {
      setExpanded3(newExpanded ? panel : false);
    }
  };

  const handleRadioChangeSubcategory = (e) => {
    dispatch(
      setFormDataCategoryData({
        propertyName: "subcategoryId",
        value: parseInt(e.target.value),
      })
    );

    dispatch(
      setFormDataCategoryData({
        propertyName: "columns",
        value:
          tempDataLoad.tableType !== "total_subcategory"
            ? category.sku_ids.filter(
                (sku) => sku.subcategory_id[0] === parseInt(e.target.value)
              )
            : tempDataLoad.defaultColumns,
      })
    );
  };

  const checkSundayByWeeks = (period, firstPeriod) => {
    if (period === "weeks") {
      const newStartDate = new Date(firstPeriod);

      let day = newStartDate.getDay();
      if (day !== 0 && day < 4) {
        newStartDate.setDate(newStartDate.getDate() - day);
      } else if (day !== 0 && day >= 4) {
        newStartDate.setDate(newStartDate.getDate() + (7 - day));
      }
      dispatch(
        setFormDataCategoryData({
          propertyName: "firstPeriod",
          value: newStartDate,
        })
      );
    }
  };

  const handleRadioChangePeriodicity = (e) => {
    const period = e.target.value;
    checkSundayByWeeks(period, tempDataLoad.firstPeriod);
    const rowsData = calculateRows(period, tempDataLoad.firstPeriod);
    dispatch(
      setFormDataCategoryData({
        propertyName: "periodWidth",
        value: period,
      })
    );
    dispatch(
      setFormDataCategoryData({
        propertyName: "rowsData",
        value: rowsData,
      })
    );
  };

  const handleChangeFirstPeriod = (e) => {

    let firstPeriod = e;
    const defaultFirstPeriod = getDefaultFirstPeriod(
      tempDataLoad.periodWidth
    );

    if (firstPeriod > defaultFirstPeriod) {
      firstPeriod = defaultFirstPeriod;
    }

    const rowsData = calculateRows(tempDataLoad.periodWidth, firstPeriod);
    if (firstPeriod !== tempDataLoad.firstPeriod) {
      if (tempDataLoad.periodWidth === "weeks") {
        checkSundayByWeeks(tempDataLoad.periodWidth, firstPeriod);
      } else {
        dispatch(
          setFormDataCategoryData({
            propertyName: "firstPeriod",
            value: firstPeriod,
          })
        );
      }

      dispatch(
        setFormDataCategoryData({
          propertyName: "rowsData",
          value: rowsData,
        })
      );
    }
  };

  return (
    <div>
      <div>
        <h5
          style={{
            fontSize: "1rem",
            color: (theme) => `${theme.palette.custom.secondary}`,
            marginBottom: "1px",
          }}
        >
          {`${t("initial_setup.step_6.step_name")}`}
        </h5>
      </div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: (theme) => `${theme.palette.custom.primaryVariant}`,
              }}
            />
          }
          aria-controls="panel1d-content"
        >
          <FormLabel
            sx={{
              color: (theme) => `${theme.palette.custom.primaryVariant}`,
              fontWeight: "bold",
              "&.Mui-focused fieldset,&.Mui-focused ": {
                color: (theme) => `${theme.palette.custom.primaryVariant}`,
              },
            }}
            id="radio-subcategories-group-label"
          >
            {`${t("initial_setup.forms.common_inputs.subcategory")}`}
          </FormLabel>
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup
            aria-labelledby="radio-subcategories-group-label"
            value={tempDataLoad.subcategoryId}
            name="radio-subcategories-group"
            onChange={handleRadioChangeSubcategory}
          >
            {category &&
              category.subcategory_ids.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option.id}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: (theme) =>
                            `${theme.palette.custom.primaryVariant}`,
                        },
                      }}
                    />
                  }
                  label={option.name}
                  sx={{ marginBottom: "-10px" }}
                />
              ))}
          </RadioGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded2 === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: (theme) => `${theme.palette.custom.primaryVariant}`,
              }}
            />
          }
          aria-controls="panel-content-period-width"
        >
          <FormLabel
            sx={{
              color: (theme) => `${theme.palette.custom.primaryVariant}`,
              fontWeight: "bold",
              "&.Mui-focused fieldset,&.Mui-focused ": {
                color: (theme) => `${theme.palette.custom.primaryVariant}`,
              },
            }}
            id="radio-periodicity-group-label"
          >
            {`${t("initial_setup.forms.common_inputs.periodWidthValues")}`}
          </FormLabel>
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup
            aria-labelledby="radio-periodicity-group-label"
            value={tempDataLoad.periodWidth}
            name="radio-periodicity-group"
            onChange={handleRadioChangePeriodicity}
          >
            {periodWidthValues.map((option, index) => {
              if (["weeks", "months"].includes(option.value)) {
                return (
                  <FormControlLabel
                    key={index}
                    value={option.value}
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: (theme) =>
                              `${theme.palette.custom.primaryVariant}`,
                          },
                        }}
                      />
                    }
                    label={`${t(
                      "initial_setup.forms.common_inputs." + option.label
                    )}`}
                    sx={{ marginBottom: "-10px" }}
                  />
                );
              }
            })}
          </RadioGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded2 === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel-content-first-period">
          <FormLabel
            sx={{
              color: (theme) => `${theme.palette.custom.primaryVariant}`,
              fontWeight: "bold",
              "&.Mui-focused fieldset,&.Mui-focused ": {
                color: (theme) => `${theme.palette.custom.primaryVariant}`,
              },
            }}
            id="radio-periodicity-group-label"
          >
            {`${t("initial_setup.forms.common_inputs.first_period")}`}
          </FormLabel>
        </AccordionSummary>
        <AccordionDetails>
          <CustomDataPicker
            {...("months" === tempDataLoad.periodWidth
              ? { views: ["year", "month"] }
              : {})}
            value={tempDataLoad.firstPeriod}
            onChange={handleChangeFirstPeriod}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FormDataLoadInputs;
