import { useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getVariablesSelected } from "../../../../Redux/modules/what-if/trend/selectors";
import { getDateValues } from "../../../../Utils/commonWithDates";
const Graph = ({ skuTable, size, ref }) => {
  const [t, i18n] = useTranslation("global");
  const lines = ["price"];
  const names = [
    "value",
    "profit",
    "distribution",
    "som",
    "quantity",
    "quantity_kg",
  ];

  const tempVariables = {
    priceBase: { color: "#16168d", visible: true },
    priceForecast: { color: "#ad1a1a", visible: false },
    valueBase: { color: "#16168d", visible: true },
    valueForecast: { color: "#6FD1B0", visible: false },
    profitBase: { color: "#407D68", visible: true },
    profitForecast: { color: "#6FD1B0", visible: false },
    distributionBase: { color: "#16168d", visible: true },
    distributionForecast: { color: "#1F1C36", visible: false },
    somBase: { color: "yellow", visible: true },
    somForecast: { color: "#EBCE75", visible: false },
    quantityBase: { color: "#ad1a1a", visible: true },
    quantityForecast: { color: "#6FD1B0", visible: false },
    quantity_kgBase: { color: "#ad1a1a", visible: true },
    quantity_kgForecast: { color: "#6FD1B0", visible: false },
  };
  const currentVariablesSelected = useSelector(getVariablesSelected);
  const [visibleVariables, setVisibleVariable] = useState([
    currentVariablesSelected,
  ]);
  let times =
    skuTable &&
    skuTable.map((item) => {
      const objDate = getDateValues(item.date);
      const month = [10, 11, 12].includes(objDate.month)
        ? objDate.month
        : `0${objDate.month}`;
      const year = objDate.year.toString().substring(2, 4);
      return `${month}-${year}`;
    });

  const handleClickLegend = (chartContext, seriesIndex, config) => {
    console.log(chartContext);
  };

  const getDataVariables = (variant, type, index) => {
    const values =
      skuTable && skuTable.map((item) => item[variant][index].toFixed(2));
    return {
      name: `${t(`price_management.trend.${variant}`)} ${
        index === 0 ? "Base" : "Forecast"
      }`,
      type: type,
      data: values,
    };
  };

  let currentSerie = [];
  let currentAxisY = [];
  if (currentVariablesSelected.length > 0) {
    for (const variant of currentVariablesSelected) {
      const base = getDataVariables(variant, "column", 0);
      const forecast = getDataVariables(variant, "column", 1);
      currentSerie.push(base);
      currentSerie.push(forecast);

      const baseValues = base.data.map((x) => parseFloat(x));
      const forecastValues = forecast.data.map((x) => parseFloat(x));
      const averageValues =
        (Math.max(...baseValues) + Math.max(...forecastValues)) / 2;

      let maxValueTick = 0;
      let minValueTick = 0;

      switch (variant) {
        case "som":
          minValueTick = averageValues / 2;
          maxValueTick = 100;
          break;
        case "distribution":
          minValueTick = averageValues - averageValues / 8;
          maxValueTick = 100;
          break;
        default:
          minValueTick = averageValues / 2;
          maxValueTick =
            Math.max(...baseValues.concat(forecastValues)) + averageValues / 16;
          break;
      }

      currentAxisY.push({
        show: true,
        showAlways: true,
        seriesName: `${variant}Base`,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: tempVariables[`${variant}Forecast`].color,
        },
        labels: {
          style: {
            colors: tempVariables[`${variant}Forecast`].color,
          },
        },
        min: minValueTick,
        max: maxValueTick,
        title: {
          text: t(`price_management.trend.${variant}`),
          style: {
            color: tempVariables[`${variant}Forecast`].color,
          },
        },
      });
      currentAxisY.push({
        show: false,
        seriesName: `${variant}Forecast`,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: tempVariables[`${variant}Forecast`].color,
        },
        labels: {
          style: {
            colors: tempVariables[`${variant}Forecast`].color,
          },
        },
        min: minValueTick,
        max: maxValueTick,
        title: {
          text: t(`price_management.trend.${variant}`),
          style: {
            color: tempVariables[`${variant}Forecast`].color,
          },
        },
      });
    }
    const priceBase = getDataVariables("price", "line", 0);
    const priceForecast = getDataVariables("price", "line", 1);

    const priceBaseValues = priceBase.data.map((x) => parseFloat(x));
    const priceForecastValues = priceForecast.data.map((x) => parseFloat(x));

    const averagePriceValues =
      (Math.max(...priceBaseValues) + Math.max(...priceForecastValues)) / 2;
    const maxPriceValueTick =
      Math.max(...priceBaseValues.concat(priceForecastValues)) +
      averagePriceValues * 0.1;
    const minPriceValueTick = 0;

    currentSerie.push(priceBase);
    currentSerie.push(priceForecast);
    currentAxisY.push({
      show: true,
      showAlways: true,
      seriesName: "priceBase",
      opposite: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: tempVariables[`priceForecast`].color,
      },
      labels: {
        style: {
          colors: tempVariables[`priceForecast`].color,
        },
      },
      min: minPriceValueTick,
      max: maxPriceValueTick,
      title: {
        text: t(`price_management.trend.price`),
        style: {
          color: tempVariables[`priceForecast`].color,
        },
      },
    });

    currentAxisY.push({
      show: visibleVariables.includes(`priceForecast`),
      seriesName: "priceForecast",
      opposite: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: tempVariables[`priceForecast`].color,
      },
      labels: {
        style: {
          colors: tempVariables[`priceForecast`].color,
        },
      },
      min: minPriceValueTick,
      max: maxPriceValueTick,
      title: {
        text: t(`price_management.trend.price`),
        style: {
          color: tempVariables[`priceForecast`].color,
        },
      },
    });
  }
  let serieColors = [];

  currentVariablesSelected &&
    currentVariablesSelected.forEach((variant) => {
      serieColors.push(`${tempVariables[`${variant}Base`].color}`);
      serieColors.push(`${tempVariables[`${variant}Forecast`].color}`);
    });
  serieColors.push(`${tempVariables[`priceBase`].color}`);
  serieColors.push(`${tempVariables[`priceForecast`].color}`);
  let optionGraphChart = {
    series: currentSerie,
    options: {
      colors: [
        function ({ value, seriesIndex, w }) {
          if (serieColors && serieColors.length > 0) {
            return serieColors[seriesIndex];
          } else {
            return "#6FD1B0";
          }
        },
      ],
      chart: {
        type: "line",
        background: "white",
        stacked: false,

        events: {
          legendClick: handleClickLegend,
        },
        toolbar: {
          enabled: false,
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1, 4],
      },

      xaxis: {
        categories: times,
      },
      yaxis: currentAxisY,

      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
      },
      legend: {
        show: true,
      },
      zoom: {
        enabled: false,
      },
    },
  };

  const getGraphStyle = (variant) => {
    return variant === "fullScreen"
      ? {
          display: "flex",
          flexDirection: "column",
          width: "90%",
          height: "90%",
          backgroundColor: "#fff",
          padding: "1rem",
        }
      : {
          display: "flex",
          flexDirection: "column",
          width: "100%",
        };
  };

  return (
    <div ref={ref} style={getGraphStyle(size)}>
      <Chart
        options={optionGraphChart.options}
        series={optionGraphChart.series}
        height={size === "fullScreen" ? "100%" : "500"}
        width={size === "fullScreen" ? "100%" : "100%"}
      />
    </div>
  );
};

export default Graph;
