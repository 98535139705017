import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import FormControl from "../../../../Components/FormControl";
import { InputLabel, Chip, Select } from "@mui/material/";

const LevelAffects = ({
  items,
  nameSelected,
  influencesFor,
  handleItemSelected,
}) => {
  const [t, i18n] = useTranslation("global");

  return (
    <>
      <FormControl variant="secondary">
        <InputLabel>
          {t("initial_setup.forms.common_inputs." + nameSelected)}
        </InputLabel>
        <Select
          label={t("initial_setup.forms.common_inputs." + nameSelected)}
          required
          multiple
          value={influencesFor}
          onChange={handleItemSelected}
          renderValue={(currentIds) => {
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {currentIds.map((id, index) => (
                  <Chip
                    key={index}
                    label={items.find((x) => x.id === id)?.name}
                  />
                ))}
              </Box>
            );
          }}
        >
          {items?.map((option, index) => {
            return (
              <MenuItem key={index} value={option.id}>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default LevelAffects;
