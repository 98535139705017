import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";

import Item from "./Item";
import { height } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  containerCategory: {
    backgroundColor: theme.palette.custom.primary,
    display: "flex",
    alignItems: "center",
    width: "auto",
    minWidth: "250px",
    height: "auto",
    minHeight: "4em",
    justifyContent: "center",
  },
  containerSubcategory: {
    backgroundColor: theme.palette.custom.background,
    display: "flex",
    alignItems: "center",
    width: "auto",
    minWidth: "250px",
    height: "auto",
    minHeight: "4em",
    paddingRight: "20px",
    paddingLeft: "20px",
    justifyContent: "flex-start",
  },
  containerBrand: {
    backgroundColor: theme.palette.custom.background,
    display: "flex",
    alignItems: "center",
    width: "auto",
    height: "auto",
    minHeight: "4em",
    minWidth: "250px",
    paddingRight: "20px",
    paddingLeft: "20px",
    justifyContent: "flex-start",
  },
  containerSku: {
    backgroundColor: theme.palette.custom.background,
    display: "flex",
    alignItems: "center",
    width: "auto",
    height: "auto",
    paddingRight: "20px",
    paddingLeft: "20px",
    minWidth: "400px",
    maxWidth: "400px",
    justifyContent: "flex-start",
    marginTop: "10px",
  },
}));

const ItemChart = ({
  id,
  style,
  title = "",
  variant = "",
  groupers = [],
  vars = [],
  data = null,
}) => {
  const classes = useStyles({ color: data.grouper ? data.grouper.color : "" });

  const getVariantContent = () => {
    switch (variant) {
      case "category":
        return (
          <div
            id={id}
            className={classes.containerCategory}
            style={{ ...style }}
          >
            <Item key={data.id} title={data.name} color={data.color} />
          </div>
        );
      case "subcategory":
        return (
          <div
            id={id}
            className={classes.containerSubcategory}
            style={{ ...style, borderLeft: `10px solid ${data.color}` }}
          >
            <Item
              key={data.id}
              title={data.name}
              subtitle={data.description}
              color={data.color}
            />
          </div>
        );
      case "brand":
        return (
          <div
            id={id}
            className={classes.containerBrand}
            style={{ ...style, borderLeft: `10px solid ${data.color}` }}
          >
            <Item
              key={data.id}
              title={data.owner_id[1]}
              subtitle={data.name}
              color={data.color}
            />
          </div>
        );

      case "sku":
        return (
          <div id={id} className={classes.containerSku} style={{ ...style }}>
            <Item
              key={data.id}
              title={data.name}
              subtitle={`${data.owner_id[1]}/${data.brand_id[1]}`}
              description={`${data.unit_weight} ${
                data.uom_id && data.uom_id[1]
              }/${data.package}`}
              color={data.color}
              imagen={data.image}
              type="sku"
            />
          </div>
        );
      // case "sku":
      //   return (
      //     <>
      //       <div style={{ postition: "relative", width: "100%" }}>
      //         <div className={classes.borderColor} />
      //         <div style={{ display: "flex" }}>
      //           <div style={{ textTransform: "capitalize" }}>
      //             {data.grouper.name}
      //           </div>{" "}
      //           /
      //           <div style={{ textTransform: "capitalize" }}>
      //             {data.grouper.maker}
      //           </div>
      //         </div>
      //         <div style={{ display: "flex" }}>
      //           <span style={{ textTransform: "capitalize" }}>
      //             {data.grouper.presentation}
      //           </span>
      //           <span style={{ fontWeight: "600" }}>
      //             {data.content} {data.unit} / {data.presentation}
      //           </span>
      //         </div>
      //       </div>
      //       <div className={classes.variablesContainer}>
      //         {vars.map((data, index) => (
      //           <Item key={index} color={data.color} />
      //         ))}
      //       </div>
      //     </>
      //   );

      case "grouper":
          return (
          <>
            <div
              className={classes.borderColor}
              style={{ backgroundColor: data.color }}
            />
            <div style={{ postition: "relative", width: "100%" }}>
              <div className={classes.borderColor} />
            </div>
            <div className={classes.description}>{data.maker}</div>
            <div className={classes.variablesContainerBottom}>
              {vars.map((data, index) => (
                <Item
                  key={index}
                  color={data.color}
                  style={{ marginRight: ".25em" }}
                />
              ))}
            </div>
          </>
        );
      default:
        return <React.Fragment />;
    }
  };

  return getVariantContent();
};

export default ItemChart;
