import moment from "moment";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../../api";
import { token } from "../../auth/selectors";
import {
  createPromotion,
  deletePromotion,
  getPromotions,
  getSimulatePromotions,
  updatePromotion,
} from "./actions";
import {
  getFormDataPromotions,
  getSimulationDataEnvPromotions,
  getSimulationDataPromotions,
} from "./selectors";

function* getPromotionsSaga() {
  try {
    yield put(getPromotions.request());
    const tokenAccess = yield select(token);

    const response = yield call(
      callApiAxios,
      "GET",
      `pricing.promotion`,
      tokenAccess,
      {},
      {}
    );
    const promotions = response.data;
    if (promotions.length > 0) {
      const promotionsIds = promotions.map((x) => x.id);
      const body = { ids: promotionsIds };
      const responseData = yield call(
        callApiAxios,
        "POST",
        `pricing.promotion/get_base_data`,
        tokenAccess,
        {},
        body
      );
      yield put(
        getPromotions.success({
          data: responseData.data,
          promotions: promotions,
        })
      );
    } else {
      yield put(
        getPromotions.success({
          data: [],
          promotions: promotions,
        })
      );
    }
  } catch (e) {
    yield put(
      getPromotions.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getPromotions.fulfill());
  }
}
function* createPromotionSaga() {
  try {
    yield put(createPromotion.request());
    const tokenAccess = yield select(token);
    const form = yield select(getFormDataPromotions);
    let body = {
      name: form.name,
      description: form.description,
      category_id: form.categoryId,
      start_date: moment(form.startPeriod).format("YYYY-MM-DD"),
      end_date: moment(form.endPeriod).format("YYYY-MM-DD"),
      filters: {
        customers: form.customerIds,
        skus: form.skuIds,
      },
    };
    const response = yield call(
      callApiAxios,
      "POST",
      `pricing.promotion`,
      tokenAccess,
      {},
      body
    );
    yield put(createPromotion.success(response.data));
  } catch (e) {
    yield put(
      createPromotion.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(createPromotion.fulfill());
  }
}

function* updatePromotionSaga({ payload }) {
  try {
    yield put(updatePromotion.request());
    const tokenAccess = yield select(token);
    const form = yield select(getFormDataPromotions);
    let body = {
      name: form.name,
      description: form.description,
      category_id: form.categoryId,
      start_date: moment(form.startPeriod).format("YYYY-MM-DD"),
      end_date: moment(form.endPeriod).format("YYYY-MM-DD"),
      filters: {
        customers: form.customerIds,
        skus: form.skuIds,
      },
    };
    const response = yield call(
      callApiAxios,
      "PUT",
      `pricing.promotion/${form.id}`,
      tokenAccess,
      {},
      body
    );
    yield put(updatePromotion.success());
  } catch (e) {
    yield put(
      updatePromotion.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(updatePromotion.fulfill());
  }
}
function* deletePromotionSaga({ payload }) {
  try {
    yield put(deletePromotion.request());
    const tokenAccess = yield select(token);
    const response = yield call(
      callApiAxios,
      "DELETE",
      `pricing.promotion/${payload.id}`,
      tokenAccess,
      {},
      {}
    );
    yield put(deletePromotion.success());
  } catch (e) {
    yield put(
      deletePromotion.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(deletePromotion.fulfill());
  }
}

function* getSimulatePromotionsSaga({ payload }) {
  try {
    yield put(getSimulatePromotions.request());
    const tokenAccess = yield select(token);
    const dataSimulationEnv = yield select(getSimulationDataEnvPromotions);
    let body = {};
    let isSimulating = false;
    const types = ["env", "base", "promo"];
    
    const formatData = (data) => {
      let newData = [];
      for (let index = 0; index < data.length; index++) {
        const obj = data[index];
        let promotionExist = newData.find((x) => x.code === obj.promotionId);
        if (promotionExist) {
          let customerExist = promotionExist.items.find(
            (x) => x.code === obj.customerId
            );
            if (customerExist) {
              let skuExist = customerExist.items.find(
              (x) => x.code === obj.skuId
            );
            if (!skuExist) {
              customerExist.items.push({ code: obj.skuId, value: obj.value });
            }
          } else {
            promotionExist.items.push({
              code: obj.customerId,
              items: [{ code: obj.skuId, value: obj.value }],
            });
          }
        } else {
          newData.push({
            code: obj.promotionId,
            items: [
              {
                code: obj.customerId,
                items: [{ code: obj.skuId, value: obj.value }],
              },
            ],
          });
        }
      }
      return newData;
    };
    
    let dataFormatted = {
      env: dataSimulationEnv,
      base: formatData(payload.base),
      promo: formatData(payload.promo),
    };
    body.promotions = [];
    const getCustomType = (type) => {
      return type === "env" ? "values" : type + "_prices";
    };
    for (const type of types) {
      if (dataFormatted[type].length > 0) {
        let data = {};
        for (const item of dataFormatted[type]) {
          let items = {};
          if (type === "env") {
            for (const obj of item.items) {
              items[`${obj.code}`] = parseFloat(obj.value);
            }
            data[`${item.code}`] = items;
          } else {
            if (type === "promo") {
              body.promotions.push(item.code);
            }
            for (const customer of item.items) {
              let itemsSku = {};
              for (const sku of customer.items) {
                itemsSku[`${sku.code}`] = parseFloat(sku.value);
              }
              items[`${customer.code}`] = itemsSku;
            }
            data[`${item.code}`] = items;
          }
          let retype = getCustomType(type);
          body[`${retype}`] = data;
          isSimulating = true;
        }
      }
    }
    let response = { data: [] };
    if (isSimulating) {
      const url = `pricing.promotion.collection/simulate`;
      response = yield call(callApiAxios, "POST", url, tokenAccess, {}, body);
    }

    yield put(getSimulatePromotions.success(response.data));
  } catch (e) {
    yield put(
      getSimulatePromotions.failure({
        error: e ? String(e.message) : "Error de conexión",
      })
    );
  } finally {
    yield put(getSimulatePromotions.fulfill());
  }
}

export default function* promotionsSaga() {
  yield takeEvery(createPromotion.TRIGGER, createPromotionSaga);
  yield takeEvery(updatePromotion.TRIGGER, updatePromotionSaga);
  yield takeEvery(deletePromotion.TRIGGER, deletePromotionSaga);
  yield takeEvery(createPromotion.SUCCESS, getPromotionsSaga);
  yield takeEvery(updatePromotion.SUCCESS, getPromotionsSaga);
  yield takeEvery(deletePromotion.SUCCESS, getPromotionsSaga);
  yield takeEvery(getPromotions.TRIGGER, getPromotionsSaga);
  yield takeEvery(getSimulatePromotions.TRIGGER, getSimulatePromotionsSaga);
}
