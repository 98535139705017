import * as type from "./types";
import { getLoadOwners } from "./actions";
import { initialState } from "./states";
export default function owners(state = initialState, action) {
  switch (action.type) {
    case type.CREATE_OWNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.CREATE_OWNER_SUCCESS:
      return {
        ...state,
        owners: [...state.owners, action.owner],
        loading: false,
        response: {
          message: "initial_setup.messages.create_owner_success",
          error: null,
        },
      };
    case type.CREATE_OWNER_FAILURE:
      return {
        ...state,
        loading: false,
        response: { message: null, error: action.error },
      };
    case getLoadOwners.TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case getLoadOwners.REQUEST:
      return state;
    case getLoadOwners.SUCCESS:
      return {
        ...state,
        loading: false,
        owners: action.payload.owners,
      };
    case getLoadOwners.FAILURE:
      return {
        ...state,
      };
    case getLoadOwners.FULFILL:
      return {
        ...state,
        loading: false,
      };
    case type.UPDATE_OWNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.UPDATE_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {
          message: "initial_setup.messages.update_owner_success",
          error: null,
        },
      };
    case type.UPDATE_OWNER_FAILURE:
      return {
        ...state,
        loading: false,
        response: { error: null, message: action.error },
      };
    case type.DELETE_OWNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {
          message: "initial_setup.messages.delete_owner_success",
          error: null,
        },
      };
    case type.DELETE_OWNER_FAILURE:
      return {
        ...state,
        loading: false,
        response: { error: null, message: action.error },
      };
    case type.CLEAR_RESPONSE_OWNER:
      return {
        ...state,
        response: { error: null, message: null },
      };

    default:
      return state;
  }
}
