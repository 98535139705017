import React, { useState } from "react";
import ColorPicker from "../../../Components/ColorPicker";
import { useTranslation } from "react-i18next";
import { Typography, Box } from "@mui/material/";
import Button from "../../../Components/Button/index";
import { SaveIcon } from "../../../Components/CustomIcons";
import LayoutTheme from "./LayoutTheme";
import { useDispatch } from "react-redux";
import { resetColors } from "../../../Redux/modules/app/actions";
import { initialState } from "../../../Redux/modules/app/states";
const CustomizeTheme = ({
  handleSaveColors,
  handleUpdateColors,
  colors,
  logo,
}) => {
  const [t, i18n] = useTranslation("global");
  const [newColors, setNewColors] = useState(colors);
  const dispatch = useDispatch();
  const handleChangeColor = (e) => {
    setNewColors({ ...newColors, [e.target.name]: e.target.value });
    handleUpdateColors({ ...newColors, [e.target.name]: e.target.value });
  };
  const handleCancelColor = () => {
    setNewColors({ ...colors });
    handleUpdateColors({ ...colors });
  };
  const handleResetColors = () => {
    setNewColors(initialState.colors);
    handleSaveColors(initialState.colors)
  };
  const getColors = (items) => {
    let content = [];
    let index = 0;
    for (const prop in items) {
      if (index == 6) break;
      content.push(
        <ColorPicker
          showLabel={false}
          value={items[prop]}
          name={prop}
          onChangeComplete={handleChangeColor}
        />
      );
      index++;
    }
    return content;
  };

  const dynamicColorPickers = getColors(newColors);

  return (
    <div>
      <Typography>{t("settings_component.customize_platform")}</Typography>
      <div className="theme-content">
        <div className="save-colors">
          <div>
            <Typography
              variant="subtitle2"
              fontWeight={550}
              align="center"
              mb={1}
            >
              {t("settings_component.color_selector")}
            </Typography>
            <div className="colors-content">{dynamicColorPickers}</div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
               alignItems: "center",
            }}
          >
            <Button variant="gray" onClick={() => handleResetColors()}>
              {t("components_globals.button_reset")}
            </Button>
            <Button variant="gray" onClick={() => handleCancelColor()}>
              {t("components_globals.button_cancel")}
            </Button>
            <Button
              variant="primaryVariant"
              onClick={() => handleSaveColors(newColors)}
              startIcon={<SaveIcon />}
            >
              {t("components_globals.button_save").toUpperCase()}
            </Button>
          </div>
        </div>
        <div className="layout">
          <LayoutTheme colors={newColors} logo={logo} />
        </div>
      </div>
    </div>
  );
};

export default CustomizeTheme;
