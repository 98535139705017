import { style } from "@mui/system";
import React from "react";
import styled from "styled-components";
import ButtonItemColumn from "../Button/ButtonItemColumn";
import { EditIcon, DeleteIcon } from "../CustomIcons";

const RoundedRowWrapper = styled.div`
  width: 100%;
  border-radius: 50em;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0.5em;
  margin-top: 0.6em;
  .color{
    border-radius: 50%;
  }
`;

const ItemRowWithOptions = ({
  index,
  children,
  buttons,
  withCommonButtons,
  onItemEdit,
  tooltipTitleEdit,
  onItemDelete,
  tooltipTitleDelete,
  columnsNumber,
  customStyle
}) => {
  let commonButtons = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ButtonItemColumn
        onClick={onItemEdit}
        tooltip={tooltipTitleEdit}
        icon={<EditIcon className="iconButton" />}
      ></ButtonItemColumn>
      <ButtonItemColumn
        onClick={onItemDelete}
        tooltip={tooltipTitleDelete}
        icon={<DeleteIcon className="iconButton" />}
      ></ButtonItemColumn>
    </div>
  );
  return (
    <RoundedRowWrapper style={{...customStyle, gridTemplateColumns:
      "repeat(" + (columnsNumber?columnsNumber:4) + ", 1fr)"}} className="divRow">
      {children}
      <div>
        {buttons && buttons}
        {withCommonButtons && commonButtons}
      </div>
    </RoundedRowWrapper>
  );
};

export default ItemRowWithOptions;
