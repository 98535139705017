import { createAction } from "redux-actions";
import { createRoutine } from "redux-saga-routines";
import {
  SET_FORM_DATA_SIMULATION,
  GET_LOAD_CURVES,
  CLEAR_RESPONSE_CURVES,
  GET_SIMULATE_CURVES,
  SET_SIMULATOR_DATA,
  CLEAR_CURVES_SIMULATION,
  CLEAR_CURVES_SIMULATION_DATA,
  SET_DEFAULT_FORM_DATA_CURVES,
  SET_TYPE_FUNCTION,
  SET_AXIS_GRAPH,
} from "./types";

export const getLoadCurves = createRoutine(GET_LOAD_CURVES);

export const getSimulateCurves = createRoutine(GET_SIMULATE_CURVES);

export const setFormDataSimulation = createAction(SET_FORM_DATA_SIMULATION);

export const setSimulatorData = createAction(SET_SIMULATOR_DATA);

export const setFunctiontype = createAction(SET_TYPE_FUNCTION);

export const setAxisGraph = createAction(SET_AXIS_GRAPH);

export const clearCurvesSimulation = createAction(CLEAR_CURVES_SIMULATION);

export const clearCurvesSimulationData = createAction(
  CLEAR_CURVES_SIMULATION_DATA
);

export const clearResponseCurves = createAction(CLEAR_RESPONSE_CURVES);

export const setDefaultFormDataCurves = createAction(
  SET_DEFAULT_FORM_DATA_CURVES
);
