import { Grid } from "@mui/material/";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Components/Button";
import MonthPicker from "../../../../Components/MonthPicker";
import TextField from "../../../../Components/TextField";
import { useCategorySelected } from "../../../../Hooks/useCategorySelected";
import { getMinMaxDateCategorySelected } from "../../../../Redux/modules/categories/selectors";
import {
  getLoadTrend,
  setFormDataTrend,
} from "../../../../Redux/modules/what-if/trend/actions";
import { getFormDataTrend } from "../../../../Redux/modules/what-if/trend/selectors";
import { monthDiff } from "../../../../Utils";

const FormTrend = () => {
  const dispatch = useDispatch();

  const [t, i18n] = useTranslation("global");
  const tempForm = useSelector(getFormDataTrend);
  const { maxDate, minDate } = useSelector(getMinMaxDateCategorySelected);
  const categorySelected = useCategorySelected({
    subcategoryId: tempForm.subcategoryId,
  });
  const { skus, subcategory, subcategories } = categorySelected;

  return categorySelected.id ? (
    <Grid
      container
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        alignItems: "center",
        gap: "0.5rem",
        marginBottom: "1rem",
      }}
    >
      <TextField
        fullWidth
        select
        variant="third"
        label={t("initial_setup.forms.common_inputs.subcategory")}
        value={tempForm?.subcategoryId}
        onChange={(e) => {
          const subcategorySelected =
            subcategories && subcategories.find((x) => x.id === e.target.value);
          dispatch(
            setFormDataTrend({
              subcategory,
              from: "form",
              changes: [
                {
                  propertyName: "subcategoryId",
                  propertyValue: e.target.value,
                },
                {
                  propertyName: "skuId",
                  propertyValue: subcategorySelected?.sku_ids[0],
                },
                {
                  propertyName: "skuIdGraph",
                  propertyValue: subcategorySelected?.sku_ids[0],
                },
              ],
            })
          );
        }}
      >
        {subcategories &&
          subcategories.map((option, index) => {
            return (
              <MenuItem key={index} value={option.id}>
                {option.name}
              </MenuItem>
            );
          })}
      </TextField>

      <MonthPicker
        label={t("price_management.trend.start_date")}
        views={["year", "month"]}
        value={
          tempForm?.startPeriod > maxDate ? minDate : tempForm?.startPeriod
        }
        onChange={(e) => {
          const month = 24 - monthDiff(e, maxDate);
          dispatch(
            setFormDataTrend({
              propertyName: "startMonth",
              propertyValue: month,
            })
          );
          dispatch(
            setFormDataTrend({
              propertyName: "startPeriod",
              propertyValue: e,
            })
          );
        }}
        maxDate={maxDate}
        minDate={minDate}
        renderInput={(params) => <TextField variant="third" {...params} />}
      />
      <MonthPicker
        label={t("price_management.trend.final_date")}
        views={["year", "month"]}
        value={tempForm?.endPeriod > maxDate ? maxDate : tempForm?.endPeriod}
        onChange={(e) => {
          const month = 24 - monthDiff(e, maxDate);
          dispatch(
            setFormDataTrend({
              propertyName: "endMonth",
              propertyValue: month,
            })
          );
          dispatch(
            setFormDataTrend({
              propertyName: "endPeriod",
              propertyValue: e,
            })
          );
        }}
        maxDate={maxDate}
        minDate={minDate}
        renderInput={(params) => <TextField variant="third" {...params} />}
      />
      <Button
        onClick={() => {
          dispatch(getLoadTrend.trigger());
        }}
      >
        {t("components_globals.button_run")}
      </Button>
    </Grid>
  ) : (
    <></>
  );
};

export default FormTrend;
