//CREATE BRAND
export const CREATE_BRAND_REQUEST = "CREATE_BRAND_REQUEST";
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS";
export const CREATE_BRAND_FAILURE = "CREATE_BRAND_FAILURE";
//GET BRAND
export const GET_LOAD_BRANDS = "GET_LOAD_BRANDS";

//UPDATE BRAND
export const UPDATE_BRAND_REQUEST = "UPDATE_BRAND_REQUEST";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_FAILURE = "UPDATE_BRAND_FAILURE";
//DELETE BRAND
export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILURE = "DELETE_BRAND_FAILURE";
//CLEAR STATE MESSAGE SUCCESS OR FAILURE 
export const CLEAR_DATA_BRAND = "CLEAR_DATA_BRAND";
export const CLEAR_RESPONSE_BRAND = "CLEAR_RESPONSE_BRAND";

