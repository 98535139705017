import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ItemRowWithOptions from "../../../../Components/Table/ItemRowWithOptions";
import ItemColumn from "../../../../Components/Table/ItemColumn";
import CommonStepList from "../../CommonStepList";
import FormBrand from "./FormBrand";
import { CustomModal } from "../../../../Components/Modal";
import { Typography } from "@mui/material";

export default function ListBrands({ currentOwners, brands, onBrandUpdated, onBrandDeleted }) {
  const [t, i18n] = useTranslation("global");
  const [currentBrand, setCurrentBrand] = useState(null);
  const [searchBrand, setSearchBrand] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [configModal, setConfigModal] = useState({
    title: "",
    type: "edit",
  });

  const onItemBrandEdit = (brand) => {
    setCurrentBrand(brand);
    setOpenModal(!openModal);
    setConfigModal({
      title: t("initial_setup.modals.edit_brand"),
      type: "edit",
      cancelText: t("components_globals.button_cancel"),
      acceptText: t("components_globals.button_update"),
    });
  };
  const onItemBrandDelete = (brand) => {
    setCurrentBrand(brand);
    setOpenModal(!openModal);
    setConfigModal({
      title: 
      <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
      >
        {`${t("initial_setup.modals.delete_brand")} `}
        <Typography variant="body" fontWeight={600}>
          {brand.name}
          </Typography>
        </div>,
      type: "delete",
      cancelText: t("components_globals.button_cancel"),
      acceptText: t("components_globals.button_ok"),
    });
  };

  const onChangeSearchBrand = (filter) => {
    if (filter) {
      setSearchBrand(filter);
    } else {
      setSearchBrand("");
    }
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
    setCurrentBrand(null);
  };

  const handleCancelModal = () => {
    setOpenModal(!openModal);
    setCurrentBrand(null);
  };

  const handleOkModal = () => {
    switch (configModal.type) {
      case "edit":
        onBrandUpdated(currentBrand);
        break;

      case "delete":
        onBrandDeleted(currentBrand);
        break;
    }
    setOpenModal(!openModal);
  };

  const filterBrands = (filter) => {
    if (filter) {
      return brands.filter(
        (brand) =>
          brand.name.toUpperCase().indexOf(filter.toUpperCase()) != -1 ||
          brand.description.toUpperCase().indexOf(filter.toUpperCase()) != -1
      );
    } else {
      return brands;
    }
  };

  const brandsFiltered = filterBrands(searchBrand);

  const columnNames = [t("initial_setup.forms.common_inputs.name"), t("initial_setup.forms.common_inputs.owner"), t("initial_setup.forms.common_inputs.color"), t("initial_setup.forms.common_inputs.options")];
  const listBrandItems = brandsFiltered.map((brand, index) => {
    return (
      <ItemRowWithOptions
        key={index}
        index={index}
        withCommonButtons={true}
        onItemEdit={() => onItemBrandEdit(brand)}
        tooltipTitleEdit={t("components_globals.button_edit")}
        onItemDelete={() => onItemBrandDelete(brand)}
        tooltipTitleDelete={t("components_globals.button_remove")}
      >
        <ItemColumn>{brand.name}</ItemColumn>
        <ItemColumn>{brand.owner_id[1]}</ItemColumn>
        <ItemColumn>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              className="color"
              style={{ backgroundColor: brand.color }}
            ></div>
          </div>
        </ItemColumn>
      </ItemRowWithOptions>
    );
  });
  return (
    <>
      <CommonStepList
        listItems={listBrandItems}
        onChangeSearchItem={onChangeSearchBrand}
        filterFn={filterBrands}
        columnNames={columnNames}
        paginationTitle={t("initial_setup.step_2.stage_2.title")}
      ></CommonStepList>
      <CustomModal
      customWidth='600px'
        title={configModal.title}
        type={configModal.type}
        open={openModal}
        onCloseModal={handleCloseModal}
        onCancelModal={handleCancelModal}
        onOkModal={handleOkModal}
        cancelText={configModal.cancelText}
        acceptText={configModal.acceptText}
      >
        {configModal.type === "edit" && (
          <FormBrand
            brand={currentBrand}
            currentOwners={currentOwners}
            setCurrentBrand={setCurrentBrand}
          ></FormBrand>
        )}
      </CustomModal>
    </>
  );
}
