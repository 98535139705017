import { styled } from "@mui/material/styles";
import { CommonPaper } from "../../../../Components/Paper";
import Stack from "@mui/material/Stack";

export const PaperModal = styled(CommonPaper)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  padding: "2em 4em",
  minWidth: "30%",
}));

export const CloseIconWrapper = styled("span")(({ theme }) => ({
  position: "absolute",
  right: "-1em",
  top: "0em",
  cursor: "pointer",
  "& .close-icon": {
    path: {
      stroke: theme.palette.custom.secondary + " !important",
    },
  },
}));
export const RoundedButton = styled("div")(({ theme, backgroundColor }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "20px",
  cursor: "pointer",
  padding: "3px",
  stroke: `${theme.palette.custom.secondary}`,
  backgroundColor: `${theme.palette.custom[backgroundColor]}`,
}));
export const SaveIconWrapper = styled("span")(({ theme }) => ({
  "& .save-icon": {
    path: {
      stroke: theme.palette.custom.primary + " !important",
    },
    fontSize: "2em",
  },
}));

export const ActionMenuWrapper = styled(Stack)(({ theme }) => ({
  marginTop: "2em",
}));

export const PLWrapper = styled("div")(({ theme }) => ({
  ".delta-green, .delta-red": {
    fontWeight: "bold",
  },
  ".delta-green": {
    color: "#046434",
  },
  ".delta-red": {
    color: "#980718",
  },
}));
