import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const CssButtonPrimary = styled(Button)(({ theme }) => ({
  stroke: theme.palette.custom.primary,
  color: theme.palette.custom.primary,
  backgroundColor: theme.palette.custom.secondary,
  borderRadius: "2em",
  // height:'4em',
  transition: "filter .25s",
  "&:hover": {
    stroke: theme.palette.custom.secondary,
    backgroundColor: theme.palette.custom.secondary,
    filter: "brightness(1.15)",
  },
}));
const CssButtonSecundary = styled(Button)(({ theme }) => ({
  stroke: theme.palette.custom.primary,
  color: theme.palette.custom.primary,
  backgroundColor: theme.palette.custom.secondary,
  borderRadius: "2em",
  // height:'2em',
  fontSize: "10px",
  transition: "filter .25s",
  "&:hover": {
    stroke: theme.palette.custom.secondary,
    backgroundColor: theme.palette.custom.secondary,
    filter: "brightness(1.15)",
  },
}));

const CssButtonSecondary = styled(Button)(({ theme }) => ({
  stroke: theme.palette.custom.secondary,
  color: theme.palette.custom.secondary,
  backgroundColor: theme.palette.custom.primary,
  borderRadius: "2em",
  // height:'4em',
  textTransform: "capitalize",
  transition: "filter .25s",
  "&:hover": {
    stroke: theme.palette.custom.secondary,
    color: theme.palette.custom.secondary,
    backgroundColor: theme.palette.custom.primary,
    filter: "brightness(1.15)",
  },
}));

const CssButtonThird = styled(Button)(({ theme }) => ({
  stroke: theme.palette.custom.secondary,
  color: theme.palette.custom.secondary,
  backgroundColor: theme.palette.custom.third,
  borderRadius: "2em",
  // height:'4em',
  transition: "filter .25s",
  "&:hover": {
    stroke: theme.palette.custom.third,
    backgroundColor: theme.palette.custom.third,
    filter: "brightness(1.15)",
  },
}));

const CssButtonThirdVariant = styled(Button)(({ theme }) => ({
  stroke: theme.palette.custom.background,
  color: theme.palette.custom.background,
  backgroundColor: theme.palette.custom.thirdVariant,
  borderRadius: "2em",
  // height:'4em',
  transition: "filter .25s",
  "&:hover": {
    backgroundColor: theme.palette.custom.thirdVariant,
    filter: "brightness(1.15)",
  },
}));

const CssButtonDefault = styled(Button)(({ theme }) => ({
  stroke: theme.palette.custom.secondary,
  color: theme.palette.custom.secondary,
  backgroundColor: theme.palette.custom.text,
  borderRadius: "2em",
  // height:'4em',
  transition: "filter .25s",
  "&:hover": {
    stroke: theme.palette.custom.text,
    backgroundColor: theme.palette.custom.text,
    filter: "brightness(1.15)",
  },
}));

const CssButtonPrimaryVariant = styled(Button)(({ theme }) => ({
  stroke: theme.palette.custom.secondary,
  color: theme.palette.custom.secondary,
  backgroundColor: theme.palette.custom.primary,
  borderRadius: "2em",
  transition: "filter .25s",
  minWidth: "100px",
  textTransform: "capitalize",
  "&:hover": {
    stroke: theme.palette.custom.primary,
    backgroundColor: theme.palette.custom.secondary,
    color: theme.palette.custom.primary,
    filter: "brightness(1.15)",
  },
}));

const CustomButton = (props) => {
  const {
    children,
    style = {},
    variant = "primary",
    disabled = false,
    onClick = () => {},
    endIcon,
  } = props;
  const getVariant = (variant) => {
    switch (variant) {
      case "primary":
        return (
          <CssButtonPrimary
            variant={"contained"}
            fullWidth
            style={{ fontWeight: "500", ...style }}
            disabled={disabled}
            onClick={onClick}
            endIcon={endIcon}
            {...props}
          >
            {children}
          </CssButtonPrimary>
        );
      case "secondary":
        return (
          <CssButtonSecondary
            variant={"contained"}
            fullWidth
            style={{ fontWeight: "500", ...style }}
            disabled={disabled}
            onClick={onClick}
            endIcon={endIcon}
          >
            {children}
          </CssButtonSecondary>
        );
      case "third":
        return (
          <CssButtonThird
            variant={"contained"}
            fullWidth
            style={{ fontWeight: "500", ...style }}
            disabled={disabled}
            onClick={onClick}
            endIcon={endIcon}
          >
            {children}
          </CssButtonThird>
        );

      case "thirdVariant":
        return (
          <CssButtonThirdVariant
            {...props}
            variant={"contained"}
            style={{ fontWeight: "500", ...style }}
            disabled={disabled}
            onClick={onClick}
            endIcon={endIcon}
          >
            {children}
          </CssButtonThirdVariant>
        );

      case "gray":
        return (
          <CssButtonDefault
            variant={"contained"}
            fullWidth
            style={{ fontWeight: "500", ...style }}
            disabled={disabled}
            onClick={onClick}
            endIcon={endIcon}
          >
            {children}
          </CssButtonDefault>
        );

      case "primaryVariant":
        return (
          <CssButtonPrimaryVariant
            variant={"contained"}
            fullWidth
            style={{ fontWeight: "500", ...style }}
            disabled={disabled}
            onClick={onClick}
            {...props}
          >
            {children}
          </CssButtonPrimaryVariant>
        );

      default:
        return (
          <CssButtonSecondary
            variant={"contained"}
            fullWidth
            style={{ fontWeight: "500", ...style }}
            disabled={disabled}
            onClick={onClick}
            endIcon={endIcon}
          >
            {children}
          </CssButtonSecondary>
        );
    }
  };
  return getVariant(variant);
};

export default CustomButton;
