import { Avatar, TableContainer } from "@mui/material";
import { CustomTable } from "../../../../Components/CustomTable";
import { formatNumber, truncateNumber } from "../../../../Utils";
import { ExpandMore } from "@mui/icons-material";
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import SaveMenu from "../../../../Components/SaveMenu";
function Row({ id, data, isMain, customer, isExpanded, onExpandedRows }) {
  const getImage = () => {
    if (customer.id !== 0) {
      return customer.image ? (
        <img
          style={{ width: 120, height: 40, objectFit: "cover" }}
          src={"data:image/png;base64," + customer.image}
        />
      ) : (
        <>
          <Avatar
            sx={{
              borderRadius: "10px",
              width: 120,
              height: 40,
              bgcolor: "rgb(205, 202, 206)",
            }}
            className="client-avatar"
          >
            {customer.name.toUpperCase().charAt(0)}
          </Avatar>
        </>
      );
    } else {
      return (
        <Avatar
          sx={{
            borderRadius: "10px",
            width: 120,
            height: 40,
            bgcolor: "#fff",
          }}
          className="client-avatar"
        ></Avatar>
      );
    }
  };

  const getValue = (type, row) => {
    return formatNumber(truncateNumber(row[type]));
  };
  const customerName = `${customer.id !== 0 ? customer.name : "Autoservicios"}`;
  const skuName = `${data.name}`;
  const row = data.items;
  const classes = isMain ? "primary" : "";

  const handleExpandRow = (e) => {
    if (isMain) {
      onExpandedRows(id);
    }
  };
  return (
    <tr style={{ cursor: "pointer" }} onClick={handleExpandRow}>
      <td
        className={isMain && "customerImage"}
        customerName={customer.id !== 0 ? customer.name : ""}
      >
        <div
          style={{
            display: "grid",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isMain && getImage()}
        </div>
      </td>
      <td className={`${classes} joined-first title-row`}>
        {isMain && (
          <ExpandMore
            sx={{
              fontSize: "1rem",
              rotate: isExpanded ? "0deg" : "180deg",
            }}
          />
        )}
        {isMain ? "Total " + customerName : skuName}
      </td>

      <td className={`${classes}`}>{getValue("base_price", row)}</td>
      <td className={`${classes}`}>{getValue("base_qty", row)}</td>

      <td className={`${classes}`}>{getValue("promo_price", row)}</td>
      <td
        className={`
        ${classes} ${
          getValue("diff_price", row) > 0
            ? "delta-green"
            : getValue("diff_price", row) < 0
            ? "delta-red"
            : ""
        }
            `}
      >
        {getValue("diff_price", row)} %
      </td>
      <td className={`${classes}`}>{getValue("promo_qty", row)}</td>
      <td
        className={`
        ${classes} ${
          getValue("diff_qty", row) > 0
            ? "delta-green"
            : getValue("diff_qty", row) < 0
            ? "delta-red"
            : ""
        }
            `}
      >
        {getValue("diff_qty", row)} %
      </td>

      <td className={`${classes}`}>{getValue("base_gross_sale", row)}</td>
      <td className={`${classes}`}>{getValue("promo_gross_sale", row)}</td>
      <td
        className={`
        ${classes} ${
          getValue("diff_gross_sale", row) > 0
            ? "delta-green"
            : getValue("diff_gross_sale", row) < 0
            ? "delta-red"
            : ""
        }
          `}
      >
        {getValue("diff_gross_sale", row)} %
      </td>

      <td className={`${classes}`}>{getValue("base_commercial_cond", row)}</td>
      <td className={`${classes}`}>{getValue("promo_commercial_cond", row)}</td>
      <td
        className={`
        ${classes} ${
          getValue("diff_commercial_cond", row) > 0
            ? "delta-green"
            : getValue("diff_commercial_cond", row) < 0
            ? "delta-red"
            : ""
        }
        `}
      >
        {getValue("diff_commercial_cond", row)} %
      </td>

      <td className={`${classes}`}>{getValue("trade_spend", row)}</td>
      <td className={`${classes}`}>{getValue("base_net_sale", row)}</td>
      <td className={`${classes}`}>{getValue("promo_net_sale", row)}</td>
      <td
        className={`
        ${classes} ${
          getValue("diff_net_sale", row) > 0
            ? "delta-green"
            : getValue("diff_net_sale", row) < 0
            ? "delta-red"
            : ""
        }
        `}
      >
        {getValue("diff_net_sale", row)} %
      </td>

      <td className={`${classes}`}>{getValue("base_cogs", row)}</td>
      <td className={`${classes}`}>{getValue("promo_cogs", row)}</td>
      <td
        className={`
        ${classes} ${
          getValue("diff_cogs", row) > 0
            ? "delta-green"
            : getValue("diff_cogs", row) < 0
            ? "delta-red"
            : ""
        }
          `}
      >
        {getValue("diff_cogs", row)} %
      </td>
      <td className={`${classes}`}>{getValue("base_op", row)}</td>
      <td className={`${classes}`}>{getValue("promo_op", row)}</td>
      <td
        className={`
        ${classes} ${
          getValue("diff_op", row) > 0
            ? "delta-green"
            : getValue("diff_op", row) < 0
            ? "delta-red"
            : ""
        }
          `}
      >
        {getValue("diff_op", row)} %
      </td>

      <td className={`${classes}`}>{getValue("roi", row)} %</td>
      <td className={`${classes} joined-last`}>{getValue("uplift", row)}</td>
    </tr>
  );
}

function ItemRow({ id, customer, expandedAll }) {
  const [expandedRows, setExpandedRows] = useState([]);
  const isExpanded = (code) => {
    return expandedRows.includes(code);
  };
  const handleExpandRow = (code) => {
    if (!isExpanded(code)) {
      setExpandedRows([...expandedRows, code]);
    } else {
      const newList = expandedRows.filter((x) => x !== code);
      setExpandedRows(newList);
    }
  };
  const totalRow = customer.items.find((x) => x.id === 0);
  return (
    <>
      <Row
        id={id}
        onExpandedRows={handleExpandRow}
        isExpanded={isExpanded(id)}
        customer={customer}
        data={totalRow}
        isMain={true}
      ></Row>
      {(expandedAll || isExpanded(id)) &&
        customer.items
          .filter((x) => x.id !== 0)
          .map((sku, index) => (
            <Row
              id={id}
              key={`${id}-${index}`}
              customer={customer}
              data={sku}
              isMain={false}
            ></Row>
          ))}
    </>
  );
}

export default function TablePL({
  promotion,
  isExpanded,
  handleDownloadExcel,
}) {
  const [t, i18n] = useTranslation("global");
  const handleDownloadPdf = useReactToPrint({
    content: () => pdfRef.current,
    documentTitle: "pl",
    pageStyle: { with: "100%" },
  });

  const pdfRef = useRef(null);
  return (
    <TableContainer sx={{ maxHeight: 400 }}>
      <CustomTable
        className={`tableSimulation tableSimulation_${promotion.id}`}
        key={`${promotion.id}`}
        cellSpacing="0"
        cellPadding="0"
        ref={pdfRef}
        sx={{ maxHeight: 440, width: "100%" }}
      >
        <thead
          style={{
            zIndex: 100,
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
          }}
        >
          <tr>
            <th colSpan={2} rowSpan={2}>
              <div style={{ display: "flex" }}>
                <span className="title-promotion">{promotion.name}</span>
                <SaveMenu
                  style={{ marginRight: "32px" }}
                  buttons={["export"]}
                  list={["excel", "pdf"]}
                  handlePdf={handleDownloadPdf}
                  handleExcel={() => handleDownloadExcel(promotion.id)}
                />
              </div>
            </th>

            <th colSpan={2}>
              <span className="space-cell primary rounded">
                {t(
                  "sales_planning.promotion_planning_module.pl_component.table.base"
                )}
              </span>
            </th>

            <th colSpan={4}>
              <span className="space-cell secondary rounded">
                {t(
                  "sales_planning.promotion_planning_module.pl_component.table.promotion"
                )}
              </span>
            </th>

            <th colSpan={3}>
              <span className="space-cell rounded">
                {t(
                  "sales_planning.promotion_planning_module.pl_component.table.gross_sale"
                )}
              </span>
            </th>

            <th colSpan={3}>
              <span className="space-cell rounded">
                {t(
                  "sales_planning.promotion_planning_module.pl_component.table.comercial_cond"
                )}
              </span>
            </th>

            <th colSpan={1}>
              <span className="space-cell rounded">
                {t(
                  "sales_planning.promotion_planning_module.pl_component.table.trade_spend"
                )}
              </span>
            </th>
            <th colSpan={3}>
              <span className="space-cell rounded">
                {t(
                  "sales_planning.promotion_planning_module.pl_component.table.net_sale"
                )}
              </span>
            </th>
            <th colSpan={3}>
              <span className="space-cell rounded">
                {t(
                  "sales_planning.promotion_planning_module.pl_component.table.cogs"
                )}
              </span>
            </th>

            <th colSpan={3}>
              <span className="space-cell rounded">
                {t(
                  "sales_planning.promotion_planning_module.pl_component.table.op"
                )}
              </span>
            </th>

            <th colSpan={1}>
              <span className="space-cell rounded">
                {t(
                  "sales_planning.promotion_planning_module.pl_component.table.roi"
                )}
              </span>
            </th>

            <th colSpan={1}>
              <span className="space-cell rounded">
                {t(
                  "sales_planning.promotion_planning_module.pl_component.table.uplift"
                )}
              </span>
            </th>
          </tr>
          <tr>
            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.price"
              )}
            </th>
            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.volume"
              )}
            </th>

            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.price"
              )}
            </th>
            <th className="text-secondary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.delta"
              )}
            </th>
            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.volume"
              )}
            </th>
            <th className="text-secondary">
              {" "}
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.delta"
              )}
            </th>

            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.base"
              )}
            </th>
            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.promo"
              )}
            </th>
            <th className="text-secondary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.delta"
              )}
            </th>

            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.base"
              )}
            </th>
            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.promo"
              )}
            </th>
            <th className="text-secondary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.delta"
              )}
            </th>

            <th className="text-primary"></th>
            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.base"
              )}
            </th>
            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.promo"
              )}
            </th>
            <th className="text-secondary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.delta"
              )}
            </th>
            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.base"
              )}
            </th>
            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.promo"
              )}
            </th>
            <th className="text-secondary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.delta"
              )}
            </th>

            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.base"
              )}
            </th>
            <th className="text-primary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.promo"
              )}
            </th>
            <th className="text-secondary">
              {t(
                "sales_planning.promotion_planning_module.pl_component.table.delta"
              )}
            </th>

            <th className="text-primary"></th>

            <th className="text-primary"></th>
          </tr>
        </thead>
        <tbody>
          {promotion.items
            .sort((a, b) => a.id - b.id)
            .map((customer, index) => (
              <ItemRow
                expandedAll={isExpanded}
                id={`${promotion.id}-${customer.id}`}
                key={`${promotion.id}-${customer.id}-${index}`}
                customer={customer}
              ></ItemRow>
            ))}
        </tbody>
      </CustomTable>
    </TableContainer>
  );
}
