import { Menu, MenuItem } from "@mui/material/";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { createFileName, useScreenshot } from "use-react-screenshot";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import { DownloadIcon, SaveIcon } from "../../../../Components/CustomIcons";
import { exportToExcel, getMonthName } from "../../../../Utils";
import { getDateValues } from "../../../../Utils/commonWithDates";

import { styled } from "@mui/material/styles";
const CustomGrid = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.custom.background}`,
  display: "grid",
  width: "100%",
  height: "100%",
  gridTemplateColumns: "repeat(15,1fr)",
  gridTemplateRows: "repeat(10,40px)",
}));

const CellGrid = styled("div")(
  ({
    theme,
    fontWeight = 500,
    backgroundColor = "background",
    color = "black",
  }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "40px",
    fontWeight: fontWeight,
    fontSize: "0.8rem",
    backgroundColor: `${theme.palette.custom[backgroundColor]}`,
    color: `${theme.palette.custom[color]}`,
  })
);

export default function GridMomentum({
  name,
  id,
  rows,
  columns,
  children,
  rowNames,
  legend
}) {
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const [t, i18n] = useTranslation("global");
  const [anchorMenuDownload, setAnchorMenuDownload] = useState(null);
  const isOpenMenuDownload = Boolean(anchorMenuDownload);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpg",
    quality: 1.0,
  });

  const download = (image, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const handleDownloadExcel = () => {
    try {
      let trHead = "<tr>";
      for (let index = 0; index < columns.length; index++) {
        const colTitle = getDateValues(columns[index]);
        const col = `${t(`months_names.${getMonthName(colTitle.month - 1)}`)
          .substring(0, 3)
          .toUpperCase()}-${colTitle.year.toString().substring(2, 4)}`;
        trHead += `<th>${col}</th>`;
      }
      trHead += `</tr>`;
      let trBody = `<tr>`;
      for (let index = 0; index < columns.length; index++) {
        trBody += `<td>${rows[index][0]}</td>`;
      }
      trBody += `</tr><tr>`;
      for (let index = 0; index < columns.length; index++) {
        trBody += `<td>${rows[index][1]}</td>`;
      }
      trBody += `</tr>`;
      let excelTableHTML = `<table><thead>${trHead}</thead><tbody>${trBody}</tbody></table>`;
      let excelTable = document.createElement("table");
      excelTable.innerHTML = excelTableHTML;
      exportToExcel(name, excelTable);
    } catch (error) {}
  };

  const handleDownloadImage = () => {
    const downloadScreenshot = () =>
      takeScreenShot(gridRef.current).then(download);
    downloadScreenshot();
  };

  const handleOpenDownloadMenu = (event) => {
    setAnchorMenuDownload(event.currentTarget);
  };

  const handleCloseDownloadMenu = () => {
    setAnchorMenuDownload(null);
  };

  const buildValues = (values, valIndex) => {
    return values.map((val, index) => {
      return (
        <CellGrid
          fontWeight={valIndex === 0 ? 600 : 500}
          backgroundColor={valIndex === 0 ? "primary" : "background"}
          style={{
            gridColumn: index + 4,
            gridRow: 2 + valIndex,
            borderTopRightRadius:
              valIndex === 0 && index == 11 ? "10px" : "0px",
            borderBottomRightRadius:
              valIndex === 0 && index == 11 ? "10px" : "0px",
          }}
        >
          {val[valIndex]}
        </CellGrid>
      );
    });
  };

  const buildHighligths = (values) => {
    let items = values.concat([]);
    items.sort((a, b) => b - a);
    let highligths = [items[0], items[1]];
    return highligths.map((val, index) => {
      let indexOf = values.indexOf(val);
      return (
        <div
          style={{
            gridColumn: indexOf + 4,
            gridRowStart: 1,
            gridRowEnd: 4,
            backgroundColor: "#46937861",
            borderRadius: "10px",
            zIndex: 300,
          }}
        ></div>
      );
    });
  };

  const buildMonths = (months) => {
    return months.map((col, index) => {
      const colTitle = getDateValues(col);
      return (
        <CellGrid
        key={`grid-${name}-th-${index}`}
          style={{
            fontWeight: "500",
            fontSize: "0.8rem",
            color: "#407D68",
            gridColumn: index + 4,
            gridRow: 1,
          }}
        >
          {`${t(`months_names.${getMonthName(colTitle.month - 1)}`)
            .substring(0, 3)
            .toUpperCase()}-${colTitle.year.toString().substring(2, 4)}`}
        </CellGrid>
      );
    });
  };

  const buildAreas = (rows) => {
    let areas = [1, 2, 3, 4, 5];
    return areas.map((val, index) => (
      <div
        style={{
          gridColumn: val * 3,
          gridRowStart: 1,
          gridRowEnd: 10,
          borderRight: "1px solid #CDCACE",
          zIndex: 200,
        }}
      ></div>
    ));
  };

  return (
    <div style={{ padding: "1rem" }}>
      <CustomGrid className={`gridMomentum${name}`} ref={gridRef}>
        <CellGrid
          style={{
            display: "grid",
            gridColumnStart: "1",
            gridColumnEnd: "4",
            gridRowStart: "1",
            justifyContent: "start",
          }}
        >
          <div style={{ display: "flex" }}>
            <ButtonItemColumn
              onClick={() => {}}
              tooltip={t("components_globals.button_save")}
              icon={
                <SaveIcon
                  className="iconButtonPrimary strokeLigth"
                  sx={{ fontSize: "2rem" }}
                />
              }
            />
            <ButtonItemColumn
              onClick={handleOpenDownloadMenu}
              tooltip={t("components_globals.button_download")}
              icon={
                <DownloadIcon
                  sx={{ fontSize: "1.5rem" }}
                  className="iconButtonPrimary strokeLigth"
                />
              }
            />
            <Menu
              id={`download-menu-`}
              anchorEl={anchorMenuDownload}
              open={isOpenMenuDownload}
              onClose={handleCloseDownloadMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleDownloadImage}>Download Image</MenuItem>
              <MenuItem onClick={handleDownloadExcel}>Download Excel</MenuItem>
            </Menu>
          </div>
        </CellGrid>
        <CellGrid
          style={{
            gridColumnStart: "1",
            gridColumnEnd: "4",
            gridRowStart: "2",
            borderTopLeftRadius: "10px",
            borderBottomLeftRadius: "10px",
            justifyContent: "start",
          }}
          fontWeight={500}
          backgroundColor="primary"
        >
          {rowNames[0]}
        </CellGrid>
        <CellGrid
          style={{
            gridColumnStart: "1",
            gridColumnEnd: "4",
            gridRowStart: "3",
            justifyContent: "start",
          }}
          fontWeight={600}
          backgroundColor="background"
        >
          {rowNames[1]}
        </CellGrid>
        {buildMonths(columns)}
        {buildValues(rows, 0)}
        {buildValues(rows, 1)}
        {buildHighligths(rows.map((val) => val[2]))}
        {buildAreas()}
        <div
          style={{
            gridColumnStart: 4,
            gridColumnEnd: 16,
            gridRowStart: 4,
            gridRowEnd: 10,
          }}
        >
          {children}
        </div>
        <div
          style={{
            gridColumnStart: 4,
            gridColumnEnd: 16,
            gridRow:11
          }}
        >
                  
                
            {legend}
                </div>
      </CustomGrid>
    </div>
  );
}
