export const GET_LOAD_MOMENTUM = "GET_LOAD_MOMENTUM";
export const GET_SIMULATE_MOMENTUM = "GET_SIMULATE_MOMENTUM";
export const CLEAR_SIMULATE_MOMENTUM = "CLEAR_SIMULATE_MOMENTUM";
export const SET_FORM_DATA_MOMENTUM = "SET_FORM_DATA_MOMENTUM";
export const SET_DEFAULT_FORM_DATA_MOMENTUM = "SET_DEFAULT_FORM_DATA_MOMENTUM";
export const CLEAR_RESPONSE_MOMENTUM  = "CLEAR_RESPONSE_MOMENTUM";
export const SET_SIMULATION_DATA_MOMENTUM  = "SET_SIMULATION_DATA_MOMENTUM";



