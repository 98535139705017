import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import TextField from "../../../../Components/TextField";
import Button from "../../../../Components/Button";
import ColorPicker from "../../../../Components/ColorPicker";
import { SaveIcon, ClipIcon } from "../../../../Components/CustomIcons";
import { useDispatch, useSelector } from "react-redux";

import {
  Chip,
  Box,
  Autocomplete,
  Stack,
} from "@mui/material/";
import { styled } from "@mui/material/styles";

const FormOwner = ({
  owner,
  owners,
  onOwnerCreated,
  onOwnerUpdated,
  onOwnersAdded,
  setCurrentOwner,
}) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  const [isNew, setIsNew] = useState(true);
  const [addExisting, setAddExisting] = useState(false);
  const [tempExistOwners, setTempExistOwners] = useState([]);
  const [tempOwner, setTempOwner] = useState({
    id: 0,
    name: "",
    description: "",
    color: "#B2ADB4",
  });

  useEffect(() => {
    if (owner) {
      setIsNew(false);
      setTempOwner(owner);
    }
  }, []);

  const handleAssociateForm = (e) => {
    setAddExisting(!addExisting);
  };

  const isValidForm = () => {
    if (!tempOwner.name) {
      enqueueSnackbar("Error", { variant: "error" });
      return false;
    }
    return true;
  };

  const handleInputChanges = (newValue, type) => {
    setTempOwner({ ...tempOwner, [type]: newValue });
    if (!isNew) {
      setCurrentOwner({ ...tempOwner, [type]: newValue });
    }
  };

  const onSubmitOwner = () => {
    if (isValidForm()) {
      isNew ? onOwnerCreated(tempOwner) : onOwnerUpdated(tempOwner);
      onReset();
    }
  };

  const onReset = () => {
    setTempOwner({ name: "", description: "", color: "#B2ADB4" });
    setIsNew(true);
  };

  const handleCancelAssociate = () => {
    setAddExisting(!addExisting);
    setTempExistOwners([]);
  };
  const handleAssociateOwners = () => {
    setAddExisting(!addExisting);
    onOwnersAdded(tempExistOwners);
    };

  const CssAssociateLink = styled("span")(({ theme }) => ({
    color: theme.palette.custom.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.custom.thirdVariant,
    },
    ".chipExisting": {
      color: theme.palette.custom.background,
      backgroundColor: theme.palette.custom.thirdVariant,
    },
  }));

  return (
    <div
      style={{ display: "flex", flexDirection: "column", marginBottom: "1em" }}
    >
      {!addExisting ? (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              required
              variant="third"
              label={`${t("initial_setup.step_2.stage_1.form_name")} ${t(
                "initial_setup.forms.common_inputs.name"
              )}`}
              value={tempOwner.name}
              onChange={(e) => {
                handleInputChanges(e.target.value, "name");
              }}
            />

            <TextField
              fullWidth
              style={{ marginLeft: "20px" }}
              variant="third"
              label={`${t("initial_setup.forms.common_inputs.description")}`}
              value={tempOwner.description}
              onChange={(e) =>
                handleInputChanges(e.target.value, "description")
              }
            />

            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                fontWeight: "600",
                flexDirection: "column",
                margin: "0px 20px",
              }}
            >
              <span>{t("initial_setup.forms.common_inputs.color")}</span>
              <ColorPicker
                value={tempOwner.color}
                onChangeComplete={(e) =>
                  handleInputChanges(e.target.value, "color")
                }
              />
            </div>
            {isNew && (
              <Button
                fullWidth
                style={{ maxWidth: "150px" }}
                variant="thirdVariant"
                onClick={onSubmitOwner}
                disabled={!tempOwner.name }
              >
                <SaveIcon />
                <span style={{ paddingLeft: "5px" }}>
                  {t("components_globals.button_save")}
                </span>
              </Button>
            )}
          </div>
          {owners && owners.length > 0 && (
            <div style={{ marginTop: "0.5em", marginBottom: "1.5em" }}>
              <CssAssociateLink onClick={handleAssociateForm}>
                <Chip className="chipExisting" label={owners.length}></Chip>{" "}
                {t("initial_setup.step_2.stage_1.associate_owners")}
              </CssAssociateLink>
            </div>
          )}
        </>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Autocomplete
            style={{ width: "500px" }}
            value={tempExistOwners}
            onChange={(e, newValue) => setTempExistOwners(newValue)}
            multiple
            options={owners}
            getOptionLabel={(option) => option.name}
            freeSolo
            renderTags={(currentIds) => {
              return (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {currentIds.map((item, index) => (
                    <Chip
                      key={index}
                      label={owners.find((x) => x.id === item.id)?.name}
                    />
                  ))}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="third"
                label={t("initial_setup.forms.common_inputs.owners")}
                placeholder={t("components_globals.button_search")}
              />
            )}
          />
          <Stack
            gap={"1rem"}
            justifyContent={"end"}
            direction="row"
            mr={"5rem"}
          >
            <Button
              variant="thirdVariant"
              disabled={tempExistOwners.length === 0}
              style={{ width: "max-content", marginLeft: "0.5em" }}
              onClick={handleAssociateOwners}
            >
              <ClipIcon />
              {t("components_globals.button_associate")}
            </Button>
            <Button
              variant="gray"
              style={{
                width: "max-content",
                marginRight: "0.5em",
              }}
              onClick={handleCancelAssociate}
            >
              {t("components_globals.button_cancel")}
            </Button>
          </Stack>
        </div>
      )}
    </div>
  );
};

export default FormOwner;
