import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from "@mui/material";
import { TableBody } from "@mui/material/";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import { getMonthName } from "../../../../Utils";
import { getDateValues } from "../../../../Utils/commonWithDates";
import { ButtonsCell, CustomTable, TitleCell } from "./Styles";
import TemplateRow from "./TemplateRow";

const TableEnvironmentVariables = ({
  environmentVariables,
}) => {
  const dispatch = useDispatch();
  const table = useRef(null);
  const [t, i18n] = useTranslation("global");
  const columns = environmentVariables ? environmentVariables[0].values : [];
  const colSpan = columns?.length;
  const pageStyle = `
  @media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  }
`;
  const handlePdf = useReactToPrint({
    content: () => table.current,
    documentTitle: "Table-Simulation-",
    pageStyle: pageStyle,
  });

  const exportToExcel = () => {
    let [table] = document.getElementsByClassName("tableEnvironment");
    let today = new Date();
    try {
      let excelTable = document.createElement("table");
      excelTable.innerHTML = table.innerHTML.trim();
      let firstCell = excelTable.querySelector(".first-cell");
      firstCell.innerHTML = "";
      let collapses = excelTable.querySelectorAll(".collapse-tr");
      for (let index = 0; index < collapses.length; index++) {
        const collapse = collapses[index];
        let expandTr = collapse.previousSibling;
        const tbody = collapse.querySelector("tbody");
        if (tbody) {
          let inputs = tbody.querySelectorAll("input");
          for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            const parentTd = input.parentNode;
            parentTd.className = "";
            parentTd.innerHTML = input.value;
          }
          const rows = tbody.innerHTML;
          expandTr.insertAdjacentHTML("afterend", rows);
        }
        collapses[index].parentNode.removeChild(collapses[index]);
      }

      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.table_to_sheet(excelTable);

      XLSX.utils.book_append_sheet(wb, ws, "Table");
      XLSX.writeFile(
        wb,
        `export_table_trend_${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDay()}${today.getTime()}.xlsx`
      );
    } catch (error) {}
  };

  const handleDownloadExcel = () => {
    exportToExcel();
  };

  const buildHeaders = (cols) => {
    return (
      cols &&
      cols.map((col, index) => {
        const colTitle = getDateValues(col.date);
        return (
          <TitleCell minWidth="60px" color="thirdVariant" key={`th-${index}`}>
            <span style={{ fontWeight: "500", fontSize: "0.8rem" }}>
              {`${t(`months_names.${getMonthName(colTitle.month - 1)}`)
                .substring(0, 3)
                .toUpperCase()}-${colTitle.year.toString().substring(2, 4)}`}
            </span>
          </TitleCell>
        );
      })
    );
  };

  const buildRows = (rows) => {
    let templateRows = rows.map((row, index) => (
      <TemplateRow
        type="env"
        id={row.id}
        item={row}
        mainIndex={index}
        values={row.values}
      ></TemplateRow>
    ));
    return templateRows;
  };

  return (
    <Accordion style={{ borderRadius: "10px" }} defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
        <Typography fontWeight={700}>
          {t(
            "price_management.sniper_module.momentum_component.table_environment.title"
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ padding: "1rem" }}>
          <TableContainer sx={{ maxHeight: 640 }}>
            <CustomTable className="tableEnvironment" ref={table}>
              <TableHead
                style={{
                  zIndex: 100,
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                }}
              >
                <TableRow
                  style={{
                    marginTop: "20px",
                    zIndex: 100,
                    backgroundColor: "#fff",
                  }}
                >
                  <ButtonsCell
                    className="first-cell"
                    rowSpan={2}
                    sx={{ border: "none" }}
                    minWidth="150px"
                    maxWidth="150px"
                  ></ButtonsCell>
                  <TitleCell colSpan={colSpan}>
                    <span style={{ fontSize: "1rem" }} className="space-cell">
                      {t(
                        `price_management.sniper_module.momentum_component.table_environment.name`
                      )}
                    </span>
                  </TitleCell>
                </TableRow>
                <TableRow>{buildHeaders(columns)}</TableRow>
              </TableHead>
              <TableBody>
                {environmentVariables.length > 0 &&
                  buildRows(environmentVariables)}
              </TableBody>
            </CustomTable>
          </TableContainer>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default TableEnvironmentVariables;
