import { SvgIcon } from "@mui/material";

export function TableMoneyIcon(props) {
  const style = { 
    fill: 'none', 
    stroke: '#000000', 
    strokeLinecap: 'round', 
    strokeLinejoin: 'round',
     ...props.style 
    };
  return (
    <SvgIcon {...props} 
    xmlns="http://www.w3.org/2000/svg" id="Componente_653_1" data-name="Componente 653 – 1" width="25.713" height="22.499" viewBox="0 0 25.713 22.499">
      <g transform="matrix(1,0,0,1,0,0)">
      <path style={style} className="a" d="M9.571,17.83H2.6a2.1,2.1,0,0,1-2.1-2.1V2.627A2.1,2.1,0,0,1,2.6.53H18.324a2.1,2.1,0,0,1,2.1,2.1v7.4">
      </path>
      <line style={style} className="a" x1="0.5" y1="4.625" x2="20.421" y2="4.625">
      </line>
      <line style={style} className="a" x1="0.5" y1="13.327" x2="10.153" y2="13.327">
      </line>
      <line style={style} className="a" x1="0.5" y1="8.982" x2="20.421" y2="8.982">
      </line>
      <line style={style} className="a" x1="8.637" y1="4.625" x2="8.637" y2="17.83">
      </line>
      <line style={style} className="a" x1="14.47" y1="4.625" x2="14.47" y2="9.761">
      </line>
      <path style={style} className="a" d="M18.987,14.443h-2.04a1.346,1.346,0,0,0-.5,2.6l2.07.827a1.346,1.346,0,0,1-.5,2.6H15.977">
      </path>
      <path style={style} className="a" d="M17.482,14.443v-.752">
      </path>
      <path style={style} className="a" d="M17.482,21.213v-.752">
      </path>
      <path style={style}className="a" d="M17.482,23.47a6.018,6.018,0,1,0-6.018-6.018A6.018,6.018,0,0,0,17.482,23.47Z">
      </path>
      </g>
    </SvgIcon>
  );
}




//   <svg xmlns="http://www.w3.org/2000/svg" id="Componente_653_1" data-name="Componente 653 – 1" width="25.713" height="22.499" viewBox="0 0 25.713 22.499">
//   <g id="Grupo_2033" data-name="Grupo 2033">
//     <path id="Trazado_3284" data-name="Trazado 3284" d="M23.034,22.5H2.678A2.681,2.681,0,0,1,0,19.82V2.678A2.681,2.681,0,0,1,2.678,0H23.034a2.681,2.681,0,0,1,2.678,2.678V19.82A2.681,2.681,0,0,1,23.034,22.5M2.678,1.071A1.609,1.609,0,0,0,1.071,2.678V19.82a1.609,1.609,0,0,0,1.607,1.607H23.034a1.609,1.609,0,0,0,1.607-1.607V2.678a1.609,1.609,0,0,0-1.607-1.607Z" fill="#cdcace"/>
//     <path id="Trazado_3285" data-name="Trazado 3285" d="M25.177,11.071H.536A.536.536,0,1,1,.536,10H25.177a.536.536,0,1,1,0,1.071" transform="translate(0 -4.643)" fill="#cdcace"/>
//     <path id="Trazado_3286" data-name="Trazado 3286" d="M25.177,21.071H.536A.536.536,0,0,1,.536,20H25.177a.536.536,0,1,1,0,1.071" transform="translate(0 -9.286)" fill="#cdcace"/>
//     <path id="Trazado_3287" data-name="Trazado 3287" d="M25.177,31.071H.536A.536.536,0,1,1,.536,30H25.177a.536.536,0,1,1,0,1.071" transform="translate(0 -13.93)" fill="#cdcace"/>
//     <path id="Trazado_3288" data-name="Trazado 3288" d="M16.536,27.142A.536.536,0,0,1,16,26.606V10.536a.536.536,0,0,1,1.071,0v16.07a.536.536,0,0,1-.536.536" transform="translate(-7.429 -4.643)" fill="#cdcace"/>
//     <path id="Trazado_3289" data-name="Trazado 3289" d="M26.536,27.142A.535.535,0,0,1,26,26.606V10.536a.536.536,0,0,1,1.071,0v16.07a.535.535,0,0,1-.536.536" transform="translate(-12.072 -4.643)" fill="#cdcace"/>
//     <path id="Trazado_3290" data-name="Trazado 3290" d="M36.536,27.142A.535.535,0,0,1,36,26.606V10.536a.536.536,0,1,1,1.071,0v16.07a.535.535,0,0,1-.536.536" transform="translate(-16.715 -4.643)" fill="#cdcace"/>
//   </g>
// </svg>