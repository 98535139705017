import { CustomCell, CustomCellSticky, CustomTableRow } from "./Styles";

export default function TemplateRow({ type, id, item, mainIndex, values }) {
  const buildCol = (col, index) => {
    let template = (
      <CustomCell
        minWidth="60px"
        backgroundColor={mainIndex % 2 === 0 ? "text" : "primary"}
        key={`${col.index}-${index}`}
      >
        <span style={{ width: "60px", padding: "8px 2px" }}>
          {col.value.toFixed(2)}
        </span>
      </CustomCell>
    );
    return template;
  };

  return (
    <CustomTableRow
      sx={{ "& > *": { borderBottom: "unset" } }}
      key={`tr-${mainIndex}-${id}`}
    >
      <CustomCellSticky
        backgroundColor={mainIndex % 2 === 0 ? "text" : "primary"}
        minWidth="150px"
        maxWidth="150px"
        type="large"
      >
        <span style={{ marginLeft: "3px" }}>{item.name}</span>
      </CustomCellSticky>
      {values.map((col, index) => buildCol(col, index))}
    </CustomTableRow>
  );
}
