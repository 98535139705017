import * as type from './types';
export function createRelation(action) {
    return {
      type: type.CREATE_RELATION_SKU_REQUEST,
      payload: action
    };
  }

  export function clearResponseRelation() {
    return {
      type: type.CLEAR_RESPONSE_RELATION,
    };
  }

  export function deleteRelation(action) {
    return {
      type: type.DELETE_RELATION_SKU_REQUEST,
      payload: action
    };
  }

  export function setTabSelected(action) {
    return {
      type: type.SET_TAB_SELECTED,
      payload: action
    };
  }