import React from "react";
import { SvgIcon } from "@mui/material";

export function ClipIcon(props) {
  const classClipIcon = {
    fill: "none",
    strokelinecap: "round",
    strokelinejoin: "round",
    strokewidth: "1.5",
  };

  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          style={classClipIcon}
          d="M18.75,15.749V7.5a6.75,6.75,0,0,0-13.5,0v11.25a4.5,4.5,0,0,0,9,0V7.5a2.25,2.25,0,1,0-4.5,0v9.75"   
        ></path>
      </g>
    </SvgIcon>
  );
}
