import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import RoundedBox from "../../../Components/RoundedBox/RoundedBoxCategory";
import Button from "../../../Components/Button/ButtonCategory";
import moment from "moment";

const CategoryItemList = ({ category, index, onSelectItem }) => {
  const [t, i18n] = useTranslation("global");
  const handleClickItem = () => {
    onSelectItem(category);
  };
  return (
    <RoundedBox variant={`${category.state}`}>
      <div>
        <Typography
          variant="h6"
          align="left"
          sx={{
            fontWeight: "bold",
          }}
        >
          <strong>{category.name}</strong>
        </Typography>
        <Typography
          variant="subtitle2"
          align="left"
          style={{ marginlef: "1rem" }}
        >
          {`${t("dashboard_component.last_update")} | ${category.write_date && moment(category.write_date).format("YYYY-MM-DD HH:mm:ss a")}`}
        </Typography>
      </div>
      <Button variant={`${category.state}`} onClick={handleClickItem} />
    </RoundedBox>
  );
};

export default CategoryItemList;
