import { SvgIcon } from "@mui/material";

export function TableIcon(props) {
    const style={fill: 'none', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', ...props.style};
    return (
      <SvgIcon {...props } xmlns="http://www.w3.org/2000/svg" id="Componente_653_1" data-name="Componente 653 – 1" width="25.713" height="22.499" viewBox="0 0 25.713 22.499">
      <g id="Grupo_2033" data-name="Grupo 2033">
   <path id="Trazado_3284" data-name="Trazado 3284" d="M23.034,22.5H2.678A2.681,2.681,0,0,1,0,19.82V2.678A2.681,2.681,0,0,1,2.678,0H23.034a2.681,2.681,0,0,1,2.678,2.678V19.82A2.681,2.681,0,0,1,23.034,22.5M2.678,1.071A1.609,1.609,0,0,0,1.071,2.678V19.82a1.609,1.609,0,0,0,1.607,1.607H23.034a1.609,1.609,0,0,0,1.607-1.607V2.678a1.609,1.609,0,0,0-1.607-1.607Z" fill="#cdcace"/>
   <path id="Trazado_3285" data-name="Trazado 3285" d="M25.177,11.071H.536A.536.536,0,1,1,.536,10H25.177a.536.536,0,1,1,0,1.071" transform="translate(0 -4.643)" fill="#cdcace"/>
   <path id="Trazado_3286" data-name="Trazado 3286" d="M25.177,21.071H.536A.536.536,0,0,1,.536,20H25.177a.536.536,0,1,1,0,1.071" transform="translate(0 -9.286)" fill="#cdcace"/>
    <path id="Trazado_3287" data-name="Trazado 3287" d="M25.177,31.071H.536A.536.536,0,1,1,.536,30H25.177a.536.536,0,1,1,0,1.071" transform="translate(0 -13.93)" fill="#cdcace"/>
   <path id="Trazado_3288" data-name="Trazado 3288" d="M16.536,27.142A.536.536,0,0,1,16,26.606V10.536a.536.536,0,0,1,1.071,0v16.07a.536.536,0,0,1-.536.536" transform="translate(-7.429 -4.643)" fill="#cdcace"/>
    <path id="Trazado_3289" data-name="Trazado 3289" d="M26.536,27.142A.535.535,0,0,1,26,26.606V10.536a.536.536,0,0,1,1.071,0v16.07a.535.535,0,0,1-.536.536" transform="translate(-12.072 -4.643)" fill="#cdcace"/>
  <path id="Trazado_3290" data-name="Trazado 3290" d="M36.536,27.142A.535.535,0,0,1,36,26.606V10.536a.536.536,0,1,1,1.071,0v16.07a.535.535,0,0,1-.536.536" transform="translate(-16.715 -4.643)" fill="#cdcace"/>
   </g>
      </SvgIcon>
    );
  }




//   <svg xmlns="http://www.w3.org/2000/svg" id="Componente_653_1" data-name="Componente 653 – 1" width="25.713" height="22.499" viewBox="0 0 25.713 22.499">
//   <g id="Grupo_2033" data-name="Grupo 2033">
//     <path id="Trazado_3284" data-name="Trazado 3284" d="M23.034,22.5H2.678A2.681,2.681,0,0,1,0,19.82V2.678A2.681,2.681,0,0,1,2.678,0H23.034a2.681,2.681,0,0,1,2.678,2.678V19.82A2.681,2.681,0,0,1,23.034,22.5M2.678,1.071A1.609,1.609,0,0,0,1.071,2.678V19.82a1.609,1.609,0,0,0,1.607,1.607H23.034a1.609,1.609,0,0,0,1.607-1.607V2.678a1.609,1.609,0,0,0-1.607-1.607Z" fill="#cdcace"/>
//     <path id="Trazado_3285" data-name="Trazado 3285" d="M25.177,11.071H.536A.536.536,0,1,1,.536,10H25.177a.536.536,0,1,1,0,1.071" transform="translate(0 -4.643)" fill="#cdcace"/>
//     <path id="Trazado_3286" data-name="Trazado 3286" d="M25.177,21.071H.536A.536.536,0,0,1,.536,20H25.177a.536.536,0,1,1,0,1.071" transform="translate(0 -9.286)" fill="#cdcace"/>
//     <path id="Trazado_3287" data-name="Trazado 3287" d="M25.177,31.071H.536A.536.536,0,1,1,.536,30H25.177a.536.536,0,1,1,0,1.071" transform="translate(0 -13.93)" fill="#cdcace"/>
//     <path id="Trazado_3288" data-name="Trazado 3288" d="M16.536,27.142A.536.536,0,0,1,16,26.606V10.536a.536.536,0,0,1,1.071,0v16.07a.536.536,0,0,1-.536.536" transform="translate(-7.429 -4.643)" fill="#cdcace"/>
//     <path id="Trazado_3289" data-name="Trazado 3289" d="M26.536,27.142A.535.535,0,0,1,26,26.606V10.536a.536.536,0,0,1,1.071,0v16.07a.535.535,0,0,1-.536.536" transform="translate(-12.072 -4.643)" fill="#cdcace"/>
//     <path id="Trazado_3290" data-name="Trazado 3290" d="M36.536,27.142A.535.535,0,0,1,36,26.606V10.536a.536.536,0,1,1,1.071,0v16.07a.535.535,0,0,1-.536.536" transform="translate(-16.715 -4.643)" fill="#cdcace"/>
//   </g>
// </svg>