import React from "react";
import { styled, Typography, Box, Grid } from "@mui/material/";
import ButtonBase from "@mui/material/ButtonBase";
import { EraserIcon } from "../../../Components/CustomIcons";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";

const CategoryButtonCommon = styled(ButtonBase)(({ theme }) => ({
  height: "6rem",
  transition: "filter .25s",
  borderRadius: "0.5em",
  width: "100%",
  "&:hover": {
    filter: "brightness(1.15)",
  },
  ".icon-category": {
    borderRadius: "50%",
    width: "2.2rem",
    height: "2.2rem",
    padding: "0.3rem",
  },
}));

const CssCategoryButtonDone = styled(CategoryButtonCommon)(({ theme }) => ({
  color: theme.palette.custom.primary,
  backgroundColor: theme.palette.custom.secondary,
  ".icon-category": {
    color: theme.palette.custom.secondary,
    backgroundColor: theme.palette.custom.primary,
  },
}));

const CssCategoryButtonDraft = styled(CategoryButtonCommon)(({ theme }) => ({
  color: theme.palette.custom.secondary,
  backgroundColor: theme.palette.custom.primary,
  ".icon-category": {
    color: theme.palette.custom.primary,
    backgroundColor: theme.palette.custom.secondary,
    stroke: theme.palette.custom.primary,
  },
}));

const CategoryButtonAvailable = styled(CategoryButtonCommon)(({ theme }) => ({
  color: theme.palette.custom.secondary,
  backgroundColor: theme.palette.custom.text,
  ".icon-category": {
    color: theme.palette.custom.primary,
    backgroundColor: theme.palette.custom.secondary,
  },
}));

const CategoryButton = ({
  categoryState = "draft",
  handleClick,
  props,
  children,
}) => {
  const getButton = (state) => {
    switch (state) {
      case "done":
        return (
          <CssCategoryButtonDone onClick={handleClick} {...props}>
            {children}
          </CssCategoryButtonDone>
        );
        break;
      case "draft":
        return (
          <CssCategoryButtonDraft onClick={handleClick} {...props}>
            {children}
          </CssCategoryButtonDraft>
        );
        break;
      default:
        return (
          <CategoryButtonAvailable onClick={handleClick} {...props}>
            {children}
          </CategoryButtonAvailable>
        );
        break;
    }
  };
  return getButton(categoryState);
};

const CategoryIcon = ({ categoryState = "draft" }) => {
  const getIcon = (state) => {
    switch (state) {
      case "draft":
        return <EraserIcon className="icon-category" />;
        break;
      case "done":
        return <CheckIcon className="icon-category" />;
        break;
      default:
        return <EditOutlinedIcon className="icon-category" />;
        break;
    }
  };
  return getIcon(categoryState);
};

const CategoryGroups = ({ categories,numAvailableCategories, onSelectGroup }) => {
  const [t, i18n] = useTranslation("global");

  const categoryStates = [
    {
      id: 1,
      title: "dashboard_component.category_state.done",
      state: "done",
      total: 0,
    },
    {
      id: 2,
      title: "dashboard_component.category_state.draft",
      state: "draft",
      total: 0,
    },
    {
      id: 3,
      title: "dashboard_component.category_state.available",
      state: "available",
      total: numAvailableCategories,
    },
  ];

  const getTotalCategories = (categoryState) => {
    if (categoryState.total>0) return categoryState.total;
    const filtered = categories.filter(
      (category) => category.state === categoryState.state
    );
    return filtered ? filtered.length : 0;
  };

  const handleClick = (categoryState) => {
    onSelectGroup(categoryState);
  };

  return (
    <Grid xs={12}>
      <Grid xs={12}>
        <Typography
          variant="h6"
          sx={{ mt: "15px", mb: "15px", fontWeight: "semibold" }}
        >
          {t("dashboard_component.category_groups")}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "no-wrap",
            justifyContent: "space-around",
            gap: "12px",
          }}
        >
          {categoryStates.map((categoryState, index) => (
            <CategoryButton
              key={index}
              handleClick={() => handleClick(categoryState.state)}
              categoryState={categoryState.state}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "no-wrap",
                  justifyContent: "space-around",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "no-wrap",
                    alignItems: "center",
                    justifyContent: "space-around",
                    gap: "12px",
                  }}
                >
                  <CategoryIcon
                    categoryState={categoryState.state}
                  ></CategoryIcon>
                  <Typography
                    component="span"
                    variant="h6"
                    sx={{ fontWeight: "600" }}
                  >
                    {t(categoryState.title)}
                  </Typography>
                </Box>

                <Typography
                  component="span"
                  variant="h5"
                  sx={{ fontWeight: "600" }}
                >
                  {getTotalCategories(categoryState)}
                </Typography>
              </Box>
            </CategoryButton>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CategoryGroups;
