import { createRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";
import { GET_LOAD_OPTIMAL_PRICES, GET_SIMULATE_OPTIMAL_PRICES, SET_FORM_DATA_OPTIMAL_PRICES,CLEAR_OPTIMAL_PRICE, CLEAR_RESPONSE_OPTIMAL_PRICES, SET_DEFAULT_FORM_DATA_OPTIMAL_PRICES } from "./types";

export const getLoadOptimals = createRoutine(GET_LOAD_OPTIMAL_PRICES);

export const getSimulateOptimals = createRoutine(GET_SIMULATE_OPTIMAL_PRICES);

export const setFormDataOptimalPrices = createAction(SET_FORM_DATA_OPTIMAL_PRICES);

export const clearOptimalPrice = createAction(CLEAR_OPTIMAL_PRICE);

export const clearResponseOptimalPrices = createAction(CLEAR_RESPONSE_OPTIMAL_PRICES);

export const setDefaultFormDataOptimalPrices = createAction(SET_DEFAULT_FORM_DATA_OPTIMAL_PRICES);
