import React from "react";
import { SvgIcon } from "@mui/material";

export function AnalysisIcon(props) {
  const classAnalysisIcon = {
    fill: "none",
    strokeWidth: "1.5px",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    ...props.style,
  };
  return (
    <SvgIcon style={props.style} width="31" height="29.201" viewBox="0 0 31 29.201">
    <g id="Grupo_2011" data-name="Grupo 2011" transform="translate(0)">
      <g id="Grupo_2012" data-name="Grupo 2012" transform="translate(0)">
        <path id="Trazado_3233" data-name="Trazado 3233" d="M4.484,13.015a.648.648,0,0,1-.642-.57,11.45,11.45,0,0,1-.083-1.373A10.391,10.391,0,0,1,14.125.682h.012a10.321,10.321,0,0,1,9.645,6.549.648.648,0,1,1-1.2.478,9.034,9.034,0,0,0-8.441-5.731h-.011a9.093,9.093,0,0,0-9.072,9.093,10.2,10.2,0,0,0,.073,1.219.648.648,0,0,1-.566.721.636.636,0,0,1-.078,0" transform="translate(-1.324 -0.24)" fill="#cdcace"/>
        <path id="Trazado_3234" data-name="Trazado 3234" d="M32.86,30.447a.646.646,0,0,1-.458-.19L29.59,27.446a.648.648,0,0,1,.916-.916l2.812,2.812a.648.648,0,0,1-.458,1.106" transform="translate(-10.354 -9.277)" fill="#cdcace"/>
        <path id="Trazado_3235" data-name="Trazado 3235" d="M39.786,39.586a2.573,2.573,0,0,1-1.763-.7c-.043-.04-.086-.082-.128-.126l-5.014-5.027a1.945,1.945,0,0,1-.009-2.746l.93-.929a1.947,1.947,0,0,1,2.746-.01l5.017,5.056a2.591,2.591,0,0,1-1.684,4.476l-.095,0m-4.608-8.8a.645.645,0,0,0-.459.19L33.8,31.9a.648.648,0,0,0,0,.922L38.9,37.941a1.3,1.3,0,1,0,1.768-1.894l-.018-.017-5.014-5.053a.655.655,0,0,0-.461-.19" transform="translate(-11.378 -10.386)" fill="#cdcace"/>
        <path id="Trazado_3236" data-name="Trazado 3236" d="M40.749,6.478A.648.648,0,0,1,40.1,5.83V1.3H35.567a.648.648,0,0,1,0-1.3h5.183A.648.648,0,0,1,41.4.648V5.83a.648.648,0,0,1-.648.648" transform="translate(-12.298)" fill="#cdcace"/>
        <path id="Trazado_3237" data-name="Trazado 3237" d="M14.706,27.068q-.314,0-.631-.019a10.4,10.4,0,0,1-8.688-5.764.648.648,0,1,1,1.162-.573,9.083,9.083,0,0,0,17.21-3.483.648.648,0,0,1,1.293.078,10.379,10.379,0,0,1-10.346,9.761" transform="translate(-1.873 -5.853)" fill="#cdcace"/>
        <path id="Trazado_3238" data-name="Trazado 3238" d="M.648,19.24A.648.648,0,0,1,.19,18.134L9.7,8.624a1.949,1.949,0,0,1,2.742,0l3.1,3.112a.648.648,0,0,0,.911,0L27.981.19a.647.647,0,1,1,.916.915L17.366,12.649a1.949,1.949,0,0,1-2.742,0l-3.1-3.112a.65.65,0,0,0-.911,0L1.106,19.05a.646.646,0,0,1-.458.19" transform="translate(0 0)" fill="#cdcace"/>
      </g>
    </g>
    </SvgIcon>
  );
}
