import React from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const CssStepperDescriptionPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.custom.primary,
    color: theme.palette.custom.secondary,
    }));
    

export const StepperDescriptionPaper = (props) => {
    return (
            <CssStepperDescriptionPaper  
                {...props}
            >
                {props.children}
            </CssStepperDescriptionPaper>
    );
  }
  
  