import React from "react";
import Category from "./Category";
import Subcategory from "./Subcategory";

const Step1 = ({ currentCategory, onNext, onBack, stage }) => {
  let stageView = <></>;
  if (!currentCategory) {
    stage = 0;
  }
  switch (stage) {
    case 0:
      stageView = <Category onNext={() => onNext()} onBack={() => onBack()} />;
      break;
    case 1:
      stageView = (
        <Subcategory
          categoryId={currentCategory ? currentCategory.id : 0}
          onNext={() => onNext()}
          onBack={() => onBack()}
          currentSubcategories={
            currentCategory ? currentCategory.subcategory_ids : []
          }
        />
      );
    default:
      break;
  }

  return stageView;
};

export default Step1;
