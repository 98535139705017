export const getState = (state) => state.app;

export const getTheme = (state) => getState(state).colors;

export const getName = (state) => getState(state).name;

export const getLogo = state => getState(state).user.imageLogo;

export const getUserName = state => getState(state).user.userName;

export const getCompanyName = state => getState(state).user.companyName;

export const getNumMaxCategories = state => getState(state).numMaxCategories;

export const getLanguage = (state) => getState(state).language;

export const getIsLoading = (state) => getState(state).loading;

export const getResponseApp = (state) => getState(state).response;

export const getUser = state => getState(state).user;
