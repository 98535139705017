import * as React from "react";
import Box from "@mui/material/Box";
import CustomButton from "../Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import { CommonPaper } from "../Paper";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const CustomModal = (props) => {
  const getType = (type) => {
    switch (type) {
      case "empty":
        return (
          <Modal open={props.open} onClose={props.onCloseModal} style={style}>
            <CommonPaper
              elevation={1}
              style={{
                width: props.customWidth ? props.customWidth : "450px",
               maxHeight: "450px",
                overflow: "auto",
                padding: "2em",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  right: "1em",
                  top: "1em",
                }}
                onClick={props.onCloseModal}
              >
                <CancelOutlinedIcon />
              </div>
              <div style ={{display:"flex",justifyContent:"space-between"}}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                textAlign={"start"}
                style={{ marginBottom: "1em" }}
                fontWeight={"bold"}
              >
                {props.title}
              </Typography>
              {props.headerOptions}

              </div>
       
              {props.children}
            </CommonPaper>
          </Modal>
        );
        break;
      case "edit":
        return (
          <Modal open={props.open} onClose={props.onCloseModal} style={style}>
            <CommonPaper
              elevation={1}
              style={{
                width: props.customWidth ? props.customWidth : "450px",
                overflow: "auto",
                padding: "2em",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  right: "1em",
                  top: "1em",
                }}
                onClick={props.onCloseModal}
              >
                <CancelOutlinedIcon />
              </div>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                textAlign={"start"}
                style={{ marginBottom: "1em" }}
                fontWeight={"bold"}
              >
                {props.title}
              </Typography>
              {props.children}
              <Stack
                gap={"1rem"}
                justifyContent={"end"}
                direction="row"
                mr={"5rem"}
              >
                <CustomButton
                  variant="gray"
                  style={{
                    width: "max-content",
                    marginTop: "2em",
                    marginRight: "0.5em",
                  }}
                  onClick={props.onCancelModal}
                >
                  {props.cancelText}
                </CustomButton>
                <CustomButton
                  variant="primary"
                  style={{ width: "max-content", marginTop: "2em" }}
                  onClick={props.onOkModal}
                >
                  {props.acceptText}
                </CustomButton>
              </Stack>
            </CommonPaper>
          </Modal>
        );
        break;
      case "delete":
        return (
          <Modal open={props.open} onClose={props.onCloseModal} style={style}>
            <CommonPaper
              elevation={1}
              style={{
                width: "450px",
                overflow: "auto",
                padding: "2em",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ErrorOutlineIcon
                  sx={{
                    color: (theme) => theme.palette.custom.primary,
                    fontWeight: "200",
                    fontSize: "3rem",
                    marginBottom: "0.5rem",
                  }}
                />
              </Box>
              <div
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  right: "1em",
                  top: "1em",
                }}
                onClick={props.onCloseModal}
              >
                <CancelOutlinedIcon />
              </div>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                textAlign={"center"}
                style={{ marginBottom: "1em" }}
              >
                {props.title}
            
              </Typography>
              {props.children}
              <Stack gap={"1rem"} justifyContent={"center"} direction="row">
                <CustomButton
                  variant="gray"
                  style={{
                    width: "max-content",
                    marginTop: "2em",
                    marginRight: "0.5em",
                  }}
                  onClick={props.onCancelModal}
                >
                  {props.cancelText}
                </CustomButton>
                <CustomButton
                  variant="primary"
                  style={{ width: "max-content", marginTop: "2em" }}
                  onClick={props.onOkModal}
                >
                  {props.acceptText}
                </CustomButton>
              </Stack>
            </CommonPaper>
          </Modal>
        );
        break;
      default:
        return (
          <Modal open={props.open} onClose={props.onCloseModal} style={style}>
            <CommonPaper
              elevation={1}
              style={{
                width: "450px",
                overflow: "auto",
                padding: "2em",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  right: "1em",
                  top: "1em",
                }}
                onClick={props.onCloseModal}
              >
                <CancelOutlinedIcon />
              </div>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                textAlign={"left"}
                style={{ marginBottom: "1em" }}
              >
                {props.title}
              </Typography>
              {props.children}
              <Stack justifyContent={"flex-end"} direction="row">
                <CustomButton
                  variant="gray"
                  style={{
                    width: "max-content",
                    marginTop: "2em",
                    marginRight: "0.5em",
                  }}
                  onClick={props.onCancelModal}
                >
                  {props.cancelText}
                </CustomButton>
                <CustomButton
                  variant="primary"
                  style={{ width: "max-content", marginTop: "2em" }}
                  onClick={props.onOkModal}
                >
                  {props.acceptText}
                </CustomButton>
              </Stack>
            </CommonPaper>
          </Modal>
        );
    }
  };
  return getType(props.type);
};

export default CustomModal;
