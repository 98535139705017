import React from "react";
import { SvgIcon } from "@mui/material";

export function MomentumIcon(props) {
  const classMomentumIcon = {
    fill: "none",
    strokeWidth: "1.5px",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    ...props.style,
  };
  return (
    <SvgIcon style={props.style} viewBox="0 0 30.2 30.176">
      <g>
        <path
          d="M23.368,25.316h-2.7a.629.629,0,1,1,0-1.257h2.7a1.7,1.7,0,0,0,.761-3.224l-2.452-1.228A2.959,2.959,0,0,1,23,14h2.7a.629.629,0,0,1,0,1.257H23a1.7,1.7,0,0,0-.761,3.225l2.451,1.227a2.959,2.959,0,0,1-1.323,5.606"
          transform="translate(-7.442 -5.199)"
        />
        <path
          d="M24.669,14.143a.629.629,0,0,1-.629-.629V11.629a.629.629,0,1,1,1.257,0v1.886a.628.628,0,0,1-.629.629"
          transform="translate(-8.927 -4.085)"
        />
        <path
          d="M24.669,33.143a.628.628,0,0,1-.629-.629V30.629a.629.629,0,1,1,1.257,0v1.886a.628.628,0,0,1-.629.629"
          transform="translate(-8.927 -11.141)"
        />
        <path
          d="M33.649,12.005a.628.628,0,0,1-.443-1.074l3.908-3.88A.629.629,0,0,1,38,7.943l-3.908,3.879a.626.626,0,0,1-.443.183"
          transform="translate(-12.262 -2.55)"
        />
        <path
          d="M41.163,6.286a3.143,3.143,0,1,1,3.143-3.143,3.147,3.147,0,0,1-3.143,3.143m0-5.029a1.886,1.886,0,1,0,1.886,1.886,1.888,1.888,0,0,0-1.886-1.886"
          transform="translate(-14.119)"
        />
        <path
          d="M38.232,38.891a.627.627,0,0,1-.446-.185L35.127,36.03a.629.629,0,1,1,.891-.886l2.659,2.676a.629.629,0,0,1-.446,1.072"
          transform="translate(-12.976 -12.981)"
        />
        <path
          d="M41.183,44.286a3.143,3.143,0,1,1,3.143-3.143,3.147,3.147,0,0,1-3.143,3.143m0-5.029a1.886,1.886,0,1,0,1.886,1.886,1.888,1.888,0,0,0-1.886-1.886"
          transform="translate(-14.126 -14.111)"
        />
        <path
          d="M11.341,12.005a.626.626,0,0,1-.443-.183L6.992,7.943a.629.629,0,0,1,.886-.892l3.906,3.88a.629.629,0,0,1-.443,1.074"
          transform="translate(-2.528 -2.55)"
        />
        <path
          d="M3.163,6.286A3.143,3.143,0,1,1,6.306,3.143,3.147,3.147,0,0,1,3.163,6.286m0-5.029A1.886,1.886,0,1,0,5.049,3.143,1.888,1.888,0,0,0,3.163,1.257"
          transform="translate(-0.007)"
        />
        <path
          d="M7.495,38.891a.629.629,0,0,1-.446-1.072l2.66-2.676a.629.629,0,0,1,.891.886L7.94,38.705a.627.627,0,0,1-.446.185"
          transform="translate(-2.55 -12.982)"
        />
        <path
          d="M3.143,44.287h0A3.142,3.142,0,0,1,0,41.143v0A3.143,3.143,0,0,1,3.143,38h0a3.144,3.144,0,0,1,0,6.288m0-5.03a1.886,1.886,0,0,0-1.886,1.885v0a1.886,1.886,0,0,0,1.885,1.887h0a1.887,1.887,0,0,0,0-3.773ZM.629,41.143h0Z"
          transform="translate(0 -14.111)"
        />
        <path
          d="M36.421,23.257H32.649a.629.629,0,1,1,0-1.257h3.772a.629.629,0,1,1,0,1.257"
          transform="translate(-11.891 -8.17)"
        />
        <path
          d="M41.163,24.286a3.143,3.143,0,1,1,3.143-3.143,3.147,3.147,0,0,1-3.143,3.143m0-5.029a1.886,1.886,0,1,0,1.886,1.886,1.888,1.888,0,0,0-1.886-1.886"
          transform="translate(-14.119 -6.684)"
        />
        <path
          d="M12.421,23.257H8.649a.629.629,0,1,1,0-1.257h3.772a.629.629,0,1,1,0,1.257"
          transform="translate(-2.978 -8.17)"
        />
        <path
          d="M3.163,24.286a3.143,3.143,0,1,1,3.143-3.143,3.147,3.147,0,0,1-3.143,3.143m0-5.029a1.886,1.886,0,1,0,1.886,1.886,1.888,1.888,0,0,0-1.886-1.886"
          transform="translate(-0.007 -6.684)"
        />
      </g>
    </SvgIcon>
  );
}
