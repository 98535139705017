import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import FormVariable from "./FormVariable";
import FooterStep from "../../FooterStep";
import ListVariables from "./ListVariables";
import { getResponseVariables } from "../../../../Redux/modules/variables/selectors";
import Accordion from "../../../../Components/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  createVariable,
  deleteVariable,
  updateVariable,
  clearResponseVariable,
} from "../../../../Redux/modules/variables/actions";
import { StepWrapper } from "../../styles";

const Variable = ({
  categoryId,
  currentVariables = [],
  onNext,
  onBack,
  category,
}) => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const response = useSelector(getResponseVariables);

  const onVariableCreated = (newVariable) => {
    dispatch(createVariable({ ...newVariable, categoryId: categoryId }));
  };
  const onVariableUpdated = (newVariable) => {
    dispatch(updateVariable({ ...newVariable, categoryId: categoryId }));
  };
  const onVariableDeleted = (variable) => {
    dispatch(deleteVariable({ ...variable, categoryId: categoryId }));
  };

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseVariable());
    }
  }, [response]);

  return (
    <StepWrapper>
      <Accordion style={{ marginTop: "1em" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
          <Typography fontWeight={600}>
            {t("initial_setup.step_5.stage_1.title")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormVariable
            onVariableCreated={onVariableCreated}
            onVariableUpdated={onVariableUpdated}
            category={category}
          />
        </AccordionDetails>
      </Accordion>
      <FooterStep onNext={onNext} onBack={onBack}></FooterStep>
      <ListVariables
        variables={currentVariables}
        category={category}
        onVariableUpdated={onVariableUpdated}
        onVariableDeleted={onVariableDeleted}
      />
    </StepWrapper>
  );
};

export default Variable;
