import { useState, useRef } from "react";
import {
  CustomTable,
  TitleCellSimulation as TitleCell,
  CustomCellSticky,
  ButtonsCell,
} from "./StyleTable";
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material/";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RowCategorySkuSimulation } from "./RowCategorySkuSimulationsticky";
import {
  getSimulationLoaded,
  getSimulationCount,
} from "../../../../Redux/modules/what-if/optimal-prices/selectors";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import { clearOptimalPrice } from "../../../../Redux/modules/what-if/optimal-prices/actions";
import { SweepingIcon } from "../../../../Components/CustomIcons/SweepingIcon";
import SaveMenu from "./SaveMenu";
import { exportExel } from "../../../../Utils";
import Tooltip from "@mui/material/Tooltip";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";

export default function TableSimulationOptimalsPrice() {
  const dispatch = useDispatch();
  const optimalTableSimulationRef = useRef(null);
  const [qtyType, setQtyType] = useState("quantity_kg");

  const handlePdf = useReactToPrint({
    content: () => optimalTableSimulationRef.current,
    documentTitle: "optimal-prices-",
    pageStyle: { with: "100%" },
  });
  const simulationOptimals = useSelector(getSimulationLoaded);
  const simulationCount = useSelector(getSimulationCount);
  const [t, i18n] = useTranslation("global");

  const exportToExcel = () => {
    let [table] = document.getElementsByClassName("tableSimulationOptimal");
    let today = new Date();
    try {
      let excelTable = document.createElement("table");
      excelTable.innerHTML = table.innerHTML.trim();
      let firstCell = excelTable.querySelector(".first-cell");
      firstCell.innerHTML = "";
      let collapses = excelTable.querySelectorAll(".collapse-tr");
      for (let index = 0; index < collapses.length; index++) {
        const collapse = collapses[index];
        let expandTr = collapse.previousSibling;
        const tbody = collapse.querySelector("tbody");
        if (tbody) {
          let inputs = tbody.querySelectorAll("input");
          for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            const parentTd = input.parentNode;
            parentTd.className = "";
            parentTd.innerHTML = input.value;
          }
          const rows = tbody.innerHTML;
          expandTr.insertAdjacentHTML("afterend", rows);
        }
        collapses[index].parentNode.removeChild(collapses[index]);
      }

      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.table_to_sheet(excelTable);

      XLSX.utils.book_append_sheet(wb, ws, "Table");
      XLSX.writeFile(
        wb,
        `export_table_optimals_${today.getFullYear()}-${
          today.getMonth() + 1
        }-${today.getDay()}-${today.getTime()}.xlsx`
      );
    } catch (error) {}
  };

  const handleDownloadExcel = () => {
    exportToExcel();
  };

  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <CustomTable
        className="tableSimulationOptimal"
        stickyHeader
        aria-label="sticky table"
        ref={optimalTableSimulationRef}
      >
        <TableHead>
          <TableRow style={{ marginTop: "20px" }}>
            <ButtonsCell>
              <div
                className="first-cell"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <ButtonItemColumn
                  style={{ height: "29px" }}
                  tooltip={t("components_globals.button_clear")}
                  onClick={() => {
                    dispatch(clearOptimalPrice("simulationOptimals"));
                  }}
                  icon={
                    <SweepingIcon
                      className="iconButtonPrimary strokeLigth"
                      sx={{ fontSize: "1.6rem" }}
                    />
                  }
                ></ButtonItemColumn>
                <SaveMenu
                  handlePdf={handlePdf}
                  handleExcel={handleDownloadExcel}
                />
              </div>
            </ButtonsCell>
            <TitleCell>
              <span className="space-cell">
                {t("price_management.optimal_prices.price")} 1
              </span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">Δ%</span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">
                {t("price_management.optimal_prices.price")} 2
              </span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">
                {t("price_management.optimal_prices.Weighted_Dist")} 1
              </span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">
                {t("price_management.optimal_prices.Weighted_Dist")} 2
              </span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">Δ%</span>
            </TitleCell>
            {
              <TitleCell
                onClick={() => {
                  setQtyType(
                    qtyType === "quantity_kg" ? "quantity" : "quantity_kg"
                  );
                }}
              >
                <Tooltip title="Click para cambiar">
                  <span className="space-cell">
                    {qtyType === "quantity_kg" ? "QTY 1 (Kg)" : "QTY 1"}
                  </span>
                </Tooltip>
              </TitleCell>
            }
            <TitleCell>
              <span className="space-cell">
                {qtyType === "quantity_kg" ? "QTY 2 (Kg)" : "QTY 2"}
              </span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">Δ%</span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">
                {t("price_management.optimal_prices.sales")} 1
              </span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">
                {t("price_management.optimal_prices.sales")} 2
              </span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">Δ%</span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">
                {t("price_management.optimal_prices.profit")} 1
              </span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">
                {t("price_management.optimal_prices.profit")} 2
              </span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">Δ%</span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">SOM 1</span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">SOM 2</span>
            </TitleCell>
            <TitleCell>
              <span className="space-cell">Δ%</span>
            </TitleCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {simulationOptimals.subcategory_ids?.map((row) => (
            <RowCategorySkuSimulation
              key={row.id}
              row={row}
              qtyType={qtyType}
            />
          ))}
        </TableBody>
      </CustomTable>
    </TableContainer>
  );
}
