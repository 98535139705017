import * as React from "react";
import { Button, Typography, Grid } from "@mui/material/";
import { styled } from "@mui/material/styles";
import { FileUpload, PencilWhiteIcon } from "../../CustomIcons";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { useTranslation } from "react-i18next";

const CssButtonPrimary = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.custom.primary,
  color: theme.palette.custom.secondary,
  stroke: theme.palette.custom.secondary,
  borderRadius:'2em',
   height:'2.7rem',
   width:'9rem',
   textTransform:'capitalize',
  transition: 'filter .25s',
  '&:hover': {
      backgroundColor: theme.palette.custom.secondary,
      color: theme.palette.custom.primary,
      stroke: theme.palette.custom.primary,
      filter:'brightness(1.15)'
  },
}));

const CssButton = styled(Button)(({ theme }) => ({
  color: theme.palette.custom.secondary,
  backgroundColor: theme.palette.custom.primary,
  borderRadius: "0.5em",
  width: "460px",
  height: "139px",
  transition: "filter .25s",
  textTransform:'capitalize',
  "&:hover": {
    backgroundColor: theme.palette.custom.primary,
    filter: "brightness(1.15)",
  },
}));

const CssSecondary = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.custom.primary,
  color: theme.palette.custom.secondary,
  stroke: theme.palette.custom.secondary,
  borderRadius:'2em',
   height:'2.7rem',
   width:'9rem',
   textTransform:'capitalize',
  transition: 'filter .25s',
  '&:hover': {
      backgroundColor: theme.palette.custom.secondary,
      color: theme.palette.custom.primary,
      stroke: theme.palette.custom.primary,
      filter:'brightness(1.15)'
  },
}));
const CssThird = styled(Button)(({ theme }) => ({
  color: theme.palette.custom.secondary,
  backgroundColor: theme.palette.custom.text,
  borderRadius:'2em',
   height:'2.7rem',
   width:'9rem',
  transition: 'filter .25s',
  "&:hover": {
    backgroundColor: theme.palette.custom.text,
    filter: "brightness(1.15)",
  },
}));

const ButtonCategory = ({
  children,
  style = {},
  variant = "primary",
  disabled = false,
  onClick = () => {},
  startIcon,
}) => {
  const [t, i18n] = useTranslation("global");
  const getVariant = (variant) => {
    switch (variant) {
      case "done":
        return (
          <CssButtonPrimary
            variant={"contained"}
            fullWidth
            style={{ fontWeight: "500", ...style }}
            disabled={disabled}
            onClick={onClick}
            startIcon={<PencilWhiteIcon />}
          >
            <Typography variant="p" sx={{fontWeight: "semibold"}}>{t("dashboard_component.category_action.edit")}</Typography>
              {children}
          </CssButtonPrimary>
        );
      case "draft":
        return (
          <CssSecondary
            variant={"contained"}
            color={"secondary"}
            fullWidth
            style={{ fontWeight: "500", color: "secondary", ...style }}
            disabled={disabled}
            onClick={onClick}
            startIcon={<ArrowForwardOutlinedIcon/>}
          >
            <Typography variant="p" sx={{
              fontWeight: "semibold"}}>{t("dashboard_component.category_action.continue")}</Typography>
            {children}
          </CssSecondary>
        );
      case "available":
        return (
          <CssButtonPrimary
            variant={"contained"}
            fullWidth
            style={{ fontWeight: "500", ...style }}
            disabled={disabled}
            onClick={onClick}
            startIcon={<FileUpload/>}
          >
             <Typography variant="p" sx={{
              fontWeight: "semibold"}}>{t("dashboard_component.category_action.start")}</Typography>
            {children}
          </CssButtonPrimary>
        );
      default:
        return (
          <CssButton
            variant={"contained"}
            color={"primary"}
            fullWidth
            style={{ fontWeight: "500", ...style }}
            disabled={disabled}
            onClick={onClick}
            startIcon={startIcon}
          >
           
            {children}
          </CssButton>
        );
    }
  };
  return getVariant(variant);
};

export default ButtonCategory;
