import { useState } from "react";
import Button from "../../../Components/Button";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ProfileIcon, SaveIcon } from "../../../Components/CustomIcons";
import { styled as MuiStyled } from "@mui/material/styles";
import ButtonUpload from "../../../Components/Button/ButtonUpload";
import { Box } from "@mui/material/";
import { getBase64 } from "../../../Utils";

const LogoWrapper = styled.div`
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .new-logo {
    width: 70px;
    margin-right: 1em;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
  .without-logo {
    font-weight: 900;
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1em;
    border: 2px solid #000;
    border-radius: 50%;
  }
`;

const RoundedLogo = MuiStyled("div")(({ theme }) => ({
  borderColor: theme.palette.custom.primary,
  color: theme.palette.custom.secondary,
}));

const CustomizeLogo = ({ logo, handleUpdateLogo, handleSaveLogo }) => {
  const [t, i18n] = useTranslation("global");
  const [newLogo, setNewLogo] = useState(logo);

  const beforeImageUpload = async (file) => {
    return new Promise((resolve, reject) => {
      const isJPG = file.type === "image/jpeg";
      const isPng = file.type === "image/png";

      if (!isJPG) {
        if (!isPng) {
          return false;
        }
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        return false;
      }
      getBase64(file, (img) => {
        if (img) {
          resolve(img);
        }
      });
    });
  };
  const handleFileInputChange = async (e) => {
    let files = e.target.files;
    const file = files[0];
    const img = await beforeImageUpload(file).then();
    setNewLogo(img);
    handleUpdateLogo(img);
  };

  return (
    <LogoWrapper>
      <div style={{ marginBottom: "2em", marginTop: "0px" }}>
        <Typography variant="body" sx={{ opacity: 0.7 }}>
          {t("settings_component.select_your_company_logo")}
        </Typography>
      </div>
      <div className="content">
        <Box
          display="flex"
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          {newLogo ? (
            <img
              className="new-logo"
              src={`${
                newLogo.indexOf("base64") != -1
                  ? newLogo
                  : `data:image/png;base64,${newLogo}`
              }`}
            />
          ) : (
            <RoundedLogo className="without-logo">
              <ProfileIcon />
            </RoundedLogo>
          )}

          {newLogo?<Button
          variant="primaryVariant"
          style={{ width: "18%", marginTop: "0.85rem" }}
          onClick={() => {
            setNewLogo(null);
            handleSaveLogo(null)
          }}        
        >
          {t("components_globals.button_clear")}
        </Button>
        :
          <ButtonUpload
            title={t("settings_component.select_update_logo")}
            onChange={handleFileInputChange}
          />}
        </Box>
  
        <Button
          variant="primaryVariant"
          style={{ width: "18%", marginTop: "0.85rem" }}
          onClick={() => handleSaveLogo(newLogo)}
          startIcon={<SaveIcon />}
        >
          {t("components_globals.button_save")}
        </Button>
      </div>
    </LogoWrapper>
  );
};

export default CustomizeLogo;
