import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { selectLanguage } from "../../../Redux/modules/app/actions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export const FloatingMenuLanguages = ({ marginTop, children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [t, i18n] = useTranslation("global");
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguage = (event) => {
    const lang = event.currentTarget.getAttribute("value");
    dispatch(selectLanguage(lang));
    setAnchorEl(null);
  };

  const menuOptionsLanguaje = [
    {
      text: t("drawer_component.spanish"),
      id: "1",
      value: "es",
    },
    {
      text: t("drawer_component.english"),
      id: "2",
      value: "en",
    },
    {
      text: t("drawer_component.french"),
      id: "3",
      value: "fr",
    },
  ];

  return (
    <div>
      <List component="nav" aria-label="Device settings">
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
          style={{marginRight: "-15px"}}
        >
          {children}
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        sx={{
          opacity: "0.7",
          "& .MuiPaper-root": {
            backgroundColor: "black",
            color: "white",
            marginTop: marginTop?marginTop:"30px",
            padding: "12px",
            paddingBottom: "0px",
            paddingTop: "0px", 
            textAlign: "right"
          },
        }}
      >
        {menuOptionsLanguaje.map((option) => (
          <MenuItem
            key={option.id}
            value={option.value}
            onClick={handleLanguage}
          >
            <ListItemText>{option.text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};


