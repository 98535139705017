import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import FormSku from "./FormSku";
import FooterStep from "../../FooterStep";
import ListSkus from "./ListSkus";
import Accordion from "../../../../Components/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  createSku,
  clearResponseSku,
  deleteSku,
  updateSku,
} from "../../../../Redux/modules/skus/actions";
import { getResponseSkus } from "../../../../Redux/modules/skus/selectors";
import { getUnits } from "../../../../Redux/modules/units/selectors";
import { loadUnits } from "../../../../Redux/modules/units/actions";
import { StepWrapper } from "../../styles";

const Sku = ({ category, currentSkus = [], onNext, onBack }) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const response = useSelector(getResponseSkus);
  const units = useSelector(getUnits);
  const onSkuCreated = (newSku) => {
    dispatch(createSku({ ...newSku, categoryId: category.id }));
  };

  const onSkuDeleted = (sku) => {
    dispatch(deleteSku({ ...sku, categoryId: category.id }));
  };

  const onSkuUpdated = (sku) => {
    dispatch(updateSku({ ...sku, categoryId: category.id }));
  };
  useEffect(() => {
    if (units.length === 0) {
      dispatch(loadUnits());
    }
  }, []);

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseSku());
    }
  }, [response]);

  return (
    <StepWrapper>
      <Accordion style={{ marginTop: "1em" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
          <Typography fontWeight={600}>
            {t("initial_setup.forms.common_inputs.add_sku")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormSku
            key={1}
            onSkuCreated={onSkuCreated}
            brands={category && category.brand_ids}
            units={units}
            subcategories={category && category.subcategory_ids}
          ></FormSku>
        </AccordionDetails>
      </Accordion>

      <FooterStep onNext={onNext} onBack={onBack}></FooterStep>
      <ListSkus
        onSkuDeleted={onSkuDeleted}
        onSkuUpdated={onSkuUpdated}
        skus={currentSkus}
        units={units}
        brands={category && category.brand_ids}
        category={category}
      ></ListSkus>
    </StepWrapper>
  );
};

export default Sku;
