import React, { useState } from "react";
import RelationSku from "./RelationSku";

const Step4 = ({ currentCategory, onNext, onBack }) => {
  const [tabSelected, setTabSelected] = useState(0);
  return (
    <RelationSku
      category={currentCategory}
      onNext={() => onNext()}
      onBack={() => onBack()}
    />
  );
};

export default Step4;
