import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { LockIcon } from "../../../Components/CustomIcons";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { setCurrentPage } from "../../../Redux/modules/layout/actions";
import { useTranslation } from "react-i18next";
import MenuPopover from "../../../Components/MenuPopover";

const MenuItem = ({ item, showIcon }) => {
  const getFixedText = (menu) => {
    let indexFirstSpace = menu.indexOf(" ");
    const textStyle = { fontSize: 14, fontWeight: "600" };
    let newMenu =
      menu.length < 12 || indexFirstSpace === -1 ? (
        <span style={textStyle} className="menu-item">
          {menu}
        </span>
      ) : (
        <span style={textStyle} className="menu-item">
          {menu.substring(0, indexFirstSpace)}
          <br></br>
          {menu.substring(indexFirstSpace + 1)}
        </span>
      );
    return newMenu;
  };
  return (
    <>
      <ListItemIcon>{showIcon && item.icon}</ListItemIcon>
      <ListItemText>{getFixedText(item.text.trim())}</ListItemText>
    </>
  );
};

export default function Menu({
  menuItem,
  closeAll,
  menuSelected,
  setMenuSelected,
}) {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openThirdMenu, setOpenThirdMenu] = useState(false);

  const handleClickMenu = (item) => {
    item.children.length > 0 && setOpenMenu(!openMenu);
  };

  const handleClickSubMenu = (item) => {
    item.children.length > 0 && setOpenSubMenu(!openSubMenu);
  };

  const handleClickThirdMenu = (item) => {
    item.children.length > 0 && setOpenThirdMenu(!openThirdMenu);
  };

  const handleNavigate = (menu, menuId, subMenuId, thirdMenuId) => {
    if (menu.path && !menu.locked) {
      setMenuSelected({
        menuId: menuId,
        subMenuId: subMenuId,
        thirdMenuId: thirdMenuId,
      });
      dispatch(setCurrentPage(menu.path));
      navigate(menu.path);
    }
  };

  useEffect(() => {
    setOpenMenu(false);
    setOpenSubMenu(false);
    setOpenThirdMenu(false);
  }, [closeAll]);

  return (
    <List
      key={menuItem.id}
      onClick={() => handleNavigate(menuItem, menuItem.id, null, null)}
    >
      <ListItemButton
        autoFocus
        onClick={() => handleClickMenu(menuItem)}
        key={menuItem.id}
        className={`${menuItem.id === menuSelected.menuId ? "active" : ""}`}
      >
        <MenuItem item={menuItem} showIcon={true}></MenuItem>
        {menuItem.children.length > 0 &&
          (openMenu ? <ExpandLess /> : <ExpandMore />)}
        {menuItem.locked && (
          <MenuPopover
            message={t("drawer_component.menu_popover")}
            Icon={LockIcon}
          />
        )}
      </ListItemButton>
      {menuItem.children.length > 0 && (
        <Collapse in={openMenu} timeout="auto" unmountOnExit>
          {menuItem.children.map((subItem, index) => (
            <List
              onClick={() =>
                handleNavigate(subItem, menuItem.id, subItem.id, null)
              }
              component="div"
            >
              <ListItemButton
                onClick={() => handleClickSubMenu(subItem)}
                className={`${
                  subItem.id === menuSelected.subMenuId ? "active" : ""
                }`}
                key={index}
              >
                <MenuItem item={subItem} showIcon={false}></MenuItem>
                {subItem.children.length > 0 &&
                  (openSubMenu ? <ExpandLess /> : <ExpandMore />)}
                {subItem.locked && (
                  <MenuPopover
                    message={t("drawer_component.menu_popover")}
                    Icon={LockIcon}
                  />
                )}
              </ListItemButton>
              {subItem.children.length > 0 && (
                <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
                  {subItem.children.map((thirdItem, index) => (
                    <List
                      key={index}
                      onClick={() =>
                        handleNavigate(
                          thirdItem,
                          menuItem.id,
                          subItem.id,
                          thirdItem.id
                        )
                      }
                      component="div"
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() => handleClickThirdMenu(thirdItem)}
                        key={index}
                        className={`${
                          thirdItem.id === menuSelected.thirdMenuId
                            ? "active"
                            : ""
                        }`}
                      >
                        <MenuItem item={thirdItem} showIcon={false}></MenuItem>
                        {thirdItem.children.length > 0 &&
                          (openThirdMenu ? <ExpandLess /> : <ExpandMore />)}
                      </ListItemButton>
                    </List>
                  ))}
                </Collapse>
              )}
            </List>
          ))}
        </Collapse>
      )}
    </List>
  );
}
