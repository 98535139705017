import Chart from "react-apexcharts";

const GraphMomentum = ({ rows, columns, type }) => {
  let minRangeAxisY = -1;
  let maxRangeAxisY = 1;
  let annotationsY = [];
  let annotationsX = [];
  let items = [];
  let colors = ["#407D68"];
  const formatColumns = columns
    ? columns.map((time) => new Date(time).getTime())
    : [];

  // const buildAnnotationsX=()=>{

  //   annotationsX.push({
  //     x: new Date("23 Nov 2017").getTime(),
  //     borderColor: "#775DD0",
  //   });
  // }

  // buildAnnotationsX();

  // annotationsX.push({
  //   x: new Date("23 Nov 2017").getTime(),
  //   borderColor: "#775DD0",
  // });

  switch (type) {
    case "cross_elasticity":
      items = rows.map((row) =>
        (row.object.cross_elasticity - row.target.cross_elasticity).toFixed(2)
      );
      minRangeAxisY = -2;
      maxRangeAxisY = 2;
      annotationsY.push({
        y: 0,
        borderColor: "gray",
      });

      colors = [
        function ({ value, seriesIndex, w }) {
          if (value < 0) {
            return "#FF0000";
          } else if (value > 0) {
            return "#407D68";
          } else {
            return "#ffc000";
          }
        },
      ];

      break;
    case "elasticity_price":
      items = rows.map((row) => row.object.elasticity - row.target.elasticity);
      const minValue = Math.min(...items);
      const maxValue = Math.max(...items);
      minRangeAxisY = minValue >= 0 ? 0 : minValue - Math.abs(minValue / 4);
      maxRangeAxisY = maxValue <= 0 ? 0 : maxValue + maxValue / 4;
      annotationsY.push({
        y: 0,
        borderColor: "gray",
      });
      break;
    case "category":
      items = rows.map((row) => row.subcategory_opportunity);
      minRangeAxisY = -1;
      maxRangeAxisY = 1;
      annotationsY.push({
        y: 0,
        borderColor: "gray",
      });

      break;
  }
  let optionGraphChart = {
    series: [
      {
        data: items,
      },
    ],
    stroke: {
      width: [14],
    },
    options: {
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      chart: {
        toolbar: {
          enabled: false,
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        animations: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
        type: "bar",
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        enabled: true,
        position: "top",
        style: {
          colors: ["#333"],
        },
        // offsetX: 0,
        offsetY: -20,
        formatter: function (val, opt) {
          return val.toFixed(2).toString().replace(".", ",");
        },
      },
      fill: {
        colors: colors,
      },
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      yaxis: {
        show: false,
        // dataLabels: {
        //   enabled: false,
        // },

        min: minRangeAxisY,
        max: maxRangeAxisY,
      },
      xaxis: {
        type: "datetime",
        categories: formatColumns,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      annotations: {
        yaxis: annotationsY,
      },
    },
  };

  return (
    <div style={{ position: "relative", top: 40, left: -17 }}>
      <Chart
        options={optionGraphChart.options}
        series={optionGraphChart.series}
        type="bar"
        height="100%"
        width="104%"
      />
    </div>
  );
};

export default GraphMomentum;
