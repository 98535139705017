import React, { useState } from "react";
import { Grid, Typography } from "@mui/material/";
import CategoryItemList from "./CategoryItemList";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpPopover from "../HelpPopover";
import { parseDateBackend } from "../../../Utils/commonWithDates";
function CategoryList({ categoriesFiltered, onSelectCategory }) {
  const [t, i18n] = useTranslation("global");
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  let content = <></>;
  if (categoriesFiltered.length > 0) {
    const orderedList = categoriesFiltered?.sort(
      (x, y) => parseDateBackend(y.write_date) - parseDateBackend(x.write_date)
    );
    content = orderedList.map((category, index) => (
      <CategoryItemList
        key={index}
        index={index}
        category={category}
        onSelectItem={onSelectCategory}
      ></CategoryItemList>
    ));
  } else {
  }
  const open = Boolean(anchorEl);

  return (
    <Grid xs={12}>
      <Grid xs={12}>
        <div
          style={{
            marginTop: "15px",
            marginBottom: "15px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {`${t("dashboard_component.loaded_categories")} `}
          </Typography>
          <ErrorOutlineIcon
            layout="intrinsic"
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onClick={handlePopoverOpen}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            style={{ marginLeft: "5px", cursor: "pointer"  }}
            width={25}
            height={25}
          />
          <HelpPopover
            open={open}
            anchorEl={anchorEl}
            onPopoverClose={handlePopoverClose}
            message={t("dashboard_component.category_popover")}
          ></HelpPopover>
        </div>
      </Grid>
      <Grid container direction="column">
        {content}
      </Grid>
    </Grid>
  );
}

export default CategoryList;
