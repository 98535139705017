//CREATE BRAND
export const SET_STEP = "SET_STEP";
export const SET_STAGE = "SET_STAGE";
export const SET_STATUS = "SET_STATUS";
export const SET_CATEGORY_ID = "SET_CATEGORY_ID";
export const SELECT_CATEGORY_ID_TO_CREATE_OR_UPDATE =
  "SELECT_CATEGORY_ID_TO_CREATE_OR_UPDATE";
export const CLEAR_CATEGORY_ID_TO_CREATE_OR_UPDATE =
  "CLEAR_CATEGORY_ID_TO_CREATE_OR_UPDATE";
  export const CLOSE_CATEGORY = "CLOSE_CATEGORY";

  export const CLEAR_RESPONSE_INITIAL_SETUP = "CLEAR_RESPONSE_INITIAL_SETUP";
  

