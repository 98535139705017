import { setCurrentView } from "../Redux/modules/layout/actions";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(async ({ configModal }) => {
    try {
      setIsOpen(!isOpen);
    } catch (e) {
    } finally {
    }
  }, []);
  const cancelModal = () => {
    setIsOpen(!isOpen);
  };
  const closeModal = () => {
    cancelModal();
  };
  const okModal = (configModal) => {
    setIsOpen(!isOpen);
  };
  
  return { openModal, isOpen, cancelModal, closeModal , okModal};

}


