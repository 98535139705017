import React from "react";
import { SvgIcon } from "@mui/material";

export function GraphCurvesIcon(props) {
  return (
    <SvgIcon {...props}>
        <g id="Grupo_1799"
        data-name="Grupo 1799"
        transform="matrix(0.8,0,0,0.8,0,0)"
        >
    <g
    id="Grupo_1795"
    data-name="Grupo 1795"

    clip-path="url(#clip-path)">
      <path
      id="Trazado_2800"
      data-name="Trazado 2800"
      d="M15.281,30.566h0A15.281,15.281,0,0,1,0,15.283v0A15.3,15.3,0,0,1,15.281,0h0a15.283,15.283,0,0,1,0,30.566m0-29.293A14.023,14.023,0,0,0,1.273,15.279l-.637,0h.637a14.007,14.007,0,0,0,14.005,14.01h0a14.01,14.01,0,0,0,0-28.02ZM.637,15.283h0Z" transform="translate(0 0)" fill="#1f1c36"/>
      <path
      id="Trazado_2801"
      data-name="Trazado 2801"
      d="M27.191,28.011H10.637A.637.637,0,0,1,10,27.375V14.641a.637.637,0,0,1,1.273,0v12.1H27.191a.637.637,0,1,1,0,1.273" transform="translate(-3.633 -5.088)" fill="#1f1c36"/>
      <path
      id="Trazado_2802"
      data-name="Trazado 2802"
      d="M18.853,25.274a1.367,1.367,0,0,1-1.231-.772l-2.405-3.609a.637.637,0,0,0-.847-.2L10.945,22.65a.637.637,0,1,1-.632-1.105l3.424-1.958a1.918,1.918,0,0,1,2.538.6l2.434,3.651a.6.6,0,0,1,.058.108.09.09,0,0,0,.169-.008.7.7,0,0,1,.046-.108l2.884-5.3a1.914,1.914,0,0,1,1.406-.977l3.82-.546a.637.637,0,1,1,.18,1.261l-3.82.546a.64.64,0,0,0-.468.325L20.126,24.4a1.363,1.363,0,0,1-1.273.879" transform="translate(-3.63 -6.177)" 
      fill="#1f1c36"/>
    </g>
  </g>
    </SvgIcon>
  );
}

{/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="31" height="31" viewBox="0 0 31 31">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectángulo_2989" data-name="Rectángulo 2989" width="31" height="31" transform="translate(0 -0.441)" fill="#1f1c36"/>
    </clipPath>
  </defs>
  <g id="Grupo_1799" data-name="Grupo 1799" transform="translate(0 0.441)">
    <g id="Grupo_1795" data-name="Grupo 1795" transform="translate(0 0)" clip-path="url(#clip-path)">
      <path 
      id="Trazado_2800" data-name="Trazado 2800" d="M15.281,30.566h0A15.281,15.281,0,0,1,0,15.283v0A15.3,15.3,0,0,1,15.281,0h0a15.283,15.283,0,0,1,0,30.566m0-29.293A14.023,14.023,0,0,0,1.273,15.279l-.637,0h.637a14.007,14.007,0,0,0,14.005,14.01h0a14.01,14.01,0,0,0,0-28.02ZM.637,15.283h0Z" transform="translate(0 0)" fill="#1f1c36"/>
      <path id="Trazado_2801" data-name="Trazado 2801" d="M27.191,28.011H10.637A.637.637,0,0,1,10,27.375V14.641a.637.637,0,0,1,1.273,0v12.1H27.191a.637.637,0,1,1,0,1.273" transform="translate(-3.633 -5.088)" fill="#1f1c36"/>
      <path id="Trazado_2802" data-name="Trazado 2802" d="M18.853,25.274a1.367,1.367,0,0,1-1.231-.772l-2.405-3.609a.637.637,0,0,0-.847-.2L10.945,22.65a.637.637,0,1,1-.632-1.105l3.424-1.958a1.918,1.918,0,0,1,2.538.6l2.434,3.651a.6.6,0,0,1,.058.108.09.09,0,0,0,.169-.008.7.7,0,0,1,.046-.108l2.884-5.3a1.914,1.914,0,0,1,1.406-.977l3.82-.546a.637.637,0,1,1,.18,1.261l-3.82.546a.64.64,0,0,0-.468.325L20.126,24.4a1.363,1.363,0,0,1-1.273.879" transform="translate(-3.63 -6.177)" 
      fill="#1f1c36"/>
    </g>
  </g>
</svg> */}