import React, { useState, useEffect } from "react";
import { Grid, Stack, Box } from "@mui/material";
import TableDataLoadValues from "./TableDataLoadValues";
import FormDataLoadInputs from "./FormDataLoadInputs";
import { periodWidthValues } from "../../dataCommon";
import Tabs from "../../../../Components/Tabs";
import { useTranslation } from "react-i18next";
import { SweepingIcon } from "../../../../Components/CustomIcons/SweepingIcon";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import { DownloadIcon } from "../../../../Components/CustomIcons";
import { useDispatch, useSelector } from "react-redux";
import { setFormDataCategoryData } from "../../../../Redux/modules/categoryData/actions";
import {
  getCategoryDataFormData,
  getCategoryDataRows,
} from "../../../../Redux/modules/categoryData/selectors";
import { getCategoryDataValues } from "./../../../../Redux/modules/categoryData/selectors";
import { calculateRows } from "../../../../Utils/commonWithDates";
import {
  truncateNumber,
  formatNumber,
  monthDiff,
  exportExel,
  getTableStyle,
  PrintElem,
} from "../../../../Utils";
const tables = [
  "total_subcategory",
  "sales_units",
  "sales_price",
  "distribution",
];

const totalCategoryCols = [
  { code: "sales_units", name: "Total Q", id: 1 },
  { code: "sales_price", name: "Total P", id: 2 },
];

const FormDataLoad = ({
  onDataLoadCreated,
  onDataLoadUpdated,
  setCurrentDataLoad,
  category,
}) => {
  const dispatch = useDispatch();
  const tempDataRows = useSelector(getCategoryDataRows);
  const tempValues = useSelector(getCategoryDataValues);
  const [t, i18n] = useTranslation("global");
  const tempDataLoad = useSelector(getCategoryDataFormData);
  const dataTableRef = React.useRef(null);

  const buildTabs = () => {
    const tabs = tables.map((tab) => {
      return {
        content: <></>,
        name: t(`initial_setup.forms.common_inputs.${tab}`),
      };
    });
    return tabs ? tabs : [];
  };
  const styleBox = {
    boxShadow: "0px 3px 6px #00000029 ",
    borderRadius: "2%",
  };

  const getValue = (row, col, tableType, subcategoryId) => {
    let filterType =
      tableType === "total_subcategory" ? "subcategory_id" : "sku_id";

    let searchId = tableType === "total_subcategory" ? subcategoryId : col.id;

    const data =
      tempValues &&
      tempValues.find(
        (x) => x.period_index === row.period_index && x[filterType] === searchId
      );
    return data
      ? data[tableType === "total_subcategory" ? col.code : tableType]
      : "";
  };

  const handleCleanValues = () => {
    dispatch(
      setFormDataCategoryData({
        propertyName: "cleanValues",
        value: [],
      })
    );
  };
  const handleCopyValues = () => {
    const columns = tempDataLoad?.columns;
    let clipboardHeader = `#\t${t(`initial_setup.forms.common_inputs.date`)}\t`;
    for (const col of columns) {
      let name = "";
      if (col?.brand_id) {
        name = `${col.brand_id && col.brand_id[1]} ${col.name} ${
          col.unit_weight
        } ${col.uom_id && col.uom_id[1]}`;
      } else {
        name = col.name;
      }
      clipboardHeader += `${name}\t`;
    }

    clipboardHeader += `\n`;
    let clipboardBody = "";
    for (const row of tempDataRows) {
      let tempClipboardBody = `${row.period_index}\t${row.date}\t`;
      for (const col of columns) {
        tempClipboardBody += `${getValue(
          row,
          col,
          tempDataLoad.tableType,
          tempDataLoad.subcategoryId
        )}\t`;
      }
      tempClipboardBody += `\n`;
      clipboardBody += tempClipboardBody;
    }
    const clipboard = clipboardHeader + clipboardBody;
    navigator.clipboard.writeText(clipboard);
  };
  const handleDownloadValues = () => {
    exportExel(dataTableRef.current.lastChild, 'dataLoad_'+category.name, (e) => {

    });
  };
  const handleChangeTab = (e) => {
    const tableType = tables[e];

    dispatch(
      setFormDataCategoryData({
        propertyName: "columns",
        value:
          tableType !== "total_subcategory"
            ? category.sku_ids.filter(
                (sku) => sku.subcategory_id[0] === tempDataLoad?.subcategoryId
              )
            : totalCategoryCols,
      })
    );
    dispatch(
      setFormDataCategoryData({
        propertyName: "tableType",
        value: tableType,
      })
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} sx={styleBox}>
        <FormDataLoadInputs
          category={category}
          tempDataLoad={tempDataLoad}
        ></FormDataLoadInputs>
      </Grid>
      <Grid item xs={9}>
        <Grid
          item
          sx={{
            boxShadow: "0px 3px 6px #00000029 ",
            borderRadius: "2%",
            height: "100%",
            width: "100%",
            padding: "2%",
            margin: "0%",
          }}
          overflow="overlay"
        >
          <Stack direction="row">
            <Tabs
              defaultValue={tables.indexOf(tempDataLoad?.tableType)}
              setTabSelected={handleChangeTab}
              tabs={buildTabs()}
              notContentDynamicTabs
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <ButtonItemColumn
                sx={{ width: "10%" }}
                onClick={handleCopyValues}
                tooltip={t("components_globals.button_copy")}
                icon={
                  <ContentCopyIcon
                    className="iconButton strokeLigth"
                    sx={{ fontSize: "1.2rem" }}
                  />
                }
              ></ButtonItemColumn>
              <ButtonItemColumn
                sx={{ width: "20%" }}
                onClick={handleDownloadValues}
                tooltip={t("components_globals.button_download")}
                icon={
                  <DownloadIcon
                    className="iconButton strokeLigth"
                    sx={{ fontSize: "1.2rem" }}
                  />
                }
              ></ButtonItemColumn>
              <ButtonItemColumn
                sx={{ width: "10%" }}
                onClick={handleCleanValues}
                tooltip={t("components_globals.button_clear")}
                icon={
                  <SweepingIcon
                    className="iconButton "
                    sx={{ fontSize: "1.2rem" }}
                  />
                }
              ></ButtonItemColumn>
            </div>
          </Stack>
          <TableDataLoadValues
            dataTableRef={dataTableRef}
            subcategoryId={tempDataLoad?.subcategoryId}
            tableType={tempDataLoad?.tableType}
            rows={tempDataRows ? tempDataRows : []}
            columns={tempDataLoad?.columns}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormDataLoad;
