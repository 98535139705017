export const customersState = state => state.customers;

export const getLoadingCustomers = state => customersState(state).loading;

export const getCustomer = state => customersState(state).customers

export const getCustomersLoaded = state => customersState(state).customers

export const getResponseCustomers = (state) => customersState(state).response;

