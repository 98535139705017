import { SweepingIcon } from "../../../../Components/CustomIcons/SweepingIcon";
import ButtonItemColumn from "../../../../Components/Button/ButtonItemColumn";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { truncateNumber, formatNumber } from "../../../../Utils";
import Button from "../../../../Components/Button";
import Input from "../../../../Components/Input";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import {
  getSimulateCurves,
  setSimulatorData,
  clearCurvesSimulationData,
} from "../../../../Redux/modules/what-if/curves/actions";
import { useEffect, useState } from "react";
import {
  getCurvesLoaded,
  getCurvesFunctionType,
  getSimulatorData,
} from "../../../../Redux/modules/what-if/curves/selectors";

const ContainerScroll = styled("div")(({ theme }) => ({
  height: "345px",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "1px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "transparent",
  },
  "&::-webkit-scrollbar-track ": {
    backgroundColor: "transparent",
  },
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: "13px",
    maxWidth: "120px",
    textAlign: "center",
    color: "#545050",
    backgroundColor: "#dbdbdbeb",
  },
  [`& .${tooltipClasses.tooltip} span::before`]: {
    backgroundColor: "#dbdbdbeb",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  border: "none",

  [`&.${tableCellClasses.head}`]: {
    background: `${theme.palette.custom.secondary}`,
    color: `${theme.palette.custom.primary}`,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: "2rem",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  padding: 0,
  border: "none",
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.custom.primary,
  },
}));

const styleCell = {
  borderRadius: "0.5rem 0 0 0.5rem",
  maxWidth: "80px",
  paddingBlock: "0.6rem",
  paddingLeft: "1rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const cellNotStyle = {
  borderRadius: "0.5rem 0 0 0.5rem",
  maxWidth: "80px",
  paddingBlock: "0.6rem",
  paddingLeft: "1rem",
  whiteSpace: "wrap",
  overflow: "hidden",
};

const Simulator = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const simulatorData = useSelector(getSimulatorData);
  const currentCurves = useSelector(getCurvesLoaded);
  const curveFunction = useSelector(getCurvesFunctionType);
  const [clean, setClean] = useState(false);

  const [ellipsis, setEllipsis] = useState(true);
  const [id, setId] = useState("");
  let minMaxPrice = [...currentCurves[0].table_limits];

  if (currentCurves[1]) {
    minMaxPrice = [
      ...currentCurves[0].table_limits,
      ...currentCurves[1].table_limits,
    ];
    minMaxPrice = [Math.min(...minMaxPrice), Math.max(...minMaxPrice)];
  }

  const [errorMsg, setErrorMsg] = useState("");
  const handleEllipsis = (bol, id) => {
    setEllipsis(bol);
    setId(id);
  };
  const [showMsg, setShowMsg] = useState(false);

  const handleMsg = (e) => {
    if (
      (minMaxPrice[0] > e.target.value || minMaxPrice[1] < e.target.value) &&
      e.target.value !== ""
    ) {
      setErrorMsg(
        `Rango sugerido: ($${minMaxPrice[0].toFixed(
          1
        )}, $${minMaxPrice[1].toFixed(1)})`
      );
      setShowMsg(true);
    } else {
      setErrorMsg("");
      setShowMsg(false);
    }
  };

  const handleSimulatorInput = (e, row) => {
    // check typed value doesnt contains letters
    const globalRegex = new RegExp("[a-zA-Z]", "g");
    if (globalRegex.test(e.target.value)) return;

    if (e.target.name === "priceSc1" || e.target.name === "priceSc2") {
      dispatch(
        setSimulatorData({
          propertyName: e.target.name,
          propertyValue: e.target.value,
        })
      );
    } else if (e.target.name === "variablesSc1") {
      const newData = simulatorData[e.target.name].map((el) =>
        el.key === row.key ? { ...el, value: e.target.value } : el
      );

      dispatch(
        setSimulatorData({
          propertyName: e.target.name,
          propertyValue: newData,
        })
      );
    } else {
      if (simulatorData[e.target.name]?.some((el) => el.key === row.key)) {
        const newData = simulatorData[e.target.name].map((el) =>
          el.key === row.key ? { ...el, value: e.target.value } : el
        );

        dispatch(
          setSimulatorData({
            propertyName: e.target.name,
            propertyValue: newData,
          })
        );
      } else {
        dispatch(
          setSimulatorData({
            propertyName: e.target.name,
            propertyValue: [
              ...simulatorData[e.target.name],
              { key: row.key, value: e.target.value },
            ],
          })
        );
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //check sending info with not empty values
    const notEmptyValues1 = simulatorData.variablesSc1.filter(
      (el) => el.value !== ""
    );
    const notEmptyValues2 = simulatorData.variablesSc2.filter(
      (el) => el.value !== ""
    );
    let newData = {
      variablesSc1: notEmptyValues1,
      variablesSc2: notEmptyValues2,
      table_limits: [],
    };

    if (simulatorData.priceSc1 !== "") {
      newData.priceSc1 = simulatorData.priceSc1;
      minMaxPrice.push(newData.priceSc1 * 1);
    }
    if (simulatorData.priceSc2 !== "") {
      newData.priceSc2 = simulatorData.priceSc2;
      minMaxPrice.push(newData.priceSc2 * 1);
    }
    // minMaxPrice = [Math.min(...minMaxPrice), Math.max(...minMaxPrice)]
    minMaxPrice = currentCurves[0].table_limits;
    newData.table_limits = minMaxPrice;

    dispatch(getSimulateCurves.trigger(newData));
    setShowMsg(false);
  };

  const getValue2 = (key) => {
    const variable =
      simulatorData.variablesSc2.length > 0
        ? simulatorData.variablesSc2.find((x) => x.key === key)
        : null;

    return variable ? variable.value : "";
  };

  const getCurvesValue = (index, functionType, namePath, nameProperty) => {
    if (currentCurves && currentCurves.length > 0 && currentCurves[index]) {
      return currentCurves[index][functionType][namePath][nameProperty];
    } else {
      return 0;
    }
  };

  return (
    <Box
      sx={{
        boxShadow: "0px 3px 6px #00000029 ",
        borderRadius: "10px",
        overflow: "hidden",
        height: "648px",
      }}
    >
      <Table>
        <TableHead sx={{ height: "3.8rem" }}>
          <StyledTableRow>
            <StyledTableCell align="center" colSpan={3}>
              <Typography
                variant="subtitle1"
                sx={{
                  textAlign: "left",
                  ml: "2rem",
                }}
              >
                {t("price_management.curves_component.simulator")}
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <ButtonItemColumn
                sx={{ width: "10%" }}
                onClick={() => {
                  dispatch(clearCurvesSimulationData());
                }}
                tooltip={t("components_globals.button_clear")}
                icon={
                  <SweepingIcon
                    className="iconButtonPrimary strokeLigth"
                    sx={{ fontSize: "1.3rem", marginRight: "1rem" }}
                  />
                }
              ></ButtonItemColumn>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
      </Table>
      <div
        style={{
          padding: "1rem",
        }}
      >
        <ContainerScroll>
          <Table
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0 6px",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <TableCell
                  align="left"
                  sx={{
                    height: "1rem",
                    border: "none",
                    minWidth: "109px",
                    padding: "8px",
                    paddingLeft: "1rem",
                  }}
                >
                  <Typography fontWeight={600}>
                    {t("price_management.common_label.variables")}
                  </Typography>
                </TableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    borderRadius: "0.5rem 0 0 0.5rem",
                    paddingInline: "0.2rem",
                  }}
                >
                  {t("price_management.curves_component.scenery1")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t("price_management.curves_component.scenery2")}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  size="small"
                  sx={{
                    borderRadius: "0 0.5rem 0.5rem 0",
                    padding: 0,
                    minWidth: 45,
                    whiteSpace: "wrap",
                    paddingRight: "0.2rem",
                  }}
                >
                  {t("price_management.curves_component.var")}
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow key="precio">
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="left"
                  sx={{
                    borderRadius: "0.5rem 0 0 0.5rem",
                    paddingLeft: "1rem",
                  }}
                >
                  {t("price_management.common_label.price")}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ padding: 0, minWidth: 50 }}
                >
                  <Input
                    label="scenary1"
                    variant="secondary"
                    name="priceSc1"
                    input
                    type="tel"
                    placeholder="0"
                    value={simulatorData.priceSc1}
                    onChange={handleSimulatorInput}
                    onBlur={handleMsg}
                  />
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ padding: 0, minWidth: 50 }}
                >
                  <Input
                    label="scenary2"
                    variant="secondary"
                    name="priceSc2"
                    placeholder={simulatorData.priceSc1}
                    input
                    type="tel"
                    value={simulatorData.priceSc2}
                    onChange={handleSimulatorInput}
                    onBlur={handleMsg}
                  />
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    borderRadius: "0 0.5rem 0.5rem 0",
                  }}
                >
                  <StyledTooltip
                    title={`${errorMsg}`}
                    placement="right"
                    open={showMsg}
                    arrow
                  >
                    <span
                      style={{
                        padding: 0,
                        maxWidth: 40,
                        display: "block",
                        overflowX: "hidden",
                        margin: "auto",
                      }}
                    >
                      {`${truncateNumber(
                        ((simulatorData?.priceSc2 - simulatorData?.priceSc1) /
                          simulatorData?.priceSc1) *
                          100,
                        0
                      )}%`}
                    </span>
                  </StyledTooltip>
                </StyledTableCell>
              </StyledTableRow>

              {simulatorData.variablesSc1 &&
                simulatorData.variablesSc1.length > 0 &&
                simulatorData.variablesSc1?.map((row, index) => (
                  <StyledTableRow key={row.key}>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      align="left"
                      onMouseEnter={() => handleEllipsis(true, row.key)}
                      onMouseLeave={() => handleEllipsis(false, "")}
                      sx={ellipsis && id === row.key ? cellNotStyle : styleCell}
                    >
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Input
                        variant="secondary"
                        name="variablesSc1"
                        placeholder="0"
                        input="numeric"
                        value={simulatorData["variablesSc1"][index]?.value}
                        onChange={(e) => handleSimulatorInput(e, row)}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Input
                        variant="secondary"
                        name="variablesSc2"
                        placeholder={simulatorData["variablesSc1"][index]?.value}
                        input="numeric"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        value={getValue2(row.key)}
                        onChange={(e) => handleSimulatorInput(e, row)}
                        styles={{ mozAppearance: "textfield" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{
                        borderRadius: "0 0.5rem 0.5rem 0",
                      }}
                    >
                      <span
                        style={{
                          padding: 0,
                          maxWidth: 40,
                          display: "block",
                          margin: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        {`${truncateNumber(
                          ((getValue2(row.key) - row.value) / row.value) * 100,
                          0
                        )}%`}
                      </span>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </ContainerScroll>
        <div
          style={{
            borderTop: "1px solid #ccc8c8",
            paddingTop: "0.5rem",
            marginTop: "0.5rem",
            textAlign: "right",
          }}
        >
          <Table
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0 2px",
            }}
          >
            <TableHead>
              <StyledTableRow>
                <TableCell
                  align="left"
                  sx={{
                    background: (theme) => `${theme.palette.custom.background}`,
                    border: "none",
                    minWidth: "100px",
                    padding: "8px",
                    paddingLeft: "1rem",
                  }}
                ></TableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    borderRadius: "0.5rem 0 0 0.5rem",
                    paddingBlock: "8px",
                  }}
                >
                  {t("price_management.curves_component.scenery1")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t("price_management.curves_component.scenery2")}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    borderRadius: "0 0.5rem 0.5rem 0",
                    minWidth: 46,
                    paddingRight: "0.2rem",
                  }}
                >
                  {t("price_management.curves_component.var")}
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow
                key={"current-quantity"}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="left"
                  sx={{
                    borderRadius: "0.5rem 0 0 0.5rem",
                    paddingLeft: "1rem",
                  }}
                >
                  {t("price_management.common_label.quantity")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {formatNumber(
                    getCurvesValue(0, curveFunction, "user_point", "quantity"),
                    true
                  )}
                </StyledTableCell>
                {currentCurves &&
                currentCurves.length > 0 &&
                currentCurves[1] ? (
                  <StyledTableCell
                    align="center"
                    sx={{
                      borderRadius: "0 0.5rem 0.5rem 0",
                    }}
                  >
                    {formatNumber(
                      getCurvesValue(
                        1,
                        curveFunction,
                        "user_point",
                        "quantity"
                      ),
                      true
                    )}
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center">0</StyledTableCell>
                )}
                <StyledTableCell align="center">
                  {`${truncateNumber(
                    ((getCurvesValue(
                      1,
                      curveFunction,
                      "user_point",
                      "quantity"
                    ) -
                      getCurvesValue(
                        0,
                        curveFunction,
                        "user_point",
                        "quantity"
                      )) /
                      getCurvesValue(
                        0,
                        curveFunction,
                        "user_point",
                        "quantity"
                      )) *
                      100,
                    0
                  )}%`}
                </StyledTableCell>
              </StyledTableRow>

              <StyledTableRow
                key={"current-sale"}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                    marginTop: "1rem",
                  },
                }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="left"
                  sx={{
                    borderRadius: "0.5rem 0 0 0.5rem",
                    paddingLeft: "1rem",
                  }}
                >
                  {t("price_management.common_label.value")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {formatNumber(
                    getCurvesValue(0, curveFunction, "user_point", "value"),
                    true
                  )}
                </StyledTableCell>

                {currentCurves &&
                currentCurves.length > 0 &&
                currentCurves[1] ? (
                  <StyledTableCell
                    align="center"
                    sx={{
                      borderRadius: "0 0.5rem 0.5rem 0",
                    }}
                  >
                    {formatNumber(
                      getCurvesValue(1, curveFunction, "user_point", "value"),
                      true
                    )}
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center">0</StyledTableCell>
                )}
                <StyledTableCell align="center">
                  {`${truncateNumber(
                    ((getCurvesValue(1, curveFunction, "user_point", "value") -
                      getCurvesValue(0, curveFunction, "user_point", "value")) /
                      getCurvesValue(0, curveFunction, "user_point", "value")) *
                      100,
                    0
                  )}%`}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow key={"current-profit"}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="left"
                  sx={{
                    borderRadius: "0.5rem 0 0 0.5rem",
                    paddingLeft: "1rem",
                  }}
                >
                  {t("price_management.common_label.profit")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {formatNumber(
                    getCurvesValue(0, curveFunction, "user_point", "profit"),
                    true
                  )}
                </StyledTableCell>

                {currentCurves &&
                currentCurves.length > 0 &&
                currentCurves[1] ? (
                  <StyledTableCell align="center">
                    {formatNumber(
                      getCurvesValue(1, curveFunction, "user_point", "profit"),
                      true
                    )}
                  </StyledTableCell>
                ) : (
                  <StyledTableCell align="center">0</StyledTableCell>
                )}
                <StyledTableCell
                  align="center"
                  sx={{
                    borderRadius: "0 0.5rem 0.5rem 0",
                  }}
                >
                  {`${truncateNumber(
                    ((getCurvesValue(1, curveFunction, "user_point", "profit") -
                      getCurvesValue(
                        0,
                        curveFunction,
                        "user_point",
                        "profit"
                      )) /
                      getCurvesValue(
                        0,
                        curveFunction,
                        "user_point",
                        "profit"
                      )) *
                      100,
                    0
                  )}%`}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
          <Button
            fullWidth
            style={{ maxWidth: "150px", margin: "0.4rem auto 1rem" }}
            variant="primary"
            onClick={handleSubmit}
          >
            {t("components_globals.button_simulate")}
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default Simulator;
