import * as React from "react";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const ViewsDatePicker = (props) => {

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          {...props}
        />
      </LocalizationProvider>
    );
}

export default ViewsDatePicker;