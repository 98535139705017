import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { ListItemIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../Redux/modules/auth/actions";
import { setInitialState } from "../../../Redux/modules/app/actions";
import { setCurrentPage } from "../../../Redux/modules/layout/actions";

const FloatingMenu = ({ children, options }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [t, i18n] = useTranslation("global");
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (option) => {
    if (option.text === t("drawer_component.logout")) {
      dispatch(logoutUser());
      dispatch(setInitialState());
    } else {
      dispatch(setCurrentPage(option.path));
      navigate(option.path);
    }
  };

  return (
    <div>
      <List component="nav" aria-label="Device settings">
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          {children}
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
        sx={{
          opacity: "0.7",
          "& .MuiPaper-root": {
            backgroundColor: "black",
            color: "white",
            marginTop: "20px",
            padding: "20px",
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleClickMenu(option)}>
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText>{option.text}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FloatingMenu;
