export const initialState = {
  loading: false,
  showCategorySelectInHeader: false,
  currentPage: "/",
  currentView: "",
  pagesWithSelectCategory: [
    "/prices-lab/what-if/curves",
    "/prices-lab/what-if/optimal-prices",
    "/prices-lab/what-if/trend",
    "/prices-lab/sniper/matriz-e",
    "/prices-lab/sniper/momentum",
    "/promotion-lab/promotion-planning/promotions",
    "/promotion-lab/promotion-planning/volumes",
    "/promotion-lab/promotion-planning/pl",
  ],
  menuOptions: [
    {
      icon: "GridViewIcon",
      text: "drawer_component.categories",
      id: 1,
      code: "categories",
      path: "/",
      locked: true,
      canLocked: true,
      children: [],
    },
    {
      icon: "PaidIcon",
      text: "drawer_component.prices_lab",
      id: 2,
      code: "prices_lab",
      locked: true,
      canLocked: true,
      children: [
        {
          text: "drawer_component.what_if",
          id: 21,
          code: "what_if",
          path: "/prices-lab/what-if",
          locked: true,
          canLocked: true,
          children: [],
        },
        {
          text: "drawer_component.sniper",
          id: 22,
          code: "sniper",
          path: "/prices-lab/sniper",
          locked: true,
          canLocked: true,
          children: [],
        },
      ],
    },
    {
      icon: "StreamLineIcon",
      text: "drawer_component.promo_lab",
      id: 3,
      code: "promo_lab",
      locked: true,
      canLocked: true,
      children: [
        // {
        //   text:"drawer_component.customer_config",
        //   id: 31,
        //   code: "customer_config",
        //   locked: true,
        //   canLocked: true,
        //   path: "/promotion-lab/configure-client",
        //   children: [],
        // },
        {
          text: "drawer_component.promotion_planning",
          id: 32,
          code: "promo_plan",
          locked: true,
          canLocked: true,
          path: "/promotion-lab/promotion-planning",
          children: [],
        },
        // {
        //   text: "drawer_component.sell_in_sell_out",
        //   id: 32,
        //   code: "sell_in_sell_out",
        //   locked: true,
        //   canLocked: true,
        //   path: "/promotion-lab/sellIn-sellOut",
        //   children: [],
        // },
      ],
    },
    {
      icon: "ForecastIcon",
      text: "drawer_component.portfolio",
      id: 4,
      code: "portfolio",
      path: "/portfolio",
      locked: true,
      canLocked: true,
      children: [],
    },
    {
      icon: "SummaryIcon",
      text: "drawer_component.analysis",
      id: 5,
      code: "analysis",
      locked: false,
      canLocked: false,
      children: [
        {
          text: "drawer_component.charts",
          id: 51,
          code: "charts",
          locked: false,
          canLocked: false,
          path: "/analysis/charts",
          children: [],
        },
        {
          text: "drawer_component.reports",
          id: 52,
          code: "reports",
          locked: false,
          canLocked: false,
          path: "/analysis/reports",
          children: [],
        },
      ],
    },
  ],
};
