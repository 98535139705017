export const optimalPricesState = (state) => state.optimalPrices;

export const getLoadingOptimalPrices = (state) => optimalPricesState(state).loading;

export const getOptimalsLoaded = (state) => optimalPricesState(state).optimals;

export const getSimulationLoaded = (state) => optimalPricesState(state).simulationOptimals;

export const getOptimalPricesFormData = (state) => optimalPricesState(state).formData;

export const getOptimalPricesSimulationData = (state) => optimalPricesState(state).formData.simulationOptimals;

export const getOptimalPricesFunctionType = (state) => optimalPricesState(state).formData.functionType;

export const getResponseOptimalPrices = (state) => optimalPricesState(state).response;

export const getSimulationCount = (state) => optimalPricesState(state).simulationCount;

