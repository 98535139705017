export const initialState = {
  loading: false,
  formData: {
    functionType: "lineal",
    subcategoryId: 0,
    skuId: 0,
    month: 1,
    period: new Date(),
    maxDate: new Date(),
    minDate: new Date(),
  },
  xyAxis: { id: 6, x: "quantity", y: "price" },
  simulatorData: {
    simulationCount: 0,
    priceSc1: 0,
    priceSc2: "",
    variablesSc1: [],
    variablesSc2: [],
  },
  curves: [],
  countSimulations: 0,
  response: { message: null, error: null },
};
