import * as type from './types';

//login
export function loginUser(action) {
  return {
    type: type.LOGIN_REQUEST,
    payload: action,
  };
}

export function rememberUser(action) {
  return {
    type: type.REMEMBER_USER,
    payload: action,
  };
}

export function logoutUser() {
  return {
    type: type.LOGOUT_REQUEST,
  };
}

export function clearResponseLogin() {
  return {
    type: type.CLEAR_RESPONSE_LOGIN,
  };
}
