export const categoryDataState = (state) => state.categoryData;

export const getLoadingCategoryData = (state) =>
  categoryDataState(state).loading;

export const getResponseCategoryData = (state) =>
  categoryDataState(state).response;

export const getCategoryDataValues = (state) => categoryDataState(state).values;

export const getCategoryDataRows = (state) => categoryDataState(state).rows;

export const getCategoryDataFormData = (state) => categoryDataState(state).formData;

