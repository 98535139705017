import React from "react";
import DataLoad from "./DataLoad";
import CategoryResume from "./CategoryResume";

const Step6 = ({ onNext, onBack, currentCategory, stage, onFinishStep }) => {
  let stageView = <></>;

  switch (stage) {
    case 0:
      stageView = (
        <DataLoad
          currentCategory={currentCategory}
          onNext={() => onNext()}
          onBack={() => onBack()}
        />
      );
      break;
    case 1:
      stageView = (
        <CategoryResume
          currentCategory={currentCategory}
          onNext={() => onNext()}
          onBack={() => onBack()}
          onFinishStep={()=>onFinishStep()}
        />
      );
      break;
    default:
      break;
  }

  return stageView;
};

export default Step6;
