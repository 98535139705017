import { Tabs as TabsMaterial, Tab as TabMaterial } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Tabs = styled(TabsMaterial)(({ theme }) => ({
  ".MuiTab-root.Mui-selected": {
    color: theme.palette.custom.secondary,
  },
  ".MuiTabs-indicator": {
    backgroundColor: theme.palette.custom.secondary,
  },
  ".MuiSvgIcon-root":{
    marginRight:"5px"
  }
}));

export const Tab = styled(TabMaterial)(({ theme, isSelected }) => ({
  textTransform: "capitalize",
  fontWeight: isSelected ? 600 : 500,
  borderBottom: "3px solid #fff",
  color: theme.palette.custom.secondary,
  borderColor: isSelected ? theme.palette.custom.secondary : "#fff",
}));
