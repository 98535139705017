import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setFunctiontype } from '../../../../Redux/modules/what-if/curves/actions';
import { getCurvesFunctionType } from '../../../../Redux/modules/what-if/curves/selectors';

const RadioBtnGroup = () => {
  const [t, i18n] = useTranslation("global");
  const functionType = useSelector(getCurvesFunctionType);
  const dispatch = useDispatch();
  const typeFunctions = ["lineal", "polynomial", "logarithmic"];
  
  return (
    <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              alignContent: "start",
            }}
          >
            <FormLabel
              sx={{
                color: (theme) => `${theme.palette.custom.primaryVariant}`,
                fontWeight: "bold",
                "&.Mui-focused fieldset,&.Mui-focused ": {
                  color: (theme) => `${theme.palette.custom.primaryVariant}`,
                },
                marginRight: "0.5rem",
              }}
              id="radio-subcategories-group-label"
            >
              {`${t("price_management.curves_component.type_function")}`}
            </FormLabel>
            <RadioGroup
              style={{ display: "flex", flexDirection: "row" }}
              aria-labelledby="radio-function-type-label"
              value={functionType}
              name="radio-function-type"
              onChange={(e) => {
                dispatch(
                  setFunctiontype(e.target.value)
                );
              }}
            >
              {typeFunctions.map((option, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: (theme) =>
                              `${theme.palette.custom.primaryVariant}`,
                          },
                        }}
                      />
                    }
                    label={`${t(
                      "price_management.curves_component." + option
                    )}`}
                    sx={{ marginBottom: "-10px" }}
                  />
                );
              })}
            </RadioGroup>
          </div>
  )
}

export default RadioBtnGroup