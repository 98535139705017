import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FooterStep from "../../FooterStep";
import { useSnackbar } from "notistack";
import Tabs from "../../../../Components/Tabs";
import {
  createRelation,
  clearResponseRelation,
  deleteRelation,
  setTabSelected,
} from "../../../../Redux/modules/relations/actions";
import {
  getResponseRelations,
  getTabSelected,
} from "../../../../Redux/modules/relations/selectors";
import RelationBoard from "./RelationBoard";
import { StepWrapper } from "../../styles";

const RelationSku = ({ category, onNext, onBack }) => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const response = useSelector(getResponseRelations);
  const currentTab = useSelector(getTabSelected);

  const onRelationCreated = (e) => {
    const sku = category.sku_ids.find((x) => x.id === e.idFrom);
    const existSku = sku.affects_ids.find((x) => e.idTo.includes(x));
    if (!existSku) {
      dispatch(
        createRelation({
          idFrom: e.idFrom,
          relations: sku.affects_ids.concat(e.idTo),
          categoryId: category.id,
        })
      );
    }
  };

  const onRelationDeleted = (e) => {
    const sku = category.sku_ids.find((x) => x.id === e.idFrom);
    const existSku = sku.affects_ids.find((x) => e.idTo.includes(x));
    if (existSku) {
      dispatch(
        deleteRelation({
          idFrom: e.idFrom,
          relations: sku.affects_ids.filter((x) => !e.idTo.includes(x)),
          categoryId: category.id,
        })
      );
    }
  };

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseRelation());
    }
  }, [response, category]);

  const getRelations = (skus) => {
    let relations = [];
    for (let i = 0; i < skus.length; i++) {
      for (let j = 0; j < skus[i].affects_ids.length; j++) {
        for (let k = 0; k < skus.length; k++) {
          if (skus[i].affects_ids[j] === skus[k].id) {
            relations.push({
              from: { ...skus[i], brand: skus[i].brand_id[0] },
              to: { ...skus[k], brand: skus[k].brand_id[0] },
            });
            break;
          }
        }
      }
    }
    return relations;
  };

  const buildTabs = () => {
    const tabs = category.subcategory_ids.map((subcategory, index) => {
      const brands = category.brand_ids;
      const skus = category.sku_ids
        .filter((item) => subcategory.id === item.subcategory_id[0])
        .map((sku) => {
          const brand = brands.find((x) => x.id === sku.brand_id[0]);
          return {
            ...sku,
            brand: {
              id: sku.brand_id[0],
              name: sku.brand_id[1],
              color: brand && brand.color,
            },
            owner: { id: sku.owner_id[0], name: sku.owner_id[1] },
          };
        });
      const relations = getRelations(skus);
      return {
        content: (
          <RelationBoard
            index={index}
            relations={relations}
            skus={skus}
            onRelationCreated={onRelationCreated}
            onRelationDeleted={onRelationDeleted}
          ></RelationBoard>
        ),
        name: subcategory.name,
      };
    });

    return tabs ? tabs : [];
  };

  return (
    <StepWrapper style={{ paddingTop: "1em" }}>
      <FooterStep onNext={onNext} onBack={onBack}></FooterStep>
      <Tabs
        value={currentTab}
        setTabSelected={(e) => dispatch(setTabSelected(e))}
        tabs={buildTabs()}
      />
    </StepWrapper>
  );
};

export default RelationSku;
