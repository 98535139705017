import * as type from "./types";
import { createVariable, updateVariable } from "./actions";
import { initialState } from "./states";
import { getDateText, parseDateBackend } from "../../../Utils/commonWithDates";
import produce from "immer";

export const variables = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case createVariable.REQUEST:
        draft.loading = true;
        break;
      case createVariable.SUCCESS:
        draft.loading = false;
        draft.response.message =
          "initial_setup.messages.create_variable_success";
        draft.response.error = null;
        break;
      case createVariable.FAILURE:
        draft.loading = false;
        draft.response.message = null;
        draft.response.error = action.error;
        break;
      case type.GET_VARIABLE_REQUEST:
        draft.loading = true;
        draft.response.message = null;
        break;
      case type.GET_VARIABLE_SUCCESS:
        draft.loading = false;
        draft.response.message = "";
        draft.response.error = null;
        break;
      case type.GET_VARIABLE_FAILURE:
        draft.loading = false;
        draft.response.message = null;
        draft.response.error = action.error;
        break;
      case type.DELETE_VARIABLE_REQUEST:
        draft.loading = true;
        draft.response.message = null;
        break;
      case type.DELETE_VARIABLE_SUCCESS:
        draft.loading = false;
        draft.response.message =
          "initial_setup.messages.delete_variable_success";
        draft.response.error = null;
        break;
      case type.DELETE_VARIABLE_FAILURE:
        draft.loading = false;
        draft.response.message = null;
        draft.response.error = null;
        break;
      case updateVariable.REQUEST:
        draft.loading = true;
        break;
      case updateVariable.SUCCESS:
        draft.loading = false;
        draft.response.message =
          "initial_setup.messages.update_variable_success";
        draft.response.error = null;
        break;
      case updateVariable.FAILURE:
        draft.loading = false;
        draft.response.message = null;
        draft.response.error = action.error;
        break;
      case type.GET_VARIABLE_BY_ID_REQUEST:
        draft.loading = true;
        break;
      case type.GET_VARIABLE_BY_ID_SUCCESS:
        draft.loading = false;
        draft.response.error = null;
        draft.variable = action.variable;
        break;
      case type.GET_VARIABLE_BY_ID_FAILURE:
        draft.loading = false;
        draft.response.error = action.message;
        break;
      case type.GET_VALUES_BY_VARIABLE_ID_REQUEST:
        draft.values = [];
        break;
      case type.GET_VALUES_BY_VARIABLE_ID_SUCCESS:
        let periods = [];
        let values = [];
        for (const item of action.data.values) {
          const period = periods.find((x) => x === parseInt(item.period_index));
          if (!period) {
            periods.push(item.period_index);
            values.push({
              date: getDateText(
                action.data.period,
                parseDateBackend(item.date),
                action.data.language
              ),
              period_index: item.period_index,
              value: item.value,
            });
          }
        }
        draft.values = values;
        draft.tempValues = values;
        draft.formData.values = values;

        break;
      case type.GET_VALUES_BY_VARIABLE_ID_FAILURE:
        draft.loading = false;
        draft.response.error = action.message;
        draft.values = [];
        draft.formData.values = [];
        break;
      case type.CLEAR_VARIABLE_DATA:
        draft.response.message = null;
        draft.response.error = null;
        break;
      case type.CLEAR_RESPONSE_VARIABLE:
        draft.response.message = null;
        draft.response.error = null;
        break;
      case type.SET_VARIABLE_VALUES:
        draft.values = action.payload;
        break;
      case type.SET_VARIABLE_TEMP_VALUES:
        draft.tempValues = action.payload.filter((x) => x.id !== -1);
        break;
      case type.SET_VARIABLE_TEMP_VALUE:
        let itemTempFound = draft.tempValues.find(
          (x) => x.period_index === action.payload.period_index
        );
        if (!itemTempFound) {
          const createdItem = {
            period_index: action.payload.period_index,
            value: action.payload.value,
          };
          draft.tempValues.push(createdItem);
        } else {
          itemTempFound.value = action.payload.value;
        }
        break;

      case type.SYNC_VARIABLE_VALUES:
        draft.values = draft.tempValues.filter((x) => x.period_index !== null);
        draft.formData.values = draft.tempValues.filter(
          (x) => x.period_index !== null
        );
        break;

      case type.RESET_VARIABLE_VALUES:
        draft.tempValues = draft.values.filter((x) => x.period_index !== null);
        break;
      case type.SET_FORM_DATA_VARIABLES:
        draft.formData[action.payload.propertyName] = action.payload.value;
        break;

      case type.SET_CURRENT_VARIABLE:
        const variableTemp = action.payload;
        if (variableTemp) {
          draft.formData.id = variableTemp.id;
          draft.formData.abbreviation = variableTemp.description;
          draft.formData.name = variableTemp.name;
          draft.formData.scope = variableTemp.scope;
          draft.formData.periodWidth = variableTemp.period_width;
          draft.formData.completionRule = variableTemp.completion_rule;
          draft.formData.firstPeriod = new Date(
            variableTemp.first_period.split("-").join("/")
          );
          draft.formData.influencesFor =
            variableTemp.influences_for &&
            variableTemp.influences_for.map((x) => x.id);
        } else {
          draft.formData.id = 0;
          draft.formData.name = "";
          draft.formData.abbreviation = "";
          draft.formData.scope = "env";
          draft.formData.influencesFor = [];
          draft.formData.periodWidth = "months";
          draft.formData.completionRule = "repeat";
          draft.formData.firstPeriod = new Date();
          draft.formData.values = [];
        }

        break;
    }
  });
