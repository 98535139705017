import React from "react";
import { SvgIcon } from "@mui/material";

export function PencilWhiteIcon(props) {
    const classPencilWhiteIcon = {
      fill: "none",
      strokelinecap: "round",
      strokelinejoin: "round",
      strokewidth: "1.5",
    };
    return (
      <SvgIcon {...props}>
        <g transform="matrix(1,0,0,1,0,0)">
          <path
            style={classPencilWhiteIcon}
            d="M13.045,14.136l-3.712.531.53-3.713,9.546-9.546A2.25,2.25,0,0,1,22.591,4.59Z"
          />
          <path style={classPencilWhiteIcon} d="M18.348 2.469L21.53 5.651" />
          <path
            style={classPencilWhiteIcon}
            d="M18.75,14.25v7.5a1.5,1.5,0,0,1-1.5,1.5h-15a1.5,1.5,0,0,1-1.5-1.5v-15a1.5,1.5,0,0,1,1.5-1.5h7.5"
          />
        </g>
      </SvgIcon>
    );
  }