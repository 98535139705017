import axios from "axios";

const { REACT_APP_API_URL } = process.env;

export const callApiAxios = (method, path, token, params, body) => {
  try {
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (token) {
      headers = { ...headers, Authorization: token };
    }

    switch (method) {
      case "POST-PARAMS":
        return axios({
          method: "POST",
          url: `${REACT_APP_API_URL}/api/${path}`,
          params: params,
          headers: headers,
        })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw error.response?.data;
          });
        break;

      case "PATCH":
        return axios({
          method: "PATCH",
          url: `${REACT_APP_API_URL}/api/${path}`,
          params: params,
          data: JSON.stringify(body),
          headers: headers,
        })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw error.response?.data;
          });
        break;

      case "POST":
        return axios({
          method: "POST",
          url: `${REACT_APP_API_URL}/api/${path}`,
          params: params,
          data: JSON.stringify(body),
          headers: headers,
        })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw error.response?.data;
          });
        break;

      case "GET":
        return axios({
          method: "GET",
          url: `${REACT_APP_API_URL}/api/${path}`,
          params: params,
          headers: headers,
        })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw error.response?.data;
          });
        break;

      case "PUT":
        return axios({
          method: "PUT",
          url: `${REACT_APP_API_URL}/api/${path}`,
          params: params,
          data: JSON.stringify(body),
          headers: headers,
        })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw error.response?.data;
          });
        break;

      case "DELETE":
        return axios({
          method: method,
          url: `${REACT_APP_API_URL}/api/${path}`,
          params: params,
          headers: headers,
        })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            throw error.response?.data;
          });
        break;
      default:
        break;
    }
  } catch (error) {}
};
