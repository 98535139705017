
//CREATE SUB CATEGORY
export const CREATE_SUBCATEGORY_REQUEST = "CREATE_SUBCATEGORY_REQUEST";
export const CREATE_SUBCATEGORY_SUCCESS = "CREATE_SUBCATEGORY_SUCCESS";
export const CREATE_SUBCATEGORY_FAILURE = "CREATE_SUBCATEGORY_FAILURE";

//GET SUBCATEGORY
export const GET_SUBCATEGORIES_REQUEST = "GET_SUBCATEGORIES_REQUEST";
export const GET_SUBCATEGORIES_SUCCESS = "GET_SUBCATEGORIES_SUCCESS";
export const GET_SUBCATEGORIES_FAILURE = "GET_SUBCATEGORIES_FAILURE";
//update by id
export const UPDATE_SUBCATEGORY_REQUEST = "UPDATE_SUBCATEGORY_REQUEST";
export const UPDATE_SUBCATEGORY_SUCCESS = "UPDATE_SUBCATEGORY_SUCCESS";
export const UPDATE_SUBCATEGORY_FAILURE = "UPDATE_SUBCATEGORY_FAILURE";
//delete by id
export const DELETE_SUBCATEGORY_REQUEST = "DELETE_SUBCATEGORY_REQUEST";
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS";
export const DELETE_SUBCATEGORY_FAILURE = "DELETE_SUBCATEGORY_FAILURE";
//CLEAR STATE MESSAGE SUCCESS OR FAILURE
export const CLEAR_RESPONSE_SUBCATEGORY = "CLEAR_RESPONSE_SUBCATEGORY";
//clear all data 
export const CLEAR_ALL_DATA ='CLEAR_ALL_DATA';




