import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import HorusChart from "../../Components/HorusChart";
import CustomModal from "../../Components/Modal/CustomModal";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import { getCategoryLoaded } from "../../Redux/modules/categories/selectors";
import {
  getCurrentCategoryFilteredTree,
  getTreeFilter,
  getSettingsTree
} from "../../Redux/modules/tree/selectors";
import {
  setCategoryFilteredTree,
  setFilterTree,
} from "../../Redux/modules/tree/actions";
import { FilterIcon } from "../../Components/CustomIcons/FilterIcon";

const CssBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.custom.secondary,
  height: "100vh",
  width: "100vw",
  position: "relative",
  overflow: "auto",
  ".close-btn": {
    color: theme.palette.custom.primary,
    position: "absolute",
    top: "1em",
    right: "1em",
    cursor: "pointer",
  },
  ".filter-btn": {
    color: theme.palette.custom.primary,
    position: "absolute",
    top: "1em",
    left: "1em",
    cursor: "pointer",
  },
}));
const CssIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.custom.third,
  color: theme.palette.custom.secondary,
}));

const Tree = (props) => {
  const [t, i18n] = useTranslation("global");
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const dispatch = useDispatch();
  const currentCategory = useSelector(getCategoryLoaded);
  const currentCategoryFiltered = useSelector(getCurrentCategoryFilteredTree);
  const settingsTree = useSelector(getSettingsTree);

  const handleOpen = () => {
    dispatch(setCategoryFilteredTree(currentCategory));
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleCloseFilter = () => setOpenFilter(false);
  const [filterModalSubcategoriesTemp, setFilterModalSubcategoriesTemp] =
    useState("All");

  const handleClearFilter = () => {
    dispatch(setFilterTree({ filter: "All", currentCategory }));
    setFilterModalSubcategoriesTemp("All");
  };
  const handleOkFilter = () => {
    dispatch(
      setFilterTree({ filter: filterModalSubcategoriesTemp, currentCategory })
    );
    setOpenFilter(false);
  };
  const handleFilter = () => {
    setOpenFilter(true);
  };

  const onCheckboxChange = (e, subcategoryId) => {
    if (e.currentTarget.checked) {
      if (filterModalSubcategoriesTemp !== "All") {
        setFilterModalSubcategoriesTemp(
          filterModalSubcategoriesTemp.concat([subcategoryId])
        );
      }
    } else {
      if (filterModalSubcategoriesTemp === "All") {
        const result = currentCategory.subcategory_ids
          .map((x) => x.id)
          .filter((x) => x !== subcategoryId);
        setFilterModalSubcategoriesTemp(result);
      } else {
        setFilterModalSubcategoriesTemp(
          filterModalSubcategoriesTemp.filter((x) => x !== subcategoryId)
        );
      }
    }
  };

  const isSubcategoryChecked = (subcategorId) => {
    if (filterModalSubcategoriesTemp !== "All") {
      return filterModalSubcategoriesTemp.includes(subcategorId);
    }
    return true;
  };


  return (
    <div>
      <CssIconButton onClick={handleOpen}>
        <AccountTreeOutlinedIcon />
      </CssIconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CssBox>
          {currentCategoryFiltered && settingsTree.length > 0 && (
            <HorusChart 
            />
          )}
          <div className="close-btn" onClick={handleClose}>
            <CloseFullscreenIcon />
          </div>
          <div className="filter-btn" onClick={handleFilter}>
            <FilterIcon />
          </div>
          <CustomModal
            customWidth="500px"
            type="default"
            open={openFilter}
            onCloseModal={handleCloseFilter}
            onCancelModal={handleClearFilter}
            onOkModal={handleOkFilter}
            cancelText={t("components_globals.button_clear")}
            acceptText={t("components_globals.button_filter")}
          >
            <div
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              {currentCategory &&
                currentCategory.subcategory_ids.map((subcategory, index) => (
                  <div
                    style={{
                      display: "flex",
                      direction: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Checkbox
                      onChange={(e) => onCheckboxChange(e, subcategory.id)}
                      checked={isSubcategoryChecked(subcategory.id)}
                      key={index}
                    ></Checkbox>
                    {subcategory.name}
                    <div
                      className="color"
                      style={{
                        backgroundColor: subcategory.color,
                        fontWeight: 700,
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        marginRight: "5px",
                        marginLeft: "5px",
                        boxShadow: "0px 1px 3px #00000029",
                      }}
                    ></div>
                  </div>
                ))}
            </div>
          </CustomModal>
        </CssBox>
      </Modal>
    </div>
  );
};

export default Tree;
