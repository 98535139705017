import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  isAuth,
  getLoadingAuth,
  getResponseAuth,
  getRemember,
  getUserRemember,
} from "../../Redux/modules/auth/selectors";
import {
  loginUser,
  rememberUser,
  clearResponseLogin,
} from "../../Redux/modules/auth/actions";

import styled from "styled-components";
import { styled as CustomStyle } from "@mui/material/styles";

import TextField from "../../Components/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import { FloatingMenuLanguages } from "../../Containers/Layout/Header/FloatingMenuLanguages";

import Button from "../../Components/Button";

import Waves from "./AnimateWaves";

import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";

import { useSnackbar } from "notistack";

import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import logo from "../../assets/icons/ForecastingBlancoSVG.svg";
import logoplankton from "../../assets/icons/LogoPlankton.svg";

import {
  LanguageIcon,
  ProfileIcon,
  ViewIcon,
  ViewOffIcon,
} from "../../Components/CustomIcons";
import { useTranslation } from "react-i18next";

const LoginForm = CustomStyle("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.custom.secondary,
  display: "flex",
  flex: 1,
  maxHeight: "100vh",
  height: "100vh",
  width: "100vw",
  padding: "0 auto",
  flexDirection: "column",
  alignItems: "center",
  "@media (min-width:768px)": {
    backgroundSize: "100%",
  },
  overflow: "hidden",
}));

const HeaderWrapper = styled.div`
  z-index: 1;
  flex-direction: row;
  display: flex;
  max-width: 1184px;
  width: 100%;
  padding: 40px 0px;
  height: 150px;
  justify-content: space-between;
`;

const CssDivLanguage = CustomStyle("div")(({ theme }) => ({
  zIndex: 1,
  display: "flex",
  color: theme.palette.custom.primary,
  backgroundColor: theme.palette.custom.secondary,
  alignItems: "center",
  justifyContent: "center",
  width: "45px",
  height: "45px",
  borderRadius: "10px",
  cursor: "pointer",
}));

const DivContent = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: row;
  max-width: 1184px;
  width: 100%;
  height: 100vh;
  text-align: center;
  .div-form {
    padding: 3em 4em;
    display: flex;
    flex: 1;
    width: 50%;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    .p {
      color: #89835f;
    }
  }
  .div-form-input {
    margin-left: 10%;
    width: 40%;
    font-size: 2em;
  }
`;

const DivFooter = styled.div`
    z-index: 1;
    position: absolute;
    padding: 1.5em;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
  }`;

function Login() {
  const [t, i18n] = useTranslation("global");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const userIsAuth = useSelector(isAuth);
  const stateRemember = useSelector(getRemember);
  const userRemember = useSelector(getUserRemember);
  const response = useSelector(getResponseAuth);
  const isLoading = useSelector(getLoadingAuth);

  const [passVisibility, setPassVisibility] = useState(true);
  const [user, setUser] = useState(userRemember?.user);
  const [password, setPassword] = useState(userRemember?.password);
  const [remember, setRemember] = useState(stateRemember);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleVisibility = () => {
    setPassVisibility(!passVisibility);
  };
  const handleLogin = () => {
    if (user === undefined || password === undefined) {
      enqueueSnackbar(t("login_component.empty_fields"), { variant: "error" });
    } else {
      const data = {
        user: user,
        password: password,
      };
      if (remember) {
        dispatch(rememberUser(data));
      }
      dispatch(loginUser(data));
    }
  };
  const handleChange = () => {
    setRemember(!remember);
  };

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseLogin());
    }
  }, [response]);
  if (userIsAuth) {
    navigate("/dashboard");
  }
  return (
    <LoginForm>
      <Waves />
      <HeaderWrapper>
        <img src={logo} width={150} height={"auto"}></img>
        <CssDivLanguage>
          <FloatingMenuLanguages marginTop="10px">
            <LanguageIcon style={{ marginRight: "10px" }} />
          </FloatingMenuLanguages>
        </CssDivLanguage>
      </HeaderWrapper>
      <DivContent>
        <div className="div-form">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} md={12}>
              <TextField
                required
                type="text"
                label={t("login_component.user")}
                variant="contrast"
                defaultValue={stateRemember ? userRemember.user : ""}
                onChange={(e) => setUser(e.target.value)}
                InputProps={{ endAdornment: <ProfileIcon /> }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                required
                type={passVisibility ? "password" : "text"}
                label={t("login_component.password")}
                variant="contrast"
                defaultValue={stateRemember ? userRemember.password : ""}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {passVisibility ? (
                        <ViewIcon
                          onClick={handleVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ViewOffIcon
                          onClick={handleVisibility}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={remember}
                      onChange={handleChange}
                      icon={<CircleOutlinedIcon />}
                      checkedIcon={<CircleIcon />}
                      style={{ color: "#6FD1B0" }}
                    />
                  }
                  label={t("login_component.remember_me")}
                  color="primary"
                  style={{ color: "#6FD1B0" }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                variant="contrast"
                onClick={handleLogin}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  <p> {t("login_component.login")} </p>
                )}
              </Button>
              <p className="p">{t("login_component.forgot_password")}</p>
            </Grid>
          </Grid>
        </div>
        <div className="div-form-input">
          <h2>{t("login_component.welcome_to")}</h2>
          <h1> Forecasting Lab</h1>
        </div>
      </DivContent>
      <DivFooter>
        <img src={logoplankton} width={150} height={"auto"}></img>
      </DivFooter>
    </LoginForm>
  );
}

export default Login;
