import React from "react";
import { SvgIcon } from "@mui/material";

export function SearchIcon(props) {
  const classSearchIcon = {
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5px",
  };
  return (
    <SvgIcon {...props} viewBox="0 0 48 48">
      <g transform="matrix(2,0,0,2,0,0)">
        <path
          d="M0.750 9.812 A9.063 9.063 0 1 0 18.876 9.812 A9.063 9.063 0 1 0 0.750 9.812 Z"
          style={classSearchIcon}
          transform="translate(-3.056 4.62) rotate(-23.025)"
        ></path>
        <path d="M16.221 16.22L23.25 23.25" style={classSearchIcon}></path>
      </g>{" "}
    </SvgIcon>
  );
}
