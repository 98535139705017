import React, { useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { styled as MuiStyled } from "@mui/material/styles";

const CssCustomStepper = MuiStyled(Stepper)(({ theme }) => ({
  paddingBottom: "10px",

  "& .MuiSvgIcon-root.Mui-active": {
    color: theme.palette.custom.primary,
    "& .MuiStepIcon-text": {
      fill: theme.palette.custom.secondary,
      fontWeight: "700",
    },
    "& .MuiStepLabel-label": {
      color: theme.palette.custom.secondary,
      fontWeight: "600",
    },
  },
  "& .MuiSvgIcon-root.Mui-completed": {
    color: theme.palette.custom.primary,
    "& .MuiStepIcon-text": {
      fill: theme.palette.custom.secondary,
      fontWeight: "700",
    },
    "& .MuiStepLabel-icon": {
      color: theme.palette.custom.secondary,
      fontWeight: "600",
    },
  },
}));

const CssStepper = MuiStyled(Stepper)(({ theme }) => ({
  color: theme.palette.custom.primary,
  backgroundColor: theme.palette.custom.secondary,
  paddingBottom: "10px",
  "& .MuiStepLabel-root": {
    cursor: "pointer",
  },
  "& .Mui-disabled": {
    color: theme.palette.custom.text,
    "& .MuiStepConnector-line": {
      borderColor: theme.palette.custom.text,
    },
    "& .MuiStepIcon-text": {
      fill: theme.palette.bg.secondary,
      fontWeight: "600",
    },
  },
  "& .Mui-active": {
    cursor: "pointer",
    "&.MuiStepLabel-label": {
      color: theme.palette.custom.secondary,
    },
    "& .MuiStepIcon-text": {
      fill: theme.palette.custom.secondary,
      fontWeight: "600",
    },
    "& .MuiStepConnector-line": {
      borderColor: theme.palette.custom.primary,
    },
  },
  "& .Mui-completed": {
    "&.MuiStepLabel-label": {
      color: theme.palette.custom.secondary,
    },
    "& .MuiStepIcon-text": {
      //fill: 'red',//theme.palette.custom.secondary,
    },
  },
}));

const CustomStepper = ({ steps = [], activeStep = 0, onClickOnStep }) => {
  const [completed, setCompleted] = useState({});
  return (
    <CssCustomStepper activeStep={activeStep} alternativeLabel>
      {steps.map((step, index) => (
        <Step key={index} completed={completed[index]}>
          <StepButton color="inherit" onClick={()=>onClickOnStep(index)}>
            {step}
          </StepButton>
        </Step>
      ))}
    </CssCustomStepper>
  );
};

export default CustomStepper;
