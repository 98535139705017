import { SvgIcon } from "@mui/material";

export function SummaryIcon(props) {
    const style={fill: 'none', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', ...props.style};
    return (
      <SvgIcon {...props}>
        <g>
          <path d="M11.5,1.5h11a1,1,0,0,1,1,1v20a1,1,0,0,1-1,1H1.5a1,1,0,0,1-1-1V2.5a1,1,0,0,1,1-1h3"  style={style}></path>
          <path d="M11.5,10.5c0,.55-.3.661-.659.248L8,7.5,5.156,10.746c-.363.414-.659.3-.659-.247v-9a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1Z"  style={style}></path>
          <path d="M14.5 6.499L20.5 6.499"  style={style}></path>
          <path d="M14.5 10.499L20.5 10.499"  style={style}></path>
          <path d="M3.5 14.499L20.5 14.499"  style={style}></path>
          <path d="M3.5 18.499L19.997 18.499"  style={style}></path>
        </g>
      </SvgIcon>
    );
  }