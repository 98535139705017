import React from "react";
import { SvgIcon } from "@mui/material";

export function StrategyIcon(props) {
  const classStrategyIcon = {
    fill: "none",
    strokeWidth: "1.5px",
    strokeLinecap: "round",
    strokeMiterlimit: "10",
    ...props.style,
  };
  return (
    <SvgIcon style={props.style}  width="30.999" height="30.992" viewBox="0 0 30.999 30.992">
  <g id="Grupo_2015" data-name="Grupo 2015" transform="translate(0 -0.001)">
    <g id="Grupo_2016" data-name="Grupo 2016" transform="translate(0 0)">
      <path id="Trazado_3239" data-name="Trazado 3239" d="M18.652,7.749a1.346,1.346,0,0,1-1.158-2.031L20.471.664a1.348,1.348,0,0,1,2.321,0L25.77,5.719a1.346,1.346,0,0,1-1.159,2.03H18.652ZM21.626,1.3a.05.05,0,0,0-.043.021L18.606,6.374c-.008.059.017.092.046.083h5.958c.054-.023.063-.057.046-.083L21.679,1.319a.089.089,0,0,0-.054-.02" transform="translate(-6.132 -0.001)" fill="#cdcace"/>
      <path id="Trazado_3240" data-name="Trazado 3240" d="M4.549,24.658a1.291,1.291,0,0,1-1.034-.515L.264,19.827a1.277,1.277,0,0,1-.251-.608,1.291,1.291,0,0,1,1.1-1.453l5.35-.753a1.291,1.291,0,0,1,1.373,1.772l-2.1,5.077a1.289,1.289,0,0,1-1.192.8m2.1-6.368-5.353.754,3.253,4.323,2.1-5.076Z" transform="translate(0 -6.024)" fill="#cdcace"/>
      <path id="Trazado_3241" data-name="Trazado 3241" d="M18.576,38.867a.646.646,0,0,1-.646-.646V36.709A14.29,14.29,0,0,0,9.081,23.466a.646.646,0,1,1,.495-1.193,15.58,15.58,0,0,1,9.646,14.436v1.512a.646.646,0,0,1-.646.646" transform="translate(-3.076 -7.874)" fill="#cdcace"/>
      <path id="Trazado_3242" data-name="Trazado 3242" d="M39.1,24.659a1.283,1.283,0,0,1-.773-.258,1.3,1.3,0,0,1-.42-.54l-2.1-5.076a1.291,1.291,0,0,1,1.374-1.772l5.352.753a1.291,1.291,0,0,1,.846,2.066l-3.247,4.311a1.288,1.288,0,0,1-1.034.515m-2.1-6.367h0l2.1,5.076,3.252-4.318Z" transform="translate(-12.654 -6.024)" fill="#cdcace"/>
      <path id="Trazado_3243" data-name="Trazado 3243" d="M23.651,38.867A.646.646,0,0,1,23,38.222V36.71a15.58,15.58,0,0,1,9.646-14.436.646.646,0,1,1,.495,1.193A14.291,14.291,0,0,0,24.3,36.71v1.512a.646.646,0,0,1-.646.646" transform="translate(-8.151 -7.875)" fill="#cdcace"/>
      <path id="Trazado_3244" data-name="Trazado 3244" d="M23.651,33.89A.646.646,0,0,1,23,33.245v-22.6a.646.646,0,0,1,1.291,0v22.6a.646.646,0,0,1-.646.646" transform="translate(-8.151 -3.543)" fill="#cdcace"/>
    </g>
  </g>

    </SvgIcon>
  );
}
