import React, { useState, useEffect } from "react";
import Accordion from "../../../../Components/Accordion";
import { Typography, AccordionDetails, AccordionSummary } from "@mui/material/";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import FooterStep from "../../FooterStep";
import ItemRowWithOptions from "../../../../Components/Table/ItemRowWithOptionsCategory";
import ItemColumn from "../../../../Components/Table/ItemColumn";
import CommonStepList from "../../CommonStepListCategory";
import { StepWrapper } from "../../styles";
import FormSku from "../../Step3/Sku/FormSku";
import { CustomModal } from "../../../../Components/Modal";
import { getUnits } from "../../../../Redux/modules/units/selectors";
import { loadUnits } from "../../../../Redux/modules/units/actions";
import { useDispatch, useSelector } from "react-redux";
import { SearchIcon } from "../../../../Components/CustomIcons";
import TextField from "../../../../Components/TextField";

const CategoryResume = ({ currentCategory, onNext, onBack, onFinishStep }) => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const [currentSku, setCurrentSku] = useState(null);
  const [searchSku, setSearchSku] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [searchSubcategory, setSearchSubcategory] = useState("");
  const units = useSelector(getUnits);

  const [configModal, setConfigModal] = useState({
    title: "",
    type: "edit",
  });

  const onItemSkuEdit = (sku) => {
    setCurrentSku({
      ...sku,
      uomId: sku.uom_id[0],
      brandId: sku.brand_id[0],
      subcategoryId: sku.subcategory_id[0],
    });

    setOpenModal(!openModal);
    setConfigModal({
      title: t("initial_setup.modals.edit_sku"),
      type: "edit",
      cancelText: t("components_globals.button_cancel"),
      acceptText: t("components_globals.button_update"),
    });
  };
  const onItemSkuDelete = (sku) => {
    setCurrentSku(sku);
    setOpenModal(!openModal);
    setConfigModal({
      title: t("initial_setup.modals.delete_sku"),
      type: "delete",
      cancelText: t("components_globals.button_cancel"),
      acceptText: t("components_globals.button_ok"),
    });
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
    setCurrentSku(null);
  };

  const handleCancelModal = () => {
    setOpenModal(!openModal);
    setCurrentSku(null);
  };

  const handleOkModal = () => {
    switch (configModal.type) {
      case "edit":
        //  onSkuUpdated(currentSku);
        break;

      case "delete":
        // onSkuDeleted(currentSku);
        break;
    }
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (units.length === 0) {
      dispatch(loadUnits());
    }
  }, []);

  const columnNames = [
    t("initial_setup.forms.common_inputs.SKU"),
    t("initial_setup.forms.common_inputs.brand"),
    t("initial_setup.forms.common_inputs.owner"),
    t("initial_setup.forms.common_inputs.content"),
    t("initial_setup.forms.common_inputs.options"),
  ];
  const getListSkuItems = (subcategoryId) => {
    const subcategory = currentCategory.subcategory_ids.find(
      (x) => x.id === subcategoryId
    );
    if (subcategory && subcategory.sku_ids.length > 0) {
      const skus = currentCategory.sku_ids
        .filter((x) => subcategory.sku_ids.includes(x.id))
        .map((sku, index) => {
          const brand = currentCategory.brand_ids.find(
            (x) => x.id == sku.brand_id[0]
          );
          return (
            <ItemRowWithOptions
              customStyle={{ padding: "10px" }}
              key={index}
              index={index}
              withCommonButtons={true}
              onItemEdit={() => onItemSkuEdit(sku)}
              tooltipTitleEdit={t("components_globals.button_edit")}
              onItemDelete={() => onItemSkuDelete(sku)}
              tooltipTitleDelete={t("components_globals.button_remove")}
              columnsNumber={5}
            >
              <ItemColumn>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: brand ? brand.color : "#fff",
                      fontWeight: 700,
                      lineHeight: "1.8rem",
                      fontSize: "14px",
                      width: "30px",
                      height: "30px",
                      marginRight: "10px",
                      borderRadius: "50%",
                    }}
                  >
                    {sku.name[0]}
                  </div>
                  <span style={{ fontSize: "13px" }}>{sku.name}</span>
                </div>
              </ItemColumn>

              <ItemColumn>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: brand.color,
                      fontWeight: 700,
                      width: "15px",
                      height: "15px",
                      marginRight: "5px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  {brand && brand.name}
                </div>
              </ItemColumn>
              <ItemColumn>{brand && brand.owner_id[1]}</ItemColumn>
              <ItemColumn>{`${sku.unit_weight} ${
                sku.uom_id && sku.uom_id[1]
              }`}</ItemColumn>
            </ItemRowWithOptions>
          );
        });
      return skus;
    } else return [];
  };

  const onChangeSearch = (filter) => {
    if (filter) {
      setSearchSubcategory(filter);
    } else {
      setSearchSubcategory("");
    }
  };

  const filterSubcategories = (filter) => {
    if (filter) {
      return currentCategory.subcategory_ids.filter(
        (subcategory) =>
          subcategory.name.toUpperCase().indexOf(filter.toUpperCase()) != -1
      );
    } else {
      return currentCategory.subcategory_ids;
    }
  };

  const subcategoriesFiltered = filterSubcategories(searchSubcategory);

  return (
    <StepWrapper>
      {currentCategory.state !== "done" ? (
        <FooterStep
          onNext={onFinishStep}
          onBack={onBack}
          textButtonName={"button_finish"}
        ></FooterStep>
      ) : (
        <FooterStep notNext onBack={onBack}></FooterStep>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "20px 0px 20px 0px",
        }}
      >
        <Typography variant="subtitle1" fontWeight={600} marginBottom={2}>
          {t("initial_setup.subcategories")}
        </Typography>
        <TextField
          variant="third"
          label={`${t("initial_setup.header_list.search")}`}
          value={searchSubcategory}
          onChange={(e) => onChangeSearch(e.target.value)}
          InputProps={{ endAdornment: <SearchIcon /> }}
        />
      </div>

      {subcategoriesFiltered &&
        subcategoriesFiltered.map((subcategory, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
              <div
                style={{
                  display: "flex",
                  direction: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="color"
                  style={{
                    backgroundColor: subcategory.color,
                    fontWeight: 700,
                    width: "15px",
                    height: "15px",
                    borderRadius: "50%",
                    marginRight: "10px",
                    marginLeft: "15px",
                    boxShadow: "0px 1px 3px #00000029",
                  }}
                ></div>
                <Typography fontWeight={600}>{subcategory.name}</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <CommonStepList
                listItems={getListSkuItems(subcategory.id)}
                columnNames={columnNames}
                paginationTitle={t("initial_setup.step_3.stage_1.title")}
              ></CommonStepList>
              <CustomModal
                customWidth="750px"
                title={configModal.title}
                type={configModal.type}
                open={openModal}
                onCloseModal={handleCloseModal}
                onCancelModal={handleCancelModal}
                onOkModal={handleOkModal}
                cancelText={configModal.cancelText}
                acceptText={configModal.acceptText}
              >
                {configModal.type === "edit" && (
                  <FormSku
                    key={2}
                    sku={currentSku}
                    brands={currentCategory && currentCategory.brand_ids}
                    units={units}
                    subcategories={
                      currentCategory && currentCategory.subcategory_ids
                    }
                    setCurrentSku={setCurrentSku}
                  ></FormSku>
                )}
              </CustomModal>
            </AccordionDetails>
          </Accordion>
        ))}
    </StepWrapper>
  );
};
export default CategoryResume;
