export const initialState = {
  loading: false,
  variable: [],
  variables: [],
  tempValues: [],
  values: [],
  response: { message: null, error: null },
  formData: {
    id: 0,
    name: "",
    abbreviation: "",
    scope: "env",
    influencesFor: [],
    periodWidth: "months",
    completionRule: "repeat",
    firstPeriod: new Date(),
    values: [],
  },
};
