import React from "react";

import Owner from "./Owner";
import Brand from "./Brand";

const Step2 = ({ currentCategory, onNext, onBack, stage }) => {
  let stageView = <></>;

  switch (stage) {
    case 0:
      stageView = (
        <Owner
          categoryId={currentCategory ? currentCategory.id : 0}
          onNext={() => onNext()}
          onBack={() => onBack()}
          currentOwners={currentCategory ? currentCategory.owner_ids : []}
        />
      );
      break;
    case 1:
      stageView = (
        <Brand
          categoryId={currentCategory ? currentCategory.id : 0}
          onNext={() => onNext()}
          onBack={() => onBack()}
          currentBrands={currentCategory ? currentCategory.brand_ids : []}
          currentOwners={currentCategory ? currentCategory.owner_ids : []}
        />
      );
    default:
      break;
  }

  return stageView;
};

export default Step2;
