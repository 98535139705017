export const initialState = {
  loading: false,
  formData: {
    subcategoryId: 0,
    skuId: 0,
    skuIds: [],
    skuIdGraph: 0,
    variablesSelected: ["quantity_kg"],
    startMonth: 1,
    endMonth: 24,
    startPeriod: new Date(),
    endPeriod: new Date(),
    typePrice: "optimal",
    simulationTrend: {
      price: [],
      distribution: [],
      cost: [],
      value: [],
      price2: [],
      distribution2: [],
      cost2: [],
    },
  },
  simulations: [],
  trend: [],
  simulationCount: 0,
  response: { message: null, error: null },
};
