import * as type from "./types";
import { createRoutine } from "redux-saga-routines";

export const getLoadOwners = createRoutine(type.GET_LOAD_OWNERS);

export function createOwner(action) {
  return {
    type: type.CREATE_OWNER_REQUEST,
    payload: action,
  };
}

export function updateOwner(action) {
  return {
    type: type.UPDATE_OWNER_REQUEST,
    payload: action,
  };
}
export function deleteOwner(action) {
  return {
    type: type.DELETE_OWNER_REQUEST,
    payload: action,
  };
}

export function clearResponseOwner() {
  return {
    type: type.CLEAR_RESPONSE_OWNER,
  };
}
