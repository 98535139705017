import { call, put, takeEvery, select } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import { token } from "../auth/selectors";
import {
  CREATE_RELATION_SKU_REQUEST,
  CREATE_RELATION_SKU_FAILURE,
  CREATE_RELATION_SKU_SUCCESS,
  DELETE_RELATION_SKU_REQUEST,
  DELETE_RELATION_SKU_FAILURE,
  DELETE_RELATION_SKU_SUCCESS,
} from "./types";

import {
  ADD_RELATION_SKU_TO_CATEGORY,
  DELETE_RELATION_SKU_OF_CATEGORY,
} from "../categories/types";

function* createRelation({ payload }) {
  try {
    const tokenAccess = yield select(token);

    const body = {
      affects_ids: JSON.stringify(payload.relations),
    };

    const response = yield call(
      callApiAxios,
      "POST",
      `pricing.sku/${payload.idFrom}/`,
      tokenAccess,
      {},
      body
    );

    yield put({
      type: CREATE_RELATION_SKU_SUCCESS,
      relation: response.data[0],
    });
    yield put({ type: ADD_RELATION_SKU_TO_CATEGORY, payload: payload });
  } catch (e) {
    yield put({
      type: CREATE_RELATION_SKU_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

function* deleteRelation({ payload }) {
  try {
    const tokenAccess = yield select(token);

    const body = {
      affects_ids: JSON.stringify(payload.relations),
    };

    const response = yield call(
      callApiAxios,
      "POST",
      `pricing.sku/${payload.idFrom}/`,
      tokenAccess,
      {},
      body
    );
    yield put({
      type: DELETE_RELATION_SKU_SUCCESS,
      relation: response.data[0],
    });
    yield put({ type: DELETE_RELATION_SKU_OF_CATEGORY, payload: payload });
  } catch (e) {
    yield put({
      type: DELETE_RELATION_SKU_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

export default function* relationsSaga() {
  yield takeEvery(CREATE_RELATION_SKU_REQUEST, createRelation);
  yield takeEvery(DELETE_RELATION_SKU_REQUEST, deleteRelation);
}
