import * as type from "./types";
import { initialState } from "./states";

export default function auth(state = initialState, action) {
  switch (action.type) {
    case type.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        access_token: "",
        isAuth: false,
        company_id: "",
        company_ids: [],
        expires_in: "",
        partner_id: "",
        uid: "",
        user_context: "",
      };
    case type.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuth: true,
        access_token: action.login.access_token,
        company_id: action.login.company_id,
        company_ids: action.login.company_ids,
        expires_in: action.login.expires_in,
        partner_id: action.login.partner_id,
        uid: action.login.uid,
        user_context: action.login.user_context,
      };
    case type.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        response: { message: null, error: action.error },
        isAuth: false,
        access_token: "",
        company_id: "",
        company_ids: [],
        expires_in: "",
        partner_id: "",
        uid: "",
        user_context: "",
      };
    case type.REMEMBER_USER:
      return {
        ...state,
        remember: true,
        user: action.payload.user,
        password: action.payload.password,
      };
    case type.LOGOUT_REQUEST:
      return {
        ...initialState,
      };
    case type.CLEAR_RESPONSE_LOGIN:
      return {
        ...state,
        response: { error: null, message: null },
      };
    default:
      return state;
  }
}
