import { createAction } from "redux-actions";
import { createRoutine } from "redux-saga-routines";
import {
  ADD_TEMP_PROMOTION,
  CREATE_PROMOTION,
  UPDATE_PROMOTION,
  DELETE_PROMOTION,
  SET_FORM_DATA_PROMOTIONS,
  GET_PROMOTIONS,
  GET_SIMULATE_PROMOTIONS,
  CLEAR_SIMULATE_PROMOTIONS,
  SET_DEFAULT_FORM_DATA_PROMOTIONS,
  CLEAR_RESPONSE_PROMOTIONS,
  SET_SIMULATION_DATA_PROMOTIONS,
} from "./types";

export const getPromotions = createRoutine(GET_PROMOTIONS);
export const createPromotion = createRoutine(CREATE_PROMOTION);
export const updatePromotion = createRoutine(UPDATE_PROMOTION);
export const deletePromotion = createRoutine(DELETE_PROMOTION);

export const getSimulatePromotions = createRoutine(GET_SIMULATE_PROMOTIONS);

export const setFormDataPromotions = createAction(SET_FORM_DATA_PROMOTIONS);
export const setSimulationDataPromotions = createAction(
  SET_SIMULATION_DATA_PROMOTIONS
);

export const clearPromotionsSimulation = createAction(
  CLEAR_SIMULATE_PROMOTIONS
);

export const setDefaultFormDataPromotions = createAction(
  SET_DEFAULT_FORM_DATA_PROMOTIONS
);

export const clearResponsePromotions = createAction(CLEAR_RESPONSE_PROMOTIONS);
export const addTempPromotion = createAction(ADD_TEMP_PROMOTION);
