import React from "react";
import Variable from "./Variable";

const Step5 = ({ onNext, onBack, currentCategory }) => {
  return (
    <Variable
      categoryId={currentCategory ? currentCategory.id : 0}
      category={currentCategory}
      onNext={() => onNext()}
      onBack={() => onBack()}
      currentVariables={currentCategory ? currentCategory.variable_ids : []}
    />    
  );
};

export default Step5;