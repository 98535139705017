import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../Components/Button";
import { CustomModal } from "../../../../Components/Modal";
import TextField from "../../../../Components/TextField";
import { getSimulationPromotionsLoaded } from "../../../../Redux/modules/promotion-planning/promotions/selectors";
import ListVolumes from "./ListVolumes";
import TableEnvironmentVariables from "./TableEnvironmentVariables";
import { useSnackbar } from "notistack";
import { Typography } from "@mui/material";
import { useState } from "react";
import { useLocalStorage } from "../../../../Hooks/useLocalStorage";
const Volumes = () => {
  const simulationLoaded = useSelector(getSimulationPromotionsLoaded);
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const [simulationName, setSimulationName] = useState("");
  const { items, saveLocalStorage } = useLocalStorage("tempSimulations");
  const { enqueueSnackbar } = useSnackbar();
  const [configModal, setConfigModal] = useState({
    title: "",
    type: "save",
    open: false,
    cancelText: t("components_globals.button_cancel"),
    acceptText: t("components_globals.button_save"),
  });

  const handleCloseModal = () => {
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  const handleCancelModal = () => {
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  const handleSaveSimulate = () => {
    setSimulationName("");
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  const handleOkModal = () => {
    const newArray = items.concat([
      {
        id: simulationLoaded.cache,
        name: simulationName,
      },
    ]);
    saveLocalStorage("tempSimulations", newArray);
    enqueueSnackbar(
      t(
        "sales_planning.promotion_planning_module.volumes_component.form.messages.save_success"
      ),
      {
        variant: `success`,
      }
    );
    setConfigModal({ ...configModal, open: !configModal.open });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "13px" }}>
      {simulationLoaded && (
        <>
          <TableEnvironmentVariables
            environmentVariables={simulationLoaded.vars}
          />
          <ListVolumes promotions={simulationLoaded.promotions} />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={handleSaveSimulate} style={{ width: "260px" }}>
              {t("components_globals.button_save")}{" "}
              {t("drawer_component.simulation")}
            </Button>
          </div>
          <CustomModal
            customWidth="750px"
            title={configModal.title}
            type={configModal.type}
            open={configModal.open}
            onCloseModal={handleCloseModal}
            onCancelModal={handleCancelModal}
            onOkModal={handleOkModal}
            cancelText={configModal.cancelText}
            acceptText={configModal.acceptText}
          >
            <div>
              <Typography
                sx={{
                  textAlign: "center",
                  color: (theme) => theme.palette.custom.primary,
                }}
              >
                {t(
                  "sales_planning.promotion_planning_module.volumes_component.form.messages.save_confirm"
                )}
              </Typography>
              <br />
              <div
                variant="body"
                style={{
                  fontSize: "1.5em",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                <TextField
                  variant="third"
                  required
                  value={simulationName}
                  label={`${t(
                    "sales_planning.promotion_planning_module.volumes_component.form.inputs.name"
                  )}`}
                  onChange={(e) => setSimulationName(e.target.value)}
                />
              </div>
            </div>
          </CustomModal>
        </>
      )}
    </div>
  );
};

export default Volumes;
