import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CheckList from "../../../../Components/CheckList";
import { AlertCircleIcon } from "../../../../Components/CustomIcons";
import { ExpandIcon } from "../../../../Components/CustomIcons/ExpandIcon";
import FormControl from "../../../../Components/FormControl";
import CustomPagination from "../../../../Components/Pagination";
import { RoundedButton } from "../Volumes/Styles";
import ItemVolume from "./ItemVolume";

import SaveMenu from "../../../../Components/SaveMenu";
import { exportToExcel } from "../../../../Utils";

const CssFormControl = styled(FormControl)(({ theme }) => ({
  stroke: theme.palette.custom.text,
  "& label": {
    color: theme.palette.custom.text,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    color: theme.palette.custom.thirdVariant,
  },
  "& .MuiInputAdornment-root": {
    color: theme.palette.custom.text,
  },
  "&:hover label, &:hover .MuiInputAdornment-root": {
    color: theme.palette.custom.thirdVariant,
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.custom.text,
      borderWidth: "3px",
      borderRadius: "1em",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.custom.thirdVariant,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.custom.thirdVariant,
    },
  },
}));

export default function ListVolumes({ promotions }) {
  const skipPages = [5, 10, 20];
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const [searchText, setSearchText] = useState("");
  const [filterVolumes, setFilterVolumes] = useState([]);
  const promotionsIds = promotions.map((x) => x.id);

  const [pagination, setPagination] = useState({
    page: 1,
    pages:
      promotions.length > skipPages[0]
        ? Math.floor(promotions.length / skipPages[0])
        : 1,
    skip: skipPages[0],
  });

  const [expandedList, setExpandedList] = useState([]);
  const [isAllExpanded, setIsAllxpanded] = useState(false);

  const handleChangeExpandedAll = (e) => {
    setIsAllxpanded(!isAllExpanded);
    if (!isAllExpanded) {
      setExpandedList(promotionsIds);
    } else {
      setExpandedList([]);
    }
  };
  const handleChangePagination = (e) => {
    const skip = e.target.value;
    const pages = promotions.length / skip;
    setPagination({ ...pagination, pages: pages, page: 1, skip: skip });
  };
  const handleChangePagePagination = (e) => {
    const page = e.target.value;
    setPagination({ ...pagination, page: page });
  };

  const isExpandedList = (id) => {
    return expandedList.includes(id);
  };
  const changeDate = (date) => {
    const stringSplitted = date.split("-");
    return `${stringSplitted[2]}/${stringSplitted[1]}/${stringSplitted[0]}`;
  };
  const handleChangeExpandedList = (id) => {
    if (isExpandedList(id)) {
      const newArray = expandedList.filter((x) => x !== id);
      setExpandedList(newArray);
    } else {
      const newArray = expandedList.concat([id]);
      setExpandedList(newArray);
    }
  };

  const handleChangeSelectedVolumes = (value) => {
    if (value[0] !== undefined || value.length === 0) {
      setFilterVolumes(value);
    }
  };

  const handleDownloadExcel = () => {
    try {
      let tables = document.getElementsByClassName(`tableVolumes`);
      let sheets = [];
      let sheetNames = [];
      for (let table of tables) {
        let excelTable = document.createElement("table");
        excelTable.innerHTML = table.innerHTML.trim();
        const tbody = excelTable.querySelector("tbody");
        if (tbody) {
          let inputs = tbody.querySelectorAll("input");
          for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];
            const parentTd = input.parentNode;
            parentTd.className = "";
            parentTd.innerHTML = input.value;
          }
        }
        const id = tbody.getAttribute("id");
        const customerName = tbody.getAttribute("customerName");
        sheetNames.push(`${id}-${customerName}`);
        sheets.push(excelTable);
      }
      exportToExcel("volumes", sheets, null, "sheets", sheetNames);
    } catch (error) {}
  };

  const handleChangeFilter = (e) => {};

  const promotionsFiltered =
    filterVolumes.length > 0
      ? promotions.filter((x) => filterVolumes.includes(x.id))
      : promotions;

  return (
    <div
      style={{
        paddingTop: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <CheckList
          style={{ width: "300px" }}
          labelName={t(
            "sales_planning.promotion_planning_module.promotions_component.form.inputs.select_promotions"
          )}
          items={promotions}
          selectedItems={filterVolumes}
          onChangeSelectItems={handleChangeSelectedVolumes}
          onBuildItemName={(option) => {
            return `${option.name}`;
          }}
        ></CheckList>
        <RoundedButton backgroundColor="text" onClick={handleChangeExpandedAll}>
          <ExpandIcon
            style={{ paddingLeft: "2px", fontSize: "1rem" }}
          ></ExpandIcon>
          <span
            style={{ width: "200px", fontSize: "0.9rem", textAlign: "center" }}
          >
            {`${
              !isAllExpanded
                ? t("components_globals.button_expand")
                : t("components_globals.button_contract")
            }  ${t(
              "sales_planning.promotion_planning_module.promotions_component.title"
            )}`}
          </span>
          <AlertCircleIcon
            style={{ paddingRight: "2px", fontSize: "1.5rem" }}
          ></AlertCircleIcon>
        </RoundedButton>
        <SaveMenu
          style={{ marginRight: "32px" }}
          buttons={["export"]}
          list={["excel"]}
          // handlePdf={handlePdf}
          handleExcel={handleDownloadExcel}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{ cursor: "pointer", width: "100px" }}
            onClick={(e) => handleChangeFilter("")}
          >{`${t("initial_setup.header_list.view_all")}`}</span>
          <CssFormControl>
            <Select
              sx={{ width: "100px", marginRight: "15px" }}
              value={pagination.skip}
              onChange={handleChangePagination}
            >
              {promotions.length > skipPages[0] ? (
                skipPages &&
                skipPages.map((option, index) => {
                  return (
                    <MenuItem key={index} value={option}>
                      {`${option}  `}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem key={1} value={skipPages[0]}>
                  {`${skipPages[0]}  `}
                </MenuItem>
              )}
            </Select>
          </CssFormControl>
        </div>
      </div>
      {promotionsFiltered.map((promotion) => (
        <Accordion
          sx={{
            borderRadius: "10px",
          }}
          key={promotion.name}
          expanded={isExpandedList(promotion.id)}
          onChange={(e) => handleChangeExpandedList(promotion.id)}
        >
          <AccordionSummary
            key={`summary-${promotion.name}`}
            expandIcon={<ExpandMoreIcon sx={{ fontSize: "1.5rem" }} />}
            aria-controls={promotion.name}
          >
            <Typography
              sx={{
                paddingTop: "3px",
                paddingLeft: "10px",
              }}
              fontSize={18}
              fontWeight={700}
            >
              {`${promotion.name} `}
            </Typography>{" "}
            <Typography
              sx={{
                paddingTop: "3px",
                paddingLeft: "10px",
              }}
              fontSize={16}
              fontWeight={600}
            >
              {`${changeDate(promotion.start_date)}-${changeDate(
                promotion.end_date
              )}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails key={`details-${promotion.name}`}>
            <ItemVolume promotion={promotion} />
          </AccordionDetails>
        </Accordion>
      ))}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CustomPagination
          count={pagination.pages}
          page={pagination.page}
          onChange={handleChangePagePagination}
        />
      </div>
    </div>
  );
}
