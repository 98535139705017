import * as type from "./types";
import { initialState } from "./states";
export default function skus(state = initialState, action) {
  switch (action.type) {
    case type.CREATE_SKU_REQUEST:
      return {
        ...state,
        loading: true,
        skus: [],
      };
    case type.CREATE_SKU_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {
          message: "initial_setup.messages.create_sku_success",
          error: null,
        },
        skus: action.skus,
      };
    case type.CREATE_SKU_FAILURE:
      return {
        ...state,
        loading: false,
        response: {
          message: null,
          error: action.error,
        },
      };
    case type.GET_SKUS_BY_CATEGORY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.GET_SKUS_BY_CATEGORY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        skus: action.skus.data,
      };
    case type.GET_SKUS_BY_CATEGORY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.message,
        skus: [],
      };
    case type.UPDATE_SKU_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.UPDATE_SKU_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {
          message: "initial_setup.messages.update_sku_success",
          error: null,
        },
      };
    case type.UPDATE_SKU_FAILURE:
      return {
        ...state,
        loading: false,
        response: {
          message: null,
          error: action.error,
        },
      };
    case type.DELETE_SKU_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.DELETE_SKU_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {
          message: "initial_setup.messages.delete_sku_success",
          error: null,
        },
      };
    case type.DELETE_SKU_FAILURE:
      return {
        ...state,
        loading: false,
        response: {
          message: null,
          error: action.error,
        },
      };
    case type.CLEAR_RESPONSE_SKU:
      return {
        ...state,
        response: {
          message: null,
          error: null,
        },
      };
    case type.CLEAR_ALL_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
