
import * as type from './types';

export function loadUnits() {
  return {
    type: type.GET_UNITS_REQUEST,
  };
}

export function clearResponseUnit() {
  return {
    type: type.CLEAR_RESPONSE_UNIT,
  };
}
