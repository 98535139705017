import React from "react";
import { SvgIcon } from "@mui/material";

export function LabelsIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 39.565 30">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_4802"
            data-name="Rectángulo 4802"
            width="39.565"
            height="30"
            fill="#cdcace"
          />
        </clipPath>
      </defs>
      <g id="Grupo_2168" data-name="Grupo 2168" clip-path="url(#clip-path)">
        <path
          id="Trazado_3460"
          data-name="Trazado 3460"
          d="M28.065,23H11.5a11.5,11.5,0,0,1,0-23H28.065a11.5,11.5,0,0,1,0,23M11.5,1.649a9.852,9.852,0,1,0,0,19.7H28.065a9.852,9.852,0,1,0,0-19.7Z"
          transform="translate(0 0)"
          fill="#cdcace"
        />
        <path
          id="Trazado_3461"
          data-name="Trazado 3461"
          d="M12.491,19.789A7.418,7.418,0,1,1,19.91,12.37a7.427,7.427,0,0,1-7.418,7.418m0-13.188a5.77,5.77,0,1,0,5.77,5.77,5.776,5.776,0,0,0-5.77-5.77"
          transform="translate(-0.891 -0.87)"
          fill="#cdcace"
        />
      </g>
    </SvgIcon>
  );
}

