import produce from "immer";
import {
  createPromotion,
  deletePromotion,
  getPromotions,
  getSimulatePromotions,
  updatePromotion,
} from "./actions";
import { initialState } from "./states";
import * as types from "./types";

export const promotions = (state = initialState, { payload, type }) =>
  produce(state, (draft) => {
    switch (type) {
      case getPromotions.TRIGGER:
        draft.action = payload;
        draft.loading = true;
        break;
      case getPromotions.REQUEST:
        break;
      case getPromotions.SUCCESS:
        if (payload.promotions.length > 0) {
          const promotions = Object.values(payload.data.promotions);
          const customers = Object.values(payload.data.customers);
          const skus = Object.values(payload.data.skus);
          draft.promotions = promotions;
          draft.promotionsData = {
            ...payload.data,
            customers: customers,
            skus: skus,
          };
        } else {
          draft.promotions = [];
        }
        break;
      case getPromotions.FAILURE:
        draft.response.error = payload.error;
        break;
      case getPromotions.FULFILL:
        draft.loading = false;
        break;
      case createPromotion.TRIGGER:
        draft.action = payload;
        draft.loading = true;
        break;
      case createPromotion.REQUEST:
        break;
      case createPromotion.SUCCESS:
        draft.formData.id = null;
        draft.formData.name = "";
        draft.formData.description = "";
        draft.formData.skuIds = [];
        draft.formData.skus = [];
        draft.formData.customerIds = [];
        draft.formData.promotionsSelectedIds = [];
        draft.formData.packageIds = [];

        break;
      case createPromotion.FAILURE:
        draft.response.error = payload.error;
        break;
      case createPromotion.FULFILL:
        draft.loading = false;
        break;
      case deletePromotion.TRIGGER:
        draft.loading = true;
        break;
      case deletePromotion.REQUEST:
        break;
      case deletePromotion.SUCCESS:
        break;
      case deletePromotion.FAILURE:
        draft.response.error = payload.error;
        break;
      case deletePromotion.FULFILL:
        draft.loading = false;
        break;
      case updatePromotion.TRIGGER:
        draft.loading = true;
        break;
      case updatePromotion.REQUEST:
        break;
      case updatePromotion.SUCCESS:
        break;
      case updatePromotion.FAILURE:
        draft.response.error = payload.error;
        break;
      case updatePromotion.FULFILL:
        draft.loading = false;
        break;

      case getSimulatePromotions.TRIGGER:
        draft.loading = true;
        draft.action = payload;
        break;
      case getSimulatePromotions.REQUEST:
        break;
      case getSimulatePromotions.SUCCESS:
        const promotions = Object.values(payload.promotions);
        const customers = Object.values(payload.customers);
        const skus = Object.values(payload.skus);
        draft.simulation = {
          ...payload,
          customers: customers,
          promotions: promotions,
          skus: skus,
        };

        break;
      case getSimulatePromotions.FAILURE:
        draft.response.error = payload.error;
        break;
      case getSimulatePromotions.FULFILL:
        draft.loading = false;
        break;
      case types.SET_FORM_DATA_PROMOTIONS:
        if (payload.edit) {
          draft.formData.id = payload.id;
          draft.formData.name = payload.name;
          draft.formData.startPeriod = new Date(
            payload.start_date.split("-").join("/")
          );
          draft.formData.endPeriod = new Date(
            payload.end_date.split("-").join("/")
          );
          draft.formData.skuIds = payload.filters.skus;
          draft.formData.customerIds = payload.filters.customers;
        } else {
          if (payload.many) {
            for (const change of payload.changes) {
              draft.formData[change.propertyName] = change.propertyValue;
            }
          } else {
            draft.formData[payload.propertyName] = payload.propertyValue;
          }
        }

        break;
      case types.SET_DEFAULT_FORM_DATA_PROMOTIONS:
        const categorySelected = payload.category;
        draft.formData.categoryId = categorySelected.id;
        const maxDate = categorySelected
          ? new Date(categorySelected.last_month.split("-").join("/"))
          : new Date();
        const minDate = new Date(
          categorySelected.first_month.split("-").join("/")
        );
        maxDate.setFullYear(maxDate.getFullYear() - 1);

        draft.formData.minDate = minDate;
        draft.formData.maxDate = maxDate;
        draft.formData.startPeriod = minDate;
        draft.formData.endPeriod = maxDate;

        break;
      case types.ADD_TEMP_PROMOTION:
        break;
      case types.CLEAR_SIMULATE_PROMOTIONS:
        if (payload && payload === "env") {
          draft.simulationData["env"] = [];
        } else if (payload && payload.type === "promotion") {
          const promo = draft.simulationData["promo"].filter(
            (x) => x.code !== payload.id
          );
          draft.simulationData["promo"] = promo;
          const base = draft.simulationData["base"].filter(
            (x) => x.code !== payload.id
          );
          draft.simulationData["base"] = base;
        } else {
          draft.simulationData = initialState.simulationData;
          draft.formData = initialState.formData;
          draft.promotions = [];
          draft.simulation = null;
        }

        break;
      case types.CLEAR_RESPONSE_PROMOTIONS:
        draft.response = { error: null, message: null, result: null };
        break;

      case types.SET_SIMULATION_DATA_PROMOTIONS:
        if (payload.type !== "env") {
          const { promotionId, customerId, skuId, newValue, type, day, index } =
            payload;

          const replaceAll = index === 1 && type === "promo";
          const isMainValue =
            (index === 2 && type === "promo") ||
            (index === 1 && type === "base");

          let skuExist = draft.simulationData[type].find(
            (x) =>
              x.promotionId === promotionId &&
              x.customerId === customerId &&
              x.skuId === skuId
          );

          if (skuExist) {
            if (replaceAll) {
              skuExist.items = [];
              skuExist.value = newValue;
            }
            let objExist = skuExist.items.find((x) => x.day === day);
            if (objExist) {
              objExist.value = newValue;
            } else {
              skuExist.items.push({
                day,
                value: newValue,
                simulate: isMainValue,
              });
            }
            if (isMainValue) {
              skuExist.value = newValue;
            }
          } else {
            draft.simulationData[type].push({
              promotionId,
              customerId,
              skuId,
              value: newValue,
              items: [
                {
                  day,
                  value: newValue,
                  simulate: isMainValue,
                },
              ],
            });
          }
        } else {
          const code = payload.id;
          let itemExist = draft.simulationDataEnv.find(
            (item) => item.code === payload.index
          );
          if (itemExist) {
            let periodExists = itemExist.items.find(
              (period) => period.code === code
            );
            if (periodExists) {
              periodExists.value = payload.propertyValue;
            } else {
              itemExist.items.push({
                code: code,
                value: payload.propertyValue,
              });
            }
          } else {
            draft.simulationDataEnv.push({
              code: payload.index,
              items: [{ code: code, value: payload.propertyValue }],
            });
          }
        }

        break;
    }
  });
