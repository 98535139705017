export const initialState = {
  loading: false,
  formData: {
    subcategoryId: 0,
    month: 1,
    isAnnual: false,
    period: new Date(),
    maxDate: new Date(),
    minDate: new Date(),
  },
  simulationData: {
    env: [],
  },
  matrizE: null,
  simulation: null,
  action: null,
  response: { message: null, error: null, result: null },
};
