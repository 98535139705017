import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../Components/Button";
import parse from "paste-from-excel";
import { useTranslation } from "react-i18next";
import { getCurrentTempValues } from "../../../../Redux/modules/variables/selectors";
import { setVariableTempValue } from "../../../../Redux/modules/variables/actions";
import { truncateNumber, isNumber } from '../../../../Utils';

function TableVariableValues({ labels, onCancelModal, onOkModal }) {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const rowsTemp = useSelector(getCurrentTempValues);
  const handlePasteTd = (rowIndex, elm, e, colIndex) => {
   return parse(e);
  };
  const validation =(e)=>{
    const fixed = parseFloat(e.target.value).toFixed(2).toString()
    if (fixed.length < parseFloat(e.target.value).toString().length)
      e.target.value = fixed

    return fixed
  }



  const onKeyPressTd = (e, currentValue) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (keyValue === ".") {
      if (currentValue.indexOf(keyValue) !== -1) {
        e.preventDefault();
        return;
      }
    }
    if (!new RegExp("[0-9.]").test(keyValue)) {
      e.preventDefault();
      return;
    }
  };

  const handleInputTd = (rowIndex, elm, e, colIndex) => {
  validation(e)
    const newValue = e.target.value;
    dispatch(
      setVariableTempValue({
        period_index: elm.period_index,
        value: newValue,
      })
    );
  };

  const stylesTr = {
    height: "30px",
  };

  const stylesTh = {
    minWidth: "30px",
    borderRadius: "10px",
    background: "#6FD1B0",
    color: "#1F1C36",
    fontSize: "0.8em",
    textAlign: "center",
  };

  const stylesTd = {
    height: "35px",
    minWidth: "30px",
    maxWidth: "80px",
    fontSize: "0.8em",
    textAlign: "center",
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "100%" }}>
        <table style={{ width: "100%" }}>
          <thead>
            <tr style={stylesTr}>
              <th style={stylesTh}>-</th>
              {labels.map((elm, idx) => {
                return (
                  <th key={idx} style={stylesTh}>
                    {["value", "date"].includes(elm)
                      ? t(`initial_setup.forms.common_inputs.${elm}`)
                      : elm}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rowsTemp &&
              rowsTemp.map((row, indexF) => {
                return (
                  <tr key={indexF}>
                    <td style={stylesTd}>{`${indexF + 1}`}</td>
                    <td style={stylesTd}>{`${row.date}`}</td>
                    <td style={stylesTd}>
                      <input
                        name={`variableValue-${indexF}`}
                        value={row.value ? row.value : ""}
                        type="number"
                        step="0.1" 
                        placeholder="0.00"
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #1F1C36",
                          width: "100%",
                          height: "100%",
                          opacity: 0.8,
                          textAlign: "center",
                        }}
                        // onChange ={(e) => handleChange(e)
                        // }
                        onKeyPress={(e) =>
                          onKeyPressTd(e, row.value ? row.value : "")
                        }
                        onInput={(e) => {
                          handleInputTd(indexF, row, e, 1);
                        }}
                        onPaste={(e) => {
                          handlePasteTd(indexF, row, e, 1);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CustomButton
            variant="gray"
            style={{
              width: "100%",
              marginTop: "2em",
              marginRight: "0.5em",
            }}
            onClick={onCancelModal}
          >
            {t("components_globals.button_cancel")}
          </CustomButton>
          <CustomButton
            variant="primary"
            style={{ width: "100%", marginTop: "2em" }}
            onClick={onOkModal}
          >
            {t("components_globals.button_save")}
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default TableVariableValues;
