import React from "react";
import { SvgIcon } from "@mui/material";

export function LockIcon(props) {
  const classLockIcon = {
    fill: "none",
    strokeLinecap: "round",
    stroke: "#000000",
    strokeLinejoin: "round",
    strokeWidth: "1.5px",
  };
  return (
    <SvgIcon viewBox="0 0 48 48" {...props}>
      <g transform="matrix(1.5,0,0,1.5,0,0)">
        <rect
          style={classLockIcon}
          x="3.75"
          y="9.75"
          width="16.5"
          height="13.5"
          rx="1.5"
          ry="1.5"
        ></rect>
        <path
          style={classLockIcon}
          d="M6.75,9.75V6a5.25,5.25,0,0,1,10.5,0V9.75"
        ></path>
        <line style={classLockIcon} x1="12" y1="15" x2="12" y2="18"></line>
      </g>
    </SvgIcon>
  );
}