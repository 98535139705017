export const SAVE_CONFIG_REQUEST = 'SAVE_CONFIG_REQUEST';
export const SAVE_CONFIG_SUCCESS = 'SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_FAILURE = 'SAVE_CONFIG_FAILURE';

export const GET_CONFIG_REQUEST = 'GET_CONFIG_REQUEST';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'GET_CONFIG_FAILURE';

export const PUT_CONFIG_REQUEST = 'PUT_CONFIG_REQUEST';
export const PUT_CONFIG_SUCCESS = 'PUT_CONFIG_SUCCESS';
export const PUT_CONFIG_FAILURE = 'PUT_CONFIG_FAILURE';

export const CLEAR_RESPONSE_APP = 'CLEAR_RESPONSE_APP';

export const ON_INITIAL_STATE = 'ON_INITIAL_STATE';

export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';

export const UPDATE_LOGO = 'UPDATE_LOGO';
export const RESET_COLORS = 'RESET_COLORS';