import * as React from "react";
import { styled } from "@mui/material/styles";
import { Table } from "@mui/material";

export const CustomTable = styled(Table)(({ theme }) => ({
  borderCollapse: "separate",
  borderSpacing: "0px  4px",

  tr: {
    th: {
      whiteSpace: "nowrap",
    },
    td: {
      textAlign: "center",
      whiteSpace: "nowrap",
      padding: ".4em",
      color: theme.palette.custom.secondary,
      backgroundColor: theme.palette.custom.background,
    },
  },
  ".empty": {
    opacity: "0",
  },
  ".rounded": {
    padding: ".5em",
    borderRadius: ".50em",
    color: theme.palette.custom.secondary,
    backgroundColor: theme.palette.custom.text,
  },
  ".spacer": {
    width: ".5em",
  },
  ".space-cell": {
    display: "grid",
    fontSize: "1rem",
    placeContent: "center",
    height: "40px",
    marginInline: "auto",
    margin: "2px",
  },
  ".spacer-vertical": {
    height: ".5em",
    backgroundColor: theme.palette.custom.background,
  },
  ".joined": {
    backgroundColor: theme.palette.custom.text,
    color: theme.palette.custom.secondary,
    padding: ".5em",
  },
  ".joined-first": {
    borderRadius: "10px 0 0 10px",
  },
  ".joined-last": {
    borderRadius: "0 10px 10px 0",
  },
  ".primary": {
    backgroundColor: theme.palette.custom.primary,
    color: theme.palette.custom.secondary,
  },
  ".secondary": {
    backgroundColor: theme.palette.custom.secondary,
    color: theme.palette.custom.primary,
  },
  ".transparent": {
    backgroundColor: "transparent",
    color: theme.palette.custom.secondary,
    td: {
      backgroundColor: "transparent",
    },
  },
  ".text": {
    color: theme.palette.custom.secondary,
    backgroundColor: theme.palette.custom.text + " !important",
    borderRadius: ".25em",
  },
  ".text-primary": {
    color: theme.palette.custom.primary,
  },
  ".text-secondary": {
    color: theme.palette.custom.secondary,
  },
  ".background": {
    backgroundColor: theme.palette.custom.background,
  },
  ".divider": {
    height: "1px",
    backgroundColor: theme.palette.custom.text,
    borderRadius: ".25em",
    padding: "0",
  },
  ".hidden": {
    display: "none",
  },
  ".customerImage": {
    left: 0,
    position: "sticky",
  },
  ".title-promotion": {
    width: "150px",
    fontSize: "24px",
    textAlign: "start",
    left: 0,
    position: "sticky",
    backgroundColor: theme.palette.custom.background,
  },
  ".title-row": {
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "start",
    left: 0,
    position: "sticky",
  },
}));

// const CustomTable = (props) => {
//   return (
//     <CssTable {...props} cellSpacing="0" cellPadding="0">
//       {props.children}
//     </CssTable>
//   );
// };

export default CustomTable;
