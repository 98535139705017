import { call, put, select, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../api";

import { token } from "../auth/selectors";
import { getCustomers } from "./actions";

function* getCustomersSaga() {
  try {
    yield put(getCustomers.request());
    const tokenAccess = yield select(token);
    const response = yield call(
      callApiAxios,
      "GET",
      "pricing.customer/",
      tokenAccess,
      {},
      {}
    );

    yield put(getCustomers.success(response.data));
  } catch (e) {
    yield put(getCustomers.failure());
  } finally {
    yield put(getCustomers.fulfill());
  }
}

export default function* customersSaga() {
  yield takeEvery(getCustomers.TRIGGER, getCustomersSaga);
}
