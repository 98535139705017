import React from "react";
import { SvgIcon } from "@mui/material";

export function DownloadIcon(props) {
  const classEditIcon = {
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5px",
    ...props.style,
  };

  return (
    <SvgIcon {...props}>
      <g>
        <path
          d="M23,18.218v1.913A2.87,2.87,0,0,1,20.131,23H3.869A2.869,2.869,0,0,1,1,20.131V18.218"
          style={classEditIcon}
        ></path>
        <path d="M12 18.108L12 1" style={classEditIcon}></path>
        <path d="M19 11.108L12 18.108 5 11.108" style={classEditIcon}></path>
      </g>
    </SvgIcon>
  );
}
