export const initialState = {
  loading: false,
  formData: {
    functionType: "lineal",
    period: null,
    month: 1,
    simulationOptimals: {
      prices: [],
      increments: [],
      distributions: [],
      costs: [],
      values: [],
    },
  },
  simulationCount: 0,
  optimals: [],
  simulationOptimals: [],
  tempSimulationOptimals: null,
  response: { message: null, error: null },
};
