import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormTrend from "./FormTrend";
import {
  getTrendLoaded,
  getFormDataTrend,
  getResponseTrend,
} from "../../../../Redux/modules/what-if/trend/selectors";
import {
  clearResponseTrend,
  getSimulateTrend,
} from "../../../../Redux/modules/what-if/trend/actions";
import TotalOrder from "./TotalOrder";
import { Typography } from "@mui/material/";
import TableTrend from "./TableTrend";
import { useTranslation } from "react-i18next";
import Accordion from "../../../../Components/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioBtnGroup from "./RadioBtnGroup";
import Button from "../../../../Components/Button";
import { useSnackbar } from "notistack";
import GraphSeries from "./GraphSeries";
import { CssGridWrapper } from "./StyleIndex";

const Trend = () => {
  const [t, i18n] = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const tempForm = useSelector(getFormDataTrend);
  const response = useSelector(getResponseTrend);
  const trendLoaded = useSelector(getTrendLoaded);
  const dispatch = useDispatch();

  const subcategory =
    trendLoaded?.subcategory_ids && trendLoaded?.subcategory_ids[0];
  const skus = subcategory?.sku_ids;

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseTrend());
    }
  }, [response]);

  const handleClickSimulation = (e) => {
    dispatch(getSimulateTrend.trigger());
  };
  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="">
          <Typography fontWeight={600}>
            {t("price_management.trend.select_data")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormTrend />
        </AccordionDetails>
      </Accordion>
      {subcategory && skus && (
        <CssGridWrapper>
          <div className="bodyGrid">
            <div className="radioBtnGroup">
              <RadioBtnGroup />
            </div>
            <div className="totalOrder">
              <TotalOrder skus={skus} />
            </div>
            <div className="graphSeries">
              <GraphSeries skus={skus} />
            </div>
            <div className="tableTrend">
              <TableTrend />
            </div>
            <div className="simular">
              <Button style={{ width: "7rem" }} onClick={handleClickSimulation}>
                {t("price_management.optimal_prices.simulate")}
              </Button>
            </div>
          </div>
        </CssGridWrapper>
      )}
    </>
  );
};

export default Trend;
