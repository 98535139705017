import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box } from "@mui/material";

const ButtonWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: "0.5em",
  ".iconButton": {
    stroke: theme.palette.custom.text,
    strokeWidth: "2px",

    "&:hover": {
      stroke: theme.palette.custom.secondary,
    },

    
  },
  ".iconButtonPrimary": {
    stroke: theme.palette.custom.text,
    strokeWidth: "2px",

    "&:hover": {
      stroke: theme.palette.custom.primary,
    },
  },

  ".iconButtonSecondary": {
    stroke: theme.palette.custom.secondary,
    strokeWidth: "2px",

    "&:hover": {
      stroke: theme.palette.custom.primary,
    },
  },
  
  ".strokeLigth":{
    strokeWidth: "1px !important",      
  }
}));

const CssTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.custom.text,
    color: theme.palette.custom.secondary,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),    
  },
}));




export default function ButtonItemColumn(props) {
  return (
    <ButtonWrapper>
      <CssTooltip placement="top" title={props.tooltip}>
        <span style={{ cursor: "pointer" }} onClick={props.onClick}>
          {props.icon}
        </span>
      </CssTooltip>
    </ButtonWrapper>
  );
}
