import { call, put, takeEvery } from "redux-saga/effects";
import { callApiAxios } from "../../api";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
} from "./types";

function* doLogin({ payload }) {
  try {
 
    const params = {
      login: payload.user,
      password: payload.password,
      db: "pricing",
    };
    const response = yield call(
      callApiAxios,
      "GET",
      "auth/token",
      null,
      params,
      {}
    );
    yield put({ type: LOGIN_SUCCESS, login: response });
  } catch (e) {
    yield put({
      type: LOGIN_FAILURE,
      error: e ? String(e.message) : "Error de conexión",
    });
  }
}

export default function* usersSaga() {
  yield takeEvery(LOGIN_REQUEST, doLogin);
}
