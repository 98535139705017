import React from "react";
import Chart from "react-apexcharts";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import {
  getCurvesLoaded,
  getCurvesFunctionType,
  getCurvesCurrentGraph,
  getCurvesFormData,
} from "../../../../Redux/modules/what-if/curves/selectors";
import { useSelector, useDispatch } from "react-redux";
import { getCategorySelected } from "../../../../Redux/modules/categories/selectors";
import { setAxisGraph } from "../../../../Redux/modules/what-if/curves/actions";

const DivGraph = styled(Paper)(({ theme, size }) => ({
  border: `1px solid ${theme.palette.custom.background}`,
  borderRadius: "10px",
  backgroundColor: "white",
  display: "grid",
  placeContent: "center",
  height: "700px",
  margin: "10px",
}));

const CustomGridGraph = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: "1em",
  border: `1px solid ${theme.palette.custom.text}`,
  borderRadius: "15px",
  "&:hover": {
    borderColor: theme.palette.custom.primary,
  },
  "&.active": {
    border: `2px solid ${theme.palette.custom.primary}`,
  },
}));

const SmallGraphChart = styled(Chart)(({ theme }) => ({
  overflow: "unset",
  width: "300px",
  "& .apx-legend-position-bottom": {
    display: "none",
  },
  "& .apexcharts-title-text": {
    fill: theme.palette.custom.secondary,
    marginTop: "2rem",
    fontSize: "1rem",
  },

  "& .apexcharts-zoom-icon": {
    display: "none",
  },
  "& .apexcharts-toolbar > *": {
    margin: 0,
    gap: "0.3rem",
  },
  "& .apexcharts-menu-open": {
    fontFamily: "Helvetica, Arial, sans-serif",
    borderRadius: "10px",
    border: "1px solid #dedede",
  },
  "& .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title": {
    background: "white",
    textAlign: "center",
    fontWeight: "bold",
  },
  "& .apexcharts-xaxistooltip": {
    backgroundColor: "white",
    borderRadius: "10px",
    border: "1px solid #b2a9a9",
  },
  "& .apexcharts-point-annotation-marker": {
    // stroke: "black",
    strokeWidth: 0.3,
    r: 4,
  },
  "& .max_sales": {
    fill: "black",
  },
  "& .max_profit": {
    fill: "black",
  },
  "& .optimal": {
    fill: "black",
  },
  "& .apexcharts-xcrosshairs": {
    display: "none",
  },
  "& .apexcharts-xaxis-title-text": {
    //titulo X min
    fontSize: "0.8rem",
    fill: theme.palette.custom.primary,
  },
  "& .apexcharts-yaxis-title-text": {
    //titulo Y min
    fontSize: "0.8rem",
    fill: theme.palette.custom.primary,
    transform: "rotate(-90 -13 146) translateY(-40%) translateX(-10px)",
  },
  "& .apexcharts-point-annotation-label": {
    fontSize: "0.75rem",
    transform: "translateX(15px)",
  },
  "& .apexcharts-yaxis-annotation-label": {
    //todos los anotaciones y min
    fontSize: "0.87rem",
    transform: "translate(-10px, 10px)",
    fontWeight: "bold",
  },
  "& .apexcharts-xaxis-annotation-label": {
    //todas las anotaciones x min
    fontSize: "0.9rem",
    transform: "translate(10px, 20px)",
    fontWeight: "bold",
  },

  "& .apexcharts-point-annotation-marker": {
    //tamaño de los puntos mini
    strokeWidth: 0,
    r: 3,
  },
  "& .Esc-1": {
    strokeWidth: 0,
    fill: "orange",
    r: 4,
    transition: "fill 50ms ease, r 50ms ease",
  },

  "& .Esc-2": {
    strokeWidth: 0,
    fill: "yellow",
    r: 4,
  },
  "& .apexcharts-xaxis-label > *": {
    transform: "rotate(45deg)",
  },
  "@media screen and (max-width: 1449px)": {
    width: "250px",
  },
  "@media screen and (min-width: 1450px)": {
    width: "285px",
  },
  "@media screen and (min-width: 1650px)": {
    width: "370px",
  },
}));

const CurrentGraphChart = styled(Chart)(({ theme }) => ({
  zIndex: 900,
  overflow: "visible",
  "& .apexcharts-toolbar": {
    display: "none",
  },
  "& .apexcharts-zoom-icon": {
    display: "none",
  },
  "& .apexcharts-canvas": {
    overflow: "visible",
  },
  "& .apexcharts-svg": {
    zIndex: 1300,
    overflow: "visible",
  },
  "& .max_sales": {
    fill: "black",
  },
  "& .max_profit": {
    fill: "black",
  },
  "& .optimal": {
    fill: "black",
  },
  "& .apexcharts-yaxis-title-text": {
    //title y
    fontSize: "0.9em",
  },
  "& .apexcharts-xaxis-title-text": {
    fontSize: "1em",
  },
  "& .apexcharts-point-annotation-label": {
    fontSize: "0.75em",
  },
  "& .apexcharts-yaxis-annotation-label": {
    fontSize: "0.87em",
    fontWeight: "bold",
  },
  "& .apexcharts-xaxis-annotation-label": {
    //labels x demand
    fontSize: "0.87em",
    fontWeight: "bold",
  },
  "& .apexcharts-point-annotation-marker": {
    strokeWidth: 0,
    r: 4,
  },
  "& .Esc-1": {
    strokeWidth: 0,
    fill: "orange",
    r: 4,
    transition: "fill 50ms ease, r 50ms ease",
  },

  "& .Esc-2": {
    //punto amarillo
    strokeWidth: 0,
    fill: "yellow",
    r: 4,
  },

  "& .arrow_box": {
    backgroundColor: "#151513",
    fontWeight: "bold",
    color: "white",
    padding: "0.5rem",
  },
  "& .arrow_box > span": {
    display: "block",
  },
}));

const FullScreenGraphChart = styled(CurrentGraphChart)(({ theme }) => ({
  width: "80vw",
  "& .apexcharts-yaxis-title-text": {
    fontSize: "1rem",
  },
  "& .apexcharts-xaxis-annotation-label": {
    fontSize: "1rem",
  },
  "& .apexcharts-yaxis-annotation-label": {
    fontSize: "1rem",
  },
  "& .apexcharts-xaxis-title-text": {
    fontSize: "1rem",
  },
  "@media screen and (max-width: 1400px)": {
    width: "80vw",
  },
  "@media screen and (min-width: 1401px)": {
    width: "80vw",
  },
  "@media screen and (min-width: 1650px)": {
    width: "1550px",
  },
}));

const Graph = ({ xyAxis, variant, labelsEsc2, labelsEsc1 }) => {
  const [t, i18n] = useTranslation("global");
  const theme = useTheme();
  const dataSimulation = useSelector(getCurvesLoaded);
  const currentGraph = useSelector(getCurvesCurrentGraph);
  const dataSerie1 = dataSimulation[0];
  const dataSerie2 = dataSimulation[1];
  const dispatch = useDispatch();
  const curveFunction = useSelector(getCurvesFunctionType);

  //Obtener el color de la curva del escenario 2 dependiendo de su brand, en caso contrario se deja el theme
  const categories = useSelector(getCategorySelected);
  const formData = useSelector(getCurvesFormData);
  const brandId = categories.sku_ids.find((el) => el.id === formData.skuId)
    ?.brand_id[0];
  let colorBrand = null;
  if (brandId) {
    colorBrand = categories.brand_ids.find((el) => el.id === brandId)?.color;
  }
  let colorCurveSeries2 = colorBrand || theme.palette.custom.primary;

  // Obtener los puntos requeridos en la grafica Demand Curve
  const getPointsGraph = (data, functionType, xyTags) => {
    return {
      x: {
        MSP: data?.[functionType]?.max_sales_point[xyTags.x],
        MPP: data?.[functionType]?.max_profit_point[xyTags.x],
        OPT: data?.[functionType]?.optimal_point[xyTags.x],
        ESC: data?.[functionType].user_point[xyTags.x],
      },
      y: {
        MSP: data?.[functionType]?.max_sales_point[xyTags.y],
        MPP: data?.[functionType]?.max_profit_point[xyTags.y],
        OPT: data?.[functionType]?.optimal_point[xyTags.y],
        ESC: data?.[functionType].user_point[xyTags.y],
      },
    };
  };

  const pricePointsEsc1 = {
    MSP: dataSerie1?.[curveFunction]?.max_sales_point.price.toFixed(2),
    MPP: dataSerie1?.[curveFunction]?.max_profit_point.price.toFixed(2),
    OPT: dataSerie1?.[curveFunction]?.optimal_point.price.toFixed(2),
    ESC: dataSerie1?.[curveFunction].user_point.price.toFixed(2),
  };

  let pricePointsEsc2 = {};

  if (dataSerie2) {
    pricePointsEsc2 = {
      MSP: dataSerie2?.[curveFunction]?.max_sales_point.price.toFixed(2),
      MPP: dataSerie2?.[curveFunction]?.max_profit_point.price.toFixed(2),
      OPT: dataSerie2?.[curveFunction]?.optimal_point.price.toFixed(2),
      ESC: dataSerie2?.[curveFunction].user_point.price.toFixed(2),
    };
  }

  const pointsEsc1 = getPointsGraph(dataSerie1, curveFunction, xyAxis);
  const pointsEsc2 = dataSerie2
    ? getPointsGraph(dataSerie2, curveFunction, xyAxis)
    : {};

  //Obtener puntos minimos y maximos de una series de datos
  const getMinMaxPoint = (data) => {
    let arr = Array.isArray(data) ? data : Object.values(data);
    let min = Math.min(...arr);
    let max = Math.max(...arr);
    return {
      minPoint: min,
      maxPoint: max,
    };
  };

  //obtener el punto medio de los valores del eje x ó y, para ubicar el texto de porcentaje diferencial
  const getDiff = (point1, point2) => {
    return point1 > point2
      ? (point1 - point2) / 2 + point2
      : (point2 - point1) / 2 + point1;
  };

  //obtener diferencia porcentual del eje x ó y
  const getDiffPerc = (Esc1, Esc2) => {
    return (((Esc2 - Esc1) * 100) / Esc1).toFixed(2);
  };

  //obtener objeto con toda la data de los puntos medios
  const getMidpoint = (pointsEsc1, pointsEsc2) => {
    return {
      x: {
        point: getDiff(pointsEsc2.x.ESC, pointsEsc1.x.ESC),
        text: getDiffPerc(pointsEsc1.x.ESC, pointsEsc2.x.ESC),
      },
      y: {
        point: getDiff(pointsEsc2.y.ESC, pointsEsc1.y.ESC),
        text: getDiffPerc(pointsEsc1.y.ESC, pointsEsc2.y.ESC),
      },
    };
  };

  const pointMiddle = dataSerie2 ? getMidpoint(pointsEsc1, pointsEsc2) : "";

  //formatear data como: [{x: , y:},....,{x: , y:}]  para anexar a apexchart dependiendo del tipo de grafica
  const getSeriesData = (data, functionType, xyTags) => {
    let dataX = data?.[functionType]?.table[xyTags.x];
    let dataY = data?.[functionType]?.table[xyTags.y];

    const dataSet = dataX.map((el, index) => {
      return {
        x: el,
        y: dataY[index],
      };
    });
    return dataSet;
  };

  const getXYRange = (data, functionType, xyTags) => {
    let pointsEsc = {};
    let minMaxPointsX = {};
    let minMaxPointsY = {};

    if (Array.isArray(data)) {
      let pointsEsc2 = {};
      let minMaxPointsX2 = {};
      let minMaxPointsY2 = {};

      pointsEsc = getPointsGraph(data[0], functionType, xyTags);
      pointsEsc2 = getPointsGraph(data[1], functionType, xyTags);

      minMaxPointsX = getMinMaxPoint(pointsEsc.x);
      minMaxPointsY = getMinMaxPoint(pointsEsc.y);

      minMaxPointsX2 = getMinMaxPoint(pointsEsc2.x);
      minMaxPointsY2 = getMinMaxPoint(pointsEsc2.y);

      let minX = Math.min(minMaxPointsX.minPoint, minMaxPointsX2.minPoint);
      let maxX = Math.max(minMaxPointsX.maxPoint, minMaxPointsX2.maxPoint);

      let minY = Math.min(minMaxPointsY.minPoint, minMaxPointsY2.minPoint);
      let maxY = Math.max(minMaxPointsY.maxPoint, minMaxPointsY2.maxPoint);

      return {
        x: {
          min: minX - minX / 20,
          max: maxX + maxX / 20,
        },
        y: {
          min: minY - minY / 20,
          max: maxY + maxY / 20,
        },
      };
    } else {
      pointsEsc = getPointsGraph(data, functionType, xyTags);

      minMaxPointsX = getMinMaxPoint(pointsEsc.x);
      minMaxPointsY = getMinMaxPoint(pointsEsc.y);

      return {
        x: {
          min: minMaxPointsX.minPoint - minMaxPointsX.minPoint / 20,
          max: minMaxPointsX.maxPoint + minMaxPointsX.maxPoint / 20,
        },
        y: {
          min: minMaxPointsY.minPoint - minMaxPointsY.minPoint / 20,
          max: minMaxPointsY.maxPoint + minMaxPointsY.maxPoint / 20,
        },
      };
    }
  };

  let series = [
    {
      name: "Esc-1",
      data: getSeriesData(dataSerie1, curveFunction, xyAxis),
    },
  ];

  if (dataSerie2) {
    series.push({
      name: "Esc-2",
      data: getSeriesData(dataSerie2, curveFunction, xyAxis),
    });
  }

  const argumentDemandRange = dataSerie2
    ? [dataSerie1, dataSerie2]
    : dataSerie1;

  let smallGraphOptions = {
    colors: ["#4d72be", colorCurveSeries2],
    chart: {
      type: "line",
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    annotations: {
      points: [
        {
          x: pointsEsc1.x.MSP,
          y: pointsEsc1.y.MSP,
          marker: {
            cssClass: "max_sales",
          },
        },
        {
          x: pointsEsc1.x.ESC,
          y: pointsEsc1.y.ESC,
          marker: {
            cssClass: "Esc-1",
          },
        },
        {
          x: pointsEsc1.x.MPP,
          y: pointsEsc1.y.MPP,
          marker: {
            cssClass: "max_profit",
          },
        },
        {
          x: pointsEsc1.x.OPT,
          y: pointsEsc1.y.OPT,
          marker: {
            cssClass: "optimal",
          },
        },
      ],
    },
    grid: {
      show: false,
    },
    stroke: {
      show: true,
      width: 1,
    },
    xaxis: {
      type: "numeric",
      min: getXYRange(argumentDemandRange, curveFunction, xyAxis).x.min,
      max: getXYRange(argumentDemandRange, curveFunction, xyAxis).x.max,
      tickAmount: 4,
      labels: {
        rotate: -45,
        formatter: (value) => {
          return value.toFixed(2);
        },
      },
      axisTicks: {
        show: true,
      },
      title: {
        offsetY: 0,
        text: t(`price_management.common_label.${xyAxis.x}`),
      },
    },
    yaxis: {
      show: true,
      tickAmount: 4,
      labels: {
        formatter: (value) => {
          return value.toFixed(2);
        },
      },
      min: getXYRange(argumentDemandRange, curveFunction, xyAxis).y.min,
      max: getXYRange(argumentDemandRange, curveFunction, xyAxis).y.max,
      showAlways: true,
      axisTicks: {
        show: true,
      },
      title: {
        rotate: -90,
        text: t(`price_management.common_label.${xyAxis.y}`), //label de afuera Y
      },
      axisBorder: {
        show: true,
        color: "#c8cacc",
      },
    },
    title: {
      text: t(`price_management.curves_component.${xyAxis.y}_${xyAxis.x}`),
      align: "center",
    },
  };

  let currentGrapOptions = {
    colors: ["#4d72be", colorCurveSeries2],
    chart: {
      type: "line",
      background: "transparent",
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    xaxis: {
      type: "numeric",
      tickAmount: 4,
      min: getXYRange(argumentDemandRange, curveFunction, xyAxis).x.min,
      max: getXYRange(argumentDemandRange, curveFunction, xyAxis).x.max,
      axisBorder: {
        show: true,
        color: "#c8cacc",
      },
      axisTicks: {
        show: false,
      },
      title: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    yaxis: {
      min: getXYRange(argumentDemandRange, curveFunction, xyAxis).y.min,
      max: getXYRange(argumentDemandRange, curveFunction, xyAxis).y.max,
      title: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "#c8cacc",
      },
      labels: {
        show: false,
      },
    },

    title: {
      show: false,
    },
    annotations: {
      yaxis: [
        {
          y: pointsEsc1.y.ESC,
          borderColor: "#c2c2c2",
          strokeDashArray: 5,
          label: {
            text: pointsEsc1.y.ESC.toFixed(2),
            borderWidth: 0,
            textAnchor: "end",
            position: "left",
            offsetX: -2,
            offsetY:
              pointMiddle &&
              pointMiddle.y &&
              pointMiddle.y.text &&
              pointMiddle.y.text >= 0
                ? 20
                : -20,
            style: {
              background: "transparent",
            },
          },
        },
      ],
      xaxis: [
        {
          x: pointsEsc1.x.ESC,
          borderColor: "#c2c2c2",
          strokeDashArray: 5,
          label: {
            text: pointsEsc1.x.ESC.toFixed(2),
            textAnchor:
              pointMiddle &&
              pointMiddle.x &&
              pointMiddle.x.text &&
              pointMiddle.x.text >= 0
                ? "end"
                : "start",
            position: "left",
            orientation: "horizontal",
            borderWidth: 0,
            offsetX: 0,
            offsetY: 20,
            style: {
              background: "transparent",
            },
          },
        },
      ],
      points: [
        {
          x: pointsEsc1.x.MSP,
          y: pointsEsc1.y.MSP,
          marker: {
            cssClass: "max_sales",
          },
          label: {
            text: !labelsEsc1 ? "" : `PMV ($${pricePointsEsc1.MSP})`,
            borderWidth: 2,
            borderColor: "#f6f2f2",
            style: {
              background: "rgba( 255, 255, 255, 0.45 )",
            },
            offsetX: 0,
            offsetY: 0,
            textAnchor: "end",
          },
        },
        {
          x: pointsEsc1.x.ESC,
          y: pointsEsc1.y.ESC,
          marker: {
            cssClass: "Esc-1",
          },
          label: {
            text: !labelsEsc1 ? "" : `Esc-1 ($${pricePointsEsc1.ESC})`,
            borderWidth: 2,
            borderColor: "#f6f2f2",
            style: {
              background: "rgba( 255, 255, 255, 0.45 )",
            },
            offsetX: 0,
            offsetY: 0,
            textAnchor: "end",
          },
        },
        {
          x: pointsEsc1.x.MPP,
          y: pointsEsc1.y.MPP,
          marker: {
            cssClass: "max_profit",
          },
          label: {
            text: !labelsEsc1 ? "" : `PMR ($${pricePointsEsc1.MPP})`,
            borderWidth: 2,
            borderColor: "#f6f2f2",
            style: {
              background: "rgba( 255, 255, 255, 0.45 )",
            },
            offsetX: 0,
            offsetY: 0,
            textAnchor: "end",
          },
        },
        {
          x: pointsEsc1.x.OPT,
          y: pointsEsc1.y.OPT,
          marker: {
            cssClass: "optimal",
          },
          label: {
            text: !labelsEsc1 ? "" : `P* ($${pricePointsEsc1.OPT})`,
            borderWidth: 2,
            borderColor: "#f6f2f2",
            style: {
              background: "rgba( 255, 255, 255, 0.45 )",
            },
            offsetX: 0,
            offsetY: 0,
            textAnchor: "end",
          },
        },
      ],
    },
  };
  //Add scenary two to small graph
  if (dataSerie2) {
    currentGrapOptions.annotations?.yaxis.push({
      y: pointsEsc2.y.ESC,
      borderColor: "#c2c2c2",
      strokeDashArray: 5,
      label: {
        text: pointsEsc2.y.ESC?.toFixed(2),
        textAnchor: "end",
        position: "left",
        strokeWidth: 0,
        borderWidth: 0,
        offsetX: -2,
        offsetY: pointMiddle.y.text >= 0 ? -20 : 20,
        style: {
          background: "transparent",
        },
      },
    });
    currentGrapOptions.annotations?.xaxis.push({
      x: pointsEsc2.x.ESC,
      borderColor: "#c2c2c2",
      strokeDashArray: 5,
      label: {
        text: pointsEsc2.x.ESC?.toFixed(2),
        textAnchor: pointMiddle.x.text >= 0 ? "start" : "end",
        position: "left",
        orientation: "horizontal",
        borderWidth: 0,
        offsetX: 0,
        offsetY: 33,
        style: {
          background: "transparent",
        },
      },
    });
    const annotationPointsSmall = [
      {
        x: pointsEsc2.x.MSP,
        y: pointsEsc2.y.MSP,
        marker: {
          cssClass: "max_sales-2",
          fillColor: colorCurveSeries2,
        },
      },
      {
        x: pointsEsc2.x.ESC,
        y: pointsEsc2.y.ESC,
        marker: {
          cssClass: "Esc-2",
        },
      },
      {
        x: pointsEsc2.x.MPP,
        y: pointsEsc2.y.MPP,
        marker: {
          cssClass: "max_profit-2",
          fillColor: colorCurveSeries2,
        },
      },
      {
        x: pointsEsc2.x.OPT,
        y: pointsEsc2.y.OPT,
        marker: {
          cssClass: "optimal-2",
          fillColor: colorCurveSeries2,
        },
      },
    ];

    const annotationPointsCurrent = [
      {
        x: pointsEsc2.x.MSP,
        y: pointsEsc2.y.MSP,
        marker: {
          cssClass: "max_sales-2",
          fillColor: colorCurveSeries2,
        },
        label: {
          text: !labelsEsc2 ? "" : `PMV ($${pricePointsEsc2.MSP})`,
          borderWidth: 2,
          borderColor: "#f6f2f2",
          offsetX: 0,
          offsetY: 0,
          textAnchor: "start",
          style: {
            background: "rgba( 255, 255, 255, 0.45 )",
          },
        },
      },
      {
        x: pointsEsc2.x.ESC,
        y: pointsEsc2.y.ESC,
        marker: {
          cssClass: "Esc-2",
        },
        label: {
          text: !labelsEsc2 ? "" : `Esc-2 ($${pricePointsEsc2.ESC})`,
          borderColor: "#f6f2f2",
          borderWidth: 2,
          offsetX: 0,
          offsetY: 0,
          textAnchor: "start",
          style: {
            background: "rgba( 255, 255, 255, 0.45 )",
          },
        },
      },
      {
        x: pointsEsc2.x.MPP,
        y: pointsEsc2.y.MPP,
        marker: {
          cssClass: "max_profit-2",
          fillColor: colorCurveSeries2,
        },
        label: {
          text: !labelsEsc2 ? "" : `PMR ($${pricePointsEsc2.MPP})`,
          borderWidth: 2,
          borderColor: "#f6f2f2",
          offsetX: 0,
          offsetY: 0,
          textAnchor: "start",
          style: {
            background: "rgba( 255, 255, 255, 0.45 )",
          },
        },
      },
      {
        x: pointsEsc2.x.OPT,
        y: pointsEsc2.y.OPT,
        marker: {
          cssClass: "optimal-2",
          fillColor: colorCurveSeries2,
        },
        label: {
          text: !labelsEsc2 ? "" : `P* ($${pricePointsEsc2.OPT})`,
          borderWidth: 2,
          borderColor: "#f6f2f2",
          offsetX: 0,
          offsetY: 0,
          textAnchor: "start",
          style: {
            background: "rgba( 255, 255, 255, 0.45 )",
          },
        },
      },
    ];
    smallGraphOptions.annotations.points =
      smallGraphOptions.annotations?.points.concat(annotationPointsSmall);
    currentGrapOptions.annotations.points =
      currentGrapOptions.annotations?.points.concat(annotationPointsCurrent);
  }

  const fullScreenGraphOptions = {
    ...currentGrapOptions,
    title: {
      text: t(`price_management.curves_component.${xyAxis.y}_${xyAxis.x}`),
      align: "center",
    },
  };

  const handleClick = (data) => {
    dispatch(setAxisGraph(data));
  };

  const SpanAxis = ({ type, text, show = true }) => {
    switch (type) {
      case "title-y":
        return (
          <div
            style={{
              fontWeight: "bold",
              width: "100%",
              paddingLeft: "12px",
              marginTop: "10px",
            }}
          >
            <span>{text}</span>
          </div>
        );

      case "diff-y":
        return (
          <div>
            <span
              style={{
                fontSize: "1em",
                fontWeight: "bold",
                color: text >= 0 ? "green" : "red",
                paddingLeft: "12px",
              }}
            >
              {text} %
            </span>
          </div>
        );
      case "title-x":
        return (
          <div style={{ textAlign: "right" }}>
            <span
              style={{
                fontWeight: "bold",
                paddingRight: "12px",
              }}
            >
              {text}
            </span>
          </div>
        );

      case "diff-x":
        return (
          <div style={{ textAlign: "right" }}>
            <span
              style={{
                fontSize: "1em",
                fontWeight: "bold",
                color: text >= 0 ? "green" : "red",
                paddingRight: "12px",
              }}
            >
              {text} %
            </span>
          </div>
        );
    }
  };

  const getVariant = (variant) => {
    switch (variant) {
      case "miniature":
        return (
          <CustomGridGraph
            className={`${currentGraph.id === xyAxis.id ? "active" : ""}`}
            item
          >
            <SmallGraphChart
              options={smallGraphOptions}
              series={series}
              height="270"
              onClick={() => handleClick(xyAxis)}
            />
          </CustomGridGraph>
        );
      case "demand":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <SpanAxis
              type="title-y"
              text={t(`price_management.common_label.${xyAxis.y}`)}
            ></SpanAxis>
            {pointMiddle && pointMiddle.y && (
              <SpanAxis type="diff-y" text={pointMiddle.y.text}></SpanAxis>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                overflow: "visible",
              }}
            >
              <div
                className="mainContentGraph"
                style={{
                  position: "relative",

                  overflow: "visible",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                   className="currentGraph"
                  style={{
                    position: "relative",
                    zIndex: 1000,
                    top: -20,
                    marginLeft: "80px",
                    overflow: "visible",
                    height: dataSerie2 ? "460px" : "500px",
                    width: dataSerie2 ? "450px" : "455px",
                  }}
                >
                  <CurrentGraphChart
                    options={currentGrapOptions}
                    series={series}
                    height="100%"
                  />
                </div>
              </div>
            </div>
            <SpanAxis
              type="title-x"
              text={t(`price_management.common_label.${xyAxis.x}`)}
            ></SpanAxis>
            {pointMiddle && pointMiddle.x && (
              <SpanAxis type="diff-x" text={pointMiddle.x.text}></SpanAxis>
            )}
          </div>
        );
      case "fullScreen":
        return (
          <Grid item xs={12} style={{ paddin: "10px" }}>
            <DivGraph size={"full"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <SpanAxis
                  type="title-y"
                  text={t(`price_management.common_label.${xyAxis.y}`)}
                ></SpanAxis>
                {pointMiddle && pointMiddle.y && (
                  <SpanAxis type="diff-y" text={pointMiddle.y.text}></SpanAxis>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      top: -20,
                      left: 0,
                    }}
                  >
                    <FullScreenGraphChart
                      options={fullScreenGraphOptions}
                      series={series}
                      height="550px"
                    />
                  </div>
                </div>
                <SpanAxis
                  type="title-x"
                  text={t(`price_management.common_label.${xyAxis.x}`)}
                ></SpanAxis>
                {pointMiddle && pointMiddle.x && (
                  <SpanAxis type="diff-x" text={pointMiddle.x.text}></SpanAxis>
                )}
              </div>
            </DivGraph>
          </Grid>
        );
      default:
        return;
    }
  };

  return getVariant(variant);
};

export default Graph;
