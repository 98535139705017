import { all } from "redux-saga/effects";
import appSaga from "./modules/app/saga";
import layout from "./modules/layout/saga";
import authSaga from "./modules/auth/saga";
import categoriesSaga from "./modules/categories/saga";
import subcategoriesSaga from "./modules/subcategories/saga";
import usersSaga from "./modules/users/saga";
import ownersSaga from "./modules/owners/saga";
import brandsSaga from "./modules/brands/saga";
import skusSaga from "./modules/skus/saga";
import unitsSaga from "./modules/units/saga";
import variablesSaga from "./modules/variables/saga";
import labSaga from "./modules/lab/saga";
import relationsSaga from "./modules/relations/saga";
import customersSaga from "./modules/customers/saga";
import categoryDataSaga from "./modules/categoryData/saga";
import simulationSaga from "./modules/what-if/curves/saga";
import optimalPricesSaga from "./modules/what-if/optimal-prices/saga";
import trendSaga from "./modules/what-if/trend/saga";
import matrizESaga from "./modules/sniper/matriz-e/saga";
import momentumSaga from "./modules/sniper/momentum/saga";
import promotionsSaga from "./modules/promotion-planning/promotions/saga";
import volumesSaga from "./modules/promotion-planning/volumes/saga";
import plSaga from "./modules/promotion-planning/pl/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    appSaga(),
    layout(),
    usersSaga(),
    unitsSaga(),
    categoriesSaga(),
    subcategoriesSaga(),
    ownersSaga(),
    brandsSaga(),
    skusSaga(),
    relationsSaga(),
    variablesSaga(),
    categoryDataSaga(),
    labSaga(),
    customersSaga(),
    simulationSaga(),
    optimalPricesSaga(),
    trendSaga(),
    matrizESaga(),
    momentumSaga(),
    promotionsSaga(),
    volumesSaga(),
    plSaga(),
  ]);
}
