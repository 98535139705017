import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import CustomizeLogo from "./CustomizeLogo";
import ChangePassword from "./ChangePassword";
import styled from "styled-components";
import CustomizeTheme from "./CustomizeTheme";
import AccordionProps from "../../Components/Accordion/AccordionProps";
import {
  clearResponseApp,
  saveConfig,
  updateLogo,
} from "../../Redux/modules/app/actions";
import {
  getResponseApp,
  getUser,
  getTheme,
} from "../../Redux/modules/app/selectors";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";

const ConfigurationWrapper = styled.div`
  .theme-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
  }
  .layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1em;
    width: 80%;
    font-size: 0.6em;
    overflow: hidden;
    border-radius: 0.25em;
    box-shadow: 0px 3px 6px #00000029;
  }
  .save-colors {
    display: flex;
    flex: row;
    justify-content: space-evenly;
    width: 100%;
  }

  .colors-content {
    display: flex;
    margin-bottom: 1em;
    .color {
      cursor: pointer;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      margin-right: 0.5em;
      box-shadow: 0px 3px 6px #00000029;
    }
  }
`;
const Configuration = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");

  const response = useSelector(getResponseApp);
  const user = useSelector(getUser);
  const colors = useSelector(getTheme);
 
  const { enqueueSnackbar } = useSnackbar();
  const [themeCustomize, setThemeCustomize] = useState({
    colors: null,
    logo: null,
  });

  const handleUpdateLogo = (newLogo) => {
    setThemeCustomize({ ...themeCustomize, logo: newLogo });
  };

  const handleSaveLogo = (newLogo) => {
    dispatch(updateLogo.trigger({ logo: newLogo }));
  };

  const handleUpdateColors = (newColors) => {
    setThemeCustomize({ ...themeCustomize, colors: newColors });
  };

  const handleSaveColors = (newColors) => {
    if (!themeCustomize.logo) {
      dispatch(
        saveConfig({
          ...themeCustomize,
          colors: newColors,
          logo: user.imageLogo,
        })
      );
    } else {
      dispatch(saveConfig({ ...themeCustomize, colors: newColors }));
    }
  };
  const accordionItems = [
    {
      id: 1,
      accordionTitle: `${t("settings_component.change_password")}`,
      accordionDetails: <ChangePassword />,
    },
    {
      id: 2,
      accordionTitle: `${t("settings_component.customize_account")}`,
      accordionDetails: (
        <CustomizeTheme
          handleSaveColors={handleSaveColors}
          handleUpdateColors={handleUpdateColors}
          colors={colors}
          logo={user.imageLogo}
        />
      ),
    },
    {
      id: 3,
      accordionTitle: `${t("components_globals.button_upload_logo")}`,
      accordionDetails: (
        <CustomizeLogo
          handleUpdateLogo={handleUpdateLogo}
          handleSaveLogo={handleSaveLogo}
          logo={user.imageLogo}
        />
      ),
    },
  ];

  useEffect(() => {
    if (response.error || response.message) {
      enqueueSnackbar(response.error ? response.error : t(response.message), {
        variant: `${response.error ? "error" : "success"}`,
      });
      dispatch(clearResponseApp());
    }
  }, [response, user, colors]);

  return (
    <ConfigurationWrapper>
      {accordionItems.map((accordionItem, index) => (
        <AccordionProps
          id={index}
          accordionTitle={
            <Typography variant="body" fontWeight="700">
              {accordionItem.accordionTitle}
            </Typography>
          }
          accordionDetails={accordionItem.accordionDetails}
          key={accordionItem.id}
        />
      ))}
    </ConfigurationWrapper>
  );
};

export default Configuration;
