import React from "react";
import Sku from "./Sku";

const Step3 = ({ currentCategory, onNext, onBack }) => {
  return (
    <Sku
    category ={currentCategory}
    onNext={() => onNext()}
    onBack={() => onBack()}
      currentSkus={currentCategory ? currentCategory.sku_ids : []}
    />
  );
};

export default Step3;
