import { Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";

const CssPagination = styled(Pagination)(({ theme }) => ({
  ".Mui-selected": {
    backgroundColor: theme.palette.custom.secondary,
    color: theme.palette.custom.background,
  },
}));

export default function CustomPagination(props) {
  return <CssPagination {...props} />;
}
