import React from "react";
import { SvgIcon } from "@mui/material";

export function EraserIcon(props) {
  const classEraser = {
    fill: "none",
    strokelinecap: "round",
    strokelinejoin: "round",
    strokewidth: "1.5",
  };
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 48 48">
        <g transform="matrix(2,0,0,2,0,0)">
          <g>
            <line
              x1="2.49"
              y1="23.25"
              x2="23.24"
              y2="23.25"
              style={classEraser}
            />
            <path
              d="M15.24,1.55l5.24,5.24a2.75,2.75,0,0,1,0,3.88l-5.93,5.92a8.21,8.21,0,0,1-11.62,0L1.56,15.23a2.75,2.75,0,0,1,0-3.88l9.8-9.8A2.75,2.75,0,0,1,15.24,1.55Z"
              style={classEraser}
            />
            <line
              x1="7.48"
              y1="5.43"
              x2="16.6"
              y2="14.55"
              style={classEraser}
            />
          </g>
        </g>
      </svg>
      ;
    </SvgIcon>
  );
}
