import React from "react";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";

const CssFormControl = styled(FormControl)(({ theme, customWidth = "100%" }) => ({
  width: customWidth,
  "& .MuiInputBase-root": {
    borderRadius: "0.5em",
    //border: '2px solid '+theme.palette.custom.third,
    color: theme.palette.custom.primary,
  },
  "& .MuiFormLabel-root": {
    color: theme.palette.custom.third,
  },
  "&:hover .MuiFormLabel-root, & .Mui-focused": {
    color: theme.palette.custom.primary,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset, .MuiSvgIcon-root": {
      color: theme.palette.custom.third,
      borderColor: theme.palette.custom.third,
      borderWidth: "3px",
    },
    "&:hover fieldset, &:hover .MuiSvgIcon-root": {
      borderColor: theme.palette.custom.primary,
      color: theme.palette.custom.primary,
    },
    "&.Mui-focused fieldset,&.Mui-focused .MuiSvgIcon-root": {
      borderColor: theme.palette.custom.primary,
      color: theme.palette.custom.primary,
    },
  },
}));

const CssFormControlSecondary = styled(FormControl)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "0.5em",
    borderRadius: "2em",
    padding: "0em",
  },
  "& .MuiFormLabel-root": {
    color: theme.palette.custom.text,
  },
  "&:hover .MuiFormLabel-root, & .Mui-focused": {
    color: theme.palette.custom.thirdVariant,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset, .MuiSvgIcon-root": {
      color: theme.palette.custom.text,
      borderColor: theme.palette.custom.thirdVariant,
      borderWidth: "3px",
    },
    "&:hover fieldset, &:hover .MuiSvgIcon-root": {
      borderColor: theme.palette.custom.thirdVariant,
      color: theme.palette.custom.thirdVariant,
    },
    "&.Mui-focused fieldset,&.Mui-focused .MuiSvgIcon-root": {
      borderColor: theme.palette.custom.thirdVariant,
    },
  },
}));

const CustomFormControl = (props) => {
  const getVariant = (variant) => {
    switch (variant) {
      case "primary":
        return <CssFormControl {...props}>{props.children}</CssFormControl>;

      case "secondary":
        return (
          <CssFormControlSecondary fullWidth>
            {props.children}
          </CssFormControlSecondary>
        );

      default:
        return <CssFormControl {...props}>{props.children}</CssFormControl>;
    }
  };
  return getVariant(props.variant ? props.variant : "default");
};

export default CustomFormControl;
